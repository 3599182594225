import React from "react";
import { DownloadTemplateFunction } from "../../util/downloadTemplate";
import { TenantMasterData } from "./data";
import Historycard from "./Historycard";
import FormComponent from "./UploadFormComponent";

export default function InstrumentSectorMapping() {
  const handleAddSubmit = (data) => {
    // console.log("data", data);
  };
  const handledownloadTemplate = (data) => {
    DownloadTemplateFunction("InstrumentSectorMapping")
  };
  const handleFileUpload = (file) => {
    // console.log("file", file);
  };
  return (
    <>
      <FormComponent
        onSubmit={handleAddSubmit}
        onFileChangeNew={handleFileUpload}
        downloadTemplate={handledownloadTemplate}
      />
      <div className="row">
        <Historycard data={TenantMasterData}/>
       
      </div>
    </>
  );
}
