const CustomDropDown = {
  option: (provided, state) => ({
    ...provided,
    color: state.isSelected ? `rgba(var(--text-primary-color))` : "",
    backgroundColor: state.isSelected ? `rgba(var(--primary-color),0.5)` : "",
    padding: 20,
    "&:hover": {},
  }),
  control: (base, state, styles, ) => ({
    ...base,
    ...base,
    ...base,
    ...styles,
    backgroundColor:"",
    borderTop: "0",
    padding: 0,
    paddingBottom: 10,
    borderLeft: "0",
    borderRight: "0",
    borderBottom: "1",
    boxShadow: "none",
    borderRadius: "0",
    marginTop: "0px",
    borderColor: state.isSelected
      ? `#ddd`
      : `#ddd`,
    "&:hover": {
      ...base,
      ...base,
      borderTop: "0",
      padding: 0,
      paddingBottom: 10,
      borderLeft: "0",
      borderRight: "0",
      borderBottom: "1",
      boxShadow: "none",
      borderRadius: "0",
      borderColor: state.isSelected
        ? `#ddd`
        : `#ddd`,
    },
  }),

  valueContainer: (provided) => ({
    ...provided,
    overflow: "visible",
    marginBottom: "-15px",
  }),
  singleValue: (provided) => ({
    ...provided,
    marginLeft: "-15px",
    marginTop: "-4px",
    // textTransform: "uppercase !important",
    paddingLeft: "10px"
  }),
  menu: (provided) => {
    return {
      ...provided,
      zIndex: 9999,
      // textTransform: "uppercase !important",
    };
  },
  placeholder: (provided, state) => ({
    ...provided,
    position: "relative",
    MaxWeight: "auto !important",
    fontFamily: "Poppins-Regular  !important",
    fontStyle: "normal  !important",
    fontWeight: "400  !important",
    lineHeight: "18px  !important",
    color: "#000",
    marginBottom: "10px  !important",
    top: state.hasValue || state.selectProps.inputValue ? -17 : -10,
    transition: "top 0.1s",
    fontSize:  state.hasValue || state.selectProps.inputValue ? "12px !important" : "15px !important",
    opacity: state.hasValue || state.selectProps.inputValue ? 1 : .9,
    paddingBottom:
      state.hasValue || state.selectProps.inputValue ? "2px" : "5px",
    marginLeft: "-15px",
    display: "none",
  }),
};

export default CustomDropDown;
