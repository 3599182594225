import { faBars, faPowerOff, faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import PropTypes from "prop-types";
import React from "react";
import { useNavigate } from "react-router-dom";
import { GetSvglogo } from "../assets/img/app/GetSvglogo";
import {
  logout,
  removeAccess,
  removeLoginType,
  removeToken,
  setLocalStorage,
} from "../util";
import { getTenantkey } from './../util/TenantMasterconfig';
import "./header.scss";
import useimg from "./user4-094019cb.jpg";

const Header = ({ onToggle, collapsedvalue }) => {
  const navigate = useNavigate();

  /**
   * Handles user logout by clearing stored data and redirecting to the login page.
   */
  const handleLogInOut = () => {
    removeToken();
    removeLoginType();
    removeAccess();
    setLocalStorage("counter", 0);
    navigate("/");
    logout("false");
    // Optional: Reload the page to reset the application state
    // window.location.reload();
  };

  return (
    <div className="headerdiv">
      {/* Application Logo */}
      <div className="applogo">
        {/* {getTenantkey("logo")} */}

        <GetSvglogo
          iconName={getTenantkey()}
          color={"d"}
        ></GetSvglogo>
      </div>

      {/* Navigation Toggle Button */}
      <button
        type="button"
        onClick={onToggle}
        className={`navbtn ${collapsedvalue ? "bar d-none" : "close d-none"}`}
      >
        <FontAwesomeIcon icon={collapsedvalue ? faTimes : faBars} />
      </button>

      {/* Profile and Logout Section */}
      <div className="d-flex justify-content-end align-items-center">
        <img
          src={useimg}
          className="profileimg img-thumbnail"
          alt="User Profile"
        />
        <FontAwesomeIcon
          icon={faPowerOff}
          onClick={handleLogInOut}
          size="2xl"
          className="logoutbtn"
          title="Logout"
        />
      </div>
    </div>
  );
};

Header.propTypes = {
  onToggle: PropTypes.func.isRequired, // Function to toggle navigation
  collapsedvalue: PropTypes.bool.isRequired, // State for navigation collapse
};

export default Header;
