export const Apiurl = {
  interventionlist: `admin/api/v1/lms-admin/intervention/list`,
  interventionCreate: `admin/api/v1/lms-admin/intervention/create`,
  interventionSingleView: `admin/api/v1/lms-admin/intervention/getById`,
  interventionEdit: `admin/api/v1/lms-admin/intervention/edit`,
  interventionToggle: `admin/api/v1/lms-admin/intervention/toogle`,
  schoolList: `master/api/v1/lms-master/school-mgmt/list`,
  activatedUserRoleList: `user/api/v1/usertype/list`,
  authlogin: "auth/login",
  authrefreshogin: "auth/refresh",

  //app login
  api_login: "redirect/api/v1/repo/auth/login",
};
