import React, { Suspense } from "react";
import { Route, Routes } from "react-router-dom";
import LandingPage from "../AppLanding/LandingPage";
import ForgotPasswordFormComponent from "../Authenticate/ForgotPasswordFormComponent";
import ForgotUserIdFormComponent from "../Authenticate/ForgotUserIdFormComponent";
import LoginFormComponents from "../Authenticate/LoginFormComponent";
import SignupFormComponent from "../Authenticate/SignupFormComponent";
import PageNotFound from "../PageNotFound";

// Import the NotFound component
// const NotFound = React.lazy(() => import("../page/PageNotFound"));

export default function Public() {
  return (
    <Suspense fallback={null}>
      <Routes>
        <Route path="/" element={<LandingPage />}></Route>
        <Route path="/login" element={<LoginFormComponents />}></Route>
        <Route path="/SignUp" element={<SignupFormComponent />}></Route>
        <Route
          path="/ForgotPassword"
          element={<ForgotPasswordFormComponent />}
        ></Route>
        <Route
          path="/ForgotUser"
          element={<ForgotUserIdFormComponent />}
        ></Route>

        <Route path="/login/rm" element={<LoginFormComponents />}></Route>
        <Route
          path="*"
          element={
            <PageNotFound
              status={404}
              message="Oops! Looks like you're lost in space."
              btnText="Return to Home"
            />
          }
        />
      </Routes>
    </Suspense>
  );
}
