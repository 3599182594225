export function getData() {
  return [
    {
      lastName: "Miller",
      firstName: "John",
      age: 23,
      gender: "Male",
      RegNo: "REG438",
      country: "USA",
      receiptNo: "REC364",
      address: "299 Elm St, City",
      oppCharges: 65,
      gstCharges: 30,
      OTTCharges: 19,
      totalAmount: 1000000055,
      status: "Cancelled",
      JourneyDate: "2023-06-16",
    },
    {
      lastName: "Brown",
      firstName: "Michael",
      age: 22,
      gender: "Male",
      RegNo: "REG899",
      country: "Australia",
      receiptNo: "REC33",
      address: "556 Elm St, City",
      oppCharges: 223,
      gstCharges: 32,
      OTTCharges: 24,
      totalAmount: 279,
      status: "Cancelled",
      JourneyDate: "2024-06-15",
    },
    {
      lastName: "Miller",
      firstName: "Richard",
      age: 52,
      gender: "Male",
      RegNo: "REG185",
      country: "Canada",
      receiptNo: "REC807",
      address: "666 Elm St, City",
      oppCharges: 120,
      gstCharges: 22,
      OTTCharges: 69,
      totalAmount: 211,
      status: "Cancelled",
      JourneyDate: "2023-04-07",
    },
    {
      lastName: "Johnson",
      firstName: "Robert",
      age: 55,
      gender: "Female",
      RegNo: "REG531",
      country: "India",
      receiptNo: "REC511",
      address: "244 Elm St, City",
      oppCharges: 158,
      gstCharges: 24,
      OTTCharges: 99,
      totalAmount: 281,
      status: "Pending",
      JourneyDate: "2023-10-01",
    },
    {
      lastName: "Johnson",
      firstName: "William",
      age: 65,
      gender: "Male",
      RegNo: "REG316",
      country: "USA",
      receiptNo: "REC427",
      address: "620 Elm St, City",
      oppCharges: 132,
      gstCharges: 28,
      OTTCharges: 89,
      totalAmount: 249,
      status: "Paid",
      JourneyDate: "2023-11-07",
    },
    {
      lastName: "Brown",
      firstName: "David",
      age: 20,
      gender: "Female",
      RegNo: "REG404",
      country: "USA",
      receiptNo: "REC827",
      address: "804 Elm St, City",
      oppCharges: 62,
      gstCharges: 17,
      OTTCharges: 46,
      totalAmount: 125,
      status: "Pending",
      JourneyDate: "2023-08-27",
    },
    {
      lastName: "Davis",
      firstName: "Michael",
      age: 43,
      gender: "Male",
      RegNo: "REG3",
      country: "Canada",
      receiptNo: "REC114",
      address: "225 Elm St, City",
      oppCharges: 56,
      gstCharges: 28,
      OTTCharges: 42,
      totalAmount: 126,
      status: "Paid",
      JourneyDate: "2023-12-23",
    },
    {
      lastName: "Williams",
      firstName: "David",
      age: 22,
      gender: "Male",
      RegNo: "REG328",
      country: "USA",
      receiptNo: "REC100",
      address: "385 Elm St, City",
      oppCharges: 65,
      gstCharges: 34,
      OTTCharges: 22,
      totalAmount: 121,
      status: "Pending",
      JourneyDate: "2024-01-22",
    },
    {
      lastName: "Smith",
      firstName: "David",
      age: 25,
      gender: "Male",
      RegNo: "REG902",
      country: "USA",
      receiptNo: "REC872",
      address: "355 Elm St, City",
      oppCharges: 174,
      gstCharges: 29,
      OTTCharges: 84,
      totalAmount: 287,
      status: "Paid",
      JourneyDate: "2023-08-31",
    },
    {
      lastName: "Smith",
      firstName: "David",
      age: 28,
      gender: "Male",
      RegNo: "REG590",
      country: "UK",
      receiptNo: "REC952",
      address: "884 Elm St, City",
      oppCharges: 169,
      gstCharges: 32,
      OTTCharges: 37,
      totalAmount: 238,
      status: "Pending",
      JourneyDate: "2023-06-12",
    },
    {
      lastName: "Johnson",
      firstName: "John",
      age: 35,
      gender: "Female",
      RegNo: "REG787",
      country: "USA",
      receiptNo: "REC182",
      address: "606 Elm St, City",
      oppCharges: 72,
      gstCharges: 25,
      OTTCharges: 90,
      totalAmount: 187,
      status: "Pending",
      JourneyDate: "2023-07-24",
    },
    {
      lastName: "Davis",
      firstName: "James",
      age: 24,
      gender: "Female",
      RegNo: "REG621",
      country: "Australia",
      receiptNo: "REC141",
      address: "701 Elm St, City",
      oppCharges: 75,
      gstCharges: 30,
      OTTCharges: 30,
      totalAmount: 135,
      status: "Pending",
      JourneyDate: "2023-08-07",
    },
    {
      lastName: "Miller",
      firstName: "Robert",
      age: 33,
      gender: "Male",
      RegNo: "REG132",
      country: "USA",
      receiptNo: "REC140",
      address: "58 Elm St, City",
      oppCharges: 233,
      gstCharges: 26,
      OTTCharges: 18,
      totalAmount: 277,
      status: "Cancelled",
      JourneyDate: "2023-04-18",
    },
    {
      lastName: "Williams",
      firstName: "David",
      age: 46,
      gender: "Male",
      RegNo: "REG335",
      country: "USA",
      receiptNo: "REC461",
      address: "494 Elm St, City",
      oppCharges: 57,
      gstCharges: 14,
      OTTCharges: 72,
      totalAmount: 143,
      status: "Pending",
      JourneyDate: "2023-06-08",
    },
    {
      lastName: "Jones",
      firstName: "William",
      age: 21,
      gender: "Female",
      RegNo: "REG652",
      country: "UK",
      receiptNo: "REC487",
      address: "160 Elm St, City",
      oppCharges: 162,
      gstCharges: 38,
      OTTCharges: 69,
      totalAmount: 269,
      status: "Paid",
      JourneyDate: "2023-01-07",
    },
    {
      lastName: "Smith",
      firstName: "Robert",
      age: 53,
      gender: "Male",
      RegNo: "REG395",
      country: "Australia",
      receiptNo: "REC883",
      address: "286 Elm St, City",
      oppCharges: 89,
      gstCharges: 25,
      OTTCharges: 95,
      totalAmount: 209,
      status: "Paid",
      JourneyDate: "2024-03-23",
    },
    {
      lastName: "Miller",
      firstName: "William",
      age: 69,
      gender: "Male",
      RegNo: "REG15",
      country: "USA",
      receiptNo: "REC327",
      address: "66 Elm St, City",
      oppCharges: 94,
      gstCharges: 34,
      OTTCharges: 33,
      totalAmount: 161,
      status: "Paid",
      JourneyDate: "2023-09-28",
    },
    {
      lastName: "Williams",
      firstName: "Michael",
      age: 20,
      gender: "Female",
      RegNo: "REG901",
      country: "Canada",
      receiptNo: "REC631",
      address: "845 Elm St, City",
      oppCharges: 138,
      gstCharges: 12,
      OTTCharges: 61,
      totalAmount: 211,
      status: "Paid",
      JourneyDate: "2023-01-25",
    },
    {
      lastName: "Jones",
      firstName: "John",
      age: 54,
      gender: "Female",
      RegNo: "REG50",
      country: "UK",
      receiptNo: "REC375",
      address: "607 Elm St, City",
      oppCharges: 115,
      gstCharges: 11,
      OTTCharges: 5,
      totalAmount: 131,
      status: "Paid",
      JourneyDate: "2023-02-27",
    },
    {
      lastName: "Williams",
      firstName: "Richard",
      age: 37,
      gender: "Female",
      RegNo: "REG976",
      country: "UK",
      receiptNo: "REC625",
      address: "667 Elm St, City",
      oppCharges: 110,
      gstCharges: 15,
      OTTCharges: 15,
      totalAmount: 140,
      status: "Paid",
      JourneyDate: "2023-02-12",
    },
    {
      lastName: "Smith",
      firstName: "Robert",
      age: 38,
      gender: "Male",
      RegNo: "REG133",
      country: "Australia",
      receiptNo: "REC344",
      address: "221 Elm St, City",
      oppCharges: 87,
      gstCharges: 31,
      OTTCharges: 66,
      totalAmount: 184,
      status: "Cancelled",
      JourneyDate: "2023-05-26",
    },
    {
      lastName: "Jones",
      firstName: "William",
      age: 58,
      gender: "Male",
      RegNo: "REG457",
      country: "Australia",
      receiptNo: "REC674",
      address: "437 Elm St, City",
      oppCharges: 81,
      gstCharges: 11,
      OTTCharges: 45,
      totalAmount: 137,
      status: "Paid",
      JourneyDate: "2024-04-01",
    },
    {
      lastName: "Brown",
      firstName: "Robert",
      age: 69,
      gender: "Male",
      RegNo: "REG777",
      country: "USA",
      receiptNo: "REC285",
      address: "886 Elm St, City",
      oppCharges: 135,
      gstCharges: 13,
      OTTCharges: 41,
      totalAmount: 189,
      status: "Cancelled",
      JourneyDate: "2024-07-02",
    },
    {
      lastName: "Miller",
      firstName: "William",
      age: 56,
      gender: "Male",
      RegNo: "REG631",
      country: "Australia",
      receiptNo: "REC234",
      address: "45 Elm St, City",
      oppCharges: 64,
      gstCharges: 29,
      OTTCharges: 72,
      totalAmount: 165,
      status: "Cancelled",
      JourneyDate: "2023-05-12",
    },
    {
      lastName: "Jones",
      firstName: "David",
      age: 63,
      gender: "Female",
      RegNo: "REG272",
      country: "UK",
      receiptNo: "REC158",
      address: "715 Elm St, City",
      oppCharges: 177,
      gstCharges: 36,
      OTTCharges: 20,
      totalAmount: 233,
      status: "Cancelled",
      JourneyDate: "2023-05-21",
    },
    {
      lastName: "Williams",
      firstName: "Robert",
      age: 34,
      gender: "Female",
      RegNo: "REG593",
      country: "Australia",
      receiptNo: "REC773",
      address: "915 Elm St, City",
      oppCharges: 63,
      gstCharges: 24,
      OTTCharges: 55,
      totalAmount: 142,
      status: "Cancelled",
      JourneyDate: "2023-07-17",
    },
    {
      lastName: "Williams",
      firstName: "Richard",
      age: 30,
      gender: "Male",
      RegNo: "REG480",
      country: "India",
      receiptNo: "REC17",
      address: "908 Elm St, City",
      oppCharges: 96,
      gstCharges: 32,
      OTTCharges: 97,
      totalAmount: 225,
      status: "Paid",
      JourneyDate: "2024-01-05",
    },
    {
      lastName: "Miller",
      firstName: "Michael",
      age: 48,
      gender: "Female",
      RegNo: "REG150",
      country: "USA",
      receiptNo: "REC454",
      address: "571 Elm St, City",
      oppCharges: 246,
      gstCharges: 19,
      OTTCharges: 22,
      totalAmount: 287,
      status: "Paid",
      JourneyDate: "2023-04-04",
    },
    {
      lastName: "Davis",
      firstName: "John",
      age: 28,
      gender: "Female",
      RegNo: "REG811",
      country: "USA",
      receiptNo: "REC344",
      address: "566 Elm St, City",
      oppCharges: 65,
      gstCharges: 24,
      OTTCharges: 56,
      totalAmount: 145,
      status: "Cancelled",
      JourneyDate: "2024-06-20",
    },
    {
      lastName: "Williams",
      firstName: "William",
      age: 42,
      gender: "Male",
      RegNo: "REG723",
      country: "India",
      receiptNo: "REC89",
      address: "468 Elm St, City",
      oppCharges: 170,
      gstCharges: 11,
      OTTCharges: 93,
      totalAmount: 274,
      status: "Cancelled",
      JourneyDate: "2024-05-22",
    },
    {
      lastName: "Miller",
      firstName: "William",
      age: 27,
      gender: "Male",
      RegNo: "REG732",
      country: "USA",
      receiptNo: "REC13",
      address: "933 Elm St, City",
      oppCharges: 128,
      gstCharges: 12,
      OTTCharges: 80,
      totalAmount: 220,
      status: "Pending",
      JourneyDate: "2023-07-10",
    },
    {
      lastName: "Miller",
      firstName: "Richard",
      age: 67,
      gender: "Female",
      RegNo: "REG517",
      country: "Canada",
      receiptNo: "REC863",
      address: "158 Elm St, City",
      oppCharges: 180,
      gstCharges: 14,
      OTTCharges: 10,
      totalAmount: 204,
      status: "Pending",
      JourneyDate: "2024-05-16",
    },
    {
      lastName: "Johnson",
      firstName: "John",
      age: 24,
      gender: "Male",
      RegNo: "REG446",
      country: "Australia",
      receiptNo: "REC40",
      address: "76 Elm St, City",
      oppCharges: 246,
      gstCharges: 15,
      OTTCharges: 79,
      totalAmount: 340,
      status: "Pending",
      JourneyDate: "2024-03-01",
    },
    {
      lastName: "Johnson",
      firstName: "David",
      age: 56,
      gender: "Female",
      RegNo: "REG367",
      country: "USA",
      receiptNo: "REC149",
      address: "382 Elm St, City",
      oppCharges: 204,
      gstCharges: 22,
      OTTCharges: 78,
      totalAmount: 304,
      status: "Paid",
      JourneyDate: "2023-12-16",
    },
    {
      lastName: "Brown",
      firstName: "Robert",
      age: 35,
      gender: "Female",
      RegNo: "REG892",
      country: "USA",
      receiptNo: "REC842",
      address: "699 Elm St, City",
      oppCharges: 249,
      gstCharges: 37,
      OTTCharges: 2,
      totalAmount: 288,
      status: "Paid",
      JourneyDate: "2023-03-07",
    },
    {
      lastName: "Brown",
      firstName: "Michael",
      age: 64,
      gender: "Male",
      RegNo: "REG939",
      country: "Australia",
      receiptNo: "REC715",
      address: "704 Elm St, City",
      oppCharges: 60,
      gstCharges: 30,
      OTTCharges: 9,
      totalAmount: 99,
      status: "Pending",
      JourneyDate: "2024-03-04",
    },
    {
      lastName: "Smith",
      firstName: "John",
      age: 63,
      gender: "Female",
      RegNo: "REG404",
      country: "Canada",
      receiptNo: "REC644",
      address: "5 Elm St, City",
      oppCharges: 184,
      gstCharges: 35,
      OTTCharges: 76,
      totalAmount: 295,
      status: "Paid",
      JourneyDate: "2023-08-08",
    },
    {
      lastName: "Johnson",
      firstName: "Michael",
      age: 66,
      gender: "Male",
      RegNo: "REG200",
      country: "Canada",
      receiptNo: "REC259",
      address: "349 Elm St, City",
      oppCharges: 155,
      gstCharges: 15,
      OTTCharges: 16,
      totalAmount: 186,
      status: "Pending",
      JourneyDate: "2023-07-09",
    },
    {
      lastName: "Brown",
      firstName: "Michael",
      age: 22,
      gender: "Female",
      RegNo: "REG714",
      country: "USA",
      receiptNo: "REC33",
      address: "51 Elm St, City",
      oppCharges: 242,
      gstCharges: 15,
      OTTCharges: 90,
      totalAmount: 347,
      status: "Pending",
      JourneyDate: "2023-04-12",
    },
    {
      lastName: "Miller",
      firstName: "John",
      age: 23,
      gender: "Male",
      RegNo: "REG546",
      country: "India",
      receiptNo: "REC554",
      address: "935 Elm St, City",
      oppCharges: 158,
      gstCharges: 27,
      OTTCharges: 48,
      totalAmount: 233,
      status: "Pending",
      JourneyDate: "2023-08-10",
    },
    {
      lastName: "Davis",
      firstName: "William",
      age: 38,
      gender: "Female",
      RegNo: "REG579",
      country: "Australia",
      receiptNo: "REC73",
      address: "774 Elm St, City",
      oppCharges: 94,
      gstCharges: 22,
      OTTCharges: 86,
      totalAmount: 202,
      status: "Pending",
      JourneyDate: "2023-08-22",
    },
    {
      lastName: "Johnson",
      firstName: "Robert",
      age: 32,
      gender: "Male",
      RegNo: "REG825",
      country: "Australia",
      receiptNo: "REC688",
      address: "656 Elm St, City",
      oppCharges: 241,
      gstCharges: 14,
      OTTCharges: 34,
      totalAmount: 289,
      status: "Cancelled",
      JourneyDate: "2024-05-20",
    },
    {
      lastName: "Jones",
      firstName: "Richard",
      age: 52,
      gender: "Male",
      RegNo: "REG934",
      country: "Canada",
      receiptNo: "REC18",
      address: "287 Elm St, City",
      oppCharges: 218,
      gstCharges: 35,
      OTTCharges: 98,
      totalAmount: 351,
      status: "Pending",
      JourneyDate: "2024-01-06",
    },
    {
      lastName: "Johnson",
      firstName: "John",
      age: 20,
      gender: "Female",
      RegNo: "REG135",
      country: "Canada",
      receiptNo: "REC918",
      address: "839 Elm St, City",
      oppCharges: 167,
      gstCharges: 37,
      OTTCharges: 46,
      totalAmount: 250,
      status: "Paid",
      JourneyDate: "2023-09-12",
    },
    {
      lastName: "Johnson",
      firstName: "James",
      age: 65,
      gender: "Female",
      RegNo: "REG367",
      country: "USA",
      receiptNo: "REC352",
      address: "294 Elm St, City",
      oppCharges: 234,
      gstCharges: 24,
      OTTCharges: 78,
      totalAmount: 336,
      status: "Paid",
      JourneyDate: "2023-01-21",
    },
    {
      lastName: "Johnson",
      firstName: "John",
      age: 54,
      gender: "Female",
      RegNo: "REG160",
      country: "Australia",
      receiptNo: "REC12",
      address: "157 Elm St, City",
      oppCharges: 94,
      gstCharges: 34,
      OTTCharges: 68,
      totalAmount: 196,
      status: "Cancelled",
      JourneyDate: "2023-06-21",
    },
    {
      lastName: "Miller",
      firstName: "Michael",
      age: 61,
      gender: "Female",
      RegNo: "REG86",
      country: "Canada",
      receiptNo: "REC614",
      address: "577 Elm St, City",
      oppCharges: 124,
      gstCharges: 27,
      OTTCharges: 22,
      totalAmount: 173,
      status: "Cancelled",
      JourneyDate: "2023-02-14",
    },
    {
      lastName: "Williams",
      firstName: "William",
      age: 37,
      gender: "Male",
      RegNo: "REG764",
      country: "UK",
      receiptNo: "REC766",
      address: "936 Elm St, City",
      oppCharges: 201,
      gstCharges: 30,
      OTTCharges: 51,
      totalAmount: 282,
      status: "Cancelled",
      JourneyDate: "2023-06-03",
    },
    {
      lastName: "Johnson",
      firstName: "John",
      age: 27,
      gender: "Female",
      RegNo: "REG836",
      country: "UK",
      receiptNo: "REC153",
      address: "994 Elm St, City",
      oppCharges: 169,
      gstCharges: 32,
      OTTCharges: 30,
      totalAmount: 231,
      status: "Paid",
      JourneyDate: "2024-03-25",
    },
    {
      lastName: "Smith",
      firstName: "David",
      age: 69,
      gender: "Male",
      RegNo: "REG859",
      country: "USA",
      receiptNo: "REC809",
      address: "596 Elm St, City",
      oppCharges: 125,
      gstCharges: 22,
      OTTCharges: 64,
      totalAmount: 211,
      status: "Pending",
      JourneyDate: "2024-04-15",
    },
    {
      lastName: "Smith",
      firstName: "Michael",
      age: 24,
      gender: "Female",
      RegNo: "REG378",
      country: "India",
      receiptNo: "REC387",
      address: "363 Elm St, City",
      oppCharges: 88,
      gstCharges: 30,
      OTTCharges: 11,
      totalAmount: 129,
      status: "Cancelled",
      JourneyDate: "2024-04-11",
    },
    {
      lastName: "Davis",
      firstName: "James",
      age: 61,
      gender: "Female",
      RegNo: "REG190",
      country: "India",
      receiptNo: "REC912",
      address: "315 Elm St, City",
      oppCharges: 237,
      gstCharges: 30,
      OTTCharges: 90,
      totalAmount: 357,
      status: "Cancelled",
      JourneyDate: "2023-04-26",
    },
    {
      lastName: "Davis",
      firstName: "Robert",
      age: 22,
      gender: "Male",
      RegNo: "REG375",
      country: "USA",
      receiptNo: "REC776",
      address: "164 Elm St, City",
      oppCharges: 216,
      gstCharges: 14,
      OTTCharges: 11,
      totalAmount: 241,
      status: "Paid",
      JourneyDate: "2024-02-13",
    },
    {
      lastName: "Williams",
      firstName: "James",
      age: 43,
      gender: "Male",
      RegNo: "REG283",
      country: "UK",
      receiptNo: "REC269",
      address: "285 Elm St, City",
      oppCharges: 167,
      gstCharges: 25,
      OTTCharges: 66,
      totalAmount: 258,
      status: "Cancelled",
      JourneyDate: "2024-03-11",
    },
    {
      lastName: "Miller",
      firstName: "Richard",
      age: 25,
      gender: "Male",
      RegNo: "REG252",
      country: "India",
      receiptNo: "REC153",
      address: "248 Elm St, City",
      oppCharges: 147,
      gstCharges: 21,
      OTTCharges: 75,
      totalAmount: 243,
      status: "Paid",
      JourneyDate: "2023-08-13",
    },
    {
      lastName: "Brown",
      firstName: "Robert",
      age: 29,
      gender: "Female",
      RegNo: "REG182",
      country: "USA",
      receiptNo: "REC269",
      address: "853 Elm St, City",
      oppCharges: 145,
      gstCharges: 15,
      OTTCharges: 17,
      totalAmount: 177,
      status: "Paid",
      JourneyDate: "2024-07-07",
    },
    {
      lastName: "Davis",
      firstName: "James",
      age: 26,
      gender: "Female",
      RegNo: "REG569",
      country: "Australia",
      receiptNo: "REC842",
      address: "572 Elm St, City",
      oppCharges: 247,
      gstCharges: 33,
      OTTCharges: 74,
      totalAmount: 354,
      status: "Paid",
      JourneyDate: "2023-04-13",
    },
    {
      lastName: "Miller",
      firstName: "Robert",
      age: 61,
      gender: "Male",
      RegNo: "REG974",
      country: "Australia",
      receiptNo: "REC978",
      address: "637 Elm St, City",
      oppCharges: 246,
      gstCharges: 19,
      OTTCharges: 92,
      totalAmount: 357,
      status: "Pending",
      JourneyDate: "2024-05-08",
    },
    {
      lastName: "Miller",
      firstName: "David",
      age: 39,
      gender: "Female",
      RegNo: "REG723",
      country: "UK",
      receiptNo: "REC522",
      address: "789 Elm St, City",
      oppCharges: 194,
      gstCharges: 11,
      OTTCharges: 24,
      totalAmount: 229,
      status: "Pending",
      JourneyDate: "2024-07-09",
    },
    {
      lastName: "Smith",
      firstName: "John",
      age: 36,
      gender: "Male",
      RegNo: "REG665",
      country: "Canada",
      receiptNo: "REC91",
      address: "585 Elm St, City",
      oppCharges: 185,
      gstCharges: 18,
      OTTCharges: 21,
      totalAmount: 224,
      status: "Pending",
      JourneyDate: "2024-07-01",
    },
    {
      lastName: "Johnson",
      firstName: "Robert",
      age: 30,
      gender: "Male",
      RegNo: "REG498",
      country: "India",
      receiptNo: "REC165",
      address: "964 Elm St, City",
      oppCharges: 112,
      gstCharges: 22,
      OTTCharges: 63,
      totalAmount: 197,
      status: "Cancelled",
      JourneyDate: "2023-12-06",
    },
    {
      lastName: "Miller",
      firstName: "James",
      age: 56,
      gender: "Female",
      RegNo: "REG397",
      country: "Australia",
      receiptNo: "REC752",
      address: "980 Elm St, City",
      oppCharges: 91,
      gstCharges: 32,
      OTTCharges: 36,
      totalAmount: 159,
      status: "Cancelled",
      JourneyDate: "2023-11-18",
    },
    {
      lastName: "Johnson",
      firstName: "David",
      age: 21,
      gender: "Male",
      RegNo: "REG729",
      country: "USA",
      receiptNo: "REC864",
      address: "958 Elm St, City",
      oppCharges: 52,
      gstCharges: 35,
      OTTCharges: 85,
      totalAmount: 172,
      status: "Pending",
      JourneyDate: "2023-09-03",
    },
    {
      lastName: "Miller",
      firstName: "James",
      age: 40,
      gender: "Female",
      RegNo: "REG579",
      country: "Canada",
      receiptNo: "REC895",
      address: "493 Elm St, City",
      oppCharges: 237,
      gstCharges: 28,
      OTTCharges: 29,
      totalAmount: 294,
      status: "Paid",
      JourneyDate: "2023-03-30",
    },
    {
      lastName: "Miller",
      firstName: "David",
      age: 30,
      gender: "Female",
      RegNo: "REG695",
      country: "Australia",
      receiptNo: "REC381",
      address: "107 Elm St, City",
      oppCharges: 215,
      gstCharges: 30,
      OTTCharges: 56,
      totalAmount: 301,
      status: "Pending",
      JourneyDate: "2024-05-11",
    },
    {
      lastName: "Davis",
      firstName: "David",
      age: 38,
      gender: "Male",
      RegNo: "REG227",
      country: "USA",
      receiptNo: "REC498",
      address: "751 Elm St, City",
      oppCharges: 184,
      gstCharges: 17,
      OTTCharges: 33,
      totalAmount: 234,
      status: "Pending",
      JourneyDate: "2023-08-27",
    },
    {
      lastName: "Miller",
      firstName: "James",
      age: 35,
      gender: "Male",
      RegNo: "REG562",
      country: "Australia",
      receiptNo: "REC338",
      address: "445 Elm St, City",
      oppCharges: 149,
      gstCharges: 19,
      OTTCharges: 94,
      totalAmount: 262,
      status: "Paid",
      JourneyDate: "2023-07-12",
    },
    {
      lastName: "Davis",
      firstName: "John",
      age: 48,
      gender: "Female",
      RegNo: "REG769",
      country: "Australia",
      receiptNo: "REC61",
      address: "360 Elm St, City",
      oppCharges: 123,
      gstCharges: 33,
      OTTCharges: 70,
      totalAmount: 226,
      status: "Paid",
      JourneyDate: "2023-03-21",
    },
    {
      lastName: "Smith",
      firstName: "John",
      age: 68,
      gender: "Male",
      RegNo: "REG853",
      country: "Australia",
      receiptNo: "REC765",
      address: "244 Elm St, City",
      oppCharges: 221,
      gstCharges: 25,
      OTTCharges: 56,
      totalAmount: 302,
      status: "Cancelled",
      JourneyDate: "2023-04-04",
    },
    {
      lastName: "Jones",
      firstName: "Robert",
      age: 65,
      gender: "Female",
      RegNo: "REG900",
      country: "Canada",
      receiptNo: "REC840",
      address: "259 Elm St, City",
      oppCharges: 168,
      gstCharges: 14,
      OTTCharges: 83,
      totalAmount: 265,
      status: "Pending",
      JourneyDate: "2023-07-02",
    },
    {
      lastName: "Smith",
      firstName: "Robert",
      age: 45,
      gender: "Male",
      RegNo: "REG806",
      country: "Australia",
      receiptNo: "REC356",
      address: "943 Elm St, City",
      oppCharges: 199,
      gstCharges: 30,
      OTTCharges: 67,
      totalAmount: 296,
      status: "Paid",
      JourneyDate: "2024-07-01",
    },
    {
      lastName: "Brown",
      firstName: "Robert",
      age: 60,
      gender: "Female",
      RegNo: "REG411",
      country: "UK",
      receiptNo: "REC869",
      address: "891 Elm St, City",
      oppCharges: 150,
      gstCharges: 23,
      OTTCharges: 76,
      totalAmount: 249,
      status: "Cancelled",
      JourneyDate: "2023-10-29",
    },
    {
      lastName: "Davis",
      firstName: "Richard",
      age: 68,
      gender: "Female",
      RegNo: "REG266",
      country: "India",
      receiptNo: "REC645",
      address: "794 Elm St, City",
      oppCharges: 54,
      gstCharges: 38,
      OTTCharges: 38,
      totalAmount: 130,
      status: "Paid",
      JourneyDate: "2023-04-30",
    },
    {
      lastName: "Smith",
      firstName: "William",
      age: 23,
      gender: "Female",
      RegNo: "REG866",
      country: "Australia",
      receiptNo: "REC995",
      address: "280 Elm St, City",
      oppCharges: 205,
      gstCharges: 31,
      OTTCharges: 58,
      totalAmount: 294,
      status: "Pending",
      JourneyDate: "2023-01-04",
    },
    {
      lastName: "Davis",
      firstName: "John",
      age: 52,
      gender: "Female",
      RegNo: "REG49",
      country: "USA",
      receiptNo: "REC705",
      address: "750 Elm St, City",
      oppCharges: 100,
      gstCharges: 30,
      OTTCharges: 73,
      totalAmount: 203,
      status: "Pending",
      JourneyDate: "2023-09-28",
    },
    {
      lastName: "Johnson",
      firstName: "James",
      age: 38,
      gender: "Female",
      RegNo: "REG173",
      country: "Canada",
      receiptNo: "REC79",
      address: "613 Elm St, City",
      oppCharges: 129,
      gstCharges: 27,
      OTTCharges: 50,
      totalAmount: 206,
      status: "Cancelled",
      JourneyDate: "2024-06-03",
    },
    {
      lastName: "Miller",
      firstName: "David",
      age: 58,
      gender: "Female",
      RegNo: "REG718",
      country: "Australia",
      receiptNo: "REC654",
      address: "883 Elm St, City",
      oppCharges: 108,
      gstCharges: 12,
      OTTCharges: 8,
      totalAmount: 128,
      status: "Paid",
      JourneyDate: "2023-03-12",
    },
    {
      lastName: "Miller",
      firstName: "David",
      age: 45,
      gender: "Female",
      RegNo: "REG231",
      country: "UK",
      receiptNo: "REC622",
      address: "487 Elm St, City",
      oppCharges: 173,
      gstCharges: 14,
      OTTCharges: 84,
      totalAmount: 271,
      status: "Cancelled",
      JourneyDate: "2023-04-11",
    },
    {
      lastName: "Williams",
      firstName: "James",
      age: 69,
      gender: "Female",
      RegNo: "REG144",
      country: "UK",
      receiptNo: "REC688",
      address: "39 Elm St, City",
      oppCharges: 211,
      gstCharges: 20,
      OTTCharges: 91,
      totalAmount: 322,
      status: "Paid",
      JourneyDate: "2023-01-27",
    },
    {
      lastName: "Davis",
      firstName: "David",
      age: 36,
      gender: "Male",
      RegNo: "REG612",
      country: "USA",
      receiptNo: "REC481",
      address: "952 Elm St, City",
      oppCharges: 218,
      gstCharges: 16,
      OTTCharges: 94,
      totalAmount: 328,
      status: "Cancelled",
      JourneyDate: "2023-12-06",
    },
    {
      lastName: "Miller",
      firstName: "Michael",
      age: 61,
      gender: "Male",
      RegNo: "REG240",
      country: "Canada",
      receiptNo: "REC538",
      address: "427 Elm St, City",
      oppCharges: 217,
      gstCharges: 20,
      OTTCharges: 88,
      totalAmount: 325,
      status: "Paid",
      JourneyDate: "2023-03-25",
    },
    {
      lastName: "Brown",
      firstName: "Michael",
      age: 35,
      gender: "Male",
      RegNo: "REG883",
      country: "UK",
      receiptNo: "REC956",
      address: "457 Elm St, City",
      oppCharges: 136,
      gstCharges: 34,
      OTTCharges: 88,
      totalAmount: 258,
      status: "Cancelled",
      JourneyDate: "2023-10-24",
    },
    {
      lastName: "Davis",
      firstName: "David",
      age: 67,
      gender: "Female",
      RegNo: "REG835",
      country: "India",
      receiptNo: "REC291",
      address: "778 Elm St, City",
      oppCharges: 84,
      gstCharges: 25,
      OTTCharges: 48,
      totalAmount: 157,
      status: "Paid",
      JourneyDate: "2024-03-06",
    },
    {
      lastName: "Smith",
      firstName: "David",
      age: 20,
      gender: "Male",
      RegNo: "REG890",
      country: "USA",
      receiptNo: "REC321",
      address: "246 Elm St, City",
      oppCharges: 129,
      gstCharges: 30,
      OTTCharges: 23,
      totalAmount: 182,
      status: "Cancelled",
      JourneyDate: "2023-03-11",
    },
    {
      lastName: "Jones",
      firstName: "Robert",
      age: 33,
      gender: "Female",
      RegNo: "REG703",
      country: "UK",
      receiptNo: "REC561",
      address: "509 Elm St, City",
      oppCharges: 198,
      gstCharges: 35,
      OTTCharges: 63,
      totalAmount: 296,
      status: "Pending",
      JourneyDate: "2023-01-20",
    },
    {
      lastName: "Davis",
      firstName: "John",
      age: 58,
      gender: "Male",
      RegNo: "REG868",
      country: "USA",
      receiptNo: "REC944",
      address: "180 Elm St, City",
      oppCharges: 88,
      gstCharges: 18,
      OTTCharges: 85,
      totalAmount: 191,
      status: "Paid",
      JourneyDate: "2023-11-24",
    },
    {
      lastName: "Johnson",
      firstName: "David",
      age: 56,
      gender: "Female",
      RegNo: "REG919",
      country: "USA",
      receiptNo: "REC418",
      address: "289 Elm St, City",
      oppCharges: 157,
      gstCharges: 29,
      OTTCharges: 70,
      totalAmount: 256,
      status: "Cancelled",
      JourneyDate: "2023-01-27",
    },
    {
      lastName: "Williams",
      firstName: "Robert",
      age: 21,
      gender: "Male",
      RegNo: "REG520",
      country: "Canada",
      receiptNo: "REC58",
      address: "927 Elm St, City",
      oppCharges: 172,
      gstCharges: 32,
      OTTCharges: 79,
      totalAmount: 283,
      status: "Pending",
      JourneyDate: "2023-05-25",
    },
    {
      lastName: "Brown",
      firstName: "Robert",
      age: 24,
      gender: "Male",
      RegNo: "REG699",
      country: "India",
      receiptNo: "REC286",
      address: "98 Elm St, City",
      oppCharges: 111,
      gstCharges: 32,
      OTTCharges: 57,
      totalAmount: 200,
      status: "Pending",
      JourneyDate: "2023-03-08",
    },
    {
      lastName: "Brown",
      firstName: "William",
      age: 64,
      gender: "Male",
      RegNo: "REG280",
      country: "UK",
      receiptNo: "REC897",
      address: "954 Elm St, City",
      oppCharges: 65,
      gstCharges: 13,
      OTTCharges: 41,
      totalAmount: 119,
      status: "Cancelled",
      JourneyDate: "2023-02-04",
    },
    {
      lastName: "Brown",
      firstName: "William",
      age: 51,
      gender: "Male",
      RegNo: "REG424",
      country: "USA",
      receiptNo: "REC441",
      address: "499 Elm St, City",
      oppCharges: 162,
      gstCharges: 17,
      OTTCharges: 50,
      totalAmount: 229,
      status: "Pending",
      JourneyDate: "2023-01-21",
    },
    {
      lastName: "Jones",
      firstName: "William",
      age: 29,
      gender: "Female",
      RegNo: "REG279",
      country: "India",
      receiptNo: "REC605",
      address: "272 Elm St, City",
      oppCharges: 70,
      gstCharges: 33,
      OTTCharges: 19,
      totalAmount: 122,
      status: "Cancelled",
      JourneyDate: "2024-04-10",
    },
    {
      lastName: "Brown",
      firstName: "Robert",
      age: 33,
      gender: "Female",
      RegNo: "REG237",
      country: "India",
      receiptNo: "REC517",
      address: "678 Elm St, City",
      oppCharges: 77,
      gstCharges: 15,
      OTTCharges: 50,
      totalAmount: 142,
      status: "Cancelled",
      JourneyDate: "2023-04-27",
    },
    {
      lastName: "Miller",
      firstName: "James",
      age: 64,
      gender: "Male",
      RegNo: "REG1",
      country: "USA",
      receiptNo: "REC230",
      address: "925 Elm St, City",
      oppCharges: 104,
      gstCharges: 22,
      OTTCharges: 29,
      totalAmount: 155,
      status: "Cancelled",
      JourneyDate: "2023-06-08",
    },
    {
      lastName: "Davis",
      firstName: "William",
      age: 52,
      gender: "Male",
      RegNo: "REG719",
      country: "Canada",
      receiptNo: "REC833",
      address: "984 Elm St, City",
      oppCharges: 149,
      gstCharges: 29,
      OTTCharges: 97,
      totalAmount: 275,
      status: "Pending",
      JourneyDate: "2023-05-07",
    },
    {
      lastName: "Jones",
      firstName: "John",
      age: 33,
      gender: "Male",
      RegNo: "REG119",
      country: "Australia",
      receiptNo: "REC231",
      address: "165 Elm St, City",
      oppCharges: 157,
      gstCharges: 25,
      OTTCharges: 7,
      totalAmount: 189,
      status: "Cancelled",
      JourneyDate: "2023-09-09",
    },
    {
      lastName: "Brown",
      firstName: "Richard",
      age: 52,
      gender: "Male",
      RegNo: "REG320",
      country: "Canada",
      receiptNo: "REC244",
      address: "404 Elm St, City",
      oppCharges: 218,
      gstCharges: 24,
      OTTCharges: 5,
      totalAmount: 247,
      status: "Pending",
      JourneyDate: "2024-04-12",
    },
    {
      lastName: "Smith",
      firstName: "William",
      age: 57,
      gender: "Female",
      RegNo: "REG843",
      country: "UK",
      receiptNo: "REC950",
      address: "147 Elm St, City",
      oppCharges: 245,
      gstCharges: 37,
      OTTCharges: 52,
      totalAmount: 334,
      status: "Paid",
      JourneyDate: "2024-02-23",
    },
    {
      lastName: "Johnson",
      firstName: "James",
      age: 36,
      gender: "Female",
      RegNo: "REG335",
      country: "Canada",
      receiptNo: "REC162",
      address: "915 Elm St, City",
      oppCharges: 136,
      gstCharges: 14,
      OTTCharges: 48,
      totalAmount: 198,
      status: "Cancelled",
      JourneyDate: "2023-12-26",
    },
    {
      lastName: "Johnson",
      firstName: "James",
      age: 41,
      gender: "Female",
      RegNo: "REG562",
      country: "Australia",
      receiptNo: "REC718",
      address: "693 Elm St, City",
      oppCharges: 215,
      gstCharges: 19,
      OTTCharges: 2,
      totalAmount: 236,
      status: "Paid",
      JourneyDate: "2024-01-14",
    },
    {
      lastName: "Jones",
      firstName: "David",
      age: 39,
      gender: "Male",
      RegNo: "REG121",
      country: "UK",
      receiptNo: "REC798",
      address: "62 Elm St, City",
      oppCharges: 149,
      gstCharges: 37,
      OTTCharges: 45,
      totalAmount: 231,
      status: "Cancelled",
      JourneyDate: "2023-06-23",
    },
    {
      lastName: "Davis",
      firstName: "David",
      age: 29,
      gender: "Male",
      RegNo: "REG988",
      country: "Australia",
      receiptNo: "REC356",
      address: "831 Elm St, City",
      oppCharges: 67,
      gstCharges: 20,
      OTTCharges: 16,
      totalAmount: 103,
      status: "Paid",
      JourneyDate: "2023-09-11",
    },
    {
      lastName: "Brown",
      firstName: "Richard",
      age: 39,
      gender: "Female",
      RegNo: "REG902",
      country: "UK",
      receiptNo: "REC390",
      address: "347 Elm St, City",
      oppCharges: 112,
      gstCharges: 22,
      OTTCharges: 31,
      totalAmount: 165,
      status: "Cancelled",
      JourneyDate: "2024-02-10",
    },
    {
      lastName: "Brown",
      firstName: "James",
      age: 53,
      gender: "Male",
      RegNo: "REG659",
      country: "USA",
      receiptNo: "REC447",
      address: "312 Elm St, City",
      oppCharges: 210,
      gstCharges: 37,
      OTTCharges: 34,
      totalAmount: 281,
      status: "Cancelled",
      JourneyDate: "2024-05-31",
    },
    {
      lastName: "Davis",
      firstName: "William",
      age: 41,
      gender: "Female",
      RegNo: "REG913",
      country: "Canada",
      receiptNo: "REC159",
      address: "37 Elm St, City",
      oppCharges: 201,
      gstCharges: 33,
      OTTCharges: 8,
      totalAmount: 242,
      status: "Pending",
      JourneyDate: "2023-11-06",
    },
    {
      lastName: "Johnson",
      firstName: "William",
      age: 59,
      gender: "Female",
      RegNo: "REG428",
      country: "Australia",
      receiptNo: "REC817",
      address: "182 Elm St, City",
      oppCharges: 156,
      gstCharges: 15,
      OTTCharges: 61,
      totalAmount: 232,
      status: "Paid",
      JourneyDate: "2023-06-25",
    },
    {
      lastName: "Jones",
      firstName: "William",
      age: 61,
      gender: "Male",
      RegNo: "REG421",
      country: "UK",
      receiptNo: "REC319",
      address: "140 Elm St, City",
      oppCharges: 138,
      gstCharges: 27,
      OTTCharges: 85,
      totalAmount: 250,
      status: "Paid",
      JourneyDate: "2024-01-06",
    },
    {
      lastName: "Smith",
      firstName: "John",
      age: 39,
      gender: "Male",
      RegNo: "REG370",
      country: "India",
      receiptNo: "REC576",
      address: "733 Elm St, City",
      oppCharges: 171,
      gstCharges: 11,
      OTTCharges: 79,
      totalAmount: 261,
      status: "Paid",
      JourneyDate: "2023-06-09",
    },
    {
      lastName: "Brown",
      firstName: "John",
      age: 57,
      gender: "Female",
      RegNo: "REG564",
      country: "USA",
      receiptNo: "REC745",
      address: "720 Elm St, City",
      oppCharges: 121,
      gstCharges: 37,
      OTTCharges: 52,
      totalAmount: 210,
      status: "Pending",
      JourneyDate: "2024-02-14",
    },
    {
      lastName: "Johnson",
      firstName: "James",
      age: 68,
      gender: "Male",
      RegNo: "REG420",
      country: "USA",
      receiptNo: "REC866",
      address: "894 Elm St, City",
      oppCharges: 212,
      gstCharges: 31,
      OTTCharges: 37,
      totalAmount: 280,
      status: "Paid",
      JourneyDate: "2023-01-21",
    },
    {
      lastName: "Brown",
      firstName: "Michael",
      age: 67,
      gender: "Female",
      RegNo: "REG142",
      country: "Canada",
      receiptNo: "REC136",
      address: "202 Elm St, City",
      oppCharges: 98,
      gstCharges: 37,
      OTTCharges: 4,
      totalAmount: 139,
      status: "Pending",
      JourneyDate: "2024-06-03",
    },
    {
      lastName: "Davis",
      firstName: "Michael",
      age: 39,
      gender: "Female",
      RegNo: "REG709",
      country: "UK",
      receiptNo: "REC486",
      address: "22 Elm St, City",
      oppCharges: 132,
      gstCharges: 22,
      OTTCharges: 94,
      totalAmount: 248,
      status: "Pending",
      JourneyDate: "2023-02-17",
    },
    {
      lastName: "Johnson",
      firstName: "John",
      age: 65,
      gender: "Male",
      RegNo: "REG188",
      country: "India",
      receiptNo: "REC447",
      address: "62 Elm St, City",
      oppCharges: 240,
      gstCharges: 28,
      OTTCharges: 26,
      totalAmount: 294,
      status: "Cancelled",
      JourneyDate: "2024-02-12",
    },
    {
      lastName: "Jones",
      firstName: "Robert",
      age: 51,
      gender: "Male",
      RegNo: "REG609",
      country: "UK",
      receiptNo: "REC416",
      address: "319 Elm St, City",
      oppCharges: 230,
      gstCharges: 11,
      OTTCharges: 25,
      totalAmount: 266,
      status: "Cancelled",
      JourneyDate: "2024-04-04",
    },
    {
      lastName: "Jones",
      firstName: "James",
      age: 50,
      gender: "Male",
      RegNo: "REG31",
      country: "Canada",
      receiptNo: "REC797",
      address: "440 Elm St, City",
      oppCharges: 189,
      gstCharges: 20,
      OTTCharges: 45,
      totalAmount: 254,
      status: "Cancelled",
      JourneyDate: "2023-11-25",
    },
    {
      lastName: "Jones",
      firstName: "John",
      age: 63,
      gender: "Female",
      RegNo: "REG985",
      country: "Canada",
      receiptNo: "REC218",
      address: "765 Elm St, City",
      oppCharges: 181,
      gstCharges: 36,
      OTTCharges: 13,
      totalAmount: 230,
      status: "Paid",
      JourneyDate: "2024-01-07",
    },
    {
      lastName: "Smith",
      firstName: "Robert",
      age: 67,
      gender: "Female",
      RegNo: "REG788",
      country: "USA",
      receiptNo: "REC326",
      address: "322 Elm St, City",
      oppCharges: 55,
      gstCharges: 21,
      OTTCharges: 3,
      totalAmount: 79,
      status: "Pending",
      JourneyDate: "2023-03-20",
    },
    {
      lastName: "Jones",
      firstName: "Michael",
      age: 62,
      gender: "Female",
      RegNo: "REG962",
      country: "UK",
      receiptNo: "REC643",
      address: "857 Elm St, City",
      oppCharges: 52,
      gstCharges: 37,
      OTTCharges: 46,
      totalAmount: 135,
      status: "Cancelled",
      JourneyDate: "2023-08-20",
    },
    {
      lastName: "Miller",
      firstName: "Michael",
      age: 59,
      gender: "Male",
      RegNo: "REG500",
      country: "Australia",
      receiptNo: "REC15",
      address: "230 Elm St, City",
      oppCharges: 107,
      gstCharges: 26,
      OTTCharges: 91,
      totalAmount: 224,
      status: "Paid",
      JourneyDate: "2024-05-26",
    },
    {
      lastName: "Williams",
      firstName: "John",
      age: 41,
      gender: "Female",
      RegNo: "REG104",
      country: "UK",
      receiptNo: "REC951",
      address: "68 Elm St, City",
      oppCharges: 99,
      gstCharges: 15,
      OTTCharges: 64,
      totalAmount: 178,
      status: "Cancelled",
      JourneyDate: "2024-05-19",
    },
    {
      lastName: "Johnson",
      firstName: "David",
      age: 42,
      gender: "Male",
      RegNo: "REG773",
      country: "Australia",
      receiptNo: "REC991",
      address: "687 Elm St, City",
      oppCharges: 242,
      gstCharges: 25,
      OTTCharges: 61,
      totalAmount: 328,
      status: "Cancelled",
      JourneyDate: "2023-02-28",
    },
    {
      lastName: "Williams",
      firstName: "James",
      age: 52,
      gender: "Female",
      RegNo: "REG914",
      country: "Canada",
      receiptNo: "REC342",
      address: "897 Elm St, City",
      oppCharges: 151,
      gstCharges: 36,
      OTTCharges: 47,
      totalAmount: 234,
      status: "Paid",
      JourneyDate: "2023-05-29",
    },
    {
      lastName: "Smith",
      firstName: "William",
      age: 29,
      gender: "Male",
      RegNo: "REG475",
      country: "Canada",
      receiptNo: "REC86",
      address: "372 Elm St, City",
      oppCharges: 149,
      gstCharges: 13,
      OTTCharges: 2,
      totalAmount: 164,
      status: "Cancelled",
      JourneyDate: "2024-04-29",
    },
    {
      lastName: "Brown",
      firstName: "David",
      age: 38,
      gender: "Female",
      RegNo: "REG968",
      country: "Canada",
      receiptNo: "REC442",
      address: "88 Elm St, City",
      oppCharges: 111,
      gstCharges: 14,
      OTTCharges: 44,
      totalAmount: 169,
      status: "Paid",
      JourneyDate: "2023-12-25",
    },
    {
      lastName: "Miller",
      firstName: "James",
      age: 52,
      gender: "Female",
      RegNo: "REG175",
      country: "Australia",
      receiptNo: "REC796",
      address: "402 Elm St, City",
      oppCharges: 128,
      gstCharges: 12,
      OTTCharges: 44,
      totalAmount: 184,
      status: "Cancelled",
      JourneyDate: "2023-11-11",
    },
    {
      lastName: "Davis",
      firstName: "Richard",
      age: 42,
      gender: "Female",
      RegNo: "REG141",
      country: "India",
      receiptNo: "REC846",
      address: "967 Elm St, City",
      oppCharges: 134,
      gstCharges: 11,
      OTTCharges: 93,
      totalAmount: 238,
      status: "Pending",
      JourneyDate: "2023-09-16",
    },
    {
      lastName: "Williams",
      firstName: "William",
      age: 29,
      gender: "Female",
      RegNo: "REG122",
      country: "UK",
      receiptNo: "REC844",
      address: "696 Elm St, City",
      oppCharges: 96,
      gstCharges: 14,
      OTTCharges: 69,
      totalAmount: 179,
      status: "Pending",
      JourneyDate: "2024-07-06",
    },
    {
      lastName: "Johnson",
      firstName: "John",
      age: 61,
      gender: "Male",
      RegNo: "REG674",
      country: "USA",
      receiptNo: "REC151",
      address: "831 Elm St, City",
      oppCharges: 114,
      gstCharges: 20,
      OTTCharges: 34,
      totalAmount: 168,
      status: "Cancelled",
      JourneyDate: "2023-08-02",
    },
    {
      lastName: "Williams",
      firstName: "William",
      age: 61,
      gender: "Male",
      RegNo: "REG686",
      country: "Australia",
      receiptNo: "REC388",
      address: "939 Elm St, City",
      oppCharges: 186,
      gstCharges: 13,
      OTTCharges: 37,
      totalAmount: 236,
      status: "Cancelled",
      JourneyDate: "2023-07-05",
    },
    {
      lastName: "Miller",
      firstName: "James",
      age: 48,
      gender: "Male",
      RegNo: "REG342",
      country: "Canada",
      receiptNo: "REC890",
      address: "200 Elm St, City",
      oppCharges: 62,
      gstCharges: 32,
      OTTCharges: 28,
      totalAmount: 122,
      status: "Pending",
      JourneyDate: "2024-02-14",
    },
    {
      lastName: "Miller",
      firstName: "John",
      age: 50,
      gender: "Male",
      RegNo: "REG197",
      country: "Australia",
      receiptNo: "REC746",
      address: "3 Elm St, City",
      oppCharges: 213,
      gstCharges: 26,
      OTTCharges: 51,
      totalAmount: 290,
      status: "Paid",
      JourneyDate: "2023-05-09",
    },
    {
      lastName: "Williams",
      firstName: "John",
      age: 65,
      gender: "Male",
      RegNo: "REG753",
      country: "Australia",
      receiptNo: "REC593",
      address: "299 Elm St, City",
      oppCharges: 209,
      gstCharges: 29,
      OTTCharges: 95,
      totalAmount: 333,
      status: "Cancelled",
      JourneyDate: "2024-06-25",
    },
    {
      lastName: "Miller",
      firstName: "Richard",
      age: 61,
      gender: "Male",
      RegNo: "REG772",
      country: "Australia",
      receiptNo: "REC454",
      address: "166 Elm St, City",
      oppCharges: 127,
      gstCharges: 22,
      OTTCharges: 29,
      totalAmount: 178,
      status: "Pending",
      JourneyDate: "2024-03-24",
    },
    {
      lastName: "Jones",
      firstName: "John",
      age: 53,
      gender: "Female",
      RegNo: "REG853",
      country: "Australia",
      receiptNo: "REC67",
      address: "582 Elm St, City",
      oppCharges: 120,
      gstCharges: 34,
      OTTCharges: 60,
      totalAmount: 214,
      status: "Pending",
      JourneyDate: "2023-12-08",
    },
    {
      lastName: "Johnson",
      firstName: "William",
      age: 30,
      gender: "Female",
      RegNo: "REG805",
      country: "USA",
      receiptNo: "REC535",
      address: "383 Elm St, City",
      oppCharges: 161,
      gstCharges: 20,
      OTTCharges: 88,
      totalAmount: 269,
      status: "Pending",
      JourneyDate: "2023-04-28",
    },
    {
      lastName: "Brown",
      firstName: "Michael",
      age: 58,
      gender: "Male",
      RegNo: "REG68",
      country: "India",
      receiptNo: "REC512",
      address: "809 Elm St, City",
      oppCharges: 222,
      gstCharges: 13,
      OTTCharges: 50,
      totalAmount: 285,
      status: "Pending",
      JourneyDate: "2023-01-23",
    },
    {
      lastName: "Brown",
      firstName: "Richard",
      age: 27,
      gender: "Female",
      RegNo: "REG694",
      country: "Australia",
      receiptNo: "REC355",
      address: "309 Elm St, City",
      oppCharges: 73,
      gstCharges: 26,
      OTTCharges: 90,
      totalAmount: 189,
      status: "Pending",
      JourneyDate: "2024-01-27",
    },
    {
      lastName: "Jones",
      firstName: "David",
      age: 48,
      gender: "Male",
      RegNo: "REG180",
      country: "UK",
      receiptNo: "REC212",
      address: "773 Elm St, City",
      oppCharges: 91,
      gstCharges: 37,
      OTTCharges: 85,
      totalAmount: 213,
      status: "Cancelled",
      JourneyDate: "2023-01-12",
    },
    {
      lastName: "Smith",
      firstName: "Robert",
      age: 41,
      gender: "Female",
      RegNo: "REG980",
      country: "USA",
      receiptNo: "REC284",
      address: "445 Elm St, City",
      oppCharges: 124,
      gstCharges: 17,
      OTTCharges: 92,
      totalAmount: 233,
      status: "Cancelled",
      JourneyDate: "2023-07-06",
    },
    {
      lastName: "Williams",
      firstName: "William",
      age: 23,
      gender: "Male",
      RegNo: "REG522",
      country: "Canada",
      receiptNo: "REC889",
      address: "393 Elm St, City",
      oppCharges: 184,
      gstCharges: 39,
      OTTCharges: 86,
      totalAmount: 309,
      status: "Paid",
      JourneyDate: "2023-04-17",
    },
    {
      lastName: "Jones",
      firstName: "James",
      age: 37,
      gender: "Female",
      RegNo: "REG663",
      country: "India",
      receiptNo: "REC636",
      address: "561 Elm St, City",
      oppCharges: 152,
      gstCharges: 30,
      OTTCharges: 2,
      totalAmount: 184,
      status: "Paid",
      JourneyDate: "2023-06-30",
    },
    {
      lastName: "Davis",
      firstName: "James",
      age: 26,
      gender: "Female",
      RegNo: "REG598",
      country: "Canada",
      receiptNo: "REC636",
      address: "105 Elm St, City",
      oppCharges: 158,
      gstCharges: 16,
      OTTCharges: 39,
      totalAmount: 213,
      status: "Paid",
      JourneyDate: "2023-04-15",
    },
    {
      lastName: "Johnson",
      firstName: "Robert",
      age: 22,
      gender: "Male",
      RegNo: "REG119",
      country: "Canada",
      receiptNo: "REC212",
      address: "264 Elm St, City",
      oppCharges: 201,
      gstCharges: 20,
      OTTCharges: 67,
      totalAmount: 288,
      status: "Cancelled",
      JourneyDate: "2024-04-21",
    },
    {
      lastName: "Williams",
      firstName: "John",
      age: 38,
      gender: "Male",
      RegNo: "REG204",
      country: "USA",
      receiptNo: "REC734",
      address: "881 Elm St, City",
      oppCharges: 181,
      gstCharges: 32,
      OTTCharges: 15,
      totalAmount: 228,
      status: "Paid",
      JourneyDate: "2023-11-15",
    },
    {
      lastName: "Davis",
      firstName: "James",
      age: 47,
      gender: "Male",
      RegNo: "REG369",
      country: "Canada",
      receiptNo: "REC231",
      address: "8 Elm St, City",
      oppCharges: 233,
      gstCharges: 24,
      OTTCharges: 40,
      totalAmount: 297,
      status: "Cancelled",
      JourneyDate: "2023-10-03",
    },
    {
      lastName: "Davis",
      firstName: "John",
      age: 41,
      gender: "Female",
      RegNo: "REG224",
      country: "Canada",
      receiptNo: "REC589",
      address: "961 Elm St, City",
      oppCharges: 80,
      gstCharges: 34,
      OTTCharges: 17,
      totalAmount: 131,
      status: "Cancelled",
      JourneyDate: "2023-10-06",
    },
    {
      lastName: "Brown",
      firstName: "James",
      age: 53,
      gender: "Female",
      RegNo: "REG701",
      country: "USA",
      receiptNo: "REC542",
      address: "135 Elm St, City",
      oppCharges: 179,
      gstCharges: 38,
      OTTCharges: 5,
      totalAmount: 222,
      status: "Paid",
      JourneyDate: "2023-05-22",
    },
    {
      lastName: "Miller",
      firstName: "Michael",
      age: 44,
      gender: "Female",
      RegNo: "REG400",
      country: "USA",
      receiptNo: "REC43",
      address: "784 Elm St, City",
      oppCharges: 245,
      gstCharges: 39,
      OTTCharges: 57,
      totalAmount: 341,
      status: "Paid",
      JourneyDate: "2023-10-31",
    },
    {
      lastName: "Miller",
      firstName: "James",
      age: 39,
      gender: "Male",
      RegNo: "REG41",
      country: "UK",
      receiptNo: "REC91",
      address: "140 Elm St, City",
      oppCharges: 181,
      gstCharges: 30,
      OTTCharges: 90,
      totalAmount: 301,
      status: "Pending",
      JourneyDate: "2023-06-19",
    },
    {
      lastName: "Miller",
      firstName: "Robert",
      age: 40,
      gender: "Male",
      RegNo: "REG470",
      country: "Canada",
      receiptNo: "REC369",
      address: "51 Elm St, City",
      oppCharges: 248,
      gstCharges: 30,
      OTTCharges: 67,
      totalAmount: 345,
      status: "Pending",
      JourneyDate: "2024-03-31",
    },
    {
      lastName: "Williams",
      firstName: "David",
      age: 59,
      gender: "Male",
      RegNo: "REG55",
      country: "India",
      receiptNo: "REC770",
      address: "818 Elm St, City",
      oppCharges: 125,
      gstCharges: 10,
      OTTCharges: 72,
      totalAmount: 207,
      status: "Cancelled",
      JourneyDate: "2024-03-12",
    },
    {
      lastName: "Jones",
      firstName: "James",
      age: 51,
      gender: "Female",
      RegNo: "REG555",
      country: "Australia",
      receiptNo: "REC23",
      address: "637 Elm St, City",
      oppCharges: 146,
      gstCharges: 31,
      OTTCharges: 4,
      totalAmount: 181,
      status: "Pending",
      JourneyDate: "2023-03-04",
    },
    {
      lastName: "Johnson",
      firstName: "James",
      age: 43,
      gender: "Female",
      RegNo: "REG371",
      country: "Australia",
      receiptNo: "REC374",
      address: "204 Elm St, City",
      oppCharges: 241,
      gstCharges: 28,
      OTTCharges: 72,
      totalAmount: 341,
      status: "Cancelled",
      JourneyDate: "2023-11-13",
    },
    {
      lastName: "Davis",
      firstName: "John",
      age: 33,
      gender: "Female",
      RegNo: "REG111",
      country: "USA",
      receiptNo: "REC435",
      address: "979 Elm St, City",
      oppCharges: 174,
      gstCharges: 20,
      OTTCharges: 87,
      totalAmount: 281,
      status: "Pending",
      JourneyDate: "2024-03-13",
    },
    {
      lastName: "Jones",
      firstName: "David",
      age: 69,
      gender: "Female",
      RegNo: "REG633",
      country: "India",
      receiptNo: "REC555",
      address: "970 Elm St, City",
      oppCharges: 215,
      gstCharges: 36,
      OTTCharges: 24,
      totalAmount: 275,
      status: "Paid",
      JourneyDate: "2023-07-29",
    },
    {
      lastName: "Johnson",
      firstName: "Richard",
      age: 63,
      gender: "Male",
      RegNo: "REG336",
      country: "India",
      receiptNo: "REC489",
      address: "951 Elm St, City",
      oppCharges: 169,
      gstCharges: 36,
      OTTCharges: 10,
      totalAmount: 215,
      status: "Paid",
      JourneyDate: "2024-05-05",
    },
    {
      lastName: "Jones",
      firstName: "James",
      age: 44,
      gender: "Male",
      RegNo: "REG136",
      country: "Canada",
      receiptNo: "REC390",
      address: "144 Elm St, City",
      oppCharges: 149,
      gstCharges: 19,
      OTTCharges: 79,
      totalAmount: 247,
      status: "Pending",
      JourneyDate: "2023-12-11",
    },
    {
      lastName: "Brown",
      firstName: "James",
      age: 48,
      gender: "Female",
      RegNo: "REG749",
      country: "Canada",
      receiptNo: "REC719",
      address: "243 Elm St, City",
      oppCharges: 235,
      gstCharges: 38,
      OTTCharges: 51,
      totalAmount: 324,
      status: "Cancelled",
      JourneyDate: "2023-12-20",
    },
    {
      lastName: "Smith",
      firstName: "Michael",
      age: 30,
      gender: "Male",
      RegNo: "REG633",
      country: "USA",
      receiptNo: "REC855",
      address: "279 Elm St, City",
      oppCharges: 187,
      gstCharges: 27,
      OTTCharges: 76,
      totalAmount: 290,
      status: "Paid",
      JourneyDate: "2023-12-13",
    },
    {
      lastName: "Smith",
      firstName: "Michael",
      age: 35,
      gender: "Female",
      RegNo: "REG795",
      country: "UK",
      receiptNo: "REC815",
      address: "207 Elm St, City",
      oppCharges: 97,
      gstCharges: 17,
      OTTCharges: 20,
      totalAmount: 134,
      status: "Cancelled",
      JourneyDate: "2024-01-28",
    },
    {
      lastName: "Brown",
      firstName: "James",
      age: 52,
      gender: "Female",
      RegNo: "REG754",
      country: "India",
      receiptNo: "REC416",
      address: "492 Elm St, City",
      oppCharges: 88,
      gstCharges: 11,
      OTTCharges: 80,
      totalAmount: 179,
      status: "Paid",
      JourneyDate: "2024-04-05",
    },
    {
      lastName: "Brown",
      firstName: "Robert",
      age: 29,
      gender: "Female",
      RegNo: "REG34",
      country: "UK",
      receiptNo: "REC713",
      address: "802 Elm St, City",
      oppCharges: 86,
      gstCharges: 15,
      OTTCharges: 31,
      totalAmount: 132,
      status: "Cancelled",
      JourneyDate: "2024-02-18",
    },
    {
      lastName: "Jones",
      firstName: "David",
      age: 69,
      gender: "Female",
      RegNo: "REG877",
      country: "UK",
      receiptNo: "REC497",
      address: "843 Elm St, City",
      oppCharges: 169,
      gstCharges: 31,
      OTTCharges: 36,
      totalAmount: 236,
      status: "Cancelled",
      JourneyDate: "2023-08-04",
    },
    {
      lastName: "Jones",
      firstName: "Robert",
      age: 59,
      gender: "Male",
      RegNo: "REG381",
      country: "India",
      receiptNo: "REC754",
      address: "442 Elm St, City",
      oppCharges: 236,
      gstCharges: 37,
      OTTCharges: 75,
      totalAmount: 348,
      status: "Pending",
      JourneyDate: "2023-07-29",
    },
    {
      lastName: "Davis",
      firstName: "John",
      age: 43,
      gender: "Male",
      RegNo: "REG139",
      country: "Australia",
      receiptNo: "REC693",
      address: "145 Elm St, City",
      oppCharges: 198,
      gstCharges: 26,
      OTTCharges: 36,
      totalAmount: 260,
      status: "Cancelled",
      JourneyDate: "2024-01-25",
    },
    {
      lastName: "Smith",
      firstName: "John",
      age: 46,
      gender: "Female",
      RegNo: "REG806",
      country: "Australia",
      receiptNo: "REC267",
      address: "83 Elm St, City",
      oppCharges: 199,
      gstCharges: 27,
      OTTCharges: 59,
      totalAmount: 285,
      status: "Cancelled",
      JourneyDate: "2023-09-28",
    },
    {
      lastName: "Davis",
      firstName: "David",
      age: 42,
      gender: "Female",
      RegNo: "REG450",
      country: "Canada",
      receiptNo: "REC599",
      address: "365 Elm St, City",
      oppCharges: 188,
      gstCharges: 33,
      OTTCharges: 27,
      totalAmount: 248,
      status: "Paid",
      JourneyDate: "2024-01-21",
    },
    {
      lastName: "Smith",
      firstName: "William",
      age: 32,
      gender: "Female",
      RegNo: "REG11",
      country: "Australia",
      receiptNo: "REC62",
      address: "679 Elm St, City",
      oppCharges: 248,
      gstCharges: 35,
      OTTCharges: 55,
      totalAmount: 338,
      status: "Pending",
      JourneyDate: "2024-03-09",
    },
    {
      lastName: "Johnson",
      firstName: "William",
      age: 67,
      gender: "Male",
      RegNo: "REG908",
      country: "India",
      receiptNo: "REC39",
      address: "988 Elm St, City",
      oppCharges: 54,
      gstCharges: 35,
      OTTCharges: 66,
      totalAmount: 155,
      status: "Paid",
      JourneyDate: "2024-04-13",
    },
    {
      lastName: "Williams",
      firstName: "James",
      age: 36,
      gender: "Female",
      RegNo: "REG43",
      country: "USA",
      receiptNo: "REC4",
      address: "622 Elm St, City",
      oppCharges: 109,
      gstCharges: 23,
      OTTCharges: 87,
      totalAmount: 219,
      status: "Cancelled",
      JourneyDate: "2024-02-22",
    },
    {
      lastName: "Davis",
      firstName: "John",
      age: 56,
      gender: "Male",
      RegNo: "REG624",
      country: "India",
      receiptNo: "REC168",
      address: "581 Elm St, City",
      oppCharges: 192,
      gstCharges: 22,
      OTTCharges: 78,
      totalAmount: 292,
      status: "Paid",
      JourneyDate: "2023-04-04",
    },
    {
      lastName: "Johnson",
      firstName: "Michael",
      age: 69,
      gender: "Male",
      RegNo: "REG53",
      country: "USA",
      receiptNo: "REC16",
      address: "146 Elm St, City",
      oppCharges: 141,
      gstCharges: 25,
      OTTCharges: 28,
      totalAmount: 194,
      status: "Paid",
      JourneyDate: "2024-06-08",
    },
    {
      lastName: "Johnson",
      firstName: "Michael",
      age: 53,
      gender: "Male",
      RegNo: "REG301",
      country: "UK",
      receiptNo: "REC123",
      address: "180 Elm St, City",
      oppCharges: 153,
      gstCharges: 32,
      OTTCharges: 55,
      totalAmount: 240,
      status: "Cancelled",
      JourneyDate: "2023-09-02",
    },
    {
      lastName: "Jones",
      firstName: "John",
      age: 23,
      gender: "Male",
      RegNo: "REG979",
      country: "Australia",
      receiptNo: "REC758",
      address: "616 Elm St, City",
      oppCharges: 244,
      gstCharges: 28,
      OTTCharges: 25,
      totalAmount: 297,
      status: "Cancelled",
      JourneyDate: "2023-11-08",
    },
    {
      lastName: "Jones",
      firstName: "Robert",
      age: 38,
      gender: "Female",
      RegNo: "REG539",
      country: "Canada",
      receiptNo: "REC884",
      address: "93 Elm St, City",
      oppCharges: 182,
      gstCharges: 16,
      OTTCharges: 99,
      totalAmount: 297,
      status: "Cancelled",
      JourneyDate: "2023-08-22",
    },
    {
      lastName: "Davis",
      firstName: "Richard",
      age: 37,
      gender: "Female",
      RegNo: "REG88",
      country: "UK",
      receiptNo: "REC918",
      address: "676 Elm St, City",
      oppCharges: 102,
      gstCharges: 29,
      OTTCharges: 44,
      totalAmount: 175,
      status: "Cancelled",
      JourneyDate: "2024-06-11",
    },
    {
      lastName: "Brown",
      firstName: "Richard",
      age: 25,
      gender: "Male",
      RegNo: "REG467",
      country: "India",
      receiptNo: "REC782",
      address: "228 Elm St, City",
      oppCharges: 123,
      gstCharges: 33,
      OTTCharges: 80,
      totalAmount: 236,
      status: "Paid",
      JourneyDate: "2023-12-09",
    },
    {
      lastName: "Smith",
      firstName: "Robert",
      age: 33,
      gender: "Female",
      RegNo: "REG848",
      country: "UK",
      receiptNo: "REC278",
      address: "824 Elm St, City",
      oppCharges: 184,
      gstCharges: 17,
      OTTCharges: 71,
      totalAmount: 272,
      status: "Cancelled",
      JourneyDate: "2024-05-31",
    },
    {
      lastName: "Williams",
      firstName: "William",
      age: 48,
      gender: "Male",
      RegNo: "REG310",
      country: "Canada",
      receiptNo: "REC547",
      address: "249 Elm St, City",
      oppCharges: 159,
      gstCharges: 12,
      OTTCharges: 87,
      totalAmount: 258,
      status: "Pending",
      JourneyDate: "2023-02-21",
    },
    {
      lastName: "Smith",
      firstName: "John",
      age: 29,
      gender: "Male",
      RegNo: "REG614",
      country: "Canada",
      receiptNo: "REC806",
      address: "689 Elm St, City",
      oppCharges: 107,
      gstCharges: 14,
      OTTCharges: 4,
      totalAmount: 125,
      status: "Pending",
      JourneyDate: "2024-06-29",
    },
    {
      lastName: "Jones",
      firstName: "Robert",
      age: 49,
      gender: "Male",
      RegNo: "REG807",
      country: "USA",
      receiptNo: "REC300",
      address: "916 Elm St, City",
      oppCharges: 75,
      gstCharges: 34,
      OTTCharges: 23,
      totalAmount: 132,
      status: "Cancelled",
      JourneyDate: "2023-06-17",
    },
    {
      lastName: "Smith",
      firstName: "Richard",
      age: 53,
      gender: "Female",
      RegNo: "REG876",
      country: "USA",
      receiptNo: "REC249",
      address: "392 Elm St, City",
      oppCharges: 65,
      gstCharges: 31,
      OTTCharges: 18,
      totalAmount: 114,
      status: "Paid",
      JourneyDate: "2024-01-12",
    },
    {
      lastName: "Miller",
      firstName: "Michael",
      age: 55,
      gender: "Female",
      RegNo: "REG644",
      country: "UK",
      receiptNo: "REC721",
      address: "437 Elm St, City",
      oppCharges: 105,
      gstCharges: 38,
      OTTCharges: 72,
      totalAmount: 215,
      status: "Paid",
      JourneyDate: "2024-01-03",
    },
    {
      lastName: "Johnson",
      firstName: "David",
      age: 50,
      gender: "Female",
      RegNo: "REG76",
      country: "Australia",
      receiptNo: "REC29",
      address: "355 Elm St, City",
      oppCharges: 145,
      gstCharges: 33,
      OTTCharges: 44,
      totalAmount: 222,
      status: "Pending",
      JourneyDate: "2023-12-06",
    },
    {
      lastName: "Johnson",
      firstName: "Richard",
      age: 28,
      gender: "Female",
      RegNo: "REG892",
      country: "UK",
      receiptNo: "REC246",
      address: "130 Elm St, City",
      oppCharges: 230,
      gstCharges: 15,
      OTTCharges: 80,
      totalAmount: 325,
      status: "Pending",
      JourneyDate: "2023-04-09",
    },
    {
      lastName: "Davis",
      firstName: "Michael",
      age: 65,
      gender: "Male",
      RegNo: "REG637",
      country: "UK",
      receiptNo: "REC129",
      address: "206 Elm St, City",
      oppCharges: 206,
      gstCharges: 17,
      OTTCharges: 15,
      totalAmount: 238,
      status: "Cancelled",
      JourneyDate: "2024-05-29",
    },
    {
      lastName: "Smith",
      firstName: "Robert",
      age: 69,
      gender: "Male",
      RegNo: "REG579",
      country: "India",
      receiptNo: "REC221",
      address: "692 Elm St, City",
      oppCharges: 53,
      gstCharges: 12,
      OTTCharges: 99,
      totalAmount: 164,
      status: "Pending",
      JourneyDate: "2023-12-30",
    },
    {
      lastName: "Brown",
      firstName: "David",
      age: 30,
      gender: "Male",
      RegNo: "REG598",
      country: "UK",
      receiptNo: "REC844",
      address: "553 Elm St, City",
      oppCharges: 197,
      gstCharges: 28,
      OTTCharges: 97,
      totalAmount: 322,
      status: "Cancelled",
      JourneyDate: "2023-10-20",
    },
    {
      lastName: "Miller",
      firstName: "James",
      age: 58,
      gender: "Male",
      RegNo: "REG834",
      country: "Canada",
      receiptNo: "REC636",
      address: "181 Elm St, City",
      oppCharges: 212,
      gstCharges: 26,
      OTTCharges: 84,
      totalAmount: 322,
      status: "Paid",
      JourneyDate: "2023-09-10",
    },
    {
      lastName: "Davis",
      firstName: "Richard",
      age: 50,
      gender: "Female",
      RegNo: "REG121",
      country: "USA",
      receiptNo: "REC769",
      address: "589 Elm St, City",
      oppCharges: 197,
      gstCharges: 36,
      OTTCharges: 35,
      totalAmount: 268,
      status: "Pending",
      JourneyDate: "2024-05-13",
    },
    {
      lastName: "Davis",
      firstName: "Richard",
      age: 66,
      gender: "Female",
      RegNo: "REG542",
      country: "Canada",
      receiptNo: "REC466",
      address: "280 Elm St, City",
      oppCharges: 139,
      gstCharges: 25,
      OTTCharges: 5,
      totalAmount: 169,
      status: "Pending",
      JourneyDate: "2023-08-30",
    },
    {
      lastName: "Miller",
      firstName: "Robert",
      age: 21,
      gender: "Male",
      RegNo: "REG12",
      country: "Australia",
      receiptNo: "REC814",
      address: "65 Elm St, City",
      oppCharges: 244,
      gstCharges: 26,
      OTTCharges: 39,
      totalAmount: 309,
      status: "Cancelled",
      JourneyDate: "2023-08-18",
    },
    {
      lastName: "Johnson",
      firstName: "Richard",
      age: 41,
      gender: "Male",
      RegNo: "REG964",
      country: "UK",
      receiptNo: "REC365",
      address: "11 Elm St, City",
      oppCharges: 203,
      gstCharges: 22,
      OTTCharges: 11,
      totalAmount: 236,
      status: "Paid",
      JourneyDate: "2023-08-24",
    },
    {
      lastName: "Smith",
      firstName: "Robert",
      age: 46,
      gender: "Male",
      RegNo: "REG473",
      country: "Canada",
      receiptNo: "REC802",
      address: "648 Elm St, City",
      oppCharges: 236,
      gstCharges: 29,
      OTTCharges: 9,
      totalAmount: 274,
      status: "Paid",
      JourneyDate: "2023-12-29",
    },
    {
      lastName: "Williams",
      firstName: "James",
      age: 59,
      gender: "Male",
      RegNo: "REG672",
      country: "India",
      receiptNo: "REC422",
      address: "607 Elm St, City",
      oppCharges: 191,
      gstCharges: 33,
      OTTCharges: 72,
      totalAmount: 296,
      status: "Pending",
      JourneyDate: "2023-12-16",
    },
    {
      lastName: "Smith",
      firstName: "Richard",
      age: 28,
      gender: "Female",
      RegNo: "REG220",
      country: "Australia",
      receiptNo: "REC772",
      address: "848 Elm St, City",
      oppCharges: 147,
      gstCharges: 15,
      OTTCharges: 76,
      totalAmount: 238,
      status: "Cancelled",
      JourneyDate: "2023-06-17",
    },
    {
      lastName: "Miller",
      firstName: "William",
      age: 35,
      gender: "Male",
      RegNo: "REG766",
      country: "Canada",
      receiptNo: "REC72",
      address: "828 Elm St, City",
      oppCharges: 191,
      gstCharges: 13,
      OTTCharges: 0,
      totalAmount: 204,
      status: "Cancelled",
      JourneyDate: "2024-06-18",
    },
    {
      lastName: "Smith",
      firstName: "John",
      age: 42,
      gender: "Female",
      RegNo: "REG83",
      country: "USA",
      receiptNo: "REC753",
      address: "63 Elm St, City",
      oppCharges: 197,
      gstCharges: 14,
      OTTCharges: 93,
      totalAmount: 304,
      status: "Pending",
      JourneyDate: "2023-01-23",
    },
    {
      lastName: "Miller",
      firstName: "Michael",
      age: 20,
      gender: "Female",
      RegNo: "REG120",
      country: "USA",
      receiptNo: "REC102",
      address: "147 Elm St, City",
      oppCharges: 207,
      gstCharges: 34,
      OTTCharges: 69,
      totalAmount: 310,
      status: "Cancelled",
      JourneyDate: "2024-06-30",
    },
    {
      lastName: "Jones",
      firstName: "Michael",
      age: 59,
      gender: "Female",
      RegNo: "REG473",
      country: "India",
      receiptNo: "REC537",
      address: "193 Elm St, City",
      oppCharges: 163,
      gstCharges: 16,
      OTTCharges: 68,
      totalAmount: 247,
      status: "Cancelled",
      JourneyDate: "2023-10-07",
    },
    {
      lastName: "Brown",
      firstName: "Michael",
      age: 55,
      gender: "Female",
      RegNo: "REG93",
      country: "Australia",
      receiptNo: "REC921",
      address: "107 Elm St, City",
      oppCharges: 95,
      gstCharges: 38,
      OTTCharges: 93,
      totalAmount: 226,
      status: "Cancelled",
      JourneyDate: "2024-01-03",
    },
    {
      lastName: "Miller",
      firstName: "Michael",
      age: 38,
      gender: "Female",
      RegNo: "REG907",
      country: "USA",
      receiptNo: "REC291",
      address: "322 Elm St, City",
      oppCharges: 230,
      gstCharges: 39,
      OTTCharges: 79,
      totalAmount: 348,
      status: "Pending",
      JourneyDate: "2024-03-09",
    },
    {
      lastName: "Jones",
      firstName: "Michael",
      age: 53,
      gender: "Male",
      RegNo: "REG447",
      country: "UK",
      receiptNo: "REC265",
      address: "198 Elm St, City",
      oppCharges: 213,
      gstCharges: 34,
      OTTCharges: 73,
      totalAmount: 320,
      status: "Cancelled",
      JourneyDate: "2023-10-19",
    },
    {
      lastName: "Davis",
      firstName: "David",
      age: 21,
      gender: "Female",
      RegNo: "REG707",
      country: "India",
      receiptNo: "REC195",
      address: "593 Elm St, City",
      oppCharges: 72,
      gstCharges: 39,
      OTTCharges: 83,
      totalAmount: 194,
      status: "Paid",
      JourneyDate: "2024-04-29",
    },
    {
      lastName: "Miller",
      firstName: "James",
      age: 20,
      gender: "Female",
      RegNo: "REG363",
      country: "India",
      receiptNo: "REC561",
      address: "171 Elm St, City",
      oppCharges: 221,
      gstCharges: 33,
      OTTCharges: 51,
      totalAmount: 305,
      status: "Pending",
      JourneyDate: "2024-07-03",
    },
    {
      lastName: "Miller",
      firstName: "James",
      age: 57,
      gender: "Male",
      RegNo: "REG43",
      country: "Canada",
      receiptNo: "REC865",
      address: "883 Elm St, City",
      oppCharges: 249,
      gstCharges: 25,
      OTTCharges: 56,
      totalAmount: 330,
      status: "Paid",
      JourneyDate: "2023-01-06",
    },
    {
      lastName: "Brown",
      firstName: "John",
      age: 37,
      gender: "Male",
      RegNo: "REG70",
      country: "USA",
      receiptNo: "REC576",
      address: "689 Elm St, City",
      oppCharges: 115,
      gstCharges: 26,
      OTTCharges: 89,
      totalAmount: 230,
      status: "Paid",
      JourneyDate: "2024-06-05",
    },
    {
      lastName: "Jones",
      firstName: "John",
      age: 49,
      gender: "Male",
      RegNo: "REG215",
      country: "USA",
      receiptNo: "REC808",
      address: "449 Elm St, City",
      oppCharges: 158,
      gstCharges: 28,
      OTTCharges: 81,
      totalAmount: 267,
      status: "Paid",
      JourneyDate: "2023-05-11",
    },
    {
      lastName: "Smith",
      firstName: "Robert",
      age: 60,
      gender: "Male",
      RegNo: "REG57",
      country: "USA",
      receiptNo: "REC493",
      address: "61 Elm St, City",
      oppCharges: 50,
      gstCharges: 31,
      OTTCharges: 6,
      totalAmount: 87,
      status: "Cancelled",
      JourneyDate: "2023-01-28",
    },
    {
      lastName: "Brown",
      firstName: "James",
      age: 64,
      gender: "Female",
      RegNo: "REG449",
      country: "India",
      receiptNo: "REC660",
      address: "317 Elm St, City",
      oppCharges: 112,
      gstCharges: 34,
      OTTCharges: 11,
      totalAmount: 157,
      status: "Paid",
      JourneyDate: "2024-03-11",
    },
    {
      lastName: "Williams",
      firstName: "Robert",
      age: 48,
      gender: "Female",
      RegNo: "REG395",
      country: "Canada",
      receiptNo: "REC913",
      address: "553 Elm St, City",
      oppCharges: 58,
      gstCharges: 39,
      OTTCharges: 85,
      totalAmount: 182,
      status: "Paid",
      JourneyDate: "2023-06-29",
    },
    {
      lastName: "Davis",
      firstName: "William",
      age: 45,
      gender: "Female",
      RegNo: "REG708",
      country: "Australia",
      receiptNo: "REC72",
      address: "394 Elm St, City",
      oppCharges: 123,
      gstCharges: 32,
      OTTCharges: 36,
      totalAmount: 191,
      status: "Paid",
      JourneyDate: "2024-02-12",
    },
    {
      lastName: "Williams",
      firstName: "James",
      age: 26,
      gender: "Female",
      RegNo: "REG103",
      country: "India",
      receiptNo: "REC424",
      address: "945 Elm St, City",
      oppCharges: 155,
      gstCharges: 15,
      OTTCharges: 88,
      totalAmount: 258,
      status: "Pending",
      JourneyDate: "2024-01-05",
    },
    {
      lastName: "Davis",
      firstName: "James",
      age: 53,
      gender: "Female",
      RegNo: "REG838",
      country: "Australia",
      receiptNo: "REC696",
      address: "716 Elm St, City",
      oppCharges: 91,
      gstCharges: 19,
      OTTCharges: 65,
      totalAmount: 175,
      status: "Cancelled",
      JourneyDate: "2023-07-10",
    },
    {
      lastName: "Johnson",
      firstName: "Richard",
      age: 52,
      gender: "Female",
      RegNo: "REG989",
      country: "UK",
      receiptNo: "REC269",
      address: "812 Elm St, City",
      oppCharges: 191,
      gstCharges: 18,
      OTTCharges: 95,
      totalAmount: 304,
      status: "Cancelled",
      JourneyDate: "2024-02-18",
    },
    {
      lastName: "Brown",
      firstName: "Richard",
      age: 21,
      gender: "Female",
      RegNo: "REG995",
      country: "USA",
      receiptNo: "REC249",
      address: "609 Elm St, City",
      oppCharges: 213,
      gstCharges: 16,
      OTTCharges: 90,
      totalAmount: 319,
      status: "Pending",
      JourneyDate: "2023-05-25",
    },
    {
      lastName: "Miller",
      firstName: "Michael",
      age: 31,
      gender: "Female",
      RegNo: "REG286",
      country: "Canada",
      receiptNo: "REC113",
      address: "324 Elm St, City",
      oppCharges: 137,
      gstCharges: 23,
      OTTCharges: 88,
      totalAmount: 248,
      status: "Pending",
      JourneyDate: "2023-04-24",
    },
    {
      lastName: "Brown",
      firstName: "Robert",
      age: 64,
      gender: "Male",
      RegNo: "REG95",
      country: "USA",
      receiptNo: "REC151",
      address: "245 Elm St, City",
      oppCharges: 230,
      gstCharges: 23,
      OTTCharges: 11,
      totalAmount: 264,
      status: "Cancelled",
      JourneyDate: "2023-07-07",
    },
    {
      lastName: "Jones",
      firstName: "Richard",
      age: 54,
      gender: "Female",
      RegNo: "REG576",
      country: "Canada",
      receiptNo: "REC585",
      address: "630 Elm St, City",
      oppCharges: 62,
      gstCharges: 26,
      OTTCharges: 56,
      totalAmount: 144,
      status: "Paid",
      JourneyDate: "2023-06-11",
    },
    {
      lastName: "Smith",
      firstName: "James",
      age: 64,
      gender: "Female",
      RegNo: "REG303",
      country: "India",
      receiptNo: "REC30",
      address: "223 Elm St, City",
      oppCharges: 218,
      gstCharges: 33,
      OTTCharges: 83,
      totalAmount: 334,
      status: "Cancelled",
      JourneyDate: "2023-03-13",
    },
    {
      lastName: "Johnson",
      firstName: "John",
      age: 53,
      gender: "Male",
      RegNo: "REG743",
      country: "India",
      receiptNo: "REC712",
      address: "856 Elm St, City",
      oppCharges: 217,
      gstCharges: 39,
      OTTCharges: 2,
      totalAmount: 258,
      status: "Pending",
      JourneyDate: "2024-03-05",
    },
    {
      lastName: "Williams",
      firstName: "William",
      age: 61,
      gender: "Male",
      RegNo: "REG597",
      country: "Canada",
      receiptNo: "REC677",
      address: "993 Elm St, City",
      oppCharges: 153,
      gstCharges: 28,
      OTTCharges: 22,
      totalAmount: 203,
      status: "Paid",
      JourneyDate: "2023-11-17",
    },
    {
      lastName: "Williams",
      firstName: "Michael",
      age: 25,
      gender: "Male",
      RegNo: "REG183",
      country: "UK",
      receiptNo: "REC60",
      address: "700 Elm St, City",
      oppCharges: 119,
      gstCharges: 23,
      OTTCharges: 23,
      totalAmount: 165,
      status: "Pending",
      JourneyDate: "2024-05-28",
    },
    {
      lastName: "Jones",
      firstName: "David",
      age: 31,
      gender: "Male",
      RegNo: "REG730",
      country: "Australia",
      receiptNo: "REC344",
      address: "975 Elm St, City",
      oppCharges: 217,
      gstCharges: 34,
      OTTCharges: 88,
      totalAmount: 339,
      status: "Cancelled",
      JourneyDate: "2023-08-10",
    },
    {
      lastName: "Brown",
      firstName: "Michael",
      age: 50,
      gender: "Male",
      RegNo: "REG165",
      country: "Canada",
      receiptNo: "REC803",
      address: "290 Elm St, City",
      oppCharges: 109,
      gstCharges: 25,
      OTTCharges: 71,
      totalAmount: 205,
      status: "Pending",
      JourneyDate: "2023-11-10",
    },
    {
      lastName: "Smith",
      firstName: "Michael",
      age: 37,
      gender: "Male",
      RegNo: "REG578",
      country: "Canada",
      receiptNo: "REC927",
      address: "273 Elm St, City",
      oppCharges: 146,
      gstCharges: 22,
      OTTCharges: 30,
      totalAmount: 198,
      status: "Paid",
      JourneyDate: "2024-05-03",
    },
    {
      lastName: "Johnson",
      firstName: "James",
      age: 50,
      gender: "Male",
      RegNo: "REG774",
      country: "Australia",
      receiptNo: "REC44",
      address: "493 Elm St, City",
      oppCharges: 111,
      gstCharges: 31,
      OTTCharges: 88,
      totalAmount: 230,
      status: "Paid",
      JourneyDate: "2024-05-25",
    },
    {
      lastName: "Smith",
      firstName: "Robert",
      age: 63,
      gender: "Male",
      RegNo: "REG650",
      country: "India",
      receiptNo: "REC476",
      address: "48 Elm St, City",
      oppCharges: 197,
      gstCharges: 16,
      OTTCharges: 67,
      totalAmount: 280,
      status: "Paid",
      JourneyDate: "2024-06-01",
    },
    {
      lastName: "Jones",
      firstName: "William",
      age: 54,
      gender: "Male",
      RegNo: "REG16",
      country: "USA",
      receiptNo: "REC124",
      address: "476 Elm St, City",
      oppCharges: 242,
      gstCharges: 19,
      OTTCharges: 30,
      totalAmount: 291,
      status: "Paid",
      JourneyDate: "2023-03-06",
    },
    {
      lastName: "Johnson",
      firstName: "Richard",
      age: 27,
      gender: "Female",
      RegNo: "REG607",
      country: "India",
      receiptNo: "REC560",
      address: "725 Elm St, City",
      oppCharges: 185,
      gstCharges: 18,
      OTTCharges: 2,
      totalAmount: 205,
      status: "Cancelled",
      JourneyDate: "2023-05-12",
    },
    {
      lastName: "Miller",
      firstName: "James",
      age: 62,
      gender: "Male",
      RegNo: "REG670",
      country: "India",
      receiptNo: "REC965",
      address: "481 Elm St, City",
      oppCharges: 102,
      gstCharges: 36,
      OTTCharges: 37,
      totalAmount: 175,
      status: "Pending",
      JourneyDate: "2024-03-26",
    },
    {
      lastName: "Miller",
      firstName: "Richard",
      age: 35,
      gender: "Female",
      RegNo: "REG832",
      country: "USA",
      receiptNo: "REC252",
      address: "218 Elm St, City",
      oppCharges: 222,
      gstCharges: 25,
      OTTCharges: 87,
      totalAmount: 334,
      status: "Cancelled",
      JourneyDate: "2023-04-05",
    },
    {
      lastName: "Williams",
      firstName: "Michael",
      age: 47,
      gender: "Male",
      RegNo: "REG20",
      country: "Canada",
      receiptNo: "REC327",
      address: "122 Elm St, City",
      oppCharges: 105,
      gstCharges: 12,
      OTTCharges: 54,
      totalAmount: 171,
      status: "Cancelled",
      JourneyDate: "2024-04-04",
    },
    {
      lastName: "Johnson",
      firstName: "Robert",
      age: 61,
      gender: "Male",
      RegNo: "REG509",
      country: "India",
      receiptNo: "REC121",
      address: "519 Elm St, City",
      oppCharges: 66,
      gstCharges: 24,
      OTTCharges: 10,
      totalAmount: 100,
      status: "Paid",
      JourneyDate: "2024-01-16",
    },
    {
      lastName: "Jones",
      firstName: "William",
      age: 26,
      gender: "Male",
      RegNo: "REG65",
      country: "Australia",
      receiptNo: "REC144",
      address: "709 Elm St, City",
      oppCharges: 198,
      gstCharges: 25,
      OTTCharges: 90,
      totalAmount: 313,
      status: "Pending",
      JourneyDate: "2023-08-31",
    },
    {
      lastName: "Williams",
      firstName: "Robert",
      age: 32,
      gender: "Female",
      RegNo: "REG766",
      country: "UK",
      receiptNo: "REC38",
      address: "22 Elm St, City",
      oppCharges: 247,
      gstCharges: 26,
      OTTCharges: 84,
      totalAmount: 357,
      status: "Pending",
      JourneyDate: "2023-11-04",
    },
    {
      lastName: "Miller",
      firstName: "Michael",
      age: 22,
      gender: "Female",
      RegNo: "REG988",
      country: "Australia",
      receiptNo: "REC99",
      address: "968 Elm St, City",
      oppCharges: 65,
      gstCharges: 35,
      OTTCharges: 2,
      totalAmount: 102,
      status: "Paid",
      JourneyDate: "2023-04-30",
    },
    {
      lastName: "Jones",
      firstName: "Michael",
      age: 58,
      gender: "Male",
      RegNo: "REG109",
      country: "Australia",
      receiptNo: "REC257",
      address: "815 Elm St, City",
      oppCharges: 87,
      gstCharges: 38,
      OTTCharges: 70,
      totalAmount: 195,
      status: "Cancelled",
      JourneyDate: "2023-12-05",
    },
    {
      lastName: "Davis",
      firstName: "John",
      age: 62,
      gender: "Female",
      RegNo: "REG910",
      country: "India",
      receiptNo: "REC151",
      address: "897 Elm St, City",
      oppCharges: 242,
      gstCharges: 27,
      OTTCharges: 25,
      totalAmount: 294,
      status: "Pending",
      JourneyDate: "2024-03-08",
    },
    {
      lastName: "Davis",
      firstName: "James",
      age: 52,
      gender: "Female",
      RegNo: "REG693",
      country: "UK",
      receiptNo: "REC883",
      address: "853 Elm St, City",
      oppCharges: 168,
      gstCharges: 39,
      OTTCharges: 65,
      totalAmount: 272,
      status: "Cancelled",
      JourneyDate: "2023-07-14",
    },
    {
      lastName: "Miller",
      firstName: "David",
      age: 36,
      gender: "Male",
      RegNo: "REG733",
      country: "Canada",
      receiptNo: "REC165",
      address: "552 Elm St, City",
      oppCharges: 137,
      gstCharges: 33,
      OTTCharges: 78,
      totalAmount: 248,
      status: "Paid",
      JourneyDate: "2023-11-25",
    },
    {
      lastName: "Brown",
      firstName: "James",
      age: 28,
      gender: "Female",
      RegNo: "REG746",
      country: "UK",
      receiptNo: "REC59",
      address: "611 Elm St, City",
      oppCharges: 123,
      gstCharges: 27,
      OTTCharges: 60,
      totalAmount: 210,
      status: "Cancelled",
      JourneyDate: "2024-04-17",
    },
    {
      lastName: "Johnson",
      firstName: "David",
      age: 27,
      gender: "Male",
      RegNo: "REG633",
      country: "Australia",
      receiptNo: "REC999",
      address: "850 Elm St, City",
      oppCharges: 83,
      gstCharges: 30,
      OTTCharges: 89,
      totalAmount: 202,
      status: "Paid",
      JourneyDate: "2023-06-25",
    },
    {
      lastName: "Johnson",
      firstName: "James",
      age: 35,
      gender: "Male",
      RegNo: "REG503",
      country: "USA",
      receiptNo: "REC724",
      address: "330 Elm St, City",
      oppCharges: 78,
      gstCharges: 16,
      OTTCharges: 66,
      totalAmount: 160,
      status: "Paid",
      JourneyDate: "2023-03-22",
    },
    {
      lastName: "Miller",
      firstName: "Michael",
      age: 20,
      gender: "Male",
      RegNo: "REG548",
      country: "Canada",
      receiptNo: "REC573",
      address: "837 Elm St, City",
      oppCharges: 220,
      gstCharges: 21,
      OTTCharges: 41,
      totalAmount: 282,
      status: "Paid",
      JourneyDate: "2023-02-19",
    },
    {
      lastName: "Jones",
      firstName: "William",
      age: 30,
      gender: "Female",
      RegNo: "REG777",
      country: "USA",
      receiptNo: "REC599",
      address: "990 Elm St, City",
      oppCharges: 130,
      gstCharges: 32,
      OTTCharges: 14,
      totalAmount: 176,
      status: "Paid",
      JourneyDate: "2024-03-20",
    },
    {
      lastName: "Williams",
      firstName: "John",
      age: 41,
      gender: "Female",
      RegNo: "REG267",
      country: "Australia",
      receiptNo: "REC674",
      address: "511 Elm St, City",
      oppCharges: 247,
      gstCharges: 21,
      OTTCharges: 38,
      totalAmount: 306,
      status: "Paid",
      JourneyDate: "2023-10-20",
    },
    {
      lastName: "Brown",
      firstName: "Michael",
      age: 36,
      gender: "Female",
      RegNo: "REG344",
      country: "UK",
      receiptNo: "REC95",
      address: "590 Elm St, City",
      oppCharges: 108,
      gstCharges: 23,
      OTTCharges: 90,
      totalAmount: 221,
      status: "Paid",
      JourneyDate: "2023-06-29",
    },
    {
      lastName: "Brown",
      firstName: "William",
      age: 60,
      gender: "Female",
      RegNo: "REG664",
      country: "India",
      receiptNo: "REC211",
      address: "16 Elm St, City",
      oppCharges: 78,
      gstCharges: 20,
      OTTCharges: 57,
      totalAmount: 155,
      status: "Paid",
      JourneyDate: "2024-05-11",
    },
    {
      lastName: "Johnson",
      firstName: "William",
      age: 23,
      gender: "Female",
      RegNo: "REG565",
      country: "Canada",
      receiptNo: "REC454",
      address: "269 Elm St, City",
      oppCharges: 233,
      gstCharges: 31,
      OTTCharges: 91,
      totalAmount: 355,
      status: "Cancelled",
      JourneyDate: "2023-08-15",
    },
    {
      lastName: "Smith",
      firstName: "John",
      age: 67,
      gender: "Female",
      RegNo: "REG739",
      country: "Australia",
      receiptNo: "REC412",
      address: "579 Elm St, City",
      oppCharges: 151,
      gstCharges: 29,
      OTTCharges: 47,
      totalAmount: 227,
      status: "Pending",
      JourneyDate: "2023-07-17",
    },
    {
      lastName: "Johnson",
      firstName: "William",
      age: 41,
      gender: "Male",
      RegNo: "REG809",
      country: "UK",
      receiptNo: "REC50",
      address: "421 Elm St, City",
      oppCharges: 215,
      gstCharges: 29,
      OTTCharges: 49,
      totalAmount: 293,
      status: "Pending",
      JourneyDate: "2023-09-05",
    },
    {
      lastName: "Smith",
      firstName: "John",
      age: 46,
      gender: "Male",
      RegNo: "REG475",
      country: "India",
      receiptNo: "REC727",
      address: "975 Elm St, City",
      oppCharges: 109,
      gstCharges: 20,
      OTTCharges: 82,
      totalAmount: 211,
      status: "Paid",
      JourneyDate: "2024-03-01",
    },
    {
      lastName: "Davis",
      firstName: "James",
      age: 26,
      gender: "Female",
      RegNo: "REG925",
      country: "Australia",
      receiptNo: "REC102",
      address: "849 Elm St, City",
      oppCharges: 130,
      gstCharges: 32,
      OTTCharges: 20,
      totalAmount: 182,
      status: "Cancelled",
      JourneyDate: "2023-04-18",
    },
    {
      lastName: "Davis",
      firstName: "Michael",
      age: 56,
      gender: "Male",
      RegNo: "REG468",
      country: "India",
      receiptNo: "REC388",
      address: "814 Elm St, City",
      oppCharges: 236,
      gstCharges: 20,
      OTTCharges: 1,
      totalAmount: 257,
      status: "Paid",
      JourneyDate: "2024-06-30",
    },
    {
      lastName: "Miller",
      firstName: "William",
      age: 36,
      gender: "Male",
      RegNo: "REG429",
      country: "USA",
      receiptNo: "REC682",
      address: "117 Elm St, City",
      oppCharges: 139,
      gstCharges: 19,
      OTTCharges: 1,
      totalAmount: 159,
      status: "Cancelled",
      JourneyDate: "2024-02-28",
    },
    {
      lastName: "Brown",
      firstName: "Richard",
      age: 47,
      gender: "Female",
      RegNo: "REG376",
      country: "Canada",
      receiptNo: "REC658",
      address: "569 Elm St, City",
      oppCharges: 218,
      gstCharges: 26,
      OTTCharges: 17,
      totalAmount: 261,
      status: "Pending",
      JourneyDate: "2023-09-14",
    },
    {
      lastName: "Davis",
      firstName: "William",
      age: 38,
      gender: "Female",
      RegNo: "REG835",
      country: "UK",
      receiptNo: "REC887",
      address: "752 Elm St, City",
      oppCharges: 64,
      gstCharges: 33,
      OTTCharges: 51,
      totalAmount: 148,
      status: "Paid",
      JourneyDate: "2023-10-29",
    },
    {
      lastName: "Jones",
      firstName: "David",
      age: 32,
      gender: "Female",
      RegNo: "REG320",
      country: "India",
      receiptNo: "REC42",
      address: "408 Elm St, City",
      oppCharges: 146,
      gstCharges: 15,
      OTTCharges: 36,
      totalAmount: 197,
      status: "Cancelled",
      JourneyDate: "2023-07-13",
    },
    {
      lastName: "Smith",
      firstName: "Richard",
      age: 35,
      gender: "Female",
      RegNo: "REG451",
      country: "USA",
      receiptNo: "REC594",
      address: "302 Elm St, City",
      oppCharges: 93,
      gstCharges: 37,
      OTTCharges: 56,
      totalAmount: 186,
      status: "Paid",
      JourneyDate: "2023-02-24",
    },
    {
      lastName: "Davis",
      firstName: "William",
      age: 26,
      gender: "Female",
      RegNo: "REG593",
      country: "UK",
      receiptNo: "REC139",
      address: "701 Elm St, City",
      oppCharges: 133,
      gstCharges: 22,
      OTTCharges: 2,
      totalAmount: 157,
      status: "Cancelled",
      JourneyDate: "2024-01-13",
    },
    {
      lastName: "Brown",
      firstName: "James",
      age: 32,
      gender: "Male",
      RegNo: "REG797",
      country: "UK",
      receiptNo: "REC362",
      address: "970 Elm St, City",
      oppCharges: 117,
      gstCharges: 29,
      OTTCharges: 69,
      totalAmount: 215,
      status: "Paid",
      JourneyDate: "2023-01-21",
    },
    {
      lastName: "Miller",
      firstName: "James",
      age: 43,
      gender: "Female",
      RegNo: "REG193",
      country: "USA",
      receiptNo: "REC19",
      address: "299 Elm St, City",
      oppCharges: 106,
      gstCharges: 10,
      OTTCharges: 73,
      totalAmount: 189,
      status: "Pending",
      JourneyDate: "2024-06-22",
    },
    {
      lastName: "Brown",
      firstName: "John",
      age: 46,
      gender: "Male",
      RegNo: "REG851",
      country: "UK",
      receiptNo: "REC743",
      address: "532 Elm St, City",
      oppCharges: 163,
      gstCharges: 21,
      OTTCharges: 41,
      totalAmount: 225,
      status: "Cancelled",
      JourneyDate: "2023-02-27",
    },
    {
      lastName: "Davis",
      firstName: "Michael",
      age: 50,
      gender: "Female",
      RegNo: "REG741",
      country: "USA",
      receiptNo: "REC167",
      address: "553 Elm St, City",
      oppCharges: 221,
      gstCharges: 33,
      OTTCharges: 26,
      totalAmount: 280,
      status: "Pending",
      JourneyDate: "2024-03-10",
    },
    {
      lastName: "Jones",
      firstName: "John",
      age: 45,
      gender: "Female",
      RegNo: "REG291",
      country: "India",
      receiptNo: "REC282",
      address: "271 Elm St, City",
      oppCharges: 120,
      gstCharges: 22,
      OTTCharges: 9,
      totalAmount: 151,
      status: "Cancelled",
      JourneyDate: "2023-08-09",
    },
    {
      lastName: "Williams",
      firstName: "Richard",
      age: 37,
      gender: "Male",
      RegNo: "REG200",
      country: "Australia",
      receiptNo: "REC509",
      address: "866 Elm St, City",
      oppCharges: 203,
      gstCharges: 39,
      OTTCharges: 3,
      totalAmount: 245,
      status: "Cancelled",
      JourneyDate: "2024-03-18",
    },
    {
      lastName: "Williams",
      firstName: "John",
      age: 43,
      gender: "Male",
      RegNo: "REG119",
      country: "Canada",
      receiptNo: "REC100",
      address: "128 Elm St, City",
      oppCharges: 173,
      gstCharges: 13,
      OTTCharges: 53,
      totalAmount: 239,
      status: "Cancelled",
      JourneyDate: "2023-05-20",
    },
    {
      lastName: "Davis",
      firstName: "Richard",
      age: 22,
      gender: "Female",
      RegNo: "REG977",
      country: "UK",
      receiptNo: "REC862",
      address: "428 Elm St, City",
      oppCharges: 243,
      gstCharges: 23,
      OTTCharges: 97,
      totalAmount: 363,
      status: "Paid",
      JourneyDate: "2024-02-29",
    },
    {
      lastName: "Johnson",
      firstName: "Robert",
      age: 39,
      gender: "Female",
      RegNo: "REG305",
      country: "UK",
      receiptNo: "REC741",
      address: "261 Elm St, City",
      oppCharges: 178,
      gstCharges: 33,
      OTTCharges: 20,
      totalAmount: 231,
      status: "Pending",
      JourneyDate: "2024-01-06",
    },
    {
      lastName: "Brown",
      firstName: "Robert",
      age: 36,
      gender: "Male",
      RegNo: "REG639",
      country: "India",
      receiptNo: "REC248",
      address: "705 Elm St, City",
      oppCharges: 121,
      gstCharges: 20,
      OTTCharges: 38,
      totalAmount: 179,
      status: "Paid",
      JourneyDate: "2023-02-25",
    },
    {
      lastName: "Brown",
      firstName: "David",
      age: 32,
      gender: "Female",
      RegNo: "REG530",
      country: "Australia",
      receiptNo: "REC553",
      address: "602 Elm St, City",
      oppCharges: 147,
      gstCharges: 33,
      OTTCharges: 10,
      totalAmount: 190,
      status: "Cancelled",
      JourneyDate: "2024-03-08",
    },
    {
      lastName: "Jones",
      firstName: "David",
      age: 48,
      gender: "Female",
      RegNo: "REG422",
      country: "UK",
      receiptNo: "REC655",
      address: "898 Elm St, City",
      oppCharges: 137,
      gstCharges: 12,
      OTTCharges: 77,
      totalAmount: 226,
      status: "Pending",
      JourneyDate: "2023-04-18",
    },
    {
      lastName: "Smith",
      firstName: "Robert",
      age: 56,
      gender: "Female",
      RegNo: "REG660",
      country: "USA",
      receiptNo: "REC179",
      address: "410 Elm St, City",
      oppCharges: 231,
      gstCharges: 16,
      OTTCharges: 98,
      totalAmount: 345,
      status: "Pending",
      JourneyDate: "2023-07-29",
    },
    {
      lastName: "Johnson",
      firstName: "David",
      age: 41,
      gender: "Female",
      RegNo: "REG810",
      country: "USA",
      receiptNo: "REC805",
      address: "962 Elm St, City",
      oppCharges: 148,
      gstCharges: 21,
      OTTCharges: 58,
      totalAmount: 227,
      status: "Pending",
      JourneyDate: "2023-08-22",
    },
    {
      lastName: "Williams",
      firstName: "William",
      age: 67,
      gender: "Male",
      RegNo: "REG516",
      country: "USA",
      receiptNo: "REC467",
      address: "434 Elm St, City",
      oppCharges: 86,
      gstCharges: 16,
      OTTCharges: 74,
      totalAmount: 176,
      status: "Paid",
      JourneyDate: "2024-03-31",
    },
    {
      lastName: "Johnson",
      firstName: "Richard",
      age: 27,
      gender: "Male",
      RegNo: "REG616",
      country: "Canada",
      receiptNo: "REC717",
      address: "750 Elm St, City",
      oppCharges: 178,
      gstCharges: 32,
      OTTCharges: 47,
      totalAmount: 257,
      status: "Paid",
      JourneyDate: "2023-09-03",
    },
    {
      lastName: "Williams",
      firstName: "David",
      age: 42,
      gender: "Male",
      RegNo: "REG468",
      country: "USA",
      receiptNo: "REC875",
      address: "532 Elm St, City",
      oppCharges: 171,
      gstCharges: 17,
      OTTCharges: 74,
      totalAmount: 262,
      status: "Pending",
      JourneyDate: "2024-02-25",
    },
    {
      lastName: "Brown",
      firstName: "Richard",
      age: 51,
      gender: "Male",
      RegNo: "REG505",
      country: "India",
      receiptNo: "REC324",
      address: "87 Elm St, City",
      oppCharges: 198,
      gstCharges: 31,
      OTTCharges: 6,
      totalAmount: 235,
      status: "Paid",
      JourneyDate: "2023-04-20",
    },
    {
      lastName: "Brown",
      firstName: "John",
      age: 51,
      gender: "Female",
      RegNo: "REG179",
      country: "Canada",
      receiptNo: "REC568",
      address: "445 Elm St, City",
      oppCharges: 63,
      gstCharges: 29,
      OTTCharges: 2,
      totalAmount: 94,
      status: "Cancelled",
      JourneyDate: "2023-11-26",
    },
    {
      lastName: "Smith",
      firstName: "Michael",
      age: 64,
      gender: "Female",
      RegNo: "REG631",
      country: "Australia",
      receiptNo: "REC324",
      address: "439 Elm St, City",
      oppCharges: 196,
      gstCharges: 24,
      OTTCharges: 11,
      totalAmount: 231,
      status: "Paid",
      JourneyDate: "2023-06-09",
    },
    {
      lastName: "Miller",
      firstName: "John",
      age: 68,
      gender: "Male",
      RegNo: "REG130",
      country: "Canada",
      receiptNo: "REC355",
      address: "307 Elm St, City",
      oppCharges: 58,
      gstCharges: 38,
      OTTCharges: 84,
      totalAmount: 180,
      status: "Cancelled",
      JourneyDate: "2023-03-06",
    },
    {
      lastName: "Miller",
      firstName: "James",
      age: 66,
      gender: "Male",
      RegNo: "REG554",
      country: "Canada",
      receiptNo: "REC988",
      address: "726 Elm St, City",
      oppCharges: 244,
      gstCharges: 36,
      OTTCharges: 61,
      totalAmount: 341,
      status: "Pending",
      JourneyDate: "2024-02-24",
    },
    {
      lastName: "Smith",
      firstName: "William",
      age: 55,
      gender: "Male",
      RegNo: "REG241",
      country: "India",
      receiptNo: "REC949",
      address: "147 Elm St, City",
      oppCharges: 124,
      gstCharges: 18,
      OTTCharges: 7,
      totalAmount: 149,
      status: "Pending",
      JourneyDate: "2023-05-28",
    },
    {
      lastName: "Jones",
      firstName: "James",
      age: 28,
      gender: "Male",
      RegNo: "REG717",
      country: "Canada",
      receiptNo: "REC815",
      address: "747 Elm St, City",
      oppCharges: 236,
      gstCharges: 23,
      OTTCharges: 21,
      totalAmount: 280,
      status: "Paid",
      JourneyDate: "2023-08-01",
    },
    {
      lastName: "Smith",
      firstName: "Richard",
      age: 36,
      gender: "Male",
      RegNo: "REG150",
      country: "Australia",
      receiptNo: "REC229",
      address: "70 Elm St, City",
      oppCharges: 104,
      gstCharges: 30,
      OTTCharges: 3,
      totalAmount: 137,
      status: "Paid",
      JourneyDate: "2023-10-26",
    },
    {
      lastName: "Smith",
      firstName: "John",
      age: 50,
      gender: "Female",
      RegNo: "REG169",
      country: "UK",
      receiptNo: "REC676",
      address: "590 Elm St, City",
      oppCharges: 161,
      gstCharges: 30,
      OTTCharges: 87,
      totalAmount: 278,
      status: "Pending",
      JourneyDate: "2023-07-09",
    },
    {
      lastName: "Jones",
      firstName: "James",
      age: 62,
      gender: "Male",
      RegNo: "REG496",
      country: "Canada",
      receiptNo: "REC940",
      address: "172 Elm St, City",
      oppCharges: 169,
      gstCharges: 38,
      OTTCharges: 81,
      totalAmount: 288,
      status: "Pending",
      JourneyDate: "2023-10-13",
    },
    {
      lastName: "Brown",
      firstName: "Richard",
      age: 43,
      gender: "Female",
      RegNo: "REG186",
      country: "Australia",
      receiptNo: "REC176",
      address: "16 Elm St, City",
      oppCharges: 229,
      gstCharges: 15,
      OTTCharges: 89,
      totalAmount: 333,
      status: "Pending",
      JourneyDate: "2023-01-02",
    },
    {
      lastName: "Brown",
      firstName: "James",
      age: 46,
      gender: "Male",
      RegNo: "REG694",
      country: "Australia",
      receiptNo: "REC759",
      address: "85 Elm St, City",
      oppCharges: 205,
      gstCharges: 37,
      OTTCharges: 78,
      totalAmount: 320,
      status: "Cancelled",
      JourneyDate: "2023-05-02",
    },
    {
      lastName: "Brown",
      firstName: "James",
      age: 45,
      gender: "Male",
      RegNo: "REG339",
      country: "Australia",
      receiptNo: "REC546",
      address: "376 Elm St, City",
      oppCharges: 137,
      gstCharges: 19,
      OTTCharges: 22,
      totalAmount: 178,
      status: "Paid",
      JourneyDate: "2024-05-05",
    },
    {
      lastName: "Miller",
      firstName: "William",
      age: 24,
      gender: "Male",
      RegNo: "REG608",
      country: "India",
      receiptNo: "REC164",
      address: "388 Elm St, City",
      oppCharges: 122,
      gstCharges: 38,
      OTTCharges: 3,
      totalAmount: 163,
      status: "Paid",
      JourneyDate: "2023-12-24",
    },
    {
      lastName: "Miller",
      firstName: "James",
      age: 29,
      gender: "Female",
      RegNo: "REG82",
      country: "UK",
      receiptNo: "REC407",
      address: "593 Elm St, City",
      oppCharges: 52,
      gstCharges: 28,
      OTTCharges: 89,
      totalAmount: 169,
      status: "Pending",
      JourneyDate: "2023-02-11",
    },
    {
      lastName: "Davis",
      firstName: "William",
      age: 59,
      gender: "Female",
      RegNo: "REG447",
      country: "UK",
      receiptNo: "REC168",
      address: "436 Elm St, City",
      oppCharges: 116,
      gstCharges: 26,
      OTTCharges: 96,
      totalAmount: 238,
      status: "Paid",
      JourneyDate: "2024-06-01",
    },
    {
      lastName: "Miller",
      firstName: "Robert",
      age: 38,
      gender: "Female",
      RegNo: "REG394",
      country: "UK",
      receiptNo: "REC809",
      address: "406 Elm St, City",
      oppCharges: 87,
      gstCharges: 31,
      OTTCharges: 30,
      totalAmount: 148,
      status: "Paid",
      JourneyDate: "2023-03-10",
    },
    {
      lastName: "Jones",
      firstName: "Richard",
      age: 21,
      gender: "Female",
      RegNo: "REG816",
      country: "UK",
      receiptNo: "REC26",
      address: "509 Elm St, City",
      oppCharges: 88,
      gstCharges: 31,
      OTTCharges: 57,
      totalAmount: 176,
      status: "Paid",
      JourneyDate: "2024-04-12",
    },
    {
      lastName: "Johnson",
      firstName: "David",
      age: 22,
      gender: "Female",
      RegNo: "REG697",
      country: "Australia",
      receiptNo: "REC378",
      address: "877 Elm St, City",
      oppCharges: 89,
      gstCharges: 12,
      OTTCharges: 47,
      totalAmount: 148,
      status: "Paid",
      JourneyDate: "2023-03-13",
    },
    {
      lastName: "Miller",
      firstName: "Robert",
      age: 62,
      gender: "Male",
      RegNo: "REG442",
      country: "India",
      receiptNo: "REC7",
      address: "826 Elm St, City",
      oppCharges: 143,
      gstCharges: 29,
      OTTCharges: 35,
      totalAmount: 207,
      status: "Paid",
      JourneyDate: "2023-04-14",
    },
    {
      lastName: "Johnson",
      firstName: "Robert",
      age: 22,
      gender: "Female",
      RegNo: "REG49",
      country: "Canada",
      receiptNo: "REC854",
      address: "514 Elm St, City",
      oppCharges: 171,
      gstCharges: 21,
      OTTCharges: 56,
      totalAmount: 248,
      status: "Cancelled",
      JourneyDate: "2023-10-29",
    },
    {
      lastName: "Jones",
      firstName: "Michael",
      age: 62,
      gender: "Male",
      RegNo: "REG120",
      country: "UK",
      receiptNo: "REC989",
      address: "437 Elm St, City",
      oppCharges: 158,
      gstCharges: 20,
      OTTCharges: 42,
      totalAmount: 220,
      status: "Pending",
      JourneyDate: "2023-07-23",
    },
    {
      lastName: "Smith",
      firstName: "Robert",
      age: 50,
      gender: "Male",
      RegNo: "REG121",
      country: "Canada",
      receiptNo: "REC985",
      address: "479 Elm St, City",
      oppCharges: 62,
      gstCharges: 23,
      OTTCharges: 54,
      totalAmount: 139,
      status: "Cancelled",
      JourneyDate: "2023-06-19",
    },
    {
      lastName: "Miller",
      firstName: "James",
      age: 31,
      gender: "Male",
      RegNo: "REG469",
      country: "UK",
      receiptNo: "REC893",
      address: "176 Elm St, City",
      oppCharges: 123,
      gstCharges: 20,
      OTTCharges: 9,
      totalAmount: 152,
      status: "Paid",
      JourneyDate: "2024-05-01",
    },
    {
      lastName: "Miller",
      firstName: "Richard",
      age: 55,
      gender: "Male",
      RegNo: "REG788",
      country: "Canada",
      receiptNo: "REC911",
      address: "504 Elm St, City",
      oppCharges: 226,
      gstCharges: 12,
      OTTCharges: 14,
      totalAmount: 252,
      status: "Cancelled",
      JourneyDate: "2024-07-13",
    },
    {
      lastName: "Williams",
      firstName: "David",
      age: 38,
      gender: "Male",
      RegNo: "REG247",
      country: "Canada",
      receiptNo: "REC512",
      address: "526 Elm St, City",
      oppCharges: 129,
      gstCharges: 29,
      OTTCharges: 12,
      totalAmount: 170,
      status: "Cancelled",
      JourneyDate: "2023-08-07",
    },
    {
      lastName: "Jones",
      firstName: "James",
      age: 62,
      gender: "Male",
      RegNo: "REG877",
      country: "Australia",
      receiptNo: "REC283",
      address: "493 Elm St, City",
      oppCharges: 50,
      gstCharges: 33,
      OTTCharges: 81,
      totalAmount: 164,
      status: "Cancelled",
      JourneyDate: "2023-11-04",
    },
    {
      lastName: "Miller",
      firstName: "Richard",
      age: 49,
      gender: "Male",
      RegNo: "REG642",
      country: "India",
      receiptNo: "REC915",
      address: "675 Elm St, City",
      oppCharges: 183,
      gstCharges: 11,
      OTTCharges: 80,
      totalAmount: 274,
      status: "Cancelled",
      JourneyDate: "2023-08-15",
    },
    {
      lastName: "Smith",
      firstName: "David",
      age: 68,
      gender: "Male",
      RegNo: "REG487",
      country: "Australia",
      receiptNo: "REC324",
      address: "710 Elm St, City",
      oppCharges: 212,
      gstCharges: 11,
      OTTCharges: 88,
      totalAmount: 311,
      status: "Pending",
      JourneyDate: "2023-06-16",
    },
    {
      lastName: "Jones",
      firstName: "John",
      age: 44,
      gender: "Female",
      RegNo: "REG758",
      country: "Canada",
      receiptNo: "REC821",
      address: "736 Elm St, City",
      oppCharges: 207,
      gstCharges: 31,
      OTTCharges: 5,
      totalAmount: 243,
      status: "Pending",
      JourneyDate: "2023-02-06",
    },
    {
      lastName: "Johnson",
      firstName: "William",
      age: 43,
      gender: "Female",
      RegNo: "REG803",
      country: "Canada",
      receiptNo: "REC280",
      address: "647 Elm St, City",
      oppCharges: 89,
      gstCharges: 34,
      OTTCharges: 16,
      totalAmount: 139,
      status: "Cancelled",
      JourneyDate: "2023-12-14",
    },
    {
      lastName: "Johnson",
      firstName: "James",
      age: 29,
      gender: "Male",
      RegNo: "REG25",
      country: "UK",
      receiptNo: "REC43",
      address: "709 Elm St, City",
      oppCharges: 216,
      gstCharges: 16,
      OTTCharges: 39,
      totalAmount: 271,
      status: "Paid",
      JourneyDate: "2024-04-13",
    },
    {
      lastName: "Jones",
      firstName: "Richard",
      age: 39,
      gender: "Female",
      RegNo: "REG323",
      country: "Canada",
      receiptNo: "REC301",
      address: "640 Elm St, City",
      oppCharges: 98,
      gstCharges: 34,
      OTTCharges: 99,
      totalAmount: 231,
      status: "Paid",
      JourneyDate: "2023-07-24",
    },
    {
      lastName: "Miller",
      firstName: "Richard",
      age: 25,
      gender: "Female",
      RegNo: "REG558",
      country: "USA",
      receiptNo: "REC796",
      address: "579 Elm St, City",
      oppCharges: 197,
      gstCharges: 17,
      OTTCharges: 40,
      totalAmount: 254,
      status: "Cancelled",
      JourneyDate: "2023-12-13",
    },
    {
      lastName: "Brown",
      firstName: "David",
      age: 42,
      gender: "Male",
      RegNo: "REG980",
      country: "USA",
      receiptNo: "REC769",
      address: "590 Elm St, City",
      oppCharges: 176,
      gstCharges: 15,
      OTTCharges: 23,
      totalAmount: 214,
      status: "Paid",
      JourneyDate: "2023-08-31",
    },
    {
      lastName: "Johnson",
      firstName: "James",
      age: 23,
      gender: "Male",
      RegNo: "REG601",
      country: "Canada",
      receiptNo: "REC68",
      address: "155 Elm St, City",
      oppCharges: 171,
      gstCharges: 33,
      OTTCharges: 28,
      totalAmount: 232,
      status: "Cancelled",
      JourneyDate: "2023-08-01",
    },
    {
      lastName: "Davis",
      firstName: "John",
      age: 32,
      gender: "Female",
      RegNo: "REG15",
      country: "USA",
      receiptNo: "REC455",
      address: "550 Elm St, City",
      oppCharges: 84,
      gstCharges: 34,
      OTTCharges: 75,
      totalAmount: 193,
      status: "Paid",
      JourneyDate: "2023-02-08",
    },
    {
      lastName: "Johnson",
      firstName: "Michael",
      age: 36,
      gender: "Male",
      RegNo: "REG772",
      country: "UK",
      receiptNo: "REC395",
      address: "873 Elm St, City",
      oppCharges: 82,
      gstCharges: 27,
      OTTCharges: 60,
      totalAmount: 169,
      status: "Cancelled",
      JourneyDate: "2024-02-04",
    },
    {
      lastName: "Brown",
      firstName: "Michael",
      age: 29,
      gender: "Male",
      RegNo: "REG682",
      country: "Canada",
      receiptNo: "REC303",
      address: "508 Elm St, City",
      oppCharges: 62,
      gstCharges: 30,
      OTTCharges: 35,
      totalAmount: 127,
      status: "Paid",
      JourneyDate: "2023-02-19",
    },
    {
      lastName: "Jones",
      firstName: "Michael",
      age: 28,
      gender: "Female",
      RegNo: "REG713",
      country: "India",
      receiptNo: "REC286",
      address: "170 Elm St, City",
      oppCharges: 143,
      gstCharges: 34,
      OTTCharges: 9,
      totalAmount: 186,
      status: "Pending",
      JourneyDate: "2023-03-08",
    },
    {
      lastName: "Williams",
      firstName: "Michael",
      age: 31,
      gender: "Female",
      RegNo: "REG263",
      country: "India",
      receiptNo: "REC809",
      address: "58 Elm St, City",
      oppCharges: 242,
      gstCharges: 14,
      OTTCharges: 82,
      totalAmount: 338,
      status: "Cancelled",
      JourneyDate: "2024-04-10",
    },
    {
      lastName: "Davis",
      firstName: "Robert",
      age: 24,
      gender: "Female",
      RegNo: "REG661",
      country: "Australia",
      receiptNo: "REC808",
      address: "265 Elm St, City",
      oppCharges: 99,
      gstCharges: 34,
      OTTCharges: 51,
      totalAmount: 184,
      status: "Pending",
      JourneyDate: "2023-02-28",
    },
    {
      lastName: "Brown",
      firstName: "Richard",
      age: 49,
      gender: "Female",
      RegNo: "REG117",
      country: "Australia",
      receiptNo: "REC219",
      address: "382 Elm St, City",
      oppCharges: 142,
      gstCharges: 14,
      OTTCharges: 77,
      totalAmount: 233,
      status: "Pending",
      JourneyDate: "2023-05-11",
    },
    {
      lastName: "Miller",
      firstName: "David",
      age: 31,
      gender: "Female",
      RegNo: "REG535",
      country: "UK",
      receiptNo: "REC834",
      address: "67 Elm St, City",
      oppCharges: 99,
      gstCharges: 21,
      OTTCharges: 18,
      totalAmount: 138,
      status: "Pending",
      JourneyDate: "2024-01-20",
    },
    {
      lastName: "Brown",
      firstName: "Michael",
      age: 56,
      gender: "Male",
      RegNo: "REG670",
      country: "Canada",
      receiptNo: "REC308",
      address: "769 Elm St, City",
      oppCharges: 202,
      gstCharges: 31,
      OTTCharges: 58,
      totalAmount: 291,
      status: "Paid",
      JourneyDate: "2023-07-10",
    },
    {
      lastName: "Smith",
      firstName: "John",
      age: 53,
      gender: "Female",
      RegNo: "REG762",
      country: "USA",
      receiptNo: "REC371",
      address: "754 Elm St, City",
      oppCharges: 246,
      gstCharges: 14,
      OTTCharges: 84,
      totalAmount: 344,
      status: "Paid",
      JourneyDate: "2024-05-19",
    },
    {
      lastName: "Williams",
      firstName: "Richard",
      age: 49,
      gender: "Female",
      RegNo: "REG637",
      country: "Australia",
      receiptNo: "REC901",
      address: "350 Elm St, City",
      oppCharges: 207,
      gstCharges: 29,
      OTTCharges: 58,
      totalAmount: 294,
      status: "Pending",
      JourneyDate: "2023-09-08",
    },
    {
      lastName: "Davis",
      firstName: "John",
      age: 59,
      gender: "Female",
      RegNo: "REG221",
      country: "Canada",
      receiptNo: "REC754",
      address: "521 Elm St, City",
      oppCharges: 183,
      gstCharges: 28,
      OTTCharges: 66,
      totalAmount: 277,
      status: "Paid",
      JourneyDate: "2023-11-12",
    },
    {
      lastName: "Johnson",
      firstName: "William",
      age: 27,
      gender: "Female",
      RegNo: "REG561",
      country: "USA",
      receiptNo: "REC88",
      address: "316 Elm St, City",
      oppCharges: 51,
      gstCharges: 36,
      OTTCharges: 37,
      totalAmount: 124,
      status: "Paid",
      JourneyDate: "2023-09-10",
    },
    {
      lastName: "Davis",
      firstName: "Michael",
      age: 40,
      gender: "Female",
      RegNo: "REG511",
      country: "India",
      receiptNo: "REC493",
      address: "129 Elm St, City",
      oppCharges: 123,
      gstCharges: 13,
      OTTCharges: 78,
      totalAmount: 214,
      status: "Paid",
      JourneyDate: "2023-04-16",
    },
    {
      lastName: "Johnson",
      firstName: "John",
      age: 37,
      gender: "Male",
      RegNo: "REG849",
      country: "India",
      receiptNo: "REC714",
      address: "613 Elm St, City",
      oppCharges: 156,
      gstCharges: 18,
      OTTCharges: 94,
      totalAmount: 268,
      status: "Pending",
      JourneyDate: "2024-03-27",
    },
    {
      lastName: "Jones",
      firstName: "Richard",
      age: 60,
      gender: "Female",
      RegNo: "REG795",
      country: "India",
      receiptNo: "REC626",
      address: "343 Elm St, City",
      oppCharges: 130,
      gstCharges: 14,
      OTTCharges: 46,
      totalAmount: 190,
      status: "Cancelled",
      JourneyDate: "2023-02-15",
    },
    {
      lastName: "Miller",
      firstName: "James",
      age: 57,
      gender: "Male",
      RegNo: "REG177",
      country: "Canada",
      receiptNo: "REC276",
      address: "508 Elm St, City",
      oppCharges: 192,
      gstCharges: 20,
      OTTCharges: 50,
      totalAmount: 262,
      status: "Cancelled",
      JourneyDate: "2024-01-11",
    },
    {
      lastName: "Johnson",
      firstName: "John",
      age: 26,
      gender: "Female",
      RegNo: "REG191",
      country: "Canada",
      receiptNo: "REC719",
      address: "260 Elm St, City",
      oppCharges: 134,
      gstCharges: 12,
      OTTCharges: 14,
      totalAmount: 160,
      status: "Pending",
      JourneyDate: "2024-02-06",
    },
    {
      lastName: "Smith",
      firstName: "William",
      age: 54,
      gender: "Female",
      RegNo: "REG975",
      country: "Australia",
      receiptNo: "REC759",
      address: "245 Elm St, City",
      oppCharges: 106,
      gstCharges: 13,
      OTTCharges: 83,
      totalAmount: 202,
      status: "Pending",
      JourneyDate: "2024-02-03",
    },
    {
      lastName: "Williams",
      firstName: "David",
      age: 24,
      gender: "Female",
      RegNo: "REG392",
      country: "USA",
      receiptNo: "REC94",
      address: "577 Elm St, City",
      oppCharges: 120,
      gstCharges: 15,
      OTTCharges: 68,
      totalAmount: 203,
      status: "Cancelled",
      JourneyDate: "2023-10-23",
    },
    {
      lastName: "Smith",
      firstName: "Richard",
      age: 64,
      gender: "Male",
      RegNo: "REG23",
      country: "Australia",
      receiptNo: "REC409",
      address: "518 Elm St, City",
      oppCharges: 240,
      gstCharges: 29,
      OTTCharges: 62,
      totalAmount: 331,
      status: "Cancelled",
      JourneyDate: "2023-07-27",
    },
    {
      lastName: "Williams",
      firstName: "William",
      age: 51,
      gender: "Male",
      RegNo: "REG742",
      country: "USA",
      receiptNo: "REC617",
      address: "977 Elm St, City",
      oppCharges: 105,
      gstCharges: 34,
      OTTCharges: 15,
      totalAmount: 154,
      status: "Cancelled",
      JourneyDate: "2024-03-04",
    },
    {
      lastName: "Davis",
      firstName: "Richard",
      age: 29,
      gender: "Male",
      RegNo: "REG859",
      country: "UK",
      receiptNo: "REC209",
      address: "159 Elm St, City",
      oppCharges: 205,
      gstCharges: 21,
      OTTCharges: 48,
      totalAmount: 274,
      status: "Pending",
      JourneyDate: "2023-11-24",
    },
    {
      lastName: "Davis",
      firstName: "William",
      age: 49,
      gender: "Male",
      RegNo: "REG649",
      country: "Australia",
      receiptNo: "REC547",
      address: "670 Elm St, City",
      oppCharges: 185,
      gstCharges: 38,
      OTTCharges: 35,
      totalAmount: 258,
      status: "Paid",
      JourneyDate: "2023-02-13",
    },
    {
      lastName: "Williams",
      firstName: "Richard",
      age: 46,
      gender: "Male",
      RegNo: "REG294",
      country: "India",
      receiptNo: "REC231",
      address: "855 Elm St, City",
      oppCharges: 128,
      gstCharges: 27,
      OTTCharges: 16,
      totalAmount: 171,
      status: "Cancelled",
      JourneyDate: "2023-12-12",
    },
    {
      lastName: "Williams",
      firstName: "John",
      age: 24,
      gender: "Female",
      RegNo: "REG466",
      country: "UK",
      receiptNo: "REC176",
      address: "749 Elm St, City",
      oppCharges: 180,
      gstCharges: 29,
      OTTCharges: 2,
      totalAmount: 211,
      status: "Pending",
      JourneyDate: "2024-01-21",
    },
    {
      lastName: "Brown",
      firstName: "Robert",
      age: 37,
      gender: "Male",
      RegNo: "REG759",
      country: "India",
      receiptNo: "REC855",
      address: "106 Elm St, City",
      oppCharges: 162,
      gstCharges: 31,
      OTTCharges: 55,
      totalAmount: 248,
      status: "Cancelled",
      JourneyDate: "2023-04-16",
    },
    {
      lastName: "Jones",
      firstName: "Richard",
      age: 53,
      gender: "Male",
      RegNo: "REG611",
      country: "UK",
      receiptNo: "REC69",
      address: "906 Elm St, City",
      oppCharges: 241,
      gstCharges: 14,
      OTTCharges: 84,
      totalAmount: 339,
      status: "Pending",
      JourneyDate: "2023-12-09",
    },
    {
      lastName: "Miller",
      firstName: "Robert",
      age: 41,
      gender: "Male",
      RegNo: "REG779",
      country: "Canada",
      receiptNo: "REC776",
      address: "444 Elm St, City",
      oppCharges: 57,
      gstCharges: 29,
      OTTCharges: 15,
      totalAmount: 101,
      status: "Paid",
      JourneyDate: "2023-09-18",
    },
    {
      lastName: "Jones",
      firstName: "Michael",
      age: 37,
      gender: "Male",
      RegNo: "REG861",
      country: "UK",
      receiptNo: "REC213",
      address: "402 Elm St, City",
      oppCharges: 193,
      gstCharges: 36,
      OTTCharges: 59,
      totalAmount: 288,
      status: "Paid",
      JourneyDate: "2023-05-23",
    },
    {
      lastName: "Jones",
      firstName: "James",
      age: 36,
      gender: "Female",
      RegNo: "REG588",
      country: "India",
      receiptNo: "REC808",
      address: "459 Elm St, City",
      oppCharges: 197,
      gstCharges: 20,
      OTTCharges: 46,
      totalAmount: 263,
      status: "Cancelled",
      JourneyDate: "2023-03-08",
    },
    {
      lastName: "Davis",
      firstName: "Richard",
      age: 22,
      gender: "Male",
      RegNo: "REG617",
      country: "USA",
      receiptNo: "REC399",
      address: "48 Elm St, City",
      oppCharges: 235,
      gstCharges: 18,
      OTTCharges: 72,
      totalAmount: 325,
      status: "Cancelled",
      JourneyDate: "2023-04-01",
    },
    {
      lastName: "Miller",
      firstName: "John",
      age: 65,
      gender: "Female",
      RegNo: "REG985",
      country: "USA",
      receiptNo: "REC688",
      address: "626 Elm St, City",
      oppCharges: 166,
      gstCharges: 35,
      OTTCharges: 80,
      totalAmount: 281,
      status: "Cancelled",
      JourneyDate: "2024-03-24",
    },
    {
      lastName: "Miller",
      firstName: "Robert",
      age: 57,
      gender: "Female",
      RegNo: "REG730",
      country: "Canada",
      receiptNo: "REC855",
      address: "619 Elm St, City",
      oppCharges: 106,
      gstCharges: 28,
      OTTCharges: 80,
      totalAmount: 214,
      status: "Pending",
      JourneyDate: "2024-05-29",
    },
    {
      lastName: "Miller",
      firstName: "Michael",
      age: 32,
      gender: "Female",
      RegNo: "REG338",
      country: "India",
      receiptNo: "REC203",
      address: "707 Elm St, City",
      oppCharges: 214,
      gstCharges: 18,
      OTTCharges: 76,
      totalAmount: 308,
      status: "Pending",
      JourneyDate: "2024-02-29",
    },
    {
      lastName: "Brown",
      firstName: "John",
      age: 56,
      gender: "Male",
      RegNo: "REG665",
      country: "USA",
      receiptNo: "REC85",
      address: "963 Elm St, City",
      oppCharges: 56,
      gstCharges: 23,
      OTTCharges: 21,
      totalAmount: 100,
      status: "Cancelled",
      JourneyDate: "2024-06-07",
    },
    {
      lastName: "Williams",
      firstName: "David",
      age: 50,
      gender: "Female",
      RegNo: "REG159",
      country: "USA",
      receiptNo: "REC957",
      address: "656 Elm St, City",
      oppCharges: 114,
      gstCharges: 35,
      OTTCharges: 57,
      totalAmount: 206,
      status: "Paid",
      JourneyDate: "2023-10-05",
    },
    {
      lastName: "Williams",
      firstName: "Richard",
      age: 52,
      gender: "Male",
      RegNo: "REG190",
      country: "India",
      receiptNo: "REC229",
      address: "377 Elm St, City",
      oppCharges: 74,
      gstCharges: 22,
      OTTCharges: 33,
      totalAmount: 129,
      status: "Pending",
      JourneyDate: "2023-03-01",
    },
    {
      lastName: "Williams",
      firstName: "William",
      age: 65,
      gender: "Male",
      RegNo: "REG668",
      country: "Canada",
      receiptNo: "REC935",
      address: "529 Elm St, City",
      oppCharges: 173,
      gstCharges: 12,
      OTTCharges: 88,
      totalAmount: 273,
      status: "Pending",
      JourneyDate: "2024-01-19",
    },
    {
      lastName: "Williams",
      firstName: "Michael",
      age: 38,
      gender: "Female",
      RegNo: "REG776",
      country: "Australia",
      receiptNo: "REC619",
      address: "110 Elm St, City",
      oppCharges: 100,
      gstCharges: 32,
      OTTCharges: 21,
      totalAmount: 153,
      status: "Cancelled",
      JourneyDate: "2024-02-26",
    },
    {
      lastName: "Miller",
      firstName: "Richard",
      age: 21,
      gender: "Male",
      RegNo: "REG505",
      country: "USA",
      receiptNo: "REC5",
      address: "357 Elm St, City",
      oppCharges: 193,
      gstCharges: 29,
      OTTCharges: 91,
      totalAmount: 313,
      status: "Pending",
      JourneyDate: "2023-12-08",
    },
    {
      lastName: "Jones",
      firstName: "James",
      age: 32,
      gender: "Female",
      RegNo: "REG641",
      country: "UK",
      receiptNo: "REC73",
      address: "401 Elm St, City",
      oppCharges: 139,
      gstCharges: 15,
      OTTCharges: 54,
      totalAmount: 208,
      status: "Cancelled",
      JourneyDate: "2023-06-06",
    },
    {
      lastName: "Jones",
      firstName: "James",
      age: 69,
      gender: "Male",
      RegNo: "REG332",
      country: "Canada",
      receiptNo: "REC551",
      address: "249 Elm St, City",
      oppCharges: 132,
      gstCharges: 31,
      OTTCharges: 54,
      totalAmount: 217,
      status: "Pending",
      JourneyDate: "2023-12-20",
    },
    {
      lastName: "Jones",
      firstName: "John",
      age: 55,
      gender: "Male",
      RegNo: "REG664",
      country: "Canada",
      receiptNo: "REC455",
      address: "199 Elm St, City",
      oppCharges: 92,
      gstCharges: 28,
      OTTCharges: 36,
      totalAmount: 156,
      status: "Cancelled",
      JourneyDate: "2024-03-16",
    },
    {
      lastName: "Williams",
      firstName: "Michael",
      age: 40,
      gender: "Male",
      RegNo: "REG989",
      country: "Canada",
      receiptNo: "REC543",
      address: "260 Elm St, City",
      oppCharges: 222,
      gstCharges: 36,
      OTTCharges: 10,
      totalAmount: 268,
      status: "Cancelled",
      JourneyDate: "2024-01-21",
    },
    {
      lastName: "Davis",
      firstName: "David",
      age: 60,
      gender: "Female",
      RegNo: "REG287",
      country: "Canada",
      receiptNo: "REC570",
      address: "111 Elm St, City",
      oppCharges: 115,
      gstCharges: 20,
      OTTCharges: 32,
      totalAmount: 167,
      status: "Paid",
      JourneyDate: "2023-01-22",
    },
    {
      lastName: "Miller",
      firstName: "Michael",
      age: 24,
      gender: "Female",
      RegNo: "REG637",
      country: "Canada",
      receiptNo: "REC336",
      address: "133 Elm St, City",
      oppCharges: 240,
      gstCharges: 19,
      OTTCharges: 74,
      totalAmount: 333,
      status: "Pending",
      JourneyDate: "2024-06-26",
    },
    {
      lastName: "Brown",
      firstName: "John",
      age: 60,
      gender: "Female",
      RegNo: "REG131",
      country: "Australia",
      receiptNo: "REC733",
      address: "85 Elm St, City",
      oppCharges: 203,
      gstCharges: 18,
      OTTCharges: 0,
      totalAmount: 221,
      status: "Paid",
      JourneyDate: "2023-08-02",
    },
    {
      lastName: "Davis",
      firstName: "William",
      age: 65,
      gender: "Female",
      RegNo: "REG422",
      country: "Australia",
      receiptNo: "REC136",
      address: "655 Elm St, City",
      oppCharges: 195,
      gstCharges: 27,
      OTTCharges: 39,
      totalAmount: 261,
      status: "Paid",
      JourneyDate: "2023-01-13",
    },
    {
      lastName: "Johnson",
      firstName: "William",
      age: 58,
      gender: "Male",
      RegNo: "REG562",
      country: "Canada",
      receiptNo: "REC586",
      address: "490 Elm St, City",
      oppCharges: 73,
      gstCharges: 15,
      OTTCharges: 77,
      totalAmount: 165,
      status: "Cancelled",
      JourneyDate: "2024-02-13",
    },
    {
      lastName: "Brown",
      firstName: "Richard",
      age: 45,
      gender: "Male",
      RegNo: "REG91",
      country: "USA",
      receiptNo: "REC501",
      address: "2 Elm St, City",
      oppCharges: 92,
      gstCharges: 21,
      OTTCharges: 15,
      totalAmount: 128,
      status: "Pending",
      JourneyDate: "2024-01-17",
    },
    {
      lastName: "Williams",
      firstName: "John",
      age: 53,
      gender: "Female",
      RegNo: "REG492",
      country: "Australia",
      receiptNo: "REC956",
      address: "792 Elm St, City",
      oppCharges: 249,
      gstCharges: 22,
      OTTCharges: 29,
      totalAmount: 300,
      status: "Cancelled",
      JourneyDate: "2023-10-28",
    },
    {
      lastName: "Miller",
      firstName: "John",
      age: 50,
      gender: "Male",
      RegNo: "REG633",
      country: "Canada",
      receiptNo: "REC783",
      address: "900 Elm St, City",
      oppCharges: 133,
      gstCharges: 35,
      OTTCharges: 29,
      totalAmount: 197,
      status: "Cancelled",
      JourneyDate: "2023-05-26",
    },
    {
      lastName: "Smith",
      firstName: "Michael",
      age: 27,
      gender: "Male",
      RegNo: "REG724",
      country: "UK",
      receiptNo: "REC749",
      address: "206 Elm St, City",
      oppCharges: 136,
      gstCharges: 28,
      OTTCharges: 84,
      totalAmount: 248,
      status: "Pending",
      JourneyDate: "2023-12-09",
    },
    {
      lastName: "Brown",
      firstName: "John",
      age: 21,
      gender: "Female",
      RegNo: "REG831",
      country: "Canada",
      receiptNo: "REC907",
      address: "664 Elm St, City",
      oppCharges: 178,
      gstCharges: 34,
      OTTCharges: 94,
      totalAmount: 306,
      status: "Paid",
      JourneyDate: "2023-03-31",
    },
    {
      lastName: "Miller",
      firstName: "John",
      age: 50,
      gender: "Female",
      RegNo: "REG106",
      country: "USA",
      receiptNo: "REC872",
      address: "237 Elm St, City",
      oppCharges: 156,
      gstCharges: 38,
      OTTCharges: 25,
      totalAmount: 219,
      status: "Pending",
      JourneyDate: "2024-05-03",
    },
    {
      lastName: "Johnson",
      firstName: "Michael",
      age: 25,
      gender: "Male",
      RegNo: "REG203",
      country: "USA",
      receiptNo: "REC705",
      address: "768 Elm St, City",
      oppCharges: 229,
      gstCharges: 21,
      OTTCharges: 75,
      totalAmount: 325,
      status: "Pending",
      JourneyDate: "2024-06-04",
    },
    {
      lastName: "Johnson",
      firstName: "David",
      age: 44,
      gender: "Female",
      RegNo: "REG350",
      country: "Australia",
      receiptNo: "REC380",
      address: "193 Elm St, City",
      oppCharges: 200,
      gstCharges: 32,
      OTTCharges: 6,
      totalAmount: 238,
      status: "Paid",
      JourneyDate: "2023-03-20",
    },
    {
      lastName: "Davis",
      firstName: "Richard",
      age: 53,
      gender: "Female",
      RegNo: "REG642",
      country: "Canada",
      receiptNo: "REC944",
      address: "147 Elm St, City",
      oppCharges: 193,
      gstCharges: 37,
      OTTCharges: 57,
      totalAmount: 287,
      status: "Pending",
      JourneyDate: "2024-03-26",
    },
    {
      lastName: "Miller",
      firstName: "Robert",
      age: 21,
      gender: "Female",
      RegNo: "REG584",
      country: "India",
      receiptNo: "REC829",
      address: "552 Elm St, City",
      oppCharges: 168,
      gstCharges: 18,
      OTTCharges: 74,
      totalAmount: 260,
      status: "Pending",
      JourneyDate: "2024-04-17",
    },
    {
      lastName: "Johnson",
      firstName: "William",
      age: 21,
      gender: "Male",
      RegNo: "REG941",
      country: "India",
      receiptNo: "REC418",
      address: "651 Elm St, City",
      oppCharges: 189,
      gstCharges: 26,
      OTTCharges: 80,
      totalAmount: 295,
      status: "Pending",
      JourneyDate: "2023-02-04",
    },
    {
      lastName: "Davis",
      firstName: "John",
      age: 63,
      gender: "Female",
      RegNo: "REG697",
      country: "UK",
      receiptNo: "REC560",
      address: "394 Elm St, City",
      oppCharges: 75,
      gstCharges: 31,
      OTTCharges: 12,
      totalAmount: 118,
      status: "Cancelled",
      JourneyDate: "2023-08-29",
    },
    {
      lastName: "Smith",
      firstName: "Michael",
      age: 45,
      gender: "Female",
      RegNo: "REG854",
      country: "UK",
      receiptNo: "REC470",
      address: "578 Elm St, City",
      oppCharges: 98,
      gstCharges: 22,
      OTTCharges: 5,
      totalAmount: 125,
      status: "Cancelled",
      JourneyDate: "2023-10-22",
    },
    {
      lastName: "Jones",
      firstName: "Richard",
      age: 51,
      gender: "Male",
      RegNo: "REG876",
      country: "India",
      receiptNo: "REC847",
      address: "542 Elm St, City",
      oppCharges: 64,
      gstCharges: 38,
      OTTCharges: 16,
      totalAmount: 118,
      status: "Pending",
      JourneyDate: "2023-09-24",
    },
    {
      lastName: "Brown",
      firstName: "William",
      age: 53,
      gender: "Female",
      RegNo: "REG594",
      country: "Australia",
      receiptNo: "REC496",
      address: "744 Elm St, City",
      oppCharges: 61,
      gstCharges: 27,
      OTTCharges: 26,
      totalAmount: 114,
      status: "Paid",
      JourneyDate: "2023-03-25",
    },
    {
      lastName: "Jones",
      firstName: "David",
      age: 65,
      gender: "Female",
      RegNo: "REG282",
      country: "UK",
      receiptNo: "REC829",
      address: "489 Elm St, City",
      oppCharges: 98,
      gstCharges: 34,
      OTTCharges: 24,
      totalAmount: 156,
      status: "Pending",
      JourneyDate: "2023-07-06",
    },
    {
      lastName: "Brown",
      firstName: "David",
      age: 20,
      gender: "Female",
      RegNo: "REG575",
      country: "UK",
      receiptNo: "REC708",
      address: "532 Elm St, City",
      oppCharges: 165,
      gstCharges: 16,
      OTTCharges: 50,
      totalAmount: 231,
      status: "Cancelled",
      JourneyDate: "2023-06-14",
    },
    {
      lastName: "Williams",
      firstName: "Richard",
      age: 29,
      gender: "Male",
      RegNo: "REG561",
      country: "Australia",
      receiptNo: "REC635",
      address: "724 Elm St, City",
      oppCharges: 149,
      gstCharges: 29,
      OTTCharges: 18,
      totalAmount: 196,
      status: "Paid",
      JourneyDate: "2023-12-27",
    },
    {
      lastName: "Johnson",
      firstName: "William",
      age: 66,
      gender: "Male",
      RegNo: "REG164",
      country: "UK",
      receiptNo: "REC236",
      address: "274 Elm St, City",
      oppCharges: 175,
      gstCharges: 19,
      OTTCharges: 90,
      totalAmount: 284,
      status: "Pending",
      JourneyDate: "2023-07-17",
    },
    {
      lastName: "Miller",
      firstName: "William",
      age: 68,
      gender: "Female",
      RegNo: "REG880",
      country: "Australia",
      receiptNo: "REC98",
      address: "583 Elm St, City",
      oppCharges: 121,
      gstCharges: 18,
      OTTCharges: 74,
      totalAmount: 213,
      status: "Cancelled",
      JourneyDate: "2023-03-15",
    },
    {
      lastName: "Davis",
      firstName: "James",
      age: 20,
      gender: "Male",
      RegNo: "REG534",
      country: "Canada",
      receiptNo: "REC745",
      address: "75 Elm St, City",
      oppCharges: 242,
      gstCharges: 26,
      OTTCharges: 41,
      totalAmount: 309,
      status: "Cancelled",
      JourneyDate: "2024-01-08",
    },
    {
      lastName: "Williams",
      firstName: "Michael",
      age: 23,
      gender: "Male",
      RegNo: "REG883",
      country: "Australia",
      receiptNo: "REC738",
      address: "533 Elm St, City",
      oppCharges: 236,
      gstCharges: 25,
      OTTCharges: 88,
      totalAmount: 349,
      status: "Pending",
      JourneyDate: "2023-11-12",
    },
    {
      lastName: "Jones",
      firstName: "Michael",
      age: 48,
      gender: "Female",
      RegNo: "REG217",
      country: "India",
      receiptNo: "REC57",
      address: "253 Elm St, City",
      oppCharges: 232,
      gstCharges: 10,
      OTTCharges: 81,
      totalAmount: 323,
      status: "Cancelled",
      JourneyDate: "2023-05-03",
    },
    {
      lastName: "Williams",
      firstName: "Robert",
      age: 60,
      gender: "Male",
      RegNo: "REG833",
      country: "Australia",
      receiptNo: "REC472",
      address: "965 Elm St, City",
      oppCharges: 70,
      gstCharges: 14,
      OTTCharges: 85,
      totalAmount: 169,
      status: "Cancelled",
      JourneyDate: "2024-03-28",
    },
    {
      lastName: "Brown",
      firstName: "Robert",
      age: 33,
      gender: "Male",
      RegNo: "REG487",
      country: "UK",
      receiptNo: "REC62",
      address: "498 Elm St, City",
      oppCharges: 238,
      gstCharges: 39,
      OTTCharges: 92,
      totalAmount: 369,
      status: "Cancelled",
      JourneyDate: "2023-04-28",
    },
    {
      lastName: "Williams",
      firstName: "John",
      age: 37,
      gender: "Male",
      RegNo: "REG675",
      country: "India",
      receiptNo: "REC871",
      address: "910 Elm St, City",
      oppCharges: 201,
      gstCharges: 25,
      OTTCharges: 99,
      totalAmount: 325,
      status: "Paid",
      JourneyDate: "2023-01-08",
    },
    {
      lastName: "Williams",
      firstName: "John",
      age: 23,
      gender: "Female",
      RegNo: "REG584",
      country: "Canada",
      receiptNo: "REC897",
      address: "654 Elm St, City",
      oppCharges: 180,
      gstCharges: 30,
      OTTCharges: 74,
      totalAmount: 284,
      status: "Paid",
      JourneyDate: "2023-11-12",
    },
    {
      lastName: "Smith",
      firstName: "James",
      age: 67,
      gender: "Male",
      RegNo: "REG516",
      country: "UK",
      receiptNo: "REC567",
      address: "691 Elm St, City",
      oppCharges: 91,
      gstCharges: 29,
      OTTCharges: 80,
      totalAmount: 200,
      status: "Pending",
      JourneyDate: "2023-09-12",
    },
    {
      lastName: "Miller",
      firstName: "Michael",
      age: 58,
      gender: "Male",
      RegNo: "REG696",
      country: "Australia",
      receiptNo: "REC824",
      address: "211 Elm St, City",
      oppCharges: 224,
      gstCharges: 27,
      OTTCharges: 72,
      totalAmount: 323,
      status: "Cancelled",
      JourneyDate: "2024-04-18",
    },
    {
      lastName: "Smith",
      firstName: "Michael",
      age: 20,
      gender: "Male",
      RegNo: "REG15",
      country: "India",
      receiptNo: "REC116",
      address: "160 Elm St, City",
      oppCharges: 59,
      gstCharges: 19,
      OTTCharges: 40,
      totalAmount: 118,
      status: "Paid",
      JourneyDate: "2024-01-31",
    },
    {
      lastName: "Williams",
      firstName: "William",
      age: 49,
      gender: "Female",
      RegNo: "REG640",
      country: "Canada",
      receiptNo: "REC509",
      address: "899 Elm St, City",
      oppCharges: 99,
      gstCharges: 38,
      OTTCharges: 16,
      totalAmount: 153,
      status: "Paid",
      JourneyDate: "2024-03-29",
    },
    {
      lastName: "Johnson",
      firstName: "James",
      age: 45,
      gender: "Male",
      RegNo: "REG686",
      country: "Canada",
      receiptNo: "REC896",
      address: "450 Elm St, City",
      oppCharges: 81,
      gstCharges: 13,
      OTTCharges: 41,
      totalAmount: 135,
      status: "Pending",
      JourneyDate: "2023-06-25",
    },
    {
      lastName: "Miller",
      firstName: "John",
      age: 30,
      gender: "Female",
      RegNo: "REG872",
      country: "USA",
      receiptNo: "REC493",
      address: "405 Elm St, City",
      oppCharges: 192,
      gstCharges: 17,
      OTTCharges: 29,
      totalAmount: 238,
      status: "Pending",
      JourneyDate: "2024-06-01",
    },
    {
      lastName: "Miller",
      firstName: "John",
      age: 29,
      gender: "Female",
      RegNo: "REG842",
      country: "UK",
      receiptNo: "REC476",
      address: "309 Elm St, City",
      oppCharges: 212,
      gstCharges: 29,
      OTTCharges: 42,
      totalAmount: 283,
      status: "Pending",
      JourneyDate: "2023-07-09",
    },
    {
      lastName: "Williams",
      firstName: "William",
      age: 65,
      gender: "Male",
      RegNo: "REG476",
      country: "India",
      receiptNo: "REC934",
      address: "285 Elm St, City",
      oppCharges: 127,
      gstCharges: 15,
      OTTCharges: 60,
      totalAmount: 202,
      status: "Pending",
      JourneyDate: "2023-04-30",
    },
    {
      lastName: "Jones",
      firstName: "James",
      age: 28,
      gender: "Male",
      RegNo: "REG204",
      country: "Australia",
      receiptNo: "REC366",
      address: "678 Elm St, City",
      oppCharges: 235,
      gstCharges: 20,
      OTTCharges: 85,
      totalAmount: 340,
      status: "Pending",
      JourneyDate: "2024-05-07",
    },
    {
      lastName: "Jones",
      firstName: "Robert",
      age: 57,
      gender: "Male",
      RegNo: "REG143",
      country: "Canada",
      receiptNo: "REC187",
      address: "692 Elm St, City",
      oppCharges: 140,
      gstCharges: 36,
      OTTCharges: 54,
      totalAmount: 230,
      status: "Pending",
      JourneyDate: "2023-10-29",
    },
    {
      lastName: "Jones",
      firstName: "Michael",
      age: 63,
      gender: "Male",
      RegNo: "REG276",
      country: "UK",
      receiptNo: "REC855",
      address: "681 Elm St, City",
      oppCharges: 216,
      gstCharges: 18,
      OTTCharges: 80,
      totalAmount: 314,
      status: "Pending",
      JourneyDate: "2023-11-14",
    },
    {
      lastName: "Smith",
      firstName: "William",
      age: 41,
      gender: "Female",
      RegNo: "REG881",
      country: "India",
      receiptNo: "REC809",
      address: "819 Elm St, City",
      oppCharges: 225,
      gstCharges: 22,
      OTTCharges: 0,
      totalAmount: 247,
      status: "Cancelled",
      JourneyDate: "2024-02-29",
    },
    {
      lastName: "Williams",
      firstName: "David",
      age: 65,
      gender: "Female",
      RegNo: "REG344",
      country: "UK",
      receiptNo: "REC546",
      address: "979 Elm St, City",
      oppCharges: 204,
      gstCharges: 24,
      OTTCharges: 34,
      totalAmount: 262,
      status: "Paid",
      JourneyDate: "2024-05-14",
    },
    {
      lastName: "Johnson",
      firstName: "John",
      age: 34,
      gender: "Female",
      RegNo: "REG601",
      country: "USA",
      receiptNo: "REC899",
      address: "176 Elm St, City",
      oppCharges: 172,
      gstCharges: 10,
      OTTCharges: 13,
      totalAmount: 195,
      status: "Paid",
      JourneyDate: "2023-05-04",
    },
    {
      lastName: "Jones",
      firstName: "Robert",
      age: 33,
      gender: "Male",
      RegNo: "REG994",
      country: "India",
      receiptNo: "REC365",
      address: "874 Elm St, City",
      oppCharges: 214,
      gstCharges: 21,
      OTTCharges: 66,
      totalAmount: 301,
      status: "Pending",
      JourneyDate: "2023-10-16",
    },
    {
      lastName: "Williams",
      firstName: "Michael",
      age: 58,
      gender: "Female",
      RegNo: "REG187",
      country: "USA",
      receiptNo: "REC294",
      address: "997 Elm St, City",
      oppCharges: 229,
      gstCharges: 14,
      OTTCharges: 79,
      totalAmount: 322,
      status: "Pending",
      JourneyDate: "2024-03-11",
    },
    {
      lastName: "Johnson",
      firstName: "James",
      age: 42,
      gender: "Male",
      RegNo: "REG39",
      country: "USA",
      receiptNo: "REC934",
      address: "830 Elm St, City",
      oppCharges: 78,
      gstCharges: 20,
      OTTCharges: 68,
      totalAmount: 166,
      status: "Cancelled",
      JourneyDate: "2023-06-21",
    },
    {
      lastName: "Williams",
      firstName: "James",
      age: 60,
      gender: "Female",
      RegNo: "REG916",
      country: "USA",
      receiptNo: "REC601",
      address: "732 Elm St, City",
      oppCharges: 113,
      gstCharges: 20,
      OTTCharges: 34,
      totalAmount: 167,
      status: "Paid",
      JourneyDate: "2024-01-07",
    },
    {
      lastName: "Smith",
      firstName: "John",
      age: 37,
      gender: "Female",
      RegNo: "REG60",
      country: "USA",
      receiptNo: "REC739",
      address: "802 Elm St, City",
      oppCharges: 195,
      gstCharges: 39,
      OTTCharges: 90,
      totalAmount: 324,
      status: "Pending",
      JourneyDate: "2023-12-11",
    },
    {
      lastName: "Brown",
      firstName: "James",
      age: 57,
      gender: "Female",
      RegNo: "REG814",
      country: "UK",
      receiptNo: "REC251",
      address: "669 Elm St, City",
      oppCharges: 235,
      gstCharges: 37,
      OTTCharges: 74,
      totalAmount: 346,
      status: "Cancelled",
      JourneyDate: "2023-10-24",
    },
    {
      lastName: "Johnson",
      firstName: "William",
      age: 28,
      gender: "Male",
      RegNo: "REG160",
      country: "UK",
      receiptNo: "REC642",
      address: "997 Elm St, City",
      oppCharges: 77,
      gstCharges: 34,
      OTTCharges: 49,
      totalAmount: 160,
      status: "Pending",
      JourneyDate: "2023-08-02",
    },
    {
      lastName: "Miller",
      firstName: "Richard",
      age: 68,
      gender: "Female",
      RegNo: "REG195",
      country: "India",
      receiptNo: "REC422",
      address: "871 Elm St, City",
      oppCharges: 193,
      gstCharges: 14,
      OTTCharges: 64,
      totalAmount: 271,
      status: "Pending",
      JourneyDate: "2023-02-15",
    },
    {
      lastName: "Johnson",
      firstName: "Richard",
      age: 23,
      gender: "Male",
      RegNo: "REG952",
      country: "USA",
      receiptNo: "REC941",
      address: "143 Elm St, City",
      oppCharges: 64,
      gstCharges: 21,
      OTTCharges: 51,
      totalAmount: 136,
      status: "Pending",
      JourneyDate: "2024-06-29",
    },
    {
      lastName: "Jones",
      firstName: "John",
      age: 31,
      gender: "Male",
      RegNo: "REG205",
      country: "UK",
      receiptNo: "REC182",
      address: "729 Elm St, City",
      oppCharges: 172,
      gstCharges: 13,
      OTTCharges: 5,
      totalAmount: 190,
      status: "Paid",
      JourneyDate: "2023-02-05",
    },
    {
      lastName: "Smith",
      firstName: "James",
      age: 30,
      gender: "Female",
      RegNo: "REG547",
      country: "USA",
      receiptNo: "REC719",
      address: "883 Elm St, City",
      oppCharges: 243,
      gstCharges: 35,
      OTTCharges: 96,
      totalAmount: 374,
      status: "Paid",
      JourneyDate: "2023-12-18",
    },
    {
      lastName: "Miller",
      firstName: "David",
      age: 59,
      gender: "Male",
      RegNo: "REG974",
      country: "UK",
      receiptNo: "REC15",
      address: "992 Elm St, City",
      oppCharges: 242,
      gstCharges: 20,
      OTTCharges: 33,
      totalAmount: 295,
      status: "Cancelled",
      JourneyDate: "2023-10-26",
    },
    {
      lastName: "Davis",
      firstName: "Robert",
      age: 44,
      gender: "Female",
      RegNo: "REG311",
      country: "Canada",
      receiptNo: "REC332",
      address: "932 Elm St, City",
      oppCharges: 92,
      gstCharges: 39,
      OTTCharges: 3,
      totalAmount: 134,
      status: "Paid",
      JourneyDate: "2023-12-16",
    },
    {
      lastName: "Williams",
      firstName: "James",
      age: 69,
      gender: "Female",
      RegNo: "REG668",
      country: "UK",
      receiptNo: "REC324",
      address: "662 Elm St, City",
      oppCharges: 189,
      gstCharges: 23,
      OTTCharges: 28,
      totalAmount: 240,
      status: "Paid",
      JourneyDate: "2023-03-12",
    },
    {
      lastName: "Miller",
      firstName: "William",
      age: 49,
      gender: "Female",
      RegNo: "REG872",
      country: "Australia",
      receiptNo: "REC203",
      address: "619 Elm St, City",
      oppCharges: 153,
      gstCharges: 17,
      OTTCharges: 68,
      totalAmount: 238,
      status: "Paid",
      JourneyDate: "2023-10-19",
    },
    {
      lastName: "Brown",
      firstName: "Richard",
      age: 32,
      gender: "Male",
      RegNo: "REG460",
      country: "Australia",
      receiptNo: "REC276",
      address: "651 Elm St, City",
      oppCharges: 177,
      gstCharges: 12,
      OTTCharges: 91,
      totalAmount: 280,
      status: "Paid",
      JourneyDate: "2023-05-23",
    },
    {
      lastName: "Johnson",
      firstName: "William",
      age: 47,
      gender: "Male",
      RegNo: "REG513",
      country: "UK",
      receiptNo: "REC651",
      address: "122 Elm St, City",
      oppCharges: 244,
      gstCharges: 30,
      OTTCharges: 21,
      totalAmount: 295,
      status: "Cancelled",
      JourneyDate: "2023-08-05",
    },
    {
      lastName: "Jones",
      firstName: "John",
      age: 48,
      gender: "Female",
      RegNo: "REG321",
      country: "UK",
      receiptNo: "REC438",
      address: "319 Elm St, City",
      oppCharges: 74,
      gstCharges: 24,
      OTTCharges: 50,
      totalAmount: 148,
      status: "Cancelled",
      JourneyDate: "2024-06-10",
    },
    {
      lastName: "Miller",
      firstName: "John",
      age: 35,
      gender: "Female",
      RegNo: "REG307",
      country: "Australia",
      receiptNo: "REC644",
      address: "614 Elm St, City",
      oppCharges: 220,
      gstCharges: 23,
      OTTCharges: 94,
      totalAmount: 337,
      status: "Cancelled",
      JourneyDate: "2023-05-06",
    },
    {
      lastName: "Miller",
      firstName: "Robert",
      age: 28,
      gender: "Male",
      RegNo: "REG570",
      country: "UK",
      receiptNo: "REC940",
      address: "690 Elm St, City",
      oppCharges: 182,
      gstCharges: 37,
      OTTCharges: 4,
      totalAmount: 223,
      status: "Paid",
      JourneyDate: "2024-03-02",
    },
    {
      lastName: "Williams",
      firstName: "John",
      age: 48,
      gender: "Female",
      RegNo: "REG436",
      country: "USA",
      receiptNo: "REC51",
      address: "596 Elm St, City",
      oppCharges: 82,
      gstCharges: 33,
      OTTCharges: 89,
      totalAmount: 204,
      status: "Cancelled",
      JourneyDate: "2024-06-13",
    },
    {
      lastName: "Miller",
      firstName: "Michael",
      age: 35,
      gender: "Male",
      RegNo: "REG493",
      country: "UK",
      receiptNo: "REC789",
      address: "929 Elm St, City",
      oppCharges: 144,
      gstCharges: 34,
      OTTCharges: 63,
      totalAmount: 241,
      status: "Pending",
      JourneyDate: "2024-02-18",
    },
    {
      lastName: "Jones",
      firstName: "William",
      age: 42,
      gender: "Male",
      RegNo: "REG899",
      country: "India",
      receiptNo: "REC51",
      address: "983 Elm St, City",
      oppCharges: 63,
      gstCharges: 35,
      OTTCharges: 21,
      totalAmount: 119,
      status: "Paid",
      JourneyDate: "2023-08-09",
    },
    {
      lastName: "Johnson",
      firstName: "William",
      age: 31,
      gender: "Female",
      RegNo: "REG414",
      country: "UK",
      receiptNo: "REC54",
      address: "739 Elm St, City",
      oppCharges: 171,
      gstCharges: 19,
      OTTCharges: 55,
      totalAmount: 245,
      status: "Paid",
      JourneyDate: "2023-08-08",
    },
    {
      lastName: "Johnson",
      firstName: "Michael",
      age: 54,
      gender: "Male",
      RegNo: "REG999",
      country: "Australia",
      receiptNo: "REC133",
      address: "762 Elm St, City",
      oppCharges: 63,
      gstCharges: 23,
      OTTCharges: 75,
      totalAmount: 161,
      status: "Cancelled",
      JourneyDate: "2023-05-06",
    },
    {
      lastName: "Johnson",
      firstName: "Richard",
      age: 30,
      gender: "Male",
      RegNo: "REG477",
      country: "Australia",
      receiptNo: "REC534",
      address: "884 Elm St, City",
      oppCharges: 244,
      gstCharges: 28,
      OTTCharges: 5,
      totalAmount: 277,
      status: "Pending",
      JourneyDate: "2024-05-15",
    },
    {
      lastName: "Williams",
      firstName: "James",
      age: 62,
      gender: "Male",
      RegNo: "REG693",
      country: "Australia",
      receiptNo: "REC481",
      address: "513 Elm St, City",
      oppCharges: 102,
      gstCharges: 13,
      OTTCharges: 60,
      totalAmount: 175,
      status: "Pending",
      JourneyDate: "2023-11-28",
    },
    {
      lastName: "Jones",
      firstName: "James",
      age: 66,
      gender: "Female",
      RegNo: "REG109",
      country: "UK",
      receiptNo: "REC303",
      address: "818 Elm St, City",
      oppCharges: 89,
      gstCharges: 20,
      OTTCharges: 10,
      totalAmount: 119,
      status: "Cancelled",
      JourneyDate: "2023-10-24",
    },
    {
      lastName: "Davis",
      firstName: "Richard",
      age: 22,
      gender: "Female",
      RegNo: "REG56",
      country: "Australia",
      receiptNo: "REC467",
      address: "154 Elm St, City",
      oppCharges: 50,
      gstCharges: 35,
      OTTCharges: 40,
      totalAmount: 125,
      status: "Cancelled",
      JourneyDate: "2023-07-28",
    },
    {
      lastName: "Williams",
      firstName: "Michael",
      age: 40,
      gender: "Female",
      RegNo: "REG768",
      country: "Australia",
      receiptNo: "REC986",
      address: "281 Elm St, City",
      oppCharges: 106,
      gstCharges: 21,
      OTTCharges: 59,
      totalAmount: 186,
      status: "Paid",
      JourneyDate: "2023-01-09",
    },
    {
      lastName: "Miller",
      firstName: "Michael",
      age: 25,
      gender: "Male",
      RegNo: "REG812",
      country: "Canada",
      receiptNo: "REC616",
      address: "824 Elm St, City",
      oppCharges: 138,
      gstCharges: 32,
      OTTCharges: 63,
      totalAmount: 233,
      status: "Cancelled",
      JourneyDate: "2023-10-11",
    },
    {
      lastName: "Jones",
      firstName: "William",
      age: 20,
      gender: "Female",
      RegNo: "REG630",
      country: "Canada",
      receiptNo: "REC278",
      address: "633 Elm St, City",
      oppCharges: 70,
      gstCharges: 37,
      OTTCharges: 53,
      totalAmount: 160,
      status: "Cancelled",
      JourneyDate: "2023-04-01",
    },
    {
      lastName: "Davis",
      firstName: "James",
      age: 40,
      gender: "Female",
      RegNo: "REG767",
      country: "India",
      receiptNo: "REC453",
      address: "310 Elm St, City",
      oppCharges: 119,
      gstCharges: 28,
      OTTCharges: 57,
      totalAmount: 204,
      status: "Paid",
      JourneyDate: "2023-10-01",
    },
    {
      lastName: "Johnson",
      firstName: "Michael",
      age: 48,
      gender: "Male",
      RegNo: "REG934",
      country: "Australia",
      receiptNo: "REC491",
      address: "743 Elm St, City",
      oppCharges: 99,
      gstCharges: 24,
      OTTCharges: 8,
      totalAmount: 131,
      status: "Paid",
      JourneyDate: "2023-08-31",
    },
    {
      lastName: "Smith",
      firstName: "John",
      age: 60,
      gender: "Male",
      RegNo: "REG957",
      country: "India",
      receiptNo: "REC276",
      address: "514 Elm St, City",
      oppCharges: 72,
      gstCharges: 18,
      OTTCharges: 6,
      totalAmount: 96,
      status: "Cancelled",
      JourneyDate: "2023-10-03",
    },
    {
      lastName: "Miller",
      firstName: "William",
      age: 22,
      gender: "Female",
      RegNo: "REG692",
      country: "Australia",
      receiptNo: "REC684",
      address: "963 Elm St, City",
      oppCharges: 75,
      gstCharges: 17,
      OTTCharges: 82,
      totalAmount: 174,
      status: "Cancelled",
      JourneyDate: "2024-06-04",
    },
    {
      lastName: "Johnson",
      firstName: "Michael",
      age: 67,
      gender: "Female",
      RegNo: "REG878",
      country: "India",
      receiptNo: "REC175",
      address: "194 Elm St, City",
      oppCharges: 215,
      gstCharges: 39,
      OTTCharges: 6,
      totalAmount: 260,
      status: "Paid",
      JourneyDate: "2023-05-12",
    },
    {
      lastName: "Johnson",
      firstName: "Michael",
      age: 39,
      gender: "Female",
      RegNo: "REG980",
      country: "USA",
      receiptNo: "REC730",
      address: "476 Elm St, City",
      oppCharges: 65,
      gstCharges: 25,
      OTTCharges: 16,
      totalAmount: 106,
      status: "Pending",
      JourneyDate: "2024-04-14",
    },
    {
      lastName: "Brown",
      firstName: "Robert",
      age: 39,
      gender: "Male",
      RegNo: "REG562",
      country: "UK",
      receiptNo: "REC829",
      address: "23 Elm St, City",
      oppCharges: 219,
      gstCharges: 23,
      OTTCharges: 50,
      totalAmount: 292,
      status: "Pending",
      JourneyDate: "2023-10-09",
    },
    {
      lastName: "Johnson",
      firstName: "James",
      age: 27,
      gender: "Male",
      RegNo: "REG338",
      country: "India",
      receiptNo: "REC245",
      address: "198 Elm St, City",
      oppCharges: 106,
      gstCharges: 17,
      OTTCharges: 12,
      totalAmount: 135,
      status: "Pending",
      JourneyDate: "2023-08-28",
    },
    {
      lastName: "Williams",
      firstName: "Michael",
      age: 49,
      gender: "Male",
      RegNo: "REG810",
      country: "India",
      receiptNo: "REC1",
      address: "437 Elm St, City",
      oppCharges: 131,
      gstCharges: 39,
      OTTCharges: 72,
      totalAmount: 242,
      status: "Cancelled",
      JourneyDate: "2023-08-27",
    },
    {
      lastName: "Jones",
      firstName: "John",
      age: 61,
      gender: "Male",
      RegNo: "REG236",
      country: "USA",
      receiptNo: "REC476",
      address: "78 Elm St, City",
      oppCharges: 64,
      gstCharges: 32,
      OTTCharges: 61,
      totalAmount: 157,
      status: "Paid",
      JourneyDate: "2024-01-04",
    },
    {
      lastName: "Smith",
      firstName: "William",
      age: 43,
      gender: "Male",
      RegNo: "REG107",
      country: "Australia",
      receiptNo: "REC469",
      address: "394 Elm St, City",
      oppCharges: 154,
      gstCharges: 18,
      OTTCharges: 15,
      totalAmount: 187,
      status: "Pending",
      JourneyDate: "2024-03-06",
    },
    {
      lastName: "Williams",
      firstName: "James",
      age: 37,
      gender: "Female",
      RegNo: "REG325",
      country: "Canada",
      receiptNo: "REC611",
      address: "766 Elm St, City",
      oppCharges: 99,
      gstCharges: 18,
      OTTCharges: 99,
      totalAmount: 216,
      status: "Cancelled",
      JourneyDate: "2023-02-25",
    },
    {
      lastName: "Davis",
      firstName: "William",
      age: 34,
      gender: "Female",
      RegNo: "REG247",
      country: "Canada",
      receiptNo: "REC863",
      address: "251 Elm St, City",
      oppCharges: 168,
      gstCharges: 39,
      OTTCharges: 88,
      totalAmount: 295,
      status: "Cancelled",
      JourneyDate: "2024-02-12",
    },
    {
      lastName: "Brown",
      firstName: "John",
      age: 49,
      gender: "Male",
      RegNo: "REG852",
      country: "Canada",
      receiptNo: "REC28",
      address: "339 Elm St, City",
      oppCharges: 121,
      gstCharges: 26,
      OTTCharges: 49,
      totalAmount: 196,
      status: "Cancelled",
      JourneyDate: "2023-08-30",
    },
    {
      lastName: "Davis",
      firstName: "John",
      age: 55,
      gender: "Female",
      RegNo: "REG361",
      country: "USA",
      receiptNo: "REC952",
      address: "264 Elm St, City",
      oppCharges: 195,
      gstCharges: 31,
      OTTCharges: 32,
      totalAmount: 258,
      status: "Pending",
      JourneyDate: "2023-02-02",
    },
    {
      lastName: "Smith",
      firstName: "Michael",
      age: 45,
      gender: "Female",
      RegNo: "REG687",
      country: "USA",
      receiptNo: "REC393",
      address: "46 Elm St, City",
      oppCharges: 206,
      gstCharges: 35,
      OTTCharges: 21,
      totalAmount: 262,
      status: "Pending",
      JourneyDate: "2024-05-19",
    },
    {
      lastName: "Miller",
      firstName: "William",
      age: 69,
      gender: "Female",
      RegNo: "REG355",
      country: "Australia",
      receiptNo: "REC89",
      address: "192 Elm St, City",
      oppCharges: 138,
      gstCharges: 24,
      OTTCharges: 34,
      totalAmount: 196,
      status: "Cancelled",
      JourneyDate: "2024-01-27",
    },
    {
      lastName: "Johnson",
      firstName: "William",
      age: 57,
      gender: "Male",
      RegNo: "REG187",
      country: "Australia",
      receiptNo: "REC160",
      address: "716 Elm St, City",
      oppCharges: 61,
      gstCharges: 22,
      OTTCharges: 54,
      totalAmount: 137,
      status: "Cancelled",
      JourneyDate: "2024-03-29",
    },
    {
      lastName: "Miller",
      firstName: "Robert",
      age: 63,
      gender: "Female",
      RegNo: "REG276",
      country: "USA",
      receiptNo: "REC573",
      address: "402 Elm St, City",
      oppCharges: 81,
      gstCharges: 34,
      OTTCharges: 57,
      totalAmount: 172,
      status: "Paid",
      JourneyDate: "2024-06-17",
    },
    {
      lastName: "Miller",
      firstName: "William",
      age: 40,
      gender: "Female",
      RegNo: "REG166",
      country: "USA",
      receiptNo: "REC397",
      address: "29 Elm St, City",
      oppCharges: 141,
      gstCharges: 28,
      OTTCharges: 36,
      totalAmount: 205,
      status: "Pending",
      JourneyDate: "2024-02-23",
    },
    {
      lastName: "Miller",
      firstName: "William",
      age: 40,
      gender: "Female",
      RegNo: "REG773",
      country: "Canada",
      receiptNo: "REC962",
      address: "44 Elm St, City",
      oppCharges: 210,
      gstCharges: 31,
      OTTCharges: 58,
      totalAmount: 299,
      status: "Paid",
      JourneyDate: "2023-06-10",
    },
    {
      lastName: "Williams",
      firstName: "Richard",
      age: 47,
      gender: "Female",
      RegNo: "REG855",
      country: "Australia",
      receiptNo: "REC447",
      address: "658 Elm St, City",
      oppCharges: 190,
      gstCharges: 26,
      OTTCharges: 7,
      totalAmount: 223,
      status: "Paid",
      JourneyDate: "2023-03-07",
    },
    {
      lastName: "Davis",
      firstName: "David",
      age: 25,
      gender: "Male",
      RegNo: "REG810",
      country: "India",
      receiptNo: "REC231",
      address: "176 Elm St, City",
      oppCharges: 82,
      gstCharges: 32,
      OTTCharges: 69,
      totalAmount: 183,
      status: "Paid",
      JourneyDate: "2023-05-24",
    },
    {
      lastName: "Johnson",
      firstName: "Robert",
      age: 30,
      gender: "Female",
      RegNo: "REG707",
      country: "Canada",
      receiptNo: "REC202",
      address: "926 Elm St, City",
      oppCharges: 239,
      gstCharges: 28,
      OTTCharges: 66,
      totalAmount: 333,
      status: "Pending",
      JourneyDate: "2023-04-05",
    },
    {
      lastName: "Johnson",
      firstName: "David",
      age: 64,
      gender: "Male",
      RegNo: "REG143",
      country: "USA",
      receiptNo: "REC464",
      address: "871 Elm St, City",
      oppCharges: 115,
      gstCharges: 36,
      OTTCharges: 80,
      totalAmount: 231,
      status: "Pending",
      JourneyDate: "2024-01-16",
    },
    {
      lastName: "Davis",
      firstName: "John",
      age: 65,
      gender: "Male",
      RegNo: "REG988",
      country: "India",
      receiptNo: "REC59",
      address: "74 Elm St, City",
      oppCharges: 127,
      gstCharges: 15,
      OTTCharges: 51,
      totalAmount: 193,
      status: "Pending",
      JourneyDate: "2024-02-19",
    },
    {
      lastName: "Miller",
      firstName: "Richard",
      age: 60,
      gender: "Male",
      RegNo: "REG738",
      country: "USA",
      receiptNo: "REC619",
      address: "575 Elm St, City",
      oppCharges: 230,
      gstCharges: 12,
      OTTCharges: 10,
      totalAmount: 252,
      status: "Paid",
      JourneyDate: "2023-05-07",
    },
    {
      lastName: "Davis",
      firstName: "Richard",
      age: 36,
      gender: "Male",
      RegNo: "REG111",
      country: "USA",
      receiptNo: "REC930",
      address: "402 Elm St, City",
      oppCharges: 169,
      gstCharges: 39,
      OTTCharges: 31,
      totalAmount: 239,
      status: "Paid",
      JourneyDate: "2023-02-02",
    },
    {
      lastName: "Brown",
      firstName: "William",
      age: 64,
      gender: "Female",
      RegNo: "REG105",
      country: "Australia",
      receiptNo: "REC725",
      address: "199 Elm St, City",
      oppCharges: 93,
      gstCharges: 36,
      OTTCharges: 0,
      totalAmount: 129,
      status: "Cancelled",
      JourneyDate: "2023-04-07",
    },
    {
      lastName: "Jones",
      firstName: "Richard",
      age: 29,
      gender: "Female",
      RegNo: "REG284",
      country: "UK",
      receiptNo: "REC257",
      address: "395 Elm St, City",
      oppCharges: 58,
      gstCharges: 33,
      OTTCharges: 16,
      totalAmount: 107,
      status: "Pending",
      JourneyDate: "2024-02-04",
    },
    {
      lastName: "Miller",
      firstName: "Richard",
      age: 28,
      gender: "Female",
      RegNo: "REG755",
      country: "Canada",
      receiptNo: "REC310",
      address: "719 Elm St, City",
      oppCharges: 204,
      gstCharges: 19,
      OTTCharges: 15,
      totalAmount: 238,
      status: "Pending",
      JourneyDate: "2023-07-03",
    },
    {
      lastName: "Jones",
      firstName: "Richard",
      age: 32,
      gender: "Male",
      RegNo: "REG207",
      country: "India",
      receiptNo: "REC696",
      address: "576 Elm St, City",
      oppCharges: 119,
      gstCharges: 23,
      OTTCharges: 47,
      totalAmount: 189,
      status: "Paid",
      JourneyDate: "2024-04-06",
    },
    {
      lastName: "Smith",
      firstName: "John",
      age: 30,
      gender: "Male",
      RegNo: "REG157",
      country: "Australia",
      receiptNo: "REC600",
      address: "941 Elm St, City",
      oppCharges: 233,
      gstCharges: 34,
      OTTCharges: 91,
      totalAmount: 358,
      status: "Pending",
      JourneyDate: "2024-05-26",
    },
    {
      lastName: "Johnson",
      firstName: "Michael",
      age: 28,
      gender: "Female",
      RegNo: "REG450",
      country: "India",
      receiptNo: "REC914",
      address: "173 Elm St, City",
      oppCharges: 231,
      gstCharges: 35,
      OTTCharges: 98,
      totalAmount: 364,
      status: "Paid",
      JourneyDate: "2023-11-14",
    },
    {
      lastName: "Johnson",
      firstName: "Michael",
      age: 59,
      gender: "Female",
      RegNo: "REG938",
      country: "India",
      receiptNo: "REC970",
      address: "255 Elm St, City",
      oppCharges: 236,
      gstCharges: 34,
      OTTCharges: 87,
      totalAmount: 357,
      status: "Pending",
      JourneyDate: "2024-06-16",
    },
    {
      lastName: "Brown",
      firstName: "William",
      age: 37,
      gender: "Female",
      RegNo: "REG976",
      country: "Canada",
      receiptNo: "REC169",
      address: "202 Elm St, City",
      oppCharges: 121,
      gstCharges: 15,
      OTTCharges: 38,
      totalAmount: 174,
      status: "Paid",
      JourneyDate: "2023-05-01",
    },
    {
      lastName: "Smith",
      firstName: "Richard",
      age: 20,
      gender: "Female",
      RegNo: "REG433",
      country: "India",
      receiptNo: "REC272",
      address: "359 Elm St, City",
      oppCharges: 248,
      gstCharges: 16,
      OTTCharges: 15,
      totalAmount: 279,
      status: "Cancelled",
      JourneyDate: "2023-07-23",
    },
    {
      lastName: "Davis",
      firstName: "Michael",
      age: 28,
      gender: "Female",
      RegNo: "REG201",
      country: "India",
      receiptNo: "REC389",
      address: "639 Elm St, City",
      oppCharges: 235,
      gstCharges: 38,
      OTTCharges: 8,
      totalAmount: 281,
      status: "Pending",
      JourneyDate: "2023-05-14",
    },
    {
      lastName: "Smith",
      firstName: "David",
      age: 21,
      gender: "Male",
      RegNo: "REG138",
      country: "USA",
      receiptNo: "REC607",
      address: "386 Elm St, City",
      oppCharges: 173,
      gstCharges: 19,
      OTTCharges: 70,
      totalAmount: 262,
      status: "Paid",
      JourneyDate: "2023-10-18",
    },
    {
      lastName: "Brown",
      firstName: "Michael",
      age: 69,
      gender: "Male",
      RegNo: "REG163",
      country: "India",
      receiptNo: "REC554",
      address: "82 Elm St, City",
      oppCharges: 197,
      gstCharges: 27,
      OTTCharges: 68,
      totalAmount: 292,
      status: "Paid",
      JourneyDate: "2023-02-24",
    },
    {
      lastName: "Jones",
      firstName: "David",
      age: 54,
      gender: "Female",
      RegNo: "REG856",
      country: "USA",
      receiptNo: "REC139",
      address: "332 Elm St, City",
      oppCharges: 244,
      gstCharges: 37,
      OTTCharges: 34,
      totalAmount: 315,
      status: "Pending",
      JourneyDate: "2023-07-09",
    },
    {
      lastName: "Smith",
      firstName: "Richard",
      age: 67,
      gender: "Male",
      RegNo: "REG589",
      country: "Canada",
      receiptNo: "REC961",
      address: "87 Elm St, City",
      oppCharges: 240,
      gstCharges: 25,
      OTTCharges: 67,
      totalAmount: 332,
      status: "Pending",
      JourneyDate: "2023-02-24",
    },
    {
      lastName: "Johnson",
      firstName: "John",
      age: 63,
      gender: "Male",
      RegNo: "REG432",
      country: "UK",
      receiptNo: "REC508",
      address: "818 Elm St, City",
      oppCharges: 159,
      gstCharges: 31,
      OTTCharges: 11,
      totalAmount: 201,
      status: "Pending",
      JourneyDate: "2023-05-04",
    },
    {
      lastName: "Jones",
      firstName: "William",
      age: 68,
      gender: "Male",
      RegNo: "REG712",
      country: "India",
      receiptNo: "REC584",
      address: "805 Elm St, City",
      oppCharges: 143,
      gstCharges: 35,
      OTTCharges: 35,
      totalAmount: 213,
      status: "Cancelled",
      JourneyDate: "2023-06-17",
    },
    {
      lastName: "Miller",
      firstName: "David",
      age: 61,
      gender: "Female",
      RegNo: "REG286",
      country: "UK",
      receiptNo: "REC759",
      address: "319 Elm St, City",
      oppCharges: 141,
      gstCharges: 14,
      OTTCharges: 54,
      totalAmount: 209,
      status: "Paid",
      JourneyDate: "2023-03-25",
    },
    {
      lastName: "Smith",
      firstName: "Robert",
      age: 43,
      gender: "Male",
      RegNo: "REG961",
      country: "India",
      receiptNo: "REC742",
      address: "361 Elm St, City",
      oppCharges: 249,
      gstCharges: 30,
      OTTCharges: 71,
      totalAmount: 350,
      status: "Cancelled",
      JourneyDate: "2023-09-11",
    },
    {
      lastName: "Miller",
      firstName: "David",
      age: 40,
      gender: "Female",
      RegNo: "REG259",
      country: "USA",
      receiptNo: "REC384",
      address: "267 Elm St, City",
      oppCharges: 62,
      gstCharges: 17,
      OTTCharges: 82,
      totalAmount: 161,
      status: "Paid",
      JourneyDate: "2023-05-04",
    },
    {
      lastName: "Brown",
      firstName: "James",
      age: 26,
      gender: "Female",
      RegNo: "REG408",
      country: "UK",
      receiptNo: "REC900",
      address: "367 Elm St, City",
      oppCharges: 224,
      gstCharges: 35,
      OTTCharges: 30,
      totalAmount: 289,
      status: "Pending",
      JourneyDate: "2023-05-29",
    },
    {
      lastName: "Smith",
      firstName: "William",
      age: 21,
      gender: "Female",
      RegNo: "REG231",
      country: "USA",
      receiptNo: "REC348",
      address: "461 Elm St, City",
      oppCharges: 223,
      gstCharges: 33,
      OTTCharges: 50,
      totalAmount: 306,
      status: "Paid",
      JourneyDate: "2024-04-05",
    },
    {
      lastName: "Johnson",
      firstName: "William",
      age: 63,
      gender: "Male",
      RegNo: "REG335",
      country: "Australia",
      receiptNo: "REC622",
      address: "337 Elm St, City",
      oppCharges: 73,
      gstCharges: 12,
      OTTCharges: 98,
      totalAmount: 183,
      status: "Paid",
      JourneyDate: "2023-02-23",
    },
    {
      lastName: "Williams",
      firstName: "Robert",
      age: 57,
      gender: "Female",
      RegNo: "REG448",
      country: "Canada",
      receiptNo: "REC191",
      address: "944 Elm St, City",
      oppCharges: 181,
      gstCharges: 20,
      OTTCharges: 94,
      totalAmount: 295,
      status: "Paid",
      JourneyDate: "2024-04-30",
    },
    {
      lastName: "Miller",
      firstName: "William",
      age: 38,
      gender: "Male",
      RegNo: "REG512",
      country: "Australia",
      receiptNo: "REC513",
      address: "140 Elm St, City",
      oppCharges: 236,
      gstCharges: 13,
      OTTCharges: 88,
      totalAmount: 337,
      status: "Pending",
      JourneyDate: "2024-06-08",
    },
    {
      lastName: "Jones",
      firstName: "Michael",
      age: 61,
      gender: "Male",
      RegNo: "REG568",
      country: "India",
      receiptNo: "REC186",
      address: "723 Elm St, City",
      oppCharges: 84,
      gstCharges: 22,
      OTTCharges: 48,
      totalAmount: 154,
      status: "Pending",
      JourneyDate: "2024-04-27",
    },
    {
      lastName: "Williams",
      firstName: "Robert",
      age: 58,
      gender: "Male",
      RegNo: "REG306",
      country: "Canada",
      receiptNo: "REC925",
      address: "894 Elm St, City",
      oppCharges: 84,
      gstCharges: 23,
      OTTCharges: 13,
      totalAmount: 120,
      status: "Paid",
      JourneyDate: "2023-06-12",
    },
    {
      lastName: "Smith",
      firstName: "William",
      age: 27,
      gender: "Female",
      RegNo: "REG178",
      country: "UK",
      receiptNo: "REC823",
      address: "282 Elm St, City",
      oppCharges: 178,
      gstCharges: 13,
      OTTCharges: 37,
      totalAmount: 228,
      status: "Cancelled",
      JourneyDate: "2023-04-21",
    },
    {
      lastName: "Johnson",
      firstName: "James",
      age: 55,
      gender: "Male",
      RegNo: "REG452",
      country: "USA",
      receiptNo: "REC704",
      address: "396 Elm St, City",
      oppCharges: 95,
      gstCharges: 16,
      OTTCharges: 39,
      totalAmount: 150,
      status: "Paid",
      JourneyDate: "2023-02-06",
    },
    {
      lastName: "Smith",
      firstName: "William",
      age: 22,
      gender: "Female",
      RegNo: "REG582",
      country: "USA",
      receiptNo: "REC477",
      address: "715 Elm St, City",
      oppCharges: 88,
      gstCharges: 15,
      OTTCharges: 0,
      totalAmount: 103,
      status: "Paid",
      JourneyDate: "2024-01-20",
    },
    {
      lastName: "Smith",
      firstName: "Robert",
      age: 55,
      gender: "Male",
      RegNo: "REG559",
      country: "USA",
      receiptNo: "REC864",
      address: "271 Elm St, City",
      oppCharges: 215,
      gstCharges: 16,
      OTTCharges: 15,
      totalAmount: 246,
      status: "Paid",
      JourneyDate: "2023-03-20",
    },
    {
      lastName: "Smith",
      firstName: "Richard",
      age: 57,
      gender: "Male",
      RegNo: "REG243",
      country: "Canada",
      receiptNo: "REC229",
      address: "928 Elm St, City",
      oppCharges: 195,
      gstCharges: 27,
      OTTCharges: 41,
      totalAmount: 263,
      status: "Cancelled",
      JourneyDate: "2024-03-14",
    },
    {
      lastName: "Williams",
      firstName: "James",
      age: 56,
      gender: "Female",
      RegNo: "REG130",
      country: "USA",
      receiptNo: "REC164",
      address: "636 Elm St, City",
      oppCharges: 235,
      gstCharges: 29,
      OTTCharges: 32,
      totalAmount: 296,
      status: "Pending",
      JourneyDate: "2023-11-19",
    },
    {
      lastName: "Williams",
      firstName: "Richard",
      age: 46,
      gender: "Female",
      RegNo: "REG422",
      country: "Australia",
      receiptNo: "REC556",
      address: "649 Elm St, City",
      oppCharges: 68,
      gstCharges: 21,
      OTTCharges: 46,
      totalAmount: 135,
      status: "Cancelled",
      JourneyDate: "2023-04-08",
    },
    {
      lastName: "Smith",
      firstName: "Robert",
      age: 62,
      gender: "Male",
      RegNo: "REG306",
      country: "India",
      receiptNo: "REC565",
      address: "989 Elm St, City",
      oppCharges: 168,
      gstCharges: 16,
      OTTCharges: 65,
      totalAmount: 249,
      status: "Pending",
      JourneyDate: "2024-07-06",
    },
    {
      lastName: "Smith",
      firstName: "David",
      age: 61,
      gender: "Male",
      RegNo: "REG149",
      country: "Canada",
      receiptNo: "REC476",
      address: "933 Elm St, City",
      oppCharges: 218,
      gstCharges: 14,
      OTTCharges: 93,
      totalAmount: 325,
      status: "Cancelled",
      JourneyDate: "2023-05-01",
    },
    {
      lastName: "Johnson",
      firstName: "Michael",
      age: 60,
      gender: "Female",
      RegNo: "REG185",
      country: "India",
      receiptNo: "REC274",
      address: "166 Elm St, City",
      oppCharges: 219,
      gstCharges: 29,
      OTTCharges: 55,
      totalAmount: 303,
      status: "Pending",
      JourneyDate: "2024-02-05",
    },
    {
      lastName: "Brown",
      firstName: "John",
      age: 32,
      gender: "Female",
      RegNo: "REG693",
      country: "Canada",
      receiptNo: "REC792",
      address: "495 Elm St, City",
      oppCharges: 111,
      gstCharges: 25,
      OTTCharges: 38,
      totalAmount: 174,
      status: "Pending",
      JourneyDate: "2023-07-15",
    },
    {
      lastName: "Davis",
      firstName: "Michael",
      age: 36,
      gender: "Female",
      RegNo: "REG128",
      country: "Australia",
      receiptNo: "REC706",
      address: "204 Elm St, City",
      oppCharges: 72,
      gstCharges: 38,
      OTTCharges: 30,
      totalAmount: 140,
      status: "Paid",
      JourneyDate: "2023-10-10",
    },
    {
      lastName: "Davis",
      firstName: "Richard",
      age: 65,
      gender: "Male",
      RegNo: "REG284",
      country: "UK",
      receiptNo: "REC921",
      address: "882 Elm St, City",
      oppCharges: 195,
      gstCharges: 24,
      OTTCharges: 14,
      totalAmount: 233,
      status: "Paid",
      JourneyDate: "2024-05-14",
    },
    {
      lastName: "Jones",
      firstName: "John",
      age: 23,
      gender: "Female",
      RegNo: "REG173",
      country: "Canada",
      receiptNo: "REC743",
      address: "324 Elm St, City",
      oppCharges: 164,
      gstCharges: 16,
      OTTCharges: 72,
      totalAmount: 252,
      status: "Pending",
      JourneyDate: "2023-03-30",
    },
    {
      lastName: "Jones",
      firstName: "John",
      age: 38,
      gender: "Male",
      RegNo: "REG250",
      country: "Australia",
      receiptNo: "REC299",
      address: "664 Elm St, City",
      oppCharges: 81,
      gstCharges: 38,
      OTTCharges: 14,
      totalAmount: 133,
      status: "Cancelled",
      JourneyDate: "2024-02-22",
    },
    {
      lastName: "Jones",
      firstName: "Richard",
      age: 61,
      gender: "Male",
      RegNo: "REG22",
      country: "UK",
      receiptNo: "REC732",
      address: "841 Elm St, City",
      oppCharges: 166,
      gstCharges: 20,
      OTTCharges: 15,
      totalAmount: 201,
      status: "Cancelled",
      JourneyDate: "2023-10-17",
    },
    {
      lastName: "Jones",
      firstName: "Michael",
      age: 68,
      gender: "Male",
      RegNo: "REG107",
      country: "UK",
      receiptNo: "REC217",
      address: "191 Elm St, City",
      oppCharges: 117,
      gstCharges: 30,
      OTTCharges: 58,
      totalAmount: 205,
      status: "Pending",
      JourneyDate: "2023-07-07",
    },
    {
      lastName: "Miller",
      firstName: "Richard",
      age: 67,
      gender: "Female",
      RegNo: "REG441",
      country: "USA",
      receiptNo: "REC928",
      address: "630 Elm St, City",
      oppCharges: 238,
      gstCharges: 35,
      OTTCharges: 94,
      totalAmount: 367,
      status: "Cancelled",
      JourneyDate: "2023-03-06",
    },
    {
      lastName: "Smith",
      firstName: "Robert",
      age: 31,
      gender: "Female",
      RegNo: "REG981",
      country: "UK",
      receiptNo: "REC484",
      address: "884 Elm St, City",
      oppCharges: 93,
      gstCharges: 18,
      OTTCharges: 99,
      totalAmount: 210,
      status: "Paid",
      JourneyDate: "2023-09-19",
    },
    {
      lastName: "Davis",
      firstName: "Michael",
      age: 20,
      gender: "Female",
      RegNo: "REG707",
      country: "India",
      receiptNo: "REC453",
      address: "768 Elm St, City",
      oppCharges: 220,
      gstCharges: 38,
      OTTCharges: 65,
      totalAmount: 323,
      status: "Pending",
      JourneyDate: "2024-07-05",
    },
    {
      lastName: "Miller",
      firstName: "Michael",
      age: 68,
      gender: "Female",
      RegNo: "REG188",
      country: "India",
      receiptNo: "REC397",
      address: "412 Elm St, City",
      oppCharges: 167,
      gstCharges: 31,
      OTTCharges: 24,
      totalAmount: 222,
      status: "Cancelled",
      JourneyDate: "2024-06-29",
    },
    {
      lastName: "Brown",
      firstName: "Robert",
      age: 43,
      gender: "Male",
      RegNo: "REG722",
      country: "USA",
      receiptNo: "REC238",
      address: "379 Elm St, City",
      oppCharges: 209,
      gstCharges: 20,
      OTTCharges: 61,
      totalAmount: 290,
      status: "Cancelled",
      JourneyDate: "2023-09-20",
    },
    {
      lastName: "Miller",
      firstName: "John",
      age: 29,
      gender: "Female",
      RegNo: "REG207",
      country: "India",
      receiptNo: "REC342",
      address: "309 Elm St, City",
      oppCharges: 173,
      gstCharges: 26,
      OTTCharges: 45,
      totalAmount: 244,
      status: "Cancelled",
      JourneyDate: "2023-05-08",
    },
    {
      lastName: "Miller",
      firstName: "Robert",
      age: 50,
      gender: "Male",
      RegNo: "REG741",
      country: "Australia",
      receiptNo: "REC408",
      address: "281 Elm St, City",
      oppCharges: 130,
      gstCharges: 27,
      OTTCharges: 83,
      totalAmount: 240,
      status: "Cancelled",
      JourneyDate: "2023-12-08",
    },
    {
      lastName: "Miller",
      firstName: "James",
      age: 28,
      gender: "Female",
      RegNo: "REG426",
      country: "UK",
      receiptNo: "REC815",
      address: "717 Elm St, City",
      oppCharges: 213,
      gstCharges: 13,
      OTTCharges: 32,
      totalAmount: 258,
      status: "Pending",
      JourneyDate: "2023-07-27",
    },
    {
      lastName: "Davis",
      firstName: "David",
      age: 35,
      gender: "Female",
      RegNo: "REG317",
      country: "Canada",
      receiptNo: "REC110",
      address: "521 Elm St, City",
      oppCharges: 205,
      gstCharges: 38,
      OTTCharges: 90,
      totalAmount: 333,
      status: "Pending",
      JourneyDate: "2023-04-06",
    },
    {
      lastName: "Williams",
      firstName: "Michael",
      age: 64,
      gender: "Female",
      RegNo: "REG544",
      country: "Australia",
      receiptNo: "REC353",
      address: "771 Elm St, City",
      oppCharges: 215,
      gstCharges: 34,
      OTTCharges: 90,
      totalAmount: 339,
      status: "Pending",
      JourneyDate: "2024-01-29",
    },
    {
      lastName: "Miller",
      firstName: "Richard",
      age: 69,
      gender: "Female",
      RegNo: "REG136",
      country: "Canada",
      receiptNo: "REC912",
      address: "728 Elm St, City",
      oppCharges: 117,
      gstCharges: 21,
      OTTCharges: 38,
      totalAmount: 176,
      status: "Paid",
      JourneyDate: "2023-06-05",
    },
    {
      lastName: "Smith",
      firstName: "William",
      age: 21,
      gender: "Female",
      RegNo: "REG13",
      country: "USA",
      receiptNo: "REC422",
      address: "336 Elm St, City",
      oppCharges: 205,
      gstCharges: 22,
      OTTCharges: 7,
      totalAmount: 234,
      status: "Paid",
      JourneyDate: "2023-03-17",
    },
    {
      lastName: "Jones",
      firstName: "Richard",
      age: 60,
      gender: "Female",
      RegNo: "REG97",
      country: "Canada",
      receiptNo: "REC742",
      address: "631 Elm St, City",
      oppCharges: 55,
      gstCharges: 21,
      OTTCharges: 87,
      totalAmount: 163,
      status: "Paid",
      JourneyDate: "2023-06-05",
    },
    {
      lastName: "Miller",
      firstName: "Robert",
      age: 63,
      gender: "Male",
      RegNo: "REG890",
      country: "India",
      receiptNo: "REC218",
      address: "866 Elm St, City",
      oppCharges: 174,
      gstCharges: 16,
      OTTCharges: 78,
      totalAmount: 268,
      status: "Pending",
      JourneyDate: "2023-03-21",
    },
    {
      lastName: "Jones",
      firstName: "John",
      age: 44,
      gender: "Female",
      RegNo: "REG981",
      country: "Australia",
      receiptNo: "REC344",
      address: "933 Elm St, City",
      oppCharges: 61,
      gstCharges: 12,
      OTTCharges: 68,
      totalAmount: 141,
      status: "Pending",
      JourneyDate: "2023-04-27",
    },
    {
      lastName: "Johnson",
      firstName: "Richard",
      age: 22,
      gender: "Female",
      RegNo: "REG226",
      country: "USA",
      receiptNo: "REC161",
      address: "267 Elm St, City",
      oppCharges: 166,
      gstCharges: 39,
      OTTCharges: 93,
      totalAmount: 298,
      status: "Pending",
      JourneyDate: "2023-02-28",
    },
    {
      lastName: "Smith",
      firstName: "David",
      age: 24,
      gender: "Male",
      RegNo: "REG300",
      country: "India",
      receiptNo: "REC508",
      address: "736 Elm St, City",
      oppCharges: 223,
      gstCharges: 24,
      OTTCharges: 80,
      totalAmount: 327,
      status: "Paid",
      JourneyDate: "2023-08-31",
    },
    {
      lastName: "Jones",
      firstName: "John",
      age: 30,
      gender: "Male",
      RegNo: "REG246",
      country: "USA",
      receiptNo: "REC380",
      address: "901 Elm St, City",
      oppCharges: 225,
      gstCharges: 28,
      OTTCharges: 58,
      totalAmount: 311,
      status: "Pending",
      JourneyDate: "2023-11-24",
    },
    {
      lastName: "Miller",
      firstName: "William",
      age: 49,
      gender: "Male",
      RegNo: "REG604",
      country: "Canada",
      receiptNo: "REC262",
      address: "797 Elm St, City",
      oppCharges: 102,
      gstCharges: 15,
      OTTCharges: 96,
      totalAmount: 213,
      status: "Pending",
      JourneyDate: "2024-05-08",
    },
    {
      lastName: "Johnson",
      firstName: "John",
      age: 59,
      gender: "Female",
      RegNo: "REG148",
      country: "India",
      receiptNo: "REC681",
      address: "808 Elm St, City",
      oppCharges: 83,
      gstCharges: 24,
      OTTCharges: 31,
      totalAmount: 138,
      status: "Pending",
      JourneyDate: "2023-01-11",
    },
    {
      lastName: "Davis",
      firstName: "John",
      age: 21,
      gender: "Male",
      RegNo: "REG596",
      country: "India",
      receiptNo: "REC225",
      address: "875 Elm St, City",
      oppCharges: 140,
      gstCharges: 21,
      OTTCharges: 80,
      totalAmount: 241,
      status: "Pending",
      JourneyDate: "2023-09-19",
    },
    {
      lastName: "Williams",
      firstName: "Richard",
      age: 27,
      gender: "Male",
      RegNo: "REG61",
      country: "Canada",
      receiptNo: "REC486",
      address: "513 Elm St, City",
      oppCharges: 107,
      gstCharges: 32,
      OTTCharges: 18,
      totalAmount: 157,
      status: "Cancelled",
      JourneyDate: "2023-02-09",
    },
    {
      lastName: "Williams",
      firstName: "Richard",
      age: 48,
      gender: "Female",
      RegNo: "REG904",
      country: "Australia",
      receiptNo: "REC777",
      address: "137 Elm St, City",
      oppCharges: 69,
      gstCharges: 10,
      OTTCharges: 87,
      totalAmount: 166,
      status: "Pending",
      JourneyDate: "2023-02-20",
    },
    {
      lastName: "Smith",
      firstName: "William",
      age: 51,
      gender: "Male",
      RegNo: "REG403",
      country: "Canada",
      receiptNo: "REC882",
      address: "731 Elm St, City",
      oppCharges: 113,
      gstCharges: 23,
      OTTCharges: 76,
      totalAmount: 212,
      status: "Cancelled",
      JourneyDate: "2024-02-07",
    },
    {
      lastName: "Davis",
      firstName: "John",
      age: 21,
      gender: "Male",
      RegNo: "REG662",
      country: "USA",
      receiptNo: "REC826",
      address: "470 Elm St, City",
      oppCharges: 239,
      gstCharges: 12,
      OTTCharges: 25,
      totalAmount: 276,
      status: "Cancelled",
      JourneyDate: "2024-01-30",
    },
    {
      lastName: "Brown",
      firstName: "John",
      age: 23,
      gender: "Male",
      RegNo: "REG221",
      country: "Canada",
      receiptNo: "REC417",
      address: "851 Elm St, City",
      oppCharges: 57,
      gstCharges: 31,
      OTTCharges: 65,
      totalAmount: 153,
      status: "Paid",
      JourneyDate: "2024-03-25",
    },
    {
      lastName: "Johnson",
      firstName: "James",
      age: 22,
      gender: "Female",
      RegNo: "REG728",
      country: "Australia",
      receiptNo: "REC495",
      address: "290 Elm St, City",
      oppCharges: 123,
      gstCharges: 21,
      OTTCharges: 3,
      totalAmount: 147,
      status: "Paid",
      JourneyDate: "2024-07-04",
    },
    {
      lastName: "Smith",
      firstName: "Michael",
      age: 30,
      gender: "Male",
      RegNo: "REG755",
      country: "USA",
      receiptNo: "REC936",
      address: "252 Elm St, City",
      oppCharges: 188,
      gstCharges: 27,
      OTTCharges: 59,
      totalAmount: 274,
      status: "Paid",
      JourneyDate: "2023-12-01",
    },
    {
      lastName: "Jones",
      firstName: "James",
      age: 57,
      gender: "Male",
      RegNo: "REG261",
      country: "Australia",
      receiptNo: "REC439",
      address: "752 Elm St, City",
      oppCharges: 234,
      gstCharges: 20,
      OTTCharges: 6,
      totalAmount: 260,
      status: "Cancelled",
      JourneyDate: "2023-02-08",
    },
    {
      lastName: "Williams",
      firstName: "Richard",
      age: 60,
      gender: "Female",
      RegNo: "REG529",
      country: "India",
      receiptNo: "REC116",
      address: "95 Elm St, City",
      oppCharges: 242,
      gstCharges: 18,
      OTTCharges: 9,
      totalAmount: 269,
      status: "Pending",
      JourneyDate: "2023-08-21",
    },
    {
      lastName: "Davis",
      firstName: "William",
      age: 55,
      gender: "Female",
      RegNo: "REG291",
      country: "India",
      receiptNo: "REC854",
      address: "110 Elm St, City",
      oppCharges: 59,
      gstCharges: 30,
      OTTCharges: 78,
      totalAmount: 167,
      status: "Pending",
      JourneyDate: "2023-10-29",
    },
    {
      lastName: "Johnson",
      firstName: "Richard",
      age: 66,
      gender: "Male",
      RegNo: "REG723",
      country: "India",
      receiptNo: "REC422",
      address: "227 Elm St, City",
      oppCharges: 81,
      gstCharges: 13,
      OTTCharges: 94,
      totalAmount: 188,
      status: "Paid",
      JourneyDate: "2023-07-19",
    },
    {
      lastName: "Davis",
      firstName: "Richard",
      age: 50,
      gender: "Female",
      RegNo: "REG629",
      country: "India",
      receiptNo: "REC178",
      address: "61 Elm St, City",
      oppCharges: 247,
      gstCharges: 37,
      OTTCharges: 12,
      totalAmount: 296,
      status: "Pending",
      JourneyDate: "2024-03-26",
    },
    {
      lastName: "Davis",
      firstName: "William",
      age: 31,
      gender: "Female",
      RegNo: "REG106",
      country: "Canada",
      receiptNo: "REC495",
      address: "945 Elm St, City",
      oppCharges: 87,
      gstCharges: 39,
      OTTCharges: 51,
      totalAmount: 177,
      status: "Paid",
      JourneyDate: "2023-04-30",
    },
    {
      lastName: "Brown",
      firstName: "Richard",
      age: 36,
      gender: "Female",
      RegNo: "REG475",
      country: "Australia",
      receiptNo: "REC754",
      address: "317 Elm St, City",
      oppCharges: 160,
      gstCharges: 34,
      OTTCharges: 9,
      totalAmount: 203,
      status: "Cancelled",
      JourneyDate: "2023-02-18",
    },
    {
      lastName: "Brown",
      firstName: "William",
      age: 50,
      gender: "Male",
      RegNo: "REG938",
      country: "India",
      receiptNo: "REC287",
      address: "744 Elm St, City",
      oppCharges: 153,
      gstCharges: 21,
      OTTCharges: 80,
      totalAmount: 254,
      status: "Paid",
      JourneyDate: "2024-02-10",
    },
    {
      lastName: "Jones",
      firstName: "William",
      age: 20,
      gender: "Female",
      RegNo: "REG310",
      country: "UK",
      receiptNo: "REC984",
      address: "490 Elm St, City",
      oppCharges: 132,
      gstCharges: 18,
      OTTCharges: 77,
      totalAmount: 227,
      status: "Paid",
      JourneyDate: "2023-08-06",
    },
    {
      lastName: "Miller",
      firstName: "Michael",
      age: 22,
      gender: "Male",
      RegNo: "REG956",
      country: "UK",
      receiptNo: "REC92",
      address: "623 Elm St, City",
      oppCharges: 218,
      gstCharges: 17,
      OTTCharges: 49,
      totalAmount: 284,
      status: "Pending",
      JourneyDate: "2023-02-13",
    },
    {
      lastName: "Johnson",
      firstName: "William",
      age: 44,
      gender: "Male",
      RegNo: "REG697",
      country: "Canada",
      receiptNo: "REC474",
      address: "207 Elm St, City",
      oppCharges: 131,
      gstCharges: 26,
      OTTCharges: 35,
      totalAmount: 192,
      status: "Paid",
      JourneyDate: "2022-12-31",
    },
    {
      lastName: "Smith",
      firstName: "Michael",
      age: 68,
      gender: "Male",
      RegNo: "REG322",
      country: "USA",
      receiptNo: "REC959",
      address: "248 Elm St, City",
      oppCharges: 204,
      gstCharges: 37,
      OTTCharges: 33,
      totalAmount: 274,
      status: "Pending",
      JourneyDate: "2023-03-26",
    },
    {
      lastName: "Miller",
      firstName: "William",
      age: 58,
      gender: "Female",
      RegNo: "REG413",
      country: "UK",
      receiptNo: "REC710",
      address: "867 Elm St, City",
      oppCharges: 111,
      gstCharges: 31,
      OTTCharges: 79,
      totalAmount: 221,
      status: "Cancelled",
      JourneyDate: "2024-06-10",
    },
    {
      lastName: "Jones",
      firstName: "John",
      age: 66,
      gender: "Male",
      RegNo: "REG172",
      country: "Australia",
      receiptNo: "REC491",
      address: "845 Elm St, City",
      oppCharges: 72,
      gstCharges: 10,
      OTTCharges: 74,
      totalAmount: 156,
      status: "Cancelled",
      JourneyDate: "2024-05-10",
    },
    {
      lastName: "Jones",
      firstName: "James",
      age: 64,
      gender: "Male",
      RegNo: "REG153",
      country: "India",
      receiptNo: "REC588",
      address: "220 Elm St, City",
      oppCharges: 124,
      gstCharges: 13,
      OTTCharges: 31,
      totalAmount: 168,
      status: "Paid",
      JourneyDate: "2023-11-01",
    },
    {
      lastName: "Brown",
      firstName: "James",
      age: 20,
      gender: "Female",
      RegNo: "REG779",
      country: "USA",
      receiptNo: "REC514",
      address: "67 Elm St, City",
      oppCharges: 236,
      gstCharges: 18,
      OTTCharges: 71,
      totalAmount: 325,
      status: "Paid",
      JourneyDate: "2024-04-24",
    },
    {
      lastName: "Williams",
      firstName: "Robert",
      age: 47,
      gender: "Female",
      RegNo: "REG447",
      country: "India",
      receiptNo: "REC506",
      address: "107 Elm St, City",
      oppCharges: 162,
      gstCharges: 10,
      OTTCharges: 50,
      totalAmount: 222,
      status: "Cancelled",
      JourneyDate: "2023-08-06",
    },
    {
      lastName: "Smith",
      firstName: "Richard",
      age: 38,
      gender: "Female",
      RegNo: "REG737",
      country: "USA",
      receiptNo: "REC698",
      address: "269 Elm St, City",
      oppCharges: 81,
      gstCharges: 30,
      OTTCharges: 78,
      totalAmount: 189,
      status: "Pending",
      JourneyDate: "2023-03-09",
    },
    {
      lastName: "Jones",
      firstName: "Michael",
      age: 34,
      gender: "Female",
      RegNo: "REG407",
      country: "Australia",
      receiptNo: "REC188",
      address: "328 Elm St, City",
      oppCharges: 142,
      gstCharges: 21,
      OTTCharges: 37,
      totalAmount: 200,
      status: "Paid",
      JourneyDate: "2023-04-19",
    },
    {
      lastName: "Davis",
      firstName: "James",
      age: 38,
      gender: "Female",
      RegNo: "REG295",
      country: "India",
      receiptNo: "REC965",
      address: "647 Elm St, City",
      oppCharges: 164,
      gstCharges: 14,
      OTTCharges: 35,
      totalAmount: 213,
      status: "Paid",
      JourneyDate: "2023-01-27",
    },
    {
      lastName: "Brown",
      firstName: "Richard",
      age: 44,
      gender: "Male",
      RegNo: "REG210",
      country: "USA",
      receiptNo: "REC29",
      address: "410 Elm St, City",
      oppCharges: 80,
      gstCharges: 37,
      OTTCharges: 92,
      totalAmount: 209,
      status: "Paid",
      JourneyDate: "2023-07-06",
    },
    {
      lastName: "Brown",
      firstName: "William",
      age: 54,
      gender: "Female",
      RegNo: "REG615",
      country: "India",
      receiptNo: "REC706",
      address: "759 Elm St, City",
      oppCharges: 73,
      gstCharges: 16,
      OTTCharges: 71,
      totalAmount: 160,
      status: "Pending",
      JourneyDate: "2023-06-23",
    },
    {
      lastName: "Miller",
      firstName: "Richard",
      age: 45,
      gender: "Male",
      RegNo: "REG124",
      country: "USA",
      receiptNo: "REC575",
      address: "761 Elm St, City",
      oppCharges: 195,
      gstCharges: 28,
      OTTCharges: 98,
      totalAmount: 321,
      status: "Cancelled",
      JourneyDate: "2023-03-04",
    },
    {
      lastName: "Williams",
      firstName: "Richard",
      age: 48,
      gender: "Male",
      RegNo: "REG322",
      country: "Canada",
      receiptNo: "REC138",
      address: "748 Elm St, City",
      oppCharges: 62,
      gstCharges: 34,
      OTTCharges: 66,
      totalAmount: 162,
      status: "Paid",
      JourneyDate: "2023-01-14",
    },
    {
      lastName: "Johnson",
      firstName: "John",
      age: 22,
      gender: "Female",
      RegNo: "REG295",
      country: "Australia",
      receiptNo: "REC745",
      address: "366 Elm St, City",
      oppCharges: 141,
      gstCharges: 25,
      OTTCharges: 73,
      totalAmount: 239,
      status: "Cancelled",
      JourneyDate: "2023-12-02",
    },
    {
      lastName: "Davis",
      firstName: "David",
      age: 48,
      gender: "Male",
      RegNo: "REG777",
      country: "USA",
      receiptNo: "REC286",
      address: "533 Elm St, City",
      oppCharges: 76,
      gstCharges: 35,
      OTTCharges: 28,
      totalAmount: 139,
      status: "Cancelled",
      JourneyDate: "2024-06-05",
    },
    {
      lastName: "Davis",
      firstName: "Robert",
      age: 23,
      gender: "Female",
      RegNo: "REG129",
      country: "USA",
      receiptNo: "REC728",
      address: "610 Elm St, City",
      oppCharges: 73,
      gstCharges: 10,
      OTTCharges: 59,
      totalAmount: 142,
      status: "Cancelled",
      JourneyDate: "2023-06-02",
    },
    {
      lastName: "Johnson",
      firstName: "William",
      age: 42,
      gender: "Male",
      RegNo: "REG39",
      country: "UK",
      receiptNo: "REC368",
      address: "687 Elm St, City",
      oppCharges: 81,
      gstCharges: 24,
      OTTCharges: 89,
      totalAmount: 194,
      status: "Paid",
      JourneyDate: "2023-07-17",
    },
    {
      lastName: "Jones",
      firstName: "James",
      age: 60,
      gender: "Male",
      RegNo: "REG981",
      country: "Australia",
      receiptNo: "REC588",
      address: "714 Elm St, City",
      oppCharges: 147,
      gstCharges: 10,
      OTTCharges: 94,
      totalAmount: 251,
      status: "Cancelled",
      JourneyDate: "2023-02-13",
    },
    {
      lastName: "Smith",
      firstName: "Richard",
      age: 28,
      gender: "Male",
      RegNo: "REG201",
      country: "India",
      receiptNo: "REC339",
      address: "320 Elm St, City",
      oppCharges: 120,
      gstCharges: 34,
      OTTCharges: 73,
      totalAmount: 227,
      status: "Cancelled",
      JourneyDate: "2023-11-01",
    },
    {
      lastName: "Johnson",
      firstName: "Richard",
      age: 21,
      gender: "Female",
      RegNo: "REG204",
      country: "Australia",
      receiptNo: "REC576",
      address: "371 Elm St, City",
      oppCharges: 124,
      gstCharges: 20,
      OTTCharges: 93,
      totalAmount: 237,
      status: "Paid",
      JourneyDate: "2023-09-29",
    },
    {
      lastName: "Miller",
      firstName: "Robert",
      age: 51,
      gender: "Female",
      RegNo: "REG606",
      country: "Australia",
      receiptNo: "REC488",
      address: "262 Elm St, City",
      oppCharges: 158,
      gstCharges: 11,
      OTTCharges: 10,
      totalAmount: 179,
      status: "Paid",
      JourneyDate: "2023-04-20",
    },
    {
      lastName: "Smith",
      firstName: "William",
      age: 61,
      gender: "Female",
      RegNo: "REG607",
      country: "Australia",
      receiptNo: "REC36",
      address: "802 Elm St, City",
      oppCharges: 220,
      gstCharges: 10,
      OTTCharges: 54,
      totalAmount: 284,
      status: "Paid",
      JourneyDate: "2023-10-02",
    },
    {
      lastName: "Brown",
      firstName: "William",
      age: 63,
      gender: "Female",
      RegNo: "REG328",
      country: "Canada",
      receiptNo: "REC658",
      address: "392 Elm St, City",
      oppCharges: 147,
      gstCharges: 35,
      OTTCharges: 23,
      totalAmount: 205,
      status: "Paid",
      JourneyDate: "2023-11-09",
    },
    {
      lastName: "Brown",
      firstName: "John",
      age: 45,
      gender: "Male",
      RegNo: "REG858",
      country: "USA",
      receiptNo: "REC13",
      address: "436 Elm St, City",
      oppCharges: 194,
      gstCharges: 14,
      OTTCharges: 82,
      totalAmount: 290,
      status: "Pending",
      JourneyDate: "2023-07-11",
    },
    {
      lastName: "Jones",
      firstName: "Richard",
      age: 47,
      gender: "Female",
      RegNo: "REG434",
      country: "Canada",
      receiptNo: "REC641",
      address: "314 Elm St, City",
      oppCharges: 164,
      gstCharges: 38,
      OTTCharges: 83,
      totalAmount: 285,
      status: "Cancelled",
      JourneyDate: "2023-11-28",
    },
    {
      lastName: "Jones",
      firstName: "Richard",
      age: 40,
      gender: "Female",
      RegNo: "REG509",
      country: "Australia",
      receiptNo: "REC299",
      address: "525 Elm St, City",
      oppCharges: 174,
      gstCharges: 18,
      OTTCharges: 76,
      totalAmount: 268,
      status: "Pending",
      JourneyDate: "2023-10-13",
    },
    {
      lastName: "Smith",
      firstName: "David",
      age: 20,
      gender: "Female",
      RegNo: "REG827",
      country: "Australia",
      receiptNo: "REC988",
      address: "492 Elm St, City",
      oppCharges: 203,
      gstCharges: 37,
      OTTCharges: 98,
      totalAmount: 338,
      status: "Pending",
      JourneyDate: "2023-08-29",
    },
    {
      lastName: "Brown",
      firstName: "Robert",
      age: 47,
      gender: "Male",
      RegNo: "REG840",
      country: "Canada",
      receiptNo: "REC163",
      address: "872 Elm St, City",
      oppCharges: 234,
      gstCharges: 16,
      OTTCharges: 91,
      totalAmount: 341,
      status: "Pending",
      JourneyDate: "2023-12-18",
    },
    {
      lastName: "Brown",
      firstName: "James",
      age: 47,
      gender: "Male",
      RegNo: "REG89",
      country: "Australia",
      receiptNo: "REC921",
      address: "357 Elm St, City",
      oppCharges: 136,
      gstCharges: 12,
      OTTCharges: 27,
      totalAmount: 175,
      status: "Paid",
      JourneyDate: "2023-03-08",
    },
    {
      lastName: "Jones",
      firstName: "David",
      age: 32,
      gender: "Female",
      RegNo: "REG79",
      country: "USA",
      receiptNo: "REC446",
      address: "367 Elm St, City",
      oppCharges: 104,
      gstCharges: 21,
      OTTCharges: 43,
      totalAmount: 168,
      status: "Paid",
      JourneyDate: "2023-05-01",
    },
    {
      lastName: "Smith",
      firstName: "David",
      age: 25,
      gender: "Female",
      RegNo: "REG599",
      country: "UK",
      receiptNo: "REC629",
      address: "650 Elm St, City",
      oppCharges: 215,
      gstCharges: 28,
      OTTCharges: 40,
      totalAmount: 283,
      status: "Paid",
      JourneyDate: "2024-05-27",
    },
    {
      lastName: "Miller",
      firstName: "Michael",
      age: 35,
      gender: "Female",
      RegNo: "REG659",
      country: "USA",
      receiptNo: "REC390",
      address: "40 Elm St, City",
      oppCharges: 229,
      gstCharges: 16,
      OTTCharges: 83,
      totalAmount: 328,
      status: "Cancelled",
      JourneyDate: "2023-09-27",
    },
    {
      lastName: "Davis",
      firstName: "Richard",
      age: 42,
      gender: "Male",
      RegNo: "REG106",
      country: "Australia",
      receiptNo: "REC533",
      address: "721 Elm St, City",
      oppCharges: 155,
      gstCharges: 13,
      OTTCharges: 71,
      totalAmount: 239,
      status: "Cancelled",
      JourneyDate: "2023-03-08",
    },
    {
      lastName: "Miller",
      firstName: "Michael",
      age: 41,
      gender: "Male",
      RegNo: "REG7",
      country: "Canada",
      receiptNo: "REC766",
      address: "935 Elm St, City",
      oppCharges: 130,
      gstCharges: 27,
      OTTCharges: 66,
      totalAmount: 223,
      status: "Pending",
      JourneyDate: "2023-12-25",
    },
    {
      lastName: "Smith",
      firstName: "Robert",
      age: 61,
      gender: "Female",
      RegNo: "REG708",
      country: "USA",
      receiptNo: "REC152",
      address: "421 Elm St, City",
      oppCharges: 146,
      gstCharges: 19,
      OTTCharges: 37,
      totalAmount: 202,
      status: "Cancelled",
      JourneyDate: "2024-06-20",
    },
    {
      lastName: "Jones",
      firstName: "Michael",
      age: 33,
      gender: "Female",
      RegNo: "REG171",
      country: "Australia",
      receiptNo: "REC906",
      address: "922 Elm St, City",
      oppCharges: 94,
      gstCharges: 20,
      OTTCharges: 62,
      totalAmount: 176,
      status: "Pending",
      JourneyDate: "2023-11-15",
    },
    {
      lastName: "Johnson",
      firstName: "Richard",
      age: 60,
      gender: "Male",
      RegNo: "REG779",
      country: "India",
      receiptNo: "REC627",
      address: "784 Elm St, City",
      oppCharges: 72,
      gstCharges: 39,
      OTTCharges: 86,
      totalAmount: 197,
      status: "Paid",
      JourneyDate: "2024-05-11",
    },
    {
      lastName: "Johnson",
      firstName: "David",
      age: 62,
      gender: "Female",
      RegNo: "REG863",
      country: "Canada",
      receiptNo: "REC291",
      address: "637 Elm St, City",
      oppCharges: 140,
      gstCharges: 16,
      OTTCharges: 85,
      totalAmount: 241,
      status: "Paid",
      JourneyDate: "2023-10-20",
    },
    {
      lastName: "Jones",
      firstName: "John",
      age: 51,
      gender: "Male",
      RegNo: "REG244",
      country: "UK",
      receiptNo: "REC234",
      address: "384 Elm St, City",
      oppCharges: 81,
      gstCharges: 20,
      OTTCharges: 35,
      totalAmount: 136,
      status: "Paid",
      JourneyDate: "2023-12-02",
    },
    {
      lastName: "Davis",
      firstName: "James",
      age: 53,
      gender: "Female",
      RegNo: "REG307",
      country: "Canada",
      receiptNo: "REC144",
      address: "422 Elm St, City",
      oppCharges: 61,
      gstCharges: 17,
      OTTCharges: 96,
      totalAmount: 174,
      status: "Pending",
      JourneyDate: "2023-11-06",
    },
    {
      lastName: "Brown",
      firstName: "William",
      age: 33,
      gender: "Female",
      RegNo: "REG671",
      country: "India",
      receiptNo: "REC405",
      address: "652 Elm St, City",
      oppCharges: 201,
      gstCharges: 35,
      OTTCharges: 34,
      totalAmount: 270,
      status: "Cancelled",
      JourneyDate: "2024-03-30",
    },
    {
      lastName: "Jones",
      firstName: "Michael",
      age: 30,
      gender: "Female",
      RegNo: "REG620",
      country: "UK",
      receiptNo: "REC120",
      address: "464 Elm St, City",
      oppCharges: 117,
      gstCharges: 31,
      OTTCharges: 17,
      totalAmount: 165,
      status: "Cancelled",
      JourneyDate: "2024-01-10",
    },
    {
      lastName: "Smith",
      firstName: "John",
      age: 52,
      gender: "Male",
      RegNo: "REG580",
      country: "Canada",
      receiptNo: "REC450",
      address: "133 Elm St, City",
      oppCharges: 144,
      gstCharges: 33,
      OTTCharges: 75,
      totalAmount: 252,
      status: "Paid",
      JourneyDate: "2024-03-28",
    },
    {
      lastName: "Johnson",
      firstName: "James",
      age: 50,
      gender: "Female",
      RegNo: "REG520",
      country: "Canada",
      receiptNo: "REC20",
      address: "619 Elm St, City",
      oppCharges: 223,
      gstCharges: 23,
      OTTCharges: 80,
      totalAmount: 326,
      status: "Pending",
      JourneyDate: "2023-06-26",
    },
    {
      lastName: "Johnson",
      firstName: "Richard",
      age: 52,
      gender: "Male",
      RegNo: "REG313",
      country: "India",
      receiptNo: "REC408",
      address: "457 Elm St, City",
      oppCharges: 196,
      gstCharges: 22,
      OTTCharges: 3,
      totalAmount: 221,
      status: "Cancelled",
      JourneyDate: "2023-04-14",
    },
    {
      lastName: "Johnson",
      firstName: "John",
      age: 36,
      gender: "Male",
      RegNo: "REG644",
      country: "UK",
      receiptNo: "REC178",
      address: "327 Elm St, City",
      oppCharges: 183,
      gstCharges: 38,
      OTTCharges: 82,
      totalAmount: 303,
      status: "Pending",
      JourneyDate: "2023-12-12",
    },
    {
      lastName: "Williams",
      firstName: "Robert",
      age: 38,
      gender: "Male",
      RegNo: "REG984",
      country: "Canada",
      receiptNo: "REC194",
      address: "906 Elm St, City",
      oppCharges: 243,
      gstCharges: 21,
      OTTCharges: 10,
      totalAmount: 274,
      status: "Paid",
      JourneyDate: "2024-01-28",
    },
    {
      lastName: "Brown",
      firstName: "Robert",
      age: 49,
      gender: "Female",
      RegNo: "REG922",
      country: "UK",
      receiptNo: "REC292",
      address: "886 Elm St, City",
      oppCharges: 155,
      gstCharges: 18,
      OTTCharges: 97,
      totalAmount: 270,
      status: "Paid",
      JourneyDate: "2024-06-11",
    },
    {
      lastName: "Johnson",
      firstName: "Richard",
      age: 26,
      gender: "Male",
      RegNo: "REG332",
      country: "USA",
      receiptNo: "REC177",
      address: "46 Elm St, City",
      oppCharges: 237,
      gstCharges: 39,
      OTTCharges: 14,
      totalAmount: 290,
      status: "Cancelled",
      JourneyDate: "2023-01-13",
    },
    {
      lastName: "Brown",
      firstName: "John",
      age: 35,
      gender: "Male",
      RegNo: "REG232",
      country: "Canada",
      receiptNo: "REC58",
      address: "707 Elm St, City",
      oppCharges: 125,
      gstCharges: 31,
      OTTCharges: 52,
      totalAmount: 208,
      status: "Cancelled",
      JourneyDate: "2024-03-17",
    },
    {
      lastName: "Davis",
      firstName: "John",
      age: 24,
      gender: "Male",
      RegNo: "REG574",
      country: "UK",
      receiptNo: "REC114",
      address: "360 Elm St, City",
      oppCharges: 81,
      gstCharges: 24,
      OTTCharges: 9,
      totalAmount: 114,
      status: "Paid",
      JourneyDate: "2023-05-20",
    },
    {
      lastName: "Smith",
      firstName: "John",
      age: 50,
      gender: "Male",
      RegNo: "REG897",
      country: "Canada",
      receiptNo: "REC73",
      address: "451 Elm St, City",
      oppCharges: 218,
      gstCharges: 18,
      OTTCharges: 96,
      totalAmount: 332,
      status: "Cancelled",
      JourneyDate: "2023-06-26",
    },
    {
      lastName: "Miller",
      firstName: "James",
      age: 48,
      gender: "Female",
      RegNo: "REG251",
      country: "India",
      receiptNo: "REC800",
      address: "78 Elm St, City",
      oppCharges: 190,
      gstCharges: 39,
      OTTCharges: 62,
      totalAmount: 291,
      status: "Paid",
      JourneyDate: "2024-06-19",
    },
    {
      lastName: "Williams",
      firstName: "John",
      age: 53,
      gender: "Female",
      RegNo: "REG228",
      country: "Canada",
      receiptNo: "REC105",
      address: "855 Elm St, City",
      oppCharges: 169,
      gstCharges: 22,
      OTTCharges: 94,
      totalAmount: 285,
      status: "Cancelled",
      JourneyDate: "2024-05-08",
    },
    {
      lastName: "Miller",
      firstName: "James",
      age: 60,
      gender: "Female",
      RegNo: "REG61",
      country: "UK",
      receiptNo: "REC652",
      address: "209 Elm St, City",
      oppCharges: 194,
      gstCharges: 25,
      OTTCharges: 16,
      totalAmount: 235,
      status: "Paid",
      JourneyDate: "2024-04-04",
    },
    {
      lastName: "Johnson",
      firstName: "James",
      age: 44,
      gender: "Male",
      RegNo: "REG743",
      country: "India",
      receiptNo: "REC589",
      address: "895 Elm St, City",
      oppCharges: 101,
      gstCharges: 33,
      OTTCharges: 42,
      totalAmount: 176,
      status: "Pending",
      JourneyDate: "2024-05-31",
    },
    {
      lastName: "Johnson",
      firstName: "John",
      age: 48,
      gender: "Male",
      RegNo: "REG629",
      country: "Canada",
      receiptNo: "REC737",
      address: "752 Elm St, City",
      oppCharges: 113,
      gstCharges: 35,
      OTTCharges: 95,
      totalAmount: 243,
      status: "Cancelled",
      JourneyDate: "2024-04-25",
    },
    {
      lastName: "Johnson",
      firstName: "Robert",
      age: 68,
      gender: "Male",
      RegNo: "REG809",
      country: "USA",
      receiptNo: "REC830",
      address: "146 Elm St, City",
      oppCharges: 230,
      gstCharges: 18,
      OTTCharges: 47,
      totalAmount: 295,
      status: "Cancelled",
      JourneyDate: "2024-04-25",
    },
    {
      lastName: "Jones",
      firstName: "William",
      age: 26,
      gender: "Female",
      RegNo: "REG841",
      country: "USA",
      receiptNo: "REC301",
      address: "175 Elm St, City",
      oppCharges: 194,
      gstCharges: 31,
      OTTCharges: 15,
      totalAmount: 240,
      status: "Paid",
      JourneyDate: "2023-08-23",
    },
    {
      lastName: "Miller",
      firstName: "Richard",
      age: 66,
      gender: "Male",
      RegNo: "REG849",
      country: "India",
      receiptNo: "REC202",
      address: "551 Elm St, City",
      oppCharges: 241,
      gstCharges: 34,
      OTTCharges: 27,
      totalAmount: 302,
      status: "Cancelled",
      JourneyDate: "2023-10-30",
    },
    {
      lastName: "Brown",
      firstName: "Richard",
      age: 33,
      gender: "Male",
      RegNo: "REG402",
      country: "Canada",
      receiptNo: "REC985",
      address: "980 Elm St, City",
      oppCharges: 154,
      gstCharges: 35,
      OTTCharges: 52,
      totalAmount: 241,
      status: "Paid",
      JourneyDate: "2023-07-18",
    },
    {
      lastName: "Jones",
      firstName: "James",
      age: 49,
      gender: "Male",
      RegNo: "REG707",
      country: "Canada",
      receiptNo: "REC560",
      address: "350 Elm St, City",
      oppCharges: 152,
      gstCharges: 19,
      OTTCharges: 53,
      totalAmount: 224,
      status: "Paid",
      JourneyDate: "2023-08-21",
    },
    {
      lastName: "Davis",
      firstName: "Robert",
      age: 32,
      gender: "Male",
      RegNo: "REG360",
      country: "USA",
      receiptNo: "REC238",
      address: "431 Elm St, City",
      oppCharges: 103,
      gstCharges: 38,
      OTTCharges: 25,
      totalAmount: 166,
      status: "Cancelled",
      JourneyDate: "2023-11-07",
    },
    {
      lastName: "Brown",
      firstName: "David",
      age: 53,
      gender: "Male",
      RegNo: "REG520",
      country: "Australia",
      receiptNo: "REC427",
      address: "219 Elm St, City",
      oppCharges: 240,
      gstCharges: 33,
      OTTCharges: 1,
      totalAmount: 274,
      status: "Pending",
      JourneyDate: "2023-08-22",
    },
    {
      lastName: "Smith",
      firstName: "Michael",
      age: 36,
      gender: "Male",
      RegNo: "REG760",
      country: "USA",
      receiptNo: "REC653",
      address: "85 Elm St, City",
      oppCharges: 186,
      gstCharges: 32,
      OTTCharges: 60,
      totalAmount: 278,
      status: "Paid",
      JourneyDate: "2023-03-19",
    },
    {
      lastName: "Williams",
      firstName: "John",
      age: 43,
      gender: "Female",
      RegNo: "REG218",
      country: "Australia",
      receiptNo: "REC208",
      address: "14 Elm St, City",
      oppCharges: 102,
      gstCharges: 32,
      OTTCharges: 67,
      totalAmount: 201,
      status: "Cancelled",
      JourneyDate: "2023-10-12",
    },
    {
      lastName: "Miller",
      firstName: "John",
      age: 42,
      gender: "Male",
      RegNo: "REG525",
      country: "India",
      receiptNo: "REC795",
      address: "494 Elm St, City",
      oppCharges: 171,
      gstCharges: 27,
      OTTCharges: 51,
      totalAmount: 249,
      status: "Cancelled",
      JourneyDate: "2023-05-14",
    },
    {
      lastName: "Miller",
      firstName: "Robert",
      age: 25,
      gender: "Female",
      RegNo: "REG832",
      country: "India",
      receiptNo: "REC742",
      address: "847 Elm St, City",
      oppCharges: 139,
      gstCharges: 21,
      OTTCharges: 34,
      totalAmount: 194,
      status: "Cancelled",
      JourneyDate: "2024-07-09",
    },
    {
      lastName: "Johnson",
      firstName: "William",
      age: 25,
      gender: "Male",
      RegNo: "REG560",
      country: "Australia",
      receiptNo: "REC141",
      address: "579 Elm St, City",
      oppCharges: 183,
      gstCharges: 25,
      OTTCharges: 4,
      totalAmount: 212,
      status: "Paid",
      JourneyDate: "2023-07-30",
    },
    {
      lastName: "Smith",
      firstName: "Robert",
      age: 54,
      gender: "Female",
      RegNo: "REG439",
      country: "USA",
      receiptNo: "REC726",
      address: "768 Elm St, City",
      oppCharges: 128,
      gstCharges: 19,
      OTTCharges: 19,
      totalAmount: 166,
      status: "Pending",
      JourneyDate: "2023-05-29",
    },
    {
      lastName: "Jones",
      firstName: "Richard",
      age: 27,
      gender: "Female",
      RegNo: "REG236",
      country: "UK",
      receiptNo: "REC983",
      address: "446 Elm St, City",
      oppCharges: 83,
      gstCharges: 36,
      OTTCharges: 37,
      totalAmount: 156,
      status: "Pending",
      JourneyDate: "2024-06-24",
    },
    {
      lastName: "Williams",
      firstName: "James",
      age: 39,
      gender: "Female",
      RegNo: "REG344",
      country: "USA",
      receiptNo: "REC370",
      address: "258 Elm St, City",
      oppCharges: 69,
      gstCharges: 14,
      OTTCharges: 57,
      totalAmount: 140,
      status: "Pending",
      JourneyDate: "2023-11-01",
    },
    {
      lastName: "Johnson",
      firstName: "James",
      age: 55,
      gender: "Female",
      RegNo: "REG237",
      country: "Canada",
      receiptNo: "REC84",
      address: "174 Elm St, City",
      oppCharges: 224,
      gstCharges: 15,
      OTTCharges: 87,
      totalAmount: 326,
      status: "Pending",
      JourneyDate: "2023-01-27",
    },
    {
      lastName: "Davis",
      firstName: "Robert",
      age: 49,
      gender: "Female",
      RegNo: "REG385",
      country: "Australia",
      receiptNo: "REC383",
      address: "964 Elm St, City",
      oppCharges: 92,
      gstCharges: 25,
      OTTCharges: 79,
      totalAmount: 196,
      status: "Paid",
      JourneyDate: "2024-05-16",
    },
    {
      lastName: "Williams",
      firstName: "David",
      age: 45,
      gender: "Male",
      RegNo: "REG989",
      country: "India",
      receiptNo: "REC159",
      address: "590 Elm St, City",
      oppCharges: 73,
      gstCharges: 35,
      OTTCharges: 42,
      totalAmount: 150,
      status: "Pending",
      JourneyDate: "2024-07-06",
    },
    {
      lastName: "Miller",
      firstName: "Michael",
      age: 23,
      gender: "Male",
      RegNo: "REG688",
      country: "Australia",
      receiptNo: "REC674",
      address: "55 Elm St, City",
      oppCharges: 123,
      gstCharges: 14,
      OTTCharges: 21,
      totalAmount: 158,
      status: "Cancelled",
      JourneyDate: "2023-02-07",
    },
    {
      lastName: "Johnson",
      firstName: "John",
      age: 65,
      gender: "Male",
      RegNo: "REG196",
      country: "UK",
      receiptNo: "REC793",
      address: "161 Elm St, City",
      oppCharges: 149,
      gstCharges: 18,
      OTTCharges: 24,
      totalAmount: 191,
      status: "Paid",
      JourneyDate: "2023-07-22",
    },
    {
      lastName: "Smith",
      firstName: "Richard",
      age: 63,
      gender: "Female",
      RegNo: "REG511",
      country: "Australia",
      receiptNo: "REC707",
      address: "359 Elm St, City",
      oppCharges: 193,
      gstCharges: 32,
      OTTCharges: 44,
      totalAmount: 269,
      status: "Paid",
      JourneyDate: "2023-07-10",
    },
    {
      lastName: "Williams",
      firstName: "John",
      age: 57,
      gender: "Male",
      RegNo: "REG813",
      country: "Australia",
      receiptNo: "REC860",
      address: "657 Elm St, City",
      oppCharges: 60,
      gstCharges: 32,
      OTTCharges: 79,
      totalAmount: 171,
      status: "Cancelled",
      JourneyDate: "2023-01-02",
    },
    {
      lastName: "Miller",
      firstName: "John",
      age: 43,
      gender: "Female",
      RegNo: "REG838",
      country: "Australia",
      receiptNo: "REC931",
      address: "794 Elm St, City",
      oppCharges: 161,
      gstCharges: 20,
      OTTCharges: 83,
      totalAmount: 264,
      status: "Pending",
      JourneyDate: "2023-01-08",
    },
    {
      lastName: "Jones",
      firstName: "Richard",
      age: 66,
      gender: "Male",
      RegNo: "REG505",
      country: "USA",
      receiptNo: "REC687",
      address: "140 Elm St, City",
      oppCharges: 112,
      gstCharges: 36,
      OTTCharges: 36,
      totalAmount: 184,
      status: "Paid",
      JourneyDate: "2024-03-08",
    },
    {
      lastName: "Johnson",
      firstName: "John",
      age: 53,
      gender: "Male",
      RegNo: "REG864",
      country: "Australia",
      receiptNo: "REC569",
      address: "266 Elm St, City",
      oppCharges: 173,
      gstCharges: 21,
      OTTCharges: 1,
      totalAmount: 195,
      status: "Pending",
      JourneyDate: "2024-02-05",
    },
    {
      lastName: "Williams",
      firstName: "William",
      age: 32,
      gender: "Female",
      RegNo: "REG396",
      country: "UK",
      receiptNo: "REC25",
      address: "765 Elm St, City",
      oppCharges: 219,
      gstCharges: 19,
      OTTCharges: 9,
      totalAmount: 247,
      status: "Pending",
      JourneyDate: "2023-07-23",
    },
    {
      lastName: "Williams",
      firstName: "David",
      age: 60,
      gender: "Female",
      RegNo: "REG856",
      country: "Australia",
      receiptNo: "REC561",
      address: "751 Elm St, City",
      oppCharges: 144,
      gstCharges: 37,
      OTTCharges: 19,
      totalAmount: 200,
      status: "Pending",
      JourneyDate: "2024-05-08",
    },
    {
      lastName: "Johnson",
      firstName: "Richard",
      age: 48,
      gender: "Male",
      RegNo: "REG52",
      country: "India",
      receiptNo: "REC97",
      address: "766 Elm St, City",
      oppCharges: 164,
      gstCharges: 35,
      OTTCharges: 30,
      totalAmount: 229,
      status: "Pending",
      JourneyDate: "2023-05-05",
    },
    {
      lastName: "Smith",
      firstName: "James",
      age: 32,
      gender: "Female",
      RegNo: "REG98",
      country: "UK",
      receiptNo: "REC320",
      address: "985 Elm St, City",
      oppCharges: 138,
      gstCharges: 15,
      OTTCharges: 2,
      totalAmount: 155,
      status: "Cancelled",
      JourneyDate: "2023-05-09",
    },
    {
      lastName: "Williams",
      firstName: "Robert",
      age: 41,
      gender: "Female",
      RegNo: "REG79",
      country: "Canada",
      receiptNo: "REC84",
      address: "160 Elm St, City",
      oppCharges: 225,
      gstCharges: 39,
      OTTCharges: 3,
      totalAmount: 267,
      status: "Cancelled",
      JourneyDate: "2024-07-08",
    },
    {
      lastName: "Jones",
      firstName: "David",
      age: 51,
      gender: "Male",
      RegNo: "REG353",
      country: "UK",
      receiptNo: "REC675",
      address: "883 Elm St, City",
      oppCharges: 166,
      gstCharges: 28,
      OTTCharges: 89,
      totalAmount: 283,
      status: "Pending",
      JourneyDate: "2023-06-24",
    },
    {
      lastName: "Jones",
      firstName: "Michael",
      age: 25,
      gender: "Female",
      RegNo: "REG708",
      country: "Australia",
      receiptNo: "REC92",
      address: "536 Elm St, City",
      oppCharges: 204,
      gstCharges: 13,
      OTTCharges: 86,
      totalAmount: 303,
      status: "Cancelled",
      JourneyDate: "2023-02-04",
    },
    {
      lastName: "Brown",
      firstName: "David",
      age: 29,
      gender: "Female",
      RegNo: "REG143",
      country: "USA",
      receiptNo: "REC546",
      address: "53 Elm St, City",
      oppCharges: 111,
      gstCharges: 38,
      OTTCharges: 50,
      totalAmount: 199,
      status: "Cancelled",
      JourneyDate: "2024-03-31",
    },
    {
      lastName: "Williams",
      firstName: "Michael",
      age: 63,
      gender: "Male",
      RegNo: "REG790",
      country: "Canada",
      receiptNo: "REC832",
      address: "817 Elm St, City",
      oppCharges: 86,
      gstCharges: 26,
      OTTCharges: 46,
      totalAmount: 158,
      status: "Pending",
      JourneyDate: "2023-04-27",
    },
    {
      lastName: "Davis",
      firstName: "Michael",
      age: 58,
      gender: "Male",
      RegNo: "REG289",
      country: "USA",
      receiptNo: "REC158",
      address: "462 Elm St, City",
      oppCharges: 240,
      gstCharges: 26,
      OTTCharges: 80,
      totalAmount: 346,
      status: "Paid",
      JourneyDate: "2024-07-01",
    },
    {
      lastName: "Smith",
      firstName: "David",
      age: 20,
      gender: "Female",
      RegNo: "REG51",
      country: "India",
      receiptNo: "REC357",
      address: "818 Elm St, City",
      oppCharges: 174,
      gstCharges: 15,
      OTTCharges: 79,
      totalAmount: 268,
      status: "Pending",
      JourneyDate: "2024-01-06",
    },
    {
      lastName: "Jones",
      firstName: "John",
      age: 32,
      gender: "Male",
      RegNo: "REG675",
      country: "USA",
      receiptNo: "REC352",
      address: "541 Elm St, City",
      oppCharges: 158,
      gstCharges: 17,
      OTTCharges: 2,
      totalAmount: 177,
      status: "Cancelled",
      JourneyDate: "2023-03-22",
    },
    {
      lastName: "Smith",
      firstName: "David",
      age: 52,
      gender: "Female",
      RegNo: "REG691",
      country: "UK",
      receiptNo: "REC921",
      address: "425 Elm St, City",
      oppCharges: 133,
      gstCharges: 27,
      OTTCharges: 9,
      totalAmount: 169,
      status: "Pending",
      JourneyDate: "2023-08-29",
    },
    {
      lastName: "Davis",
      firstName: "William",
      age: 37,
      gender: "Male",
      RegNo: "REG167",
      country: "India",
      receiptNo: "REC545",
      address: "619 Elm St, City",
      oppCharges: 68,
      gstCharges: 26,
      OTTCharges: 11,
      totalAmount: 105,
      status: "Pending",
      JourneyDate: "2023-06-13",
    },
    {
      lastName: "Miller",
      firstName: "Richard",
      age: 35,
      gender: "Female",
      RegNo: "REG473",
      country: "Australia",
      receiptNo: "REC529",
      address: "448 Elm St, City",
      oppCharges: 248,
      gstCharges: 35,
      OTTCharges: 99,
      totalAmount: 382,
      status: "Paid",
      JourneyDate: "2024-05-24",
    },
    {
      lastName: "Miller",
      firstName: "John",
      age: 59,
      gender: "Male",
      RegNo: "REG360",
      country: "India",
      receiptNo: "REC167",
      address: "506 Elm St, City",
      oppCharges: 232,
      gstCharges: 38,
      OTTCharges: 40,
      totalAmount: 310,
      status: "Pending",
      JourneyDate: "2023-01-02",
    },
    {
      lastName: "Davis",
      firstName: "James",
      age: 66,
      gender: "Male",
      RegNo: "REG298",
      country: "India",
      receiptNo: "REC339",
      address: "258 Elm St, City",
      oppCharges: 169,
      gstCharges: 35,
      OTTCharges: 28,
      totalAmount: 232,
      status: "Paid",
      JourneyDate: "2023-03-16",
    },
    {
      lastName: "Brown",
      firstName: "William",
      age: 68,
      gender: "Male",
      RegNo: "REG668",
      country: "USA",
      receiptNo: "REC406",
      address: "164 Elm St, City",
      oppCharges: 93,
      gstCharges: 20,
      OTTCharges: 12,
      totalAmount: 125,
      status: "Cancelled",
      JourneyDate: "2023-10-21",
    },
    {
      lastName: "Jones",
      firstName: "Robert",
      age: 57,
      gender: "Male",
      RegNo: "REG410",
      country: "India",
      receiptNo: "REC317",
      address: "591 Elm St, City",
      oppCharges: 99,
      gstCharges: 35,
      OTTCharges: 80,
      totalAmount: 214,
      status: "Paid",
      JourneyDate: "2023-03-20",
    },
    {
      lastName: "Jones",
      firstName: "Richard",
      age: 35,
      gender: "Female",
      RegNo: "REG479",
      country: "Canada",
      receiptNo: "REC427",
      address: "241 Elm St, City",
      oppCharges: 220,
      gstCharges: 24,
      OTTCharges: 20,
      totalAmount: 264,
      status: "Paid",
      JourneyDate: "2023-04-07",
    },
    {
      lastName: "Miller",
      firstName: "Robert",
      age: 25,
      gender: "Male",
      RegNo: "REG59",
      country: "USA",
      receiptNo: "REC344",
      address: "589 Elm St, City",
      oppCharges: 65,
      gstCharges: 33,
      OTTCharges: 67,
      totalAmount: 165,
      status: "Cancelled",
      JourneyDate: "2023-05-26",
    },
    {
      lastName: "Williams",
      firstName: "Richard",
      age: 69,
      gender: "Male",
      RegNo: "REG94",
      country: "Australia",
      receiptNo: "REC427",
      address: "535 Elm St, City",
      oppCharges: 73,
      gstCharges: 26,
      OTTCharges: 31,
      totalAmount: 130,
      status: "Paid",
      JourneyDate: "2024-01-31",
    },
    {
      lastName: "Johnson",
      firstName: "Richard",
      age: 56,
      gender: "Female",
      RegNo: "REG162",
      country: "Canada",
      receiptNo: "REC655",
      address: "228 Elm St, City",
      oppCharges: 141,
      gstCharges: 25,
      OTTCharges: 5,
      totalAmount: 171,
      status: "Paid",
      JourneyDate: "2023-12-03",
    },
    {
      lastName: "Smith",
      firstName: "Michael",
      age: 64,
      gender: "Male",
      RegNo: "REG257",
      country: "Canada",
      receiptNo: "REC793",
      address: "454 Elm St, City",
      oppCharges: 154,
      gstCharges: 36,
      OTTCharges: 63,
      totalAmount: 253,
      status: "Paid",
      JourneyDate: "2023-03-03",
    },
    {
      lastName: "Miller",
      firstName: "Richard",
      age: 50,
      gender: "Female",
      RegNo: "REG428",
      country: "UK",
      receiptNo: "REC191",
      address: "232 Elm St, City",
      oppCharges: 106,
      gstCharges: 10,
      OTTCharges: 45,
      totalAmount: 161,
      status: "Paid",
      JourneyDate: "2024-01-12",
    },
    {
      lastName: "Johnson",
      firstName: "John",
      age: 39,
      gender: "Male",
      RegNo: "REG706",
      country: "USA",
      receiptNo: "REC527",
      address: "3 Elm St, City",
      oppCharges: 65,
      gstCharges: 17,
      OTTCharges: 40,
      totalAmount: 122,
      status: "Cancelled",
      JourneyDate: "2024-01-03",
    },
    {
      lastName: "Johnson",
      firstName: "Michael",
      age: 45,
      gender: "Female",
      RegNo: "REG72",
      country: "Australia",
      receiptNo: "REC776",
      address: "167 Elm St, City",
      oppCharges: 110,
      gstCharges: 20,
      OTTCharges: 89,
      totalAmount: 219,
      status: "Pending",
      JourneyDate: "2024-01-13",
    },
    {
      lastName: "Smith",
      firstName: "Michael",
      age: 50,
      gender: "Male",
      RegNo: "REG411",
      country: "Australia",
      receiptNo: "REC527",
      address: "362 Elm St, City",
      oppCharges: 213,
      gstCharges: 14,
      OTTCharges: 6,
      totalAmount: 233,
      status: "Paid",
      JourneyDate: "2023-02-18",
    },
    {
      lastName: "Jones",
      firstName: "William",
      age: 64,
      gender: "Female",
      RegNo: "REG428",
      country: "Australia",
      receiptNo: "REC910",
      address: "541 Elm St, City",
      oppCharges: 90,
      gstCharges: 22,
      OTTCharges: 47,
      totalAmount: 159,
      status: "Paid",
      JourneyDate: "2023-08-11",
    },
    {
      lastName: "Brown",
      firstName: "John",
      age: 40,
      gender: "Female",
      RegNo: "REG877",
      country: "UK",
      receiptNo: "REC431",
      address: "575 Elm St, City",
      oppCharges: 249,
      gstCharges: 13,
      OTTCharges: 62,
      totalAmount: 324,
      status: "Cancelled",
      JourneyDate: "2024-01-14",
    },
    {
      lastName: "Davis",
      firstName: "Richard",
      age: 42,
      gender: "Female",
      RegNo: "REG380",
      country: "India",
      receiptNo: "REC251",
      address: "851 Elm St, City",
      oppCharges: 172,
      gstCharges: 19,
      OTTCharges: 6,
      totalAmount: 197,
      status: "Cancelled",
      JourneyDate: "2023-08-22",
    },
    {
      lastName: "Davis",
      firstName: "Richard",
      age: 37,
      gender: "Male",
      RegNo: "REG958",
      country: "India",
      receiptNo: "REC841",
      address: "485 Elm St, City",
      oppCharges: 122,
      gstCharges: 22,
      OTTCharges: 81,
      totalAmount: 225,
      status: "Paid",
      JourneyDate: "2024-03-29",
    },
    {
      lastName: "Johnson",
      firstName: "William",
      age: 31,
      gender: "Male",
      RegNo: "REG67",
      country: "USA",
      receiptNo: "REC257",
      address: "90 Elm St, City",
      oppCharges: 72,
      gstCharges: 17,
      OTTCharges: 14,
      totalAmount: 103,
      status: "Pending",
      JourneyDate: "2023-11-11",
    },
    {
      lastName: "Williams",
      firstName: "John",
      age: 37,
      gender: "Female",
      RegNo: "REG992",
      country: "Canada",
      receiptNo: "REC893",
      address: "303 Elm St, City",
      oppCharges: 134,
      gstCharges: 11,
      OTTCharges: 42,
      totalAmount: 187,
      status: "Pending",
      JourneyDate: "2024-02-12",
    },
    {
      lastName: "Brown",
      firstName: "James",
      age: 69,
      gender: "Female",
      RegNo: "REG808",
      country: "India",
      receiptNo: "REC35",
      address: "220 Elm St, City",
      oppCharges: 81,
      gstCharges: 28,
      OTTCharges: 14,
      totalAmount: 123,
      status: "Cancelled",
      JourneyDate: "2024-06-13",
    },
    {
      lastName: "Smith",
      firstName: "Richard",
      age: 32,
      gender: "Male",
      RegNo: "REG916",
      country: "USA",
      receiptNo: "REC657",
      address: "769 Elm St, City",
      oppCharges: 182,
      gstCharges: 26,
      OTTCharges: 48,
      totalAmount: 256,
      status: "Cancelled",
      JourneyDate: "2023-04-15",
    },
    {
      lastName: "Miller",
      firstName: "John",
      age: 68,
      gender: "Male",
      RegNo: "REG789",
      country: "India",
      receiptNo: "REC72",
      address: "92 Elm St, City",
      oppCharges: 234,
      gstCharges: 21,
      OTTCharges: 40,
      totalAmount: 295,
      status: "Cancelled",
      JourneyDate: "2023-04-16",
    },
    {
      lastName: "Davis",
      firstName: "Michael",
      age: 24,
      gender: "Female",
      RegNo: "REG743",
      country: "India",
      receiptNo: "REC261",
      address: "14 Elm St, City",
      oppCharges: 153,
      gstCharges: 16,
      OTTCharges: 53,
      totalAmount: 222,
      status: "Pending",
      JourneyDate: "2023-12-05",
    },
    {
      lastName: "Brown",
      firstName: "Robert",
      age: 26,
      gender: "Female",
      RegNo: "REG6",
      country: "Canada",
      receiptNo: "REC933",
      address: "838 Elm St, City",
      oppCharges: 62,
      gstCharges: 28,
      OTTCharges: 99,
      totalAmount: 189,
      status: "Paid",
      JourneyDate: "2023-05-13",
    },
    {
      lastName: "Johnson",
      firstName: "Richard",
      age: 48,
      gender: "Male",
      RegNo: "REG254",
      country: "India",
      receiptNo: "REC998",
      address: "727 Elm St, City",
      oppCharges: 83,
      gstCharges: 39,
      OTTCharges: 50,
      totalAmount: 172,
      status: "Paid",
      JourneyDate: "2023-07-25",
    },
    {
      lastName: "Jones",
      firstName: "James",
      age: 44,
      gender: "Female",
      RegNo: "REG805",
      country: "India",
      receiptNo: "REC756",
      address: "833 Elm St, City",
      oppCharges: 71,
      gstCharges: 12,
      OTTCharges: 44,
      totalAmount: 127,
      status: "Cancelled",
      JourneyDate: "2023-11-01",
    },
    {
      lastName: "Smith",
      firstName: "William",
      age: 56,
      gender: "Female",
      RegNo: "REG594",
      country: "UK",
      receiptNo: "REC170",
      address: "834 Elm St, City",
      oppCharges: 135,
      gstCharges: 27,
      OTTCharges: 91,
      totalAmount: 253,
      status: "Pending",
      JourneyDate: "2023-04-23",
    },
    {
      lastName: "Jones",
      firstName: "William",
      age: 35,
      gender: "Female",
      RegNo: "REG115",
      country: "Canada",
      receiptNo: "REC849",
      address: "688 Elm St, City",
      oppCharges: 138,
      gstCharges: 25,
      OTTCharges: 84,
      totalAmount: 247,
      status: "Paid",
      JourneyDate: "2023-09-21",
    },
    {
      lastName: "Davis",
      firstName: "James",
      age: 20,
      gender: "Male",
      RegNo: "REG247",
      country: "Canada",
      receiptNo: "REC517",
      address: "865 Elm St, City",
      oppCharges: 119,
      gstCharges: 35,
      OTTCharges: 7,
      totalAmount: 161,
      status: "Paid",
      JourneyDate: "2023-04-01",
    },
    {
      lastName: "Brown",
      firstName: "Robert",
      age: 38,
      gender: "Female",
      RegNo: "REG788",
      country: "USA",
      receiptNo: "REC506",
      address: "577 Elm St, City",
      oppCharges: 61,
      gstCharges: 37,
      OTTCharges: 19,
      totalAmount: 117,
      status: "Paid",
      JourneyDate: "2024-07-05",
    },
    {
      lastName: "Jones",
      firstName: "David",
      age: 38,
      gender: "Female",
      RegNo: "REG930",
      country: "USA",
      receiptNo: "REC718",
      address: "987 Elm St, City",
      oppCharges: 63,
      gstCharges: 24,
      OTTCharges: 35,
      totalAmount: 122,
      status: "Cancelled",
      JourneyDate: "2023-02-27",
    },
    {
      lastName: "Williams",
      firstName: "Richard",
      age: 61,
      gender: "Male",
      RegNo: "REG444",
      country: "Canada",
      receiptNo: "REC21",
      address: "111 Elm St, City",
      oppCharges: 244,
      gstCharges: 13,
      OTTCharges: 66,
      totalAmount: 323,
      status: "Cancelled",
      JourneyDate: "2023-06-30",
    },
    {
      lastName: "Davis",
      firstName: "David",
      age: 50,
      gender: "Male",
      RegNo: "REG617",
      country: "USA",
      receiptNo: "REC940",
      address: "279 Elm St, City",
      oppCharges: 139,
      gstCharges: 15,
      OTTCharges: 38,
      totalAmount: 192,
      status: "Cancelled",
      JourneyDate: "2024-05-08",
    },
    {
      lastName: "Smith",
      firstName: "David",
      age: 40,
      gender: "Female",
      RegNo: "REG384",
      country: "USA",
      receiptNo: "REC135",
      address: "695 Elm St, City",
      oppCharges: 172,
      gstCharges: 23,
      OTTCharges: 6,
      totalAmount: 201,
      status: "Cancelled",
      JourneyDate: "2023-05-31",
    },
    {
      lastName: "Miller",
      firstName: "John",
      age: 62,
      gender: "Male",
      RegNo: "REG53",
      country: "USA",
      receiptNo: "REC966",
      address: "577 Elm St, City",
      oppCharges: 109,
      gstCharges: 39,
      OTTCharges: 5,
      totalAmount: 153,
      status: "Pending",
      JourneyDate: "2024-05-06",
    },
    {
      lastName: "Davis",
      firstName: "John",
      age: 49,
      gender: "Female",
      RegNo: "REG319",
      country: "Canada",
      receiptNo: "REC638",
      address: "682 Elm St, City",
      oppCharges: 129,
      gstCharges: 19,
      OTTCharges: 83,
      totalAmount: 231,
      status: "Paid",
      JourneyDate: "2024-07-04",
    },
    {
      lastName: "Davis",
      firstName: "Robert",
      age: 54,
      gender: "Female",
      RegNo: "REG970",
      country: "India",
      receiptNo: "REC140",
      address: "282 Elm St, City",
      oppCharges: 72,
      gstCharges: 18,
      OTTCharges: 24,
      totalAmount: 114,
      status: "Pending",
      JourneyDate: "2024-03-08",
    },
    {
      lastName: "Jones",
      firstName: "James",
      age: 34,
      gender: "Female",
      RegNo: "REG884",
      country: "Canada",
      receiptNo: "REC423",
      address: "595 Elm St, City",
      oppCharges: 74,
      gstCharges: 33,
      OTTCharges: 63,
      totalAmount: 170,
      status: "Pending",
      JourneyDate: "2023-12-15",
    },
    {
      lastName: "Johnson",
      firstName: "David",
      age: 42,
      gender: "Male",
      RegNo: "REG864",
      country: "UK",
      receiptNo: "REC150",
      address: "534 Elm St, City",
      oppCharges: 189,
      gstCharges: 27,
      OTTCharges: 75,
      totalAmount: 291,
      status: "Paid",
      JourneyDate: "2023-01-02",
    },
    {
      lastName: "Smith",
      firstName: "Robert",
      age: 58,
      gender: "Female",
      RegNo: "REG8",
      country: "Canada",
      receiptNo: "REC801",
      address: "944 Elm St, City",
      oppCharges: 138,
      gstCharges: 25,
      OTTCharges: 37,
      totalAmount: 200,
      status: "Pending",
      JourneyDate: "2023-03-18",
    },
    {
      lastName: "Miller",
      firstName: "James",
      age: 61,
      gender: "Male",
      RegNo: "REG868",
      country: "UK",
      receiptNo: "REC550",
      address: "30 Elm St, City",
      oppCharges: 174,
      gstCharges: 13,
      OTTCharges: 3,
      totalAmount: 190,
      status: "Paid",
      JourneyDate: "2023-01-12",
    },
    {
      lastName: "Williams",
      firstName: "John",
      age: 40,
      gender: "Female",
      RegNo: "REG503",
      country: "India",
      receiptNo: "REC134",
      address: "736 Elm St, City",
      oppCharges: 166,
      gstCharges: 12,
      OTTCharges: 73,
      totalAmount: 251,
      status: "Paid",
      JourneyDate: "2024-05-04",
    },
    {
      lastName: "Davis",
      firstName: "Michael",
      age: 54,
      gender: "Female",
      RegNo: "REG658",
      country: "India",
      receiptNo: "REC719",
      address: "42 Elm St, City",
      oppCharges: 58,
      gstCharges: 34,
      OTTCharges: 13,
      totalAmount: 105,
      status: "Paid",
      JourneyDate: "2024-02-23",
    },
    {
      lastName: "Smith",
      firstName: "David",
      age: 66,
      gender: "Male",
      RegNo: "REG941",
      country: "USA",
      receiptNo: "REC599",
      address: "244 Elm St, City",
      oppCharges: 98,
      gstCharges: 26,
      OTTCharges: 75,
      totalAmount: 199,
      status: "Pending",
      JourneyDate: "2024-01-27",
    },
    {
      lastName: "Smith",
      firstName: "David",
      age: 28,
      gender: "Female",
      RegNo: "REG141",
      country: "Australia",
      receiptNo: "REC559",
      address: "292 Elm St, City",
      oppCharges: 241,
      gstCharges: 22,
      OTTCharges: 54,
      totalAmount: 317,
      status: "Cancelled",
      JourneyDate: "2023-02-19",
    },
    {
      lastName: "Davis",
      firstName: "Michael",
      age: 65,
      gender: "Female",
      RegNo: "REG550",
      country: "Canada",
      receiptNo: "REC834",
      address: "106 Elm St, City",
      oppCharges: 85,
      gstCharges: 33,
      OTTCharges: 58,
      totalAmount: 176,
      status: "Paid",
      JourneyDate: "2024-05-31",
    },
    {
      lastName: "Jones",
      firstName: "Michael",
      age: 37,
      gender: "Female",
      RegNo: "REG566",
      country: "UK",
      receiptNo: "REC145",
      address: "64 Elm St, City",
      oppCharges: 212,
      gstCharges: 15,
      OTTCharges: 15,
      totalAmount: 242,
      status: "Cancelled",
      JourneyDate: "2024-02-18",
    },
    {
      lastName: "Johnson",
      firstName: "William",
      age: 62,
      gender: "Female",
      RegNo: "REG866",
      country: "Canada",
      receiptNo: "REC868",
      address: "945 Elm St, City",
      oppCharges: 58,
      gstCharges: 28,
      OTTCharges: 31,
      totalAmount: 117,
      status: "Paid",
      JourneyDate: "2023-03-04",
    },
    {
      lastName: "Miller",
      firstName: "William",
      age: 42,
      gender: "Female",
      RegNo: "REG736",
      country: "India",
      receiptNo: "REC67",
      address: "483 Elm St, City",
      oppCharges: 113,
      gstCharges: 25,
      OTTCharges: 63,
      totalAmount: 201,
      status: "Paid",
      JourneyDate: "2024-01-27",
    },
    {
      lastName: "Smith",
      firstName: "Richard",
      age: 30,
      gender: "Female",
      RegNo: "REG531",
      country: "UK",
      receiptNo: "REC966",
      address: "743 Elm St, City",
      oppCharges: 88,
      gstCharges: 32,
      OTTCharges: 61,
      totalAmount: 181,
      status: "Paid",
      JourneyDate: "2024-06-09",
    },
    {
      lastName: "Miller",
      firstName: "John",
      age: 36,
      gender: "Female",
      RegNo: "REG491",
      country: "India",
      receiptNo: "REC518",
      address: "816 Elm St, City",
      oppCharges: 243,
      gstCharges: 10,
      OTTCharges: 37,
      totalAmount: 290,
      status: "Paid",
      JourneyDate: "2023-11-28",
    },
    {
      lastName: "Miller",
      firstName: "James",
      age: 39,
      gender: "Male",
      RegNo: "REG432",
      country: "India",
      receiptNo: "REC743",
      address: "932 Elm St, City",
      oppCharges: 111,
      gstCharges: 22,
      OTTCharges: 3,
      totalAmount: 136,
      status: "Pending",
      JourneyDate: "2023-02-02",
    },
    {
      lastName: "Williams",
      firstName: "Robert",
      age: 49,
      gender: "Male",
      RegNo: "REG507",
      country: "India",
      receiptNo: "REC415",
      address: "248 Elm St, City",
      oppCharges: 60,
      gstCharges: 35,
      OTTCharges: 16,
      totalAmount: 111,
      status: "Pending",
      JourneyDate: "2023-07-31",
    },
    {
      lastName: "Smith",
      firstName: "Richard",
      age: 56,
      gender: "Male",
      RegNo: "REG574",
      country: "Canada",
      receiptNo: "REC416",
      address: "107 Elm St, City",
      oppCharges: 172,
      gstCharges: 23,
      OTTCharges: 22,
      totalAmount: 217,
      status: "Cancelled",
      JourneyDate: "2023-09-23",
    },
    {
      lastName: "Davis",
      firstName: "Richard",
      age: 57,
      gender: "Male",
      RegNo: "REG850",
      country: "India",
      receiptNo: "REC855",
      address: "697 Elm St, City",
      oppCharges: 134,
      gstCharges: 26,
      OTTCharges: 14,
      totalAmount: 174,
      status: "Pending",
      JourneyDate: "2023-12-21",
    },
    {
      lastName: "Brown",
      firstName: "James",
      age: 59,
      gender: "Female",
      RegNo: "REG859",
      country: "Canada",
      receiptNo: "REC879",
      address: "637 Elm St, City",
      oppCharges: 219,
      gstCharges: 35,
      OTTCharges: 90,
      totalAmount: 344,
      status: "Cancelled",
      JourneyDate: "2024-05-26",
    },
    {
      lastName: "Davis",
      firstName: "Michael",
      age: 66,
      gender: "Male",
      RegNo: "REG526",
      country: "UK",
      receiptNo: "REC313",
      address: "607 Elm St, City",
      oppCharges: 72,
      gstCharges: 36,
      OTTCharges: 63,
      totalAmount: 171,
      status: "Paid",
      JourneyDate: "2023-05-01",
    },
    {
      lastName: "Williams",
      firstName: "David",
      age: 67,
      gender: "Female",
      RegNo: "REG605",
      country: "Australia",
      receiptNo: "REC32",
      address: "859 Elm St, City",
      oppCharges: 146,
      gstCharges: 31,
      OTTCharges: 21,
      totalAmount: 198,
      status: "Cancelled",
      JourneyDate: "2023-11-24",
    },
    {
      lastName: "Miller",
      firstName: "Michael",
      age: 59,
      gender: "Female",
      RegNo: "REG443",
      country: "Canada",
      receiptNo: "REC16",
      address: "478 Elm St, City",
      oppCharges: 67,
      gstCharges: 12,
      OTTCharges: 64,
      totalAmount: 143,
      status: "Pending",
      JourneyDate: "2023-04-20",
    },
    {
      lastName: "Johnson",
      firstName: "David",
      age: 51,
      gender: "Female",
      RegNo: "REG592",
      country: "Canada",
      receiptNo: "REC79",
      address: "267 Elm St, City",
      oppCharges: 119,
      gstCharges: 14,
      OTTCharges: 60,
      totalAmount: 193,
      status: "Cancelled",
      JourneyDate: "2023-05-17",
    },
    {
      lastName: "Davis",
      firstName: "John",
      age: 42,
      gender: "Female",
      RegNo: "REG351",
      country: "Australia",
      receiptNo: "REC255",
      address: "951 Elm St, City",
      oppCharges: 236,
      gstCharges: 35,
      OTTCharges: 24,
      totalAmount: 295,
      status: "Paid",
      JourneyDate: "2024-06-11",
    },
    {
      lastName: "Johnson",
      firstName: "David",
      age: 60,
      gender: "Male",
      RegNo: "REG649",
      country: "UK",
      receiptNo: "REC391",
      address: "793 Elm St, City",
      oppCharges: 222,
      gstCharges: 28,
      OTTCharges: 86,
      totalAmount: 336,
      status: "Pending",
      JourneyDate: "2024-03-26",
    },
    {
      lastName: "Williams",
      firstName: "Richard",
      age: 58,
      gender: "Female",
      RegNo: "REG185",
      country: "India",
      receiptNo: "REC277",
      address: "239 Elm St, City",
      oppCharges: 139,
      gstCharges: 18,
      OTTCharges: 87,
      totalAmount: 244,
      status: "Cancelled",
      JourneyDate: "2024-02-11",
    },
    {
      lastName: "Smith",
      firstName: "Michael",
      age: 32,
      gender: "Female",
      RegNo: "REG313",
      country: "Canada",
      receiptNo: "REC28",
      address: "575 Elm St, City",
      oppCharges: 152,
      gstCharges: 18,
      OTTCharges: 1,
      totalAmount: 171,
      status: "Paid",
      JourneyDate: "2024-03-13",
    },
    {
      lastName: "Smith",
      firstName: "Richard",
      age: 69,
      gender: "Male",
      RegNo: "REG973",
      country: "India",
      receiptNo: "REC514",
      address: "472 Elm St, City",
      oppCharges: 184,
      gstCharges: 36,
      OTTCharges: 24,
      totalAmount: 244,
      status: "Pending",
      JourneyDate: "2023-01-29",
    },
    {
      lastName: "Davis",
      firstName: "Richard",
      age: 46,
      gender: "Male",
      RegNo: "REG68",
      country: "UK",
      receiptNo: "REC341",
      address: "227 Elm St, City",
      oppCharges: 232,
      gstCharges: 18,
      OTTCharges: 94,
      totalAmount: 344,
      status: "Paid",
      JourneyDate: "2023-09-21",
    },
    {
      lastName: "Brown",
      firstName: "John",
      age: 66,
      gender: "Female",
      RegNo: "REG510",
      country: "Canada",
      receiptNo: "REC642",
      address: "653 Elm St, City",
      oppCharges: 222,
      gstCharges: 14,
      OTTCharges: 98,
      totalAmount: 334,
      status: "Pending",
      JourneyDate: "2023-03-24",
    },
    {
      lastName: "Williams",
      firstName: "David",
      age: 67,
      gender: "Female",
      RegNo: "REG205",
      country: "Australia",
      receiptNo: "REC636",
      address: "985 Elm St, City",
      oppCharges: 189,
      gstCharges: 33,
      OTTCharges: 38,
      totalAmount: 260,
      status: "Pending",
      JourneyDate: "2023-12-30",
    },
    {
      lastName: "Brown",
      firstName: "John",
      age: 60,
      gender: "Male",
      RegNo: "REG136",
      country: "India",
      receiptNo: "REC772",
      address: "47 Elm St, City",
      oppCharges: 153,
      gstCharges: 35,
      OTTCharges: 91,
      totalAmount: 279,
      status: "Pending",
      JourneyDate: "2023-06-08",
    },
    {
      lastName: "Williams",
      firstName: "Richard",
      age: 38,
      gender: "Female",
      RegNo: "REG762",
      country: "USA",
      receiptNo: "REC733",
      address: "228 Elm St, City",
      oppCharges: 113,
      gstCharges: 13,
      OTTCharges: 13,
      totalAmount: 139,
      status: "Paid",
      JourneyDate: "2023-07-12",
    },
    {
      lastName: "Williams",
      firstName: "Robert",
      age: 59,
      gender: "Female",
      RegNo: "REG881",
      country: "UK",
      receiptNo: "REC78",
      address: "859 Elm St, City",
      oppCharges: 170,
      gstCharges: 12,
      OTTCharges: 69,
      totalAmount: 251,
      status: "Cancelled",
      JourneyDate: "2024-03-30",
    },
    {
      lastName: "Brown",
      firstName: "John",
      age: 41,
      gender: "Male",
      RegNo: "REG648",
      country: "Australia",
      receiptNo: "REC556",
      address: "578 Elm St, City",
      oppCharges: 244,
      gstCharges: 11,
      OTTCharges: 40,
      totalAmount: 295,
      status: "Cancelled",
      JourneyDate: "2023-09-22",
    },
    {
      lastName: "Smith",
      firstName: "Robert",
      age: 22,
      gender: "Male",
      RegNo: "REG210",
      country: "Australia",
      receiptNo: "REC444",
      address: "216 Elm St, City",
      oppCharges: 76,
      gstCharges: 18,
      OTTCharges: 58,
      totalAmount: 152,
      status: "Paid",
      JourneyDate: "2024-06-23",
    },
    {
      lastName: "Brown",
      firstName: "James",
      age: 60,
      gender: "Female",
      RegNo: "REG918",
      country: "Australia",
      receiptNo: "REC675",
      address: "968 Elm St, City",
      oppCharges: 146,
      gstCharges: 14,
      OTTCharges: 59,
      totalAmount: 219,
      status: "Paid",
      JourneyDate: "2023-02-13",
    },
    {
      lastName: "Williams",
      firstName: "Michael",
      age: 44,
      gender: "Female",
      RegNo: "REG757",
      country: "Canada",
      receiptNo: "REC58",
      address: "653 Elm St, City",
      oppCharges: 145,
      gstCharges: 35,
      OTTCharges: 44,
      totalAmount: 224,
      status: "Cancelled",
      JourneyDate: "2024-02-07",
    },
    {
      lastName: "Davis",
      firstName: "Michael",
      age: 33,
      gender: "Male",
      RegNo: "REG965",
      country: "UK",
      receiptNo: "REC955",
      address: "343 Elm St, City",
      oppCharges: 168,
      gstCharges: 31,
      OTTCharges: 86,
      totalAmount: 285,
      status: "Cancelled",
      JourneyDate: "2023-03-06",
    },
    {
      lastName: "Brown",
      firstName: "John",
      age: 66,
      gender: "Male",
      RegNo: "REG526",
      country: "UK",
      receiptNo: "REC918",
      address: "39 Elm St, City",
      oppCharges: 85,
      gstCharges: 30,
      OTTCharges: 75,
      totalAmount: 190,
      status: "Pending",
      JourneyDate: "2024-01-16",
    },
    {
      lastName: "Davis",
      firstName: "John",
      age: 29,
      gender: "Male",
      RegNo: "REG439",
      country: "Canada",
      receiptNo: "REC930",
      address: "364 Elm St, City",
      oppCharges: 240,
      gstCharges: 21,
      OTTCharges: 54,
      totalAmount: 315,
      status: "Pending",
      JourneyDate: "2023-09-28",
    },
    {
      lastName: "Miller",
      firstName: "William",
      age: 31,
      gender: "Male",
      RegNo: "REG725",
      country: "Australia",
      receiptNo: "REC324",
      address: "134 Elm St, City",
      oppCharges: 228,
      gstCharges: 27,
      OTTCharges: 23,
      totalAmount: 278,
      status: "Pending",
      JourneyDate: "2023-05-30",
    },
    {
      lastName: "Jones",
      firstName: "Robert",
      age: 28,
      gender: "Female",
      RegNo: "REG688",
      country: "UK",
      receiptNo: "REC370",
      address: "481 Elm St, City",
      oppCharges: 79,
      gstCharges: 11,
      OTTCharges: 40,
      totalAmount: 130,
      status: "Cancelled",
      JourneyDate: "2024-03-30",
    },
    {
      lastName: "Miller",
      firstName: "David",
      age: 65,
      gender: "Male",
      RegNo: "REG83",
      country: "Australia",
      receiptNo: "REC196",
      address: "841 Elm St, City",
      oppCharges: 137,
      gstCharges: 17,
      OTTCharges: 56,
      totalAmount: 210,
      status: "Pending",
      JourneyDate: "2024-06-09",
    },
    {
      lastName: "Williams",
      firstName: "Robert",
      age: 63,
      gender: "Male",
      RegNo: "REG856",
      country: "USA",
      receiptNo: "REC547",
      address: "858 Elm St, City",
      oppCharges: 234,
      gstCharges: 26,
      OTTCharges: 70,
      totalAmount: 330,
      status: "Cancelled",
      JourneyDate: "2024-07-11",
    },
    {
      lastName: "Miller",
      firstName: "David",
      age: 54,
      gender: "Female",
      RegNo: "REG390",
      country: "Canada",
      receiptNo: "REC433",
      address: "497 Elm St, City",
      oppCharges: 145,
      gstCharges: 30,
      OTTCharges: 39,
      totalAmount: 214,
      status: "Paid",
      JourneyDate: "2023-05-24",
    },
    {
      lastName: "Brown",
      firstName: "Richard",
      age: 62,
      gender: "Female",
      RegNo: "REG16",
      country: "Canada",
      receiptNo: "REC786",
      address: "399 Elm St, City",
      oppCharges: 139,
      gstCharges: 34,
      OTTCharges: 28,
      totalAmount: 201,
      status: "Pending",
      JourneyDate: "2023-04-28",
    },
    {
      lastName: "Johnson",
      firstName: "Richard",
      age: 53,
      gender: "Female",
      RegNo: "REG239",
      country: "India",
      receiptNo: "REC546",
      address: "879 Elm St, City",
      oppCharges: 100,
      gstCharges: 32,
      OTTCharges: 96,
      totalAmount: 228,
      status: "Pending",
      JourneyDate: "2023-09-26",
    },
    {
      lastName: "Miller",
      firstName: "Richard",
      age: 59,
      gender: "Female",
      RegNo: "REG841",
      country: "India",
      receiptNo: "REC698",
      address: "129 Elm St, City",
      oppCharges: 248,
      gstCharges: 11,
      OTTCharges: 31,
      totalAmount: 290,
      status: "Pending",
      JourneyDate: "2023-10-11",
    },
    {
      lastName: "Williams",
      firstName: "Robert",
      age: 60,
      gender: "Male",
      RegNo: "REG419",
      country: "USA",
      receiptNo: "REC439",
      address: "831 Elm St, City",
      oppCharges: 105,
      gstCharges: 13,
      OTTCharges: 45,
      totalAmount: 163,
      status: "Paid",
      JourneyDate: "2023-12-31",
    },
    {
      lastName: "Davis",
      firstName: "John",
      age: 69,
      gender: "Male",
      RegNo: "REG676",
      country: "India",
      receiptNo: "REC229",
      address: "239 Elm St, City",
      oppCharges: 225,
      gstCharges: 18,
      OTTCharges: 1,
      totalAmount: 244,
      status: "Cancelled",
      JourneyDate: "2024-02-09",
    },
    {
      lastName: "Williams",
      firstName: "David",
      age: 25,
      gender: "Female",
      RegNo: "REG211",
      country: "Canada",
      receiptNo: "REC990",
      address: "774 Elm St, City",
      oppCharges: 113,
      gstCharges: 10,
      OTTCharges: 7,
      totalAmount: 130,
      status: "Cancelled",
      JourneyDate: "2023-05-19",
    },
    {
      lastName: "Brown",
      firstName: "James",
      age: 41,
      gender: "Female",
      RegNo: "REG800",
      country: "Australia",
      receiptNo: "REC384",
      address: "3 Elm St, City",
      oppCharges: 78,
      gstCharges: 28,
      OTTCharges: 62,
      totalAmount: 168,
      status: "Pending",
      JourneyDate: "2023-07-22",
    },
    {
      lastName: "Jones",
      firstName: "James",
      age: 40,
      gender: "Male",
      RegNo: "REG722",
      country: "Canada",
      receiptNo: "REC548",
      address: "68 Elm St, City",
      oppCharges: 232,
      gstCharges: 25,
      OTTCharges: 44,
      totalAmount: 301,
      status: "Pending",
      JourneyDate: "2023-06-14",
    },
    {
      lastName: "Johnson",
      firstName: "Richard",
      age: 53,
      gender: "Female",
      RegNo: "REG528",
      country: "Australia",
      receiptNo: "REC785",
      address: "626 Elm St, City",
      oppCharges: 112,
      gstCharges: 19,
      OTTCharges: 70,
      totalAmount: 201,
      status: "Cancelled",
      JourneyDate: "2023-08-16",
    },
    {
      lastName: "Davis",
      firstName: "Robert",
      age: 67,
      gender: "Male",
      RegNo: "REG487",
      country: "India",
      receiptNo: "REC400",
      address: "928 Elm St, City",
      oppCharges: 85,
      gstCharges: 26,
      OTTCharges: 67,
      totalAmount: 178,
      status: "Cancelled",
      JourneyDate: "2023-01-14",
    },
    {
      lastName: "Brown",
      firstName: "William",
      age: 68,
      gender: "Female",
      RegNo: "REG871",
      country: "India",
      receiptNo: "REC699",
      address: "595 Elm St, City",
      oppCharges: 229,
      gstCharges: 35,
      OTTCharges: 21,
      totalAmount: 285,
      status: "Paid",
      JourneyDate: "2023-03-06",
    },
    {
      lastName: "Miller",
      firstName: "John",
      age: 52,
      gender: "Male",
      RegNo: "REG999",
      country: "Canada",
      receiptNo: "REC327",
      address: "413 Elm St, City",
      oppCharges: 200,
      gstCharges: 16,
      OTTCharges: 64,
      totalAmount: 280,
      status: "Cancelled",
      JourneyDate: "2023-05-10",
    },
    {
      lastName: "Miller",
      firstName: "Richard",
      age: 63,
      gender: "Female",
      RegNo: "REG380",
      country: "Australia",
      receiptNo: "REC616",
      address: "859 Elm St, City",
      oppCharges: 203,
      gstCharges: 31,
      OTTCharges: 16,
      totalAmount: 250,
      status: "Pending",
      JourneyDate: "2024-04-20",
    },
    {
      lastName: "Smith",
      firstName: "Richard",
      age: 52,
      gender: "Female",
      RegNo: "REG118",
      country: "UK",
      receiptNo: "REC234",
      address: "887 Elm St, City",
      oppCharges: 185,
      gstCharges: 33,
      OTTCharges: 13,
      totalAmount: 231,
      status: "Pending",
      JourneyDate: "2023-10-01",
    },
    {
      lastName: "Smith",
      firstName: "John",
      age: 52,
      gender: "Male",
      RegNo: "REG812",
      country: "USA",
      receiptNo: "REC90",
      address: "117 Elm St, City",
      oppCharges: 200,
      gstCharges: 28,
      OTTCharges: 12,
      totalAmount: 240,
      status: "Paid",
      JourneyDate: "2023-01-02",
    },
    {
      lastName: "Jones",
      firstName: "Robert",
      age: 28,
      gender: "Male",
      RegNo: "REG776",
      country: "Australia",
      receiptNo: "REC381",
      address: "225 Elm St, City",
      oppCharges: 224,
      gstCharges: 15,
      OTTCharges: 17,
      totalAmount: 256,
      status: "Cancelled",
      JourneyDate: "2023-07-24",
    },
    {
      lastName: "Williams",
      firstName: "Robert",
      age: 47,
      gender: "Female",
      RegNo: "REG520",
      country: "Australia",
      receiptNo: "REC775",
      address: "608 Elm St, City",
      oppCharges: 81,
      gstCharges: 18,
      OTTCharges: 0,
      totalAmount: 99,
      status: "Paid",
      JourneyDate: "2024-01-30",
    },
    {
      lastName: "Jones",
      firstName: "John",
      age: 69,
      gender: "Female",
      RegNo: "REG49",
      country: "UK",
      receiptNo: "REC630",
      address: "462 Elm St, City",
      oppCharges: 173,
      gstCharges: 18,
      OTTCharges: 31,
      totalAmount: 222,
      status: "Pending",
      JourneyDate: "2023-07-21",
    },
    {
      lastName: "Jones",
      firstName: "Richard",
      age: 65,
      gender: "Male",
      RegNo: "REG371",
      country: "USA",
      receiptNo: "REC259",
      address: "210 Elm St, City",
      oppCharges: 200,
      gstCharges: 11,
      OTTCharges: 17,
      totalAmount: 228,
      status: "Cancelled",
      JourneyDate: "2024-03-19",
    },
    {
      lastName: "Davis",
      firstName: "John",
      age: 55,
      gender: "Male",
      RegNo: "REG410",
      country: "UK",
      receiptNo: "REC143",
      address: "40 Elm St, City",
      oppCharges: 169,
      gstCharges: 30,
      OTTCharges: 47,
      totalAmount: 246,
      status: "Paid",
      JourneyDate: "2023-11-19",
    },
    {
      lastName: "Miller",
      firstName: "David",
      age: 66,
      gender: "Male",
      RegNo: "REG533",
      country: "Australia",
      receiptNo: "REC28",
      address: "463 Elm St, City",
      oppCharges: 236,
      gstCharges: 19,
      OTTCharges: 71,
      totalAmount: 326,
      status: "Pending",
      JourneyDate: "2023-01-03",
    },
    {
      lastName: "Jones",
      firstName: "James",
      age: 29,
      gender: "Male",
      RegNo: "REG519",
      country: "Canada",
      receiptNo: "REC993",
      address: "179 Elm St, City",
      oppCharges: 166,
      gstCharges: 10,
      OTTCharges: 70,
      totalAmount: 246,
      status: "Cancelled",
      JourneyDate: "2024-02-23",
    },
    {
      lastName: "Davis",
      firstName: "Robert",
      age: 66,
      gender: "Female",
      RegNo: "REG900",
      country: "Canada",
      receiptNo: "REC819",
      address: "125 Elm St, City",
      oppCharges: 119,
      gstCharges: 31,
      OTTCharges: 66,
      totalAmount: 216,
      status: "Cancelled",
      JourneyDate: "2023-10-16",
    },
    {
      lastName: "Jones",
      firstName: "David",
      age: 46,
      gender: "Female",
      RegNo: "REG667",
      country: "Australia",
      receiptNo: "REC807",
      address: "980 Elm St, City",
      oppCharges: 139,
      gstCharges: 27,
      OTTCharges: 4,
      totalAmount: 170,
      status: "Paid",
      JourneyDate: "2024-03-26",
    },
    {
      lastName: "Jones",
      firstName: "Robert",
      age: 50,
      gender: "Male",
      RegNo: "REG542",
      country: "USA",
      receiptNo: "REC666",
      address: "720 Elm St, City",
      oppCharges: 224,
      gstCharges: 19,
      OTTCharges: 65,
      totalAmount: 308,
      status: "Pending",
      JourneyDate: "2024-06-11",
    },
    {
      lastName: "Brown",
      firstName: "David",
      age: 57,
      gender: "Male",
      RegNo: "REG71",
      country: "India",
      receiptNo: "REC944",
      address: "485 Elm St, City",
      oppCharges: 148,
      gstCharges: 11,
      OTTCharges: 73,
      totalAmount: 232,
      status: "Paid",
      JourneyDate: "2024-06-16",
    },
    {
      lastName: "Johnson",
      firstName: "Michael",
      age: 51,
      gender: "Male",
      RegNo: "REG303",
      country: "Canada",
      receiptNo: "REC61",
      address: "366 Elm St, City",
      oppCharges: 221,
      gstCharges: 11,
      OTTCharges: 29,
      totalAmount: 261,
      status: "Cancelled",
      JourneyDate: "2024-04-01",
    },
    {
      lastName: "Miller",
      firstName: "John",
      age: 42,
      gender: "Female",
      RegNo: "REG401",
      country: "Australia",
      receiptNo: "REC366",
      address: "1 Elm St, City",
      oppCharges: 52,
      gstCharges: 26,
      OTTCharges: 86,
      totalAmount: 164,
      status: "Pending",
      JourneyDate: "2023-12-30",
    },
    {
      lastName: "Johnson",
      firstName: "James",
      age: 62,
      gender: "Female",
      RegNo: "REG712",
      country: "Canada",
      receiptNo: "REC313",
      address: "293 Elm St, City",
      oppCharges: 144,
      gstCharges: 34,
      OTTCharges: 99,
      totalAmount: 277,
      status: "Pending",
      JourneyDate: "2023-01-23",
    },
    {
      lastName: "Williams",
      firstName: "Michael",
      age: 53,
      gender: "Female",
      RegNo: "REG118",
      country: "India",
      receiptNo: "REC535",
      address: "926 Elm St, City",
      oppCharges: 243,
      gstCharges: 35,
      OTTCharges: 90,
      totalAmount: 368,
      status: "Cancelled",
      JourneyDate: "2023-05-01",
    },
    {
      lastName: "Miller",
      firstName: "Michael",
      age: 32,
      gender: "Female",
      RegNo: "REG294",
      country: "USA",
      receiptNo: "REC318",
      address: "125 Elm St, City",
      oppCharges: 176,
      gstCharges: 25,
      OTTCharges: 75,
      totalAmount: 276,
      status: "Pending",
      JourneyDate: "2023-04-18",
    },
    {
      lastName: "Johnson",
      firstName: "William",
      age: 60,
      gender: "Male",
      RegNo: "REG281",
      country: "Australia",
      receiptNo: "REC57",
      address: "275 Elm St, City",
      oppCharges: 127,
      gstCharges: 12,
      OTTCharges: 64,
      totalAmount: 203,
      status: "Cancelled",
      JourneyDate: "2023-01-05",
    },
    {
      lastName: "Jones",
      firstName: "Robert",
      age: 28,
      gender: "Female",
      RegNo: "REG183",
      country: "UK",
      receiptNo: "REC258",
      address: "788 Elm St, City",
      oppCharges: 72,
      gstCharges: 29,
      OTTCharges: 89,
      totalAmount: 190,
      status: "Paid",
      JourneyDate: "2023-11-20",
    },
    {
      lastName: "Johnson",
      firstName: "John",
      age: 48,
      gender: "Male",
      RegNo: "REG855",
      country: "USA",
      receiptNo: "REC367",
      address: "816 Elm St, City",
      oppCharges: 150,
      gstCharges: 17,
      OTTCharges: 92,
      totalAmount: 259,
      status: "Pending",
      JourneyDate: "2023-05-27",
    },
    {
      lastName: "Smith",
      firstName: "Richard",
      age: 34,
      gender: "Male",
      RegNo: "REG120",
      country: "India",
      receiptNo: "REC574",
      address: "504 Elm St, City",
      oppCharges: 223,
      gstCharges: 30,
      OTTCharges: 87,
      totalAmount: 340,
      status: "Pending",
      JourneyDate: "2023-10-01",
    },
    {
      lastName: "Brown",
      firstName: "William",
      age: 34,
      gender: "Female",
      RegNo: "REG641",
      country: "Canada",
      receiptNo: "REC72",
      address: "38 Elm St, City",
      oppCharges: 209,
      gstCharges: 27,
      OTTCharges: 88,
      totalAmount: 324,
      status: "Cancelled",
      JourneyDate: "2023-03-21",
    },
    {
      lastName: "Williams",
      firstName: "Robert",
      age: 57,
      gender: "Female",
      RegNo: "REG4",
      country: "India",
      receiptNo: "REC308",
      address: "198 Elm St, City",
      oppCharges: 211,
      gstCharges: 22,
      OTTCharges: 94,
      totalAmount: 327,
      status: "Paid",
      JourneyDate: "2023-05-16",
    },
    {
      lastName: "Davis",
      firstName: "John",
      age: 57,
      gender: "Female",
      RegNo: "REG991",
      country: "UK",
      receiptNo: "REC136",
      address: "565 Elm St, City",
      oppCharges: 115,
      gstCharges: 35,
      OTTCharges: 30,
      totalAmount: 180,
      status: "Pending",
      JourneyDate: "2024-03-11",
    },
    {
      lastName: "Miller",
      firstName: "William",
      age: 35,
      gender: "Female",
      RegNo: "REG108",
      country: "UK",
      receiptNo: "REC454",
      address: "771 Elm St, City",
      oppCharges: 180,
      gstCharges: 30,
      OTTCharges: 9,
      totalAmount: 219,
      status: "Pending",
      JourneyDate: "2024-07-09",
    },
    {
      lastName: "Jones",
      firstName: "Michael",
      age: 41,
      gender: "Female",
      RegNo: "REG957",
      country: "UK",
      receiptNo: "REC658",
      address: "556 Elm St, City",
      oppCharges: 100,
      gstCharges: 21,
      OTTCharges: 68,
      totalAmount: 189,
      status: "Pending",
      JourneyDate: "2023-11-06",
    },
    {
      lastName: "Johnson",
      firstName: "Richard",
      age: 46,
      gender: "Female",
      RegNo: "REG344",
      country: "USA",
      receiptNo: "REC266",
      address: "238 Elm St, City",
      oppCharges: 198,
      gstCharges: 24,
      OTTCharges: 97,
      totalAmount: 319,
      status: "Paid",
      JourneyDate: "2024-03-31",
    },
    {
      lastName: "Brown",
      firstName: "Robert",
      age: 68,
      gender: "Female",
      RegNo: "REG145",
      country: "India",
      receiptNo: "REC118",
      address: "138 Elm St, City",
      oppCharges: 186,
      gstCharges: 26,
      OTTCharges: 29,
      totalAmount: 241,
      status: "Cancelled",
      JourneyDate: "2023-07-29",
    },
    {
      lastName: "Brown",
      firstName: "John",
      age: 36,
      gender: "Female",
      RegNo: "REG838",
      country: "Australia",
      receiptNo: "REC136",
      address: "21 Elm St, City",
      oppCharges: 189,
      gstCharges: 20,
      OTTCharges: 94,
      totalAmount: 303,
      status: "Cancelled",
      JourneyDate: "2023-06-28",
    },
    {
      lastName: "Brown",
      firstName: "William",
      age: 31,
      gender: "Male",
      RegNo: "REG478",
      country: "Australia",
      receiptNo: "REC442",
      address: "920 Elm St, City",
      oppCharges: 170,
      gstCharges: 12,
      OTTCharges: 0,
      totalAmount: 182,
      status: "Paid",
      JourneyDate: "2024-05-27",
    },
    {
      lastName: "Miller",
      firstName: "James",
      age: 64,
      gender: "Female",
      RegNo: "REG857",
      country: "India",
      receiptNo: "REC647",
      address: "589 Elm St, City",
      oppCharges: 56,
      gstCharges: 16,
      OTTCharges: 88,
      totalAmount: 160,
      status: "Pending",
      JourneyDate: "2024-07-06",
    },
    {
      lastName: "Smith",
      firstName: "John",
      age: 27,
      gender: "Male",
      RegNo: "REG421",
      country: "Canada",
      receiptNo: "REC288",
      address: "913 Elm St, City",
      oppCharges: 238,
      gstCharges: 20,
      OTTCharges: 11,
      totalAmount: 269,
      status: "Pending",
      JourneyDate: "2023-01-29",
    },
    {
      lastName: "Brown",
      firstName: "David",
      age: 43,
      gender: "Female",
      RegNo: "REG627",
      country: "USA",
      receiptNo: "REC433",
      address: "306 Elm St, City",
      oppCharges: 225,
      gstCharges: 17,
      OTTCharges: 27,
      totalAmount: 269,
      status: "Pending",
      JourneyDate: "2024-03-18",
    },
    {
      lastName: "Miller",
      firstName: "James",
      age: 46,
      gender: "Female",
      RegNo: "REG181",
      country: "USA",
      receiptNo: "REC369",
      address: "424 Elm St, City",
      oppCharges: 51,
      gstCharges: 33,
      OTTCharges: 26,
      totalAmount: 110,
      status: "Pending",
      JourneyDate: "2023-09-04",
    },
    {
      lastName: "Davis",
      firstName: "William",
      age: 68,
      gender: "Male",
      RegNo: "REG939",
      country: "USA",
      receiptNo: "REC725",
      address: "283 Elm St, City",
      oppCharges: 159,
      gstCharges: 20,
      OTTCharges: 53,
      totalAmount: 232,
      status: "Pending",
      JourneyDate: "2023-04-27",
    },
    {
      lastName: "Johnson",
      firstName: "David",
      age: 46,
      gender: "Female",
      RegNo: "REG787",
      country: "USA",
      receiptNo: "REC789",
      address: "997 Elm St, City",
      oppCharges: 212,
      gstCharges: 28,
      OTTCharges: 4,
      totalAmount: 244,
      status: "Pending",
      JourneyDate: "2023-12-27",
    },
    {
      lastName: "Miller",
      firstName: "William",
      age: 23,
      gender: "Male",
      RegNo: "REG864",
      country: "UK",
      receiptNo: "REC736",
      address: "297 Elm St, City",
      oppCharges: 175,
      gstCharges: 33,
      OTTCharges: 64,
      totalAmount: 272,
      status: "Cancelled",
      JourneyDate: "2023-09-11",
    },
    {
      lastName: "Johnson",
      firstName: "James",
      age: 67,
      gender: "Female",
      RegNo: "REG869",
      country: "India",
      receiptNo: "REC151",
      address: "212 Elm St, City",
      oppCharges: 195,
      gstCharges: 30,
      OTTCharges: 19,
      totalAmount: 244,
      status: "Cancelled",
      JourneyDate: "2024-06-16",
    },
    {
      lastName: "Davis",
      firstName: "William",
      age: 20,
      gender: "Male",
      RegNo: "REG994",
      country: "UK",
      receiptNo: "REC194",
      address: "139 Elm St, City",
      oppCharges: 156,
      gstCharges: 35,
      OTTCharges: 4,
      totalAmount: 195,
      status: "Pending",
      JourneyDate: "2023-08-02",
    },
    {
      lastName: "Williams",
      firstName: "Robert",
      age: 63,
      gender: "Female",
      RegNo: "REG80",
      country: "Canada",
      receiptNo: "REC25",
      address: "230 Elm St, City",
      oppCharges: 212,
      gstCharges: 29,
      OTTCharges: 34,
      totalAmount: 275,
      status: "Paid",
      JourneyDate: "2023-08-06",
    },
    {
      lastName: "Jones",
      firstName: "William",
      age: 69,
      gender: "Female",
      RegNo: "REG754",
      country: "USA",
      receiptNo: "REC215",
      address: "603 Elm St, City",
      oppCharges: 152,
      gstCharges: 24,
      OTTCharges: 63,
      totalAmount: 239,
      status: "Cancelled",
      JourneyDate: "2023-08-17",
    },
    {
      lastName: "Johnson",
      firstName: "William",
      age: 24,
      gender: "Male",
      RegNo: "REG872",
      country: "Canada",
      receiptNo: "REC816",
      address: "971 Elm St, City",
      oppCharges: 142,
      gstCharges: 11,
      OTTCharges: 60,
      totalAmount: 213,
      status: "Paid",
      JourneyDate: "2023-04-12",
    },
    {
      lastName: "Jones",
      firstName: "Richard",
      age: 25,
      gender: "Male",
      RegNo: "REG355",
      country: "Canada",
      receiptNo: "REC419",
      address: "230 Elm St, City",
      oppCharges: 248,
      gstCharges: 17,
      OTTCharges: 34,
      totalAmount: 299,
      status: "Paid",
      JourneyDate: "2023-08-06",
    },
    {
      lastName: "Smith",
      firstName: "William",
      age: 55,
      gender: "Female",
      RegNo: "REG344",
      country: "Australia",
      receiptNo: "REC270",
      address: "919 Elm St, City",
      oppCharges: 164,
      gstCharges: 32,
      OTTCharges: 16,
      totalAmount: 212,
      status: "Cancelled",
      JourneyDate: "2024-06-30",
    },
    {
      lastName: "Brown",
      firstName: "William",
      age: 28,
      gender: "Female",
      RegNo: "REG829",
      country: "India",
      receiptNo: "REC159",
      address: "983 Elm St, City",
      oppCharges: 189,
      gstCharges: 16,
      OTTCharges: 94,
      totalAmount: 299,
      status: "Cancelled",
      JourneyDate: "2024-02-20",
    },
    {
      lastName: "Smith",
      firstName: "William",
      age: 40,
      gender: "Male",
      RegNo: "REG77",
      country: "USA",
      receiptNo: "REC640",
      address: "524 Elm St, City",
      oppCharges: 219,
      gstCharges: 22,
      OTTCharges: 20,
      totalAmount: 261,
      status: "Paid",
      JourneyDate: "2023-01-27",
    },
    {
      lastName: "Smith",
      firstName: "Michael",
      age: 51,
      gender: "Male",
      RegNo: "REG285",
      country: "USA",
      receiptNo: "REC164",
      address: "704 Elm St, City",
      oppCharges: 76,
      gstCharges: 36,
      OTTCharges: 76,
      totalAmount: 188,
      status: "Cancelled",
      JourneyDate: "2023-12-23",
    },
    {
      lastName: "Williams",
      firstName: "William",
      age: 30,
      gender: "Male",
      RegNo: "REG573",
      country: "USA",
      receiptNo: "REC704",
      address: "636 Elm St, City",
      oppCharges: 194,
      gstCharges: 15,
      OTTCharges: 87,
      totalAmount: 296,
      status: "Pending",
      JourneyDate: "2023-12-10",
    },
    {
      lastName: "Miller",
      firstName: "David",
      age: 66,
      gender: "Male",
      RegNo: "REG727",
      country: "Canada",
      receiptNo: "REC432",
      address: "615 Elm St, City",
      oppCharges: 100,
      gstCharges: 25,
      OTTCharges: 73,
      totalAmount: 198,
      status: "Paid",
      JourneyDate: "2023-05-24",
    },
    {
      lastName: "Smith",
      firstName: "William",
      age: 66,
      gender: "Male",
      RegNo: "REG974",
      country: "UK",
      receiptNo: "REC543",
      address: "541 Elm St, City",
      oppCharges: 232,
      gstCharges: 19,
      OTTCharges: 49,
      totalAmount: 300,
      status: "Paid",
      JourneyDate: "2024-02-26",
    },
    {
      lastName: "Davis",
      firstName: "William",
      age: 40,
      gender: "Male",
      RegNo: "REG969",
      country: "India",
      receiptNo: "REC69",
      address: "424 Elm St, City",
      oppCharges: 132,
      gstCharges: 20,
      OTTCharges: 20,
      totalAmount: 172,
      status: "Pending",
      JourneyDate: "2023-12-13",
    },
    {
      lastName: "Brown",
      firstName: "Robert",
      age: 43,
      gender: "Male",
      RegNo: "REG730",
      country: "India",
      receiptNo: "REC326",
      address: "554 Elm St, City",
      oppCharges: 113,
      gstCharges: 16,
      OTTCharges: 95,
      totalAmount: 224,
      status: "Pending",
      JourneyDate: "2023-01-06",
    },
    {
      lastName: "Williams",
      firstName: "David",
      age: 41,
      gender: "Female",
      RegNo: "REG384",
      country: "Australia",
      receiptNo: "REC1",
      address: "54 Elm St, City",
      oppCharges: 204,
      gstCharges: 37,
      OTTCharges: 48,
      totalAmount: 289,
      status: "Paid",
      JourneyDate: "2024-07-09",
    },
    {
      lastName: "Miller",
      firstName: "Robert",
      age: 52,
      gender: "Female",
      RegNo: "REG327",
      country: "Canada",
      receiptNo: "REC438",
      address: "809 Elm St, City",
      oppCharges: 239,
      gstCharges: 11,
      OTTCharges: 72,
      totalAmount: 322,
      status: "Cancelled",
      JourneyDate: "2023-02-03",
    },
    {
      lastName: "Miller",
      firstName: "David",
      age: 34,
      gender: "Male",
      RegNo: "REG725",
      country: "India",
      receiptNo: "REC821",
      address: "67 Elm St, City",
      oppCharges: 213,
      gstCharges: 25,
      OTTCharges: 79,
      totalAmount: 317,
      status: "Pending",
      JourneyDate: "2023-05-25",
    },
    {
      lastName: "Miller",
      firstName: "Richard",
      age: 51,
      gender: "Male",
      RegNo: "REG173",
      country: "Canada",
      receiptNo: "REC279",
      address: "772 Elm St, City",
      oppCharges: 236,
      gstCharges: 28,
      OTTCharges: 63,
      totalAmount: 327,
      status: "Cancelled",
      JourneyDate: "2023-12-26",
    },
    {
      lastName: "Johnson",
      firstName: "James",
      age: 47,
      gender: "Female",
      RegNo: "REG975",
      country: "India",
      receiptNo: "REC836",
      address: "45 Elm St, City",
      oppCharges: 147,
      gstCharges: 17,
      OTTCharges: 78,
      totalAmount: 242,
      status: "Cancelled",
      JourneyDate: "2024-01-08",
    },
    {
      lastName: "Jones",
      firstName: "James",
      age: 48,
      gender: "Male",
      RegNo: "REG997",
      country: "Australia",
      receiptNo: "REC408",
      address: "152 Elm St, City",
      oppCharges: 102,
      gstCharges: 38,
      OTTCharges: 3,
      totalAmount: 143,
      status: "Cancelled",
      JourneyDate: "2023-10-13",
    },
    {
      lastName: "Brown",
      firstName: "James",
      age: 60,
      gender: "Female",
      RegNo: "REG606",
      country: "Canada",
      receiptNo: "REC629",
      address: "799 Elm St, City",
      oppCharges: 148,
      gstCharges: 22,
      OTTCharges: 99,
      totalAmount: 269,
      status: "Cancelled",
      JourneyDate: "2023-12-21",
    },
    {
      lastName: "Davis",
      firstName: "David",
      age: 46,
      gender: "Male",
      RegNo: "REG88",
      country: "India",
      receiptNo: "REC239",
      address: "111 Elm St, City",
      oppCharges: 53,
      gstCharges: 11,
      OTTCharges: 45,
      totalAmount: 109,
      status: "Paid",
      JourneyDate: "2023-01-22",
    },
    {
      lastName: "Brown",
      firstName: "Robert",
      age: 39,
      gender: "Female",
      RegNo: "REG279",
      country: "UK",
      receiptNo: "REC350",
      address: "107 Elm St, City",
      oppCharges: 147,
      gstCharges: 10,
      OTTCharges: 28,
      totalAmount: 185,
      status: "Cancelled",
      JourneyDate: "2024-01-26",
    },
    {
      lastName: "Smith",
      firstName: "William",
      age: 51,
      gender: "Female",
      RegNo: "REG748",
      country: "USA",
      receiptNo: "REC541",
      address: "496 Elm St, City",
      oppCharges: 77,
      gstCharges: 19,
      OTTCharges: 91,
      totalAmount: 187,
      status: "Pending",
      JourneyDate: "2024-04-21",
    },
    {
      lastName: "Smith",
      firstName: "David",
      age: 67,
      gender: "Male",
      RegNo: "REG533",
      country: "USA",
      receiptNo: "REC221",
      address: "553 Elm St, City",
      oppCharges: 218,
      gstCharges: 34,
      OTTCharges: 86,
      totalAmount: 338,
      status: "Pending",
      JourneyDate: "2023-12-29",
    },
    {
      lastName: "Davis",
      firstName: "John",
      age: 46,
      gender: "Female",
      RegNo: "REG495",
      country: "India",
      receiptNo: "REC32",
      address: "744 Elm St, City",
      oppCharges: 83,
      gstCharges: 15,
      OTTCharges: 60,
      totalAmount: 158,
      status: "Cancelled",
      JourneyDate: "2023-03-24",
    },
    {
      lastName: "Davis",
      firstName: "John",
      age: 46,
      gender: "Male",
      RegNo: "REG997",
      country: "Canada",
      receiptNo: "REC252",
      address: "961 Elm St, City",
      oppCharges: 193,
      gstCharges: 26,
      OTTCharges: 61,
      totalAmount: 280,
      status: "Paid",
      JourneyDate: "2024-05-17",
    },
    {
      lastName: "Johnson",
      firstName: "James",
      age: 53,
      gender: "Female",
      RegNo: "REG785",
      country: "Canada",
      receiptNo: "REC879",
      address: "373 Elm St, City",
      oppCharges: 135,
      gstCharges: 12,
      OTTCharges: 50,
      totalAmount: 197,
      status: "Pending",
      JourneyDate: "2023-03-31",
    },
    {
      lastName: "Jones",
      firstName: "William",
      age: 23,
      gender: "Female",
      RegNo: "REG732",
      country: "India",
      receiptNo: "REC134",
      address: "823 Elm St, City",
      oppCharges: 169,
      gstCharges: 13,
      OTTCharges: 78,
      totalAmount: 260,
      status: "Pending",
      JourneyDate: "2024-02-04",
    },
    {
      lastName: "Davis",
      firstName: "David",
      age: 22,
      gender: "Female",
      RegNo: "REG31",
      country: "UK",
      receiptNo: "REC959",
      address: "513 Elm St, City",
      oppCharges: 65,
      gstCharges: 13,
      OTTCharges: 35,
      totalAmount: 113,
      status: "Paid",
      JourneyDate: "2024-05-05",
    },
    {
      lastName: "Brown",
      firstName: "Richard",
      age: 65,
      gender: "Male",
      RegNo: "REG83",
      country: "India",
      receiptNo: "REC984",
      address: "575 Elm St, City",
      oppCharges: 57,
      gstCharges: 34,
      OTTCharges: 2,
      totalAmount: 93,
      status: "Cancelled",
      JourneyDate: "2023-01-03",
    },
    {
      lastName: "Johnson",
      firstName: "Robert",
      age: 65,
      gender: "Male",
      RegNo: "REG855",
      country: "USA",
      receiptNo: "REC821",
      address: "452 Elm St, City",
      oppCharges: 60,
      gstCharges: 17,
      OTTCharges: 46,
      totalAmount: 123,
      status: "Cancelled",
      JourneyDate: "2023-03-26",
    },
    {
      lastName: "Johnson",
      firstName: "Richard",
      age: 37,
      gender: "Female",
      RegNo: "REG155",
      country: "India",
      receiptNo: "REC855",
      address: "142 Elm St, City",
      oppCharges: 139,
      gstCharges: 27,
      OTTCharges: 92,
      totalAmount: 258,
      status: "Paid",
      JourneyDate: "2023-06-18",
    },
    {
      lastName: "Jones",
      firstName: "Richard",
      age: 62,
      gender: "Male",
      RegNo: "REG873",
      country: "India",
      receiptNo: "REC564",
      address: "193 Elm St, City",
      oppCharges: 79,
      gstCharges: 32,
      OTTCharges: 46,
      totalAmount: 157,
      status: "Pending",
      JourneyDate: "2023-12-04",
    },
    {
      lastName: "Miller",
      firstName: "David",
      age: 27,
      gender: "Female",
      RegNo: "REG463",
      country: "UK",
      receiptNo: "REC44",
      address: "806 Elm St, City",
      oppCharges: 83,
      gstCharges: 38,
      OTTCharges: 55,
      totalAmount: 176,
      status: "Paid",
      JourneyDate: "2023-10-31",
    },
    {
      lastName: "Smith",
      firstName: "John",
      age: 32,
      gender: "Male",
      RegNo: "REG310",
      country: "UK",
      receiptNo: "REC69",
      address: "693 Elm St, City",
      oppCharges: 167,
      gstCharges: 34,
      OTTCharges: 37,
      totalAmount: 238,
      status: "Cancelled",
      JourneyDate: "2024-04-15",
    },
    {
      lastName: "Davis",
      firstName: "David",
      age: 46,
      gender: "Female",
      RegNo: "REG812",
      country: "Canada",
      receiptNo: "REC627",
      address: "236 Elm St, City",
      oppCharges: 58,
      gstCharges: 27,
      OTTCharges: 12,
      totalAmount: 97,
      status: "Cancelled",
      JourneyDate: "2024-04-17",
    },
    {
      lastName: "Jones",
      firstName: "James",
      age: 47,
      gender: "Female",
      RegNo: "REG810",
      country: "Canada",
      receiptNo: "REC812",
      address: "1 Elm St, City",
      oppCharges: 168,
      gstCharges: 20,
      OTTCharges: 28,
      totalAmount: 216,
      status: "Pending",
      JourneyDate: "2023-01-21",
    },
    {
      lastName: "Davis",
      firstName: "David",
      age: 56,
      gender: "Female",
      RegNo: "REG896",
      country: "Canada",
      receiptNo: "REC741",
      address: "478 Elm St, City",
      oppCharges: 142,
      gstCharges: 28,
      OTTCharges: 29,
      totalAmount: 199,
      status: "Paid",
      JourneyDate: "2024-05-12",
    },
    {
      lastName: "Jones",
      firstName: "Robert",
      age: 35,
      gender: "Male",
      RegNo: "REG214",
      country: "Australia",
      receiptNo: "REC295",
      address: "417 Elm St, City",
      oppCharges: 231,
      gstCharges: 27,
      OTTCharges: 77,
      totalAmount: 335,
      status: "Pending",
      JourneyDate: "2023-06-10",
    },
    {
      lastName: "Johnson",
      firstName: "William",
      age: 23,
      gender: "Female",
      RegNo: "REG992",
      country: "India",
      receiptNo: "REC267",
      address: "578 Elm St, City",
      oppCharges: 99,
      gstCharges: 19,
      OTTCharges: 65,
      totalAmount: 183,
      status: "Cancelled",
      JourneyDate: "2023-01-19",
    },
    {
      lastName: "Miller",
      firstName: "Robert",
      age: 38,
      gender: "Female",
      RegNo: "REG476",
      country: "UK",
      receiptNo: "REC268",
      address: "724 Elm St, City",
      oppCharges: 99,
      gstCharges: 18,
      OTTCharges: 62,
      totalAmount: 179,
      status: "Cancelled",
      JourneyDate: "2023-01-20",
    },
    {
      lastName: "Davis",
      firstName: "James",
      age: 47,
      gender: "Male",
      RegNo: "REG26",
      country: "Australia",
      receiptNo: "REC353",
      address: "816 Elm St, City",
      oppCharges: 64,
      gstCharges: 12,
      OTTCharges: 84,
      totalAmount: 160,
      status: "Cancelled",
      JourneyDate: "2023-03-26",
    },
    {
      lastName: "Smith",
      firstName: "John",
      age: 37,
      gender: "Female",
      RegNo: "REG735",
      country: "UK",
      receiptNo: "REC762",
      address: "794 Elm St, City",
      oppCharges: 167,
      gstCharges: 37,
      OTTCharges: 93,
      totalAmount: 297,
      status: "Cancelled",
      JourneyDate: "2023-09-25",
    },
    {
      lastName: "Miller",
      firstName: "Robert",
      age: 37,
      gender: "Female",
      RegNo: "REG489",
      country: "India",
      receiptNo: "REC318",
      address: "300 Elm St, City",
      oppCharges: 131,
      gstCharges: 34,
      OTTCharges: 68,
      totalAmount: 233,
      status: "Pending",
      JourneyDate: "2024-04-28",
    },
    {
      lastName: "Johnson",
      firstName: "Robert",
      age: 61,
      gender: "Female",
      RegNo: "REG458",
      country: "Australia",
      receiptNo: "REC1",
      address: "342 Elm St, City",
      oppCharges: 140,
      gstCharges: 11,
      OTTCharges: 32,
      totalAmount: 183,
      status: "Paid",
      JourneyDate: "2023-05-17",
    },
    {
      lastName: "Miller",
      firstName: "David",
      age: 34,
      gender: "Male",
      RegNo: "REG601",
      country: "UK",
      receiptNo: "REC957",
      address: "166 Elm St, City",
      oppCharges: 57,
      gstCharges: 23,
      OTTCharges: 81,
      totalAmount: 161,
      status: "Pending",
      JourneyDate: "2024-05-20",
    },
    {
      lastName: "Jones",
      firstName: "Robert",
      age: 37,
      gender: "Male",
      RegNo: "REG715",
      country: "Australia",
      receiptNo: "REC27",
      address: "293 Elm St, City",
      oppCharges: 236,
      gstCharges: 37,
      OTTCharges: 59,
      totalAmount: 332,
      status: "Cancelled",
      JourneyDate: "2023-01-25",
    },
    {
      lastName: "Johnson",
      firstName: "John",
      age: 21,
      gender: "Female",
      RegNo: "REG914",
      country: "Canada",
      receiptNo: "REC590",
      address: "583 Elm St, City",
      oppCharges: 156,
      gstCharges: 34,
      OTTCharges: 2,
      totalAmount: 192,
      status: "Pending",
      JourneyDate: "2023-06-05",
    },
    {
      lastName: "Brown",
      firstName: "John",
      age: 65,
      gender: "Male",
      RegNo: "REG881",
      country: "Canada",
      receiptNo: "REC873",
      address: "815 Elm St, City",
      oppCharges: 201,
      gstCharges: 23,
      OTTCharges: 52,
      totalAmount: 276,
      status: "Paid",
      JourneyDate: "2023-01-06",
    },
    {
      lastName: "Johnson",
      firstName: "Richard",
      age: 35,
      gender: "Male",
      RegNo: "REG198",
      country: "Canada",
      receiptNo: "REC954",
      address: "900 Elm St, City",
      oppCharges: 100,
      gstCharges: 12,
      OTTCharges: 40,
      totalAmount: 152,
      status: "Paid",
      JourneyDate: "2023-05-12",
    },
    {
      lastName: "Davis",
      firstName: "Robert",
      age: 38,
      gender: "Male",
      RegNo: "REG543",
      country: "India",
      receiptNo: "REC1",
      address: "525 Elm St, City",
      oppCharges: 184,
      gstCharges: 36,
      OTTCharges: 86,
      totalAmount: 306,
      status: "Pending",
      JourneyDate: "2023-11-07",
    },
    {
      lastName: "Williams",
      firstName: "Robert",
      age: 31,
      gender: "Male",
      RegNo: "REG734",
      country: "India",
      receiptNo: "REC625",
      address: "350 Elm St, City",
      oppCharges: 113,
      gstCharges: 35,
      OTTCharges: 44,
      totalAmount: 192,
      status: "Pending",
      JourneyDate: "2024-05-28",
    },
    {
      lastName: "Smith",
      firstName: "John",
      age: 60,
      gender: "Female",
      RegNo: "REG279",
      country: "Canada",
      receiptNo: "REC700",
      address: "726 Elm St, City",
      oppCharges: 228,
      gstCharges: 16,
      OTTCharges: 10,
      totalAmount: 254,
      status: "Cancelled",
      JourneyDate: "2023-12-01",
    },
    {
      lastName: "Jones",
      firstName: "James",
      age: 57,
      gender: "Male",
      RegNo: "REG816",
      country: "Australia",
      receiptNo: "REC743",
      address: "807 Elm St, City",
      oppCharges: 234,
      gstCharges: 25,
      OTTCharges: 15,
      totalAmount: 274,
      status: "Paid",
      JourneyDate: "2024-03-24",
    },
    {
      lastName: "Johnson",
      firstName: "James",
      age: 40,
      gender: "Male",
      RegNo: "REG762",
      country: "Australia",
      receiptNo: "REC892",
      address: "694 Elm St, City",
      oppCharges: 194,
      gstCharges: 37,
      OTTCharges: 14,
      totalAmount: 245,
      status: "Pending",
      JourneyDate: "2024-04-05",
    },
    {
      lastName: "Smith",
      firstName: "Richard",
      age: 41,
      gender: "Female",
      RegNo: "REG349",
      country: "USA",
      receiptNo: "REC900",
      address: "904 Elm St, City",
      oppCharges: 117,
      gstCharges: 21,
      OTTCharges: 45,
      totalAmount: 183,
      status: "Cancelled",
      JourneyDate: "2024-06-23",
    },
    {
      lastName: "Miller",
      firstName: "John",
      age: 35,
      gender: "Female",
      RegNo: "REG272",
      country: "India",
      receiptNo: "REC766",
      address: "469 Elm St, City",
      oppCharges: 86,
      gstCharges: 34,
      OTTCharges: 22,
      totalAmount: 142,
      status: "Paid",
      JourneyDate: "2024-01-28",
    },
    {
      lastName: "Brown",
      firstName: "David",
      age: 32,
      gender: "Female",
      RegNo: "REG303",
      country: "India",
      receiptNo: "REC312",
      address: "712 Elm St, City",
      oppCharges: 155,
      gstCharges: 16,
      OTTCharges: 23,
      totalAmount: 194,
      status: "Pending",
      JourneyDate: "2024-04-29",
    },
    {
      lastName: "Smith",
      firstName: "Robert",
      age: 23,
      gender: "Female",
      RegNo: "REG779",
      country: "India",
      receiptNo: "REC522",
      address: "243 Elm St, City",
      oppCharges: 137,
      gstCharges: 21,
      OTTCharges: 51,
      totalAmount: 209,
      status: "Pending",
      JourneyDate: "2023-02-07",
    },
    {
      lastName: "Johnson",
      firstName: "James",
      age: 68,
      gender: "Male",
      RegNo: "REG349",
      country: "Australia",
      receiptNo: "REC479",
      address: "210 Elm St, City",
      oppCharges: 186,
      gstCharges: 20,
      OTTCharges: 71,
      totalAmount: 277,
      status: "Paid",
      JourneyDate: "2024-03-20",
    },
    {
      lastName: "Brown",
      firstName: "Richard",
      age: 38,
      gender: "Male",
      RegNo: "REG573",
      country: "Canada",
      receiptNo: "REC952",
      address: "336 Elm St, City",
      oppCharges: 234,
      gstCharges: 31,
      OTTCharges: 60,
      totalAmount: 325,
      status: "Cancelled",
      JourneyDate: "2023-07-19",
    },
    {
      lastName: "Brown",
      firstName: "John",
      age: 42,
      gender: "Female",
      RegNo: "REG600",
      country: "India",
      receiptNo: "REC560",
      address: "19 Elm St, City",
      oppCharges: 67,
      gstCharges: 16,
      OTTCharges: 46,
      totalAmount: 129,
      status: "Pending",
      JourneyDate: "2023-09-14",
    },
    {
      lastName: "Smith",
      firstName: "Robert",
      age: 36,
      gender: "Male",
      RegNo: "REG435",
      country: "USA",
      receiptNo: "REC261",
      address: "464 Elm St, City",
      oppCharges: 186,
      gstCharges: 31,
      OTTCharges: 87,
      totalAmount: 304,
      status: "Pending",
      JourneyDate: "2024-06-16",
    },
    {
      lastName: "Brown",
      firstName: "Robert",
      age: 57,
      gender: "Female",
      RegNo: "REG350",
      country: "Australia",
      receiptNo: "REC980",
      address: "598 Elm St, City",
      oppCharges: 217,
      gstCharges: 38,
      OTTCharges: 32,
      totalAmount: 287,
      status: "Paid",
      JourneyDate: "2024-04-04",
    },
    {
      lastName: "Brown",
      firstName: "James",
      age: 59,
      gender: "Female",
      RegNo: "REG660",
      country: "India",
      receiptNo: "REC432",
      address: "415 Elm St, City",
      oppCharges: 246,
      gstCharges: 32,
      OTTCharges: 81,
      totalAmount: 359,
      status: "Paid",
      JourneyDate: "2024-04-16",
    },
    {
      lastName: "Smith",
      firstName: "Robert",
      age: 46,
      gender: "Male",
      RegNo: "REG142",
      country: "Canada",
      receiptNo: "REC176",
      address: "515 Elm St, City",
      oppCharges: 124,
      gstCharges: 27,
      OTTCharges: 67,
      totalAmount: 218,
      status: "Cancelled",
      JourneyDate: "2024-04-12",
    },
    {
      lastName: "Miller",
      firstName: "Michael",
      age: 25,
      gender: "Male",
      RegNo: "REG407",
      country: "USA",
      receiptNo: "REC107",
      address: "301 Elm St, City",
      oppCharges: 108,
      gstCharges: 16,
      OTTCharges: 11,
      totalAmount: 135,
      status: "Pending",
      JourneyDate: "2024-02-22",
    },
    {
      lastName: "Johnson",
      firstName: "William",
      age: 23,
      gender: "Male",
      RegNo: "REG62",
      country: "Australia",
      receiptNo: "REC902",
      address: "151 Elm St, City",
      oppCharges: 92,
      gstCharges: 32,
      OTTCharges: 73,
      totalAmount: 197,
      status: "Pending",
      JourneyDate: "2023-05-18",
    },
    {
      lastName: "Smith",
      firstName: "James",
      age: 47,
      gender: "Male",
      RegNo: "REG150",
      country: "USA",
      receiptNo: "REC700",
      address: "462 Elm St, City",
      oppCharges: 78,
      gstCharges: 37,
      OTTCharges: 39,
      totalAmount: 154,
      status: "Paid",
      JourneyDate: "2024-03-13",
    },
    {
      lastName: "Davis",
      firstName: "Michael",
      age: 26,
      gender: "Male",
      RegNo: "REG852",
      country: "USA",
      receiptNo: "REC680",
      address: "537 Elm St, City",
      oppCharges: 184,
      gstCharges: 16,
      OTTCharges: 0,
      totalAmount: 200,
      status: "Paid",
      JourneyDate: "2024-06-14",
    },
    {
      lastName: "Jones",
      firstName: "David",
      age: 57,
      gender: "Female",
      RegNo: "REG151",
      country: "USA",
      receiptNo: "REC870",
      address: "822 Elm St, City",
      oppCharges: 168,
      gstCharges: 15,
      OTTCharges: 50,
      totalAmount: 233,
      status: "Paid",
      JourneyDate: "2023-05-31",
    },
    {
      lastName: "Davis",
      firstName: "William",
      age: 52,
      gender: "Female",
      RegNo: "REG73",
      country: "Canada",
      receiptNo: "REC623",
      address: "156 Elm St, City",
      oppCharges: 84,
      gstCharges: 33,
      OTTCharges: 4,
      totalAmount: 121,
      status: "Pending",
      JourneyDate: "2023-09-20",
    },
    {
      lastName: "Williams",
      firstName: "Michael",
      age: 23,
      gender: "Female",
      RegNo: "REG406",
      country: "India",
      receiptNo: "REC895",
      address: "506 Elm St, City",
      oppCharges: 139,
      gstCharges: 30,
      OTTCharges: 10,
      totalAmount: 179,
      status: "Paid",
      JourneyDate: "2023-05-24",
    },
    {
      lastName: "Davis",
      firstName: "Robert",
      age: 29,
      gender: "Male",
      RegNo: "REG644",
      country: "Canada",
      receiptNo: "REC203",
      address: "104 Elm St, City",
      oppCharges: 75,
      gstCharges: 24,
      OTTCharges: 88,
      totalAmount: 187,
      status: "Paid",
      JourneyDate: "2023-06-02",
    },
    {
      lastName: "Jones",
      firstName: "David",
      age: 63,
      gender: "Female",
      RegNo: "REG440",
      country: "Australia",
      receiptNo: "REC361",
      address: "364 Elm St, City",
      oppCharges: 202,
      gstCharges: 30,
      OTTCharges: 83,
      totalAmount: 315,
      status: "Cancelled",
      JourneyDate: "2023-05-18",
    },
    {
      lastName: "Miller",
      firstName: "James",
      age: 25,
      gender: "Male",
      RegNo: "REG359",
      country: "USA",
      receiptNo: "REC861",
      address: "747 Elm St, City",
      oppCharges: 130,
      gstCharges: 31,
      OTTCharges: 40,
      totalAmount: 201,
      status: "Paid",
      JourneyDate: "2023-04-22",
    },
    {
      lastName: "Jones",
      firstName: "James",
      age: 45,
      gender: "Female",
      RegNo: "REG798",
      country: "Australia",
      receiptNo: "REC458",
      address: "359 Elm St, City",
      oppCharges: 139,
      gstCharges: 17,
      OTTCharges: 55,
      totalAmount: 211,
      status: "Pending",
      JourneyDate: "2024-01-29",
    },
    {
      lastName: "Williams",
      firstName: "James",
      age: 22,
      gender: "Female",
      RegNo: "REG670",
      country: "UK",
      receiptNo: "REC305",
      address: "718 Elm St, City",
      oppCharges: 103,
      gstCharges: 19,
      OTTCharges: 96,
      totalAmount: 218,
      status: "Paid",
      JourneyDate: "2023-03-26",
    },
    {
      lastName: "Smith",
      firstName: "James",
      age: 23,
      gender: "Male",
      RegNo: "REG526",
      country: "UK",
      receiptNo: "REC970",
      address: "501 Elm St, City",
      oppCharges: 201,
      gstCharges: 16,
      OTTCharges: 55,
      totalAmount: 272,
      status: "Pending",
      JourneyDate: "2023-05-25",
    },
    {
      lastName: "Johnson",
      firstName: "William",
      age: 63,
      gender: "Female",
      RegNo: "REG853",
      country: "Canada",
      receiptNo: "REC837",
      address: "509 Elm St, City",
      oppCharges: 188,
      gstCharges: 29,
      OTTCharges: 31,
      totalAmount: 248,
      status: "Pending",
      JourneyDate: "2023-03-30",
    },
    {
      lastName: "Smith",
      firstName: "Richard",
      age: 64,
      gender: "Female",
      RegNo: "REG233",
      country: "Australia",
      receiptNo: "REC801",
      address: "233 Elm St, City",
      oppCharges: 66,
      gstCharges: 25,
      OTTCharges: 69,
      totalAmount: 160,
      status: "Pending",
      JourneyDate: "2024-03-31",
    },
    {
      lastName: "Davis",
      firstName: "Robert",
      age: 24,
      gender: "Female",
      RegNo: "REG326",
      country: "Australia",
      receiptNo: "REC922",
      address: "165 Elm St, City",
      oppCharges: 240,
      gstCharges: 10,
      OTTCharges: 48,
      totalAmount: 298,
      status: "Cancelled",
      JourneyDate: "2024-01-27",
    },
    {
      lastName: "Smith",
      firstName: "Richard",
      age: 45,
      gender: "Male",
      RegNo: "REG890",
      country: "Canada",
      receiptNo: "REC520",
      address: "930 Elm St, City",
      oppCharges: 91,
      gstCharges: 38,
      OTTCharges: 30,
      totalAmount: 159,
      status: "Paid",
      JourneyDate: "2023-08-04",
    },
    {
      lastName: "Smith",
      firstName: "William",
      age: 53,
      gender: "Male",
      RegNo: "REG713",
      country: "India",
      receiptNo: "REC161",
      address: "312 Elm St, City",
      oppCharges: 197,
      gstCharges: 36,
      OTTCharges: 46,
      totalAmount: 279,
      status: "Pending",
      JourneyDate: "2023-06-11",
    },
    {
      lastName: "Johnson",
      firstName: "Michael",
      age: 37,
      gender: "Female",
      RegNo: "REG869",
      country: "UK",
      receiptNo: "REC191",
      address: "33 Elm St, City",
      oppCharges: 199,
      gstCharges: 15,
      OTTCharges: 32,
      totalAmount: 246,
      status: "Paid",
      JourneyDate: "2023-03-27",
    },
    {
      lastName: "Johnson",
      firstName: "Richard",
      age: 34,
      gender: "Female",
      RegNo: "REG373",
      country: "Australia",
      receiptNo: "REC394",
      address: "405 Elm St, City",
      oppCharges: 158,
      gstCharges: 31,
      OTTCharges: 0,
      totalAmount: 189,
      status: "Cancelled",
      JourneyDate: "2023-06-13",
    },
    {
      lastName: "Johnson",
      firstName: "Robert",
      age: 24,
      gender: "Male",
      RegNo: "REG313",
      country: "USA",
      receiptNo: "REC686",
      address: "339 Elm St, City",
      oppCharges: 207,
      gstCharges: 11,
      OTTCharges: 41,
      totalAmount: 259,
      status: "Cancelled",
      JourneyDate: "2023-01-08",
    },
    {
      lastName: "Brown",
      firstName: "James",
      age: 34,
      gender: "Male",
      RegNo: "REG462",
      country: "Australia",
      receiptNo: "REC414",
      address: "177 Elm St, City",
      oppCharges: 206,
      gstCharges: 10,
      OTTCharges: 68,
      totalAmount: 284,
      status: "Pending",
      JourneyDate: "2024-05-24",
    },
    {
      lastName: "Williams",
      firstName: "Michael",
      age: 36,
      gender: "Female",
      RegNo: "REG890",
      country: "UK",
      receiptNo: "REC337",
      address: "538 Elm St, City",
      oppCharges: 221,
      gstCharges: 10,
      OTTCharges: 87,
      totalAmount: 318,
      status: "Paid",
      JourneyDate: "2024-06-05",
    },
    {
      lastName: "Williams",
      firstName: "David",
      age: 65,
      gender: "Female",
      RegNo: "REG419",
      country: "Canada",
      receiptNo: "REC193",
      address: "374 Elm St, City",
      oppCharges: 97,
      gstCharges: 34,
      OTTCharges: 10,
      totalAmount: 141,
      status: "Cancelled",
      JourneyDate: "2024-06-08",
    },
    {
      lastName: "Brown",
      firstName: "Michael",
      age: 58,
      gender: "Female",
      RegNo: "REG7",
      country: "USA",
      receiptNo: "REC717",
      address: "156 Elm St, City",
      oppCharges: 202,
      gstCharges: 29,
      OTTCharges: 41,
      totalAmount: 272,
      status: "Pending",
      JourneyDate: "2023-12-13",
    },
    {
      lastName: "Johnson",
      firstName: "John",
      age: 64,
      gender: "Female",
      RegNo: "REG140",
      country: "UK",
      receiptNo: "REC333",
      address: "449 Elm St, City",
      oppCharges: 76,
      gstCharges: 39,
      OTTCharges: 17,
      totalAmount: 132,
      status: "Pending",
      JourneyDate: "2023-03-09",
    },
    {
      lastName: "Davis",
      firstName: "James",
      age: 46,
      gender: "Female",
      RegNo: "REG910",
      country: "India",
      receiptNo: "REC439",
      address: "690 Elm St, City",
      oppCharges: 138,
      gstCharges: 12,
      OTTCharges: 27,
      totalAmount: 177,
      status: "Pending",
      JourneyDate: "2024-05-15",
    },
    {
      lastName: "Smith",
      firstName: "David",
      age: 24,
      gender: "Female",
      RegNo: "REG397",
      country: "India",
      receiptNo: "REC513",
      address: "750 Elm St, City",
      oppCharges: 195,
      gstCharges: 21,
      OTTCharges: 10,
      totalAmount: 226,
      status: "Pending",
      JourneyDate: "2023-01-07",
    },
    {
      lastName: "Miller",
      firstName: "William",
      age: 56,
      gender: "Female",
      RegNo: "REG359",
      country: "USA",
      receiptNo: "REC444",
      address: "337 Elm St, City",
      oppCharges: 171,
      gstCharges: 29,
      OTTCharges: 77,
      totalAmount: 277,
      status: "Paid",
      JourneyDate: "2024-05-30",
    },
    {
      lastName: "Jones",
      firstName: "David",
      age: 35,
      gender: "Male",
      RegNo: "REG865",
      country: "USA",
      receiptNo: "REC622",
      address: "380 Elm St, City",
      oppCharges: 232,
      gstCharges: 31,
      OTTCharges: 36,
      totalAmount: 299,
      status: "Pending",
      JourneyDate: "2023-01-29",
    },
    {
      lastName: "Davis",
      firstName: "William",
      age: 43,
      gender: "Female",
      RegNo: "REG806",
      country: "USA",
      receiptNo: "REC182",
      address: "577 Elm St, City",
      oppCharges: 168,
      gstCharges: 19,
      OTTCharges: 52,
      totalAmount: 239,
      status: "Paid",
      JourneyDate: "2023-02-01",
    },
    {
      lastName: "Johnson",
      firstName: "John",
      age: 41,
      gender: "Male",
      RegNo: "REG176",
      country: "UK",
      receiptNo: "REC992",
      address: "544 Elm St, City",
      oppCharges: 248,
      gstCharges: 27,
      OTTCharges: 51,
      totalAmount: 326,
      status: "Paid",
      JourneyDate: "2023-10-09",
    },
    {
      lastName: "Davis",
      firstName: "Michael",
      age: 46,
      gender: "Male",
      RegNo: "REG559",
      country: "Canada",
      receiptNo: "REC84",
      address: "758 Elm St, City",
      oppCharges: 112,
      gstCharges: 33,
      OTTCharges: 91,
      totalAmount: 236,
      status: "Pending",
      JourneyDate: "2024-02-16",
    },
    {
      lastName: "Brown",
      firstName: "John",
      age: 64,
      gender: "Male",
      RegNo: "REG742",
      country: "Canada",
      receiptNo: "REC185",
      address: "562 Elm St, City",
      oppCharges: 201,
      gstCharges: 35,
      OTTCharges: 30,
      totalAmount: 266,
      status: "Pending",
      JourneyDate: "2023-02-23",
    },
    {
      lastName: "Williams",
      firstName: "John",
      age: 24,
      gender: "Male",
      RegNo: "REG605",
      country: "UK",
      receiptNo: "REC749",
      address: "719 Elm St, City",
      oppCharges: 183,
      gstCharges: 39,
      OTTCharges: 74,
      totalAmount: 296,
      status: "Cancelled",
      JourneyDate: "2023-02-16",
    },
    {
      lastName: "Smith",
      firstName: "John",
      age: 29,
      gender: "Male",
      RegNo: "REG61",
      country: "India",
      receiptNo: "REC984",
      address: "526 Elm St, City",
      oppCharges: 201,
      gstCharges: 36,
      OTTCharges: 85,
      totalAmount: 322,
      status: "Cancelled",
      JourneyDate: "2023-08-07",
    },
    {
      lastName: "Smith",
      firstName: "William",
      age: 33,
      gender: "Female",
      RegNo: "REG61",
      country: "Australia",
      receiptNo: "REC730",
      address: "32 Elm St, City",
      oppCharges: 231,
      gstCharges: 12,
      OTTCharges: 72,
      totalAmount: 315,
      status: "Cancelled",
      JourneyDate: "2024-03-22",
    },
    {
      lastName: "Brown",
      firstName: "Michael",
      age: 22,
      gender: "Female",
      RegNo: "REG45",
      country: "USA",
      receiptNo: "REC332",
      address: "367 Elm St, City",
      oppCharges: 52,
      gstCharges: 31,
      OTTCharges: 67,
      totalAmount: 150,
      status: "Paid",
      JourneyDate: "2024-04-30",
    },
    {
      lastName: "Brown",
      firstName: "William",
      age: 24,
      gender: "Male",
      RegNo: "REG68",
      country: "USA",
      receiptNo: "REC144",
      address: "424 Elm St, City",
      oppCharges: 247,
      gstCharges: 25,
      OTTCharges: 44,
      totalAmount: 316,
      status: "Pending",
      JourneyDate: "2024-06-18",
    },
    {
      lastName: "Johnson",
      firstName: "James",
      age: 48,
      gender: "Male",
      RegNo: "REG203",
      country: "USA",
      receiptNo: "REC545",
      address: "35 Elm St, City",
      oppCharges: 160,
      gstCharges: 30,
      OTTCharges: 45,
      totalAmount: 235,
      status: "Cancelled",
      JourneyDate: "2023-08-11",
    },
    {
      lastName: "Miller",
      firstName: "James",
      age: 63,
      gender: "Male",
      RegNo: "REG534",
      country: "Australia",
      receiptNo: "REC523",
      address: "135 Elm St, City",
      oppCharges: 144,
      gstCharges: 32,
      OTTCharges: 16,
      totalAmount: 192,
      status: "Pending",
      JourneyDate: "2024-01-26",
    },
    {
      lastName: "Davis",
      firstName: "Robert",
      age: 46,
      gender: "Male",
      RegNo: "REG501",
      country: "UK",
      receiptNo: "REC379",
      address: "676 Elm St, City",
      oppCharges: 213,
      gstCharges: 30,
      OTTCharges: 92,
      totalAmount: 335,
      status: "Paid",
      JourneyDate: "2023-08-25",
    },
    {
      lastName: "Brown",
      firstName: "John",
      age: 47,
      gender: "Female",
      RegNo: "REG383",
      country: "India",
      receiptNo: "REC241",
      address: "508 Elm St, City",
      oppCharges: 199,
      gstCharges: 32,
      OTTCharges: 47,
      totalAmount: 278,
      status: "Paid",
      JourneyDate: "2024-07-10",
    },
    {
      lastName: "Miller",
      firstName: "James",
      age: 69,
      gender: "Female",
      RegNo: "REG895",
      country: "India",
      receiptNo: "REC786",
      address: "594 Elm St, City",
      oppCharges: 57,
      gstCharges: 30,
      OTTCharges: 33,
      totalAmount: 120,
      status: "Paid",
      JourneyDate: "2023-05-12",
    },
    {
      lastName: "Smith",
      firstName: "Michael",
      age: 21,
      gender: "Female",
      RegNo: "REG199",
      country: "Canada",
      receiptNo: "REC918",
      address: "496 Elm St, City",
      oppCharges: 132,
      gstCharges: 11,
      OTTCharges: 30,
      totalAmount: 173,
      status: "Pending",
      JourneyDate: "2023-12-29",
    },
    {
      lastName: "Miller",
      firstName: "William",
      age: 69,
      gender: "Male",
      RegNo: "REG188",
      country: "Canada",
      receiptNo: "REC477",
      address: "342 Elm St, City",
      oppCharges: 224,
      gstCharges: 11,
      OTTCharges: 19,
      totalAmount: 254,
      status: "Pending",
      JourneyDate: "2023-06-04",
    },
    {
      lastName: "Jones",
      firstName: "Michael",
      age: 33,
      gender: "Female",
      RegNo: "REG519",
      country: "UK",
      receiptNo: "REC151",
      address: "712 Elm St, City",
      oppCharges: 221,
      gstCharges: 27,
      OTTCharges: 83,
      totalAmount: 331,
      status: "Cancelled",
      JourneyDate: "2023-03-04",
    },
    {
      lastName: "Brown",
      firstName: "Robert",
      age: 27,
      gender: "Male",
      RegNo: "REG516",
      country: "Australia",
      receiptNo: "REC823",
      address: "517 Elm St, City",
      oppCharges: 75,
      gstCharges: 26,
      OTTCharges: 14,
      totalAmount: 115,
      status: "Pending",
      JourneyDate: "2024-02-12",
    },
    {
      lastName: "Williams",
      firstName: "William",
      age: 33,
      gender: "Male",
      RegNo: "REG177",
      country: "UK",
      receiptNo: "REC509",
      address: "885 Elm St, City",
      oppCharges: 201,
      gstCharges: 23,
      OTTCharges: 6,
      totalAmount: 230,
      status: "Paid",
      JourneyDate: "2023-06-29",
    },
    {
      lastName: "Jones",
      firstName: "Richard",
      age: 60,
      gender: "Male",
      RegNo: "REG34",
      country: "USA",
      receiptNo: "REC880",
      address: "972 Elm St, City",
      oppCharges: 146,
      gstCharges: 29,
      OTTCharges: 52,
      totalAmount: 227,
      status: "Pending",
      JourneyDate: "2024-04-09",
    },
    {
      lastName: "Brown",
      firstName: "David",
      age: 55,
      gender: "Female",
      RegNo: "REG628",
      country: "Canada",
      receiptNo: "REC325",
      address: "99 Elm St, City",
      oppCharges: 177,
      gstCharges: 31,
      OTTCharges: 9,
      totalAmount: 217,
      status: "Cancelled",
      JourneyDate: "2023-10-02",
    },
    {
      lastName: "Davis",
      firstName: "David",
      age: 58,
      gender: "Male",
      RegNo: "REG520",
      country: "Australia",
      receiptNo: "REC891",
      address: "984 Elm St, City",
      oppCharges: 207,
      gstCharges: 30,
      OTTCharges: 59,
      totalAmount: 296,
      status: "Paid",
      JourneyDate: "2024-04-28",
    },
    {
      lastName: "Johnson",
      firstName: "Michael",
      age: 27,
      gender: "Female",
      RegNo: "REG474",
      country: "Canada",
      receiptNo: "REC800",
      address: "331 Elm St, City",
      oppCharges: 141,
      gstCharges: 15,
      OTTCharges: 76,
      totalAmount: 232,
      status: "Paid",
      JourneyDate: "2023-11-18",
    },
    {
      lastName: "Davis",
      firstName: "William",
      age: 54,
      gender: "Male",
      RegNo: "REG704",
      country: "UK",
      receiptNo: "REC417",
      address: "237 Elm St, City",
      oppCharges: 204,
      gstCharges: 20,
      OTTCharges: 8,
      totalAmount: 232,
      status: "Pending",
      JourneyDate: "2023-01-15",
    },
    {
      lastName: "Brown",
      firstName: "James",
      age: 31,
      gender: "Female",
      RegNo: "REG921",
      country: "India",
      receiptNo: "REC705",
      address: "369 Elm St, City",
      oppCharges: 109,
      gstCharges: 20,
      OTTCharges: 61,
      totalAmount: 190,
      status: "Cancelled",
      JourneyDate: "2024-06-03",
    },
    {
      lastName: "Jones",
      firstName: "James",
      age: 20,
      gender: "Female",
      RegNo: "REG337",
      country: "USA",
      receiptNo: "REC188",
      address: "292 Elm St, City",
      oppCharges: 99,
      gstCharges: 35,
      OTTCharges: 16,
      totalAmount: 150,
      status: "Pending",
      JourneyDate: "2023-04-24",
    },
    {
      lastName: "Miller",
      firstName: "Richard",
      age: 61,
      gender: "Female",
      RegNo: "REG815",
      country: "UK",
      receiptNo: "REC223",
      address: "279 Elm St, City",
      oppCharges: 162,
      gstCharges: 18,
      OTTCharges: 35,
      totalAmount: 215,
      status: "Cancelled",
      JourneyDate: "2023-12-04",
    },
    {
      lastName: "Davis",
      firstName: "Michael",
      age: 62,
      gender: "Male",
      RegNo: "REG649",
      country: "Canada",
      receiptNo: "REC325",
      address: "397 Elm St, City",
      oppCharges: 137,
      gstCharges: 30,
      OTTCharges: 23,
      totalAmount: 190,
      status: "Pending",
      JourneyDate: "2024-06-26",
    },
    {
      lastName: "Jones",
      firstName: "John",
      age: 69,
      gender: "Male",
      RegNo: "REG290",
      country: "USA",
      receiptNo: "REC348",
      address: "165 Elm St, City",
      oppCharges: 66,
      gstCharges: 36,
      OTTCharges: 51,
      totalAmount: 153,
      status: "Cancelled",
      JourneyDate: "2024-02-27",
    },
    {
      lastName: "Williams",
      firstName: "Richard",
      age: 41,
      gender: "Male",
      RegNo: "REG781",
      country: "Canada",
      receiptNo: "REC373",
      address: "458 Elm St, City",
      oppCharges: 169,
      gstCharges: 36,
      OTTCharges: 89,
      totalAmount: 294,
      status: "Pending",
      JourneyDate: "2024-04-15",
    },
    {
      lastName: "Davis",
      firstName: "John",
      age: 60,
      gender: "Female",
      RegNo: "REG781",
      country: "UK",
      receiptNo: "REC132",
      address: "271 Elm St, City",
      oppCharges: 140,
      gstCharges: 36,
      OTTCharges: 42,
      totalAmount: 218,
      status: "Pending",
      JourneyDate: "2024-05-30",
    },
    {
      lastName: "Jones",
      firstName: "Michael",
      age: 35,
      gender: "Male",
      RegNo: "REG939",
      country: "UK",
      receiptNo: "REC748",
      address: "827 Elm St, City",
      oppCharges: 173,
      gstCharges: 37,
      OTTCharges: 23,
      totalAmount: 233,
      status: "Cancelled",
      JourneyDate: "2023-03-25",
    },
    {
      lastName: "Johnson",
      firstName: "Michael",
      age: 68,
      gender: "Male",
      RegNo: "REG103",
      country: "UK",
      receiptNo: "REC242",
      address: "371 Elm St, City",
      oppCharges: 60,
      gstCharges: 27,
      OTTCharges: 32,
      totalAmount: 119,
      status: "Pending",
      JourneyDate: "2024-06-13",
    },
    {
      lastName: "Jones",
      firstName: "William",
      age: 27,
      gender: "Male",
      RegNo: "REG304",
      country: "India",
      receiptNo: "REC597",
      address: "968 Elm St, City",
      oppCharges: 135,
      gstCharges: 38,
      OTTCharges: 1,
      totalAmount: 174,
      status: "Cancelled",
      JourneyDate: "2023-05-03",
    },
    {
      lastName: "Smith",
      firstName: "William",
      age: 68,
      gender: "Female",
      RegNo: "REG449",
      country: "USA",
      receiptNo: "REC314",
      address: "871 Elm St, City",
      oppCharges: 129,
      gstCharges: 28,
      OTTCharges: 78,
      totalAmount: 235,
      status: "Paid",
      JourneyDate: "2023-10-14",
    },
    {
      lastName: "Davis",
      firstName: "James",
      age: 21,
      gender: "Female",
      RegNo: "REG10",
      country: "Australia",
      receiptNo: "REC358",
      address: "436 Elm St, City",
      oppCharges: 245,
      gstCharges: 21,
      OTTCharges: 9,
      totalAmount: 275,
      status: "Cancelled",
      JourneyDate: "2023-03-23",
    },
    {
      lastName: "Jones",
      firstName: "David",
      age: 57,
      gender: "Male",
      RegNo: "REG754",
      country: "UK",
      receiptNo: "REC508",
      address: "994 Elm St, City",
      oppCharges: 238,
      gstCharges: 21,
      OTTCharges: 32,
      totalAmount: 291,
      status: "Paid",
      JourneyDate: "2023-05-26",
    },
    {
      lastName: "Jones",
      firstName: "David",
      age: 41,
      gender: "Female",
      RegNo: "REG599",
      country: "India",
      receiptNo: "REC527",
      address: "362 Elm St, City",
      oppCharges: 156,
      gstCharges: 20,
      OTTCharges: 69,
      totalAmount: 245,
      status: "Paid",
      JourneyDate: "2023-03-20",
    },
    {
      lastName: "Miller",
      firstName: "David",
      age: 54,
      gender: "Female",
      RegNo: "REG489",
      country: "Canada",
      receiptNo: "REC566",
      address: "868 Elm St, City",
      oppCharges: 126,
      gstCharges: 28,
      OTTCharges: 0,
      totalAmount: 154,
      status: "Paid",
      JourneyDate: "2023-06-05",
    },
    {
      lastName: "Davis",
      firstName: "John",
      age: 54,
      gender: "Male",
      RegNo: "REG442",
      country: "UK",
      receiptNo: "REC114",
      address: "236 Elm St, City",
      oppCharges: 214,
      gstCharges: 18,
      OTTCharges: 96,
      totalAmount: 328,
      status: "Cancelled",
      JourneyDate: "2023-07-31",
    },
    {
      lastName: "Miller",
      firstName: "James",
      age: 40,
      gender: "Male",
      RegNo: "REG604",
      country: "USA",
      receiptNo: "REC414",
      address: "278 Elm St, City",
      oppCharges: 199,
      gstCharges: 36,
      OTTCharges: 18,
      totalAmount: 253,
      status: "Cancelled",
      JourneyDate: "2023-04-08",
    },
    {
      lastName: "Smith",
      firstName: "Robert",
      age: 31,
      gender: "Female",
      RegNo: "REG205",
      country: "USA",
      receiptNo: "REC42",
      address: "113 Elm St, City",
      oppCharges: 154,
      gstCharges: 25,
      OTTCharges: 81,
      totalAmount: 260,
      status: "Cancelled",
      JourneyDate: "2023-04-15",
    },
    {
      lastName: "Brown",
      firstName: "John",
      age: 52,
      gender: "Female",
      RegNo: "REG903",
      country: "India",
      receiptNo: "REC869",
      address: "363 Elm St, City",
      oppCharges: 82,
      gstCharges: 20,
      OTTCharges: 48,
      totalAmount: 150,
      status: "Pending",
      JourneyDate: "2023-05-26",
    },
    {
      lastName: "Davis",
      firstName: "Richard",
      age: 57,
      gender: "Female",
      RegNo: "REG333",
      country: "India",
      receiptNo: "REC391",
      address: "582 Elm St, City",
      oppCharges: 52,
      gstCharges: 20,
      OTTCharges: 63,
      totalAmount: 135,
      status: "Pending",
      JourneyDate: "2023-08-02",
    },
    {
      lastName: "Jones",
      firstName: "David",
      age: 39,
      gender: "Female",
      RegNo: "REG609",
      country: "India",
      receiptNo: "REC900",
      address: "301 Elm St, City",
      oppCharges: 70,
      gstCharges: 10,
      OTTCharges: 83,
      totalAmount: 163,
      status: "Cancelled",
      JourneyDate: "2024-03-19",
    },
    {
      lastName: "Brown",
      firstName: "Michael",
      age: 50,
      gender: "Male",
      RegNo: "REG670",
      country: "India",
      receiptNo: "REC285",
      address: "767 Elm St, City",
      oppCharges: 107,
      gstCharges: 29,
      OTTCharges: 29,
      totalAmount: 165,
      status: "Paid",
      JourneyDate: "2023-12-23",
    },
    {
      lastName: "Jones",
      firstName: "William",
      age: 39,
      gender: "Male",
      RegNo: "REG545",
      country: "USA",
      receiptNo: "REC94",
      address: "799 Elm St, City",
      oppCharges: 245,
      gstCharges: 25,
      OTTCharges: 38,
      totalAmount: 308,
      status: "Cancelled",
      JourneyDate: "2023-02-28",
    },
    {
      lastName: "Davis",
      firstName: "John",
      age: 32,
      gender: "Male",
      RegNo: "REG605",
      country: "USA",
      receiptNo: "REC341",
      address: "486 Elm St, City",
      oppCharges: 203,
      gstCharges: 16,
      OTTCharges: 1,
      totalAmount: 220,
      status: "Cancelled",
      JourneyDate: "2024-04-29",
    },
    {
      lastName: "Davis",
      firstName: "Michael",
      age: 44,
      gender: "Male",
      RegNo: "REG398",
      country: "UK",
      receiptNo: "REC906",
      address: "630 Elm St, City",
      oppCharges: 234,
      gstCharges: 13,
      OTTCharges: 73,
      totalAmount: 320,
      status: "Pending",
      JourneyDate: "2023-09-21",
    },
    {
      lastName: "Davis",
      firstName: "Michael",
      age: 39,
      gender: "Female",
      RegNo: "REG123",
      country: "Canada",
      receiptNo: "REC159",
      address: "568 Elm St, City",
      oppCharges: 111,
      gstCharges: 11,
      OTTCharges: 64,
      totalAmount: 186,
      status: "Pending",
      JourneyDate: "2023-04-07",
    },
    {
      lastName: "Miller",
      firstName: "Robert",
      age: 42,
      gender: "Male",
      RegNo: "REG296",
      country: "Australia",
      receiptNo: "REC82",
      address: "984 Elm St, City",
      oppCharges: 84,
      gstCharges: 28,
      OTTCharges: 50,
      totalAmount: 162,
      status: "Paid",
      JourneyDate: "2023-03-11",
    },
    {
      lastName: "Davis",
      firstName: "David",
      age: 20,
      gender: "Female",
      RegNo: "REG881",
      country: "Canada",
      receiptNo: "REC174",
      address: "149 Elm St, City",
      oppCharges: 126,
      gstCharges: 31,
      OTTCharges: 47,
      totalAmount: 204,
      status: "Cancelled",
      JourneyDate: "2023-07-05",
    },
    {
      lastName: "Johnson",
      firstName: "William",
      age: 30,
      gender: "Male",
      RegNo: "REG513",
      country: "UK",
      receiptNo: "REC875",
      address: "189 Elm St, City",
      oppCharges: 110,
      gstCharges: 17,
      OTTCharges: 64,
      totalAmount: 191,
      status: "Paid",
      JourneyDate: "2024-05-19",
    },
    {
      lastName: "Miller",
      firstName: "David",
      age: 32,
      gender: "Female",
      RegNo: "REG785",
      country: "USA",
      receiptNo: "REC828",
      address: "829 Elm St, City",
      oppCharges: 145,
      gstCharges: 32,
      OTTCharges: 65,
      totalAmount: 242,
      status: "Cancelled",
      JourneyDate: "2024-04-18",
    },
    {
      lastName: "Brown",
      firstName: "James",
      age: 20,
      gender: "Female",
      RegNo: "REG412",
      country: "Australia",
      receiptNo: "REC627",
      address: "878 Elm St, City",
      oppCharges: 192,
      gstCharges: 19,
      OTTCharges: 8,
      totalAmount: 219,
      status: "Paid",
      JourneyDate: "2024-04-28",
    },
    {
      lastName: "Williams",
      firstName: "John",
      age: 34,
      gender: "Female",
      RegNo: "REG461",
      country: "India",
      receiptNo: "REC130",
      address: "760 Elm St, City",
      oppCharges: 61,
      gstCharges: 27,
      OTTCharges: 74,
      totalAmount: 162,
      status: "Cancelled",
      JourneyDate: "2023-01-29",
    },
    {
      lastName: "Brown",
      firstName: "Robert",
      age: 65,
      gender: "Male",
      RegNo: "REG480",
      country: "Australia",
      receiptNo: "REC136",
      address: "84 Elm St, City",
      oppCharges: 185,
      gstCharges: 35,
      OTTCharges: 93,
      totalAmount: 313,
      status: "Pending",
      JourneyDate: "2023-01-13",
    },
    {
      lastName: "Smith",
      firstName: "Michael",
      age: 33,
      gender: "Male",
      RegNo: "REG184",
      country: "India",
      receiptNo: "REC427",
      address: "127 Elm St, City",
      oppCharges: 185,
      gstCharges: 19,
      OTTCharges: 2,
      totalAmount: 206,
      status: "Paid",
      JourneyDate: "2023-09-18",
    },
    {
      lastName: "Miller",
      firstName: "Michael",
      age: 53,
      gender: "Female",
      RegNo: "REG139",
      country: "India",
      receiptNo: "REC341",
      address: "421 Elm St, City",
      oppCharges: 107,
      gstCharges: 19,
      OTTCharges: 68,
      totalAmount: 194,
      status: "Pending",
      JourneyDate: "2023-02-18",
    },
    {
      lastName: "Davis",
      firstName: "William",
      age: 36,
      gender: "Male",
      RegNo: "REG107",
      country: "India",
      receiptNo: "REC609",
      address: "312 Elm St, City",
      oppCharges: 208,
      gstCharges: 29,
      OTTCharges: 97,
      totalAmount: 334,
      status: "Cancelled",
      JourneyDate: "2023-02-11",
    },
    {
      lastName: "Miller",
      firstName: "James",
      age: 33,
      gender: "Female",
      RegNo: "REG537",
      country: "Australia",
      receiptNo: "REC757",
      address: "710 Elm St, City",
      oppCharges: 76,
      gstCharges: 13,
      OTTCharges: 89,
      totalAmount: 178,
      status: "Pending",
      JourneyDate: "2023-06-21",
    },
    {
      lastName: "Miller",
      firstName: "Robert",
      age: 64,
      gender: "Female",
      RegNo: "REG517",
      country: "UK",
      receiptNo: "REC507",
      address: "92 Elm St, City",
      oppCharges: 215,
      gstCharges: 30,
      OTTCharges: 54,
      totalAmount: 299,
      status: "Pending",
      JourneyDate: "2023-05-18",
    },
    {
      lastName: "Brown",
      firstName: "Robert",
      age: 25,
      gender: "Male",
      RegNo: "REG601",
      country: "Canada",
      receiptNo: "REC932",
      address: "149 Elm St, City",
      oppCharges: 82,
      gstCharges: 33,
      OTTCharges: 3,
      totalAmount: 118,
      status: "Paid",
      JourneyDate: "2023-11-16",
    },
    {
      lastName: "Williams",
      firstName: "John",
      age: 22,
      gender: "Male",
      RegNo: "REG939",
      country: "Canada",
      receiptNo: "REC527",
      address: "188 Elm St, City",
      oppCharges: 206,
      gstCharges: 32,
      OTTCharges: 27,
      totalAmount: 265,
      status: "Cancelled",
      JourneyDate: "2023-01-30",
    },
    {
      lastName: "Jones",
      firstName: "William",
      age: 43,
      gender: "Male",
      RegNo: "REG25",
      country: "UK",
      receiptNo: "REC61",
      address: "590 Elm St, City",
      oppCharges: 112,
      gstCharges: 19,
      OTTCharges: 95,
      totalAmount: 226,
      status: "Paid",
      JourneyDate: "2023-10-31",
    },
    {
      lastName: "Johnson",
      firstName: "Michael",
      age: 51,
      gender: "Male",
      RegNo: "REG195",
      country: "India",
      receiptNo: "REC112",
      address: "166 Elm St, City",
      oppCharges: 128,
      gstCharges: 36,
      OTTCharges: 5,
      totalAmount: 169,
      status: "Paid",
      JourneyDate: "2023-05-22",
    },
    {
      lastName: "Miller",
      firstName: "Michael",
      age: 51,
      gender: "Male",
      RegNo: "REG715",
      country: "Canada",
      receiptNo: "REC544",
      address: "38 Elm St, City",
      oppCharges: 195,
      gstCharges: 14,
      OTTCharges: 35,
      totalAmount: 244,
      status: "Pending",
      JourneyDate: "2024-02-09",
    },
    {
      lastName: "Williams",
      firstName: "Richard",
      age: 67,
      gender: "Female",
      RegNo: "REG539",
      country: "India",
      receiptNo: "REC557",
      address: "324 Elm St, City",
      oppCharges: 215,
      gstCharges: 37,
      OTTCharges: 58,
      totalAmount: 310,
      status: "Cancelled",
      JourneyDate: "2024-02-09",
    },
    {
      lastName: "Brown",
      firstName: "Michael",
      age: 34,
      gender: "Female",
      RegNo: "REG532",
      country: "Australia",
      receiptNo: "REC457",
      address: "893 Elm St, City",
      oppCharges: 78,
      gstCharges: 11,
      OTTCharges: 15,
      totalAmount: 104,
      status: "Paid",
      JourneyDate: "2024-05-03",
    },
    {
      lastName: "Brown",
      firstName: "Robert",
      age: 27,
      gender: "Female",
      RegNo: "REG909",
      country: "Canada",
      receiptNo: "REC46",
      address: "942 Elm St, City",
      oppCharges: 166,
      gstCharges: 13,
      OTTCharges: 84,
      totalAmount: 263,
      status: "Cancelled",
      JourneyDate: "2024-05-16",
    },
    {
      lastName: "Miller",
      firstName: "Michael",
      age: 40,
      gender: "Male",
      RegNo: "REG201",
      country: "India",
      receiptNo: "REC575",
      address: "907 Elm St, City",
      oppCharges: 201,
      gstCharges: 18,
      OTTCharges: 3,
      totalAmount: 222,
      status: "Pending",
      JourneyDate: "2023-04-06",
    },
    {
      lastName: "Williams",
      firstName: "Robert",
      age: 26,
      gender: "Male",
      RegNo: "REG700",
      country: "UK",
      receiptNo: "REC764",
      address: "945 Elm St, City",
      oppCharges: 162,
      gstCharges: 39,
      OTTCharges: 56,
      totalAmount: 257,
      status: "Pending",
      JourneyDate: "2023-08-13",
    },
    {
      lastName: "Smith",
      firstName: "James",
      age: 50,
      gender: "Male",
      RegNo: "REG452",
      country: "Canada",
      receiptNo: "REC607",
      address: "417 Elm St, City",
      oppCharges: 176,
      gstCharges: 28,
      OTTCharges: 95,
      totalAmount: 299,
      status: "Pending",
      JourneyDate: "2023-07-29",
    },
    {
      lastName: "Smith",
      firstName: "David",
      age: 53,
      gender: "Male",
      RegNo: "REG725",
      country: "USA",
      receiptNo: "REC710",
      address: "221 Elm St, City",
      oppCharges: 182,
      gstCharges: 36,
      OTTCharges: 69,
      totalAmount: 287,
      status: "Cancelled",
      JourneyDate: "2023-03-31",
    },
    {
      lastName: "Jones",
      firstName: "Richard",
      age: 29,
      gender: "Male",
      RegNo: "REG122",
      country: "USA",
      receiptNo: "REC44",
      address: "927 Elm St, City",
      oppCharges: 213,
      gstCharges: 18,
      OTTCharges: 5,
      totalAmount: 236,
      status: "Pending",
      JourneyDate: "2024-01-23",
    },
    {
      lastName: "Jones",
      firstName: "Robert",
      age: 68,
      gender: "Female",
      RegNo: "REG840",
      country: "India",
      receiptNo: "REC51",
      address: "866 Elm St, City",
      oppCharges: 69,
      gstCharges: 10,
      OTTCharges: 9,
      totalAmount: 88,
      status: "Pending",
      JourneyDate: "2024-01-31",
    },
    {
      lastName: "Williams",
      firstName: "David",
      age: 62,
      gender: "Female",
      RegNo: "REG280",
      country: "Australia",
      receiptNo: "REC550",
      address: "496 Elm St, City",
      oppCharges: 245,
      gstCharges: 18,
      OTTCharges: 96,
      totalAmount: 359,
      status: "Paid",
      JourneyDate: "2024-06-23",
    },
    {
      lastName: "Miller",
      firstName: "William",
      age: 54,
      gender: "Male",
      RegNo: "REG640",
      country: "Canada",
      receiptNo: "REC670",
      address: "7 Elm St, City",
      oppCharges: 52,
      gstCharges: 10,
      OTTCharges: 94,
      totalAmount: 156,
      status: "Paid",
      JourneyDate: "2023-01-01",
    },
    {
      lastName: "Miller",
      firstName: "John",
      age: 38,
      gender: "Male",
      RegNo: "REG376",
      country: "Australia",
      receiptNo: "REC945",
      address: "575 Elm St, City",
      oppCharges: 145,
      gstCharges: 30,
      OTTCharges: 2,
      totalAmount: 177,
      status: "Cancelled",
      JourneyDate: "2023-05-01",
    },
    {
      lastName: "Smith",
      firstName: "William",
      age: 58,
      gender: "Male",
      RegNo: "REG520",
      country: "India",
      receiptNo: "REC793",
      address: "81 Elm St, City",
      oppCharges: 93,
      gstCharges: 15,
      OTTCharges: 5,
      totalAmount: 113,
      status: "Pending",
      JourneyDate: "2023-01-25",
    },
    {
      lastName: "Jones",
      firstName: "William",
      age: 56,
      gender: "Female",
      RegNo: "REG872",
      country: "India",
      receiptNo: "REC867",
      address: "713 Elm St, City",
      oppCharges: 132,
      gstCharges: 14,
      OTTCharges: 57,
      totalAmount: 203,
      status: "Paid",
      JourneyDate: "2023-06-27",
    },
    {
      lastName: "Davis",
      firstName: "Richard",
      age: 64,
      gender: "Male",
      RegNo: "REG173",
      country: "Australia",
      receiptNo: "REC373",
      address: "378 Elm St, City",
      oppCharges: 218,
      gstCharges: 19,
      OTTCharges: 95,
      totalAmount: 332,
      status: "Pending",
      JourneyDate: "2023-02-12",
    },
    {
      lastName: "Miller",
      firstName: "James",
      age: 58,
      gender: "Female",
      RegNo: "REG659",
      country: "UK",
      receiptNo: "REC653",
      address: "822 Elm St, City",
      oppCharges: 159,
      gstCharges: 12,
      OTTCharges: 76,
      totalAmount: 247,
      status: "Paid",
      JourneyDate: "2023-08-25",
    },
    {
      lastName: "Davis",
      firstName: "David",
      age: 38,
      gender: "Male",
      RegNo: "REG249",
      country: "UK",
      receiptNo: "REC785",
      address: "314 Elm St, City",
      oppCharges: 140,
      gstCharges: 23,
      OTTCharges: 35,
      totalAmount: 198,
      status: "Paid",
      JourneyDate: "2023-12-30",
    },
    {
      lastName: "Davis",
      firstName: "James",
      age: 20,
      gender: "Male",
      RegNo: "REG329",
      country: "UK",
      receiptNo: "REC333",
      address: "731 Elm St, City",
      oppCharges: 171,
      gstCharges: 20,
      OTTCharges: 51,
      totalAmount: 242,
      status: "Paid",
      JourneyDate: "2023-01-12",
    },
    {
      lastName: "Smith",
      firstName: "James",
      age: 28,
      gender: "Female",
      RegNo: "REG260",
      country: "Australia",
      receiptNo: "REC988",
      address: "701 Elm St, City",
      oppCharges: 196,
      gstCharges: 37,
      OTTCharges: 80,
      totalAmount: 313,
      status: "Pending",
      JourneyDate: "2023-01-21",
    },
    {
      lastName: "Miller",
      firstName: "John",
      age: 42,
      gender: "Female",
      RegNo: "REG516",
      country: "UK",
      receiptNo: "REC703",
      address: "290 Elm St, City",
      oppCharges: 110,
      gstCharges: 27,
      OTTCharges: 69,
      totalAmount: 206,
      status: "Paid",
      JourneyDate: "2024-04-14",
    },
    {
      lastName: "Davis",
      firstName: "Michael",
      age: 53,
      gender: "Female",
      RegNo: "REG64",
      country: "Australia",
      receiptNo: "REC305",
      address: "293 Elm St, City",
      oppCharges: 160,
      gstCharges: 13,
      OTTCharges: 76,
      totalAmount: 249,
      status: "Pending",
      JourneyDate: "2024-03-31",
    },
    {
      lastName: "Brown",
      firstName: "James",
      age: 31,
      gender: "Female",
      RegNo: "REG714",
      country: "UK",
      receiptNo: "REC612",
      address: "560 Elm St, City",
      oppCharges: 73,
      gstCharges: 17,
      OTTCharges: 88,
      totalAmount: 178,
      status: "Cancelled",
      JourneyDate: "2023-12-07",
    },
    {
      lastName: "Williams",
      firstName: "Robert",
      age: 24,
      gender: "Male",
      RegNo: "REG849",
      country: "USA",
      receiptNo: "REC916",
      address: "985 Elm St, City",
      oppCharges: 196,
      gstCharges: 34,
      OTTCharges: 34,
      totalAmount: 264,
      status: "Pending",
      JourneyDate: "2023-04-17",
    },
    {
      lastName: "Brown",
      firstName: "James",
      age: 27,
      gender: "Male",
      RegNo: "REG943",
      country: "UK",
      receiptNo: "REC848",
      address: "333 Elm St, City",
      oppCharges: 238,
      gstCharges: 20,
      OTTCharges: 23,
      totalAmount: 281,
      status: "Cancelled",
      JourneyDate: "2024-05-14",
    },
    {
      lastName: "Williams",
      firstName: "Robert",
      age: 27,
      gender: "Female",
      RegNo: "REG413",
      country: "UK",
      receiptNo: "REC634",
      address: "365 Elm St, City",
      oppCharges: 68,
      gstCharges: 19,
      OTTCharges: 17,
      totalAmount: 104,
      status: "Cancelled",
      JourneyDate: "2023-05-06",
    },
    {
      lastName: "Williams",
      firstName: "Michael",
      age: 32,
      gender: "Female",
      RegNo: "REG42",
      country: "UK",
      receiptNo: "REC359",
      address: "550 Elm St, City",
      oppCharges: 138,
      gstCharges: 19,
      OTTCharges: 77,
      totalAmount: 234,
      status: "Cancelled",
      JourneyDate: "2023-03-27",
    },
    {
      lastName: "Jones",
      firstName: "Richard",
      age: 23,
      gender: "Female",
      RegNo: "REG223",
      country: "UK",
      receiptNo: "REC611",
      address: "229 Elm St, City",
      oppCharges: 53,
      gstCharges: 22,
      OTTCharges: 61,
      totalAmount: 136,
      status: "Pending",
      JourneyDate: "2023-08-06",
    },
    {
      lastName: "Davis",
      firstName: "John",
      age: 20,
      gender: "Female",
      RegNo: "REG532",
      country: "Canada",
      receiptNo: "REC240",
      address: "110 Elm St, City",
      oppCharges: 171,
      gstCharges: 38,
      OTTCharges: 64,
      totalAmount: 273,
      status: "Pending",
      JourneyDate: "2024-02-19",
    },
    {
      lastName: "Johnson",
      firstName: "Robert",
      age: 46,
      gender: "Male",
      RegNo: "REG265",
      country: "Australia",
      receiptNo: "REC461",
      address: "723 Elm St, City",
      oppCharges: 153,
      gstCharges: 31,
      OTTCharges: 89,
      totalAmount: 273,
      status: "Cancelled",
      JourneyDate: "2024-04-04",
    },
    {
      lastName: "Miller",
      firstName: "Michael",
      age: 57,
      gender: "Male",
      RegNo: "REG578",
      country: "Canada",
      receiptNo: "REC370",
      address: "243 Elm St, City",
      oppCharges: 208,
      gstCharges: 29,
      OTTCharges: 38,
      totalAmount: 275,
      status: "Paid",
      JourneyDate: "2023-06-28",
    },
    {
      lastName: "Jones",
      firstName: "David",
      age: 28,
      gender: "Male",
      RegNo: "REG873",
      country: "UK",
      receiptNo: "REC45",
      address: "326 Elm St, City",
      oppCharges: 86,
      gstCharges: 30,
      OTTCharges: 47,
      totalAmount: 163,
      status: "Paid",
      JourneyDate: "2024-03-01",
    },
    {
      lastName: "Miller",
      firstName: "Michael",
      age: 58,
      gender: "Female",
      RegNo: "REG635",
      country: "Canada",
      receiptNo: "REC716",
      address: "847 Elm St, City",
      oppCharges: 205,
      gstCharges: 27,
      OTTCharges: 71,
      totalAmount: 303,
      status: "Paid",
      JourneyDate: "2023-01-24",
    },
    {
      lastName: "Jones",
      firstName: "Michael",
      age: 49,
      gender: "Female",
      RegNo: "REG863",
      country: "UK",
      receiptNo: "REC166",
      address: "103 Elm St, City",
      oppCharges: 54,
      gstCharges: 34,
      OTTCharges: 17,
      totalAmount: 105,
      status: "Pending",
      JourneyDate: "2023-02-27",
    },
    {
      lastName: "Jones",
      firstName: "David",
      age: 35,
      gender: "Male",
      RegNo: "REG98",
      country: "UK",
      receiptNo: "REC255",
      address: "784 Elm St, City",
      oppCharges: 94,
      gstCharges: 32,
      OTTCharges: 97,
      totalAmount: 223,
      status: "Paid",
      JourneyDate: "2023-08-04",
    },
    {
      lastName: "Williams",
      firstName: "Richard",
      age: 39,
      gender: "Female",
      RegNo: "REG181",
      country: "UK",
      receiptNo: "REC826",
      address: "319 Elm St, City",
      oppCharges: 236,
      gstCharges: 27,
      OTTCharges: 91,
      totalAmount: 354,
      status: "Paid",
      JourneyDate: "2023-06-29",
    },
    {
      lastName: "Davis",
      firstName: "William",
      age: 38,
      gender: "Female",
      RegNo: "REG926",
      country: "Australia",
      receiptNo: "REC711",
      address: "213 Elm St, City",
      oppCharges: 179,
      gstCharges: 30,
      OTTCharges: 55,
      totalAmount: 264,
      status: "Cancelled",
      JourneyDate: "2023-11-26",
    },
    {
      lastName: "Davis",
      firstName: "Michael",
      age: 58,
      gender: "Female",
      RegNo: "REG210",
      country: "India",
      receiptNo: "REC148",
      address: "624 Elm St, City",
      oppCharges: 120,
      gstCharges: 33,
      OTTCharges: 78,
      totalAmount: 231,
      status: "Paid",
      JourneyDate: "2024-01-10",
    },
    {
      lastName: "Johnson",
      firstName: "Richard",
      age: 47,
      gender: "Male",
      RegNo: "REG641",
      country: "UK",
      receiptNo: "REC759",
      address: "508 Elm St, City",
      oppCharges: 115,
      gstCharges: 32,
      OTTCharges: 1,
      totalAmount: 148,
      status: "Cancelled",
      JourneyDate: "2024-06-13",
    },
    {
      lastName: "Miller",
      firstName: "Michael",
      age: 24,
      gender: "Male",
      RegNo: "REG444",
      country: "India",
      receiptNo: "REC55",
      address: "157 Elm St, City",
      oppCharges: 129,
      gstCharges: 25,
      OTTCharges: 49,
      totalAmount: 203,
      status: "Paid",
      JourneyDate: "2024-02-29",
    },
    {
      lastName: "Brown",
      firstName: "William",
      age: 38,
      gender: "Female",
      RegNo: "REG801",
      country: "Canada",
      receiptNo: "REC870",
      address: "121 Elm St, City",
      oppCharges: 172,
      gstCharges: 25,
      OTTCharges: 88,
      totalAmount: 285,
      status: "Pending",
      JourneyDate: "2024-06-30",
    },
    {
      lastName: "Miller",
      firstName: "David",
      age: 47,
      gender: "Female",
      RegNo: "REG110",
      country: "UK",
      receiptNo: "REC971",
      address: "293 Elm St, City",
      oppCharges: 146,
      gstCharges: 38,
      OTTCharges: 68,
      totalAmount: 252,
      status: "Pending",
      JourneyDate: "2023-04-22",
    },
    {
      lastName: "Davis",
      firstName: "James",
      age: 68,
      gender: "Male",
      RegNo: "REG929",
      country: "India",
      receiptNo: "REC948",
      address: "552 Elm St, City",
      oppCharges: 138,
      gstCharges: 26,
      OTTCharges: 31,
      totalAmount: 195,
      status: "Cancelled",
      JourneyDate: "2024-03-31",
    },
    {
      lastName: "Brown",
      firstName: "Robert",
      age: 63,
      gender: "Male",
      RegNo: "REG856",
      country: "Australia",
      receiptNo: "REC679",
      address: "733 Elm St, City",
      oppCharges: 123,
      gstCharges: 30,
      OTTCharges: 88,
      totalAmount: 241,
      status: "Paid",
      JourneyDate: "2023-10-17",
    },
    {
      lastName: "Williams",
      firstName: "John",
      age: 46,
      gender: "Male",
      RegNo: "REG509",
      country: "Canada",
      receiptNo: "REC940",
      address: "702 Elm St, City",
      oppCharges: 93,
      gstCharges: 17,
      OTTCharges: 68,
      totalAmount: 178,
      status: "Pending",
      JourneyDate: "2023-08-06",
    },
    {
      lastName: "Johnson",
      firstName: "John",
      age: 51,
      gender: "Female",
      RegNo: "REG168",
      country: "India",
      receiptNo: "REC170",
      address: "417 Elm St, City",
      oppCharges: 227,
      gstCharges: 14,
      OTTCharges: 70,
      totalAmount: 311,
      status: "Pending",
      JourneyDate: "2024-06-10",
    },
    {
      lastName: "Jones",
      firstName: "William",
      age: 46,
      gender: "Male",
      RegNo: "REG867",
      country: "UK",
      receiptNo: "REC129",
      address: "347 Elm St, City",
      oppCharges: 194,
      gstCharges: 24,
      OTTCharges: 37,
      totalAmount: 255,
      status: "Cancelled",
      JourneyDate: "2023-11-23",
    },
    {
      lastName: "Johnson",
      firstName: "Robert",
      age: 38,
      gender: "Female",
      RegNo: "REG64",
      country: "Canada",
      receiptNo: "REC170",
      address: "246 Elm St, City",
      oppCharges: 199,
      gstCharges: 25,
      OTTCharges: 87,
      totalAmount: 311,
      status: "Cancelled",
      JourneyDate: "2024-01-14",
    },
    {
      lastName: "Jones",
      firstName: "William",
      age: 54,
      gender: "Male",
      RegNo: "REG189",
      country: "UK",
      receiptNo: "REC671",
      address: "414 Elm St, City",
      oppCharges: 72,
      gstCharges: 13,
      OTTCharges: 39,
      totalAmount: 124,
      status: "Paid",
      JourneyDate: "2023-09-04",
    },
    {
      lastName: "Jones",
      firstName: "William",
      age: 47,
      gender: "Female",
      RegNo: "REG674",
      country: "UK",
      receiptNo: "REC412",
      address: "525 Elm St, City",
      oppCharges: 249,
      gstCharges: 17,
      OTTCharges: 43,
      totalAmount: 309,
      status: "Pending",
      JourneyDate: "2023-12-15",
    },
    {
      lastName: "Miller",
      firstName: "James",
      age: 45,
      gender: "Female",
      RegNo: "REG873",
      country: "UK",
      receiptNo: "REC439",
      address: "438 Elm St, City",
      oppCharges: 129,
      gstCharges: 21,
      OTTCharges: 88,
      totalAmount: 238,
      status: "Paid",
      JourneyDate: "2023-09-27",
    },
    {
      lastName: "Davis",
      firstName: "John",
      age: 38,
      gender: "Male",
      RegNo: "REG479",
      country: "Australia",
      receiptNo: "REC129",
      address: "248 Elm St, City",
      oppCharges: 57,
      gstCharges: 21,
      OTTCharges: 90,
      totalAmount: 168,
      status: "Paid",
      JourneyDate: "2023-02-07",
    },
    {
      lastName: "Miller",
      firstName: "Richard",
      age: 35,
      gender: "Female",
      RegNo: "REG887",
      country: "Australia",
      receiptNo: "REC7",
      address: "490 Elm St, City",
      oppCharges: 147,
      gstCharges: 38,
      OTTCharges: 77,
      totalAmount: 262,
      status: "Paid",
      JourneyDate: "2023-11-16",
    },
    {
      lastName: "Davis",
      firstName: "Robert",
      age: 34,
      gender: "Male",
      RegNo: "REG478",
      country: "Canada",
      receiptNo: "REC821",
      address: "914 Elm St, City",
      oppCharges: 118,
      gstCharges: 26,
      OTTCharges: 23,
      totalAmount: 167,
      status: "Cancelled",
      JourneyDate: "2023-10-19",
    },
    {
      lastName: "Jones",
      firstName: "David",
      age: 69,
      gender: "Male",
      RegNo: "REG916",
      country: "Australia",
      receiptNo: "REC694",
      address: "755 Elm St, City",
      oppCharges: 60,
      gstCharges: 21,
      OTTCharges: 58,
      totalAmount: 139,
      status: "Cancelled",
      JourneyDate: "2024-07-11",
    },
    {
      lastName: "Jones",
      firstName: "James",
      age: 66,
      gender: "Female",
      RegNo: "REG167",
      country: "Australia",
      receiptNo: "REC715",
      address: "951 Elm St, City",
      oppCharges: 86,
      gstCharges: 10,
      OTTCharges: 99,
      totalAmount: 195,
      status: "Pending",
      JourneyDate: "2023-02-23",
    },
    {
      lastName: "Miller",
      firstName: "James",
      age: 69,
      gender: "Female",
      RegNo: "REG718",
      country: "UK",
      receiptNo: "REC248",
      address: "743 Elm St, City",
      oppCharges: 116,
      gstCharges: 23,
      OTTCharges: 86,
      totalAmount: 225,
      status: "Pending",
      JourneyDate: "2024-02-15",
    },
    {
      lastName: "Miller",
      firstName: "David",
      age: 66,
      gender: "Female",
      RegNo: "REG961",
      country: "Australia",
      receiptNo: "REC5",
      address: "927 Elm St, City",
      oppCharges: 214,
      gstCharges: 30,
      OTTCharges: 27,
      totalAmount: 271,
      status: "Paid",
      JourneyDate: "2024-01-20",
    },
    {
      lastName: "Williams",
      firstName: "John",
      age: 29,
      gender: "Male",
      RegNo: "REG163",
      country: "Australia",
      receiptNo: "REC62",
      address: "223 Elm St, City",
      oppCharges: 162,
      gstCharges: 24,
      OTTCharges: 66,
      totalAmount: 252,
      status: "Cancelled",
      JourneyDate: "2023-03-14",
    },
    {
      lastName: "Davis",
      firstName: "Robert",
      age: 61,
      gender: "Male",
      RegNo: "REG57",
      country: "Canada",
      receiptNo: "REC409",
      address: "306 Elm St, City",
      oppCharges: 226,
      gstCharges: 23,
      OTTCharges: 16,
      totalAmount: 265,
      status: "Cancelled",
      JourneyDate: "2023-04-09",
    },
    {
      lastName: "Smith",
      firstName: "Michael",
      age: 21,
      gender: "Male",
      RegNo: "REG981",
      country: "Australia",
      receiptNo: "REC942",
      address: "707 Elm St, City",
      oppCharges: 102,
      gstCharges: 32,
      OTTCharges: 71,
      totalAmount: 205,
      status: "Cancelled",
      JourneyDate: "2024-05-05",
    },
    {
      lastName: "Brown",
      firstName: "Robert",
      age: 23,
      gender: "Female",
      RegNo: "REG186",
      country: "USA",
      receiptNo: "REC784",
      address: "357 Elm St, City",
      oppCharges: 57,
      gstCharges: 21,
      OTTCharges: 47,
      totalAmount: 125,
      status: "Pending",
      JourneyDate: "2023-04-21",
    },
    {
      lastName: "Brown",
      firstName: "James",
      age: 67,
      gender: "Female",
      RegNo: "REG232",
      country: "Canada",
      receiptNo: "REC55",
      address: "715 Elm St, City",
      oppCharges: 206,
      gstCharges: 11,
      OTTCharges: 51,
      totalAmount: 268,
      status: "Pending",
      JourneyDate: "2023-05-20",
    },
    {
      lastName: "Miller",
      firstName: "William",
      age: 63,
      gender: "Male",
      RegNo: "REG324",
      country: "Canada",
      receiptNo: "REC834",
      address: "807 Elm St, City",
      oppCharges: 132,
      gstCharges: 10,
      OTTCharges: 71,
      totalAmount: 213,
      status: "Paid",
      JourneyDate: "2023-08-11",
    },
    {
      lastName: "Johnson",
      firstName: "David",
      age: 21,
      gender: "Female",
      RegNo: "REG165",
      country: "India",
      receiptNo: "REC177",
      address: "178 Elm St, City",
      oppCharges: 239,
      gstCharges: 29,
      OTTCharges: 63,
      totalAmount: 331,
      status: "Cancelled",
      JourneyDate: "2023-01-13",
    },
    {
      lastName: "Smith",
      firstName: "William",
      age: 46,
      gender: "Female",
      RegNo: "REG484",
      country: "India",
      receiptNo: "REC85",
      address: "984 Elm St, City",
      oppCharges: 143,
      gstCharges: 13,
      OTTCharges: 41,
      totalAmount: 197,
      status: "Paid",
      JourneyDate: "2023-09-25",
    },
    {
      lastName: "Johnson",
      firstName: "Robert",
      age: 41,
      gender: "Female",
      RegNo: "REG236",
      country: "India",
      receiptNo: "REC21",
      address: "783 Elm St, City",
      oppCharges: 132,
      gstCharges: 35,
      OTTCharges: 20,
      totalAmount: 187,
      status: "Cancelled",
      JourneyDate: "2023-05-21",
    },
    {
      lastName: "Brown",
      firstName: "William",
      age: 28,
      gender: "Male",
      RegNo: "REG913",
      country: "Canada",
      receiptNo: "REC862",
      address: "389 Elm St, City",
      oppCharges: 150,
      gstCharges: 39,
      OTTCharges: 44,
      totalAmount: 233,
      status: "Pending",
      JourneyDate: "2024-02-09",
    },
    {
      lastName: "Smith",
      firstName: "John",
      age: 28,
      gender: "Female",
      RegNo: "REG107",
      country: "Australia",
      receiptNo: "REC245",
      address: "493 Elm St, City",
      oppCharges: 113,
      gstCharges: 16,
      OTTCharges: 6,
      totalAmount: 135,
      status: "Cancelled",
      JourneyDate: "2023-04-08",
    },
    {
      lastName: "Williams",
      firstName: "Robert",
      age: 64,
      gender: "Male",
      RegNo: "REG384",
      country: "USA",
      receiptNo: "REC382",
      address: "326 Elm St, City",
      oppCharges: 160,
      gstCharges: 15,
      OTTCharges: 59,
      totalAmount: 234,
      status: "Pending",
      JourneyDate: "2023-03-05",
    },
    {
      lastName: "Johnson",
      firstName: "Michael",
      age: 26,
      gender: "Male",
      RegNo: "REG162",
      country: "Canada",
      receiptNo: "REC713",
      address: "216 Elm St, City",
      oppCharges: 246,
      gstCharges: 21,
      OTTCharges: 75,
      totalAmount: 342,
      status: "Cancelled",
      JourneyDate: "2023-08-02",
    },
    {
      lastName: "Davis",
      firstName: "Michael",
      age: 56,
      gender: "Female",
      RegNo: "REG362",
      country: "UK",
      receiptNo: "REC637",
      address: "723 Elm St, City",
      oppCharges: 120,
      gstCharges: 26,
      OTTCharges: 92,
      totalAmount: 238,
      status: "Pending",
      JourneyDate: "2023-05-12",
    },
    {
      lastName: "Smith",
      firstName: "Robert",
      age: 62,
      gender: "Female",
      RegNo: "REG567",
      country: "India",
      receiptNo: "REC220",
      address: "387 Elm St, City",
      oppCharges: 236,
      gstCharges: 38,
      OTTCharges: 49,
      totalAmount: 323,
      status: "Pending",
      JourneyDate: "2023-09-08",
    },
    {
      lastName: "Davis",
      firstName: "John",
      age: 42,
      gender: "Female",
      RegNo: "REG543",
      country: "USA",
      receiptNo: "REC313",
      address: "505 Elm St, City",
      oppCharges: 63,
      gstCharges: 26,
      OTTCharges: 35,
      totalAmount: 124,
      status: "Paid",
      JourneyDate: "2023-03-26",
    },
    {
      lastName: "Brown",
      firstName: "James",
      age: 60,
      gender: "Female",
      RegNo: "REG417",
      country: "Australia",
      receiptNo: "REC680",
      address: "128 Elm St, City",
      oppCharges: 225,
      gstCharges: 11,
      OTTCharges: 39,
      totalAmount: 275,
      status: "Paid",
      JourneyDate: "2023-07-20",
    },
    {
      lastName: "Brown",
      firstName: "Michael",
      age: 37,
      gender: "Female",
      RegNo: "REG369",
      country: "USA",
      receiptNo: "REC653",
      address: "525 Elm St, City",
      oppCharges: 237,
      gstCharges: 34,
      OTTCharges: 1,
      totalAmount: 272,
      status: "Paid",
      JourneyDate: "2024-07-09",
    },
    {
      lastName: "Smith",
      firstName: "William",
      age: 34,
      gender: "Female",
      RegNo: "REG429",
      country: "Canada",
      receiptNo: "REC287",
      address: "80 Elm St, City",
      oppCharges: 200,
      gstCharges: 37,
      OTTCharges: 58,
      totalAmount: 295,
      status: "Paid",
      JourneyDate: "2023-04-23",
    },
    {
      lastName: "Miller",
      firstName: "David",
      age: 37,
      gender: "Male",
      RegNo: "REG48",
      country: "Australia",
      receiptNo: "REC255",
      address: "955 Elm St, City",
      oppCharges: 205,
      gstCharges: 16,
      OTTCharges: 53,
      totalAmount: 274,
      status: "Paid",
      JourneyDate: "2023-10-12",
    },
    {
      lastName: "Miller",
      firstName: "Robert",
      age: 28,
      gender: "Female",
      RegNo: "REG300",
      country: "Canada",
      receiptNo: "REC144",
      address: "714 Elm St, City",
      oppCharges: 187,
      gstCharges: 25,
      OTTCharges: 55,
      totalAmount: 267,
      status: "Cancelled",
      JourneyDate: "2023-02-20",
    },
    {
      lastName: "Williams",
      firstName: "Robert",
      age: 63,
      gender: "Female",
      RegNo: "REG418",
      country: "Canada",
      receiptNo: "REC675",
      address: "438 Elm St, City",
      oppCharges: 176,
      gstCharges: 11,
      OTTCharges: 23,
      totalAmount: 210,
      status: "Pending",
      JourneyDate: "2023-03-06",
    },
    {
      lastName: "Miller",
      firstName: "David",
      age: 28,
      gender: "Female",
      RegNo: "REG534",
      country: "India",
      receiptNo: "REC949",
      address: "957 Elm St, City",
      oppCharges: 243,
      gstCharges: 10,
      OTTCharges: 45,
      totalAmount: 298,
      status: "Paid",
      JourneyDate: "2023-11-24",
    },
    {
      lastName: "Williams",
      firstName: "James",
      age: 62,
      gender: "Male",
      RegNo: "REG669",
      country: "Canada",
      receiptNo: "REC675",
      address: "730 Elm St, City",
      oppCharges: 207,
      gstCharges: 17,
      OTTCharges: 43,
      totalAmount: 267,
      status: "Cancelled",
      JourneyDate: "2023-01-24",
    },
    {
      lastName: "Brown",
      firstName: "Richard",
      age: 42,
      gender: "Female",
      RegNo: "REG530",
      country: "India",
      receiptNo: "REC374",
      address: "956 Elm St, City",
      oppCharges: 203,
      gstCharges: 11,
      OTTCharges: 64,
      totalAmount: 278,
      status: "Paid",
      JourneyDate: "2023-02-16",
    },
    {
      lastName: "Jones",
      firstName: "Michael",
      age: 57,
      gender: "Female",
      RegNo: "REG632",
      country: "Canada",
      receiptNo: "REC515",
      address: "870 Elm St, City",
      oppCharges: 240,
      gstCharges: 39,
      OTTCharges: 25,
      totalAmount: 304,
      status: "Cancelled",
      JourneyDate: "2024-04-04",
    },
    {
      lastName: "Miller",
      firstName: "John",
      age: 38,
      gender: "Female",
      RegNo: "REG90",
      country: "India",
      receiptNo: "REC842",
      address: "138 Elm St, City",
      oppCharges: 161,
      gstCharges: 30,
      OTTCharges: 70,
      totalAmount: 261,
      status: "Pending",
      JourneyDate: "2023-08-29",
    },
    {
      lastName: "Johnson",
      firstName: "James",
      age: 29,
      gender: "Male",
      RegNo: "REG319",
      country: "Australia",
      receiptNo: "REC669",
      address: "422 Elm St, City",
      oppCharges: 159,
      gstCharges: 36,
      OTTCharges: 57,
      totalAmount: 252,
      status: "Paid",
      JourneyDate: "2024-03-23",
    },
    {
      lastName: "Smith",
      firstName: "Robert",
      age: 63,
      gender: "Male",
      RegNo: "REG187",
      country: "USA",
      receiptNo: "REC283",
      address: "374 Elm St, City",
      oppCharges: 92,
      gstCharges: 31,
      OTTCharges: 14,
      totalAmount: 137,
      status: "Paid",
      JourneyDate: "2023-02-14",
    },
    {
      lastName: "Miller",
      firstName: "Robert",
      age: 35,
      gender: "Male",
      RegNo: "REG893",
      country: "India",
      receiptNo: "REC5",
      address: "233 Elm St, City",
      oppCharges: 58,
      gstCharges: 14,
      OTTCharges: 23,
      totalAmount: 95,
      status: "Pending",
      JourneyDate: "2024-06-25",
    },
    {
      lastName: "Jones",
      firstName: "John",
      age: 31,
      gender: "Female",
      RegNo: "REG834",
      country: "India",
      receiptNo: "REC736",
      address: "616 Elm St, City",
      oppCharges: 141,
      gstCharges: 22,
      OTTCharges: 97,
      totalAmount: 260,
      status: "Pending",
      JourneyDate: "2023-06-19",
    },
    {
      lastName: "Smith",
      firstName: "David",
      age: 63,
      gender: "Female",
      RegNo: "REG883",
      country: "India",
      receiptNo: "REC233",
      address: "837 Elm St, City",
      oppCharges: 111,
      gstCharges: 23,
      OTTCharges: 72,
      totalAmount: 206,
      status: "Cancelled",
      JourneyDate: "2024-02-29",
    },
    {
      lastName: "Brown",
      firstName: "David",
      age: 25,
      gender: "Male",
      RegNo: "REG619",
      country: "India",
      receiptNo: "REC377",
      address: "550 Elm St, City",
      oppCharges: 71,
      gstCharges: 31,
      OTTCharges: 99,
      totalAmount: 201,
      status: "Cancelled",
      JourneyDate: "2023-12-15",
    },
    {
      lastName: "Williams",
      firstName: "Richard",
      age: 59,
      gender: "Female",
      RegNo: "REG932",
      country: "Canada",
      receiptNo: "REC604",
      address: "457 Elm St, City",
      oppCharges: 226,
      gstCharges: 35,
      OTTCharges: 54,
      totalAmount: 315,
      status: "Cancelled",
      JourneyDate: "2023-11-22",
    },
    {
      lastName: "Smith",
      firstName: "William",
      age: 41,
      gender: "Male",
      RegNo: "REG148",
      country: "India",
      receiptNo: "REC53",
      address: "460 Elm St, City",
      oppCharges: 125,
      gstCharges: 11,
      OTTCharges: 56,
      totalAmount: 192,
      status: "Paid",
      JourneyDate: "2023-06-28",
    },
    {
      lastName: "Jones",
      firstName: "William",
      age: 38,
      gender: "Male",
      RegNo: "REG641",
      country: "USA",
      receiptNo: "REC513",
      address: "717 Elm St, City",
      oppCharges: 59,
      gstCharges: 15,
      OTTCharges: 44,
      totalAmount: 118,
      status: "Cancelled",
      JourneyDate: "2023-01-28",
    },
    {
      lastName: "Brown",
      firstName: "David",
      age: 64,
      gender: "Female",
      RegNo: "REG31",
      country: "UK",
      receiptNo: "REC186",
      address: "370 Elm St, City",
      oppCharges: 193,
      gstCharges: 10,
      OTTCharges: 34,
      totalAmount: 237,
      status: "Pending",
      JourneyDate: "2024-05-08",
    },
    {
      lastName: "Brown",
      firstName: "William",
      age: 21,
      gender: "Female",
      RegNo: "REG635",
      country: "Australia",
      receiptNo: "REC133",
      address: "794 Elm St, City",
      oppCharges: 80,
      gstCharges: 20,
      OTTCharges: 38,
      totalAmount: 138,
      status: "Paid",
      JourneyDate: "2023-12-09",
    },
    {
      lastName: "Brown",
      firstName: "John",
      age: 31,
      gender: "Female",
      RegNo: "REG254",
      country: "India",
      receiptNo: "REC259",
      address: "569 Elm St, City",
      oppCharges: 204,
      gstCharges: 12,
      OTTCharges: 50,
      totalAmount: 266,
      status: "Pending",
      JourneyDate: "2023-06-01",
    },
    {
      lastName: "Johnson",
      firstName: "James",
      age: 58,
      gender: "Female",
      RegNo: "REG451",
      country: "Australia",
      receiptNo: "REC234",
      address: "290 Elm St, City",
      oppCharges: 50,
      gstCharges: 39,
      OTTCharges: 42,
      totalAmount: 131,
      status: "Cancelled",
      JourneyDate: "2023-10-08",
    },
    {
      lastName: "Williams",
      firstName: "David",
      age: 49,
      gender: "Female",
      RegNo: "REG489",
      country: "USA",
      receiptNo: "REC597",
      address: "5 Elm St, City",
      oppCharges: 87,
      gstCharges: 37,
      OTTCharges: 30,
      totalAmount: 154,
      status: "Pending",
      JourneyDate: "2023-07-21",
    },
    {
      lastName: "Johnson",
      firstName: "David",
      age: 66,
      gender: "Male",
      RegNo: "REG807",
      country: "UK",
      receiptNo: "REC508",
      address: "637 Elm St, City",
      oppCharges: 173,
      gstCharges: 12,
      OTTCharges: 77,
      totalAmount: 262,
      status: "Pending",
      JourneyDate: "2023-06-23",
    },
    {
      lastName: "Williams",
      firstName: "David",
      age: 53,
      gender: "Male",
      RegNo: "REG285",
      country: "Canada",
      receiptNo: "REC363",
      address: "904 Elm St, City",
      oppCharges: 195,
      gstCharges: 33,
      OTTCharges: 99,
      totalAmount: 327,
      status: "Cancelled",
      JourneyDate: "2023-05-16",
    },
    {
      lastName: "Smith",
      firstName: "John",
      age: 58,
      gender: "Male",
      RegNo: "REG391",
      country: "Australia",
      receiptNo: "REC49",
      address: "123 Elm St, City",
      oppCharges: 183,
      gstCharges: 17,
      OTTCharges: 59,
      totalAmount: 259,
      status: "Cancelled",
      JourneyDate: "2024-04-15",
    },
    {
      lastName: "Williams",
      firstName: "David",
      age: 20,
      gender: "Female",
      RegNo: "REG494",
      country: "India",
      receiptNo: "REC186",
      address: "958 Elm St, City",
      oppCharges: 120,
      gstCharges: 13,
      OTTCharges: 73,
      totalAmount: 206,
      status: "Pending",
      JourneyDate: "2024-02-12",
    },
    {
      lastName: "Williams",
      firstName: "Michael",
      age: 20,
      gender: "Female",
      RegNo: "REG818",
      country: "Australia",
      receiptNo: "REC52",
      address: "507 Elm St, City",
      oppCharges: 113,
      gstCharges: 24,
      OTTCharges: 25,
      totalAmount: 162,
      status: "Cancelled",
      JourneyDate: "2023-05-27",
    },
    {
      lastName: "Davis",
      firstName: "Richard",
      age: 42,
      gender: "Male",
      RegNo: "REG581",
      country: "India",
      receiptNo: "REC394",
      address: "903 Elm St, City",
      oppCharges: 243,
      gstCharges: 26,
      OTTCharges: 90,
      totalAmount: 359,
      status: "Paid",
      JourneyDate: "2023-05-24",
    },
    {
      lastName: "Smith",
      firstName: "William",
      age: 25,
      gender: "Female",
      RegNo: "REG182",
      country: "Australia",
      receiptNo: "REC69",
      address: "766 Elm St, City",
      oppCharges: 93,
      gstCharges: 15,
      OTTCharges: 28,
      totalAmount: 136,
      status: "Cancelled",
      JourneyDate: "2023-08-17",
    },
    {
      lastName: "Jones",
      firstName: "David",
      age: 65,
      gender: "Female",
      RegNo: "REG705",
      country: "USA",
      receiptNo: "REC260",
      address: "557 Elm St, City",
      oppCharges: 160,
      gstCharges: 24,
      OTTCharges: 91,
      totalAmount: 275,
      status: "Paid",
      JourneyDate: "2023-09-29",
    },
    {
      lastName: "Davis",
      firstName: "Robert",
      age: 45,
      gender: "Male",
      RegNo: "REG849",
      country: "USA",
      receiptNo: "REC956",
      address: "942 Elm St, City",
      oppCharges: 99,
      gstCharges: 28,
      OTTCharges: 92,
      totalAmount: 219,
      status: "Cancelled",
      JourneyDate: "2024-05-23",
    },
    {
      lastName: "Johnson",
      firstName: "Michael",
      age: 61,
      gender: "Male",
      RegNo: "REG826",
      country: "USA",
      receiptNo: "REC888",
      address: "193 Elm St, City",
      oppCharges: 247,
      gstCharges: 38,
      OTTCharges: 22,
      totalAmount: 307,
      status: "Pending",
      JourneyDate: "2023-07-30",
    },
    {
      lastName: "Johnson",
      firstName: "James",
      age: 31,
      gender: "Female",
      RegNo: "REG63",
      country: "India",
      receiptNo: "REC574",
      address: "150 Elm St, City",
      oppCharges: 229,
      gstCharges: 35,
      OTTCharges: 2,
      totalAmount: 266,
      status: "Pending",
      JourneyDate: "2023-05-04",
    },
    {
      lastName: "Davis",
      firstName: "Michael",
      age: 56,
      gender: "Male",
      RegNo: "REG378",
      country: "Canada",
      receiptNo: "REC337",
      address: "666 Elm St, City",
      oppCharges: 230,
      gstCharges: 10,
      OTTCharges: 31,
      totalAmount: 271,
      status: "Paid",
      JourneyDate: "2024-01-22",
    },
    {
      lastName: "Johnson",
      firstName: "James",
      age: 52,
      gender: "Female",
      RegNo: "REG837",
      country: "UK",
      receiptNo: "REC97",
      address: "949 Elm St, City",
      oppCharges: 247,
      gstCharges: 23,
      OTTCharges: 90,
      totalAmount: 360,
      status: "Cancelled",
      JourneyDate: "2023-11-05",
    },
    {
      lastName: "Davis",
      firstName: "Robert",
      age: 21,
      gender: "Male",
      RegNo: "REG447",
      country: "USA",
      receiptNo: "REC503",
      address: "856 Elm St, City",
      oppCharges: 55,
      gstCharges: 11,
      OTTCharges: 36,
      totalAmount: 102,
      status: "Pending",
      JourneyDate: "2024-02-01",
    },
    {
      lastName: "Smith",
      firstName: "John",
      age: 44,
      gender: "Male",
      RegNo: "REG977",
      country: "USA",
      receiptNo: "REC935",
      address: "683 Elm St, City",
      oppCharges: 118,
      gstCharges: 20,
      OTTCharges: 35,
      totalAmount: 173,
      status: "Pending",
      JourneyDate: "2023-09-06",
    },
    {
      lastName: "Brown",
      firstName: "Robert",
      age: 65,
      gender: "Female",
      RegNo: "REG365",
      country: "Australia",
      receiptNo: "REC154",
      address: "558 Elm St, City",
      oppCharges: 82,
      gstCharges: 19,
      OTTCharges: 98,
      totalAmount: 199,
      status: "Cancelled",
      JourneyDate: "2023-01-19",
    },
    {
      lastName: "Miller",
      firstName: "James",
      age: 53,
      gender: "Female",
      RegNo: "REG899",
      country: "UK",
      receiptNo: "REC998",
      address: "392 Elm St, City",
      oppCharges: 226,
      gstCharges: 14,
      OTTCharges: 18,
      totalAmount: 258,
      status: "Cancelled",
      JourneyDate: "2024-06-10",
    },
    {
      lastName: "Davis",
      firstName: "Richard",
      age: 52,
      gender: "Female",
      RegNo: "REG379",
      country: "India",
      receiptNo: "REC338",
      address: "961 Elm St, City",
      oppCharges: 236,
      gstCharges: 28,
      OTTCharges: 47,
      totalAmount: 311,
      status: "Paid",
      JourneyDate: "2024-05-28",
    },
    {
      lastName: "Davis",
      firstName: "David",
      age: 27,
      gender: "Male",
      RegNo: "REG619",
      country: "Canada",
      receiptNo: "REC841",
      address: "605 Elm St, City",
      oppCharges: 136,
      gstCharges: 34,
      OTTCharges: 3,
      totalAmount: 173,
      status: "Paid",
      JourneyDate: "2024-06-28",
    },
    {
      lastName: "Davis",
      firstName: "William",
      age: 47,
      gender: "Male",
      RegNo: "REG258",
      country: "Canada",
      receiptNo: "REC162",
      address: "752 Elm St, City",
      oppCharges: 127,
      gstCharges: 10,
      OTTCharges: 36,
      totalAmount: 173,
      status: "Cancelled",
      JourneyDate: "2024-01-13",
    },
    {
      lastName: "Johnson",
      firstName: "David",
      age: 37,
      gender: "Male",
      RegNo: "REG913",
      country: "UK",
      receiptNo: "REC784",
      address: "338 Elm St, City",
      oppCharges: 163,
      gstCharges: 30,
      OTTCharges: 92,
      totalAmount: 285,
      status: "Paid",
      JourneyDate: "2024-01-06",
    },
    {
      lastName: "Davis",
      firstName: "Michael",
      age: 28,
      gender: "Female",
      RegNo: "REG78",
      country: "India",
      receiptNo: "REC655",
      address: "834 Elm St, City",
      oppCharges: 150,
      gstCharges: 14,
      OTTCharges: 43,
      totalAmount: 207,
      status: "Pending",
      JourneyDate: "2024-01-18",
    },
    {
      lastName: "Smith",
      firstName: "Robert",
      age: 42,
      gender: "Male",
      RegNo: "REG1",
      country: "Australia",
      receiptNo: "REC827",
      address: "40 Elm St, City",
      oppCharges: 167,
      gstCharges: 37,
      OTTCharges: 65,
      totalAmount: 269,
      status: "Cancelled",
      JourneyDate: "2023-07-08",
    },
    {
      lastName: "Johnson",
      firstName: "Richard",
      age: 35,
      gender: "Male",
      RegNo: "REG768",
      country: "India",
      receiptNo: "REC998",
      address: "809 Elm St, City",
      oppCharges: 190,
      gstCharges: 23,
      OTTCharges: 90,
      totalAmount: 303,
      status: "Cancelled",
      JourneyDate: "2024-02-04",
    },
    {
      lastName: "Davis",
      firstName: "John",
      age: 39,
      gender: "Female",
      RegNo: "REG779",
      country: "Canada",
      receiptNo: "REC260",
      address: "890 Elm St, City",
      oppCharges: 227,
      gstCharges: 30,
      OTTCharges: 84,
      totalAmount: 341,
      status: "Cancelled",
      JourneyDate: "2023-05-21",
    },
    {
      lastName: "Williams",
      firstName: "Michael",
      age: 64,
      gender: "Female",
      RegNo: "REG140",
      country: "USA",
      receiptNo: "REC27",
      address: "80 Elm St, City",
      oppCharges: 78,
      gstCharges: 18,
      OTTCharges: 31,
      totalAmount: 127,
      status: "Pending",
      JourneyDate: "2023-11-30",
    },
    {
      lastName: "Williams",
      firstName: "John",
      age: 56,
      gender: "Female",
      RegNo: "REG521",
      country: "UK",
      receiptNo: "REC763",
      address: "63 Elm St, City",
      oppCharges: 220,
      gstCharges: 12,
      OTTCharges: 56,
      totalAmount: 288,
      status: "Cancelled",
      JourneyDate: "2023-09-05",
    },
    {
      lastName: "Brown",
      firstName: "Michael",
      age: 45,
      gender: "Female",
      RegNo: "REG973",
      country: "Canada",
      receiptNo: "REC260",
      address: "829 Elm St, City",
      oppCharges: 177,
      gstCharges: 16,
      OTTCharges: 85,
      totalAmount: 278,
      status: "Pending",
      JourneyDate: "2024-05-14",
    },
    {
      lastName: "Johnson",
      firstName: "James",
      age: 44,
      gender: "Male",
      RegNo: "REG335",
      country: "USA",
      receiptNo: "REC307",
      address: "497 Elm St, City",
      oppCharges: 137,
      gstCharges: 18,
      OTTCharges: 52,
      totalAmount: 207,
      status: "Paid",
      JourneyDate: "2024-05-25",
    },
    {
      lastName: "Jones",
      firstName: "Robert",
      age: 45,
      gender: "Male",
      RegNo: "REG117",
      country: "USA",
      receiptNo: "REC273",
      address: "337 Elm St, City",
      oppCharges: 150,
      gstCharges: 16,
      OTTCharges: 56,
      totalAmount: 222,
      status: "Paid",
      JourneyDate: "2023-12-25",
    },
    {
      lastName: "Davis",
      firstName: "James",
      age: 61,
      gender: "Male",
      RegNo: "REG815",
      country: "India",
      receiptNo: "REC357",
      address: "64 Elm St, City",
      oppCharges: 99,
      gstCharges: 32,
      OTTCharges: 93,
      totalAmount: 224,
      status: "Paid",
      JourneyDate: "2023-02-04",
    },
    {
      lastName: "Miller",
      firstName: "Robert",
      age: 64,
      gender: "Female",
      RegNo: "REG277",
      country: "USA",
      receiptNo: "REC289",
      address: "455 Elm St, City",
      oppCharges: 243,
      gstCharges: 12,
      OTTCharges: 36,
      totalAmount: 291,
      status: "Cancelled",
      JourneyDate: "2024-02-12",
    },
    {
      lastName: "Miller",
      firstName: "David",
      age: 68,
      gender: "Female",
      RegNo: "REG683",
      country: "USA",
      receiptNo: "REC478",
      address: "860 Elm St, City",
      oppCharges: 126,
      gstCharges: 22,
      OTTCharges: 43,
      totalAmount: 191,
      status: "Cancelled",
      JourneyDate: "2024-02-17",
    },
    {
      lastName: "Williams",
      firstName: "Robert",
      age: 62,
      gender: "Male",
      RegNo: "REG777",
      country: "USA",
      receiptNo: "REC556",
      address: "728 Elm St, City",
      oppCharges: 103,
      gstCharges: 33,
      OTTCharges: 1,
      totalAmount: 137,
      status: "Paid",
      JourneyDate: "2023-04-11",
    },
    {
      lastName: "Miller",
      firstName: "Richard",
      age: 32,
      gender: "Female",
      RegNo: "REG604",
      country: "India",
      receiptNo: "REC30",
      address: "73 Elm St, City",
      oppCharges: 189,
      gstCharges: 25,
      OTTCharges: 56,
      totalAmount: 270,
      status: "Paid",
      JourneyDate: "2023-04-13",
    },
    {
      lastName: "Smith",
      firstName: "Michael",
      age: 53,
      gender: "Female",
      RegNo: "REG333",
      country: "USA",
      receiptNo: "REC980",
      address: "436 Elm St, City",
      oppCharges: 148,
      gstCharges: 17,
      OTTCharges: 7,
      totalAmount: 172,
      status: "Pending",
      JourneyDate: "2024-05-20",
    },
    {
      lastName: "Brown",
      firstName: "William",
      age: 20,
      gender: "Female",
      RegNo: "REG965",
      country: "Australia",
      receiptNo: "REC489",
      address: "347 Elm St, City",
      oppCharges: 97,
      gstCharges: 18,
      OTTCharges: 82,
      totalAmount: 197,
      status: "Cancelled",
      JourneyDate: "2023-08-06",
    },
    {
      lastName: "Davis",
      firstName: "Michael",
      age: 58,
      gender: "Male",
      RegNo: "REG572",
      country: "India",
      receiptNo: "REC327",
      address: "478 Elm St, City",
      oppCharges: 237,
      gstCharges: 27,
      OTTCharges: 20,
      totalAmount: 284,
      status: "Pending",
      JourneyDate: "2023-02-22",
    },
    {
      lastName: "Johnson",
      firstName: "James",
      age: 60,
      gender: "Female",
      RegNo: "REG933",
      country: "USA",
      receiptNo: "REC123",
      address: "802 Elm St, City",
      oppCharges: 93,
      gstCharges: 20,
      OTTCharges: 86,
      totalAmount: 199,
      status: "Cancelled",
      JourneyDate: "2023-02-25",
    },
    {
      lastName: "Williams",
      firstName: "David",
      age: 31,
      gender: "Male",
      RegNo: "REG444",
      country: "India",
      receiptNo: "REC631",
      address: "56 Elm St, City",
      oppCharges: 221,
      gstCharges: 26,
      OTTCharges: 29,
      totalAmount: 276,
      status: "Paid",
      JourneyDate: "2024-04-15",
    },
    {
      lastName: "Davis",
      firstName: "David",
      age: 50,
      gender: "Female",
      RegNo: "REG388",
      country: "UK",
      receiptNo: "REC543",
      address: "482 Elm St, City",
      oppCharges: 186,
      gstCharges: 27,
      OTTCharges: 69,
      totalAmount: 282,
      status: "Cancelled",
      JourneyDate: "2024-03-03",
    },
    {
      lastName: "Brown",
      firstName: "John",
      age: 46,
      gender: "Female",
      RegNo: "REG125",
      country: "USA",
      receiptNo: "REC740",
      address: "795 Elm St, City",
      oppCharges: 94,
      gstCharges: 20,
      OTTCharges: 2,
      totalAmount: 116,
      status: "Pending",
      JourneyDate: "2023-03-24",
    },
    {
      lastName: "Williams",
      firstName: "John",
      age: 45,
      gender: "Male",
      RegNo: "REG147",
      country: "Australia",
      receiptNo: "REC258",
      address: "718 Elm St, City",
      oppCharges: 154,
      gstCharges: 38,
      OTTCharges: 52,
      totalAmount: 244,
      status: "Paid",
      JourneyDate: "2023-10-21",
    },
    {
      lastName: "Jones",
      firstName: "Richard",
      age: 20,
      gender: "Female",
      RegNo: "REG621",
      country: "UK",
      receiptNo: "REC937",
      address: "973 Elm St, City",
      oppCharges: 213,
      gstCharges: 25,
      OTTCharges: 15,
      totalAmount: 253,
      status: "Cancelled",
      JourneyDate: "2024-06-10",
    },
    {
      lastName: "Jones",
      firstName: "John",
      age: 63,
      gender: "Male",
      RegNo: "REG403",
      country: "Canada",
      receiptNo: "REC768",
      address: "871 Elm St, City",
      oppCharges: 120,
      gstCharges: 23,
      OTTCharges: 78,
      totalAmount: 221,
      status: "Paid",
      JourneyDate: "2023-09-28",
    },
    {
      lastName: "Jones",
      firstName: "Michael",
      age: 69,
      gender: "Female",
      RegNo: "REG491",
      country: "Australia",
      receiptNo: "REC588",
      address: "301 Elm St, City",
      oppCharges: 121,
      gstCharges: 21,
      OTTCharges: 12,
      totalAmount: 154,
      status: "Cancelled",
      JourneyDate: "2024-04-10",
    },
    {
      lastName: "Williams",
      firstName: "James",
      age: 51,
      gender: "Female",
      RegNo: "REG351",
      country: "Australia",
      receiptNo: "REC894",
      address: "543 Elm St, City",
      oppCharges: 238,
      gstCharges: 33,
      OTTCharges: 62,
      totalAmount: 333,
      status: "Paid",
      JourneyDate: "2023-03-01",
    },
    {
      lastName: "Brown",
      firstName: "John",
      age: 55,
      gender: "Female",
      RegNo: "REG305",
      country: "Canada",
      receiptNo: "REC230",
      address: "93 Elm St, City",
      oppCharges: 236,
      gstCharges: 29,
      OTTCharges: 27,
      totalAmount: 292,
      status: "Paid",
      JourneyDate: "2023-07-10",
    },
    {
      lastName: "Davis",
      firstName: "David",
      age: 68,
      gender: "Female",
      RegNo: "REG969",
      country: "UK",
      receiptNo: "REC907",
      address: "124 Elm St, City",
      oppCharges: 168,
      gstCharges: 24,
      OTTCharges: 40,
      totalAmount: 232,
      status: "Paid",
      JourneyDate: "2023-01-30",
    },
    {
      lastName: "Smith",
      firstName: "Robert",
      age: 20,
      gender: "Male",
      RegNo: "REG741",
      country: "Canada",
      receiptNo: "REC28",
      address: "868 Elm St, City",
      oppCharges: 133,
      gstCharges: 32,
      OTTCharges: 28,
      totalAmount: 193,
      status: "Cancelled",
      JourneyDate: "2023-10-10",
    },
    {
      lastName: "Jones",
      firstName: "Michael",
      age: 26,
      gender: "Female",
      RegNo: "REG767",
      country: "Australia",
      receiptNo: "REC185",
      address: "525 Elm St, City",
      oppCharges: 130,
      gstCharges: 11,
      OTTCharges: 78,
      totalAmount: 219,
      status: "Cancelled",
      JourneyDate: "2023-07-15",
    },
    {
      lastName: "Miller",
      firstName: "David",
      age: 38,
      gender: "Male",
      RegNo: "REG234",
      country: "USA",
      receiptNo: "REC45",
      address: "964 Elm St, City",
      oppCharges: 89,
      gstCharges: 31,
      OTTCharges: 40,
      totalAmount: 160,
      status: "Cancelled",
      JourneyDate: "2023-10-19",
    },
    {
      lastName: "Brown",
      firstName: "Michael",
      age: 67,
      gender: "Female",
      RegNo: "REG298",
      country: "USA",
      receiptNo: "REC815",
      address: "347 Elm St, City",
      oppCharges: 223,
      gstCharges: 21,
      OTTCharges: 27,
      totalAmount: 271,
      status: "Cancelled",
      JourneyDate: "2023-10-20",
    },
    {
      lastName: "Davis",
      firstName: "Robert",
      age: 34,
      gender: "Female",
      RegNo: "REG709",
      country: "UK",
      receiptNo: "REC595",
      address: "266 Elm St, City",
      oppCharges: 218,
      gstCharges: 39,
      OTTCharges: 59,
      totalAmount: 316,
      status: "Cancelled",
      JourneyDate: "2023-03-14",
    },
    {
      lastName: "Williams",
      firstName: "David",
      age: 65,
      gender: "Female",
      RegNo: "REG242",
      country: "Australia",
      receiptNo: "REC352",
      address: "187 Elm St, City",
      oppCharges: 65,
      gstCharges: 38,
      OTTCharges: 40,
      totalAmount: 143,
      status: "Paid",
      JourneyDate: "2023-12-02",
    },
    {
      lastName: "Davis",
      firstName: "James",
      age: 61,
      gender: "Male",
      RegNo: "REG458",
      country: "Canada",
      receiptNo: "REC961",
      address: "89 Elm St, City",
      oppCharges: 102,
      gstCharges: 12,
      OTTCharges: 91,
      totalAmount: 205,
      status: "Cancelled",
      JourneyDate: "2023-01-23",
    },
    {
      lastName: "Jones",
      firstName: "Michael",
      age: 53,
      gender: "Male",
      RegNo: "REG398",
      country: "India",
      receiptNo: "REC415",
      address: "642 Elm St, City",
      oppCharges: 207,
      gstCharges: 18,
      OTTCharges: 37,
      totalAmount: 262,
      status: "Paid",
      JourneyDate: "2023-03-17",
    },
    {
      lastName: "Miller",
      firstName: "Richard",
      age: 29,
      gender: "Male",
      RegNo: "REG938",
      country: "Canada",
      receiptNo: "REC989",
      address: "690 Elm St, City",
      oppCharges: 195,
      gstCharges: 28,
      OTTCharges: 3,
      totalAmount: 226,
      status: "Cancelled",
      JourneyDate: "2024-06-29",
    },
    {
      lastName: "Miller",
      firstName: "James",
      age: 46,
      gender: "Female",
      RegNo: "REG375",
      country: "India",
      receiptNo: "REC901",
      address: "19 Elm St, City",
      oppCharges: 101,
      gstCharges: 24,
      OTTCharges: 66,
      totalAmount: 191,
      status: "Pending",
      JourneyDate: "2024-04-12",
    },
    {
      lastName: "Davis",
      firstName: "James",
      age: 23,
      gender: "Female",
      RegNo: "REG832",
      country: "UK",
      receiptNo: "REC849",
      address: "67 Elm St, City",
      oppCharges: 151,
      gstCharges: 39,
      OTTCharges: 81,
      totalAmount: 271,
      status: "Pending",
      JourneyDate: "2023-01-01",
    },
    {
      lastName: "Miller",
      firstName: "Richard",
      age: 65,
      gender: "Female",
      RegNo: "REG118",
      country: "Australia",
      receiptNo: "REC679",
      address: "170 Elm St, City",
      oppCharges: 138,
      gstCharges: 14,
      OTTCharges: 3,
      totalAmount: 155,
      status: "Cancelled",
      JourneyDate: "2023-04-01",
    },
    {
      lastName: "Brown",
      firstName: "David",
      age: 52,
      gender: "Male",
      RegNo: "REG788",
      country: "USA",
      receiptNo: "REC582",
      address: "430 Elm St, City",
      oppCharges: 218,
      gstCharges: 14,
      OTTCharges: 8,
      totalAmount: 240,
      status: "Pending",
      JourneyDate: "2023-12-24",
    },
    {
      lastName: "Brown",
      firstName: "Robert",
      age: 55,
      gender: "Male",
      RegNo: "REG85",
      country: "Canada",
      receiptNo: "REC473",
      address: "117 Elm St, City",
      oppCharges: 165,
      gstCharges: 11,
      OTTCharges: 57,
      totalAmount: 233,
      status: "Cancelled",
      JourneyDate: "2023-03-12",
    },
    {
      lastName: "Brown",
      firstName: "Richard",
      age: 67,
      gender: "Female",
      RegNo: "REG712",
      country: "India",
      receiptNo: "REC783",
      address: "758 Elm St, City",
      oppCharges: 235,
      gstCharges: 19,
      OTTCharges: 11,
      totalAmount: 265,
      status: "Paid",
      JourneyDate: "2023-11-08",
    },
    {
      lastName: "Jones",
      firstName: "David",
      age: 33,
      gender: "Female",
      RegNo: "REG401",
      country: "UK",
      receiptNo: "REC779",
      address: "121 Elm St, City",
      oppCharges: 128,
      gstCharges: 36,
      OTTCharges: 77,
      totalAmount: 241,
      status: "Paid",
      JourneyDate: "2023-07-16",
    },
    {
      lastName: "Brown",
      firstName: "Robert",
      age: 36,
      gender: "Female",
      RegNo: "REG89",
      country: "USA",
      receiptNo: "REC251",
      address: "308 Elm St, City",
      oppCharges: 70,
      gstCharges: 21,
      OTTCharges: 62,
      totalAmount: 153,
      status: "Pending",
      JourneyDate: "2024-03-04",
    },
    {
      lastName: "Williams",
      firstName: "Richard",
      age: 41,
      gender: "Male",
      RegNo: "REG204",
      country: "India",
      receiptNo: "REC811",
      address: "946 Elm St, City",
      oppCharges: 136,
      gstCharges: 12,
      OTTCharges: 31,
      totalAmount: 179,
      status: "Cancelled",
      JourneyDate: "2023-11-01",
    },
    {
      lastName: "Brown",
      firstName: "Robert",
      age: 66,
      gender: "Female",
      RegNo: "REG533",
      country: "UK",
      receiptNo: "REC626",
      address: "394 Elm St, City",
      oppCharges: 80,
      gstCharges: 11,
      OTTCharges: 3,
      totalAmount: 94,
      status: "Pending",
      JourneyDate: "2024-04-24",
    },
    {
      lastName: "Johnson",
      firstName: "John",
      age: 35,
      gender: "Female",
      RegNo: "REG203",
      country: "Canada",
      receiptNo: "REC325",
      address: "321 Elm St, City",
      oppCharges: 51,
      gstCharges: 37,
      OTTCharges: 91,
      totalAmount: 179,
      status: "Paid",
      JourneyDate: "2024-03-19",
    },
    {
      lastName: "Williams",
      firstName: "John",
      age: 43,
      gender: "Male",
      RegNo: "REG929",
      country: "India",
      receiptNo: "REC592",
      address: "520 Elm St, City",
      oppCharges: 203,
      gstCharges: 24,
      OTTCharges: 81,
      totalAmount: 308,
      status: "Paid",
      JourneyDate: "2024-03-28",
    },
    {
      lastName: "Miller",
      firstName: "Richard",
      age: 51,
      gender: "Male",
      RegNo: "REG331",
      country: "UK",
      receiptNo: "REC237",
      address: "299 Elm St, City",
      oppCharges: 209,
      gstCharges: 38,
      OTTCharges: 48,
      totalAmount: 295,
      status: "Paid",
      JourneyDate: "2023-08-13",
    },
    {
      lastName: "Davis",
      firstName: "David",
      age: 47,
      gender: "Male",
      RegNo: "REG750",
      country: "Canada",
      receiptNo: "REC316",
      address: "482 Elm St, City",
      oppCharges: 90,
      gstCharges: 16,
      OTTCharges: 88,
      totalAmount: 194,
      status: "Cancelled",
      JourneyDate: "2023-02-19",
    },
    {
      lastName: "Brown",
      firstName: "Richard",
      age: 34,
      gender: "Male",
      RegNo: "REG800",
      country: "UK",
      receiptNo: "REC634",
      address: "3 Elm St, City",
      oppCharges: 122,
      gstCharges: 15,
      OTTCharges: 55,
      totalAmount: 192,
      status: "Cancelled",
      JourneyDate: "2023-06-10",
    },
    {
      lastName: "Davis",
      firstName: "Richard",
      age: 44,
      gender: "Male",
      RegNo: "REG8",
      country: "Canada",
      receiptNo: "REC514",
      address: "424 Elm St, City",
      oppCharges: 209,
      gstCharges: 22,
      OTTCharges: 5,
      totalAmount: 236,
      status: "Pending",
      JourneyDate: "2024-01-23",
    },
    {
      lastName: "Davis",
      firstName: "William",
      age: 26,
      gender: "Male",
      RegNo: "REG293",
      country: "USA",
      receiptNo: "REC860",
      address: "664 Elm St, City",
      oppCharges: 241,
      gstCharges: 16,
      OTTCharges: 16,
      totalAmount: 273,
      status: "Paid",
      JourneyDate: "2024-07-12",
    },
    {
      lastName: "Smith",
      firstName: "William",
      age: 25,
      gender: "Female",
      RegNo: "REG523",
      country: "UK",
      receiptNo: "REC136",
      address: "535 Elm St, City",
      oppCharges: 86,
      gstCharges: 30,
      OTTCharges: 86,
      totalAmount: 202,
      status: "Pending",
      JourneyDate: "2023-11-05",
    },
    {
      lastName: "Smith",
      firstName: "John",
      age: 27,
      gender: "Female",
      RegNo: "REG415",
      country: "UK",
      receiptNo: "REC708",
      address: "85 Elm St, City",
      oppCharges: 190,
      gstCharges: 13,
      OTTCharges: 95,
      totalAmount: 298,
      status: "Pending",
      JourneyDate: "2023-08-07",
    },
    {
      lastName: "Williams",
      firstName: "Robert",
      age: 35,
      gender: "Female",
      RegNo: "REG648",
      country: "Australia",
      receiptNo: "REC925",
      address: "346 Elm St, City",
      oppCharges: 247,
      gstCharges: 27,
      OTTCharges: 96,
      totalAmount: 370,
      status: "Cancelled",
      JourneyDate: "2024-05-06",
    },
    {
      lastName: "Davis",
      firstName: "William",
      age: 21,
      gender: "Male",
      RegNo: "REG301",
      country: "Canada",
      receiptNo: "REC835",
      address: "409 Elm St, City",
      oppCharges: 185,
      gstCharges: 27,
      OTTCharges: 4,
      totalAmount: 216,
      status: "Pending",
      JourneyDate: "2023-02-02",
    },
    {
      lastName: "Williams",
      firstName: "William",
      age: 38,
      gender: "Female",
      RegNo: "REG793",
      country: "Australia",
      receiptNo: "REC373",
      address: "908 Elm St, City",
      oppCharges: 88,
      gstCharges: 39,
      OTTCharges: 57,
      totalAmount: 184,
      status: "Pending",
      JourneyDate: "2023-06-11",
    },
    {
      lastName: "Jones",
      firstName: "James",
      age: 34,
      gender: "Male",
      RegNo: "REG266",
      country: "Canada",
      receiptNo: "REC918",
      address: "723 Elm St, City",
      oppCharges: 100,
      gstCharges: 23,
      OTTCharges: 88,
      totalAmount: 211,
      status: "Pending",
      JourneyDate: "2023-03-27",
    },
    {
      lastName: "Smith",
      firstName: "William",
      age: 39,
      gender: "Male",
      RegNo: "REG573",
      country: "USA",
      receiptNo: "REC819",
      address: "553 Elm St, City",
      oppCharges: 242,
      gstCharges: 16,
      OTTCharges: 35,
      totalAmount: 293,
      status: "Pending",
      JourneyDate: "2024-03-23",
    },
    {
      lastName: "Williams",
      firstName: "David",
      age: 56,
      gender: "Female",
      RegNo: "REG518",
      country: "USA",
      receiptNo: "REC130",
      address: "199 Elm St, City",
      oppCharges: 56,
      gstCharges: 33,
      OTTCharges: 94,
      totalAmount: 183,
      status: "Paid",
      JourneyDate: "2023-11-11",
    },
    {
      lastName: "Smith",
      firstName: "James",
      age: 64,
      gender: "Female",
      RegNo: "REG819",
      country: "India",
      receiptNo: "REC207",
      address: "869 Elm St, City",
      oppCharges: 145,
      gstCharges: 13,
      OTTCharges: 19,
      totalAmount: 177,
      status: "Paid",
      JourneyDate: "2023-04-17",
    },
    {
      lastName: "Davis",
      firstName: "James",
      age: 42,
      gender: "Female",
      RegNo: "REG560",
      country: "Australia",
      receiptNo: "REC950",
      address: "971 Elm St, City",
      oppCharges: 186,
      gstCharges: 25,
      OTTCharges: 22,
      totalAmount: 233,
      status: "Paid",
      JourneyDate: "2023-04-10",
    },
    {
      lastName: "Johnson",
      firstName: "James",
      age: 53,
      gender: "Female",
      RegNo: "REG509",
      country: "Canada",
      receiptNo: "REC58",
      address: "771 Elm St, City",
      oppCharges: 165,
      gstCharges: 33,
      OTTCharges: 83,
      totalAmount: 281,
      status: "Cancelled",
      JourneyDate: "2024-05-13",
    },
    {
      lastName: "Davis",
      firstName: "William",
      age: 65,
      gender: "Male",
      RegNo: "REG713",
      country: "Canada",
      receiptNo: "REC857",
      address: "245 Elm St, City",
      oppCharges: 178,
      gstCharges: 14,
      OTTCharges: 48,
      totalAmount: 240,
      status: "Cancelled",
      JourneyDate: "2024-07-05",
    },
    {
      lastName: "Davis",
      firstName: "William",
      age: 40,
      gender: "Female",
      RegNo: "REG863",
      country: "India",
      receiptNo: "REC385",
      address: "582 Elm St, City",
      oppCharges: 196,
      gstCharges: 27,
      OTTCharges: 85,
      totalAmount: 308,
      status: "Pending",
      JourneyDate: "2024-01-02",
    },
    {
      lastName: "Jones",
      firstName: "Michael",
      age: 24,
      gender: "Female",
      RegNo: "REG337",
      country: "India",
      receiptNo: "REC132",
      address: "463 Elm St, City",
      oppCharges: 96,
      gstCharges: 36,
      OTTCharges: 56,
      totalAmount: 188,
      status: "Pending",
      JourneyDate: "2023-02-06",
    },
    {
      lastName: "Miller",
      firstName: "David",
      age: 62,
      gender: "Male",
      RegNo: "REG132",
      country: "Australia",
      receiptNo: "REC743",
      address: "307 Elm St, City",
      oppCharges: 233,
      gstCharges: 15,
      OTTCharges: 87,
      totalAmount: 335,
      status: "Cancelled",
      JourneyDate: "2024-06-02",
    },
    {
      lastName: "Johnson",
      firstName: "William",
      age: 63,
      gender: "Male",
      RegNo: "REG84",
      country: "UK",
      receiptNo: "REC601",
      address: "920 Elm St, City",
      oppCharges: 115,
      gstCharges: 20,
      OTTCharges: 38,
      totalAmount: 173,
      status: "Cancelled",
      JourneyDate: "2024-05-13",
    },
    {
      lastName: "Brown",
      firstName: "Richard",
      age: 61,
      gender: "Female",
      RegNo: "REG562",
      country: "Canada",
      receiptNo: "REC654",
      address: "397 Elm St, City",
      oppCharges: 239,
      gstCharges: 17,
      OTTCharges: 35,
      totalAmount: 291,
      status: "Cancelled",
      JourneyDate: "2023-02-04",
    },
    {
      lastName: "Williams",
      firstName: "Richard",
      age: 42,
      gender: "Male",
      RegNo: "REG928",
      country: "USA",
      receiptNo: "REC150",
      address: "57 Elm St, City",
      oppCharges: 134,
      gstCharges: 35,
      OTTCharges: 34,
      totalAmount: 203,
      status: "Cancelled",
      JourneyDate: "2023-05-22",
    },
    {
      lastName: "Williams",
      firstName: "David",
      age: 68,
      gender: "Female",
      RegNo: "REG259",
      country: "UK",
      receiptNo: "REC164",
      address: "200 Elm St, City",
      oppCharges: 189,
      gstCharges: 35,
      OTTCharges: 45,
      totalAmount: 269,
      status: "Cancelled",
      JourneyDate: "2023-02-23",
    },
    {
      lastName: "Davis",
      firstName: "Michael",
      age: 22,
      gender: "Female",
      RegNo: "REG359",
      country: "UK",
      receiptNo: "REC799",
      address: "255 Elm St, City",
      oppCharges: 142,
      gstCharges: 37,
      OTTCharges: 94,
      totalAmount: 273,
      status: "Paid",
      JourneyDate: "2024-03-28",
    },
    {
      lastName: "Davis",
      firstName: "Michael",
      age: 68,
      gender: "Female",
      RegNo: "REG169",
      country: "USA",
      receiptNo: "REC148",
      address: "236 Elm St, City",
      oppCharges: 202,
      gstCharges: 25,
      OTTCharges: 27,
      totalAmount: 254,
      status: "Cancelled",
      JourneyDate: "2023-04-19",
    },
    {
      lastName: "Davis",
      firstName: "Robert",
      age: 55,
      gender: "Male",
      RegNo: "REG593",
      country: "UK",
      receiptNo: "REC383",
      address: "481 Elm St, City",
      oppCharges: 166,
      gstCharges: 23,
      OTTCharges: 43,
      totalAmount: 232,
      status: "Pending",
      JourneyDate: "2024-01-30",
    },
    {
      lastName: "Johnson",
      firstName: "William",
      age: 68,
      gender: "Male",
      RegNo: "REG343",
      country: "USA",
      receiptNo: "REC24",
      address: "279 Elm St, City",
      oppCharges: 176,
      gstCharges: 13,
      OTTCharges: 29,
      totalAmount: 218,
      status: "Cancelled",
      JourneyDate: "2023-02-18",
    },
    {
      lastName: "Davis",
      firstName: "John",
      age: 20,
      gender: "Female",
      RegNo: "REG874",
      country: "Australia",
      receiptNo: "REC842",
      address: "616 Elm St, City",
      oppCharges: 137,
      gstCharges: 27,
      OTTCharges: 41,
      totalAmount: 205,
      status: "Pending",
      JourneyDate: "2023-12-08",
    },
    {
      lastName: "Jones",
      firstName: "William",
      age: 51,
      gender: "Male",
      RegNo: "REG792",
      country: "UK",
      receiptNo: "REC335",
      address: "721 Elm St, City",
      oppCharges: 74,
      gstCharges: 36,
      OTTCharges: 95,
      totalAmount: 205,
      status: "Paid",
      JourneyDate: "2023-10-27",
    },
    {
      lastName: "Jones",
      firstName: "Robert",
      age: 64,
      gender: "Male",
      RegNo: "REG204",
      country: "India",
      receiptNo: "REC590",
      address: "453 Elm St, City",
      oppCharges: 171,
      gstCharges: 22,
      OTTCharges: 27,
      totalAmount: 220,
      status: "Paid",
      JourneyDate: "2023-08-15",
    },
    {
      lastName: "Johnson",
      firstName: "Robert",
      age: 30,
      gender: "Female",
      RegNo: "REG427",
      country: "USA",
      receiptNo: "REC233",
      address: "223 Elm St, City",
      oppCharges: 53,
      gstCharges: 26,
      OTTCharges: 88,
      totalAmount: 167,
      status: "Cancelled",
      JourneyDate: "2023-03-25",
    },
    {
      lastName: "Davis",
      firstName: "Robert",
      age: 49,
      gender: "Female",
      RegNo: "REG351",
      country: "India",
      receiptNo: "REC52",
      address: "789 Elm St, City",
      oppCharges: 204,
      gstCharges: 25,
      OTTCharges: 2,
      totalAmount: 231,
      status: "Pending",
      JourneyDate: "2023-12-29",
    },
    {
      lastName: "Davis",
      firstName: "Richard",
      age: 64,
      gender: "Male",
      RegNo: "REG468",
      country: "India",
      receiptNo: "REC796",
      address: "316 Elm St, City",
      oppCharges: 96,
      gstCharges: 30,
      OTTCharges: 30,
      totalAmount: 156,
      status: "Cancelled",
      JourneyDate: "2023-04-09",
    },
    {
      lastName: "Williams",
      firstName: "William",
      age: 20,
      gender: "Male",
      RegNo: "REG800",
      country: "USA",
      receiptNo: "REC891",
      address: "586 Elm St, City",
      oppCharges: 65,
      gstCharges: 33,
      OTTCharges: 29,
      totalAmount: 127,
      status: "Cancelled",
      JourneyDate: "2024-05-06",
    },
    {
      lastName: "Williams",
      firstName: "John",
      age: 33,
      gender: "Male",
      RegNo: "REG479",
      country: "USA",
      receiptNo: "REC371",
      address: "544 Elm St, City",
      oppCharges: 100,
      gstCharges: 39,
      OTTCharges: 56,
      totalAmount: 195,
      status: "Pending",
      JourneyDate: "2023-02-11",
    },
    {
      lastName: "Johnson",
      firstName: "Michael",
      age: 42,
      gender: "Male",
      RegNo: "REG126",
      country: "India",
      receiptNo: "REC559",
      address: "386 Elm St, City",
      oppCharges: 95,
      gstCharges: 19,
      OTTCharges: 91,
      totalAmount: 205,
      status: "Cancelled",
      JourneyDate: "2023-02-03",
    },
    {
      lastName: "Miller",
      firstName: "Richard",
      age: 69,
      gender: "Male",
      RegNo: "REG315",
      country: "Australia",
      receiptNo: "REC392",
      address: "835 Elm St, City",
      oppCharges: 120,
      gstCharges: 36,
      OTTCharges: 40,
      totalAmount: 196,
      status: "Paid",
      JourneyDate: "2024-06-02",
    },
    {
      lastName: "Davis",
      firstName: "Richard",
      age: 29,
      gender: "Female",
      RegNo: "REG697",
      country: "Australia",
      receiptNo: "REC710",
      address: "19 Elm St, City",
      oppCharges: 170,
      gstCharges: 20,
      OTTCharges: 65,
      totalAmount: 255,
      status: "Paid",
      JourneyDate: "2024-05-24",
    },
    {
      lastName: "Jones",
      firstName: "Richard",
      age: 58,
      gender: "Male",
      RegNo: "REG514",
      country: "Canada",
      receiptNo: "REC764",
      address: "293 Elm St, City",
      oppCharges: 156,
      gstCharges: 25,
      OTTCharges: 40,
      totalAmount: 221,
      status: "Paid",
      JourneyDate: "2023-04-22",
    },
    {
      lastName: "Miller",
      firstName: "Robert",
      age: 49,
      gender: "Male",
      RegNo: "REG815",
      country: "Canada",
      receiptNo: "REC811",
      address: "345 Elm St, City",
      oppCharges: 208,
      gstCharges: 19,
      OTTCharges: 26,
      totalAmount: 253,
      status: "Paid",
      JourneyDate: "2024-01-13",
    },
    {
      lastName: "Jones",
      firstName: "Richard",
      age: 32,
      gender: "Female",
      RegNo: "REG450",
      country: "USA",
      receiptNo: "REC406",
      address: "289 Elm St, City",
      oppCharges: 166,
      gstCharges: 23,
      OTTCharges: 79,
      totalAmount: 268,
      status: "Pending",
      JourneyDate: "2024-05-23",
    },
    {
      lastName: "Smith",
      firstName: "John",
      age: 38,
      gender: "Female",
      RegNo: "REG402",
      country: "Canada",
      receiptNo: "REC594",
      address: "910 Elm St, City",
      oppCharges: 50,
      gstCharges: 29,
      OTTCharges: 52,
      totalAmount: 131,
      status: "Paid",
      JourneyDate: "2024-05-06",
    },
    {
      lastName: "Miller",
      firstName: "Robert",
      age: 34,
      gender: "Male",
      RegNo: "REG876",
      country: "Canada",
      receiptNo: "REC941",
      address: "398 Elm St, City",
      oppCharges: 126,
      gstCharges: 39,
      OTTCharges: 94,
      totalAmount: 259,
      status: "Paid",
      JourneyDate: "2024-03-28",
    },
    {
      lastName: "Brown",
      firstName: "James",
      age: 55,
      gender: "Female",
      RegNo: "REG605",
      country: "Canada",
      receiptNo: "REC682",
      address: "969 Elm St, City",
      oppCharges: 208,
      gstCharges: 22,
      OTTCharges: 79,
      totalAmount: 309,
      status: "Pending",
      JourneyDate: "2023-08-11",
    },
    {
      lastName: "Jones",
      firstName: "Michael",
      age: 46,
      gender: "Male",
      RegNo: "REG284",
      country: "UK",
      receiptNo: "REC198",
      address: "469 Elm St, City",
      oppCharges: 160,
      gstCharges: 35,
      OTTCharges: 80,
      totalAmount: 275,
      status: "Pending",
      JourneyDate: "2024-04-13",
    },
    {
      lastName: "Smith",
      firstName: "Michael",
      age: 32,
      gender: "Male",
      RegNo: "REG275",
      country: "Canada",
      receiptNo: "REC284",
      address: "809 Elm St, City",
      oppCharges: 137,
      gstCharges: 19,
      OTTCharges: 59,
      totalAmount: 215,
      status: "Paid",
      JourneyDate: "2023-07-12",
    },
    {
      lastName: "Johnson",
      firstName: "Michael",
      age: 65,
      gender: "Male",
      RegNo: "REG733",
      country: "Australia",
      receiptNo: "REC849",
      address: "655 Elm St, City",
      oppCharges: 145,
      gstCharges: 29,
      OTTCharges: 11,
      totalAmount: 185,
      status: "Paid",
      JourneyDate: "2024-01-12",
    },
    {
      lastName: "Davis",
      firstName: "James",
      age: 21,
      gender: "Male",
      RegNo: "REG634",
      country: "Australia",
      receiptNo: "REC816",
      address: "781 Elm St, City",
      oppCharges: 71,
      gstCharges: 23,
      OTTCharges: 19,
      totalAmount: 113,
      status: "Pending",
      JourneyDate: "2023-02-19",
    },
    {
      lastName: "Smith",
      firstName: "William",
      age: 54,
      gender: "Male",
      RegNo: "REG526",
      country: "India",
      receiptNo: "REC570",
      address: "723 Elm St, City",
      oppCharges: 213,
      gstCharges: 38,
      OTTCharges: 80,
      totalAmount: 331,
      status: "Pending",
      JourneyDate: "2023-08-12",
    },
    {
      lastName: "Williams",
      firstName: "Robert",
      age: 66,
      gender: "Male",
      RegNo: "REG249",
      country: "UK",
      receiptNo: "REC533",
      address: "94 Elm St, City",
      oppCharges: 245,
      gstCharges: 35,
      OTTCharges: 39,
      totalAmount: 319,
      status: "Paid",
      JourneyDate: "2023-11-28",
    },
    {
      lastName: "Johnson",
      firstName: "William",
      age: 66,
      gender: "Male",
      RegNo: "REG636",
      country: "Australia",
      receiptNo: "REC562",
      address: "701 Elm St, City",
      oppCharges: 199,
      gstCharges: 10,
      OTTCharges: 12,
      totalAmount: 221,
      status: "Pending",
      JourneyDate: "2023-12-22",
    },
    {
      lastName: "Jones",
      firstName: "Richard",
      age: 67,
      gender: "Female",
      RegNo: "REG515",
      country: "USA",
      receiptNo: "REC688",
      address: "452 Elm St, City",
      oppCharges: 147,
      gstCharges: 36,
      OTTCharges: 20,
      totalAmount: 203,
      status: "Pending",
      JourneyDate: "2023-05-23",
    },
    {
      lastName: "Davis",
      firstName: "Richard",
      age: 47,
      gender: "Female",
      RegNo: "REG824",
      country: "India",
      receiptNo: "REC765",
      address: "49 Elm St, City",
      oppCharges: 124,
      gstCharges: 31,
      OTTCharges: 47,
      totalAmount: 202,
      status: "Pending",
      JourneyDate: "2023-06-23",
    },
    {
      lastName: "Johnson",
      firstName: "Robert",
      age: 25,
      gender: "Female",
      RegNo: "REG217",
      country: "India",
      receiptNo: "REC736",
      address: "112 Elm St, City",
      oppCharges: 91,
      gstCharges: 29,
      OTTCharges: 20,
      totalAmount: 140,
      status: "Pending",
      JourneyDate: "2023-01-04",
    },
    {
      lastName: "Johnson",
      firstName: "John",
      age: 26,
      gender: "Male",
      RegNo: "REG480",
      country: "Canada",
      receiptNo: "REC128",
      address: "901 Elm St, City",
      oppCharges: 222,
      gstCharges: 23,
      OTTCharges: 99,
      totalAmount: 344,
      status: "Cancelled",
      JourneyDate: "2023-03-16",
    },
    {
      lastName: "Smith",
      firstName: "David",
      age: 60,
      gender: "Female",
      RegNo: "REG38",
      country: "Canada",
      receiptNo: "REC813",
      address: "469 Elm St, City",
      oppCharges: 204,
      gstCharges: 30,
      OTTCharges: 65,
      totalAmount: 299,
      status: "Pending",
      JourneyDate: "2024-02-28",
    },
    {
      lastName: "Davis",
      firstName: "James",
      age: 69,
      gender: "Female",
      RegNo: "REG932",
      country: "Canada",
      receiptNo: "REC209",
      address: "656 Elm St, City",
      oppCharges: 141,
      gstCharges: 19,
      OTTCharges: 65,
      totalAmount: 225,
      status: "Pending",
      JourneyDate: "2023-12-20",
    },
    {
      lastName: "Brown",
      firstName: "Michael",
      age: 63,
      gender: "Female",
      RegNo: "REG654",
      country: "UK",
      receiptNo: "REC630",
      address: "827 Elm St, City",
      oppCharges: 217,
      gstCharges: 14,
      OTTCharges: 67,
      totalAmount: 298,
      status: "Paid",
      JourneyDate: "2024-02-08",
    },
    {
      lastName: "Davis",
      firstName: "Richard",
      age: 37,
      gender: "Male",
      RegNo: "REG73",
      country: "India",
      receiptNo: "REC433",
      address: "157 Elm St, City",
      oppCharges: 155,
      gstCharges: 28,
      OTTCharges: 73,
      totalAmount: 256,
      status: "Paid",
      JourneyDate: "2023-09-26",
    },
    {
      lastName: "Williams",
      firstName: "John",
      age: 47,
      gender: "Female",
      RegNo: "REG849",
      country: "USA",
      receiptNo: "REC445",
      address: "845 Elm St, City",
      oppCharges: 122,
      gstCharges: 37,
      OTTCharges: 48,
      totalAmount: 207,
      status: "Paid",
      JourneyDate: "2023-06-19",
    },
    {
      lastName: "Smith",
      firstName: "Richard",
      age: 53,
      gender: "Male",
      RegNo: "REG834",
      country: "India",
      receiptNo: "REC241",
      address: "920 Elm St, City",
      oppCharges: 241,
      gstCharges: 13,
      OTTCharges: 49,
      totalAmount: 303,
      status: "Pending",
      JourneyDate: "2023-03-22",
    },
    {
      lastName: "Johnson",
      firstName: "Robert",
      age: 49,
      gender: "Female",
      RegNo: "REG342",
      country: "Australia",
      receiptNo: "REC31",
      address: "857 Elm St, City",
      oppCharges: 90,
      gstCharges: 23,
      OTTCharges: 50,
      totalAmount: 163,
      status: "Cancelled",
      JourneyDate: "2023-10-04",
    },
    {
      lastName: "Johnson",
      firstName: "James",
      age: 34,
      gender: "Female",
      RegNo: "REG375",
      country: "USA",
      receiptNo: "REC513",
      address: "100 Elm St, City",
      oppCharges: 141,
      gstCharges: 31,
      OTTCharges: 13,
      totalAmount: 185,
      status: "Paid",
      JourneyDate: "2023-05-20",
    },
    {
      lastName: "Miller",
      firstName: "Richard",
      age: 21,
      gender: "Female",
      RegNo: "REG834",
      country: "Australia",
      receiptNo: "REC107",
      address: "463 Elm St, City",
      oppCharges: 127,
      gstCharges: 24,
      OTTCharges: 83,
      totalAmount: 234,
      status: "Paid",
      JourneyDate: "2024-02-25",
    },
    {
      lastName: "Miller",
      firstName: "Michael",
      age: 41,
      gender: "Male",
      RegNo: "REG343",
      country: "USA",
      receiptNo: "REC409",
      address: "636 Elm St, City",
      oppCharges: 111,
      gstCharges: 19,
      OTTCharges: 69,
      totalAmount: 199,
      status: "Pending",
      JourneyDate: "2024-06-06",
    },
    {
      lastName: "Davis",
      firstName: "David",
      age: 44,
      gender: "Female",
      RegNo: "REG141",
      country: "India",
      receiptNo: "REC250",
      address: "390 Elm St, City",
      oppCharges: 116,
      gstCharges: 14,
      OTTCharges: 39,
      totalAmount: 169,
      status: "Paid",
      JourneyDate: "2024-01-24",
    },
    {
      lastName: "Williams",
      firstName: "David",
      age: 55,
      gender: "Female",
      RegNo: "REG580",
      country: "Australia",
      receiptNo: "REC212",
      address: "246 Elm St, City",
      oppCharges: 58,
      gstCharges: 26,
      OTTCharges: 16,
      totalAmount: 100,
      status: "Paid",
      JourneyDate: "2024-03-26",
    },
    {
      lastName: "Davis",
      firstName: "Richard",
      age: 61,
      gender: "Female",
      RegNo: "REG960",
      country: "India",
      receiptNo: "REC570",
      address: "604 Elm St, City",
      oppCharges: 58,
      gstCharges: 22,
      OTTCharges: 29,
      totalAmount: 109,
      status: "Cancelled",
      JourneyDate: "2023-03-26",
    },
    {
      lastName: "Smith",
      firstName: "Michael",
      age: 53,
      gender: "Male",
      RegNo: "REG606",
      country: "UK",
      receiptNo: "REC806",
      address: "120 Elm St, City",
      oppCharges: 233,
      gstCharges: 15,
      OTTCharges: 25,
      totalAmount: 273,
      status: "Cancelled",
      JourneyDate: "2023-11-16",
    },
    {
      lastName: "Johnson",
      firstName: "David",
      age: 23,
      gender: "Male",
      RegNo: "REG161",
      country: "Canada",
      receiptNo: "REC987",
      address: "389 Elm St, City",
      oppCharges: 162,
      gstCharges: 28,
      OTTCharges: 15,
      totalAmount: 205,
      status: "Paid",
      JourneyDate: "2023-07-21",
    },
    {
      lastName: "Jones",
      firstName: "Robert",
      age: 30,
      gender: "Male",
      RegNo: "REG734",
      country: "Australia",
      receiptNo: "REC733",
      address: "338 Elm St, City",
      oppCharges: 246,
      gstCharges: 20,
      OTTCharges: 29,
      totalAmount: 295,
      status: "Pending",
      JourneyDate: "2023-07-19",
    },
    {
      lastName: "Brown",
      firstName: "David",
      age: 27,
      gender: "Female",
      RegNo: "REG111",
      country: "UK",
      receiptNo: "REC853",
      address: "790 Elm St, City",
      oppCharges: 101,
      gstCharges: 27,
      OTTCharges: 72,
      totalAmount: 200,
      status: "Paid",
      JourneyDate: "2023-10-08",
    },
    {
      lastName: "Miller",
      firstName: "Michael",
      age: 44,
      gender: "Male",
      RegNo: "REG910",
      country: "UK",
      receiptNo: "REC625",
      address: "782 Elm St, City",
      oppCharges: 121,
      gstCharges: 14,
      OTTCharges: 1,
      totalAmount: 136,
      status: "Cancelled",
      JourneyDate: "2023-04-14",
    },
    {
      lastName: "Johnson",
      firstName: "Richard",
      age: 63,
      gender: "Male",
      RegNo: "REG673",
      country: "UK",
      receiptNo: "REC58",
      address: "878 Elm St, City",
      oppCharges: 207,
      gstCharges: 16,
      OTTCharges: 77,
      totalAmount: 300,
      status: "Pending",
      JourneyDate: "2024-03-07",
    },
    {
      lastName: "Jones",
      firstName: "William",
      age: 45,
      gender: "Male",
      RegNo: "REG825",
      country: "USA",
      receiptNo: "REC753",
      address: "861 Elm St, City",
      oppCharges: 160,
      gstCharges: 19,
      OTTCharges: 69,
      totalAmount: 248,
      status: "Cancelled",
      JourneyDate: "2023-08-06",
    },
    {
      lastName: "Williams",
      firstName: "Richard",
      age: 61,
      gender: "Male",
      RegNo: "REG226",
      country: "UK",
      receiptNo: "REC542",
      address: "167 Elm St, City",
      oppCharges: 77,
      gstCharges: 29,
      OTTCharges: 38,
      totalAmount: 144,
      status: "Paid",
      JourneyDate: "2024-06-23",
    },
    {
      lastName: "Smith",
      firstName: "Robert",
      age: 64,
      gender: "Male",
      RegNo: "REG532",
      country: "India",
      receiptNo: "REC933",
      address: "252 Elm St, City",
      oppCharges: 99,
      gstCharges: 14,
      OTTCharges: 77,
      totalAmount: 190,
      status: "Paid",
      JourneyDate: "2023-11-14",
    },
    {
      lastName: "Johnson",
      firstName: "David",
      age: 53,
      gender: "Male",
      RegNo: "REG767",
      country: "Canada",
      receiptNo: "REC876",
      address: "517 Elm St, City",
      oppCharges: 192,
      gstCharges: 29,
      OTTCharges: 24,
      totalAmount: 245,
      status: "Paid",
      JourneyDate: "2024-03-26",
    },
    {
      lastName: "Miller",
      firstName: "Michael",
      age: 29,
      gender: "Female",
      RegNo: "REG728",
      country: "Canada",
      receiptNo: "REC206",
      address: "797 Elm St, City",
      oppCharges: 248,
      gstCharges: 17,
      OTTCharges: 72,
      totalAmount: 337,
      status: "Paid",
      JourneyDate: "2024-07-01",
    },
    {
      lastName: "Jones",
      firstName: "James",
      age: 68,
      gender: "Female",
      RegNo: "REG216",
      country: "India",
      receiptNo: "REC801",
      address: "815 Elm St, City",
      oppCharges: 122,
      gstCharges: 33,
      OTTCharges: 82,
      totalAmount: 237,
      status: "Cancelled",
      JourneyDate: "2023-10-28",
    },
    {
      lastName: "Smith",
      firstName: "David",
      age: 20,
      gender: "Male",
      RegNo: "REG241",
      country: "India",
      receiptNo: "REC234",
      address: "882 Elm St, City",
      oppCharges: 198,
      gstCharges: 36,
      OTTCharges: 35,
      totalAmount: 269,
      status: "Cancelled",
      JourneyDate: "2024-06-23",
    },
    {
      lastName: "Brown",
      firstName: "Robert",
      age: 42,
      gender: "Male",
      RegNo: "REG666",
      country: "USA",
      receiptNo: "REC485",
      address: "456 Elm St, City",
      oppCharges: 222,
      gstCharges: 20,
      OTTCharges: 19,
      totalAmount: 261,
      status: "Pending",
      JourneyDate: "2024-06-29",
    },
    {
      lastName: "Davis",
      firstName: "William",
      age: 26,
      gender: "Male",
      RegNo: "REG780",
      country: "USA",
      receiptNo: "REC793",
      address: "328 Elm St, City",
      oppCharges: 69,
      gstCharges: 31,
      OTTCharges: 13,
      totalAmount: 113,
      status: "Paid",
      JourneyDate: "2023-03-08",
    },
    {
      lastName: "Johnson",
      firstName: "John",
      age: 40,
      gender: "Male",
      RegNo: "REG62",
      country: "Australia",
      receiptNo: "REC413",
      address: "655 Elm St, City",
      oppCharges: 205,
      gstCharges: 26,
      OTTCharges: 38,
      totalAmount: 269,
      status: "Paid",
      JourneyDate: "2024-02-27",
    },
    {
      lastName: "Brown",
      firstName: "Robert",
      age: 31,
      gender: "Male",
      RegNo: "REG331",
      country: "India",
      receiptNo: "REC708",
      address: "167 Elm St, City",
      oppCharges: 63,
      gstCharges: 25,
      OTTCharges: 56,
      totalAmount: 144,
      status: "Paid",
      JourneyDate: "2023-01-07",
    },
    {
      lastName: "Brown",
      firstName: "James",
      age: 39,
      gender: "Male",
      RegNo: "REG693",
      country: "India",
      receiptNo: "REC687",
      address: "212 Elm St, City",
      oppCharges: 199,
      gstCharges: 39,
      OTTCharges: 26,
      totalAmount: 264,
      status: "Pending",
      JourneyDate: "2023-10-04",
    },
    {
      lastName: "Johnson",
      firstName: "Michael",
      age: 51,
      gender: "Male",
      RegNo: "REG459",
      country: "Australia",
      receiptNo: "REC351",
      address: "154 Elm St, City",
      oppCharges: 143,
      gstCharges: 18,
      OTTCharges: 98,
      totalAmount: 259,
      status: "Pending",
      JourneyDate: "2024-01-15",
    },
    {
      lastName: "Smith",
      firstName: "Michael",
      age: 52,
      gender: "Female",
      RegNo: "REG991",
      country: "USA",
      receiptNo: "REC747",
      address: "128 Elm St, City",
      oppCharges: 207,
      gstCharges: 19,
      OTTCharges: 23,
      totalAmount: 249,
      status: "Cancelled",
      JourneyDate: "2023-08-15",
    },
    {
      lastName: "Johnson",
      firstName: "Robert",
      age: 68,
      gender: "Male",
      RegNo: "REG403",
      country: "Australia",
      receiptNo: "REC189",
      address: "25 Elm St, City",
      oppCharges: 98,
      gstCharges: 38,
      OTTCharges: 36,
      totalAmount: 172,
      status: "Paid",
      JourneyDate: "2024-01-08",
    },
    {
      lastName: "Brown",
      firstName: "Richard",
      age: 44,
      gender: "Male",
      RegNo: "REG804",
      country: "Australia",
      receiptNo: "REC948",
      address: "193 Elm St, City",
      oppCharges: 76,
      gstCharges: 28,
      OTTCharges: 60,
      totalAmount: 164,
      status: "Pending",
      JourneyDate: "2023-01-11",
    },
    {
      lastName: "Smith",
      firstName: "Richard",
      age: 29,
      gender: "Male",
      RegNo: "REG934",
      country: "Canada",
      receiptNo: "REC145",
      address: "273 Elm St, City",
      oppCharges: 96,
      gstCharges: 29,
      OTTCharges: 35,
      totalAmount: 160,
      status: "Paid",
      JourneyDate: "2023-05-30",
    },
    {
      lastName: "Brown",
      firstName: "Michael",
      age: 55,
      gender: "Male",
      RegNo: "REG612",
      country: "Canada",
      receiptNo: "REC731",
      address: "39 Elm St, City",
      oppCharges: 198,
      gstCharges: 38,
      OTTCharges: 47,
      totalAmount: 283,
      status: "Paid",
      JourneyDate: "2023-06-22",
    },
    {
      lastName: "Miller",
      firstName: "John",
      age: 59,
      gender: "Male",
      RegNo: "REG917",
      country: "UK",
      receiptNo: "REC19",
      address: "826 Elm St, City",
      oppCharges: 160,
      gstCharges: 17,
      OTTCharges: 10,
      totalAmount: 187,
      status: "Cancelled",
      JourneyDate: "2024-05-14",
    },
    {
      lastName: "Miller",
      firstName: "David",
      age: 53,
      gender: "Male",
      RegNo: "REG733",
      country: "Australia",
      receiptNo: "REC609",
      address: "318 Elm St, City",
      oppCharges: 109,
      gstCharges: 33,
      OTTCharges: 61,
      totalAmount: 203,
      status: "Paid",
      JourneyDate: "2023-12-06",
    },
    {
      lastName: "Johnson",
      firstName: "James",
      age: 59,
      gender: "Female",
      RegNo: "REG127",
      country: "Australia",
      receiptNo: "REC190",
      address: "615 Elm St, City",
      oppCharges: 144,
      gstCharges: 29,
      OTTCharges: 98,
      totalAmount: 271,
      status: "Cancelled",
      JourneyDate: "2023-11-22",
    },
    {
      lastName: "Miller",
      firstName: "James",
      age: 20,
      gender: "Female",
      RegNo: "REG643",
      country: "UK",
      receiptNo: "REC370",
      address: "698 Elm St, City",
      oppCharges: 221,
      gstCharges: 19,
      OTTCharges: 63,
      totalAmount: 303,
      status: "Paid",
      JourneyDate: "2024-02-13",
    },
    {
      lastName: "Johnson",
      firstName: "James",
      age: 29,
      gender: "Female",
      RegNo: "REG740",
      country: "Canada",
      receiptNo: "REC47",
      address: "48 Elm St, City",
      oppCharges: 68,
      gstCharges: 17,
      OTTCharges: 80,
      totalAmount: 165,
      status: "Pending",
      JourneyDate: "2023-04-07",
    },
    {
      lastName: "Jones",
      firstName: "David",
      age: 28,
      gender: "Male",
      RegNo: "REG279",
      country: "Australia",
      receiptNo: "REC334",
      address: "250 Elm St, City",
      oppCharges: 181,
      gstCharges: 19,
      OTTCharges: 44,
      totalAmount: 244,
      status: "Paid",
      JourneyDate: "2024-02-21",
    },
    {
      lastName: "Johnson",
      firstName: "Richard",
      age: 46,
      gender: "Female",
      RegNo: "REG226",
      country: "Canada",
      receiptNo: "REC515",
      address: "916 Elm St, City",
      oppCharges: 217,
      gstCharges: 35,
      OTTCharges: 18,
      totalAmount: 270,
      status: "Cancelled",
      JourneyDate: "2023-03-13",
    },
    {
      lastName: "Brown",
      firstName: "John",
      age: 36,
      gender: "Female",
      RegNo: "REG194",
      country: "UK",
      receiptNo: "REC976",
      address: "572 Elm St, City",
      oppCharges: 131,
      gstCharges: 32,
      OTTCharges: 93,
      totalAmount: 256,
      status: "Pending",
      JourneyDate: "2023-10-08",
    },
    {
      lastName: "Johnson",
      firstName: "Michael",
      age: 45,
      gender: "Male",
      RegNo: "REG619",
      country: "Australia",
      receiptNo: "REC120",
      address: "529 Elm St, City",
      oppCharges: 241,
      gstCharges: 33,
      OTTCharges: 79,
      totalAmount: 353,
      status: "Paid",
      JourneyDate: "2023-09-15",
    },
    {
      lastName: "Johnson",
      firstName: "John",
      age: 55,
      gender: "Female",
      RegNo: "REG478",
      country: "India",
      receiptNo: "REC166",
      address: "785 Elm St, City",
      oppCharges: 210,
      gstCharges: 22,
      OTTCharges: 87,
      totalAmount: 319,
      status: "Cancelled",
      JourneyDate: "2024-01-27",
    },
    {
      lastName: "Johnson",
      firstName: "David",
      age: 58,
      gender: "Male",
      RegNo: "REG732",
      country: "India",
      receiptNo: "REC497",
      address: "811 Elm St, City",
      oppCharges: 144,
      gstCharges: 27,
      OTTCharges: 92,
      totalAmount: 263,
      status: "Pending",
      JourneyDate: "2024-04-08",
    },
    {
      lastName: "Jones",
      firstName: "John",
      age: 32,
      gender: "Female",
      RegNo: "REG252",
      country: "UK",
      receiptNo: "REC83",
      address: "833 Elm St, City",
      oppCharges: 204,
      gstCharges: 11,
      OTTCharges: 32,
      totalAmount: 247,
      status: "Cancelled",
      JourneyDate: "2024-04-08",
    },
    {
      lastName: "Williams",
      firstName: "Robert",
      age: 52,
      gender: "Female",
      RegNo: "REG471",
      country: "USA",
      receiptNo: "REC153",
      address: "284 Elm St, City",
      oppCharges: 124,
      gstCharges: 23,
      OTTCharges: 8,
      totalAmount: 155,
      status: "Paid",
      JourneyDate: "2023-04-10",
    },
    {
      lastName: "Davis",
      firstName: "John",
      age: 60,
      gender: "Female",
      RegNo: "REG213",
      country: "USA",
      receiptNo: "REC503",
      address: "932 Elm St, City",
      oppCharges: 156,
      gstCharges: 12,
      OTTCharges: 34,
      totalAmount: 202,
      status: "Cancelled",
      JourneyDate: "2024-05-20",
    },
    {
      lastName: "Johnson",
      firstName: "John",
      age: 55,
      gender: "Female",
      RegNo: "REG795",
      country: "UK",
      receiptNo: "REC385",
      address: "94 Elm St, City",
      oppCharges: 232,
      gstCharges: 28,
      OTTCharges: 24,
      totalAmount: 284,
      status: "Cancelled",
      JourneyDate: "2024-06-30",
    },
    {
      lastName: "Smith",
      firstName: "William",
      age: 49,
      gender: "Female",
      RegNo: "REG908",
      country: "India",
      receiptNo: "REC280",
      address: "863 Elm St, City",
      oppCharges: 84,
      gstCharges: 35,
      OTTCharges: 11,
      totalAmount: 130,
      status: "Pending",
      JourneyDate: "2023-06-27",
    },
    {
      lastName: "Brown",
      firstName: "Richard",
      age: 48,
      gender: "Female",
      RegNo: "REG906",
      country: "India",
      receiptNo: "REC385",
      address: "283 Elm St, City",
      oppCharges: 245,
      gstCharges: 12,
      OTTCharges: 8,
      totalAmount: 265,
      status: "Pending",
      JourneyDate: "2023-09-02",
    },
    {
      lastName: "Smith",
      firstName: "James",
      age: 27,
      gender: "Female",
      RegNo: "REG948",
      country: "UK",
      receiptNo: "REC800",
      address: "725 Elm St, City",
      oppCharges: 202,
      gstCharges: 32,
      OTTCharges: 62,
      totalAmount: 296,
      status: "Pending",
      JourneyDate: "2023-07-16",
    },
    {
      lastName: "Johnson",
      firstName: "Richard",
      age: 41,
      gender: "Male",
      RegNo: "REG877",
      country: "Australia",
      receiptNo: "REC568",
      address: "745 Elm St, City",
      oppCharges: 85,
      gstCharges: 30,
      OTTCharges: 32,
      totalAmount: 147,
      status: "Cancelled",
      JourneyDate: "2024-03-31",
    },
    {
      lastName: "Johnson",
      firstName: "Michael",
      age: 48,
      gender: "Male",
      RegNo: "REG639",
      country: "USA",
      receiptNo: "REC255",
      address: "212 Elm St, City",
      oppCharges: 245,
      gstCharges: 14,
      OTTCharges: 30,
      totalAmount: 289,
      status: "Pending",
      JourneyDate: "2024-07-12",
    },
    {
      lastName: "Johnson",
      firstName: "James",
      age: 56,
      gender: "Female",
      RegNo: "REG566",
      country: "Australia",
      receiptNo: "REC40",
      address: "607 Elm St, City",
      oppCharges: 74,
      gstCharges: 10,
      OTTCharges: 56,
      totalAmount: 140,
      status: "Paid",
      JourneyDate: "2023-06-19",
    },
    {
      lastName: "Johnson",
      firstName: "Michael",
      age: 61,
      gender: "Female",
      RegNo: "REG653",
      country: "Canada",
      receiptNo: "REC281",
      address: "732 Elm St, City",
      oppCharges: 124,
      gstCharges: 35,
      OTTCharges: 68,
      totalAmount: 227,
      status: "Paid",
      JourneyDate: "2024-01-08",
    },
    {
      lastName: "Williams",
      firstName: "James",
      age: 61,
      gender: "Male",
      RegNo: "REG316",
      country: "USA",
      receiptNo: "REC966",
      address: "222 Elm St, City",
      oppCharges: 113,
      gstCharges: 25,
      OTTCharges: 53,
      totalAmount: 191,
      status: "Pending",
      JourneyDate: "2023-04-03",
    },
    {
      lastName: "Williams",
      firstName: "James",
      age: 34,
      gender: "Male",
      RegNo: "REG615",
      country: "Australia",
      receiptNo: "REC347",
      address: "705 Elm St, City",
      oppCharges: 248,
      gstCharges: 10,
      OTTCharges: 94,
      totalAmount: 352,
      status: "Cancelled",
      JourneyDate: "2024-02-15",
    },
    {
      lastName: "Jones",
      firstName: "William",
      age: 50,
      gender: "Male",
      RegNo: "REG379",
      country: "USA",
      receiptNo: "REC909",
      address: "839 Elm St, City",
      oppCharges: 115,
      gstCharges: 33,
      OTTCharges: 96,
      totalAmount: 244,
      status: "Paid",
      JourneyDate: "2023-08-03",
    },
    {
      lastName: "Smith",
      firstName: "William",
      age: 45,
      gender: "Male",
      RegNo: "REG869",
      country: "Australia",
      receiptNo: "REC336",
      address: "975 Elm St, City",
      oppCharges: 88,
      gstCharges: 24,
      OTTCharges: 14,
      totalAmount: 126,
      status: "Pending",
      JourneyDate: "2024-02-25",
    },
    {
      lastName: "Brown",
      firstName: "Robert",
      age: 41,
      gender: "Female",
      RegNo: "REG77",
      country: "Canada",
      receiptNo: "REC542",
      address: "614 Elm St, City",
      oppCharges: 194,
      gstCharges: 20,
      OTTCharges: 60,
      totalAmount: 274,
      status: "Paid",
      JourneyDate: "2023-05-01",
    },
    {
      lastName: "Davis",
      firstName: "James",
      age: 66,
      gender: "Male",
      RegNo: "REG314",
      country: "USA",
      receiptNo: "REC96",
      address: "374 Elm St, City",
      oppCharges: 76,
      gstCharges: 33,
      OTTCharges: 78,
      totalAmount: 187,
      status: "Cancelled",
      JourneyDate: "2023-04-18",
    },
    {
      lastName: "Johnson",
      firstName: "David",
      age: 34,
      gender: "Male",
      RegNo: "REG49",
      country: "UK",
      receiptNo: "REC974",
      address: "705 Elm St, City",
      oppCharges: 69,
      gstCharges: 36,
      OTTCharges: 84,
      totalAmount: 189,
      status: "Cancelled",
      JourneyDate: "2024-06-21",
    },
    {
      lastName: "Miller",
      firstName: "Michael",
      age: 62,
      gender: "Female",
      RegNo: "REG863",
      country: "India",
      receiptNo: "REC402",
      address: "635 Elm St, City",
      oppCharges: 94,
      gstCharges: 15,
      OTTCharges: 98,
      totalAmount: 207,
      status: "Cancelled",
      JourneyDate: "2023-04-16",
    },
    {
      lastName: "Johnson",
      firstName: "Richard",
      age: 51,
      gender: "Female",
      RegNo: "REG209",
      country: "USA",
      receiptNo: "REC531",
      address: "22 Elm St, City",
      oppCharges: 89,
      gstCharges: 24,
      OTTCharges: 36,
      totalAmount: 149,
      status: "Cancelled",
      JourneyDate: "2024-04-09",
    },
    {
      lastName: "Davis",
      firstName: "William",
      age: 53,
      gender: "Male",
      RegNo: "REG35",
      country: "Australia",
      receiptNo: "REC730",
      address: "535 Elm St, City",
      oppCharges: 177,
      gstCharges: 12,
      OTTCharges: 80,
      totalAmount: 269,
      status: "Pending",
      JourneyDate: "2023-09-17",
    },
    {
      lastName: "Smith",
      firstName: "John",
      age: 30,
      gender: "Male",
      RegNo: "REG766",
      country: "Australia",
      receiptNo: "REC217",
      address: "567 Elm St, City",
      oppCharges: 52,
      gstCharges: 29,
      OTTCharges: 70,
      totalAmount: 151,
      status: "Cancelled",
      JourneyDate: "2023-05-12",
    },
    {
      lastName: "Brown",
      firstName: "Robert",
      age: 31,
      gender: "Male",
      RegNo: "REG287",
      country: "India",
      receiptNo: "REC442",
      address: "222 Elm St, City",
      oppCharges: 195,
      gstCharges: 11,
      OTTCharges: 49,
      totalAmount: 255,
      status: "Pending",
      JourneyDate: "2023-11-19",
    },
    {
      lastName: "Johnson",
      firstName: "James",
      age: 66,
      gender: "Female",
      RegNo: "REG408",
      country: "USA",
      receiptNo: "REC332",
      address: "205 Elm St, City",
      oppCharges: 185,
      gstCharges: 36,
      OTTCharges: 0,
      totalAmount: 221,
      status: "Paid",
      JourneyDate: "2023-03-30",
    },
    {
      lastName: "Brown",
      firstName: "William",
      age: 37,
      gender: "Male",
      RegNo: "REG179",
      country: "UK",
      receiptNo: "REC576",
      address: "136 Elm St, City",
      oppCharges: 180,
      gstCharges: 25,
      OTTCharges: 73,
      totalAmount: 278,
      status: "Pending",
      JourneyDate: "2023-11-23",
    },
    {
      lastName: "Brown",
      firstName: "John",
      age: 45,
      gender: "Male",
      RegNo: "REG435",
      country: "USA",
      receiptNo: "REC507",
      address: "216 Elm St, City",
      oppCharges: 58,
      gstCharges: 29,
      OTTCharges: 40,
      totalAmount: 127,
      status: "Cancelled",
      JourneyDate: "2023-08-09",
    },
    {
      lastName: "Brown",
      firstName: "Michael",
      age: 20,
      gender: "Male",
      RegNo: "REG58",
      country: "Australia",
      receiptNo: "REC169",
      address: "510 Elm St, City",
      oppCharges: 62,
      gstCharges: 23,
      OTTCharges: 64,
      totalAmount: 149,
      status: "Pending",
      JourneyDate: "2023-07-12",
    },
    {
      lastName: "Johnson",
      firstName: "James",
      age: 56,
      gender: "Male",
      RegNo: "REG107",
      country: "Canada",
      receiptNo: "REC383",
      address: "535 Elm St, City",
      oppCharges: 221,
      gstCharges: 17,
      OTTCharges: 12,
      totalAmount: 250,
      status: "Cancelled",
      JourneyDate: "2024-04-19",
    },
    {
      lastName: "Miller",
      firstName: "Richard",
      age: 39,
      gender: "Female",
      RegNo: "REG474",
      country: "UK",
      receiptNo: "REC296",
      address: "509 Elm St, City",
      oppCharges: 115,
      gstCharges: 39,
      OTTCharges: 96,
      totalAmount: 250,
      status: "Cancelled",
      JourneyDate: "2023-11-20",
    },
    {
      lastName: "Johnson",
      firstName: "Robert",
      age: 26,
      gender: "Female",
      RegNo: "REG985",
      country: "Australia",
      receiptNo: "REC677",
      address: "295 Elm St, City",
      oppCharges: 218,
      gstCharges: 21,
      OTTCharges: 34,
      totalAmount: 273,
      status: "Paid",
      JourneyDate: "2023-08-01",
    },
    {
      lastName: "Brown",
      firstName: "David",
      age: 55,
      gender: "Female",
      RegNo: "REG514",
      country: "Australia",
      receiptNo: "REC46",
      address: "617 Elm St, City",
      oppCharges: 235,
      gstCharges: 33,
      OTTCharges: 47,
      totalAmount: 315,
      status: "Pending",
      JourneyDate: "2023-06-30",
    },
    {
      lastName: "Smith",
      firstName: "David",
      age: 47,
      gender: "Male",
      RegNo: "REG891",
      country: "India",
      receiptNo: "REC424",
      address: "223 Elm St, City",
      oppCharges: 74,
      gstCharges: 39,
      OTTCharges: 16,
      totalAmount: 129,
      status: "Pending",
      JourneyDate: "2023-07-22",
    },
    {
      lastName: "Williams",
      firstName: "William",
      age: 31,
      gender: "Male",
      RegNo: "REG600",
      country: "India",
      receiptNo: "REC126",
      address: "917 Elm St, City",
      oppCharges: 244,
      gstCharges: 28,
      OTTCharges: 61,
      totalAmount: 333,
      status: "Paid",
      JourneyDate: "2024-03-26",
    },
    {
      lastName: "Miller",
      firstName: "Richard",
      age: 56,
      gender: "Male",
      RegNo: "REG22",
      country: "India",
      receiptNo: "REC767",
      address: "732 Elm St, City",
      oppCharges: 203,
      gstCharges: 21,
      OTTCharges: 41,
      totalAmount: 265,
      status: "Paid",
      JourneyDate: "2024-02-19",
    },
    {
      lastName: "Jones",
      firstName: "David",
      age: 65,
      gender: "Female",
      RegNo: "REG867",
      country: "USA",
      receiptNo: "REC4",
      address: "640 Elm St, City",
      oppCharges: 245,
      gstCharges: 22,
      OTTCharges: 98,
      totalAmount: 365,
      status: "Paid",
      JourneyDate: "2024-05-08",
    },
    {
      lastName: "Brown",
      firstName: "James",
      age: 34,
      gender: "Female",
      RegNo: "REG91",
      country: "India",
      receiptNo: "REC204",
      address: "494 Elm St, City",
      oppCharges: 88,
      gstCharges: 21,
      OTTCharges: 22,
      totalAmount: 131,
      status: "Pending",
      JourneyDate: "2023-04-20",
    },
    {
      lastName: "Jones",
      firstName: "Richard",
      age: 26,
      gender: "Male",
      RegNo: "REG178",
      country: "USA",
      receiptNo: "REC853",
      address: "481 Elm St, City",
      oppCharges: 111,
      gstCharges: 21,
      OTTCharges: 9,
      totalAmount: 141,
      status: "Cancelled",
      JourneyDate: "2023-05-20",
    },
    {
      lastName: "Williams",
      firstName: "Richard",
      age: 68,
      gender: "Female",
      RegNo: "REG400",
      country: "USA",
      receiptNo: "REC296",
      address: "205 Elm St, City",
      oppCharges: 229,
      gstCharges: 25,
      OTTCharges: 0,
      totalAmount: 254,
      status: "Cancelled",
      JourneyDate: "2024-06-10",
    },
    {
      lastName: "Miller",
      firstName: "David",
      age: 46,
      gender: "Male",
      RegNo: "REG907",
      country: "UK",
      receiptNo: "REC202",
      address: "526 Elm St, City",
      oppCharges: 184,
      gstCharges: 15,
      OTTCharges: 54,
      totalAmount: 253,
      status: "Cancelled",
      JourneyDate: "2024-03-22",
    },
    {
      lastName: "Williams",
      firstName: "David",
      age: 38,
      gender: "Male",
      RegNo: "REG313",
      country: "UK",
      receiptNo: "REC179",
      address: "972 Elm St, City",
      oppCharges: 224,
      gstCharges: 12,
      OTTCharges: 65,
      totalAmount: 301,
      status: "Paid",
      JourneyDate: "2023-08-28",
    },
    {
      lastName: "Miller",
      firstName: "Robert",
      age: 59,
      gender: "Male",
      RegNo: "REG223",
      country: "UK",
      receiptNo: "REC717",
      address: "723 Elm St, City",
      oppCharges: 215,
      gstCharges: 33,
      OTTCharges: 85,
      totalAmount: 333,
      status: "Cancelled",
      JourneyDate: "2023-04-25",
    },
    {
      lastName: "Miller",
      firstName: "John",
      age: 39,
      gender: "Female",
      RegNo: "REG478",
      country: "Australia",
      receiptNo: "REC111",
      address: "837 Elm St, City",
      oppCharges: 123,
      gstCharges: 38,
      OTTCharges: 75,
      totalAmount: 236,
      status: "Cancelled",
      JourneyDate: "2023-01-06",
    },
    {
      lastName: "Williams",
      firstName: "John",
      age: 69,
      gender: "Female",
      RegNo: "REG292",
      country: "Canada",
      receiptNo: "REC922",
      address: "54 Elm St, City",
      oppCharges: 247,
      gstCharges: 36,
      OTTCharges: 42,
      totalAmount: 325,
      status: "Pending",
      JourneyDate: "2024-06-21",
    },
    {
      lastName: "Williams",
      firstName: "James",
      age: 64,
      gender: "Male",
      RegNo: "REG742",
      country: "India",
      receiptNo: "REC781",
      address: "412 Elm St, City",
      oppCharges: 134,
      gstCharges: 26,
      OTTCharges: 11,
      totalAmount: 171,
      status: "Cancelled",
      JourneyDate: "2022-12-31",
    },
    {
      lastName: "Brown",
      firstName: "John",
      age: 53,
      gender: "Female",
      RegNo: "REG207",
      country: "Canada",
      receiptNo: "REC719",
      address: "451 Elm St, City",
      oppCharges: 193,
      gstCharges: 34,
      OTTCharges: 40,
      totalAmount: 267,
      status: "Cancelled",
      JourneyDate: "2023-06-22",
    },
    {
      lastName: "Jones",
      firstName: "David",
      age: 65,
      gender: "Female",
      RegNo: "REG405",
      country: "Australia",
      receiptNo: "REC462",
      address: "426 Elm St, City",
      oppCharges: 132,
      gstCharges: 30,
      OTTCharges: 4,
      totalAmount: 166,
      status: "Pending",
      JourneyDate: "2024-05-24",
    },
    {
      lastName: "Brown",
      firstName: "Michael",
      age: 53,
      gender: "Female",
      RegNo: "REG365",
      country: "Canada",
      receiptNo: "REC583",
      address: "737 Elm St, City",
      oppCharges: 232,
      gstCharges: 26,
      OTTCharges: 20,
      totalAmount: 278,
      status: "Pending",
      JourneyDate: "2023-07-21",
    },
    {
      lastName: "Williams",
      firstName: "Richard",
      age: 22,
      gender: "Female",
      RegNo: "REG251",
      country: "UK",
      receiptNo: "REC412",
      address: "920 Elm St, City",
      oppCharges: 175,
      gstCharges: 38,
      OTTCharges: 25,
      totalAmount: 238,
      status: "Pending",
      JourneyDate: "2023-11-20",
    },
    {
      lastName: "Miller",
      firstName: "James",
      age: 37,
      gender: "Male",
      RegNo: "REG778",
      country: "USA",
      receiptNo: "REC82",
      address: "182 Elm St, City",
      oppCharges: 74,
      gstCharges: 36,
      OTTCharges: 38,
      totalAmount: 148,
      status: "Pending",
      JourneyDate: "2024-01-09",
    },
    {
      lastName: "Johnson",
      firstName: "John",
      age: 50,
      gender: "Male",
      RegNo: "REG704",
      country: "Canada",
      receiptNo: "REC632",
      address: "347 Elm St, City",
      oppCharges: 66,
      gstCharges: 37,
      OTTCharges: 55,
      totalAmount: 158,
      status: "Cancelled",
      JourneyDate: "2023-07-02",
    },
    {
      lastName: "Davis",
      firstName: "David",
      age: 54,
      gender: "Female",
      RegNo: "REG227",
      country: "UK",
      receiptNo: "REC880",
      address: "267 Elm St, City",
      oppCharges: 84,
      gstCharges: 28,
      OTTCharges: 83,
      totalAmount: 195,
      status: "Pending",
      JourneyDate: "2023-02-01",
    },
    {
      lastName: "Williams",
      firstName: "William",
      age: 46,
      gender: "Female",
      RegNo: "REG864",
      country: "India",
      receiptNo: "REC94",
      address: "607 Elm St, City",
      oppCharges: 175,
      gstCharges: 30,
      OTTCharges: 47,
      totalAmount: 252,
      status: "Cancelled",
      JourneyDate: "2023-04-15",
    },
    {
      lastName: "Davis",
      firstName: "David",
      age: 50,
      gender: "Female",
      RegNo: "REG710",
      country: "UK",
      receiptNo: "REC49",
      address: "971 Elm St, City",
      oppCharges: 85,
      gstCharges: 11,
      OTTCharges: 83,
      totalAmount: 179,
      status: "Pending",
      JourneyDate: "2023-05-25",
    },
    {
      lastName: "Jones",
      firstName: "James",
      age: 33,
      gender: "Female",
      RegNo: "REG422",
      country: "USA",
      receiptNo: "REC423",
      address: "851 Elm St, City",
      oppCharges: 128,
      gstCharges: 17,
      OTTCharges: 42,
      totalAmount: 187,
      status: "Paid",
      JourneyDate: "2024-06-11",
    },
    {
      lastName: "Smith",
      firstName: "David",
      age: 43,
      gender: "Male",
      RegNo: "REG252",
      country: "India",
      receiptNo: "REC697",
      address: "520 Elm St, City",
      oppCharges: 100,
      gstCharges: 31,
      OTTCharges: 59,
      totalAmount: 190,
      status: "Paid",
      JourneyDate: "2024-05-01",
    },
    {
      lastName: "Miller",
      firstName: "William",
      age: 47,
      gender: "Female",
      RegNo: "REG662",
      country: "UK",
      receiptNo: "REC831",
      address: "979 Elm St, City",
      oppCharges: 118,
      gstCharges: 13,
      OTTCharges: 67,
      totalAmount: 198,
      status: "Paid",
      JourneyDate: "2023-04-25",
    },
    {
      lastName: "Williams",
      firstName: "Michael",
      age: 43,
      gender: "Male",
      RegNo: "REG716",
      country: "USA",
      receiptNo: "REC863",
      address: "738 Elm St, City",
      oppCharges: 95,
      gstCharges: 26,
      OTTCharges: 17,
      totalAmount: 138,
      status: "Pending",
      JourneyDate: "2023-06-30",
    },
    {
      lastName: "Davis",
      firstName: "Richard",
      age: 59,
      gender: "Male",
      RegNo: "REG831",
      country: "India",
      receiptNo: "REC626",
      address: "950 Elm St, City",
      oppCharges: 86,
      gstCharges: 33,
      OTTCharges: 53,
      totalAmount: 172,
      status: "Paid",
      JourneyDate: "2024-06-21",
    },
    {
      lastName: "Smith",
      firstName: "James",
      age: 36,
      gender: "Female",
      RegNo: "REG205",
      country: "Canada",
      receiptNo: "REC816",
      address: "489 Elm St, City",
      oppCharges: 199,
      gstCharges: 37,
      OTTCharges: 69,
      totalAmount: 305,
      status: "Paid",
      JourneyDate: "2024-05-22",
    },
    {
      lastName: "Jones",
      firstName: "John",
      age: 38,
      gender: "Male",
      RegNo: "REG950",
      country: "Australia",
      receiptNo: "REC936",
      address: "589 Elm St, City",
      oppCharges: 209,
      gstCharges: 30,
      OTTCharges: 31,
      totalAmount: 270,
      status: "Pending",
      JourneyDate: "2023-06-22",
    },
    {
      lastName: "Brown",
      firstName: "James",
      age: 52,
      gender: "Male",
      RegNo: "REG590",
      country: "USA",
      receiptNo: "REC295",
      address: "863 Elm St, City",
      oppCharges: 213,
      gstCharges: 11,
      OTTCharges: 23,
      totalAmount: 247,
      status: "Cancelled",
      JourneyDate: "2023-07-17",
    },
    {
      lastName: "Brown",
      firstName: "James",
      age: 29,
      gender: "Male",
      RegNo: "REG768",
      country: "Australia",
      receiptNo: "REC927",
      address: "228 Elm St, City",
      oppCharges: 62,
      gstCharges: 10,
      OTTCharges: 39,
      totalAmount: 111,
      status: "Cancelled",
      JourneyDate: "2023-03-29",
    },
    {
      lastName: "Johnson",
      firstName: "William",
      age: 46,
      gender: "Male",
      RegNo: "REG38",
      country: "UK",
      receiptNo: "REC103",
      address: "517 Elm St, City",
      oppCharges: 133,
      gstCharges: 37,
      OTTCharges: 55,
      totalAmount: 225,
      status: "Cancelled",
      JourneyDate: "2023-09-09",
    },
    {
      lastName: "Miller",
      firstName: "Michael",
      age: 62,
      gender: "Male",
      RegNo: "REG915",
      country: "Australia",
      receiptNo: "REC935",
      address: "107 Elm St, City",
      oppCharges: 142,
      gstCharges: 24,
      OTTCharges: 73,
      totalAmount: 239,
      status: "Pending",
      JourneyDate: "2023-03-12",
    },
    {
      lastName: "Smith",
      firstName: "James",
      age: 35,
      gender: "Female",
      RegNo: "REG667",
      country: "USA",
      receiptNo: "REC749",
      address: "271 Elm St, City",
      oppCharges: 239,
      gstCharges: 13,
      OTTCharges: 45,
      totalAmount: 297,
      status: "Pending",
      JourneyDate: "2023-08-26",
    },
    {
      lastName: "Brown",
      firstName: "Richard",
      age: 63,
      gender: "Female",
      RegNo: "REG447",
      country: "Australia",
      receiptNo: "REC613",
      address: "111 Elm St, City",
      oppCharges: 81,
      gstCharges: 17,
      OTTCharges: 4,
      totalAmount: 102,
      status: "Cancelled",
      JourneyDate: "2023-06-15",
    },
    {
      lastName: "Davis",
      firstName: "Richard",
      age: 22,
      gender: "Female",
      RegNo: "REG153",
      country: "Canada",
      receiptNo: "REC910",
      address: "341 Elm St, City",
      oppCharges: 166,
      gstCharges: 35,
      OTTCharges: 77,
      totalAmount: 278,
      status: "Pending",
      JourneyDate: "2024-03-15",
    },
    {
      lastName: "Davis",
      firstName: "William",
      age: 22,
      gender: "Female",
      RegNo: "REG75",
      country: "USA",
      receiptNo: "REC415",
      address: "465 Elm St, City",
      oppCharges: 110,
      gstCharges: 28,
      OTTCharges: 8,
      totalAmount: 146,
      status: "Cancelled",
      JourneyDate: "2023-05-14",
    },
    {
      lastName: "Brown",
      firstName: "Robert",
      age: 40,
      gender: "Female",
      RegNo: "REG831",
      country: "USA",
      receiptNo: "REC668",
      address: "193 Elm St, City",
      oppCharges: 69,
      gstCharges: 16,
      OTTCharges: 98,
      totalAmount: 183,
      status: "Paid",
      JourneyDate: "2023-10-02",
    },
    {
      lastName: "Jones",
      firstName: "William",
      age: 55,
      gender: "Female",
      RegNo: "REG514",
      country: "Canada",
      receiptNo: "REC431",
      address: "755 Elm St, City",
      oppCharges: 237,
      gstCharges: 29,
      OTTCharges: 1,
      totalAmount: 267,
      status: "Paid",
      JourneyDate: "2023-10-20",
    },
    {
      lastName: "Miller",
      firstName: "Robert",
      age: 56,
      gender: "Male",
      RegNo: "REG728",
      country: "Australia",
      receiptNo: "REC643",
      address: "258 Elm St, City",
      oppCharges: 191,
      gstCharges: 38,
      OTTCharges: 77,
      totalAmount: 306,
      status: "Cancelled",
      JourneyDate: "2024-03-24",
    },
    {
      lastName: "Davis",
      firstName: "John",
      age: 28,
      gender: "Male",
      RegNo: "REG150",
      country: "UK",
      receiptNo: "REC837",
      address: "842 Elm St, City",
      oppCharges: 61,
      gstCharges: 20,
      OTTCharges: 83,
      totalAmount: 164,
      status: "Pending",
      JourneyDate: "2023-09-14",
    },
    {
      lastName: "Brown",
      firstName: "John",
      age: 26,
      gender: "Male",
      RegNo: "REG114",
      country: "India",
      receiptNo: "REC812",
      address: "776 Elm St, City",
      oppCharges: 223,
      gstCharges: 16,
      OTTCharges: 62,
      totalAmount: 301,
      status: "Paid",
      JourneyDate: "2023-04-11",
    },
    {
      lastName: "Smith",
      firstName: "William",
      age: 35,
      gender: "Male",
      RegNo: "REG929",
      country: "India",
      receiptNo: "REC232",
      address: "206 Elm St, City",
      oppCharges: 159,
      gstCharges: 30,
      OTTCharges: 12,
      totalAmount: 201,
      status: "Cancelled",
      JourneyDate: "2023-11-22",
    },
    {
      lastName: "Johnson",
      firstName: "Michael",
      age: 39,
      gender: "Female",
      RegNo: "REG128",
      country: "USA",
      receiptNo: "REC516",
      address: "455 Elm St, City",
      oppCharges: 128,
      gstCharges: 12,
      OTTCharges: 19,
      totalAmount: 159,
      status: "Paid",
      JourneyDate: "2024-06-01",
    },
    {
      lastName: "Johnson",
      firstName: "William",
      age: 42,
      gender: "Female",
      RegNo: "REG222",
      country: "UK",
      receiptNo: "REC805",
      address: "145 Elm St, City",
      oppCharges: 176,
      gstCharges: 37,
      OTTCharges: 30,
      totalAmount: 243,
      status: "Paid",
      JourneyDate: "2023-04-03",
    },
    {
      lastName: "Miller",
      firstName: "David",
      age: 22,
      gender: "Male",
      RegNo: "REG499",
      country: "USA",
      receiptNo: "REC978",
      address: "330 Elm St, City",
      oppCharges: 130,
      gstCharges: 28,
      OTTCharges: 35,
      totalAmount: 193,
      status: "Paid",
      JourneyDate: "2023-04-10",
    },
    {
      lastName: "Jones",
      firstName: "John",
      age: 33,
      gender: "Male",
      RegNo: "REG432",
      country: "Canada",
      receiptNo: "REC291",
      address: "535 Elm St, City",
      oppCharges: 171,
      gstCharges: 38,
      OTTCharges: 73,
      totalAmount: 282,
      status: "Cancelled",
      JourneyDate: "2023-03-03",
    },
    {
      lastName: "Smith",
      firstName: "William",
      age: 57,
      gender: "Female",
      RegNo: "REG885",
      country: "UK",
      receiptNo: "REC960",
      address: "287 Elm St, City",
      oppCharges: 88,
      gstCharges: 24,
      OTTCharges: 64,
      totalAmount: 176,
      status: "Cancelled",
      JourneyDate: "2023-09-01",
    },
    {
      lastName: "Brown",
      firstName: "Michael",
      age: 62,
      gender: "Male",
      RegNo: "REG74",
      country: "UK",
      receiptNo: "REC544",
      address: "812 Elm St, City",
      oppCharges: 218,
      gstCharges: 16,
      OTTCharges: 32,
      totalAmount: 266,
      status: "Cancelled",
      JourneyDate: "2023-11-01",
    },
    {
      lastName: "Davis",
      firstName: "John",
      age: 52,
      gender: "Female",
      RegNo: "REG880",
      country: "USA",
      receiptNo: "REC134",
      address: "942 Elm St, City",
      oppCharges: 216,
      gstCharges: 17,
      OTTCharges: 27,
      totalAmount: 260,
      status: "Paid",
      JourneyDate: "2023-07-17",
    },
    {
      lastName: "Miller",
      firstName: "Richard",
      age: 35,
      gender: "Male",
      RegNo: "REG35",
      country: "Canada",
      receiptNo: "REC195",
      address: "536 Elm St, City",
      oppCharges: 235,
      gstCharges: 19,
      OTTCharges: 31,
      totalAmount: 285,
      status: "Cancelled",
      JourneyDate: "2023-08-14",
    },
    {
      lastName: "Brown",
      firstName: "William",
      age: 56,
      gender: "Male",
      RegNo: "REG51",
      country: "Australia",
      receiptNo: "REC294",
      address: "213 Elm St, City",
      oppCharges: 53,
      gstCharges: 24,
      OTTCharges: 54,
      totalAmount: 131,
      status: "Cancelled",
      JourneyDate: "2024-03-31",
    },
    {
      lastName: "Smith",
      firstName: "Richard",
      age: 57,
      gender: "Female",
      RegNo: "REG315",
      country: "UK",
      receiptNo: "REC14",
      address: "140 Elm St, City",
      oppCharges: 139,
      gstCharges: 23,
      OTTCharges: 51,
      totalAmount: 213,
      status: "Cancelled",
      JourneyDate: "2023-06-17",
    },
    {
      lastName: "Jones",
      firstName: "Michael",
      age: 36,
      gender: "Female",
      RegNo: "REG825",
      country: "UK",
      receiptNo: "REC35",
      address: "17 Elm St, City",
      oppCharges: 230,
      gstCharges: 28,
      OTTCharges: 60,
      totalAmount: 318,
      status: "Pending",
      JourneyDate: "2024-05-08",
    },
    {
      lastName: "Miller",
      firstName: "Richard",
      age: 63,
      gender: "Male",
      RegNo: "REG671",
      country: "Canada",
      receiptNo: "REC412",
      address: "287 Elm St, City",
      oppCharges: 153,
      gstCharges: 11,
      OTTCharges: 31,
      totalAmount: 195,
      status: "Paid",
      JourneyDate: "2023-06-23",
    },
    {
      lastName: "Miller",
      firstName: "Robert",
      age: 26,
      gender: "Male",
      RegNo: "REG635",
      country: "UK",
      receiptNo: "REC292",
      address: "427 Elm St, City",
      oppCharges: 225,
      gstCharges: 31,
      OTTCharges: 17,
      totalAmount: 273,
      status: "Paid",
      JourneyDate: "2024-06-30",
    },
    {
      lastName: "Davis",
      firstName: "Richard",
      age: 26,
      gender: "Female",
      RegNo: "REG745",
      country: "UK",
      receiptNo: "REC890",
      address: "137 Elm St, City",
      oppCharges: 62,
      gstCharges: 16,
      OTTCharges: 37,
      totalAmount: 115,
      status: "Paid",
      JourneyDate: "2023-05-19",
    },
    {
      lastName: "Williams",
      firstName: "David",
      age: 62,
      gender: "Male",
      RegNo: "REG992",
      country: "Australia",
      receiptNo: "REC469",
      address: "529 Elm St, City",
      oppCharges: 137,
      gstCharges: 12,
      OTTCharges: 95,
      totalAmount: 244,
      status: "Paid",
      JourneyDate: "2024-03-11",
    },
    {
      lastName: "Davis",
      firstName: "David",
      age: 38,
      gender: "Female",
      RegNo: "REG775",
      country: "UK",
      receiptNo: "REC536",
      address: "165 Elm St, City",
      oppCharges: 194,
      gstCharges: 12,
      OTTCharges: 82,
      totalAmount: 288,
      status: "Paid",
      JourneyDate: "2024-03-19",
    },
    {
      lastName: "Williams",
      firstName: "John",
      age: 62,
      gender: "Male",
      RegNo: "REG339",
      country: "Australia",
      receiptNo: "REC695",
      address: "732 Elm St, City",
      oppCharges: 243,
      gstCharges: 21,
      OTTCharges: 48,
      totalAmount: 312,
      status: "Paid",
      JourneyDate: "2023-01-05",
    },
    {
      lastName: "Miller",
      firstName: "David",
      age: 58,
      gender: "Female",
      RegNo: "REG492",
      country: "India",
      receiptNo: "REC654",
      address: "872 Elm St, City",
      oppCharges: 172,
      gstCharges: 26,
      OTTCharges: 14,
      totalAmount: 212,
      status: "Cancelled",
      JourneyDate: "2024-07-01",
    },
    {
      lastName: "Smith",
      firstName: "Robert",
      age: 45,
      gender: "Female",
      RegNo: "REG912",
      country: "Canada",
      receiptNo: "REC178",
      address: "616 Elm St, City",
      oppCharges: 201,
      gstCharges: 20,
      OTTCharges: 58,
      totalAmount: 279,
      status: "Paid",
      JourneyDate: "2023-03-04",
    },
    {
      lastName: "Smith",
      firstName: "William",
      age: 40,
      gender: "Male",
      RegNo: "REG85",
      country: "India",
      receiptNo: "REC372",
      address: "148 Elm St, City",
      oppCharges: 200,
      gstCharges: 38,
      OTTCharges: 32,
      totalAmount: 270,
      status: "Paid",
      JourneyDate: "2023-03-30",
    },
    {
      lastName: "Johnson",
      firstName: "John",
      age: 23,
      gender: "Male",
      RegNo: "REG157",
      country: "India",
      receiptNo: "REC396",
      address: "188 Elm St, City",
      oppCharges: 70,
      gstCharges: 19,
      OTTCharges: 12,
      totalAmount: 101,
      status: "Paid",
      JourneyDate: "2023-08-22",
    },
    {
      lastName: "Williams",
      firstName: "John",
      age: 24,
      gender: "Male",
      RegNo: "REG272",
      country: "USA",
      receiptNo: "REC435",
      address: "647 Elm St, City",
      oppCharges: 244,
      gstCharges: 19,
      OTTCharges: 80,
      totalAmount: 343,
      status: "Cancelled",
      JourneyDate: "2023-08-23",
    },
    {
      lastName: "Jones",
      firstName: "James",
      age: 38,
      gender: "Male",
      RegNo: "REG124",
      country: "India",
      receiptNo: "REC796",
      address: "911 Elm St, City",
      oppCharges: 165,
      gstCharges: 16,
      OTTCharges: 4,
      totalAmount: 185,
      status: "Pending",
      JourneyDate: "2023-12-12",
    },
    {
      lastName: "Miller",
      firstName: "Robert",
      age: 46,
      gender: "Female",
      RegNo: "REG90",
      country: "Canada",
      receiptNo: "REC389",
      address: "489 Elm St, City",
      oppCharges: 216,
      gstCharges: 29,
      OTTCharges: 43,
      totalAmount: 288,
      status: "Pending",
      JourneyDate: "2023-04-13",
    },
    {
      lastName: "Davis",
      firstName: "William",
      age: 43,
      gender: "Female",
      RegNo: "REG578",
      country: "Australia",
      receiptNo: "REC634",
      address: "828 Elm St, City",
      oppCharges: 60,
      gstCharges: 19,
      OTTCharges: 44,
      totalAmount: 123,
      status: "Pending",
      JourneyDate: "2023-10-08",
    },
    {
      lastName: "Johnson",
      firstName: "Richard",
      age: 64,
      gender: "Male",
      RegNo: "REG633",
      country: "Canada",
      receiptNo: "REC797",
      address: "805 Elm St, City",
      oppCharges: 169,
      gstCharges: 12,
      OTTCharges: 21,
      totalAmount: 202,
      status: "Paid",
      JourneyDate: "2023-09-14",
    },
    {
      lastName: "Johnson",
      firstName: "David",
      age: 24,
      gender: "Male",
      RegNo: "REG59",
      country: "Australia",
      receiptNo: "REC612",
      address: "76 Elm St, City",
      oppCharges: 219,
      gstCharges: 12,
      OTTCharges: 61,
      totalAmount: 292,
      status: "Paid",
      JourneyDate: "2023-02-15",
    },
    {
      lastName: "Smith",
      firstName: "David",
      age: 48,
      gender: "Female",
      RegNo: "REG791",
      country: "Canada",
      receiptNo: "REC51",
      address: "196 Elm St, City",
      oppCharges: 205,
      gstCharges: 35,
      OTTCharges: 42,
      totalAmount: 282,
      status: "Cancelled",
      JourneyDate: "2023-03-22",
    },
    {
      lastName: "Williams",
      firstName: "Robert",
      age: 42,
      gender: "Female",
      RegNo: "REG820",
      country: "USA",
      receiptNo: "REC206",
      address: "950 Elm St, City",
      oppCharges: 161,
      gstCharges: 23,
      OTTCharges: 12,
      totalAmount: 196,
      status: "Pending",
      JourneyDate: "2023-08-09",
    },
    {
      lastName: "Davis",
      firstName: "David",
      age: 68,
      gender: "Female",
      RegNo: "REG367",
      country: "UK",
      receiptNo: "REC149",
      address: "404 Elm St, City",
      oppCharges: 190,
      gstCharges: 14,
      OTTCharges: 72,
      totalAmount: 276,
      status: "Paid",
      JourneyDate: "2024-01-22",
    },
    {
      lastName: "Williams",
      firstName: "Robert",
      age: 49,
      gender: "Male",
      RegNo: "REG686",
      country: "Australia",
      receiptNo: "REC859",
      address: "377 Elm St, City",
      oppCharges: 123,
      gstCharges: 27,
      OTTCharges: 72,
      totalAmount: 222,
      status: "Paid",
      JourneyDate: "2023-04-08",
    },
    {
      lastName: "Smith",
      firstName: "David",
      age: 30,
      gender: "Female",
      RegNo: "REG229",
      country: "Australia",
      receiptNo: "REC868",
      address: "990 Elm St, City",
      oppCharges: 105,
      gstCharges: 11,
      OTTCharges: 48,
      totalAmount: 164,
      status: "Cancelled",
      JourneyDate: "2024-04-01",
    },
    {
      lastName: "Smith",
      firstName: "Michael",
      age: 35,
      gender: "Male",
      RegNo: "REG283",
      country: "USA",
      receiptNo: "REC110",
      address: "660 Elm St, City",
      oppCharges: 210,
      gstCharges: 17,
      OTTCharges: 49,
      totalAmount: 276,
      status: "Cancelled",
      JourneyDate: "2023-02-07",
    },
    {
      lastName: "Smith",
      firstName: "Michael",
      age: 61,
      gender: "Female",
      RegNo: "REG147",
      country: "USA",
      receiptNo: "REC907",
      address: "650 Elm St, City",
      oppCharges: 63,
      gstCharges: 39,
      OTTCharges: 14,
      totalAmount: 116,
      status: "Paid",
      JourneyDate: "2024-01-16",
    },
    {
      lastName: "Williams",
      firstName: "John",
      age: 21,
      gender: "Female",
      RegNo: "REG178",
      country: "Canada",
      receiptNo: "REC939",
      address: "764 Elm St, City",
      oppCharges: 129,
      gstCharges: 10,
      OTTCharges: 25,
      totalAmount: 164,
      status: "Paid",
      JourneyDate: "2023-09-22",
    },
    {
      lastName: "Jones",
      firstName: "James",
      age: 48,
      gender: "Female",
      RegNo: "REG883",
      country: "USA",
      receiptNo: "REC815",
      address: "410 Elm St, City",
      oppCharges: 195,
      gstCharges: 32,
      OTTCharges: 30,
      totalAmount: 257,
      status: "Cancelled",
      JourneyDate: "2024-03-27",
    },
    {
      lastName: "Davis",
      firstName: "David",
      age: 24,
      gender: "Male",
      RegNo: "REG512",
      country: "USA",
      receiptNo: "REC341",
      address: "601 Elm St, City",
      oppCharges: 87,
      gstCharges: 22,
      OTTCharges: 32,
      totalAmount: 141,
      status: "Cancelled",
      JourneyDate: "2024-04-22",
    },
    {
      lastName: "Smith",
      firstName: "Michael",
      age: 54,
      gender: "Female",
      RegNo: "REG495",
      country: "USA",
      receiptNo: "REC859",
      address: "795 Elm St, City",
      oppCharges: 161,
      gstCharges: 12,
      OTTCharges: 72,
      totalAmount: 245,
      status: "Cancelled",
      JourneyDate: "2024-03-21",
    },
    {
      lastName: "Davis",
      firstName: "William",
      age: 65,
      gender: "Male",
      RegNo: "REG835",
      country: "UK",
      receiptNo: "REC352",
      address: "489 Elm St, City",
      oppCharges: 235,
      gstCharges: 10,
      OTTCharges: 45,
      totalAmount: 290,
      status: "Cancelled",
      JourneyDate: "2023-11-04",
    },
    {
      lastName: "Williams",
      firstName: "Richard",
      age: 32,
      gender: "Female",
      RegNo: "REG137",
      country: "USA",
      receiptNo: "REC73",
      address: "361 Elm St, City",
      oppCharges: 119,
      gstCharges: 36,
      OTTCharges: 70,
      totalAmount: 225,
      status: "Cancelled",
      JourneyDate: "2023-11-14",
    },
    {
      lastName: "Brown",
      firstName: "David",
      age: 54,
      gender: "Male",
      RegNo: "REG504",
      country: "India",
      receiptNo: "REC221",
      address: "956 Elm St, City",
      oppCharges: 52,
      gstCharges: 33,
      OTTCharges: 82,
      totalAmount: 167,
      status: "Cancelled",
      JourneyDate: "2023-07-01",
    },
    {
      lastName: "Smith",
      firstName: "David",
      age: 62,
      gender: "Female",
      RegNo: "REG202",
      country: "India",
      receiptNo: "REC419",
      address: "985 Elm St, City",
      oppCharges: 185,
      gstCharges: 37,
      OTTCharges: 24,
      totalAmount: 246,
      status: "Paid",
      JourneyDate: "2024-01-24",
    },
    {
      lastName: "Jones",
      firstName: "James",
      age: 26,
      gender: "Female",
      RegNo: "REG358",
      country: "UK",
      receiptNo: "REC732",
      address: "251 Elm St, City",
      oppCharges: 176,
      gstCharges: 14,
      OTTCharges: 10,
      totalAmount: 200,
      status: "Paid",
      JourneyDate: "2024-02-28",
    },
    {
      lastName: "Miller",
      firstName: "William",
      age: 53,
      gender: "Female",
      RegNo: "REG123",
      country: "UK",
      receiptNo: "REC218",
      address: "49 Elm St, City",
      oppCharges: 134,
      gstCharges: 10,
      OTTCharges: 14,
      totalAmount: 158,
      status: "Cancelled",
      JourneyDate: "2024-04-28",
    },
    {
      lastName: "Davis",
      firstName: "Michael",
      age: 69,
      gender: "Male",
      RegNo: "REG346",
      country: "Canada",
      receiptNo: "REC80",
      address: "168 Elm St, City",
      oppCharges: 122,
      gstCharges: 35,
      OTTCharges: 21,
      totalAmount: 178,
      status: "Pending",
      JourneyDate: "2024-01-05",
    },
    {
      lastName: "Johnson",
      firstName: "Robert",
      age: 55,
      gender: "Female",
      RegNo: "REG468",
      country: "UK",
      receiptNo: "REC951",
      address: "484 Elm St, City",
      oppCharges: 238,
      gstCharges: 23,
      OTTCharges: 53,
      totalAmount: 314,
      status: "Cancelled",
      JourneyDate: "2023-05-22",
    },
    {
      lastName: "Smith",
      firstName: "Robert",
      age: 66,
      gender: "Female",
      RegNo: "REG465",
      country: "UK",
      receiptNo: "REC95",
      address: "318 Elm St, City",
      oppCharges: 81,
      gstCharges: 31,
      OTTCharges: 76,
      totalAmount: 188,
      status: "Paid",
      JourneyDate: "2023-06-03",
    },
    {
      lastName: "Johnson",
      firstName: "Richard",
      age: 46,
      gender: "Male",
      RegNo: "REG96",
      country: "UK",
      receiptNo: "REC749",
      address: "933 Elm St, City",
      oppCharges: 97,
      gstCharges: 21,
      OTTCharges: 64,
      totalAmount: 182,
      status: "Pending",
      JourneyDate: "2023-06-17",
    },
    {
      lastName: "Jones",
      firstName: "Richard",
      age: 35,
      gender: "Female",
      RegNo: "REG961",
      country: "Australia",
      receiptNo: "REC191",
      address: "184 Elm St, City",
      oppCharges: 207,
      gstCharges: 21,
      OTTCharges: 57,
      totalAmount: 285,
      status: "Cancelled",
      JourneyDate: "2024-03-11",
    },
    {
      lastName: "Davis",
      firstName: "John",
      age: 46,
      gender: "Male",
      RegNo: "REG187",
      country: "India",
      receiptNo: "REC97",
      address: "344 Elm St, City",
      oppCharges: 176,
      gstCharges: 19,
      OTTCharges: 42,
      totalAmount: 237,
      status: "Cancelled",
      JourneyDate: "2023-10-11",
    },
    {
      lastName: "Smith",
      firstName: "Richard",
      age: 58,
      gender: "Male",
      RegNo: "REG542",
      country: "USA",
      receiptNo: "REC15",
      address: "312 Elm St, City",
      oppCharges: 102,
      gstCharges: 10,
      OTTCharges: 87,
      totalAmount: 199,
      status: "Cancelled",
      JourneyDate: "2024-01-05",
    },
    {
      lastName: "Smith",
      firstName: "James",
      age: 50,
      gender: "Female",
      RegNo: "REG655",
      country: "USA",
      receiptNo: "REC383",
      address: "765 Elm St, City",
      oppCharges: 66,
      gstCharges: 21,
      OTTCharges: 58,
      totalAmount: 145,
      status: "Paid",
      JourneyDate: "2023-05-31",
    },
    {
      lastName: "Williams",
      firstName: "Richard",
      age: 37,
      gender: "Female",
      RegNo: "REG842",
      country: "USA",
      receiptNo: "REC954",
      address: "255 Elm St, City",
      oppCharges: 118,
      gstCharges: 32,
      OTTCharges: 54,
      totalAmount: 204,
      status: "Pending",
      JourneyDate: "2023-07-02",
    },
    {
      lastName: "Smith",
      firstName: "William",
      age: 22,
      gender: "Male",
      RegNo: "REG728",
      country: "USA",
      receiptNo: "REC614",
      address: "798 Elm St, City",
      oppCharges: 208,
      gstCharges: 24,
      OTTCharges: 73,
      totalAmount: 305,
      status: "Cancelled",
      JourneyDate: "2023-12-29",
    },
    {
      lastName: "Davis",
      firstName: "David",
      age: 64,
      gender: "Female",
      RegNo: "REG657",
      country: "UK",
      receiptNo: "REC80",
      address: "877 Elm St, City",
      oppCharges: 101,
      gstCharges: 34,
      OTTCharges: 15,
      totalAmount: 150,
      status: "Paid",
      JourneyDate: "2024-05-06",
    },
    {
      lastName: "Johnson",
      firstName: "Michael",
      age: 21,
      gender: "Male",
      RegNo: "REG581",
      country: "USA",
      receiptNo: "REC350",
      address: "9 Elm St, City",
      oppCharges: 215,
      gstCharges: 32,
      OTTCharges: 42,
      totalAmount: 289,
      status: "Cancelled",
      JourneyDate: "2023-12-13",
    },
    {
      lastName: "Brown",
      firstName: "John",
      age: 38,
      gender: "Male",
      RegNo: "REG801",
      country: "Australia",
      receiptNo: "REC277",
      address: "7 Elm St, City",
      oppCharges: 101,
      gstCharges: 30,
      OTTCharges: 74,
      totalAmount: 205,
      status: "Paid",
      JourneyDate: "2024-06-02",
    },
    {
      lastName: "Johnson",
      firstName: "James",
      age: 41,
      gender: "Male",
      RegNo: "REG228",
      country: "Canada",
      receiptNo: "REC743",
      address: "748 Elm St, City",
      oppCharges: 150,
      gstCharges: 13,
      OTTCharges: 19,
      totalAmount: 182,
      status: "Paid",
      JourneyDate: "2023-09-05",
    },
    {
      lastName: "Jones",
      firstName: "John",
      age: 46,
      gender: "Female",
      RegNo: "REG420",
      country: "UK",
      receiptNo: "REC316",
      address: "5 Elm St, City",
      oppCharges: 68,
      gstCharges: 14,
      OTTCharges: 0,
      totalAmount: 82,
      status: "Cancelled",
      JourneyDate: "2023-02-08",
    },
    {
      lastName: "Miller",
      firstName: "Richard",
      age: 28,
      gender: "Male",
      RegNo: "REG683",
      country: "India",
      receiptNo: "REC28",
      address: "664 Elm St, City",
      oppCharges: 208,
      gstCharges: 29,
      OTTCharges: 65,
      totalAmount: 302,
      status: "Pending",
      JourneyDate: "2023-03-20",
    },
    {
      lastName: "Smith",
      firstName: "David",
      age: 68,
      gender: "Female",
      RegNo: "REG19",
      country: "Australia",
      receiptNo: "REC72",
      address: "439 Elm St, City",
      oppCharges: 71,
      gstCharges: 39,
      OTTCharges: 95,
      totalAmount: 205,
      status: "Cancelled",
      JourneyDate: "2023-08-05",
    },
    {
      lastName: "Davis",
      firstName: "Michael",
      age: 59,
      gender: "Male",
      RegNo: "REG294",
      country: "USA",
      receiptNo: "REC904",
      address: "374 Elm St, City",
      oppCharges: 167,
      gstCharges: 15,
      OTTCharges: 88,
      totalAmount: 270,
      status: "Paid",
      JourneyDate: "2024-05-30",
    },
    {
      lastName: "Davis",
      firstName: "Robert",
      age: 65,
      gender: "Male",
      RegNo: "REG592",
      country: "USA",
      receiptNo: "REC57",
      address: "379 Elm St, City",
      oppCharges: 75,
      gstCharges: 17,
      OTTCharges: 57,
      totalAmount: 149,
      status: "Cancelled",
      JourneyDate: "2023-01-21",
    },
    {
      lastName: "Williams",
      firstName: "John",
      age: 47,
      gender: "Female",
      RegNo: "REG906",
      country: "UK",
      receiptNo: "REC496",
      address: "141 Elm St, City",
      oppCharges: 68,
      gstCharges: 15,
      OTTCharges: 32,
      totalAmount: 115,
      status: "Cancelled",
      JourneyDate: "2024-06-20",
    },
    {
      lastName: "Davis",
      firstName: "Michael",
      age: 29,
      gender: "Female",
      RegNo: "REG413",
      country: "Canada",
      receiptNo: "REC737",
      address: "470 Elm St, City",
      oppCharges: 74,
      gstCharges: 15,
      OTTCharges: 54,
      totalAmount: 143,
      status: "Pending",
      JourneyDate: "2023-07-14",
    },
    {
      lastName: "Jones",
      firstName: "Robert",
      age: 30,
      gender: "Female",
      RegNo: "REG745",
      country: "USA",
      receiptNo: "REC375",
      address: "337 Elm St, City",
      oppCharges: 214,
      gstCharges: 33,
      OTTCharges: 17,
      totalAmount: 264,
      status: "Cancelled",
      JourneyDate: "2024-02-04",
    },
    {
      lastName: "Davis",
      firstName: "Richard",
      age: 53,
      gender: "Male",
      RegNo: "REG863",
      country: "Canada",
      receiptNo: "REC846",
      address: "341 Elm St, City",
      oppCharges: 141,
      gstCharges: 36,
      OTTCharges: 60,
      totalAmount: 237,
      status: "Cancelled",
      JourneyDate: "2023-06-03",
    },
    {
      lastName: "Brown",
      firstName: "James",
      age: 37,
      gender: "Male",
      RegNo: "REG160",
      country: "India",
      receiptNo: "REC626",
      address: "905 Elm St, City",
      oppCharges: 203,
      gstCharges: 20,
      OTTCharges: 82,
      totalAmount: 305,
      status: "Cancelled",
      JourneyDate: "2023-03-27",
    },
    {
      lastName: "Davis",
      firstName: "David",
      age: 65,
      gender: "Female",
      RegNo: "REG56",
      country: "UK",
      receiptNo: "REC574",
      address: "583 Elm St, City",
      oppCharges: 102,
      gstCharges: 18,
      OTTCharges: 9,
      totalAmount: 129,
      status: "Pending",
      JourneyDate: "2023-09-08",
    },
    {
      lastName: "Brown",
      firstName: "James",
      age: 48,
      gender: "Female",
      RegNo: "REG182",
      country: "Canada",
      receiptNo: "REC610",
      address: "927 Elm St, City",
      oppCharges: 181,
      gstCharges: 21,
      OTTCharges: 85,
      totalAmount: 287,
      status: "Paid",
      JourneyDate: "2023-01-17",
    },
    {
      lastName: "Davis",
      firstName: "James",
      age: 49,
      gender: "Female",
      RegNo: "REG627",
      country: "Canada",
      receiptNo: "REC641",
      address: "586 Elm St, City",
      oppCharges: 67,
      gstCharges: 30,
      OTTCharges: 40,
      totalAmount: 137,
      status: "Pending",
      JourneyDate: "2024-07-08",
    },
    {
      lastName: "Miller",
      firstName: "William",
      age: 61,
      gender: "Female",
      RegNo: "REG513",
      country: "Australia",
      receiptNo: "REC960",
      address: "972 Elm St, City",
      oppCharges: 69,
      gstCharges: 39,
      OTTCharges: 66,
      totalAmount: 174,
      status: "Pending",
      JourneyDate: "2024-01-24",
    },
    {
      lastName: "Davis",
      firstName: "Robert",
      age: 37,
      gender: "Female",
      RegNo: "REG10",
      country: "Australia",
      receiptNo: "REC239",
      address: "545 Elm St, City",
      oppCharges: 223,
      gstCharges: 35,
      OTTCharges: 87,
      totalAmount: 345,
      status: "Pending",
      JourneyDate: "2023-09-03",
    },
    {
      lastName: "Johnson",
      firstName: "Richard",
      age: 48,
      gender: "Male",
      RegNo: "REG681",
      country: "USA",
      receiptNo: "REC628",
      address: "985 Elm St, City",
      oppCharges: 176,
      gstCharges: 34,
      OTTCharges: 89,
      totalAmount: 299,
      status: "Pending",
      JourneyDate: "2023-11-22",
    },
    {
      lastName: "Davis",
      firstName: "John",
      age: 23,
      gender: "Male",
      RegNo: "REG224",
      country: "India",
      receiptNo: "REC925",
      address: "2 Elm St, City",
      oppCharges: 147,
      gstCharges: 10,
      OTTCharges: 26,
      totalAmount: 183,
      status: "Cancelled",
      JourneyDate: "2023-03-06",
    },
    {
      lastName: "Davis",
      firstName: "James",
      age: 30,
      gender: "Female",
      RegNo: "REG432",
      country: "Canada",
      receiptNo: "REC594",
      address: "502 Elm St, City",
      oppCharges: 241,
      gstCharges: 15,
      OTTCharges: 41,
      totalAmount: 297,
      status: "Paid",
      JourneyDate: "2023-01-07",
    },
    {
      lastName: "Miller",
      firstName: "Michael",
      age: 43,
      gender: "Female",
      RegNo: "REG32",
      country: "India",
      receiptNo: "REC157",
      address: "563 Elm St, City",
      oppCharges: 224,
      gstCharges: 14,
      OTTCharges: 68,
      totalAmount: 306,
      status: "Pending",
      JourneyDate: "2023-01-09",
    },
    {
      lastName: "Jones",
      firstName: "John",
      age: 62,
      gender: "Female",
      RegNo: "REG770",
      country: "UK",
      receiptNo: "REC983",
      address: "627 Elm St, City",
      oppCharges: 177,
      gstCharges: 27,
      OTTCharges: 60,
      totalAmount: 264,
      status: "Pending",
      JourneyDate: "2024-05-20",
    },
    {
      lastName: "Johnson",
      firstName: "James",
      age: 62,
      gender: "Male",
      RegNo: "REG862",
      country: "UK",
      receiptNo: "REC359",
      address: "414 Elm St, City",
      oppCharges: 60,
      gstCharges: 30,
      OTTCharges: 18,
      totalAmount: 108,
      status: "Cancelled",
      JourneyDate: "2023-12-09",
    },
    {
      lastName: "Johnson",
      firstName: "John",
      age: 59,
      gender: "Female",
      RegNo: "REG327",
      country: "Canada",
      receiptNo: "REC141",
      address: "823 Elm St, City",
      oppCharges: 63,
      gstCharges: 13,
      OTTCharges: 36,
      totalAmount: 112,
      status: "Paid",
      JourneyDate: "2024-03-16",
    },
    {
      lastName: "Brown",
      firstName: "David",
      age: 35,
      gender: "Female",
      RegNo: "REG357",
      country: "India",
      receiptNo: "REC826",
      address: "165 Elm St, City",
      oppCharges: 123,
      gstCharges: 35,
      OTTCharges: 6,
      totalAmount: 164,
      status: "Pending",
      JourneyDate: "2023-11-01",
    },
    {
      lastName: "Brown",
      firstName: "David",
      age: 29,
      gender: "Male",
      RegNo: "REG272",
      country: "India",
      receiptNo: "REC768",
      address: "140 Elm St, City",
      oppCharges: 64,
      gstCharges: 29,
      OTTCharges: 75,
      totalAmount: 168,
      status: "Cancelled",
      JourneyDate: "2023-05-06",
    },
    {
      lastName: "Brown",
      firstName: "Richard",
      age: 60,
      gender: "Male",
      RegNo: "REG504",
      country: "India",
      receiptNo: "REC572",
      address: "731 Elm St, City",
      oppCharges: 188,
      gstCharges: 21,
      OTTCharges: 73,
      totalAmount: 282,
      status: "Cancelled",
      JourneyDate: "2024-04-21",
    },
    {
      lastName: "Smith",
      firstName: "Richard",
      age: 46,
      gender: "Female",
      RegNo: "REG536",
      country: "USA",
      receiptNo: "REC656",
      address: "818 Elm St, City",
      oppCharges: 175,
      gstCharges: 24,
      OTTCharges: 81,
      totalAmount: 280,
      status: "Paid",
      JourneyDate: "2024-05-27",
    },
    {
      lastName: "Jones",
      firstName: "David",
      age: 27,
      gender: "Male",
      RegNo: "REG571",
      country: "Australia",
      receiptNo: "REC76",
      address: "520 Elm St, City",
      oppCharges: 57,
      gstCharges: 20,
      OTTCharges: 42,
      totalAmount: 119,
      status: "Pending",
      JourneyDate: "2024-04-11",
    },
    {
      lastName: "Miller",
      firstName: "Michael",
      age: 27,
      gender: "Female",
      RegNo: "REG9",
      country: "Australia",
      receiptNo: "REC788",
      address: "596 Elm St, City",
      oppCharges: 197,
      gstCharges: 38,
      OTTCharges: 18,
      totalAmount: 253,
      status: "Cancelled",
      JourneyDate: "2024-03-12",
    },
    {
      lastName: "Williams",
      firstName: "Michael",
      age: 25,
      gender: "Female",
      RegNo: "REG740",
      country: "USA",
      receiptNo: "REC556",
      address: "634 Elm St, City",
      oppCharges: 157,
      gstCharges: 38,
      OTTCharges: 53,
      totalAmount: 248,
      status: "Cancelled",
      JourneyDate: "2023-05-02",
    },
    {
      lastName: "Smith",
      firstName: "Richard",
      age: 68,
      gender: "Female",
      RegNo: "REG442",
      country: "UK",
      receiptNo: "REC410",
      address: "681 Elm St, City",
      oppCharges: 209,
      gstCharges: 11,
      OTTCharges: 26,
      totalAmount: 246,
      status: "Paid",
      JourneyDate: "2024-06-25",
    },
    {
      lastName: "Williams",
      firstName: "Richard",
      age: 22,
      gender: "Male",
      RegNo: "REG216",
      country: "India",
      receiptNo: "REC558",
      address: "112 Elm St, City",
      oppCharges: 96,
      gstCharges: 24,
      OTTCharges: 86,
      totalAmount: 206,
      status: "Pending",
      JourneyDate: "2023-10-23",
    },
    {
      lastName: "Smith",
      firstName: "John",
      age: 45,
      gender: "Female",
      RegNo: "REG429",
      country: "Australia",
      receiptNo: "REC334",
      address: "362 Elm St, City",
      oppCharges: 90,
      gstCharges: 39,
      OTTCharges: 75,
      totalAmount: 204,
      status: "Cancelled",
      JourneyDate: "2024-01-11",
    },
    {
      lastName: "Brown",
      firstName: "William",
      age: 61,
      gender: "Female",
      RegNo: "REG977",
      country: "UK",
      receiptNo: "REC234",
      address: "764 Elm St, City",
      oppCharges: 187,
      gstCharges: 33,
      OTTCharges: 9,
      totalAmount: 229,
      status: "Paid",
      JourneyDate: "2024-07-09",
    },
    {
      lastName: "Williams",
      firstName: "David",
      age: 42,
      gender: "Male",
      RegNo: "REG935",
      country: "Australia",
      receiptNo: "REC369",
      address: "350 Elm St, City",
      oppCharges: 229,
      gstCharges: 29,
      OTTCharges: 27,
      totalAmount: 285,
      status: "Pending",
      JourneyDate: "2023-06-23",
    },
    {
      lastName: "Jones",
      firstName: "John",
      age: 31,
      gender: "Male",
      RegNo: "REG869",
      country: "India",
      receiptNo: "REC402",
      address: "304 Elm St, City",
      oppCharges: 114,
      gstCharges: 37,
      OTTCharges: 51,
      totalAmount: 202,
      status: "Paid",
      JourneyDate: "2023-12-26",
    },
    {
      lastName: "Miller",
      firstName: "James",
      age: 58,
      gender: "Female",
      RegNo: "REG348",
      country: "Canada",
      receiptNo: "REC179",
      address: "130 Elm St, City",
      oppCharges: 135,
      gstCharges: 10,
      OTTCharges: 32,
      totalAmount: 177,
      status: "Pending",
      JourneyDate: "2023-06-29",
    },
    {
      lastName: "Davis",
      firstName: "Michael",
      age: 29,
      gender: "Female",
      RegNo: "REG320",
      country: "UK",
      receiptNo: "REC128",
      address: "314 Elm St, City",
      oppCharges: 108,
      gstCharges: 10,
      OTTCharges: 22,
      totalAmount: 140,
      status: "Paid",
      JourneyDate: "2023-06-16",
    },
    {
      lastName: "Davis",
      firstName: "Richard",
      age: 31,
      gender: "Male",
      RegNo: "REG284",
      country: "Australia",
      receiptNo: "REC930",
      address: "533 Elm St, City",
      oppCharges: 117,
      gstCharges: 19,
      OTTCharges: 52,
      totalAmount: 188,
      status: "Paid",
      JourneyDate: "2023-12-20",
    },
    {
      lastName: "Miller",
      firstName: "William",
      age: 51,
      gender: "Male",
      RegNo: "REG721",
      country: "USA",
      receiptNo: "REC57",
      address: "592 Elm St, City",
      oppCharges: 77,
      gstCharges: 15,
      OTTCharges: 7,
      totalAmount: 99,
      status: "Pending",
      JourneyDate: "2023-06-08",
    },
    {
      lastName: "Miller",
      firstName: "John",
      age: 61,
      gender: "Female",
      RegNo: "REG500",
      country: "Canada",
      receiptNo: "REC178",
      address: "84 Elm St, City",
      oppCharges: 173,
      gstCharges: 29,
      OTTCharges: 5,
      totalAmount: 207,
      status: "Paid",
      JourneyDate: "2024-03-19",
    },
    {
      lastName: "Smith",
      firstName: "William",
      age: 27,
      gender: "Male",
      RegNo: "REG413",
      country: "Australia",
      receiptNo: "REC455",
      address: "788 Elm St, City",
      oppCharges: 142,
      gstCharges: 18,
      OTTCharges: 43,
      totalAmount: 203,
      status: "Paid",
      JourneyDate: "2023-04-14",
    },
    {
      lastName: "Smith",
      firstName: "Robert",
      age: 27,
      gender: "Female",
      RegNo: "REG805",
      country: "UK",
      receiptNo: "REC869",
      address: "530 Elm St, City",
      oppCharges: 201,
      gstCharges: 29,
      OTTCharges: 69,
      totalAmount: 299,
      status: "Paid",
      JourneyDate: "2023-01-09",
    },
    {
      lastName: "Williams",
      firstName: "Richard",
      age: 43,
      gender: "Female",
      RegNo: "REG501",
      country: "Australia",
      receiptNo: "REC542",
      address: "640 Elm St, City",
      oppCharges: 215,
      gstCharges: 14,
      OTTCharges: 2,
      totalAmount: 231,
      status: "Cancelled",
      JourneyDate: "2023-09-26",
    },
    {
      lastName: "Smith",
      firstName: "Michael",
      age: 56,
      gender: "Female",
      RegNo: "REG906",
      country: "USA",
      receiptNo: "REC200",
      address: "841 Elm St, City",
      oppCharges: 197,
      gstCharges: 22,
      OTTCharges: 23,
      totalAmount: 242,
      status: "Pending",
      JourneyDate: "2023-01-07",
    },
    {
      lastName: "Davis",
      firstName: "Michael",
      age: 23,
      gender: "Female",
      RegNo: "REG653",
      country: "India",
      receiptNo: "REC821",
      address: "793 Elm St, City",
      oppCharges: 194,
      gstCharges: 32,
      OTTCharges: 1,
      totalAmount: 227,
      status: "Pending",
      JourneyDate: "2023-01-25",
    },
    {
      lastName: "Williams",
      firstName: "Robert",
      age: 22,
      gender: "Male",
      RegNo: "REG926",
      country: "Australia",
      receiptNo: "REC261",
      address: "193 Elm St, City",
      oppCharges: 60,
      gstCharges: 39,
      OTTCharges: 87,
      totalAmount: 186,
      status: "Paid",
      JourneyDate: "2024-02-22",
    },
    {
      lastName: "Jones",
      firstName: "Robert",
      age: 32,
      gender: "Female",
      RegNo: "REG279",
      country: "India",
      receiptNo: "REC544",
      address: "255 Elm St, City",
      oppCharges: 117,
      gstCharges: 32,
      OTTCharges: 32,
      totalAmount: 181,
      status: "Cancelled",
      JourneyDate: "2023-10-29",
    },
    {
      lastName: "Miller",
      firstName: "William",
      age: 35,
      gender: "Male",
      RegNo: "REG445",
      country: "India",
      receiptNo: "REC86",
      address: "424 Elm St, City",
      oppCharges: 100,
      gstCharges: 10,
      OTTCharges: 53,
      totalAmount: 163,
      status: "Paid",
      JourneyDate: "2024-04-18",
    },
    {
      lastName: "Johnson",
      firstName: "John",
      age: 65,
      gender: "Female",
      RegNo: "REG572",
      country: "UK",
      receiptNo: "REC223",
      address: "146 Elm St, City",
      oppCharges: 88,
      gstCharges: 37,
      OTTCharges: 23,
      totalAmount: 148,
      status: "Pending",
      JourneyDate: "2023-02-25",
    },
    {
      lastName: "Jones",
      firstName: "Michael",
      age: 29,
      gender: "Female",
      RegNo: "REG220",
      country: "Canada",
      receiptNo: "REC481",
      address: "340 Elm St, City",
      oppCharges: 78,
      gstCharges: 27,
      OTTCharges: 90,
      totalAmount: 195,
      status: "Cancelled",
      JourneyDate: "2023-09-12",
    },
    {
      lastName: "Johnson",
      firstName: "David",
      age: 63,
      gender: "Female",
      RegNo: "REG553",
      country: "Australia",
      receiptNo: "REC168",
      address: "93 Elm St, City",
      oppCharges: 227,
      gstCharges: 30,
      OTTCharges: 55,
      totalAmount: 312,
      status: "Cancelled",
      JourneyDate: "2024-05-30",
    },
    {
      lastName: "Brown",
      firstName: "John",
      age: 61,
      gender: "Male",
      RegNo: "REG796",
      country: "Canada",
      receiptNo: "REC864",
      address: "63 Elm St, City",
      oppCharges: 56,
      gstCharges: 13,
      OTTCharges: 94,
      totalAmount: 163,
      status: "Cancelled",
      JourneyDate: "2023-02-15",
    },
    {
      lastName: "Johnson",
      firstName: "David",
      age: 44,
      gender: "Male",
      RegNo: "REG547",
      country: "USA",
      receiptNo: "REC521",
      address: "130 Elm St, City",
      oppCharges: 153,
      gstCharges: 10,
      OTTCharges: 23,
      totalAmount: 186,
      status: "Pending",
      JourneyDate: "2023-11-24",
    },
    {
      lastName: "Smith",
      firstName: "John",
      age: 26,
      gender: "Male",
      RegNo: "REG229",
      country: "India",
      receiptNo: "REC184",
      address: "815 Elm St, City",
      oppCharges: 77,
      gstCharges: 37,
      OTTCharges: 8,
      totalAmount: 122,
      status: "Paid",
      JourneyDate: "2024-07-02",
    },
    {
      lastName: "Williams",
      firstName: "James",
      age: 40,
      gender: "Female",
      RegNo: "REG287",
      country: "India",
      receiptNo: "REC461",
      address: "160 Elm St, City",
      oppCharges: 175,
      gstCharges: 17,
      OTTCharges: 71,
      totalAmount: 263,
      status: "Pending",
      JourneyDate: "2023-08-09",
    },
    {
      lastName: "Smith",
      firstName: "Michael",
      age: 49,
      gender: "Female",
      RegNo: "REG537",
      country: "UK",
      receiptNo: "REC115",
      address: "421 Elm St, City",
      oppCharges: 143,
      gstCharges: 10,
      OTTCharges: 43,
      totalAmount: 196,
      status: "Cancelled",
      JourneyDate: "2024-05-19",
    },
    {
      lastName: "Williams",
      firstName: "Richard",
      age: 64,
      gender: "Male",
      RegNo: "REG707",
      country: "Australia",
      receiptNo: "REC689",
      address: "397 Elm St, City",
      oppCharges: 220,
      gstCharges: 29,
      OTTCharges: 19,
      totalAmount: 268,
      status: "Paid",
      JourneyDate: "2023-01-27",
    },
    {
      lastName: "Johnson",
      firstName: "David",
      age: 62,
      gender: "Female",
      RegNo: "REG371",
      country: "India",
      receiptNo: "REC81",
      address: "749 Elm St, City",
      oppCharges: 126,
      gstCharges: 37,
      OTTCharges: 45,
      totalAmount: 208,
      status: "Cancelled",
      JourneyDate: "2024-04-18",
    },
    {
      lastName: "Brown",
      firstName: "David",
      age: 31,
      gender: "Female",
      RegNo: "REG430",
      country: "Australia",
      receiptNo: "REC83",
      address: "559 Elm St, City",
      oppCharges: 249,
      gstCharges: 34,
      OTTCharges: 28,
      totalAmount: 311,
      status: "Cancelled",
      JourneyDate: "2023-11-14",
    },
    {
      lastName: "Smith",
      firstName: "Michael",
      age: 61,
      gender: "Male",
      RegNo: "REG941",
      country: "UK",
      receiptNo: "REC338",
      address: "576 Elm St, City",
      oppCharges: 122,
      gstCharges: 34,
      OTTCharges: 95,
      totalAmount: 251,
      status: "Paid",
      JourneyDate: "2023-03-30",
    },
    {
      lastName: "Jones",
      firstName: "Richard",
      age: 51,
      gender: "Male",
      RegNo: "REG868",
      country: "UK",
      receiptNo: "REC136",
      address: "934 Elm St, City",
      oppCharges: 94,
      gstCharges: 15,
      OTTCharges: 11,
      totalAmount: 120,
      status: "Pending",
      JourneyDate: "2023-08-27",
    },
    {
      lastName: "Brown",
      firstName: "John",
      age: 33,
      gender: "Male",
      RegNo: "REG159",
      country: "USA",
      receiptNo: "REC846",
      address: "393 Elm St, City",
      oppCharges: 115,
      gstCharges: 13,
      OTTCharges: 93,
      totalAmount: 221,
      status: "Paid",
      JourneyDate: "2023-04-23",
    },
    {
      lastName: "Davis",
      firstName: "Robert",
      age: 41,
      gender: "Female",
      RegNo: "REG908",
      country: "Australia",
      receiptNo: "REC185",
      address: "321 Elm St, City",
      oppCharges: 139,
      gstCharges: 20,
      OTTCharges: 30,
      totalAmount: 189,
      status: "Cancelled",
      JourneyDate: "2023-08-24",
    },
    {
      lastName: "Davis",
      firstName: "Michael",
      age: 52,
      gender: "Female",
      RegNo: "REG163",
      country: "Australia",
      receiptNo: "REC936",
      address: "219 Elm St, City",
      oppCharges: 230,
      gstCharges: 16,
      OTTCharges: 79,
      totalAmount: 325,
      status: "Pending",
      JourneyDate: "2023-10-27",
    },
    {
      lastName: "Jones",
      firstName: "John",
      age: 56,
      gender: "Male",
      RegNo: "REG633",
      country: "Canada",
      receiptNo: "REC774",
      address: "647 Elm St, City",
      oppCharges: 152,
      gstCharges: 26,
      OTTCharges: 43,
      totalAmount: 221,
      status: "Cancelled",
      JourneyDate: "2023-10-20",
    },
    {
      lastName: "Brown",
      firstName: "William",
      age: 46,
      gender: "Male",
      RegNo: "REG951",
      country: "India",
      receiptNo: "REC369",
      address: "26 Elm St, City",
      oppCharges: 207,
      gstCharges: 38,
      OTTCharges: 53,
      totalAmount: 298,
      status: "Pending",
      JourneyDate: "2024-02-09",
    },
    {
      lastName: "Davis",
      firstName: "Michael",
      age: 47,
      gender: "Male",
      RegNo: "REG931",
      country: "Australia",
      receiptNo: "REC528",
      address: "299 Elm St, City",
      oppCharges: 90,
      gstCharges: 23,
      OTTCharges: 94,
      totalAmount: 207,
      status: "Paid",
      JourneyDate: "2023-02-27",
    },
    {
      lastName: "Smith",
      firstName: "Michael",
      age: 21,
      gender: "Female",
      RegNo: "REG569",
      country: "USA",
      receiptNo: "REC302",
      address: "693 Elm St, City",
      oppCharges: 156,
      gstCharges: 19,
      OTTCharges: 42,
      totalAmount: 217,
      status: "Pending",
      JourneyDate: "2024-03-25",
    },
    {
      lastName: "Williams",
      firstName: "John",
      age: 35,
      gender: "Male",
      RegNo: "REG272",
      country: "Canada",
      receiptNo: "REC815",
      address: "646 Elm St, City",
      oppCharges: 138,
      gstCharges: 11,
      OTTCharges: 28,
      totalAmount: 177,
      status: "Paid",
      JourneyDate: "2023-07-25",
    },
    {
      lastName: "Miller",
      firstName: "David",
      age: 58,
      gender: "Female",
      RegNo: "REG104",
      country: "Canada",
      receiptNo: "REC225",
      address: "893 Elm St, City",
      oppCharges: 169,
      gstCharges: 22,
      OTTCharges: 99,
      totalAmount: 290,
      status: "Cancelled",
      JourneyDate: "2024-07-12",
    },
    {
      lastName: "Williams",
      firstName: "Richard",
      age: 44,
      gender: "Female",
      RegNo: "REG778",
      country: "India",
      receiptNo: "REC334",
      address: "674 Elm St, City",
      oppCharges: 181,
      gstCharges: 24,
      OTTCharges: 63,
      totalAmount: 268,
      status: "Cancelled",
      JourneyDate: "2023-11-25",
    },
    {
      lastName: "Smith",
      firstName: "David",
      age: 60,
      gender: "Female",
      RegNo: "REG672",
      country: "USA",
      receiptNo: "REC520",
      address: "344 Elm St, City",
      oppCharges: 239,
      gstCharges: 15,
      OTTCharges: 79,
      totalAmount: 333,
      status: "Pending",
      JourneyDate: "2023-06-19",
    },
    {
      lastName: "Davis",
      firstName: "Robert",
      age: 33,
      gender: "Male",
      RegNo: "REG140",
      country: "UK",
      receiptNo: "REC535",
      address: "395 Elm St, City",
      oppCharges: 243,
      gstCharges: 12,
      OTTCharges: 77,
      totalAmount: 332,
      status: "Paid",
      JourneyDate: "2023-02-09",
    },
    {
      lastName: "Smith",
      firstName: "John",
      age: 45,
      gender: "Female",
      RegNo: "REG408",
      country: "Australia",
      receiptNo: "REC968",
      address: "149 Elm St, City",
      oppCharges: 118,
      gstCharges: 17,
      OTTCharges: 96,
      totalAmount: 231,
      status: "Pending",
      JourneyDate: "2023-06-24",
    },
    {
      lastName: "Davis",
      firstName: "Richard",
      age: 64,
      gender: "Male",
      RegNo: "REG126",
      country: "India",
      receiptNo: "REC672",
      address: "95 Elm St, City",
      oppCharges: 114,
      gstCharges: 39,
      OTTCharges: 99,
      totalAmount: 252,
      status: "Cancelled",
      JourneyDate: "2024-07-06",
    },
    {
      lastName: "Williams",
      firstName: "John",
      age: 57,
      gender: "Female",
      RegNo: "REG796",
      country: "Australia",
      receiptNo: "REC465",
      address: "754 Elm St, City",
      oppCharges: 233,
      gstCharges: 32,
      OTTCharges: 51,
      totalAmount: 316,
      status: "Pending",
      JourneyDate: "2023-07-30",
    },
    {
      lastName: "Davis",
      firstName: "Robert",
      age: 35,
      gender: "Female",
      RegNo: "REG54",
      country: "UK",
      receiptNo: "REC424",
      address: "906 Elm St, City",
      oppCharges: 91,
      gstCharges: 14,
      OTTCharges: 30,
      totalAmount: 135,
      status: "Pending",
      JourneyDate: "2024-02-26",
    },
    {
      lastName: "Smith",
      firstName: "James",
      age: 20,
      gender: "Male",
      RegNo: "REG242",
      country: "UK",
      receiptNo: "REC944",
      address: "892 Elm St, City",
      oppCharges: 133,
      gstCharges: 30,
      OTTCharges: 92,
      totalAmount: 255,
      status: "Pending",
      JourneyDate: "2023-01-31",
    },
    {
      lastName: "Williams",
      firstName: "Richard",
      age: 64,
      gender: "Female",
      RegNo: "REG483",
      country: "USA",
      receiptNo: "REC406",
      address: "341 Elm St, City",
      oppCharges: 132,
      gstCharges: 15,
      OTTCharges: 10,
      totalAmount: 157,
      status: "Paid",
      JourneyDate: "2023-10-11",
    },
    {
      lastName: "Davis",
      firstName: "David",
      age: 66,
      gender: "Male",
      RegNo: "REG872",
      country: "India",
      receiptNo: "REC837",
      address: "890 Elm St, City",
      oppCharges: 195,
      gstCharges: 34,
      OTTCharges: 85,
      totalAmount: 314,
      status: "Pending",
      JourneyDate: "2023-03-28",
    },
    {
      lastName: "Davis",
      firstName: "John",
      age: 35,
      gender: "Male",
      RegNo: "REG713",
      country: "India",
      receiptNo: "REC318",
      address: "776 Elm St, City",
      oppCharges: 186,
      gstCharges: 14,
      OTTCharges: 64,
      totalAmount: 264,
      status: "Cancelled",
      JourneyDate: "2024-06-03",
    },
    {
      lastName: "Jones",
      firstName: "David",
      age: 23,
      gender: "Male",
      RegNo: "REG425",
      country: "India",
      receiptNo: "REC446",
      address: "412 Elm St, City",
      oppCharges: 80,
      gstCharges: 24,
      OTTCharges: 16,
      totalAmount: 120,
      status: "Cancelled",
      JourneyDate: "2024-02-01",
    },
    {
      lastName: "Jones",
      firstName: "Michael",
      age: 55,
      gender: "Male",
      RegNo: "REG763",
      country: "India",
      receiptNo: "REC2",
      address: "772 Elm St, City",
      oppCharges: 239,
      gstCharges: 39,
      OTTCharges: 26,
      totalAmount: 304,
      status: "Pending",
      JourneyDate: "2023-07-30",
    },
    {
      lastName: "Brown",
      firstName: "Robert",
      age: 36,
      gender: "Female",
      RegNo: "REG457",
      country: "USA",
      receiptNo: "REC490",
      address: "400 Elm St, City",
      oppCharges: 101,
      gstCharges: 19,
      OTTCharges: 8,
      totalAmount: 128,
      status: "Cancelled",
      JourneyDate: "2023-04-23",
    },
    {
      lastName: "Brown",
      firstName: "Robert",
      age: 69,
      gender: "Male",
      RegNo: "REG330",
      country: "UK",
      receiptNo: "REC50",
      address: "497 Elm St, City",
      oppCharges: 119,
      gstCharges: 37,
      OTTCharges: 93,
      totalAmount: 249,
      status: "Cancelled",
      JourneyDate: "2024-03-16",
    },
    {
      lastName: "Davis",
      firstName: "Robert",
      age: 65,
      gender: "Female",
      RegNo: "REG54",
      country: "India",
      receiptNo: "REC136",
      address: "855 Elm St, City",
      oppCharges: 77,
      gstCharges: 38,
      OTTCharges: 18,
      totalAmount: 133,
      status: "Paid",
      JourneyDate: "2023-07-24",
    },
    {
      lastName: "Johnson",
      firstName: "David",
      age: 67,
      gender: "Female",
      RegNo: "REG661",
      country: "Canada",
      receiptNo: "REC759",
      address: "370 Elm St, City",
      oppCharges: 151,
      gstCharges: 28,
      OTTCharges: 96,
      totalAmount: 275,
      status: "Pending",
      JourneyDate: "2023-10-11",
    },
    {
      lastName: "Johnson",
      firstName: "John",
      age: 21,
      gender: "Male",
      RegNo: "REG80",
      country: "Australia",
      receiptNo: "REC881",
      address: "813 Elm St, City",
      oppCharges: 215,
      gstCharges: 11,
      OTTCharges: 59,
      totalAmount: 285,
      status: "Paid",
      JourneyDate: "2023-12-10",
    },
    {
      lastName: "Johnson",
      firstName: "William",
      age: 47,
      gender: "Male",
      RegNo: "REG535",
      country: "Australia",
      receiptNo: "REC963",
      address: "894 Elm St, City",
      oppCharges: 166,
      gstCharges: 13,
      OTTCharges: 59,
      totalAmount: 238,
      status: "Cancelled",
      JourneyDate: "2024-03-03",
    },
    {
      lastName: "Brown",
      firstName: "David",
      age: 55,
      gender: "Male",
      RegNo: "REG506",
      country: "Australia",
      receiptNo: "REC600",
      address: "110 Elm St, City",
      oppCharges: 107,
      gstCharges: 32,
      OTTCharges: 32,
      totalAmount: 171,
      status: "Pending",
      JourneyDate: "2023-06-01",
    },
    {
      lastName: "Davis",
      firstName: "James",
      age: 25,
      gender: "Female",
      RegNo: "REG803",
      country: "Australia",
      receiptNo: "REC409",
      address: "375 Elm St, City",
      oppCharges: 167,
      gstCharges: 16,
      OTTCharges: 20,
      totalAmount: 203,
      status: "Paid",
      JourneyDate: "2024-04-25",
    },
    {
      lastName: "Davis",
      firstName: "Michael",
      age: 23,
      gender: "Female",
      RegNo: "REG706",
      country: "Canada",
      receiptNo: "REC129",
      address: "556 Elm St, City",
      oppCharges: 56,
      gstCharges: 24,
      OTTCharges: 59,
      totalAmount: 139,
      status: "Pending",
      JourneyDate: "2023-05-06",
    },
    {
      lastName: "Davis",
      firstName: "William",
      age: 23,
      gender: "Male",
      RegNo: "REG724",
      country: "India",
      receiptNo: "REC687",
      address: "549 Elm St, City",
      oppCharges: 186,
      gstCharges: 11,
      OTTCharges: 83,
      totalAmount: 280,
      status: "Paid",
      JourneyDate: "2023-08-14",
    },
    {
      lastName: "Miller",
      firstName: "William",
      age: 44,
      gender: "Female",
      RegNo: "REG47",
      country: "Australia",
      receiptNo: "REC76",
      address: "808 Elm St, City",
      oppCharges: 178,
      gstCharges: 10,
      OTTCharges: 51,
      totalAmount: 239,
      status: "Pending",
      JourneyDate: "2023-08-06",
    },
    {
      lastName: "Miller",
      firstName: "Richard",
      age: 57,
      gender: "Female",
      RegNo: "REG667",
      country: "USA",
      receiptNo: "REC234",
      address: "347 Elm St, City",
      oppCharges: 199,
      gstCharges: 39,
      OTTCharges: 11,
      totalAmount: 249,
      status: "Pending",
      JourneyDate: "2023-05-05",
    },
    {
      lastName: "Brown",
      firstName: "Michael",
      age: 58,
      gender: "Female",
      RegNo: "REG828",
      country: "Australia",
      receiptNo: "REC640",
      address: "12 Elm St, City",
      oppCharges: 162,
      gstCharges: 23,
      OTTCharges: 82,
      totalAmount: 267,
      status: "Paid",
      JourneyDate: "2023-01-24",
    },
    {
      lastName: "Davis",
      firstName: "Michael",
      age: 55,
      gender: "Male",
      RegNo: "REG169",
      country: "Canada",
      receiptNo: "REC81",
      address: "529 Elm St, City",
      oppCharges: 74,
      gstCharges: 11,
      OTTCharges: 69,
      totalAmount: 154,
      status: "Paid",
      JourneyDate: "2024-01-02",
    },
    {
      lastName: "Brown",
      firstName: "James",
      age: 39,
      gender: "Male",
      RegNo: "REG747",
      country: "USA",
      receiptNo: "REC406",
      address: "275 Elm St, City",
      oppCharges: 120,
      gstCharges: 11,
      OTTCharges: 29,
      totalAmount: 160,
      status: "Cancelled",
      JourneyDate: "2023-06-18",
    },
    {
      lastName: "Jones",
      firstName: "William",
      age: 38,
      gender: "Male",
      RegNo: "REG748",
      country: "Canada",
      receiptNo: "REC348",
      address: "592 Elm St, City",
      oppCharges: 118,
      gstCharges: 28,
      OTTCharges: 38,
      totalAmount: 184,
      status: "Paid",
      JourneyDate: "2023-02-26",
    },
    {
      lastName: "Brown",
      firstName: "William",
      age: 20,
      gender: "Male",
      RegNo: "REG338",
      country: "Canada",
      receiptNo: "REC874",
      address: "82 Elm St, City",
      oppCharges: 115,
      gstCharges: 13,
      OTTCharges: 72,
      totalAmount: 200,
      status: "Pending",
      JourneyDate: "2023-05-01",
    },
    {
      lastName: "Davis",
      firstName: "William",
      age: 27,
      gender: "Female",
      RegNo: "REG812",
      country: "Canada",
      receiptNo: "REC751",
      address: "453 Elm St, City",
      oppCharges: 215,
      gstCharges: 15,
      OTTCharges: 48,
      totalAmount: 278,
      status: "Pending",
      JourneyDate: "2023-02-24",
    },
    {
      lastName: "Davis",
      firstName: "William",
      age: 27,
      gender: "Male",
      RegNo: "REG584",
      country: "India",
      receiptNo: "REC776",
      address: "389 Elm St, City",
      oppCharges: 181,
      gstCharges: 33,
      OTTCharges: 16,
      totalAmount: 230,
      status: "Paid",
      JourneyDate: "2024-02-08",
    },
    {
      lastName: "Jones",
      firstName: "John",
      age: 49,
      gender: "Female",
      RegNo: "REG916",
      country: "Canada",
      receiptNo: "REC105",
      address: "791 Elm St, City",
      oppCharges: 174,
      gstCharges: 14,
      OTTCharges: 11,
      totalAmount: 199,
      status: "Paid",
      JourneyDate: "2023-06-04",
    },
    {
      lastName: "Brown",
      firstName: "John",
      age: 54,
      gender: "Female",
      RegNo: "REG990",
      country: "Australia",
      receiptNo: "REC818",
      address: "929 Elm St, City",
      oppCharges: 110,
      gstCharges: 30,
      OTTCharges: 22,
      totalAmount: 162,
      status: "Pending",
      JourneyDate: "2024-04-18",
    },
    {
      lastName: "Smith",
      firstName: "David",
      age: 25,
      gender: "Male",
      RegNo: "REG613",
      country: "USA",
      receiptNo: "REC378",
      address: "359 Elm St, City",
      oppCharges: 58,
      gstCharges: 35,
      OTTCharges: 34,
      totalAmount: 127,
      status: "Pending",
      JourneyDate: "2024-02-08",
    },
    {
      lastName: "Davis",
      firstName: "Richard",
      age: 50,
      gender: "Female",
      RegNo: "REG806",
      country: "India",
      receiptNo: "REC505",
      address: "318 Elm St, City",
      oppCharges: 231,
      gstCharges: 10,
      OTTCharges: 26,
      totalAmount: 267,
      status: "Cancelled",
      JourneyDate: "2024-03-14",
    },
    {
      lastName: "Johnson",
      firstName: "James",
      age: 32,
      gender: "Male",
      RegNo: "REG124",
      country: "USA",
      receiptNo: "REC189",
      address: "358 Elm St, City",
      oppCharges: 188,
      gstCharges: 12,
      OTTCharges: 96,
      totalAmount: 296,
      status: "Pending",
      JourneyDate: "2023-04-30",
    },
    {
      lastName: "Jones",
      firstName: "Robert",
      age: 50,
      gender: "Male",
      RegNo: "REG398",
      country: "Canada",
      receiptNo: "REC370",
      address: "329 Elm St, City",
      oppCharges: 237,
      gstCharges: 28,
      OTTCharges: 25,
      totalAmount: 290,
      status: "Cancelled",
      JourneyDate: "2023-06-12",
    },
    {
      lastName: "Johnson",
      firstName: "Michael",
      age: 69,
      gender: "Female",
      RegNo: "REG344",
      country: "UK",
      receiptNo: "REC809",
      address: "949 Elm St, City",
      oppCharges: 198,
      gstCharges: 32,
      OTTCharges: 23,
      totalAmount: 253,
      status: "Cancelled",
      JourneyDate: "2023-12-08",
    },
    {
      lastName: "Jones",
      firstName: "Robert",
      age: 45,
      gender: "Male",
      RegNo: "REG619",
      country: "USA",
      receiptNo: "REC358",
      address: "287 Elm St, City",
      oppCharges: 144,
      gstCharges: 27,
      OTTCharges: 96,
      totalAmount: 267,
      status: "Paid",
      JourneyDate: "2024-06-22",
    },
    {
      lastName: "Brown",
      firstName: "Robert",
      age: 52,
      gender: "Male",
      RegNo: "REG956",
      country: "Australia",
      receiptNo: "REC171",
      address: "29 Elm St, City",
      oppCharges: 134,
      gstCharges: 29,
      OTTCharges: 52,
      totalAmount: 215,
      status: "Cancelled",
      JourneyDate: "2023-02-22",
    },
    {
      lastName: "Johnson",
      firstName: "Michael",
      age: 55,
      gender: "Male",
      RegNo: "REG805",
      country: "India",
      receiptNo: "REC553",
      address: "645 Elm St, City",
      oppCharges: 224,
      gstCharges: 35,
      OTTCharges: 63,
      totalAmount: 322,
      status: "Paid",
      JourneyDate: "2024-05-25",
    },
    {
      lastName: "Johnson",
      firstName: "David",
      age: 36,
      gender: "Female",
      RegNo: "REG646",
      country: "India",
      receiptNo: "REC38",
      address: "234 Elm St, City",
      oppCharges: 71,
      gstCharges: 31,
      OTTCharges: 59,
      totalAmount: 161,
      status: "Pending",
      JourneyDate: "2024-02-16",
    },
    {
      lastName: "Miller",
      firstName: "John",
      age: 38,
      gender: "Female",
      RegNo: "REG30",
      country: "UK",
      receiptNo: "REC836",
      address: "919 Elm St, City",
      oppCharges: 227,
      gstCharges: 25,
      OTTCharges: 45,
      totalAmount: 297,
      status: "Cancelled",
      JourneyDate: "2023-07-09",
    },
    {
      lastName: "Williams",
      firstName: "Michael",
      age: 58,
      gender: "Female",
      RegNo: "REG478",
      country: "Australia",
      receiptNo: "REC144",
      address: "816 Elm St, City",
      oppCharges: 210,
      gstCharges: 32,
      OTTCharges: 0,
      totalAmount: 242,
      status: "Cancelled",
      JourneyDate: "2024-02-25",
    },
    {
      lastName: "Davis",
      firstName: "David",
      age: 64,
      gender: "Male",
      RegNo: "REG419",
      country: "Canada",
      receiptNo: "REC765",
      address: "408 Elm St, City",
      oppCharges: 194,
      gstCharges: 36,
      OTTCharges: 32,
      totalAmount: 262,
      status: "Paid",
      JourneyDate: "2023-05-14",
    },
    {
      lastName: "Smith",
      firstName: "James",
      age: 26,
      gender: "Male",
      RegNo: "REG372",
      country: "USA",
      receiptNo: "REC607",
      address: "212 Elm St, City",
      oppCharges: 232,
      gstCharges: 14,
      OTTCharges: 15,
      totalAmount: 261,
      status: "Cancelled",
      JourneyDate: "2023-05-26",
    },
    {
      lastName: "Jones",
      firstName: "John",
      age: 66,
      gender: "Male",
      RegNo: "REG651",
      country: "USA",
      receiptNo: "REC752",
      address: "41 Elm St, City",
      oppCharges: 227,
      gstCharges: 17,
      OTTCharges: 87,
      totalAmount: 331,
      status: "Pending",
      JourneyDate: "2023-04-23",
    },
    {
      lastName: "Jones",
      firstName: "James",
      age: 22,
      gender: "Female",
      RegNo: "REG27",
      country: "India",
      receiptNo: "REC29",
      address: "728 Elm St, City",
      oppCharges: 96,
      gstCharges: 22,
      OTTCharges: 70,
      totalAmount: 188,
      status: "Cancelled",
      JourneyDate: "2023-09-20",
    },
    {
      lastName: "Miller",
      firstName: "Michael",
      age: 59,
      gender: "Male",
      RegNo: "REG151",
      country: "USA",
      receiptNo: "REC990",
      address: "529 Elm St, City",
      oppCharges: 117,
      gstCharges: 23,
      OTTCharges: 80,
      totalAmount: 220,
      status: "Cancelled",
      JourneyDate: "2024-05-20",
    },
    {
      lastName: "Miller",
      firstName: "Richard",
      age: 49,
      gender: "Female",
      RegNo: "REG277",
      country: "USA",
      receiptNo: "REC867",
      address: "832 Elm St, City",
      oppCharges: 244,
      gstCharges: 39,
      OTTCharges: 90,
      totalAmount: 373,
      status: "Paid",
      JourneyDate: "2023-07-04",
    },
    {
      lastName: "Miller",
      firstName: "David",
      age: 33,
      gender: "Male",
      RegNo: "REG533",
      country: "Canada",
      receiptNo: "REC803",
      address: "700 Elm St, City",
      oppCharges: 202,
      gstCharges: 13,
      OTTCharges: 64,
      totalAmount: 279,
      status: "Cancelled",
      JourneyDate: "2024-03-14",
    },
    {
      lastName: "Miller",
      firstName: "David",
      age: 32,
      gender: "Male",
      RegNo: "REG793",
      country: "India",
      receiptNo: "REC749",
      address: "289 Elm St, City",
      oppCharges: 84,
      gstCharges: 24,
      OTTCharges: 52,
      totalAmount: 160,
      status: "Paid",
      JourneyDate: "2023-01-30",
    },
    {
      lastName: "Miller",
      firstName: "David",
      age: 36,
      gender: "Female",
      RegNo: "REG326",
      country: "Australia",
      receiptNo: "REC520",
      address: "694 Elm St, City",
      oppCharges: 176,
      gstCharges: 19,
      OTTCharges: 87,
      totalAmount: 282,
      status: "Pending",
      JourneyDate: "2023-04-10",
    },
    {
      lastName: "Jones",
      firstName: "Robert",
      age: 62,
      gender: "Female",
      RegNo: "REG256",
      country: "UK",
      receiptNo: "REC955",
      address: "862 Elm St, City",
      oppCharges: 152,
      gstCharges: 18,
      OTTCharges: 51,
      totalAmount: 221,
      status: "Cancelled",
      JourneyDate: "2023-04-02",
    },
    {
      lastName: "Johnson",
      firstName: "Robert",
      age: 41,
      gender: "Male",
      RegNo: "REG662",
      country: "USA",
      receiptNo: "REC142",
      address: "622 Elm St, City",
      oppCharges: 73,
      gstCharges: 34,
      OTTCharges: 34,
      totalAmount: 141,
      status: "Paid",
      JourneyDate: "2023-03-03",
    },
    {
      lastName: "Smith",
      firstName: "David",
      age: 29,
      gender: "Male",
      RegNo: "REG950",
      country: "UK",
      receiptNo: "REC16",
      address: "797 Elm St, City",
      oppCharges: 162,
      gstCharges: 35,
      OTTCharges: 61,
      totalAmount: 258,
      status: "Cancelled",
      JourneyDate: "2023-01-19",
    },
    {
      lastName: "Williams",
      firstName: "Robert",
      age: 56,
      gender: "Female",
      RegNo: "REG744",
      country: "USA",
      receiptNo: "REC377",
      address: "421 Elm St, City",
      oppCharges: 67,
      gstCharges: 37,
      OTTCharges: 60,
      totalAmount: 164,
      status: "Cancelled",
      JourneyDate: "2023-02-08",
    },
    {
      lastName: "Williams",
      firstName: "Michael",
      age: 23,
      gender: "Male",
      RegNo: "REG540",
      country: "USA",
      receiptNo: "REC231",
      address: "866 Elm St, City",
      oppCharges: 146,
      gstCharges: 29,
      OTTCharges: 5,
      totalAmount: 180,
      status: "Paid",
      JourneyDate: "2024-06-29",
    },
    {
      lastName: "Johnson",
      firstName: "Michael",
      age: 32,
      gender: "Male",
      RegNo: "REG375",
      country: "Australia",
      receiptNo: "REC894",
      address: "390 Elm St, City",
      oppCharges: 207,
      gstCharges: 26,
      OTTCharges: 6,
      totalAmount: 239,
      status: "Paid",
      JourneyDate: "2023-08-27",
    },
    {
      lastName: "Jones",
      firstName: "David",
      age: 28,
      gender: "Male",
      RegNo: "REG893",
      country: "India",
      receiptNo: "REC493",
      address: "672 Elm St, City",
      oppCharges: 122,
      gstCharges: 12,
      OTTCharges: 80,
      totalAmount: 214,
      status: "Paid",
      JourneyDate: "2023-08-19",
    },
    {
      lastName: "Johnson",
      firstName: "Robert",
      age: 54,
      gender: "Female",
      RegNo: "REG412",
      country: "Australia",
      receiptNo: "REC934",
      address: "768 Elm St, City",
      oppCharges: 214,
      gstCharges: 21,
      OTTCharges: 17,
      totalAmount: 252,
      status: "Paid",
      JourneyDate: "2023-06-27",
    },
    {
      lastName: "Miller",
      firstName: "Richard",
      age: 47,
      gender: "Male",
      RegNo: "REG342",
      country: "India",
      receiptNo: "REC432",
      address: "802 Elm St, City",
      oppCharges: 66,
      gstCharges: 27,
      OTTCharges: 75,
      totalAmount: 168,
      status: "Cancelled",
      JourneyDate: "2023-08-14",
    },
    {
      lastName: "Miller",
      firstName: "David",
      age: 49,
      gender: "Female",
      RegNo: "REG314",
      country: "USA",
      receiptNo: "REC411",
      address: "924 Elm St, City",
      oppCharges: 77,
      gstCharges: 21,
      OTTCharges: 61,
      totalAmount: 159,
      status: "Pending",
      JourneyDate: "2023-09-10",
    },
    {
      lastName: "Miller",
      firstName: "Michael",
      age: 23,
      gender: "Female",
      RegNo: "REG521",
      country: "India",
      receiptNo: "REC881",
      address: "657 Elm St, City",
      oppCharges: 57,
      gstCharges: 10,
      OTTCharges: 22,
      totalAmount: 89,
      status: "Pending",
      JourneyDate: "2024-02-13",
    },
    {
      lastName: "Davis",
      firstName: "David",
      age: 37,
      gender: "Female",
      RegNo: "REG870",
      country: "Canada",
      receiptNo: "REC704",
      address: "62 Elm St, City",
      oppCharges: 144,
      gstCharges: 29,
      OTTCharges: 75,
      totalAmount: 248,
      status: "Paid",
      JourneyDate: "2023-04-14",
    },
    {
      lastName: "Miller",
      firstName: "John",
      age: 32,
      gender: "Female",
      RegNo: "REG354",
      country: "Canada",
      receiptNo: "REC923",
      address: "378 Elm St, City",
      oppCharges: 141,
      gstCharges: 30,
      OTTCharges: 9,
      totalAmount: 180,
      status: "Paid",
      JourneyDate: "2024-06-02",
    },
    {
      lastName: "Brown",
      firstName: "Michael",
      age: 58,
      gender: "Male",
      RegNo: "REG535",
      country: "UK",
      receiptNo: "REC876",
      address: "813 Elm St, City",
      oppCharges: 143,
      gstCharges: 12,
      OTTCharges: 21,
      totalAmount: 176,
      status: "Pending",
      JourneyDate: "2024-02-26",
    },
    {
      lastName: "Brown",
      firstName: "Robert",
      age: 40,
      gender: "Male",
      RegNo: "REG338",
      country: "Australia",
      receiptNo: "REC865",
      address: "478 Elm St, City",
      oppCharges: 112,
      gstCharges: 19,
      OTTCharges: 33,
      totalAmount: 164,
      status: "Paid",
      JourneyDate: "2023-09-28",
    },
    {
      lastName: "Jones",
      firstName: "Michael",
      age: 27,
      gender: "Male",
      RegNo: "REG43",
      country: "UK",
      receiptNo: "REC928",
      address: "477 Elm St, City",
      oppCharges: 240,
      gstCharges: 38,
      OTTCharges: 24,
      totalAmount: 302,
      status: "Pending",
      JourneyDate: "2024-05-18",
    },
    {
      lastName: "Davis",
      firstName: "James",
      age: 64,
      gender: "Female",
      RegNo: "REG611",
      country: "USA",
      receiptNo: "REC659",
      address: "943 Elm St, City",
      oppCharges: 161,
      gstCharges: 20,
      OTTCharges: 68,
      totalAmount: 249,
      status: "Pending",
      JourneyDate: "2023-03-14",
    },
    {
      lastName: "Johnson",
      firstName: "John",
      age: 66,
      gender: "Male",
      RegNo: "REG883",
      country: "Canada",
      receiptNo: "REC522",
      address: "712 Elm St, City",
      oppCharges: 155,
      gstCharges: 33,
      OTTCharges: 77,
      totalAmount: 265,
      status: "Paid",
      JourneyDate: "2024-04-06",
    },
    {
      lastName: "Smith",
      firstName: "John",
      age: 55,
      gender: "Female",
      RegNo: "REG971",
      country: "Australia",
      receiptNo: "REC320",
      address: "303 Elm St, City",
      oppCharges: 134,
      gstCharges: 20,
      OTTCharges: 74,
      totalAmount: 228,
      status: "Pending",
      JourneyDate: "2023-03-10",
    },
    {
      lastName: "Miller",
      firstName: "John",
      age: 58,
      gender: "Female",
      RegNo: "REG124",
      country: "USA",
      receiptNo: "REC429",
      address: "639 Elm St, City",
      oppCharges: 91,
      gstCharges: 27,
      OTTCharges: 92,
      totalAmount: 210,
      status: "Paid",
      JourneyDate: "2023-02-21",
    },
    {
      lastName: "Jones",
      firstName: "James",
      age: 50,
      gender: "Female",
      RegNo: "REG648",
      country: "Australia",
      receiptNo: "REC435",
      address: "337 Elm St, City",
      oppCharges: 134,
      gstCharges: 32,
      OTTCharges: 54,
      totalAmount: 220,
      status: "Pending",
      JourneyDate: "2023-10-01",
    },
    {
      lastName: "Davis",
      firstName: "Richard",
      age: 43,
      gender: "Male",
      RegNo: "REG881",
      country: "Canada",
      receiptNo: "REC611",
      address: "826 Elm St, City",
      oppCharges: 122,
      gstCharges: 39,
      OTTCharges: 36,
      totalAmount: 197,
      status: "Pending",
      JourneyDate: "2024-02-10",
    },
    {
      lastName: "Davis",
      firstName: "John",
      age: 61,
      gender: "Female",
      RegNo: "REG316",
      country: "USA",
      receiptNo: "REC162",
      address: "175 Elm St, City",
      oppCharges: 177,
      gstCharges: 28,
      OTTCharges: 77,
      totalAmount: 282,
      status: "Paid",
      JourneyDate: "2023-12-21",
    },
    {
      lastName: "Johnson",
      firstName: "Robert",
      age: 34,
      gender: "Female",
      RegNo: "REG666",
      country: "India",
      receiptNo: "REC59",
      address: "18 Elm St, City",
      oppCharges: 199,
      gstCharges: 30,
      OTTCharges: 59,
      totalAmount: 288,
      status: "Cancelled",
      JourneyDate: "2023-01-06",
    },
    {
      lastName: "Jones",
      firstName: "David",
      age: 61,
      gender: "Male",
      RegNo: "REG500",
      country: "India",
      receiptNo: "REC774",
      address: "949 Elm St, City",
      oppCharges: 215,
      gstCharges: 12,
      OTTCharges: 75,
      totalAmount: 302,
      status: "Cancelled",
      JourneyDate: "2023-12-04",
    },
    {
      lastName: "Miller",
      firstName: "Robert",
      age: 27,
      gender: "Female",
      RegNo: "REG439",
      country: "Canada",
      receiptNo: "REC49",
      address: "378 Elm St, City",
      oppCharges: 83,
      gstCharges: 14,
      OTTCharges: 96,
      totalAmount: 193,
      status: "Cancelled",
      JourneyDate: "2024-05-10",
    },
    {
      lastName: "Williams",
      firstName: "Michael",
      age: 26,
      gender: "Male",
      RegNo: "REG59",
      country: "India",
      receiptNo: "REC350",
      address: "839 Elm St, City",
      oppCharges: 202,
      gstCharges: 25,
      OTTCharges: 39,
      totalAmount: 266,
      status: "Pending",
      JourneyDate: "2023-11-17",
    },
    {
      lastName: "Brown",
      firstName: "Michael",
      age: 42,
      gender: "Female",
      RegNo: "REG672",
      country: "India",
      receiptNo: "REC477",
      address: "778 Elm St, City",
      oppCharges: 56,
      gstCharges: 16,
      OTTCharges: 63,
      totalAmount: 135,
      status: "Paid",
      JourneyDate: "2024-02-15",
    },
    {
      lastName: "Brown",
      firstName: "William",
      age: 50,
      gender: "Female",
      RegNo: "REG315",
      country: "USA",
      receiptNo: "REC603",
      address: "911 Elm St, City",
      oppCharges: 106,
      gstCharges: 14,
      OTTCharges: 78,
      totalAmount: 198,
      status: "Pending",
      JourneyDate: "2023-06-03",
    },
    {
      lastName: "Johnson",
      firstName: "David",
      age: 37,
      gender: "Male",
      RegNo: "REG186",
      country: "USA",
      receiptNo: "REC193",
      address: "454 Elm St, City",
      oppCharges: 212,
      gstCharges: 29,
      OTTCharges: 64,
      totalAmount: 305,
      status: "Cancelled",
      JourneyDate: "2023-01-28",
    },
    {
      lastName: "Miller",
      firstName: "Michael",
      age: 50,
      gender: "Male",
      RegNo: "REG811",
      country: "India",
      receiptNo: "REC12",
      address: "419 Elm St, City",
      oppCharges: 92,
      gstCharges: 29,
      OTTCharges: 53,
      totalAmount: 174,
      status: "Cancelled",
      JourneyDate: "2023-10-28",
    },
    {
      lastName: "Johnson",
      firstName: "Richard",
      age: 36,
      gender: "Female",
      RegNo: "REG241",
      country: "Canada",
      receiptNo: "REC659",
      address: "132 Elm St, City",
      oppCharges: 175,
      gstCharges: 27,
      OTTCharges: 93,
      totalAmount: 295,
      status: "Cancelled",
      JourneyDate: "2023-06-30",
    },
    {
      lastName: "Brown",
      firstName: "Richard",
      age: 55,
      gender: "Female",
      RegNo: "REG778",
      country: "Australia",
      receiptNo: "REC326",
      address: "781 Elm St, City",
      oppCharges: 167,
      gstCharges: 20,
      OTTCharges: 62,
      totalAmount: 249,
      status: "Pending",
      JourneyDate: "2023-04-18",
    },
    {
      lastName: "Miller",
      firstName: "James",
      age: 56,
      gender: "Female",
      RegNo: "REG62",
      country: "USA",
      receiptNo: "REC521",
      address: "831 Elm St, City",
      oppCharges: 238,
      gstCharges: 11,
      OTTCharges: 88,
      totalAmount: 337,
      status: "Cancelled",
      JourneyDate: "2024-06-18",
    },
    {
      lastName: "Williams",
      firstName: "James",
      age: 50,
      gender: "Female",
      RegNo: "REG730",
      country: "USA",
      receiptNo: "REC914",
      address: "449 Elm St, City",
      oppCharges: 149,
      gstCharges: 10,
      OTTCharges: 65,
      totalAmount: 224,
      status: "Cancelled",
      JourneyDate: "2024-07-05",
    },
    {
      lastName: "Williams",
      firstName: "Michael",
      age: 53,
      gender: "Female",
      RegNo: "REG897",
      country: "Canada",
      receiptNo: "REC634",
      address: "965 Elm St, City",
      oppCharges: 220,
      gstCharges: 17,
      OTTCharges: 45,
      totalAmount: 282,
      status: "Paid",
      JourneyDate: "2024-05-17",
    },
    {
      lastName: "Brown",
      firstName: "Robert",
      age: 47,
      gender: "Female",
      RegNo: "REG264",
      country: "India",
      receiptNo: "REC932",
      address: "625 Elm St, City",
      oppCharges: 176,
      gstCharges: 10,
      OTTCharges: 60,
      totalAmount: 246,
      status: "Cancelled",
      JourneyDate: "2023-02-19",
    },
    {
      lastName: "Miller",
      firstName: "Michael",
      age: 68,
      gender: "Female",
      RegNo: "REG715",
      country: "UK",
      receiptNo: "REC519",
      address: "834 Elm St, City",
      oppCharges: 58,
      gstCharges: 35,
      OTTCharges: 5,
      totalAmount: 98,
      status: "Paid",
      JourneyDate: "2023-05-12",
    },
    {
      lastName: "Williams",
      firstName: "David",
      age: 68,
      gender: "Male",
      RegNo: "REG373",
      country: "USA",
      receiptNo: "REC482",
      address: "334 Elm St, City",
      oppCharges: 91,
      gstCharges: 32,
      OTTCharges: 45,
      totalAmount: 168,
      status: "Cancelled",
      JourneyDate: "2023-12-12",
    },
    {
      lastName: "Davis",
      firstName: "John",
      age: 22,
      gender: "Male",
      RegNo: "REG427",
      country: "USA",
      receiptNo: "REC474",
      address: "636 Elm St, City",
      oppCharges: 240,
      gstCharges: 38,
      OTTCharges: 77,
      totalAmount: 355,
      status: "Paid",
      JourneyDate: "2023-05-05",
    },
    {
      lastName: "Davis",
      firstName: "Michael",
      age: 68,
      gender: "Male",
      RegNo: "REG147",
      country: "Canada",
      receiptNo: "REC672",
      address: "285 Elm St, City",
      oppCharges: 245,
      gstCharges: 13,
      OTTCharges: 13,
      totalAmount: 271,
      status: "Cancelled",
      JourneyDate: "2023-07-13",
    },
    {
      lastName: "Brown",
      firstName: "David",
      age: 31,
      gender: "Female",
      RegNo: "REG534",
      country: "Canada",
      receiptNo: "REC157",
      address: "744 Elm St, City",
      oppCharges: 225,
      gstCharges: 27,
      OTTCharges: 60,
      totalAmount: 312,
      status: "Cancelled",
      JourneyDate: "2023-06-05",
    },
    {
      lastName: "Johnson",
      firstName: "Robert",
      age: 24,
      gender: "Female",
      RegNo: "REG982",
      country: "Canada",
      receiptNo: "REC712",
      address: "613 Elm St, City",
      oppCharges: 230,
      gstCharges: 13,
      OTTCharges: 9,
      totalAmount: 252,
      status: "Pending",
      JourneyDate: "2023-12-15",
    },
    {
      lastName: "Smith",
      firstName: "Michael",
      age: 48,
      gender: "Female",
      RegNo: "REG910",
      country: "Canada",
      receiptNo: "REC869",
      address: "492 Elm St, City",
      oppCharges: 67,
      gstCharges: 33,
      OTTCharges: 15,
      totalAmount: 115,
      status: "Cancelled",
      JourneyDate: "2023-10-06",
    },
    {
      lastName: "Smith",
      firstName: "Michael",
      age: 56,
      gender: "Male",
      RegNo: "REG613",
      country: "Australia",
      receiptNo: "REC805",
      address: "145 Elm St, City",
      oppCharges: 206,
      gstCharges: 29,
      OTTCharges: 55,
      totalAmount: 290,
      status: "Paid",
      JourneyDate: "2023-03-30",
    },
    {
      lastName: "Jones",
      firstName: "Michael",
      age: 42,
      gender: "Male",
      RegNo: "REG816",
      country: "Canada",
      receiptNo: "REC330",
      address: "162 Elm St, City",
      oppCharges: 226,
      gstCharges: 39,
      OTTCharges: 85,
      totalAmount: 350,
      status: "Paid",
      JourneyDate: "2024-05-28",
    },
    {
      lastName: "Davis",
      firstName: "John",
      age: 55,
      gender: "Female",
      RegNo: "REG839",
      country: "USA",
      receiptNo: "REC97",
      address: "298 Elm St, City",
      oppCharges: 73,
      gstCharges: 17,
      OTTCharges: 79,
      totalAmount: 169,
      status: "Cancelled",
      JourneyDate: "2023-02-03",
    },
    {
      lastName: "Johnson",
      firstName: "Robert",
      age: 37,
      gender: "Female",
      RegNo: "REG501",
      country: "Australia",
      receiptNo: "REC143",
      address: "538 Elm St, City",
      oppCharges: 52,
      gstCharges: 33,
      OTTCharges: 86,
      totalAmount: 171,
      status: "Pending",
      JourneyDate: "2023-12-16",
    },
    {
      lastName: "Miller",
      firstName: "Richard",
      age: 33,
      gender: "Female",
      RegNo: "REG359",
      country: "Australia",
      receiptNo: "REC92",
      address: "578 Elm St, City",
      oppCharges: 50,
      gstCharges: 13,
      OTTCharges: 10,
      totalAmount: 73,
      status: "Paid",
      JourneyDate: "2024-01-01",
    },
    {
      lastName: "Williams",
      firstName: "Robert",
      age: 44,
      gender: "Male",
      RegNo: "REG686",
      country: "India",
      receiptNo: "REC39",
      address: "298 Elm St, City",
      oppCharges: 202,
      gstCharges: 11,
      OTTCharges: 8,
      totalAmount: 221,
      status: "Pending",
      JourneyDate: "2024-02-25",
    },
    {
      lastName: "Miller",
      firstName: "James",
      age: 60,
      gender: "Female",
      RegNo: "REG855",
      country: "India",
      receiptNo: "REC863",
      address: "622 Elm St, City",
      oppCharges: 92,
      gstCharges: 33,
      OTTCharges: 90,
      totalAmount: 215,
      status: "Paid",
      JourneyDate: "2024-01-11",
    },
    {
      lastName: "Williams",
      firstName: "William",
      age: 53,
      gender: "Female",
      RegNo: "REG550",
      country: "UK",
      receiptNo: "REC53",
      address: "543 Elm St, City",
      oppCharges: 85,
      gstCharges: 33,
      OTTCharges: 88,
      totalAmount: 206,
      status: "Cancelled",
      JourneyDate: "2023-02-14",
    },
    {
      lastName: "Davis",
      firstName: "James",
      age: 24,
      gender: "Female",
      RegNo: "REG19",
      country: "UK",
      receiptNo: "REC34",
      address: "588 Elm St, City",
      oppCharges: 63,
      gstCharges: 11,
      OTTCharges: 48,
      totalAmount: 122,
      status: "Cancelled",
      JourneyDate: "2023-08-11",
    },
    {
      lastName: "Williams",
      firstName: "Robert",
      age: 20,
      gender: "Female",
      RegNo: "REG165",
      country: "USA",
      receiptNo: "REC107",
      address: "980 Elm St, City",
      oppCharges: 233,
      gstCharges: 39,
      OTTCharges: 11,
      totalAmount: 283,
      status: "Pending",
      JourneyDate: "2023-01-27",
    },
    {
      lastName: "Smith",
      firstName: "Richard",
      age: 29,
      gender: "Male",
      RegNo: "REG606",
      country: "USA",
      receiptNo: "REC256",
      address: "784 Elm St, City",
      oppCharges: 197,
      gstCharges: 14,
      OTTCharges: 90,
      totalAmount: 301,
      status: "Paid",
      JourneyDate: "2024-01-24",
    },
    {
      lastName: "Miller",
      firstName: "John",
      age: 53,
      gender: "Male",
      RegNo: "REG510",
      country: "UK",
      receiptNo: "REC963",
      address: "377 Elm St, City",
      oppCharges: 51,
      gstCharges: 34,
      OTTCharges: 98,
      totalAmount: 183,
      status: "Cancelled",
      JourneyDate: "2023-05-06",
    },
    {
      lastName: "Brown",
      firstName: "Michael",
      age: 39,
      gender: "Male",
      RegNo: "REG881",
      country: "UK",
      receiptNo: "REC63",
      address: "868 Elm St, City",
      oppCharges: 159,
      gstCharges: 23,
      OTTCharges: 0,
      totalAmount: 182,
      status: "Cancelled",
      JourneyDate: "2023-03-13",
    },
    {
      lastName: "Williams",
      firstName: "David",
      age: 61,
      gender: "Male",
      RegNo: "REG425",
      country: "UK",
      receiptNo: "REC428",
      address: "28 Elm St, City",
      oppCharges: 168,
      gstCharges: 15,
      OTTCharges: 38,
      totalAmount: 221,
      status: "Pending",
      JourneyDate: "2023-03-24",
    },
    {
      lastName: "Smith",
      firstName: "James",
      age: 47,
      gender: "Female",
      RegNo: "REG668",
      country: "Canada",
      receiptNo: "REC193",
      address: "843 Elm St, City",
      oppCharges: 237,
      gstCharges: 22,
      OTTCharges: 59,
      totalAmount: 318,
      status: "Pending",
      JourneyDate: "2023-08-22",
    },
    {
      lastName: "Williams",
      firstName: "John",
      age: 68,
      gender: "Female",
      RegNo: "REG126",
      country: "Canada",
      receiptNo: "REC721",
      address: "180 Elm St, City",
      oppCharges: 148,
      gstCharges: 16,
      OTTCharges: 28,
      totalAmount: 192,
      status: "Pending",
      JourneyDate: "2023-02-05",
    },
    {
      lastName: "Jones",
      firstName: "Richard",
      age: 31,
      gender: "Male",
      RegNo: "REG742",
      country: "UK",
      receiptNo: "REC867",
      address: "113 Elm St, City",
      oppCharges: 173,
      gstCharges: 15,
      OTTCharges: 10,
      totalAmount: 198,
      status: "Paid",
      JourneyDate: "2023-05-17",
    },
    {
      lastName: "Davis",
      firstName: "Michael",
      age: 68,
      gender: "Male",
      RegNo: "REG819",
      country: "UK",
      receiptNo: "REC789",
      address: "195 Elm St, City",
      oppCharges: 85,
      gstCharges: 35,
      OTTCharges: 26,
      totalAmount: 146,
      status: "Paid",
      JourneyDate: "2024-05-20",
    },
    {
      lastName: "Johnson",
      firstName: "Robert",
      age: 36,
      gender: "Male",
      RegNo: "REG858",
      country: "UK",
      receiptNo: "REC999",
      address: "153 Elm St, City",
      oppCharges: 175,
      gstCharges: 19,
      OTTCharges: 96,
      totalAmount: 290,
      status: "Cancelled",
      JourneyDate: "2023-10-16",
    },
    {
      lastName: "Smith",
      firstName: "William",
      age: 26,
      gender: "Female",
      RegNo: "REG154",
      country: "India",
      receiptNo: "REC26",
      address: "747 Elm St, City",
      oppCharges: 217,
      gstCharges: 20,
      OTTCharges: 79,
      totalAmount: 316,
      status: "Paid",
      JourneyDate: "2024-01-18",
    },
    {
      lastName: "Johnson",
      firstName: "John",
      age: 55,
      gender: "Male",
      RegNo: "REG310",
      country: "Australia",
      receiptNo: "REC872",
      address: "722 Elm St, City",
      oppCharges: 224,
      gstCharges: 10,
      OTTCharges: 87,
      totalAmount: 321,
      status: "Cancelled",
      JourneyDate: "2023-02-25",
    },
    {
      lastName: "Williams",
      firstName: "William",
      age: 43,
      gender: "Female",
      RegNo: "REG880",
      country: "Canada",
      receiptNo: "REC73",
      address: "518 Elm St, City",
      oppCharges: 205,
      gstCharges: 23,
      OTTCharges: 17,
      totalAmount: 245,
      status: "Pending",
      JourneyDate: "2024-06-30",
    },
    {
      lastName: "Williams",
      firstName: "John",
      age: 38,
      gender: "Female",
      RegNo: "REG664",
      country: "Canada",
      receiptNo: "REC833",
      address: "540 Elm St, City",
      oppCharges: 125,
      gstCharges: 13,
      OTTCharges: 60,
      totalAmount: 198,
      status: "Paid",
      JourneyDate: "2024-06-26",
    },
    {
      lastName: "Davis",
      firstName: "Robert",
      age: 54,
      gender: "Male",
      RegNo: "REG850",
      country: "Canada",
      receiptNo: "REC623",
      address: "814 Elm St, City",
      oppCharges: 194,
      gstCharges: 21,
      OTTCharges: 55,
      totalAmount: 270,
      status: "Pending",
      JourneyDate: "2023-05-02",
    },
    {
      lastName: "Williams",
      firstName: "James",
      age: 37,
      gender: "Female",
      RegNo: "REG457",
      country: "USA",
      receiptNo: "REC562",
      address: "910 Elm St, City",
      oppCharges: 74,
      gstCharges: 25,
      OTTCharges: 87,
      totalAmount: 186,
      status: "Paid",
      JourneyDate: "2023-12-22",
    },
    {
      lastName: "Miller",
      firstName: "Robert",
      age: 20,
      gender: "Female",
      RegNo: "REG323",
      country: "UK",
      receiptNo: "REC348",
      address: "94 Elm St, City",
      oppCharges: 184,
      gstCharges: 32,
      OTTCharges: 77,
      totalAmount: 293,
      status: "Cancelled",
      JourneyDate: "2024-06-21",
    },
    {
      lastName: "Brown",
      firstName: "James",
      age: 53,
      gender: "Female",
      RegNo: "REG933",
      country: "USA",
      receiptNo: "REC278",
      address: "73 Elm St, City",
      oppCharges: 66,
      gstCharges: 39,
      OTTCharges: 2,
      totalAmount: 107,
      status: "Paid",
      JourneyDate: "2023-06-30",
    },
    {
      lastName: "Smith",
      firstName: "Richard",
      age: 26,
      gender: "Female",
      RegNo: "REG62",
      country: "Australia",
      receiptNo: "REC866",
      address: "648 Elm St, City",
      oppCharges: 180,
      gstCharges: 25,
      OTTCharges: 84,
      totalAmount: 289,
      status: "Pending",
      JourneyDate: "2023-11-13",
    },
    {
      lastName: "Johnson",
      firstName: "David",
      age: 32,
      gender: "Female",
      RegNo: "REG838",
      country: "USA",
      receiptNo: "REC674",
      address: "796 Elm St, City",
      oppCharges: 227,
      gstCharges: 29,
      OTTCharges: 71,
      totalAmount: 327,
      status: "Cancelled",
      JourneyDate: "2023-04-21",
    },
    {
      lastName: "Davis",
      firstName: "John",
      age: 20,
      gender: "Male",
      RegNo: "REG129",
      country: "India",
      receiptNo: "REC717",
      address: "725 Elm St, City",
      oppCharges: 91,
      gstCharges: 17,
      OTTCharges: 27,
      totalAmount: 135,
      status: "Pending",
      JourneyDate: "2023-10-01",
    },
    {
      lastName: "Jones",
      firstName: "James",
      age: 36,
      gender: "Female",
      RegNo: "REG340",
      country: "Canada",
      receiptNo: "REC648",
      address: "121 Elm St, City",
      oppCharges: 54,
      gstCharges: 14,
      OTTCharges: 45,
      totalAmount: 113,
      status: "Cancelled",
      JourneyDate: "2023-11-09",
    },
    {
      lastName: "Williams",
      firstName: "Michael",
      age: 62,
      gender: "Female",
      RegNo: "REG756",
      country: "Australia",
      receiptNo: "REC448",
      address: "746 Elm St, City",
      oppCharges: 137,
      gstCharges: 20,
      OTTCharges: 92,
      totalAmount: 249,
      status: "Cancelled",
      JourneyDate: "2023-04-13",
    },
    {
      lastName: "Williams",
      firstName: "William",
      age: 68,
      gender: "Male",
      RegNo: "REG965",
      country: "India",
      receiptNo: "REC486",
      address: "268 Elm St, City",
      oppCharges: 101,
      gstCharges: 23,
      OTTCharges: 23,
      totalAmount: 147,
      status: "Pending",
      JourneyDate: "2024-02-16",
    },
    {
      lastName: "Johnson",
      firstName: "William",
      age: 44,
      gender: "Male",
      RegNo: "REG119",
      country: "Australia",
      receiptNo: "REC687",
      address: "642 Elm St, City",
      oppCharges: 112,
      gstCharges: 27,
      OTTCharges: 15,
      totalAmount: 154,
      status: "Cancelled",
      JourneyDate: "2023-04-03",
    },
    {
      lastName: "Smith",
      firstName: "Michael",
      age: 68,
      gender: "Male",
      RegNo: "REG225",
      country: "Canada",
      receiptNo: "REC788",
      address: "229 Elm St, City",
      oppCharges: 204,
      gstCharges: 12,
      OTTCharges: 46,
      totalAmount: 262,
      status: "Paid",
      JourneyDate: "2024-05-09",
    },
    {
      lastName: "Davis",
      firstName: "William",
      age: 41,
      gender: "Female",
      RegNo: "REG321",
      country: "USA",
      receiptNo: "REC549",
      address: "117 Elm St, City",
      oppCharges: 206,
      gstCharges: 18,
      OTTCharges: 16,
      totalAmount: 240,
      status: "Cancelled",
      JourneyDate: "2024-06-04",
    },
    {
      lastName: "Smith",
      firstName: "Robert",
      age: 69,
      gender: "Female",
      RegNo: "REG769",
      country: "Australia",
      receiptNo: "REC636",
      address: "264 Elm St, City",
      oppCharges: 54,
      gstCharges: 37,
      OTTCharges: 75,
      totalAmount: 166,
      status: "Pending",
      JourneyDate: "2024-03-20",
    },
    {
      lastName: "Johnson",
      firstName: "James",
      age: 49,
      gender: "Male",
      RegNo: "REG521",
      country: "India",
      receiptNo: "REC282",
      address: "649 Elm St, City",
      oppCharges: 94,
      gstCharges: 36,
      OTTCharges: 82,
      totalAmount: 212,
      status: "Paid",
      JourneyDate: "2024-01-07",
    },
    {
      lastName: "Smith",
      firstName: "William",
      age: 56,
      gender: "Female",
      RegNo: "REG724",
      country: "Canada",
      receiptNo: "REC368",
      address: "181 Elm St, City",
      oppCharges: 51,
      gstCharges: 19,
      OTTCharges: 28,
      totalAmount: 98,
      status: "Pending",
      JourneyDate: "2023-05-25",
    },
    {
      lastName: "Smith",
      firstName: "John",
      age: 21,
      gender: "Male",
      RegNo: "REG252",
      country: "UK",
      receiptNo: "REC752",
      address: "780 Elm St, City",
      oppCharges: 221,
      gstCharges: 36,
      OTTCharges: 32,
      totalAmount: 289,
      status: "Cancelled",
      JourneyDate: "2023-05-03",
    },
    {
      lastName: "Johnson",
      firstName: "James",
      age: 36,
      gender: "Male",
      RegNo: "REG795",
      country: "Australia",
      receiptNo: "REC794",
      address: "370 Elm St, City",
      oppCharges: 173,
      gstCharges: 11,
      OTTCharges: 31,
      totalAmount: 215,
      status: "Cancelled",
      JourneyDate: "2023-05-21",
    },
    {
      lastName: "Smith",
      firstName: "Richard",
      age: 28,
      gender: "Female",
      RegNo: "REG818",
      country: "India",
      receiptNo: "REC960",
      address: "0 Elm St, City",
      oppCharges: 232,
      gstCharges: 39,
      OTTCharges: 79,
      totalAmount: 350,
      status: "Cancelled",
      JourneyDate: "2023-01-13",
    },
    {
      lastName: "Brown",
      firstName: "David",
      age: 56,
      gender: "Female",
      RegNo: "REG704",
      country: "UK",
      receiptNo: "REC600",
      address: "968 Elm St, City",
      oppCharges: 80,
      gstCharges: 33,
      OTTCharges: 65,
      totalAmount: 178,
      status: "Paid",
      JourneyDate: "2023-03-17",
    },
    {
      lastName: "Johnson",
      firstName: "John",
      age: 67,
      gender: "Male",
      RegNo: "REG980",
      country: "Australia",
      receiptNo: "REC851",
      address: "637 Elm St, City",
      oppCharges: 192,
      gstCharges: 37,
      OTTCharges: 72,
      totalAmount: 301,
      status: "Paid",
      JourneyDate: "2023-10-10",
    },
    {
      lastName: "Smith",
      firstName: "Richard",
      age: 60,
      gender: "Female",
      RegNo: "REG942",
      country: "UK",
      receiptNo: "REC451",
      address: "679 Elm St, City",
      oppCharges: 187,
      gstCharges: 20,
      OTTCharges: 19,
      totalAmount: 226,
      status: "Paid",
      JourneyDate: "2023-04-25",
    },
    {
      lastName: "Johnson",
      firstName: "William",
      age: 47,
      gender: "Male",
      RegNo: "REG792",
      country: "USA",
      receiptNo: "REC185",
      address: "843 Elm St, City",
      oppCharges: 184,
      gstCharges: 37,
      OTTCharges: 68,
      totalAmount: 289,
      status: "Paid",
      JourneyDate: "2023-02-18",
    },
    {
      lastName: "Brown",
      firstName: "Michael",
      age: 25,
      gender: "Male",
      RegNo: "REG317",
      country: "Australia",
      receiptNo: "REC249",
      address: "729 Elm St, City",
      oppCharges: 215,
      gstCharges: 21,
      OTTCharges: 33,
      totalAmount: 269,
      status: "Cancelled",
      JourneyDate: "2023-12-04",
    },
    {
      lastName: "Jones",
      firstName: "David",
      age: 67,
      gender: "Male",
      RegNo: "REG24",
      country: "UK",
      receiptNo: "REC414",
      address: "132 Elm St, City",
      oppCharges: 75,
      gstCharges: 34,
      OTTCharges: 19,
      totalAmount: 128,
      status: "Pending",
      JourneyDate: "2024-04-21",
    },
    {
      lastName: "Smith",
      firstName: "Robert",
      age: 48,
      gender: "Female",
      RegNo: "REG584",
      country: "USA",
      receiptNo: "REC221",
      address: "235 Elm St, City",
      oppCharges: 108,
      gstCharges: 17,
      OTTCharges: 55,
      totalAmount: 180,
      status: "Cancelled",
      JourneyDate: "2023-05-28",
    },
    {
      lastName: "Johnson",
      firstName: "Robert",
      age: 57,
      gender: "Male",
      RegNo: "REG117",
      country: "USA",
      receiptNo: "REC275",
      address: "69 Elm St, City",
      oppCharges: 60,
      gstCharges: 34,
      OTTCharges: 33,
      totalAmount: 127,
      status: "Pending",
      JourneyDate: "2023-09-14",
    },
    {
      lastName: "Jones",
      firstName: "Michael",
      age: 63,
      gender: "Male",
      RegNo: "REG302",
      country: "Canada",
      receiptNo: "REC881",
      address: "500 Elm St, City",
      oppCharges: 236,
      gstCharges: 37,
      OTTCharges: 19,
      totalAmount: 292,
      status: "Pending",
      JourneyDate: "2023-06-04",
    },
    {
      lastName: "Miller",
      firstName: "Michael",
      age: 45,
      gender: "Female",
      RegNo: "REG708",
      country: "India",
      receiptNo: "REC171",
      address: "467 Elm St, City",
      oppCharges: 210,
      gstCharges: 11,
      OTTCharges: 32,
      totalAmount: 253,
      status: "Paid",
      JourneyDate: "2024-03-25",
    },
    {
      lastName: "Jones",
      firstName: "James",
      age: 23,
      gender: "Female",
      RegNo: "REG868",
      country: "Australia",
      receiptNo: "REC546",
      address: "864 Elm St, City",
      oppCharges: 96,
      gstCharges: 14,
      OTTCharges: 48,
      totalAmount: 158,
      status: "Pending",
      JourneyDate: "2023-05-05",
    },
    {
      lastName: "Davis",
      firstName: "Robert",
      age: 22,
      gender: "Female",
      RegNo: "REG949",
      country: "Australia",
      receiptNo: "REC871",
      address: "12 Elm St, City",
      oppCharges: 196,
      gstCharges: 37,
      OTTCharges: 84,
      totalAmount: 317,
      status: "Cancelled",
      JourneyDate: "2023-01-23",
    },
    {
      lastName: "Johnson",
      firstName: "Michael",
      age: 42,
      gender: "Male",
      RegNo: "REG464",
      country: "UK",
      receiptNo: "REC338",
      address: "75 Elm St, City",
      oppCharges: 78,
      gstCharges: 22,
      OTTCharges: 3,
      totalAmount: 103,
      status: "Pending",
      JourneyDate: "2024-07-12",
    },
    {
      lastName: "Miller",
      firstName: "Michael",
      age: 34,
      gender: "Female",
      RegNo: "REG519",
      country: "India",
      receiptNo: "REC141",
      address: "583 Elm St, City",
      oppCharges: 99,
      gstCharges: 15,
      OTTCharges: 83,
      totalAmount: 197,
      status: "Paid",
      JourneyDate: "2023-06-14",
    },
    {
      lastName: "Davis",
      firstName: "John",
      age: 30,
      gender: "Female",
      RegNo: "REG375",
      country: "Canada",
      receiptNo: "REC881",
      address: "794 Elm St, City",
      oppCharges: 194,
      gstCharges: 37,
      OTTCharges: 56,
      totalAmount: 287,
      status: "Pending",
      JourneyDate: "2023-01-26",
    },
    {
      lastName: "Davis",
      firstName: "Robert",
      age: 50,
      gender: "Male",
      RegNo: "REG807",
      country: "Canada",
      receiptNo: "REC749",
      address: "881 Elm St, City",
      oppCharges: 76,
      gstCharges: 17,
      OTTCharges: 6,
      totalAmount: 99,
      status: "Pending",
      JourneyDate: "2023-08-21",
    },
    {
      lastName: "Miller",
      firstName: "Richard",
      age: 28,
      gender: "Male",
      RegNo: "REG688",
      country: "UK",
      receiptNo: "REC747",
      address: "633 Elm St, City",
      oppCharges: 238,
      gstCharges: 11,
      OTTCharges: 99,
      totalAmount: 348,
      status: "Cancelled",
      JourneyDate: "2024-04-21",
    },
    {
      lastName: "Williams",
      firstName: "William",
      age: 60,
      gender: "Male",
      RegNo: "REG954",
      country: "USA",
      receiptNo: "REC438",
      address: "720 Elm St, City",
      oppCharges: 174,
      gstCharges: 24,
      OTTCharges: 7,
      totalAmount: 205,
      status: "Pending",
      JourneyDate: "2024-02-02",
    },
    {
      lastName: "Williams",
      firstName: "Michael",
      age: 25,
      gender: "Male",
      RegNo: "REG135",
      country: "UK",
      receiptNo: "REC883",
      address: "537 Elm St, City",
      oppCharges: 190,
      gstCharges: 31,
      OTTCharges: 11,
      totalAmount: 232,
      status: "Cancelled",
      JourneyDate: "2023-03-20",
    },
    {
      lastName: "Davis",
      firstName: "William",
      age: 54,
      gender: "Male",
      RegNo: "REG956",
      country: "USA",
      receiptNo: "REC740",
      address: "232 Elm St, City",
      oppCharges: 161,
      gstCharges: 18,
      OTTCharges: 29,
      totalAmount: 208,
      status: "Paid",
      JourneyDate: "2024-06-28",
    },
    {
      lastName: "Davis",
      firstName: "Michael",
      age: 41,
      gender: "Male",
      RegNo: "REG454",
      country: "USA",
      receiptNo: "REC916",
      address: "296 Elm St, City",
      oppCharges: 205,
      gstCharges: 17,
      OTTCharges: 11,
      totalAmount: 233,
      status: "Paid",
      JourneyDate: "2023-01-16",
    },
    {
      lastName: "Miller",
      firstName: "Robert",
      age: 45,
      gender: "Female",
      RegNo: "REG819",
      country: "UK",
      receiptNo: "REC67",
      address: "641 Elm St, City",
      oppCharges: 163,
      gstCharges: 34,
      OTTCharges: 19,
      totalAmount: 216,
      status: "Pending",
      JourneyDate: "2024-03-23",
    },
    {
      lastName: "Smith",
      firstName: "Robert",
      age: 21,
      gender: "Male",
      RegNo: "REG385",
      country: "Canada",
      receiptNo: "REC58",
      address: "524 Elm St, City",
      oppCharges: 164,
      gstCharges: 24,
      OTTCharges: 28,
      totalAmount: 216,
      status: "Paid",
      JourneyDate: "2024-04-24",
    },
    {
      lastName: "Jones",
      firstName: "James",
      age: 50,
      gender: "Male",
      RegNo: "REG168",
      country: "Australia",
      receiptNo: "REC364",
      address: "624 Elm St, City",
      oppCharges: 187,
      gstCharges: 20,
      OTTCharges: 41,
      totalAmount: 248,
      status: "Pending",
      JourneyDate: "2023-02-20",
    },
    {
      lastName: "Brown",
      firstName: "James",
      age: 28,
      gender: "Male",
      RegNo: "REG181",
      country: "USA",
      receiptNo: "REC500",
      address: "173 Elm St, City",
      oppCharges: 61,
      gstCharges: 22,
      OTTCharges: 57,
      totalAmount: 140,
      status: "Cancelled",
      JourneyDate: "2023-01-17",
    },
    {
      lastName: "Williams",
      firstName: "James",
      age: 28,
      gender: "Male",
      RegNo: "REG537",
      country: "UK",
      receiptNo: "REC372",
      address: "452 Elm St, City",
      oppCharges: 91,
      gstCharges: 39,
      OTTCharges: 52,
      totalAmount: 182,
      status: "Paid",
      JourneyDate: "2023-12-02",
    },
    {
      lastName: "Brown",
      firstName: "Robert",
      age: 52,
      gender: "Female",
      RegNo: "REG340",
      country: "UK",
      receiptNo: "REC34",
      address: "625 Elm St, City",
      oppCharges: 69,
      gstCharges: 19,
      OTTCharges: 83,
      totalAmount: 171,
      status: "Paid",
      JourneyDate: "2023-08-02",
    },
    {
      lastName: "Smith",
      firstName: "David",
      age: 28,
      gender: "Female",
      RegNo: "REG49",
      country: "Canada",
      receiptNo: "REC934",
      address: "803 Elm St, City",
      oppCharges: 199,
      gstCharges: 18,
      OTTCharges: 88,
      totalAmount: 305,
      status: "Pending",
      JourneyDate: "2023-02-21",
    },
    {
      lastName: "Davis",
      firstName: "Robert",
      age: 37,
      gender: "Female",
      RegNo: "REG516",
      country: "Australia",
      receiptNo: "REC849",
      address: "365 Elm St, City",
      oppCharges: 198,
      gstCharges: 29,
      OTTCharges: 16,
      totalAmount: 243,
      status: "Paid",
      JourneyDate: "2024-03-05",
    },
    {
      lastName: "Jones",
      firstName: "William",
      age: 59,
      gender: "Male",
      RegNo: "REG594",
      country: "India",
      receiptNo: "REC36",
      address: "514 Elm St, City",
      oppCharges: 61,
      gstCharges: 21,
      OTTCharges: 2,
      totalAmount: 84,
      status: "Cancelled",
      JourneyDate: "2023-11-16",
    },
    {
      lastName: "Davis",
      firstName: "Michael",
      age: 20,
      gender: "Male",
      RegNo: "REG205",
      country: "USA",
      receiptNo: "REC968",
      address: "579 Elm St, City",
      oppCharges: 67,
      gstCharges: 15,
      OTTCharges: 2,
      totalAmount: 84,
      status: "Cancelled",
      JourneyDate: "2024-03-04",
    },
    {
      lastName: "Jones",
      firstName: "John",
      age: 54,
      gender: "Female",
      RegNo: "REG457",
      country: "USA",
      receiptNo: "REC725",
      address: "8 Elm St, City",
      oppCharges: 103,
      gstCharges: 22,
      OTTCharges: 78,
      totalAmount: 203,
      status: "Paid",
      JourneyDate: "2023-03-29",
    },
    {
      lastName: "Davis",
      firstName: "James",
      age: 39,
      gender: "Female",
      RegNo: "REG48",
      country: "India",
      receiptNo: "REC47",
      address: "431 Elm St, City",
      oppCharges: 140,
      gstCharges: 20,
      OTTCharges: 84,
      totalAmount: 244,
      status: "Pending",
      JourneyDate: "2024-06-30",
    },
    {
      lastName: "Johnson",
      firstName: "Robert",
      age: 42,
      gender: "Male",
      RegNo: "REG86",
      country: "UK",
      receiptNo: "REC536",
      address: "365 Elm St, City",
      oppCharges: 242,
      gstCharges: 25,
      OTTCharges: 95,
      totalAmount: 362,
      status: "Pending",
      JourneyDate: "2023-04-22",
    },
    {
      lastName: "Brown",
      firstName: "Richard",
      age: 38,
      gender: "Male",
      RegNo: "REG309",
      country: "USA",
      receiptNo: "REC359",
      address: "90 Elm St, City",
      oppCharges: 206,
      gstCharges: 25,
      OTTCharges: 30,
      totalAmount: 261,
      status: "Paid",
      JourneyDate: "2023-07-03",
    },
    {
      lastName: "Jones",
      firstName: "Michael",
      age: 59,
      gender: "Female",
      RegNo: "REG16",
      country: "USA",
      receiptNo: "REC962",
      address: "24 Elm St, City",
      oppCharges: 108,
      gstCharges: 25,
      OTTCharges: 56,
      totalAmount: 189,
      status: "Paid",
      JourneyDate: "2024-06-29",
    },
    {
      lastName: "Miller",
      firstName: "James",
      age: 46,
      gender: "Female",
      RegNo: "REG318",
      country: "USA",
      receiptNo: "REC425",
      address: "365 Elm St, City",
      oppCharges: 97,
      gstCharges: 30,
      OTTCharges: 28,
      totalAmount: 155,
      status: "Cancelled",
      JourneyDate: "2023-09-24",
    },
    {
      lastName: "Williams",
      firstName: "John",
      age: 26,
      gender: "Female",
      RegNo: "REG652",
      country: "Canada",
      receiptNo: "REC250",
      address: "960 Elm St, City",
      oppCharges: 99,
      gstCharges: 18,
      OTTCharges: 98,
      totalAmount: 215,
      status: "Pending",
      JourneyDate: "2023-08-08",
    },
    {
      lastName: "Davis",
      firstName: "William",
      age: 67,
      gender: "Female",
      RegNo: "REG438",
      country: "USA",
      receiptNo: "REC402",
      address: "63 Elm St, City",
      oppCharges: 54,
      gstCharges: 11,
      OTTCharges: 78,
      totalAmount: 143,
      status: "Cancelled",
      JourneyDate: "2023-11-12",
    },
    {
      lastName: "Davis",
      firstName: "David",
      age: 52,
      gender: "Female",
      RegNo: "REG592",
      country: "Australia",
      receiptNo: "REC462",
      address: "736 Elm St, City",
      oppCharges: 82,
      gstCharges: 20,
      OTTCharges: 70,
      totalAmount: 172,
      status: "Cancelled",
      JourneyDate: "2024-03-21",
    },
    {
      lastName: "Davis",
      firstName: "Richard",
      age: 33,
      gender: "Male",
      RegNo: "REG425",
      country: "India",
      receiptNo: "REC375",
      address: "911 Elm St, City",
      oppCharges: 204,
      gstCharges: 25,
      OTTCharges: 0,
      totalAmount: 229,
      status: "Paid",
      JourneyDate: "2023-05-18",
    },
    {
      lastName: "Davis",
      firstName: "Richard",
      age: 37,
      gender: "Male",
      RegNo: "REG334",
      country: "UK",
      receiptNo: "REC291",
      address: "993 Elm St, City",
      oppCharges: 83,
      gstCharges: 29,
      OTTCharges: 90,
      totalAmount: 202,
      status: "Paid",
      JourneyDate: "2023-05-23",
    },
    {
      lastName: "Miller",
      firstName: "William",
      age: 24,
      gender: "Female",
      RegNo: "REG676",
      country: "India",
      receiptNo: "REC299",
      address: "557 Elm St, City",
      oppCharges: 191,
      gstCharges: 29,
      OTTCharges: 14,
      totalAmount: 234,
      status: "Pending",
      JourneyDate: "2023-03-29",
    },
    {
      lastName: "Miller",
      firstName: "Richard",
      age: 52,
      gender: "Female",
      RegNo: "REG690",
      country: "UK",
      receiptNo: "REC58",
      address: "510 Elm St, City",
      oppCharges: 68,
      gstCharges: 15,
      OTTCharges: 46,
      totalAmount: 129,
      status: "Cancelled",
      JourneyDate: "2023-06-30",
    },
    {
      lastName: "Davis",
      firstName: "James",
      age: 41,
      gender: "Male",
      RegNo: "REG384",
      country: "India",
      receiptNo: "REC961",
      address: "63 Elm St, City",
      oppCharges: 167,
      gstCharges: 31,
      OTTCharges: 76,
      totalAmount: 274,
      status: "Cancelled",
      JourneyDate: "2023-08-04",
    },
    {
      lastName: "Miller",
      firstName: "Richard",
      age: 27,
      gender: "Female",
      RegNo: "REG376",
      country: "Australia",
      receiptNo: "REC430",
      address: "920 Elm St, City",
      oppCharges: 204,
      gstCharges: 26,
      OTTCharges: 41,
      totalAmount: 271,
      status: "Pending",
      JourneyDate: "2023-04-17",
    },
    {
      lastName: "Brown",
      firstName: "James",
      age: 33,
      gender: "Female",
      RegNo: "REG312",
      country: "Canada",
      receiptNo: "REC593",
      address: "521 Elm St, City",
      oppCharges: 79,
      gstCharges: 25,
      OTTCharges: 75,
      totalAmount: 179,
      status: "Paid",
      JourneyDate: "2023-08-05",
    },
    {
      lastName: "Johnson",
      firstName: "William",
      age: 69,
      gender: "Male",
      RegNo: "REG586",
      country: "UK",
      receiptNo: "REC96",
      address: "453 Elm St, City",
      oppCharges: 175,
      gstCharges: 39,
      OTTCharges: 43,
      totalAmount: 257,
      status: "Pending",
      JourneyDate: "2023-03-04",
    },
    {
      lastName: "Davis",
      firstName: "William",
      age: 34,
      gender: "Female",
      RegNo: "REG421",
      country: "USA",
      receiptNo: "REC279",
      address: "847 Elm St, City",
      oppCharges: 232,
      gstCharges: 26,
      OTTCharges: 86,
      totalAmount: 344,
      status: "Paid",
      JourneyDate: "2024-04-25",
    },
    {
      lastName: "Johnson",
      firstName: "Richard",
      age: 38,
      gender: "Female",
      RegNo: "REG438",
      country: "UK",
      receiptNo: "REC302",
      address: "479 Elm St, City",
      oppCharges: 191,
      gstCharges: 32,
      OTTCharges: 20,
      totalAmount: 243,
      status: "Paid",
      JourneyDate: "2023-02-16",
    },
    {
      lastName: "Smith",
      firstName: "James",
      age: 35,
      gender: "Female",
      RegNo: "REG57",
      country: "India",
      receiptNo: "REC734",
      address: "694 Elm St, City",
      oppCharges: 105,
      gstCharges: 13,
      OTTCharges: 77,
      totalAmount: 195,
      status: "Cancelled",
      JourneyDate: "2023-04-11",
    },
    {
      lastName: "Brown",
      firstName: "David",
      age: 29,
      gender: "Male",
      RegNo: "REG539",
      country: "Australia",
      receiptNo: "REC1",
      address: "267 Elm St, City",
      oppCharges: 79,
      gstCharges: 22,
      OTTCharges: 34,
      totalAmount: 135,
      status: "Paid",
      JourneyDate: "2023-06-09",
    },
    {
      lastName: "Davis",
      firstName: "John",
      age: 43,
      gender: "Female",
      RegNo: "REG968",
      country: "UK",
      receiptNo: "REC499",
      address: "77 Elm St, City",
      oppCharges: 220,
      gstCharges: 39,
      OTTCharges: 17,
      totalAmount: 276,
      status: "Pending",
      JourneyDate: "2023-01-06",
    },
    {
      lastName: "Jones",
      firstName: "David",
      age: 65,
      gender: "Male",
      RegNo: "REG924",
      country: "India",
      receiptNo: "REC116",
      address: "1 Elm St, City",
      oppCharges: 57,
      gstCharges: 33,
      OTTCharges: 4,
      totalAmount: 94,
      status: "Cancelled",
      JourneyDate: "2024-05-21",
    },
    {
      lastName: "Miller",
      firstName: "Robert",
      age: 50,
      gender: "Male",
      RegNo: "REG431",
      country: "Canada",
      receiptNo: "REC603",
      address: "856 Elm St, City",
      oppCharges: 151,
      gstCharges: 36,
      OTTCharges: 44,
      totalAmount: 231,
      status: "Paid",
      JourneyDate: "2024-03-31",
    },
    {
      lastName: "Jones",
      firstName: "James",
      age: 45,
      gender: "Female",
      RegNo: "REG926",
      country: "Canada",
      receiptNo: "REC598",
      address: "933 Elm St, City",
      oppCharges: 183,
      gstCharges: 19,
      OTTCharges: 65,
      totalAmount: 267,
      status: "Pending",
      JourneyDate: "2023-06-05",
    },
    {
      lastName: "Smith",
      firstName: "Michael",
      age: 43,
      gender: "Male",
      RegNo: "REG306",
      country: "Australia",
      receiptNo: "REC733",
      address: "632 Elm St, City",
      oppCharges: 80,
      gstCharges: 29,
      OTTCharges: 6,
      totalAmount: 115,
      status: "Paid",
      JourneyDate: "2023-12-09",
    },
    {
      lastName: "Jones",
      firstName: "James",
      age: 65,
      gender: "Female",
      RegNo: "REG944",
      country: "India",
      receiptNo: "REC902",
      address: "14 Elm St, City",
      oppCharges: 107,
      gstCharges: 10,
      OTTCharges: 23,
      totalAmount: 140,
      status: "Cancelled",
      JourneyDate: "2023-05-22",
    },
    {
      lastName: "Johnson",
      firstName: "John",
      age: 58,
      gender: "Male",
      RegNo: "REG232",
      country: "India",
      receiptNo: "REC736",
      address: "63 Elm St, City",
      oppCharges: 183,
      gstCharges: 32,
      OTTCharges: 67,
      totalAmount: 282,
      status: "Paid",
      JourneyDate: "2023-04-29",
    },
    {
      lastName: "Smith",
      firstName: "Richard",
      age: 42,
      gender: "Male",
      RegNo: "REG904",
      country: "Canada",
      receiptNo: "REC717",
      address: "921 Elm St, City",
      oppCharges: 233,
      gstCharges: 34,
      OTTCharges: 82,
      totalAmount: 349,
      status: "Paid",
      JourneyDate: "2024-06-06",
    },
    {
      lastName: "Davis",
      firstName: "Robert",
      age: 30,
      gender: "Male",
      RegNo: "REG220",
      country: "Australia",
      receiptNo: "REC651",
      address: "368 Elm St, City",
      oppCharges: 58,
      gstCharges: 27,
      OTTCharges: 29,
      totalAmount: 114,
      status: "Pending",
      JourneyDate: "2023-12-14",
    },
    {
      lastName: "Jones",
      firstName: "Robert",
      age: 42,
      gender: "Male",
      RegNo: "REG35",
      country: "India",
      receiptNo: "REC339",
      address: "603 Elm St, City",
      oppCharges: 183,
      gstCharges: 26,
      OTTCharges: 12,
      totalAmount: 221,
      status: "Paid",
      JourneyDate: "2024-03-17",
    },
    {
      lastName: "Brown",
      firstName: "David",
      age: 45,
      gender: "Male",
      RegNo: "REG766",
      country: "Australia",
      receiptNo: "REC542",
      address: "198 Elm St, City",
      oppCharges: 90,
      gstCharges: 30,
      OTTCharges: 39,
      totalAmount: 159,
      status: "Pending",
      JourneyDate: "2023-05-23",
    },
    {
      lastName: "Brown",
      firstName: "John",
      age: 39,
      gender: "Female",
      RegNo: "REG487",
      country: "India",
      receiptNo: "REC349",
      address: "221 Elm St, City",
      oppCharges: 52,
      gstCharges: 25,
      OTTCharges: 51,
      totalAmount: 128,
      status: "Cancelled",
      JourneyDate: "2024-06-13",
    },
    {
      lastName: "Brown",
      firstName: "Michael",
      age: 27,
      gender: "Male",
      RegNo: "REG511",
      country: "UK",
      receiptNo: "REC229",
      address: "742 Elm St, City",
      oppCharges: 113,
      gstCharges: 32,
      OTTCharges: 14,
      totalAmount: 159,
      status: "Paid",
      JourneyDate: "2023-11-17",
    },
    {
      lastName: "Williams",
      firstName: "Richard",
      age: 51,
      gender: "Female",
      RegNo: "REG303",
      country: "Canada",
      receiptNo: "REC793",
      address: "929 Elm St, City",
      oppCharges: 131,
      gstCharges: 24,
      OTTCharges: 85,
      totalAmount: 240,
      status: "Paid",
      JourneyDate: "2024-06-01",
    },
    {
      lastName: "Jones",
      firstName: "Richard",
      age: 35,
      gender: "Male",
      RegNo: "REG610",
      country: "Canada",
      receiptNo: "REC468",
      address: "11 Elm St, City",
      oppCharges: 213,
      gstCharges: 19,
      OTTCharges: 54,
      totalAmount: 286,
      status: "Cancelled",
      JourneyDate: "2023-04-04",
    },
    {
      lastName: "Davis",
      firstName: "James",
      age: 61,
      gender: "Female",
      RegNo: "REG665",
      country: "India",
      receiptNo: "REC671",
      address: "823 Elm St, City",
      oppCharges: 217,
      gstCharges: 19,
      OTTCharges: 67,
      totalAmount: 303,
      status: "Cancelled",
      JourneyDate: "2023-02-15",
    },
    {
      lastName: "Davis",
      firstName: "Michael",
      age: 32,
      gender: "Female",
      RegNo: "REG94",
      country: "USA",
      receiptNo: "REC985",
      address: "182 Elm St, City",
      oppCharges: 230,
      gstCharges: 39,
      OTTCharges: 18,
      totalAmount: 287,
      status: "Paid",
      JourneyDate: "2023-09-09",
    },
    {
      lastName: "Davis",
      firstName: "David",
      age: 57,
      gender: "Male",
      RegNo: "REG253",
      country: "UK",
      receiptNo: "REC398",
      address: "119 Elm St, City",
      oppCharges: 185,
      gstCharges: 16,
      OTTCharges: 40,
      totalAmount: 241,
      status: "Pending",
      JourneyDate: "2023-03-26",
    },
    {
      lastName: "Davis",
      firstName: "Robert",
      age: 39,
      gender: "Female",
      RegNo: "REG504",
      country: "India",
      receiptNo: "REC555",
      address: "871 Elm St, City",
      oppCharges: 185,
      gstCharges: 33,
      OTTCharges: 36,
      totalAmount: 254,
      status: "Paid",
      JourneyDate: "2023-06-13",
    },
    {
      lastName: "Jones",
      firstName: "Michael",
      age: 32,
      gender: "Male",
      RegNo: "REG649",
      country: "USA",
      receiptNo: "REC666",
      address: "527 Elm St, City",
      oppCharges: 160,
      gstCharges: 31,
      OTTCharges: 85,
      totalAmount: 276,
      status: "Paid",
      JourneyDate: "2023-01-24",
    },
    {
      lastName: "Brown",
      firstName: "Robert",
      age: 47,
      gender: "Female",
      RegNo: "REG319",
      country: "Canada",
      receiptNo: "REC961",
      address: "835 Elm St, City",
      oppCharges: 83,
      gstCharges: 25,
      OTTCharges: 47,
      totalAmount: 155,
      status: "Cancelled",
      JourneyDate: "2023-07-12",
    },
    {
      lastName: "Smith",
      firstName: "John",
      age: 65,
      gender: "Female",
      RegNo: "REG771",
      country: "Australia",
      receiptNo: "REC477",
      address: "465 Elm St, City",
      oppCharges: 65,
      gstCharges: 36,
      OTTCharges: 75,
      totalAmount: 176,
      status: "Pending",
      JourneyDate: "2023-10-19",
    },
    {
      lastName: "Smith",
      firstName: "William",
      age: 32,
      gender: "Male",
      RegNo: "REG280",
      country: "USA",
      receiptNo: "REC74",
      address: "628 Elm St, City",
      oppCharges: 154,
      gstCharges: 22,
      OTTCharges: 87,
      totalAmount: 263,
      status: "Cancelled",
      JourneyDate: "2024-05-06",
    },
    {
      lastName: "Smith",
      firstName: "John",
      age: 52,
      gender: "Male",
      RegNo: "REG576",
      country: "Australia",
      receiptNo: "REC37",
      address: "970 Elm St, City",
      oppCharges: 217,
      gstCharges: 21,
      OTTCharges: 81,
      totalAmount: 319,
      status: "Cancelled",
      JourneyDate: "2023-04-27",
    },
    {
      lastName: "Brown",
      firstName: "William",
      age: 35,
      gender: "Male",
      RegNo: "REG524",
      country: "India",
      receiptNo: "REC928",
      address: "572 Elm St, City",
      oppCharges: 88,
      gstCharges: 21,
      OTTCharges: 37,
      totalAmount: 146,
      status: "Paid",
      JourneyDate: "2024-05-07",
    },
    {
      lastName: "Miller",
      firstName: "James",
      age: 40,
      gender: "Female",
      RegNo: "REG673",
      country: "Canada",
      receiptNo: "REC297",
      address: "901 Elm St, City",
      oppCharges: 125,
      gstCharges: 36,
      OTTCharges: 52,
      totalAmount: 213,
      status: "Cancelled",
      JourneyDate: "2023-10-29",
    },
    {
      lastName: "Smith",
      firstName: "Robert",
      age: 68,
      gender: "Female",
      RegNo: "REG261",
      country: "India",
      receiptNo: "REC662",
      address: "881 Elm St, City",
      oppCharges: 164,
      gstCharges: 21,
      OTTCharges: 84,
      totalAmount: 269,
      status: "Cancelled",
      JourneyDate: "2023-09-06",
    },
    {
      lastName: "Johnson",
      firstName: "John",
      age: 46,
      gender: "Male",
      RegNo: "REG848",
      country: "UK",
      receiptNo: "REC72",
      address: "17 Elm St, City",
      oppCharges: 238,
      gstCharges: 17,
      OTTCharges: 8,
      totalAmount: 263,
      status: "Paid",
      JourneyDate: "2023-03-16",
    },
    {
      lastName: "Jones",
      firstName: "John",
      age: 43,
      gender: "Female",
      RegNo: "REG798",
      country: "Australia",
      receiptNo: "REC217",
      address: "134 Elm St, City",
      oppCharges: 127,
      gstCharges: 21,
      OTTCharges: 33,
      totalAmount: 181,
      status: "Pending",
      JourneyDate: "2024-07-10",
    },
    {
      lastName: "Brown",
      firstName: "Michael",
      age: 49,
      gender: "Female",
      RegNo: "REG499",
      country: "USA",
      receiptNo: "REC649",
      address: "39 Elm St, City",
      oppCharges: 172,
      gstCharges: 21,
      OTTCharges: 82,
      totalAmount: 275,
      status: "Pending",
      JourneyDate: "2024-04-11",
    },
    {
      lastName: "Johnson",
      firstName: "William",
      age: 55,
      gender: "Female",
      RegNo: "REG438",
      country: "USA",
      receiptNo: "REC50",
      address: "769 Elm St, City",
      oppCharges: 79,
      gstCharges: 35,
      OTTCharges: 40,
      totalAmount: 154,
      status: "Cancelled",
      JourneyDate: "2024-05-31",
    },
    {
      lastName: "Smith",
      firstName: "Richard",
      age: 68,
      gender: "Male",
      RegNo: "REG43",
      country: "UK",
      receiptNo: "REC647",
      address: "733 Elm St, City",
      oppCharges: 222,
      gstCharges: 21,
      OTTCharges: 95,
      totalAmount: 338,
      status: "Paid",
      JourneyDate: "2023-08-07",
    },
    {
      lastName: "Jones",
      firstName: "David",
      age: 50,
      gender: "Male",
      RegNo: "REG657",
      country: "Australia",
      receiptNo: "REC719",
      address: "857 Elm St, City",
      oppCharges: 94,
      gstCharges: 17,
      OTTCharges: 36,
      totalAmount: 147,
      status: "Pending",
      JourneyDate: "2023-08-19",
    },
    {
      lastName: "Brown",
      firstName: "Robert",
      age: 28,
      gender: "Female",
      RegNo: "REG698",
      country: "Australia",
      receiptNo: "REC311",
      address: "641 Elm St, City",
      oppCharges: 141,
      gstCharges: 20,
      OTTCharges: 67,
      totalAmount: 228,
      status: "Pending",
      JourneyDate: "2023-02-01",
    },
    {
      lastName: "Miller",
      firstName: "Michael",
      age: 54,
      gender: "Male",
      RegNo: "REG929",
      country: "UK",
      receiptNo: "REC744",
      address: "992 Elm St, City",
      oppCharges: 164,
      gstCharges: 11,
      OTTCharges: 0,
      totalAmount: 175,
      status: "Paid",
      JourneyDate: "2024-03-22",
    },
    {
      lastName: "Johnson",
      firstName: "Robert",
      age: 57,
      gender: "Female",
      RegNo: "REG992",
      country: "USA",
      receiptNo: "REC269",
      address: "727 Elm St, City",
      oppCharges: 226,
      gstCharges: 33,
      OTTCharges: 73,
      totalAmount: 332,
      status: "Pending",
      JourneyDate: "2023-04-10",
    },
    {
      lastName: "Smith",
      firstName: "James",
      age: 64,
      gender: "Male",
      RegNo: "REG809",
      country: "Australia",
      receiptNo: "REC942",
      address: "402 Elm St, City",
      oppCharges: 131,
      gstCharges: 39,
      OTTCharges: 28,
      totalAmount: 198,
      status: "Cancelled",
      JourneyDate: "2024-01-23",
    },
    {
      lastName: "Brown",
      firstName: "Michael",
      age: 33,
      gender: "Male",
      RegNo: "REG148",
      country: "UK",
      receiptNo: "REC603",
      address: "818 Elm St, City",
      oppCharges: 243,
      gstCharges: 28,
      OTTCharges: 26,
      totalAmount: 297,
      status: "Pending",
      JourneyDate: "2023-02-28",
    },
    {
      lastName: "Williams",
      firstName: "Robert",
      age: 54,
      gender: "Female",
      RegNo: "REG924",
      country: "Australia",
      receiptNo: "REC888",
      address: "257 Elm St, City",
      oppCharges: 83,
      gstCharges: 33,
      OTTCharges: 34,
      totalAmount: 150,
      status: "Pending",
      JourneyDate: "2023-03-27",
    },
    {
      lastName: "Brown",
      firstName: "James",
      age: 39,
      gender: "Male",
      RegNo: "REG689",
      country: "USA",
      receiptNo: "REC897",
      address: "311 Elm St, City",
      oppCharges: 174,
      gstCharges: 12,
      OTTCharges: 67,
      totalAmount: 253,
      status: "Pending",
      JourneyDate: "2023-06-27",
    },
    {
      lastName: "Jones",
      firstName: "Michael",
      age: 28,
      gender: "Male",
      RegNo: "REG171",
      country: "Canada",
      receiptNo: "REC384",
      address: "969 Elm St, City",
      oppCharges: 136,
      gstCharges: 35,
      OTTCharges: 86,
      totalAmount: 257,
      status: "Pending",
      JourneyDate: "2024-01-14",
    },
    {
      lastName: "Jones",
      firstName: "Robert",
      age: 49,
      gender: "Male",
      RegNo: "REG839",
      country: "UK",
      receiptNo: "REC961",
      address: "549 Elm St, City",
      oppCharges: 72,
      gstCharges: 10,
      OTTCharges: 28,
      totalAmount: 110,
      status: "Paid",
      JourneyDate: "2023-01-24",
    },
    {
      lastName: "Williams",
      firstName: "Robert",
      age: 58,
      gender: "Male",
      RegNo: "REG361",
      country: "India",
      receiptNo: "REC451",
      address: "268 Elm St, City",
      oppCharges: 143,
      gstCharges: 34,
      OTTCharges: 7,
      totalAmount: 184,
      status: "Cancelled",
      JourneyDate: "2023-02-26",
    },
    {
      lastName: "Brown",
      firstName: "James",
      age: 28,
      gender: "Male",
      RegNo: "REG112",
      country: "UK",
      receiptNo: "REC623",
      address: "641 Elm St, City",
      oppCharges: 216,
      gstCharges: 19,
      OTTCharges: 73,
      totalAmount: 308,
      status: "Pending",
      JourneyDate: "2024-04-14",
    },
    {
      lastName: "Miller",
      firstName: "William",
      age: 42,
      gender: "Male",
      RegNo: "REG857",
      country: "India",
      receiptNo: "REC4",
      address: "380 Elm St, City",
      oppCharges: 235,
      gstCharges: 33,
      OTTCharges: 8,
      totalAmount: 276,
      status: "Paid",
      JourneyDate: "2024-03-13",
    },
    {
      lastName: "Smith",
      firstName: "John",
      age: 52,
      gender: "Male",
      RegNo: "REG924",
      country: "Canada",
      receiptNo: "REC996",
      address: "714 Elm St, City",
      oppCharges: 60,
      gstCharges: 28,
      OTTCharges: 52,
      totalAmount: 140,
      status: "Cancelled",
      JourneyDate: "2023-05-13",
    },
    {
      lastName: "Johnson",
      firstName: "Robert",
      age: 41,
      gender: "Male",
      RegNo: "REG674",
      country: "USA",
      receiptNo: "REC573",
      address: "982 Elm St, City",
      oppCharges: 154,
      gstCharges: 33,
      OTTCharges: 87,
      totalAmount: 274,
      status: "Paid",
      JourneyDate: "2023-05-19",
    },
    {
      lastName: "Davis",
      firstName: "William",
      age: 27,
      gender: "Female",
      RegNo: "REG643",
      country: "USA",
      receiptNo: "REC759",
      address: "198 Elm St, City",
      oppCharges: 223,
      gstCharges: 27,
      OTTCharges: 98,
      totalAmount: 348,
      status: "Pending",
      JourneyDate: "2023-09-10",
    },
    {
      lastName: "Brown",
      firstName: "Richard",
      age: 68,
      gender: "Male",
      RegNo: "REG151",
      country: "Canada",
      receiptNo: "REC249",
      address: "280 Elm St, City",
      oppCharges: 186,
      gstCharges: 39,
      OTTCharges: 52,
      totalAmount: 277,
      status: "Pending",
      JourneyDate: "2024-01-01",
    },
    {
      lastName: "Brown",
      firstName: "Richard",
      age: 43,
      gender: "Female",
      RegNo: "REG559",
      country: "UK",
      receiptNo: "REC66",
      address: "929 Elm St, City",
      oppCharges: 212,
      gstCharges: 37,
      OTTCharges: 49,
      totalAmount: 298,
      status: "Pending",
      JourneyDate: "2023-05-19",
    },
    {
      lastName: "Smith",
      firstName: "Michael",
      age: 57,
      gender: "Male",
      RegNo: "REG787",
      country: "Canada",
      receiptNo: "REC57",
      address: "969 Elm St, City",
      oppCharges: 213,
      gstCharges: 31,
      OTTCharges: 48,
      totalAmount: 292,
      status: "Pending",
      JourneyDate: "2023-09-11",
    },
    {
      lastName: "Smith",
      firstName: "James",
      age: 65,
      gender: "Female",
      RegNo: "REG239",
      country: "USA",
      receiptNo: "REC410",
      address: "790 Elm St, City",
      oppCharges: 153,
      gstCharges: 30,
      OTTCharges: 80,
      totalAmount: 263,
      status: "Cancelled",
      JourneyDate: "2024-03-20",
    },
    {
      lastName: "Davis",
      firstName: "David",
      age: 54,
      gender: "Female",
      RegNo: "REG252",
      country: "UK",
      receiptNo: "REC411",
      address: "96 Elm St, City",
      oppCharges: 141,
      gstCharges: 18,
      OTTCharges: 66,
      totalAmount: 225,
      status: "Pending",
      JourneyDate: "2024-07-06",
    },
    {
      lastName: "Williams",
      firstName: "David",
      age: 30,
      gender: "Male",
      RegNo: "REG242",
      country: "UK",
      receiptNo: "REC292",
      address: "161 Elm St, City",
      oppCharges: 73,
      gstCharges: 36,
      OTTCharges: 26,
      totalAmount: 135,
      status: "Pending",
      JourneyDate: "2023-07-11",
    },
    {
      lastName: "Jones",
      firstName: "Richard",
      age: 62,
      gender: "Male",
      RegNo: "REG445",
      country: "UK",
      receiptNo: "REC438",
      address: "809 Elm St, City",
      oppCharges: 63,
      gstCharges: 15,
      OTTCharges: 35,
      totalAmount: 113,
      status: "Paid",
      JourneyDate: "2024-02-14",
    },
    {
      lastName: "Jones",
      firstName: "Richard",
      age: 35,
      gender: "Male",
      RegNo: "REG698",
      country: "UK",
      receiptNo: "REC638",
      address: "566 Elm St, City",
      oppCharges: 184,
      gstCharges: 22,
      OTTCharges: 90,
      totalAmount: 296,
      status: "Paid",
      JourneyDate: "2024-06-10",
    },
    {
      lastName: "Johnson",
      firstName: "Robert",
      age: 23,
      gender: "Female",
      RegNo: "REG736",
      country: "USA",
      receiptNo: "REC927",
      address: "556 Elm St, City",
      oppCharges: 86,
      gstCharges: 37,
      OTTCharges: 97,
      totalAmount: 220,
      status: "Pending",
      JourneyDate: "2024-01-22",
    },
    {
      lastName: "Smith",
      firstName: "John",
      age: 41,
      gender: "Female",
      RegNo: "REG622",
      country: "USA",
      receiptNo: "REC889",
      address: "936 Elm St, City",
      oppCharges: 115,
      gstCharges: 33,
      OTTCharges: 16,
      totalAmount: 164,
      status: "Pending",
      JourneyDate: "2024-04-04",
    },
    {
      lastName: "Davis",
      firstName: "David",
      age: 20,
      gender: "Male",
      RegNo: "REG43",
      country: "UK",
      receiptNo: "REC553",
      address: "590 Elm St, City",
      oppCharges: 103,
      gstCharges: 22,
      OTTCharges: 20,
      totalAmount: 145,
      status: "Cancelled",
      JourneyDate: "2024-06-18",
    },
    {
      lastName: "Williams",
      firstName: "Richard",
      age: 46,
      gender: "Male",
      RegNo: "REG164",
      country: "USA",
      receiptNo: "REC765",
      address: "972 Elm St, City",
      oppCharges: 234,
      gstCharges: 33,
      OTTCharges: 18,
      totalAmount: 285,
      status: "Pending",
      JourneyDate: "2024-01-03",
    },
    {
      lastName: "Jones",
      firstName: "David",
      age: 46,
      gender: "Female",
      RegNo: "REG22",
      country: "Australia",
      receiptNo: "REC946",
      address: "915 Elm St, City",
      oppCharges: 96,
      gstCharges: 35,
      OTTCharges: 21,
      totalAmount: 152,
      status: "Paid",
      JourneyDate: "2023-01-04",
    },
    {
      lastName: "Williams",
      firstName: "William",
      age: 53,
      gender: "Male",
      RegNo: "REG106",
      country: "USA",
      receiptNo: "REC182",
      address: "390 Elm St, City",
      oppCharges: 177,
      gstCharges: 15,
      OTTCharges: 81,
      totalAmount: 273,
      status: "Paid",
      JourneyDate: "2023-10-07",
    },
    {
      lastName: "Miller",
      firstName: "David",
      age: 60,
      gender: "Female",
      RegNo: "REG224",
      country: "Canada",
      receiptNo: "REC32",
      address: "598 Elm St, City",
      oppCharges: 98,
      gstCharges: 14,
      OTTCharges: 45,
      totalAmount: 157,
      status: "Pending",
      JourneyDate: "2023-03-22",
    },
    {
      lastName: "Johnson",
      firstName: "John",
      age: 35,
      gender: "Female",
      RegNo: "REG585",
      country: "USA",
      receiptNo: "REC834",
      address: "650 Elm St, City",
      oppCharges: 165,
      gstCharges: 31,
      OTTCharges: 10,
      totalAmount: 206,
      status: "Paid",
      JourneyDate: "2023-05-23",
    },
    {
      lastName: "Smith",
      firstName: "John",
      age: 56,
      gender: "Female",
      RegNo: "REG794",
      country: "USA",
      receiptNo: "REC904",
      address: "299 Elm St, City",
      oppCharges: 224,
      gstCharges: 39,
      OTTCharges: 77,
      totalAmount: 340,
      status: "Pending",
      JourneyDate: "2024-04-18",
    },
    {
      lastName: "Brown",
      firstName: "Richard",
      age: 52,
      gender: "Male",
      RegNo: "REG660",
      country: "Canada",
      receiptNo: "REC943",
      address: "953 Elm St, City",
      oppCharges: 120,
      gstCharges: 22,
      OTTCharges: 58,
      totalAmount: 200,
      status: "Cancelled",
      JourneyDate: "2023-05-11",
    },
    {
      lastName: "Miller",
      firstName: "David",
      age: 67,
      gender: "Female",
      RegNo: "REG775",
      country: "USA",
      receiptNo: "REC335",
      address: "814 Elm St, City",
      oppCharges: 237,
      gstCharges: 16,
      OTTCharges: 44,
      totalAmount: 297,
      status: "Paid",
      JourneyDate: "2023-10-23",
    },
    {
      lastName: "Davis",
      firstName: "Michael",
      age: 59,
      gender: "Male",
      RegNo: "REG75",
      country: "Australia",
      receiptNo: "REC912",
      address: "761 Elm St, City",
      oppCharges: 180,
      gstCharges: 18,
      OTTCharges: 29,
      totalAmount: 227,
      status: "Paid",
      JourneyDate: "2023-06-12",
    },
    {
      lastName: "Brown",
      firstName: "Richard",
      age: 64,
      gender: "Female",
      RegNo: "REG741",
      country: "India",
      receiptNo: "REC206",
      address: "814 Elm St, City",
      oppCharges: 145,
      gstCharges: 39,
      OTTCharges: 14,
      totalAmount: 198,
      status: "Pending",
      JourneyDate: "2024-06-06",
    },
    {
      lastName: "Smith",
      firstName: "Robert",
      age: 43,
      gender: "Female",
      RegNo: "REG470",
      country: "Australia",
      receiptNo: "REC1",
      address: "428 Elm St, City",
      oppCharges: 142,
      gstCharges: 12,
      OTTCharges: 17,
      totalAmount: 171,
      status: "Pending",
      JourneyDate: "2023-11-11",
    },
    {
      lastName: "Smith",
      firstName: "John",
      age: 35,
      gender: "Female",
      RegNo: "REG242",
      country: "Canada",
      receiptNo: "REC322",
      address: "948 Elm St, City",
      oppCharges: 169,
      gstCharges: 32,
      OTTCharges: 87,
      totalAmount: 288,
      status: "Paid",
      JourneyDate: "2023-10-31",
    },
    {
      lastName: "Davis",
      firstName: "Robert",
      age: 37,
      gender: "Male",
      RegNo: "REG385",
      country: "UK",
      receiptNo: "REC206",
      address: "690 Elm St, City",
      oppCharges: 211,
      gstCharges: 29,
      OTTCharges: 52,
      totalAmount: 292,
      status: "Pending",
      JourneyDate: "2024-01-13",
    },
    {
      lastName: "Jones",
      firstName: "John",
      age: 57,
      gender: "Female",
      RegNo: "REG874",
      country: "UK",
      receiptNo: "REC557",
      address: "695 Elm St, City",
      oppCharges: 105,
      gstCharges: 28,
      OTTCharges: 32,
      totalAmount: 165,
      status: "Cancelled",
      JourneyDate: "2023-08-26",
    },
    {
      lastName: "Miller",
      firstName: "Michael",
      age: 62,
      gender: "Male",
      RegNo: "REG723",
      country: "UK",
      receiptNo: "REC908",
      address: "121 Elm St, City",
      oppCharges: 81,
      gstCharges: 32,
      OTTCharges: 31,
      totalAmount: 144,
      status: "Paid",
      JourneyDate: "2023-03-30",
    },
    {
      lastName: "Smith",
      firstName: "Michael",
      age: 37,
      gender: "Female",
      RegNo: "REG214",
      country: "Australia",
      receiptNo: "REC719",
      address: "151 Elm St, City",
      oppCharges: 68,
      gstCharges: 37,
      OTTCharges: 50,
      totalAmount: 155,
      status: "Cancelled",
      JourneyDate: "2024-02-28",
    },
    {
      lastName: "Johnson",
      firstName: "William",
      age: 61,
      gender: "Male",
      RegNo: "REG880",
      country: "India",
      receiptNo: "REC863",
      address: "32 Elm St, City",
      oppCharges: 236,
      gstCharges: 31,
      OTTCharges: 26,
      totalAmount: 293,
      status: "Cancelled",
      JourneyDate: "2024-05-12",
    },
    {
      lastName: "Johnson",
      firstName: "David",
      age: 35,
      gender: "Male",
      RegNo: "REG242",
      country: "Canada",
      receiptNo: "REC771",
      address: "615 Elm St, City",
      oppCharges: 159,
      gstCharges: 26,
      OTTCharges: 49,
      totalAmount: 234,
      status: "Cancelled",
      JourneyDate: "2023-06-15",
    },
    {
      lastName: "Williams",
      firstName: "John",
      age: 56,
      gender: "Male",
      RegNo: "REG77",
      country: "Australia",
      receiptNo: "REC956",
      address: "108 Elm St, City",
      oppCharges: 201,
      gstCharges: 38,
      OTTCharges: 51,
      totalAmount: 290,
      status: "Pending",
      JourneyDate: "2024-02-25",
    },
    {
      lastName: "Jones",
      firstName: "James",
      age: 68,
      gender: "Male",
      RegNo: "REG18",
      country: "USA",
      receiptNo: "REC143",
      address: "465 Elm St, City",
      oppCharges: 216,
      gstCharges: 27,
      OTTCharges: 60,
      totalAmount: 303,
      status: "Paid",
      JourneyDate: "2023-01-18",
    },
    {
      lastName: "Brown",
      firstName: "David",
      age: 46,
      gender: "Female",
      RegNo: "REG138",
      country: "USA",
      receiptNo: "REC587",
      address: "534 Elm St, City",
      oppCharges: 156,
      gstCharges: 21,
      OTTCharges: 62,
      totalAmount: 239,
      status: "Paid",
      JourneyDate: "2023-01-25",
    },
    {
      lastName: "Johnson",
      firstName: "Michael",
      age: 37,
      gender: "Male",
      RegNo: "REG601",
      country: "Australia",
      receiptNo: "REC610",
      address: "841 Elm St, City",
      oppCharges: 206,
      gstCharges: 36,
      OTTCharges: 51,
      totalAmount: 293,
      status: "Cancelled",
      JourneyDate: "2024-01-16",
    },
    {
      lastName: "Smith",
      firstName: "David",
      age: 23,
      gender: "Female",
      RegNo: "REG381",
      country: "India",
      receiptNo: "REC361",
      address: "578 Elm St, City",
      oppCharges: 171,
      gstCharges: 30,
      OTTCharges: 31,
      totalAmount: 232,
      status: "Pending",
      JourneyDate: "2024-06-05",
    },
    {
      lastName: "Brown",
      firstName: "William",
      age: 37,
      gender: "Male",
      RegNo: "REG326",
      country: "Canada",
      receiptNo: "REC997",
      address: "635 Elm St, City",
      oppCharges: 58,
      gstCharges: 19,
      OTTCharges: 79,
      totalAmount: 156,
      status: "Pending",
      JourneyDate: "2023-05-27",
    },
    {
      lastName: "Johnson",
      firstName: "John",
      age: 43,
      gender: "Male",
      RegNo: "REG721",
      country: "Australia",
      receiptNo: "REC738",
      address: "958 Elm St, City",
      oppCharges: 130,
      gstCharges: 27,
      OTTCharges: 71,
      totalAmount: 228,
      status: "Pending",
      JourneyDate: "2024-01-08",
    },
    {
      lastName: "Smith",
      firstName: "Robert",
      age: 60,
      gender: "Female",
      RegNo: "REG444",
      country: "UK",
      receiptNo: "REC398",
      address: "290 Elm St, City",
      oppCharges: 126,
      gstCharges: 19,
      OTTCharges: 33,
      totalAmount: 178,
      status: "Cancelled",
      JourneyDate: "2024-06-12",
    },
    {
      lastName: "Smith",
      firstName: "Michael",
      age: 32,
      gender: "Male",
      RegNo: "REG125",
      country: "Canada",
      receiptNo: "REC781",
      address: "366 Elm St, City",
      oppCharges: 177,
      gstCharges: 10,
      OTTCharges: 61,
      totalAmount: 248,
      status: "Paid",
      JourneyDate: "2023-07-04",
    },
    {
      lastName: "Miller",
      firstName: "Richard",
      age: 20,
      gender: "Female",
      RegNo: "REG173",
      country: "USA",
      receiptNo: "REC168",
      address: "11 Elm St, City",
      oppCharges: 73,
      gstCharges: 36,
      OTTCharges: 84,
      totalAmount: 193,
      status: "Pending",
      JourneyDate: "2024-01-15",
    },
    {
      lastName: "Miller",
      firstName: "William",
      age: 58,
      gender: "Male",
      RegNo: "REG842",
      country: "Canada",
      receiptNo: "REC709",
      address: "298 Elm St, City",
      oppCharges: 195,
      gstCharges: 28,
      OTTCharges: 66,
      totalAmount: 289,
      status: "Paid",
      JourneyDate: "2023-07-12",
    },
    {
      lastName: "Miller",
      firstName: "James",
      age: 31,
      gender: "Male",
      RegNo: "REG163",
      country: "Canada",
      receiptNo: "REC32",
      address: "929 Elm St, City",
      oppCharges: 98,
      gstCharges: 22,
      OTTCharges: 48,
      totalAmount: 168,
      status: "Pending",
      JourneyDate: "2024-01-25",
    },
    {
      lastName: "Brown",
      firstName: "David",
      age: 65,
      gender: "Male",
      RegNo: "REG424",
      country: "Canada",
      receiptNo: "REC113",
      address: "323 Elm St, City",
      oppCharges: 169,
      gstCharges: 34,
      OTTCharges: 34,
      totalAmount: 237,
      status: "Pending",
      JourneyDate: "2023-08-14",
    },
    {
      lastName: "Williams",
      firstName: "Michael",
      age: 61,
      gender: "Female",
      RegNo: "REG913",
      country: "India",
      receiptNo: "REC819",
      address: "912 Elm St, City",
      oppCharges: 244,
      gstCharges: 21,
      OTTCharges: 1,
      totalAmount: 266,
      status: "Pending",
      JourneyDate: "2023-05-20",
    },
    {
      lastName: "Jones",
      firstName: "John",
      age: 33,
      gender: "Male",
      RegNo: "REG827",
      country: "USA",
      receiptNo: "REC267",
      address: "715 Elm St, City",
      oppCharges: 178,
      gstCharges: 12,
      OTTCharges: 63,
      totalAmount: 253,
      status: "Paid",
      JourneyDate: "2023-01-05",
    },
    {
      lastName: "Smith",
      firstName: "William",
      age: 30,
      gender: "Female",
      RegNo: "REG313",
      country: "UK",
      receiptNo: "REC627",
      address: "916 Elm St, City",
      oppCharges: 219,
      gstCharges: 20,
      OTTCharges: 14,
      totalAmount: 253,
      status: "Pending",
      JourneyDate: "2024-05-05",
    },
    {
      lastName: "Miller",
      firstName: "Michael",
      age: 35,
      gender: "Female",
      RegNo: "REG919",
      country: "India",
      receiptNo: "REC443",
      address: "590 Elm St, City",
      oppCharges: 105,
      gstCharges: 24,
      OTTCharges: 64,
      totalAmount: 193,
      status: "Paid",
      JourneyDate: "2023-12-13",
    },
    {
      lastName: "Smith",
      firstName: "John",
      age: 59,
      gender: "Female",
      RegNo: "REG910",
      country: "Australia",
      receiptNo: "REC722",
      address: "454 Elm St, City",
      oppCharges: 231,
      gstCharges: 30,
      OTTCharges: 18,
      totalAmount: 279,
      status: "Cancelled",
      JourneyDate: "2024-03-31",
    },
    {
      lastName: "Miller",
      firstName: "James",
      age: 50,
      gender: "Female",
      RegNo: "REG202",
      country: "India",
      receiptNo: "REC761",
      address: "560 Elm St, City",
      oppCharges: 72,
      gstCharges: 18,
      OTTCharges: 58,
      totalAmount: 148,
      status: "Cancelled",
      JourneyDate: "2024-05-22",
    },
    {
      lastName: "Johnson",
      firstName: "Robert",
      age: 67,
      gender: "Female",
      RegNo: "REG274",
      country: "India",
      receiptNo: "REC400",
      address: "487 Elm St, City",
      oppCharges: 107,
      gstCharges: 15,
      OTTCharges: 21,
      totalAmount: 143,
      status: "Paid",
      JourneyDate: "2024-01-31",
    },
    {
      lastName: "Johnson",
      firstName: "William",
      age: 35,
      gender: "Male",
      RegNo: "REG873",
      country: "USA",
      receiptNo: "REC689",
      address: "392 Elm St, City",
      oppCharges: 130,
      gstCharges: 25,
      OTTCharges: 40,
      totalAmount: 195,
      status: "Cancelled",
      JourneyDate: "2023-02-07",
    },
    {
      lastName: "Miller",
      firstName: "John",
      age: 47,
      gender: "Male",
      RegNo: "REG309",
      country: "USA",
      receiptNo: "REC981",
      address: "19 Elm St, City",
      oppCharges: 59,
      gstCharges: 31,
      OTTCharges: 90,
      totalAmount: 180,
      status: "Cancelled",
      JourneyDate: "2024-02-29",
    },
    {
      lastName: "Williams",
      firstName: "David",
      age: 55,
      gender: "Male",
      RegNo: "REG639",
      country: "India",
      receiptNo: "REC773",
      address: "490 Elm St, City",
      oppCharges: 67,
      gstCharges: 32,
      OTTCharges: 42,
      totalAmount: 141,
      status: "Pending",
      JourneyDate: "2023-05-08",
    },
    {
      lastName: "Jones",
      firstName: "Robert",
      age: 34,
      gender: "Female",
      RegNo: "REG81",
      country: "Canada",
      receiptNo: "REC300",
      address: "995 Elm St, City",
      oppCharges: 211,
      gstCharges: 21,
      OTTCharges: 48,
      totalAmount: 280,
      status: "Paid",
      JourneyDate: "2024-06-27",
    },
    {
      lastName: "Smith",
      firstName: "William",
      age: 62,
      gender: "Male",
      RegNo: "REG33",
      country: "UK",
      receiptNo: "REC527",
      address: "52 Elm St, City",
      oppCharges: 151,
      gstCharges: 17,
      OTTCharges: 14,
      totalAmount: 182,
      status: "Pending",
      JourneyDate: "2023-04-05",
    },
    {
      lastName: "Johnson",
      firstName: "Richard",
      age: 51,
      gender: "Male",
      RegNo: "REG266",
      country: "Canada",
      receiptNo: "REC530",
      address: "614 Elm St, City",
      oppCharges: 237,
      gstCharges: 27,
      OTTCharges: 24,
      totalAmount: 288,
      status: "Pending",
      JourneyDate: "2023-03-06",
    },
    {
      lastName: "Jones",
      firstName: "John",
      age: 69,
      gender: "Female",
      RegNo: "REG434",
      country: "Canada",
      receiptNo: "REC303",
      address: "706 Elm St, City",
      oppCharges: 83,
      gstCharges: 23,
      OTTCharges: 58,
      totalAmount: 164,
      status: "Cancelled",
      JourneyDate: "2023-11-14",
    },
    {
      lastName: "Williams",
      firstName: "Robert",
      age: 28,
      gender: "Male",
      RegNo: "REG70",
      country: "USA",
      receiptNo: "REC318",
      address: "907 Elm St, City",
      oppCharges: 171,
      gstCharges: 17,
      OTTCharges: 37,
      totalAmount: 225,
      status: "Paid",
      JourneyDate: "2023-07-13",
    },
    {
      lastName: "Johnson",
      firstName: "David",
      age: 67,
      gender: "Female",
      RegNo: "REG659",
      country: "India",
      receiptNo: "REC229",
      address: "141 Elm St, City",
      oppCharges: 224,
      gstCharges: 26,
      OTTCharges: 28,
      totalAmount: 278,
      status: "Cancelled",
      JourneyDate: "2024-06-18",
    },
    {
      lastName: "Smith",
      firstName: "David",
      age: 27,
      gender: "Female",
      RegNo: "REG867",
      country: "India",
      receiptNo: "REC876",
      address: "724 Elm St, City",
      oppCharges: 150,
      gstCharges: 10,
      OTTCharges: 29,
      totalAmount: 189,
      status: "Pending",
      JourneyDate: "2024-04-28",
    },
    {
      lastName: "Williams",
      firstName: "John",
      age: 63,
      gender: "Male",
      RegNo: "REG838",
      country: "India",
      receiptNo: "REC115",
      address: "180 Elm St, City",
      oppCharges: 143,
      gstCharges: 22,
      OTTCharges: 36,
      totalAmount: 201,
      status: "Cancelled",
      JourneyDate: "2023-05-28",
    },
    {
      lastName: "Williams",
      firstName: "Richard",
      age: 52,
      gender: "Male",
      RegNo: "REG533",
      country: "Canada",
      receiptNo: "REC83",
      address: "992 Elm St, City",
      oppCharges: 123,
      gstCharges: 34,
      OTTCharges: 32,
      totalAmount: 189,
      status: "Paid",
      JourneyDate: "2023-03-14",
    },
    {
      lastName: "Brown",
      firstName: "William",
      age: 55,
      gender: "Male",
      RegNo: "REG300",
      country: "UK",
      receiptNo: "REC821",
      address: "454 Elm St, City",
      oppCharges: 187,
      gstCharges: 14,
      OTTCharges: 17,
      totalAmount: 218,
      status: "Cancelled",
      JourneyDate: "2023-10-04",
    },
    {
      lastName: "Brown",
      firstName: "Michael",
      age: 42,
      gender: "Male",
      RegNo: "REG223",
      country: "UK",
      receiptNo: "REC603",
      address: "730 Elm St, City",
      oppCharges: 107,
      gstCharges: 20,
      OTTCharges: 77,
      totalAmount: 204,
      status: "Paid",
      JourneyDate: "2023-06-16",
    },
    {
      lastName: "Williams",
      firstName: "Richard",
      age: 31,
      gender: "Male",
      RegNo: "REG560",
      country: "Canada",
      receiptNo: "REC114",
      address: "687 Elm St, City",
      oppCharges: 51,
      gstCharges: 27,
      OTTCharges: 94,
      totalAmount: 172,
      status: "Pending",
      JourneyDate: "2023-10-16",
    },
    {
      lastName: "Brown",
      firstName: "Robert",
      age: 22,
      gender: "Female",
      RegNo: "REG86",
      country: "USA",
      receiptNo: "REC230",
      address: "723 Elm St, City",
      oppCharges: 228,
      gstCharges: 35,
      OTTCharges: 89,
      totalAmount: 352,
      status: "Cancelled",
      JourneyDate: "2023-09-05",
    },
    {
      lastName: "Williams",
      firstName: "James",
      age: 24,
      gender: "Male",
      RegNo: "REG381",
      country: "India",
      receiptNo: "REC693",
      address: "38 Elm St, City",
      oppCharges: 163,
      gstCharges: 11,
      OTTCharges: 72,
      totalAmount: 246,
      status: "Pending",
      JourneyDate: "2024-03-14",
    },
    {
      lastName: "Smith",
      firstName: "David",
      age: 39,
      gender: "Male",
      RegNo: "REG112",
      country: "UK",
      receiptNo: "REC734",
      address: "290 Elm St, City",
      oppCharges: 154,
      gstCharges: 16,
      OTTCharges: 37,
      totalAmount: 207,
      status: "Cancelled",
      JourneyDate: "2023-07-04",
    },
    {
      lastName: "Williams",
      firstName: "William",
      age: 38,
      gender: "Female",
      RegNo: "REG986",
      country: "Australia",
      receiptNo: "REC583",
      address: "23 Elm St, City",
      oppCharges: 124,
      gstCharges: 31,
      OTTCharges: 57,
      totalAmount: 212,
      status: "Cancelled",
      JourneyDate: "2023-12-02",
    },
    {
      lastName: "Williams",
      firstName: "James",
      age: 63,
      gender: "Female",
      RegNo: "REG383",
      country: "India",
      receiptNo: "REC574",
      address: "285 Elm St, City",
      oppCharges: 179,
      gstCharges: 11,
      OTTCharges: 17,
      totalAmount: 207,
      status: "Pending",
      JourneyDate: "2024-05-18",
    },
    {
      lastName: "Smith",
      firstName: "Robert",
      age: 20,
      gender: "Male",
      RegNo: "REG914",
      country: "Australia",
      receiptNo: "REC915",
      address: "811 Elm St, City",
      oppCharges: 57,
      gstCharges: 24,
      OTTCharges: 58,
      totalAmount: 139,
      status: "Pending",
      JourneyDate: "2023-10-24",
    },
    {
      lastName: "Johnson",
      firstName: "William",
      age: 49,
      gender: "Male",
      RegNo: "REG318",
      country: "UK",
      receiptNo: "REC267",
      address: "38 Elm St, City",
      oppCharges: 199,
      gstCharges: 19,
      OTTCharges: 37,
      totalAmount: 255,
      status: "Pending",
      JourneyDate: "2023-08-25",
    },
    {
      lastName: "Brown",
      firstName: "John",
      age: 68,
      gender: "Male",
      RegNo: "REG77",
      country: "India",
      receiptNo: "REC623",
      address: "68 Elm St, City",
      oppCharges: 104,
      gstCharges: 26,
      OTTCharges: 88,
      totalAmount: 218,
      status: "Paid",
      JourneyDate: "2023-07-05",
    },
    {
      lastName: "Williams",
      firstName: "Richard",
      age: 51,
      gender: "Male",
      RegNo: "REG15",
      country: "Australia",
      receiptNo: "REC238",
      address: "42 Elm St, City",
      oppCharges: 67,
      gstCharges: 21,
      OTTCharges: 89,
      totalAmount: 177,
      status: "Cancelled",
      JourneyDate: "2023-04-07",
    },
    {
      lastName: "Brown",
      firstName: "John",
      age: 27,
      gender: "Male",
      RegNo: "REG372",
      country: "Canada",
      receiptNo: "REC77",
      address: "363 Elm St, City",
      oppCharges: 205,
      gstCharges: 11,
      OTTCharges: 64,
      totalAmount: 280,
      status: "Cancelled",
      JourneyDate: "2024-04-30",
    },
    {
      lastName: "Jones",
      firstName: "Richard",
      age: 44,
      gender: "Female",
      RegNo: "REG260",
      country: "Canada",
      receiptNo: "REC819",
      address: "412 Elm St, City",
      oppCharges: 74,
      gstCharges: 17,
      OTTCharges: 14,
      totalAmount: 105,
      status: "Paid",
      JourneyDate: "2024-04-07",
    },
    {
      lastName: "Johnson",
      firstName: "James",
      age: 64,
      gender: "Male",
      RegNo: "REG722",
      country: "Australia",
      receiptNo: "REC707",
      address: "408 Elm St, City",
      oppCharges: 59,
      gstCharges: 29,
      OTTCharges: 66,
      totalAmount: 154,
      status: "Pending",
      JourneyDate: "2024-01-17",
    },
    {
      lastName: "Jones",
      firstName: "Michael",
      age: 25,
      gender: "Male",
      RegNo: "REG0",
      country: "USA",
      receiptNo: "REC944",
      address: "166 Elm St, City",
      oppCharges: 159,
      gstCharges: 23,
      OTTCharges: 52,
      totalAmount: 234,
      status: "Cancelled",
      JourneyDate: "2024-06-19",
    },
    {
      lastName: "Miller",
      firstName: "James",
      age: 27,
      gender: "Female",
      RegNo: "REG115",
      country: "India",
      receiptNo: "REC877",
      address: "652 Elm St, City",
      oppCharges: 152,
      gstCharges: 18,
      OTTCharges: 87,
      totalAmount: 257,
      status: "Paid",
      JourneyDate: "2023-03-20",
    },
    {
      lastName: "Smith",
      firstName: "Richard",
      age: 22,
      gender: "Female",
      RegNo: "REG391",
      country: "UK",
      receiptNo: "REC350",
      address: "414 Elm St, City",
      oppCharges: 67,
      gstCharges: 38,
      OTTCharges: 73,
      totalAmount: 178,
      status: "Paid",
      JourneyDate: "2023-05-12",
    },
    {
      lastName: "Davis",
      firstName: "Richard",
      age: 26,
      gender: "Male",
      RegNo: "REG800",
      country: "USA",
      receiptNo: "REC739",
      address: "252 Elm St, City",
      oppCharges: 242,
      gstCharges: 24,
      OTTCharges: 97,
      totalAmount: 363,
      status: "Cancelled",
      JourneyDate: "2023-05-12",
    },
    {
      lastName: "Smith",
      firstName: "Richard",
      age: 40,
      gender: "Female",
      RegNo: "REG360",
      country: "India",
      receiptNo: "REC843",
      address: "990 Elm St, City",
      oppCharges: 61,
      gstCharges: 33,
      OTTCharges: 7,
      totalAmount: 101,
      status: "Pending",
      JourneyDate: "2024-05-07",
    },
    {
      lastName: "Johnson",
      firstName: "William",
      age: 55,
      gender: "Female",
      RegNo: "REG961",
      country: "UK",
      receiptNo: "REC669",
      address: "593 Elm St, City",
      oppCharges: 70,
      gstCharges: 11,
      OTTCharges: 95,
      totalAmount: 176,
      status: "Cancelled",
      JourneyDate: "2024-04-27",
    },
    {
      lastName: "Davis",
      firstName: "John",
      age: 48,
      gender: "Female",
      RegNo: "REG843",
      country: "UK",
      receiptNo: "REC582",
      address: "557 Elm St, City",
      oppCharges: 95,
      gstCharges: 26,
      OTTCharges: 14,
      totalAmount: 135,
      status: "Pending",
      JourneyDate: "2023-02-01",
    },
    {
      lastName: "Brown",
      firstName: "James",
      age: 44,
      gender: "Female",
      RegNo: "REG87",
      country: "USA",
      receiptNo: "REC667",
      address: "684 Elm St, City",
      oppCharges: 232,
      gstCharges: 32,
      OTTCharges: 57,
      totalAmount: 321,
      status: "Cancelled",
      JourneyDate: "2024-02-23",
    },
    {
      lastName: "Davis",
      firstName: "William",
      age: 35,
      gender: "Female",
      RegNo: "REG308",
      country: "USA",
      receiptNo: "REC43",
      address: "533 Elm St, City",
      oppCharges: 226,
      gstCharges: 10,
      OTTCharges: 45,
      totalAmount: 281,
      status: "Cancelled",
      JourneyDate: "2023-09-05",
    },
    {
      lastName: "Smith",
      firstName: "Michael",
      age: 68,
      gender: "Male",
      RegNo: "REG895",
      country: "Canada",
      receiptNo: "REC621",
      address: "72 Elm St, City",
      oppCharges: 113,
      gstCharges: 18,
      OTTCharges: 97,
      totalAmount: 228,
      status: "Paid",
      JourneyDate: "2024-05-31",
    },
    {
      lastName: "Miller",
      firstName: "David",
      age: 27,
      gender: "Male",
      RegNo: "REG603",
      country: "India",
      receiptNo: "REC308",
      address: "484 Elm St, City",
      oppCharges: 93,
      gstCharges: 31,
      OTTCharges: 60,
      totalAmount: 184,
      status: "Cancelled",
      JourneyDate: "2023-08-20",
    },
    {
      lastName: "Williams",
      firstName: "John",
      age: 66,
      gender: "Male",
      RegNo: "REG532",
      country: "UK",
      receiptNo: "REC651",
      address: "860 Elm St, City",
      oppCharges: 99,
      gstCharges: 30,
      OTTCharges: 13,
      totalAmount: 142,
      status: "Pending",
      JourneyDate: "2024-07-04",
    },
    {
      lastName: "Smith",
      firstName: "Robert",
      age: 47,
      gender: "Female",
      RegNo: "REG752",
      country: "India",
      receiptNo: "REC82",
      address: "854 Elm St, City",
      oppCharges: 170,
      gstCharges: 11,
      OTTCharges: 39,
      totalAmount: 220,
      status: "Paid",
      JourneyDate: "2024-03-23",
    },
    {
      lastName: "Brown",
      firstName: "David",
      age: 59,
      gender: "Male",
      RegNo: "REG615",
      country: "USA",
      receiptNo: "REC373",
      address: "296 Elm St, City",
      oppCharges: 219,
      gstCharges: 25,
      OTTCharges: 93,
      totalAmount: 337,
      status: "Paid",
      JourneyDate: "2024-05-07",
    },
    {
      lastName: "Williams",
      firstName: "Richard",
      age: 30,
      gender: "Male",
      RegNo: "REG545",
      country: "Canada",
      receiptNo: "REC810",
      address: "337 Elm St, City",
      oppCharges: 200,
      gstCharges: 32,
      OTTCharges: 0,
      totalAmount: 232,
      status: "Paid",
      JourneyDate: "2023-07-03",
    },
    {
      lastName: "Williams",
      firstName: "Richard",
      age: 58,
      gender: "Male",
      RegNo: "REG323",
      country: "Canada",
      receiptNo: "REC758",
      address: "735 Elm St, City",
      oppCharges: 169,
      gstCharges: 20,
      OTTCharges: 72,
      totalAmount: 261,
      status: "Pending",
      JourneyDate: "2023-09-29",
    },
    {
      lastName: "Brown",
      firstName: "William",
      age: 43,
      gender: "Female",
      RegNo: "REG383",
      country: "USA",
      receiptNo: "REC186",
      address: "432 Elm St, City",
      oppCharges: 220,
      gstCharges: 32,
      OTTCharges: 89,
      totalAmount: 341,
      status: "Pending",
      JourneyDate: "2023-12-11",
    },
    {
      lastName: "Johnson",
      firstName: "David",
      age: 39,
      gender: "Female",
      RegNo: "REG152",
      country: "India",
      receiptNo: "REC883",
      address: "858 Elm St, City",
      oppCharges: 183,
      gstCharges: 26,
      OTTCharges: 52,
      totalAmount: 261,
      status: "Paid",
      JourneyDate: "2023-03-16",
    },
    {
      lastName: "Davis",
      firstName: "William",
      age: 22,
      gender: "Female",
      RegNo: "REG6",
      country: "USA",
      receiptNo: "REC608",
      address: "14 Elm St, City",
      oppCharges: 79,
      gstCharges: 37,
      OTTCharges: 45,
      totalAmount: 161,
      status: "Pending",
      JourneyDate: "2024-01-20",
    },
    {
      lastName: "Williams",
      firstName: "Robert",
      age: 42,
      gender: "Male",
      RegNo: "REG392",
      country: "UK",
      receiptNo: "REC134",
      address: "512 Elm St, City",
      oppCharges: 56,
      gstCharges: 38,
      OTTCharges: 97,
      totalAmount: 191,
      status: "Pending",
      JourneyDate: "2023-06-06",
    },
    {
      lastName: "Jones",
      firstName: "William",
      age: 34,
      gender: "Female",
      RegNo: "REG407",
      country: "UK",
      receiptNo: "REC208",
      address: "271 Elm St, City",
      oppCharges: 85,
      gstCharges: 34,
      OTTCharges: 99,
      totalAmount: 218,
      status: "Pending",
      JourneyDate: "2023-07-17",
    },
    {
      lastName: "Miller",
      firstName: "Richard",
      age: 65,
      gender: "Male",
      RegNo: "REG827",
      country: "Canada",
      receiptNo: "REC662",
      address: "152 Elm St, City",
      oppCharges: 240,
      gstCharges: 39,
      OTTCharges: 35,
      totalAmount: 314,
      status: "Pending",
      JourneyDate: "2024-03-22",
    },
    {
      lastName: "Smith",
      firstName: "Michael",
      age: 39,
      gender: "Female",
      RegNo: "REG339",
      country: "USA",
      receiptNo: "REC228",
      address: "291 Elm St, City",
      oppCharges: 119,
      gstCharges: 30,
      OTTCharges: 63,
      totalAmount: 212,
      status: "Pending",
      JourneyDate: "2023-08-12",
    },
    {
      lastName: "Brown",
      firstName: "David",
      age: 56,
      gender: "Male",
      RegNo: "REG222",
      country: "USA",
      receiptNo: "REC772",
      address: "244 Elm St, City",
      oppCharges: 236,
      gstCharges: 24,
      OTTCharges: 95,
      totalAmount: 355,
      status: "Paid",
      JourneyDate: "2023-12-05",
    },
    {
      lastName: "Williams",
      firstName: "John",
      age: 38,
      gender: "Male",
      RegNo: "REG188",
      country: "India",
      receiptNo: "REC241",
      address: "267 Elm St, City",
      oppCharges: 109,
      gstCharges: 30,
      OTTCharges: 34,
      totalAmount: 173,
      status: "Cancelled",
      JourneyDate: "2023-03-15",
    },
    {
      lastName: "Miller",
      firstName: "William",
      age: 49,
      gender: "Female",
      RegNo: "REG898",
      country: "India",
      receiptNo: "REC588",
      address: "945 Elm St, City",
      oppCharges: 105,
      gstCharges: 19,
      OTTCharges: 82,
      totalAmount: 206,
      status: "Paid",
      JourneyDate: "2024-01-17",
    },
    {
      lastName: "Brown",
      firstName: "Robert",
      age: 52,
      gender: "Male",
      RegNo: "REG748",
      country: "India",
      receiptNo: "REC757",
      address: "461 Elm St, City",
      oppCharges: 63,
      gstCharges: 23,
      OTTCharges: 52,
      totalAmount: 138,
      status: "Cancelled",
      JourneyDate: "2023-03-05",
    },
    {
      lastName: "Brown",
      firstName: "Robert",
      age: 43,
      gender: "Female",
      RegNo: "REG936",
      country: "USA",
      receiptNo: "REC469",
      address: "540 Elm St, City",
      oppCharges: 222,
      gstCharges: 14,
      OTTCharges: 86,
      totalAmount: 322,
      status: "Pending",
      JourneyDate: "2024-04-23",
    },
    {
      lastName: "Johnson",
      firstName: "Richard",
      age: 45,
      gender: "Male",
      RegNo: "REG976",
      country: "India",
      receiptNo: "REC320",
      address: "597 Elm St, City",
      oppCharges: 170,
      gstCharges: 34,
      OTTCharges: 85,
      totalAmount: 289,
      status: "Paid",
      JourneyDate: "2023-06-02",
    },
    {
      lastName: "Jones",
      firstName: "James",
      age: 29,
      gender: "Female",
      RegNo: "REG495",
      country: "USA",
      receiptNo: "REC798",
      address: "106 Elm St, City",
      oppCharges: 230,
      gstCharges: 19,
      OTTCharges: 21,
      totalAmount: 270,
      status: "Cancelled",
      JourneyDate: "2024-06-18",
    },
    {
      lastName: "Brown",
      firstName: "Robert",
      age: 44,
      gender: "Male",
      RegNo: "REG373",
      country: "Canada",
      receiptNo: "REC393",
      address: "240 Elm St, City",
      oppCharges: 171,
      gstCharges: 27,
      OTTCharges: 31,
      totalAmount: 229,
      status: "Cancelled",
      JourneyDate: "2023-05-09",
    },
    {
      lastName: "Miller",
      firstName: "Richard",
      age: 43,
      gender: "Female",
      RegNo: "REG11",
      country: "Australia",
      receiptNo: "REC293",
      address: "172 Elm St, City",
      oppCharges: 193,
      gstCharges: 11,
      OTTCharges: 21,
      totalAmount: 225,
      status: "Paid",
      JourneyDate: "2023-09-11",
    },
    {
      lastName: "Brown",
      firstName: "James",
      age: 31,
      gender: "Female",
      RegNo: "REG781",
      country: "USA",
      receiptNo: "REC148",
      address: "963 Elm St, City",
      oppCharges: 68,
      gstCharges: 30,
      OTTCharges: 56,
      totalAmount: 154,
      status: "Pending",
      JourneyDate: "2023-02-25",
    },
    {
      lastName: "Brown",
      firstName: "William",
      age: 34,
      gender: "Female",
      RegNo: "REG481",
      country: "Canada",
      receiptNo: "REC805",
      address: "865 Elm St, City",
      oppCharges: 167,
      gstCharges: 39,
      OTTCharges: 24,
      totalAmount: 230,
      status: "Paid",
      JourneyDate: "2024-03-15",
    },
    {
      lastName: "Davis",
      firstName: "William",
      age: 24,
      gender: "Female",
      RegNo: "REG540",
      country: "Canada",
      receiptNo: "REC722",
      address: "829 Elm St, City",
      oppCharges: 237,
      gstCharges: 39,
      OTTCharges: 23,
      totalAmount: 299,
      status: "Paid",
      JourneyDate: "2023-12-16",
    },
    {
      lastName: "Miller",
      firstName: "John",
      age: 43,
      gender: "Male",
      RegNo: "REG953",
      country: "USA",
      receiptNo: "REC829",
      address: "499 Elm St, City",
      oppCharges: 171,
      gstCharges: 17,
      OTTCharges: 15,
      totalAmount: 203,
      status: "Cancelled",
      JourneyDate: "2024-03-23",
    },
    {
      lastName: "Davis",
      firstName: "Richard",
      age: 68,
      gender: "Male",
      RegNo: "REG614",
      country: "UK",
      receiptNo: "REC903",
      address: "724 Elm St, City",
      oppCharges: 50,
      gstCharges: 36,
      OTTCharges: 72,
      totalAmount: 158,
      status: "Cancelled",
      JourneyDate: "2024-01-16",
    },
    {
      lastName: "Johnson",
      firstName: "William",
      age: 44,
      gender: "Female",
      RegNo: "REG96",
      country: "USA",
      receiptNo: "REC447",
      address: "531 Elm St, City",
      oppCharges: 171,
      gstCharges: 13,
      OTTCharges: 30,
      totalAmount: 214,
      status: "Paid",
      JourneyDate: "2024-03-20",
    },
    {
      lastName: "Williams",
      firstName: "Michael",
      age: 47,
      gender: "Female",
      RegNo: "REG553",
      country: "Australia",
      receiptNo: "REC704",
      address: "154 Elm St, City",
      oppCharges: 167,
      gstCharges: 18,
      OTTCharges: 5,
      totalAmount: 190,
      status: "Paid",
      JourneyDate: "2023-08-25",
    },
    {
      lastName: "Smith",
      firstName: "Robert",
      age: 34,
      gender: "Female",
      RegNo: "REG807",
      country: "India",
      receiptNo: "REC739",
      address: "643 Elm St, City",
      oppCharges: 95,
      gstCharges: 18,
      OTTCharges: 96,
      totalAmount: 209,
      status: "Cancelled",
      JourneyDate: "2023-06-03",
    },
    {
      lastName: "Johnson",
      firstName: "Michael",
      age: 67,
      gender: "Female",
      RegNo: "REG320",
      country: "Canada",
      receiptNo: "REC27",
      address: "977 Elm St, City",
      oppCharges: 153,
      gstCharges: 32,
      OTTCharges: 68,
      totalAmount: 253,
      status: "Paid",
      JourneyDate: "2024-02-23",
    },
    {
      lastName: "Davis",
      firstName: "James",
      age: 27,
      gender: "Female",
      RegNo: "REG42",
      country: "Australia",
      receiptNo: "REC919",
      address: "863 Elm St, City",
      oppCharges: 173,
      gstCharges: 38,
      OTTCharges: 95,
      totalAmount: 306,
      status: "Cancelled",
      JourneyDate: "2023-11-26",
    },
    {
      lastName: "Brown",
      firstName: "Michael",
      age: 22,
      gender: "Male",
      RegNo: "REG266",
      country: "Australia",
      receiptNo: "REC388",
      address: "785 Elm St, City",
      oppCharges: 63,
      gstCharges: 34,
      OTTCharges: 96,
      totalAmount: 193,
      status: "Paid",
      JourneyDate: "2023-05-18",
    },
    {
      lastName: "Jones",
      firstName: "William",
      age: 68,
      gender: "Female",
      RegNo: "REG273",
      country: "Canada",
      receiptNo: "REC213",
      address: "113 Elm St, City",
      oppCharges: 164,
      gstCharges: 29,
      OTTCharges: 63,
      totalAmount: 256,
      status: "Paid",
      JourneyDate: "2023-02-20",
    },
    {
      lastName: "Jones",
      firstName: "William",
      age: 31,
      gender: "Male",
      RegNo: "REG390",
      country: "India",
      receiptNo: "REC72",
      address: "738 Elm St, City",
      oppCharges: 117,
      gstCharges: 27,
      OTTCharges: 83,
      totalAmount: 227,
      status: "Cancelled",
      JourneyDate: "2023-10-20",
    },
    {
      lastName: "Johnson",
      firstName: "John",
      age: 60,
      gender: "Female",
      RegNo: "REG867",
      country: "USA",
      receiptNo: "REC672",
      address: "839 Elm St, City",
      oppCharges: 192,
      gstCharges: 24,
      OTTCharges: 63,
      totalAmount: 279,
      status: "Cancelled",
      JourneyDate: "2024-04-13",
    },
    {
      lastName: "Johnson",
      firstName: "William",
      age: 61,
      gender: "Female",
      RegNo: "REG400",
      country: "Australia",
      receiptNo: "REC965",
      address: "776 Elm St, City",
      oppCharges: 239,
      gstCharges: 18,
      OTTCharges: 60,
      totalAmount: 317,
      status: "Pending",
      JourneyDate: "2024-04-09",
    },
    {
      lastName: "Williams",
      firstName: "John",
      age: 57,
      gender: "Male",
      RegNo: "REG247",
      country: "UK",
      receiptNo: "REC45",
      address: "858 Elm St, City",
      oppCharges: 65,
      gstCharges: 16,
      OTTCharges: 45,
      totalAmount: 126,
      status: "Paid",
      JourneyDate: "2024-04-23",
    },
    {
      lastName: "Miller",
      firstName: "James",
      age: 63,
      gender: "Female",
      RegNo: "REG909",
      country: "Australia",
      receiptNo: "REC701",
      address: "894 Elm St, City",
      oppCharges: 185,
      gstCharges: 24,
      OTTCharges: 4,
      totalAmount: 213,
      status: "Pending",
      JourneyDate: "2024-02-29",
    },
    {
      lastName: "Miller",
      firstName: "John",
      age: 47,
      gender: "Female",
      RegNo: "REG164",
      country: "USA",
      receiptNo: "REC793",
      address: "358 Elm St, City",
      oppCharges: 78,
      gstCharges: 19,
      OTTCharges: 75,
      totalAmount: 172,
      status: "Pending",
      JourneyDate: "2023-06-14",
    },
    {
      lastName: "Davis",
      firstName: "Michael",
      age: 30,
      gender: "Male",
      RegNo: "REG627",
      country: "Australia",
      receiptNo: "REC824",
      address: "83 Elm St, City",
      oppCharges: 215,
      gstCharges: 23,
      OTTCharges: 54,
      totalAmount: 292,
      status: "Pending",
      JourneyDate: "2023-10-16",
    },
    {
      lastName: "Jones",
      firstName: "David",
      age: 69,
      gender: "Female",
      RegNo: "REG280",
      country: "UK",
      receiptNo: "REC914",
      address: "633 Elm St, City",
      oppCharges: 135,
      gstCharges: 23,
      OTTCharges: 66,
      totalAmount: 224,
      status: "Pending",
      JourneyDate: "2023-06-03",
    },
    {
      lastName: "Smith",
      firstName: "David",
      age: 29,
      gender: "Female",
      RegNo: "REG218",
      country: "UK",
      receiptNo: "REC927",
      address: "694 Elm St, City",
      oppCharges: 118,
      gstCharges: 38,
      OTTCharges: 73,
      totalAmount: 229,
      status: "Pending",
      JourneyDate: "2024-03-18",
    },
    {
      lastName: "Smith",
      firstName: "Richard",
      age: 36,
      gender: "Female",
      RegNo: "REG353",
      country: "Canada",
      receiptNo: "REC496",
      address: "906 Elm St, City",
      oppCharges: 167,
      gstCharges: 18,
      OTTCharges: 80,
      totalAmount: 265,
      status: "Pending",
      JourneyDate: "2023-08-02",
    },
    {
      lastName: "Jones",
      firstName: "Robert",
      age: 55,
      gender: "Female",
      RegNo: "REG244",
      country: "UK",
      receiptNo: "REC448",
      address: "42 Elm St, City",
      oppCharges: 214,
      gstCharges: 36,
      OTTCharges: 96,
      totalAmount: 346,
      status: "Pending",
      JourneyDate: "2024-05-15",
    },
    {
      lastName: "Jones",
      firstName: "William",
      age: 41,
      gender: "Female",
      RegNo: "REG653",
      country: "Australia",
      receiptNo: "REC329",
      address: "492 Elm St, City",
      oppCharges: 155,
      gstCharges: 31,
      OTTCharges: 28,
      totalAmount: 214,
      status: "Cancelled",
      JourneyDate: "2023-04-30",
    },
    {
      lastName: "Williams",
      firstName: "Robert",
      age: 48,
      gender: "Female",
      RegNo: "REG722",
      country: "Canada",
      receiptNo: "REC19",
      address: "394 Elm St, City",
      oppCharges: 86,
      gstCharges: 11,
      OTTCharges: 54,
      totalAmount: 151,
      status: "Pending",
      JourneyDate: "2023-11-06",
    },
    {
      lastName: "Williams",
      firstName: "William",
      age: 52,
      gender: "Female",
      RegNo: "REG139",
      country: "Australia",
      receiptNo: "REC97",
      address: "54 Elm St, City",
      oppCharges: 158,
      gstCharges: 37,
      OTTCharges: 35,
      totalAmount: 230,
      status: "Pending",
      JourneyDate: "2023-10-27",
    },
    {
      lastName: "Johnson",
      firstName: "John",
      age: 26,
      gender: "Female",
      RegNo: "REG486",
      country: "Australia",
      receiptNo: "REC24",
      address: "1 Elm St, City",
      oppCharges: 181,
      gstCharges: 35,
      OTTCharges: 43,
      totalAmount: 259,
      status: "Cancelled",
      JourneyDate: "2023-11-06",
    },
    {
      lastName: "Jones",
      firstName: "David",
      age: 23,
      gender: "Male",
      RegNo: "REG479",
      country: "USA",
      receiptNo: "REC326",
      address: "655 Elm St, City",
      oppCharges: 186,
      gstCharges: 14,
      OTTCharges: 80,
      totalAmount: 280,
      status: "Pending",
      JourneyDate: "2023-07-14",
    },
    {
      lastName: "Smith",
      firstName: "Robert",
      age: 42,
      gender: "Male",
      RegNo: "REG662",
      country: "India",
      receiptNo: "REC977",
      address: "639 Elm St, City",
      oppCharges: 224,
      gstCharges: 36,
      OTTCharges: 79,
      totalAmount: 339,
      status: "Paid",
      JourneyDate: "2024-01-15",
    },
    {
      lastName: "Smith",
      firstName: "James",
      age: 50,
      gender: "Male",
      RegNo: "REG217",
      country: "Australia",
      receiptNo: "REC370",
      address: "220 Elm St, City",
      oppCharges: 138,
      gstCharges: 16,
      OTTCharges: 37,
      totalAmount: 191,
      status: "Paid",
      JourneyDate: "2023-05-23",
    },
    {
      lastName: "Williams",
      firstName: "James",
      age: 35,
      gender: "Male",
      RegNo: "REG41",
      country: "India",
      receiptNo: "REC27",
      address: "990 Elm St, City",
      oppCharges: 135,
      gstCharges: 13,
      OTTCharges: 34,
      totalAmount: 182,
      status: "Paid",
      JourneyDate: "2024-01-09",
    },
    {
      lastName: "Miller",
      firstName: "James",
      age: 34,
      gender: "Female",
      RegNo: "REG617",
      country: "USA",
      receiptNo: "REC614",
      address: "867 Elm St, City",
      oppCharges: 132,
      gstCharges: 30,
      OTTCharges: 35,
      totalAmount: 197,
      status: "Paid",
      JourneyDate: "2024-06-02",
    },
    {
      lastName: "Johnson",
      firstName: "Michael",
      age: 57,
      gender: "Female",
      RegNo: "REG582",
      country: "UK",
      receiptNo: "REC25",
      address: "636 Elm St, City",
      oppCharges: 218,
      gstCharges: 20,
      OTTCharges: 62,
      totalAmount: 300,
      status: "Pending",
      JourneyDate: "2023-12-03",
    },
    {
      lastName: "Johnson",
      firstName: "Michael",
      age: 46,
      gender: "Female",
      RegNo: "REG866",
      country: "Australia",
      receiptNo: "REC888",
      address: "418 Elm St, City",
      oppCharges: 191,
      gstCharges: 38,
      OTTCharges: 76,
      totalAmount: 305,
      status: "Cancelled",
      JourneyDate: "2024-02-27",
    },
    {
      lastName: "Williams",
      firstName: "James",
      age: 55,
      gender: "Female",
      RegNo: "REG676",
      country: "India",
      receiptNo: "REC707",
      address: "41 Elm St, City",
      oppCharges: 210,
      gstCharges: 31,
      OTTCharges: 54,
      totalAmount: 295,
      status: "Cancelled",
      JourneyDate: "2024-05-19",
    },
    {
      lastName: "Jones",
      firstName: "William",
      age: 46,
      gender: "Male",
      RegNo: "REG439",
      country: "USA",
      receiptNo: "REC126",
      address: "470 Elm St, City",
      oppCharges: 52,
      gstCharges: 36,
      OTTCharges: 65,
      totalAmount: 153,
      status: "Pending",
      JourneyDate: "2023-10-12",
    },
    {
      lastName: "Davis",
      firstName: "William",
      age: 52,
      gender: "Male",
      RegNo: "REG87",
      country: "UK",
      receiptNo: "REC400",
      address: "868 Elm St, City",
      oppCharges: 222,
      gstCharges: 27,
      OTTCharges: 78,
      totalAmount: 327,
      status: "Paid",
      JourneyDate: "2024-06-22",
    },
    {
      lastName: "Williams",
      firstName: "Robert",
      age: 50,
      gender: "Male",
      RegNo: "REG945",
      country: "USA",
      receiptNo: "REC585",
      address: "849 Elm St, City",
      oppCharges: 63,
      gstCharges: 35,
      OTTCharges: 17,
      totalAmount: 115,
      status: "Cancelled",
      JourneyDate: "2024-05-07",
    },
    {
      lastName: "Smith",
      firstName: "James",
      age: 51,
      gender: "Male",
      RegNo: "REG663",
      country: "Australia",
      receiptNo: "REC308",
      address: "264 Elm St, City",
      oppCharges: 215,
      gstCharges: 13,
      OTTCharges: 0,
      totalAmount: 228,
      status: "Paid",
      JourneyDate: "2024-04-04",
    },
    {
      lastName: "Smith",
      firstName: "Richard",
      age: 33,
      gender: "Female",
      RegNo: "REG769",
      country: "USA",
      receiptNo: "REC415",
      address: "78 Elm St, City",
      oppCharges: 179,
      gstCharges: 35,
      OTTCharges: 93,
      totalAmount: 307,
      status: "Paid",
      JourneyDate: "2024-04-20",
    },
    {
      lastName: "Smith",
      firstName: "Richard",
      age: 46,
      gender: "Male",
      RegNo: "REG366",
      country: "Canada",
      receiptNo: "REC600",
      address: "844 Elm St, City",
      oppCharges: 88,
      gstCharges: 33,
      OTTCharges: 58,
      totalAmount: 179,
      status: "Paid",
      JourneyDate: "2023-02-12",
    },
    {
      lastName: "Jones",
      firstName: "William",
      age: 51,
      gender: "Female",
      RegNo: "REG929",
      country: "India",
      receiptNo: "REC813",
      address: "547 Elm St, City",
      oppCharges: 121,
      gstCharges: 35,
      OTTCharges: 96,
      totalAmount: 252,
      status: "Pending",
      JourneyDate: "2023-07-17",
    },
    {
      lastName: "Brown",
      firstName: "David",
      age: 21,
      gender: "Female",
      RegNo: "REG647",
      country: "India",
      receiptNo: "REC203",
      address: "729 Elm St, City",
      oppCharges: 196,
      gstCharges: 29,
      OTTCharges: 19,
      totalAmount: 244,
      status: "Pending",
      JourneyDate: "2023-08-28",
    },
    {
      lastName: "Johnson",
      firstName: "James",
      age: 43,
      gender: "Male",
      RegNo: "REG367",
      country: "Australia",
      receiptNo: "REC368",
      address: "159 Elm St, City",
      oppCharges: 51,
      gstCharges: 31,
      OTTCharges: 26,
      totalAmount: 108,
      status: "Pending",
      JourneyDate: "2024-06-14",
    },
    {
      lastName: "Miller",
      firstName: "James",
      age: 29,
      gender: "Male",
      RegNo: "REG428",
      country: "Australia",
      receiptNo: "REC522",
      address: "574 Elm St, City",
      oppCharges: 200,
      gstCharges: 25,
      OTTCharges: 36,
      totalAmount: 261,
      status: "Cancelled",
      JourneyDate: "2023-07-08",
    },
    {
      lastName: "Davis",
      firstName: "William",
      age: 34,
      gender: "Male",
      RegNo: "REG814",
      country: "Canada",
      receiptNo: "REC572",
      address: "415 Elm St, City",
      oppCharges: 219,
      gstCharges: 30,
      OTTCharges: 32,
      totalAmount: 281,
      status: "Pending",
      JourneyDate: "2023-06-12",
    },
    {
      lastName: "Brown",
      firstName: "Robert",
      age: 51,
      gender: "Female",
      RegNo: "REG863",
      country: "Australia",
      receiptNo: "REC100",
      address: "919 Elm St, City",
      oppCharges: 196,
      gstCharges: 13,
      OTTCharges: 66,
      totalAmount: 275,
      status: "Paid",
      JourneyDate: "2024-02-14",
    },
    {
      lastName: "Davis",
      firstName: "Michael",
      age: 32,
      gender: "Female",
      RegNo: "REG155",
      country: "USA",
      receiptNo: "REC722",
      address: "680 Elm St, City",
      oppCharges: 60,
      gstCharges: 22,
      OTTCharges: 19,
      totalAmount: 101,
      status: "Paid",
      JourneyDate: "2023-06-29",
    },
    {
      lastName: "Williams",
      firstName: "William",
      age: 33,
      gender: "Female",
      RegNo: "REG982",
      country: "USA",
      receiptNo: "REC448",
      address: "697 Elm St, City",
      oppCharges: 69,
      gstCharges: 14,
      OTTCharges: 77,
      totalAmount: 160,
      status: "Cancelled",
      JourneyDate: "2023-03-13",
    },
    {
      lastName: "Davis",
      firstName: "David",
      age: 25,
      gender: "Male",
      RegNo: "REG113",
      country: "UK",
      receiptNo: "REC462",
      address: "867 Elm St, City",
      oppCharges: 110,
      gstCharges: 19,
      OTTCharges: 68,
      totalAmount: 197,
      status: "Paid",
      JourneyDate: "2024-01-13",
    },
    {
      lastName: "Miller",
      firstName: "Robert",
      age: 27,
      gender: "Male",
      RegNo: "REG832",
      country: "India",
      receiptNo: "REC986",
      address: "235 Elm St, City",
      oppCharges: 184,
      gstCharges: 29,
      OTTCharges: 20,
      totalAmount: 233,
      status: "Paid",
      JourneyDate: "2024-07-13",
    },
    {
      lastName: "Davis",
      firstName: "Richard",
      age: 44,
      gender: "Male",
      RegNo: "REG223",
      country: "Australia",
      receiptNo: "REC213",
      address: "991 Elm St, City",
      oppCharges: 179,
      gstCharges: 38,
      OTTCharges: 14,
      totalAmount: 231,
      status: "Paid",
      JourneyDate: "2023-07-29",
    },
    {
      lastName: "Williams",
      firstName: "Richard",
      age: 41,
      gender: "Male",
      RegNo: "REG369",
      country: "India",
      receiptNo: "REC863",
      address: "448 Elm St, City",
      oppCharges: 89,
      gstCharges: 11,
      OTTCharges: 58,
      totalAmount: 158,
      status: "Cancelled",
      JourneyDate: "2023-09-15",
    },
    {
      lastName: "Williams",
      firstName: "James",
      age: 50,
      gender: "Female",
      RegNo: "REG436",
      country: "UK",
      receiptNo: "REC901",
      address: "595 Elm St, City",
      oppCharges: 211,
      gstCharges: 19,
      OTTCharges: 5,
      totalAmount: 235,
      status: "Pending",
      JourneyDate: "2023-01-02",
    },
    {
      lastName: "Johnson",
      firstName: "William",
      age: 56,
      gender: "Female",
      RegNo: "REG519",
      country: "USA",
      receiptNo: "REC276",
      address: "64 Elm St, City",
      oppCharges: 50,
      gstCharges: 28,
      OTTCharges: 5,
      totalAmount: 83,
      status: "Cancelled",
      JourneyDate: "2023-11-20",
    },
    {
      lastName: "Johnson",
      firstName: "William",
      age: 29,
      gender: "Male",
      RegNo: "REG968",
      country: "Canada",
      receiptNo: "REC765",
      address: "695 Elm St, City",
      oppCharges: 142,
      gstCharges: 10,
      OTTCharges: 93,
      totalAmount: 245,
      status: "Pending",
      JourneyDate: "2023-09-14",
    },
    {
      lastName: "Smith",
      firstName: "John",
      age: 63,
      gender: "Male",
      RegNo: "REG782",
      country: "India",
      receiptNo: "REC72",
      address: "4 Elm St, City",
      oppCharges: 151,
      gstCharges: 27,
      OTTCharges: 16,
      totalAmount: 194,
      status: "Pending",
      JourneyDate: "2023-06-19",
    },
    {
      lastName: "Davis",
      firstName: "David",
      age: 59,
      gender: "Female",
      RegNo: "REG157",
      country: "Canada",
      receiptNo: "REC14",
      address: "138 Elm St, City",
      oppCharges: 87,
      gstCharges: 16,
      OTTCharges: 77,
      totalAmount: 180,
      status: "Paid",
      JourneyDate: "2023-11-03",
    },
    {
      lastName: "Brown",
      firstName: "Michael",
      age: 65,
      gender: "Female",
      RegNo: "REG597",
      country: "USA",
      receiptNo: "REC89",
      address: "836 Elm St, City",
      oppCharges: 56,
      gstCharges: 27,
      OTTCharges: 50,
      totalAmount: 133,
      status: "Pending",
      JourneyDate: "2024-02-10",
    },
    {
      lastName: "Davis",
      firstName: "John",
      age: 57,
      gender: "Female",
      RegNo: "REG683",
      country: "Canada",
      receiptNo: "REC861",
      address: "573 Elm St, City",
      oppCharges: 81,
      gstCharges: 12,
      OTTCharges: 43,
      totalAmount: 136,
      status: "Paid",
      JourneyDate: "2023-03-11",
    },
    {
      lastName: "Williams",
      firstName: "Michael",
      age: 52,
      gender: "Female",
      RegNo: "REG21",
      country: "USA",
      receiptNo: "REC442",
      address: "301 Elm St, City",
      oppCharges: 147,
      gstCharges: 35,
      OTTCharges: 22,
      totalAmount: 204,
      status: "Paid",
      JourneyDate: "2023-10-09",
    },
    {
      lastName: "Johnson",
      firstName: "Richard",
      age: 31,
      gender: "Female",
      RegNo: "REG888",
      country: "Canada",
      receiptNo: "REC737",
      address: "979 Elm St, City",
      oppCharges: 193,
      gstCharges: 21,
      OTTCharges: 70,
      totalAmount: 284,
      status: "Pending",
      JourneyDate: "2023-01-02",
    },
    {
      lastName: "Brown",
      firstName: "James",
      age: 28,
      gender: "Female",
      RegNo: "REG795",
      country: "USA",
      receiptNo: "REC75",
      address: "310 Elm St, City",
      oppCharges: 110,
      gstCharges: 13,
      OTTCharges: 84,
      totalAmount: 207,
      status: "Pending",
      JourneyDate: "2024-05-19",
    },
    {
      lastName: "Johnson",
      firstName: "Michael",
      age: 60,
      gender: "Male",
      RegNo: "REG273",
      country: "UK",
      receiptNo: "REC460",
      address: "582 Elm St, City",
      oppCharges: 169,
      gstCharges: 13,
      OTTCharges: 98,
      totalAmount: 280,
      status: "Paid",
      JourneyDate: "2024-02-14",
    },
    {
      lastName: "Brown",
      firstName: "David",
      age: 22,
      gender: "Female",
      RegNo: "REG727",
      country: "India",
      receiptNo: "REC384",
      address: "640 Elm St, City",
      oppCharges: 167,
      gstCharges: 13,
      OTTCharges: 34,
      totalAmount: 214,
      status: "Pending",
      JourneyDate: "2023-06-02",
    },
    {
      lastName: "Smith",
      firstName: "David",
      age: 26,
      gender: "Male",
      RegNo: "REG771",
      country: "Canada",
      receiptNo: "REC216",
      address: "472 Elm St, City",
      oppCharges: 165,
      gstCharges: 22,
      OTTCharges: 54,
      totalAmount: 241,
      status: "Paid",
      JourneyDate: "2024-04-05",
    },
    {
      lastName: "Jones",
      firstName: "William",
      age: 64,
      gender: "Male",
      RegNo: "REG435",
      country: "Canada",
      receiptNo: "REC621",
      address: "183 Elm St, City",
      oppCharges: 148,
      gstCharges: 14,
      OTTCharges: 34,
      totalAmount: 196,
      status: "Paid",
      JourneyDate: "2024-05-28",
    },
    {
      lastName: "Miller",
      firstName: "William",
      age: 50,
      gender: "Male",
      RegNo: "REG846",
      country: "Canada",
      receiptNo: "REC121",
      address: "19 Elm St, City",
      oppCharges: 202,
      gstCharges: 14,
      OTTCharges: 0,
      totalAmount: 216,
      status: "Pending",
      JourneyDate: "2024-01-16",
    },
    {
      lastName: "Brown",
      firstName: "Michael",
      age: 21,
      gender: "Female",
      RegNo: "REG841",
      country: "India",
      receiptNo: "REC761",
      address: "275 Elm St, City",
      oppCharges: 104,
      gstCharges: 23,
      OTTCharges: 93,
      totalAmount: 220,
      status: "Pending",
      JourneyDate: "2024-02-08",
    },
    {
      lastName: "Smith",
      firstName: "James",
      age: 33,
      gender: "Male",
      RegNo: "REG598",
      country: "USA",
      receiptNo: "REC424",
      address: "358 Elm St, City",
      oppCharges: 163,
      gstCharges: 12,
      OTTCharges: 19,
      totalAmount: 194,
      status: "Paid",
      JourneyDate: "2023-04-19",
    },
    {
      lastName: "Miller",
      firstName: "Michael",
      age: 64,
      gender: "Male",
      RegNo: "REG294",
      country: "UK",
      receiptNo: "REC258",
      address: "0 Elm St, City",
      oppCharges: 81,
      gstCharges: 16,
      OTTCharges: 81,
      totalAmount: 178,
      status: "Pending",
      JourneyDate: "2024-06-07",
    },
    {
      lastName: "Johnson",
      firstName: "James",
      age: 49,
      gender: "Female",
      RegNo: "REG627",
      country: "Canada",
      receiptNo: "REC526",
      address: "761 Elm St, City",
      oppCharges: 224,
      gstCharges: 17,
      OTTCharges: 32,
      totalAmount: 273,
      status: "Pending",
      JourneyDate: "2023-06-01",
    },
    {
      lastName: "Jones",
      firstName: "John",
      age: 56,
      gender: "Female",
      RegNo: "REG364",
      country: "USA",
      receiptNo: "REC652",
      address: "134 Elm St, City",
      oppCharges: 182,
      gstCharges: 11,
      OTTCharges: 22,
      totalAmount: 215,
      status: "Pending",
      JourneyDate: "2024-06-22",
    },
    {
      lastName: "Williams",
      firstName: "David",
      age: 32,
      gender: "Female",
      RegNo: "REG772",
      country: "India",
      receiptNo: "REC892",
      address: "419 Elm St, City",
      oppCharges: 196,
      gstCharges: 31,
      OTTCharges: 34,
      totalAmount: 261,
      status: "Paid",
      JourneyDate: "2023-01-09",
    },
    {
      lastName: "Miller",
      firstName: "Robert",
      age: 69,
      gender: "Female",
      RegNo: "REG261",
      country: "India",
      receiptNo: "REC95",
      address: "505 Elm St, City",
      oppCharges: 113,
      gstCharges: 19,
      OTTCharges: 13,
      totalAmount: 145,
      status: "Cancelled",
      JourneyDate: "2023-03-15",
    },
    {
      lastName: "Smith",
      firstName: "William",
      age: 56,
      gender: "Female",
      RegNo: "REG556",
      country: "Canada",
      receiptNo: "REC263",
      address: "517 Elm St, City",
      oppCharges: 216,
      gstCharges: 16,
      OTTCharges: 35,
      totalAmount: 267,
      status: "Paid",
      JourneyDate: "2023-12-19",
    },
    {
      lastName: "Williams",
      firstName: "Michael",
      age: 64,
      gender: "Male",
      RegNo: "REG69",
      country: "USA",
      receiptNo: "REC419",
      address: "670 Elm St, City",
      oppCharges: 183,
      gstCharges: 29,
      OTTCharges: 7,
      totalAmount: 219,
      status: "Cancelled",
      JourneyDate: "2023-12-06",
    },
    {
      lastName: "Johnson",
      firstName: "William",
      age: 20,
      gender: "Female",
      RegNo: "REG153",
      country: "UK",
      receiptNo: "REC731",
      address: "328 Elm St, City",
      oppCharges: 241,
      gstCharges: 28,
      OTTCharges: 18,
      totalAmount: 287,
      status: "Pending",
      JourneyDate: "2024-03-05",
    },
    {
      lastName: "Smith",
      firstName: "Michael",
      age: 29,
      gender: "Female",
      RegNo: "REG177",
      country: "Canada",
      receiptNo: "REC413",
      address: "460 Elm St, City",
      oppCharges: 88,
      gstCharges: 21,
      OTTCharges: 11,
      totalAmount: 120,
      status: "Cancelled",
      JourneyDate: "2024-01-12",
    },
    {
      lastName: "Johnson",
      firstName: "James",
      age: 44,
      gender: "Female",
      RegNo: "REG750",
      country: "India",
      receiptNo: "REC934",
      address: "788 Elm St, City",
      oppCharges: 80,
      gstCharges: 16,
      OTTCharges: 35,
      totalAmount: 131,
      status: "Cancelled",
      JourneyDate: "2023-11-05",
    },
    {
      lastName: "Brown",
      firstName: "Richard",
      age: 34,
      gender: "Female",
      RegNo: "REG945",
      country: "UK",
      receiptNo: "REC257",
      address: "383 Elm St, City",
      oppCharges: 161,
      gstCharges: 11,
      OTTCharges: 68,
      totalAmount: 240,
      status: "Pending",
      JourneyDate: "2024-07-10",
    },
    {
      lastName: "Smith",
      firstName: "Michael",
      age: 43,
      gender: "Male",
      RegNo: "REG656",
      country: "Australia",
      receiptNo: "REC862",
      address: "454 Elm St, City",
      oppCharges: 180,
      gstCharges: 39,
      OTTCharges: 61,
      totalAmount: 280,
      status: "Cancelled",
      JourneyDate: "2023-01-04",
    },
    {
      lastName: "Brown",
      firstName: "John",
      age: 22,
      gender: "Female",
      RegNo: "REG147",
      country: "India",
      receiptNo: "REC208",
      address: "988 Elm St, City",
      oppCharges: 117,
      gstCharges: 39,
      OTTCharges: 14,
      totalAmount: 170,
      status: "Pending",
      JourneyDate: "2023-08-02",
    },
    {
      lastName: "Johnson",
      firstName: "John",
      age: 27,
      gender: "Male",
      RegNo: "REG260",
      country: "USA",
      receiptNo: "REC22",
      address: "549 Elm St, City",
      oppCharges: 233,
      gstCharges: 19,
      OTTCharges: 26,
      totalAmount: 278,
      status: "Pending",
      JourneyDate: "2023-11-17",
    },
    {
      lastName: "Miller",
      firstName: "Robert",
      age: 35,
      gender: "Male",
      RegNo: "REG139",
      country: "UK",
      receiptNo: "REC89",
      address: "372 Elm St, City",
      oppCharges: 159,
      gstCharges: 33,
      OTTCharges: 66,
      totalAmount: 258,
      status: "Pending",
      JourneyDate: "2023-02-21",
    },
    {
      lastName: "Jones",
      firstName: "David",
      age: 37,
      gender: "Female",
      RegNo: "REG776",
      country: "India",
      receiptNo: "REC983",
      address: "897 Elm St, City",
      oppCharges: 87,
      gstCharges: 30,
      OTTCharges: 28,
      totalAmount: 145,
      status: "Cancelled",
      JourneyDate: "2023-10-06",
    },
    {
      lastName: "Smith",
      firstName: "James",
      age: 65,
      gender: "Female",
      RegNo: "REG939",
      country: "Australia",
      receiptNo: "REC341",
      address: "522 Elm St, City",
      oppCharges: 204,
      gstCharges: 14,
      OTTCharges: 39,
      totalAmount: 257,
      status: "Pending",
      JourneyDate: "2023-07-23",
    },
    {
      lastName: "Williams",
      firstName: "David",
      age: 24,
      gender: "Male",
      RegNo: "REG942",
      country: "India",
      receiptNo: "REC500",
      address: "644 Elm St, City",
      oppCharges: 222,
      gstCharges: 31,
      OTTCharges: 14,
      totalAmount: 267,
      status: "Pending",
      JourneyDate: "2023-02-11",
    },
    {
      lastName: "Williams",
      firstName: "Robert",
      age: 56,
      gender: "Female",
      RegNo: "REG562",
      country: "UK",
      receiptNo: "REC83",
      address: "58 Elm St, City",
      oppCharges: 214,
      gstCharges: 32,
      OTTCharges: 84,
      totalAmount: 330,
      status: "Paid",
      JourneyDate: "2023-04-21",
    },
    {
      lastName: "Brown",
      firstName: "Robert",
      age: 56,
      gender: "Female",
      RegNo: "REG264",
      country: "India",
      receiptNo: "REC1",
      address: "558 Elm St, City",
      oppCharges: 89,
      gstCharges: 28,
      OTTCharges: 30,
      totalAmount: 147,
      status: "Pending",
      JourneyDate: "2024-05-26",
    },
    {
      lastName: "Jones",
      firstName: "John",
      age: 42,
      gender: "Female",
      RegNo: "REG754",
      country: "Canada",
      receiptNo: "REC741",
      address: "269 Elm St, City",
      oppCharges: 68,
      gstCharges: 29,
      OTTCharges: 61,
      totalAmount: 158,
      status: "Cancelled",
      JourneyDate: "2023-11-21",
    },
    {
      lastName: "Williams",
      firstName: "Michael",
      age: 34,
      gender: "Male",
      RegNo: "REG594",
      country: "USA",
      receiptNo: "REC842",
      address: "551 Elm St, City",
      oppCharges: 206,
      gstCharges: 31,
      OTTCharges: 67,
      totalAmount: 304,
      status: "Pending",
      JourneyDate: "2023-08-10",
    },
    {
      lastName: "Smith",
      firstName: "James",
      age: 53,
      gender: "Female",
      RegNo: "REG297",
      country: "Australia",
      receiptNo: "REC78",
      address: "950 Elm St, City",
      oppCharges: 90,
      gstCharges: 25,
      OTTCharges: 96,
      totalAmount: 211,
      status: "Pending",
      JourneyDate: "2023-12-08",
    },
    {
      lastName: "Davis",
      firstName: "David",
      age: 21,
      gender: "Male",
      RegNo: "REG419",
      country: "USA",
      receiptNo: "REC126",
      address: "685 Elm St, City",
      oppCharges: 193,
      gstCharges: 36,
      OTTCharges: 33,
      totalAmount: 262,
      status: "Cancelled",
      JourneyDate: "2023-03-09",
    },
    {
      lastName: "Johnson",
      firstName: "William",
      age: 55,
      gender: "Male",
      RegNo: "REG659",
      country: "Australia",
      receiptNo: "REC510",
      address: "61 Elm St, City",
      oppCharges: 66,
      gstCharges: 17,
      OTTCharges: 63,
      totalAmount: 146,
      status: "Paid",
      JourneyDate: "2023-12-20",
    },
    {
      lastName: "Johnson",
      firstName: "Robert",
      age: 36,
      gender: "Female",
      RegNo: "REG998",
      country: "USA",
      receiptNo: "REC890",
      address: "130 Elm St, City",
      oppCharges: 198,
      gstCharges: 32,
      OTTCharges: 4,
      totalAmount: 234,
      status: "Paid",
      JourneyDate: "2023-03-27",
    },
    {
      lastName: "Brown",
      firstName: "James",
      age: 64,
      gender: "Male",
      RegNo: "REG958",
      country: "Australia",
      receiptNo: "REC272",
      address: "577 Elm St, City",
      oppCharges: 199,
      gstCharges: 18,
      OTTCharges: 23,
      totalAmount: 240,
      status: "Cancelled",
      JourneyDate: "2023-07-01",
    },
    {
      lastName: "Williams",
      firstName: "William",
      age: 22,
      gender: "Female",
      RegNo: "REG489",
      country: "UK",
      receiptNo: "REC930",
      address: "60 Elm St, City",
      oppCharges: 173,
      gstCharges: 20,
      OTTCharges: 42,
      totalAmount: 235,
      status: "Cancelled",
      JourneyDate: "2023-08-26",
    },
    {
      lastName: "Davis",
      firstName: "David",
      age: 30,
      gender: "Male",
      RegNo: "REG611",
      country: "USA",
      receiptNo: "REC521",
      address: "170 Elm St, City",
      oppCharges: 226,
      gstCharges: 15,
      OTTCharges: 74,
      totalAmount: 315,
      status: "Paid",
      JourneyDate: "2023-02-14",
    },
    {
      lastName: "Smith",
      firstName: "Michael",
      age: 31,
      gender: "Male",
      RegNo: "REG62",
      country: "Canada",
      receiptNo: "REC754",
      address: "874 Elm St, City",
      oppCharges: 141,
      gstCharges: 20,
      OTTCharges: 62,
      totalAmount: 223,
      status: "Paid",
      JourneyDate: "2023-07-19",
    },
    {
      lastName: "Williams",
      firstName: "Michael",
      age: 50,
      gender: "Male",
      RegNo: "REG776",
      country: "USA",
      receiptNo: "REC933",
      address: "408 Elm St, City",
      oppCharges: 216,
      gstCharges: 19,
      OTTCharges: 74,
      totalAmount: 309,
      status: "Pending",
      JourneyDate: "2024-05-03",
    },
    {
      lastName: "Brown",
      firstName: "Richard",
      age: 31,
      gender: "Male",
      RegNo: "REG998",
      country: "India",
      receiptNo: "REC482",
      address: "629 Elm St, City",
      oppCharges: 148,
      gstCharges: 10,
      OTTCharges: 60,
      totalAmount: 218,
      status: "Cancelled",
      JourneyDate: "2024-06-30",
    },
    {
      lastName: "Jones",
      firstName: "Richard",
      age: 20,
      gender: "Male",
      RegNo: "REG52",
      country: "UK",
      receiptNo: "REC706",
      address: "407 Elm St, City",
      oppCharges: 237,
      gstCharges: 25,
      OTTCharges: 75,
      totalAmount: 337,
      status: "Cancelled",
      JourneyDate: "2023-08-28",
    },
    {
      lastName: "Miller",
      firstName: "John",
      age: 53,
      gender: "Male",
      RegNo: "REG725",
      country: "UK",
      receiptNo: "REC228",
      address: "749 Elm St, City",
      oppCharges: 184,
      gstCharges: 29,
      OTTCharges: 80,
      totalAmount: 293,
      status: "Pending",
      JourneyDate: "2023-07-20",
    },
    {
      lastName: "Miller",
      firstName: "David",
      age: 22,
      gender: "Female",
      RegNo: "REG643",
      country: "India",
      receiptNo: "REC52",
      address: "78 Elm St, City",
      oppCharges: 167,
      gstCharges: 28,
      OTTCharges: 75,
      totalAmount: 270,
      status: "Cancelled",
      JourneyDate: "2024-07-08",
    },
    {
      lastName: "Brown",
      firstName: "William",
      age: 20,
      gender: "Female",
      RegNo: "REG714",
      country: "USA",
      receiptNo: "REC236",
      address: "464 Elm St, City",
      oppCharges: 226,
      gstCharges: 39,
      OTTCharges: 98,
      totalAmount: 363,
      status: "Pending",
      JourneyDate: "2024-02-08",
    },
    {
      lastName: "Johnson",
      firstName: "James",
      age: 40,
      gender: "Female",
      RegNo: "REG767",
      country: "India",
      receiptNo: "REC89",
      address: "316 Elm St, City",
      oppCharges: 95,
      gstCharges: 24,
      OTTCharges: 28,
      totalAmount: 147,
      status: "Cancelled",
      JourneyDate: "2023-02-18",
    },
    {
      lastName: "Jones",
      firstName: "William",
      age: 60,
      gender: "Female",
      RegNo: "REG369",
      country: "Australia",
      receiptNo: "REC380",
      address: "887 Elm St, City",
      oppCharges: 168,
      gstCharges: 20,
      OTTCharges: 76,
      totalAmount: 264,
      status: "Cancelled",
      JourneyDate: "2023-12-02",
    },
    {
      lastName: "Johnson",
      firstName: "David",
      age: 22,
      gender: "Female",
      RegNo: "REG947",
      country: "UK",
      receiptNo: "REC561",
      address: "919 Elm St, City",
      oppCharges: 193,
      gstCharges: 39,
      OTTCharges: 39,
      totalAmount: 271,
      status: "Paid",
      JourneyDate: "2023-10-20",
    },
    {
      lastName: "Davis",
      firstName: "Richard",
      age: 61,
      gender: "Male",
      RegNo: "REG503",
      country: "Australia",
      receiptNo: "REC446",
      address: "858 Elm St, City",
      oppCharges: 142,
      gstCharges: 22,
      OTTCharges: 7,
      totalAmount: 171,
      status: "Paid",
      JourneyDate: "2024-06-30",
    },
    {
      lastName: "Johnson",
      firstName: "James",
      age: 62,
      gender: "Male",
      RegNo: "REG467",
      country: "Australia",
      receiptNo: "REC140",
      address: "300 Elm St, City",
      oppCharges: 216,
      gstCharges: 17,
      OTTCharges: 19,
      totalAmount: 252,
      status: "Pending",
      JourneyDate: "2024-04-07",
    },
    {
      lastName: "Miller",
      firstName: "William",
      age: 46,
      gender: "Male",
      RegNo: "REG52",
      country: "UK",
      receiptNo: "REC915",
      address: "368 Elm St, City",
      oppCharges: 240,
      gstCharges: 22,
      OTTCharges: 33,
      totalAmount: 295,
      status: "Cancelled",
      JourneyDate: "2023-04-23",
    },
    {
      lastName: "Davis",
      firstName: "David",
      age: 42,
      gender: "Female",
      RegNo: "REG141",
      country: "UK",
      receiptNo: "REC194",
      address: "765 Elm St, City",
      oppCharges: 226,
      gstCharges: 36,
      OTTCharges: 2,
      totalAmount: 264,
      status: "Paid",
      JourneyDate: "2023-11-12",
    },
    {
      lastName: "Brown",
      firstName: "David",
      age: 37,
      gender: "Male",
      RegNo: "REG400",
      country: "UK",
      receiptNo: "REC505",
      address: "29 Elm St, City",
      oppCharges: 136,
      gstCharges: 25,
      OTTCharges: 23,
      totalAmount: 184,
      status: "Pending",
      JourneyDate: "2023-02-07",
    },
    {
      lastName: "Davis",
      firstName: "Robert",
      age: 28,
      gender: "Male",
      RegNo: "REG869",
      country: "USA",
      receiptNo: "REC626",
      address: "544 Elm St, City",
      oppCharges: 86,
      gstCharges: 16,
      OTTCharges: 58,
      totalAmount: 160,
      status: "Cancelled",
      JourneyDate: "2023-09-16",
    },
    {
      lastName: "Jones",
      firstName: "Michael",
      age: 23,
      gender: "Female",
      RegNo: "REG323",
      country: "Canada",
      receiptNo: "REC592",
      address: "723 Elm St, City",
      oppCharges: 224,
      gstCharges: 25,
      OTTCharges: 33,
      totalAmount: 282,
      status: "Pending",
      JourneyDate: "2023-08-07",
    },
    {
      lastName: "Williams",
      firstName: "Michael",
      age: 31,
      gender: "Female",
      RegNo: "REG835",
      country: "India",
      receiptNo: "REC984",
      address: "755 Elm St, City",
      oppCharges: 202,
      gstCharges: 18,
      OTTCharges: 90,
      totalAmount: 310,
      status: "Pending",
      JourneyDate: "2023-05-28",
    },
    {
      lastName: "Jones",
      firstName: "William",
      age: 55,
      gender: "Female",
      RegNo: "REG39",
      country: "India",
      receiptNo: "REC836",
      address: "162 Elm St, City",
      oppCharges: 71,
      gstCharges: 30,
      OTTCharges: 53,
      totalAmount: 154,
      status: "Cancelled",
      JourneyDate: "2023-03-05",
    },
    {
      lastName: "Jones",
      firstName: "Michael",
      age: 37,
      gender: "Female",
      RegNo: "REG740",
      country: "UK",
      receiptNo: "REC564",
      address: "299 Elm St, City",
      oppCharges: 163,
      gstCharges: 31,
      OTTCharges: 39,
      totalAmount: 233,
      status: "Pending",
      JourneyDate: "2023-03-01",
    },
    {
      lastName: "Jones",
      firstName: "John",
      age: 62,
      gender: "Male",
      RegNo: "REG531",
      country: "Canada",
      receiptNo: "REC901",
      address: "944 Elm St, City",
      oppCharges: 69,
      gstCharges: 37,
      OTTCharges: 40,
      totalAmount: 146,
      status: "Pending",
      JourneyDate: "2024-01-10",
    },
    {
      lastName: "Jones",
      firstName: "Michael",
      age: 66,
      gender: "Female",
      RegNo: "REG698",
      country: "India",
      receiptNo: "REC610",
      address: "74 Elm St, City",
      oppCharges: 232,
      gstCharges: 27,
      OTTCharges: 22,
      totalAmount: 281,
      status: "Paid",
      JourneyDate: "2023-03-10",
    },
    {
      lastName: "Miller",
      firstName: "Richard",
      age: 39,
      gender: "Female",
      RegNo: "REG286",
      country: "Canada",
      receiptNo: "REC500",
      address: "322 Elm St, City",
      oppCharges: 74,
      gstCharges: 35,
      OTTCharges: 83,
      totalAmount: 192,
      status: "Pending",
      JourneyDate: "2023-10-11",
    },
    {
      lastName: "Smith",
      firstName: "James",
      age: 30,
      gender: "Female",
      RegNo: "REG904",
      country: "India",
      receiptNo: "REC663",
      address: "476 Elm St, City",
      oppCharges: 235,
      gstCharges: 26,
      OTTCharges: 86,
      totalAmount: 347,
      status: "Cancelled",
      JourneyDate: "2023-03-31",
    },
    {
      lastName: "Smith",
      firstName: "Robert",
      age: 64,
      gender: "Female",
      RegNo: "REG651",
      country: "USA",
      receiptNo: "REC305",
      address: "760 Elm St, City",
      oppCharges: 97,
      gstCharges: 35,
      OTTCharges: 10,
      totalAmount: 142,
      status: "Paid",
      JourneyDate: "2024-05-17",
    },
    {
      lastName: "Miller",
      firstName: "Robert",
      age: 64,
      gender: "Male",
      RegNo: "REG108",
      country: "USA",
      receiptNo: "REC477",
      address: "471 Elm St, City",
      oppCharges: 131,
      gstCharges: 33,
      OTTCharges: 47,
      totalAmount: 211,
      status: "Paid",
      JourneyDate: "2023-04-06",
    },
    {
      lastName: "Miller",
      firstName: "John",
      age: 65,
      gender: "Female",
      RegNo: "REG243",
      country: "Australia",
      receiptNo: "REC456",
      address: "416 Elm St, City",
      oppCharges: 136,
      gstCharges: 38,
      OTTCharges: 20,
      totalAmount: 194,
      status: "Paid",
      JourneyDate: "2023-04-22",
    },
    {
      lastName: "Davis",
      firstName: "John",
      age: 63,
      gender: "Female",
      RegNo: "REG617",
      country: "USA",
      receiptNo: "REC247",
      address: "521 Elm St, City",
      oppCharges: 86,
      gstCharges: 13,
      OTTCharges: 90,
      totalAmount: 189,
      status: "Paid",
      JourneyDate: "2024-03-02",
    },
    {
      lastName: "Miller",
      firstName: "Robert",
      age: 37,
      gender: "Female",
      RegNo: "REG121",
      country: "UK",
      receiptNo: "REC718",
      address: "454 Elm St, City",
      oppCharges: 231,
      gstCharges: 17,
      OTTCharges: 31,
      totalAmount: 279,
      status: "Pending",
      JourneyDate: "2024-04-29",
    },
    {
      lastName: "Jones",
      firstName: "James",
      age: 47,
      gender: "Male",
      RegNo: "REG941",
      country: "Canada",
      receiptNo: "REC75",
      address: "584 Elm St, City",
      oppCharges: 63,
      gstCharges: 22,
      OTTCharges: 24,
      totalAmount: 109,
      status: "Cancelled",
      JourneyDate: "2023-03-17",
    },
    {
      lastName: "Smith",
      firstName: "Michael",
      age: 45,
      gender: "Female",
      RegNo: "REG861",
      country: "USA",
      receiptNo: "REC605",
      address: "645 Elm St, City",
      oppCharges: 177,
      gstCharges: 19,
      OTTCharges: 54,
      totalAmount: 250,
      status: "Cancelled",
      JourneyDate: "2024-02-12",
    },
    {
      lastName: "Davis",
      firstName: "John",
      age: 41,
      gender: "Male",
      RegNo: "REG157",
      country: "Canada",
      receiptNo: "REC779",
      address: "203 Elm St, City",
      oppCharges: 220,
      gstCharges: 18,
      OTTCharges: 67,
      totalAmount: 305,
      status: "Paid",
      JourneyDate: "2023-12-14",
    },
    {
      lastName: "Miller",
      firstName: "John",
      age: 63,
      gender: "Male",
      RegNo: "REG568",
      country: "USA",
      receiptNo: "REC390",
      address: "392 Elm St, City",
      oppCharges: 173,
      gstCharges: 32,
      OTTCharges: 41,
      totalAmount: 246,
      status: "Cancelled",
      JourneyDate: "2023-02-01",
    },
    {
      lastName: "Davis",
      firstName: "John",
      age: 46,
      gender: "Female",
      RegNo: "REG208",
      country: "India",
      receiptNo: "REC952",
      address: "436 Elm St, City",
      oppCharges: 213,
      gstCharges: 30,
      OTTCharges: 25,
      totalAmount: 268,
      status: "Cancelled",
      JourneyDate: "2023-05-25",
    },
    {
      lastName: "Davis",
      firstName: "Richard",
      age: 41,
      gender: "Male",
      RegNo: "REG826",
      country: "Canada",
      receiptNo: "REC251",
      address: "318 Elm St, City",
      oppCharges: 69,
      gstCharges: 24,
      OTTCharges: 42,
      totalAmount: 135,
      status: "Cancelled",
      JourneyDate: "2024-04-06",
    },
    {
      lastName: "Johnson",
      firstName: "William",
      age: 67,
      gender: "Female",
      RegNo: "REG523",
      country: "UK",
      receiptNo: "REC666",
      address: "750 Elm St, City",
      oppCharges: 213,
      gstCharges: 18,
      OTTCharges: 1,
      totalAmount: 232,
      status: "Cancelled",
      JourneyDate: "2023-10-20",
    },
    {
      lastName: "Johnson",
      firstName: "Michael",
      age: 66,
      gender: "Male",
      RegNo: "REG794",
      country: "Canada",
      receiptNo: "REC691",
      address: "8 Elm St, City",
      oppCharges: 96,
      gstCharges: 12,
      OTTCharges: 70,
      totalAmount: 178,
      status: "Paid",
      JourneyDate: "2023-02-23",
    },
    {
      lastName: "Davis",
      firstName: "Michael",
      age: 41,
      gender: "Male",
      RegNo: "REG495",
      country: "USA",
      receiptNo: "REC905",
      address: "248 Elm St, City",
      oppCharges: 123,
      gstCharges: 34,
      OTTCharges: 76,
      totalAmount: 233,
      status: "Pending",
      JourneyDate: "2023-11-25",
    },
    {
      lastName: "Williams",
      firstName: "David",
      age: 63,
      gender: "Male",
      RegNo: "REG918",
      country: "India",
      receiptNo: "REC71",
      address: "702 Elm St, City",
      oppCharges: 204,
      gstCharges: 32,
      OTTCharges: 50,
      totalAmount: 286,
      status: "Cancelled",
      JourneyDate: "2023-08-23",
    },
    {
      lastName: "Johnson",
      firstName: "William",
      age: 68,
      gender: "Male",
      RegNo: "REG815",
      country: "USA",
      receiptNo: "REC836",
      address: "965 Elm St, City",
      oppCharges: 171,
      gstCharges: 13,
      OTTCharges: 23,
      totalAmount: 207,
      status: "Paid",
      JourneyDate: "2023-02-09",
    },
    {
      lastName: "Johnson",
      firstName: "John",
      age: 53,
      gender: "Male",
      RegNo: "REG267",
      country: "India",
      receiptNo: "REC858",
      address: "171 Elm St, City",
      oppCharges: 242,
      gstCharges: 20,
      OTTCharges: 44,
      totalAmount: 306,
      status: "Paid",
      JourneyDate: "2024-05-08",
    },
    {
      lastName: "Johnson",
      firstName: "Robert",
      age: 24,
      gender: "Male",
      RegNo: "REG474",
      country: "USA",
      receiptNo: "REC79",
      address: "416 Elm St, City",
      oppCharges: 145,
      gstCharges: 27,
      OTTCharges: 70,
      totalAmount: 242,
      status: "Cancelled",
      JourneyDate: "2023-05-26",
    },
    {
      lastName: "Jones",
      firstName: "William",
      age: 63,
      gender: "Female",
      RegNo: "REG842",
      country: "India",
      receiptNo: "REC234",
      address: "238 Elm St, City",
      oppCharges: 235,
      gstCharges: 18,
      OTTCharges: 80,
      totalAmount: 333,
      status: "Paid",
      JourneyDate: "2023-05-23",
    },
    {
      lastName: "Davis",
      firstName: "William",
      age: 35,
      gender: "Male",
      RegNo: "REG252",
      country: "Canada",
      receiptNo: "REC120",
      address: "54 Elm St, City",
      oppCharges: 156,
      gstCharges: 24,
      OTTCharges: 37,
      totalAmount: 217,
      status: "Paid",
      JourneyDate: "2023-08-10",
    },
    {
      lastName: "Jones",
      firstName: "Richard",
      age: 54,
      gender: "Male",
      RegNo: "REG141",
      country: "USA",
      receiptNo: "REC618",
      address: "804 Elm St, City",
      oppCharges: 99,
      gstCharges: 21,
      OTTCharges: 96,
      totalAmount: 216,
      status: "Cancelled",
      JourneyDate: "2023-10-08",
    },
    {
      lastName: "Davis",
      firstName: "David",
      age: 69,
      gender: "Male",
      RegNo: "REG744",
      country: "USA",
      receiptNo: "REC846",
      address: "288 Elm St, City",
      oppCharges: 77,
      gstCharges: 34,
      OTTCharges: 77,
      totalAmount: 188,
      status: "Pending",
      JourneyDate: "2024-03-13",
    },
    {
      lastName: "Johnson",
      firstName: "Richard",
      age: 22,
      gender: "Male",
      RegNo: "REG277",
      country: "Australia",
      receiptNo: "REC464",
      address: "87 Elm St, City",
      oppCharges: 186,
      gstCharges: 33,
      OTTCharges: 31,
      totalAmount: 250,
      status: "Pending",
      JourneyDate: "2023-08-06",
    },
    {
      lastName: "Brown",
      firstName: "Richard",
      age: 36,
      gender: "Female",
      RegNo: "REG142",
      country: "USA",
      receiptNo: "REC817",
      address: "375 Elm St, City",
      oppCharges: 226,
      gstCharges: 17,
      OTTCharges: 5,
      totalAmount: 248,
      status: "Pending",
      JourneyDate: "2024-01-10",
    },
    {
      lastName: "Williams",
      firstName: "David",
      age: 32,
      gender: "Male",
      RegNo: "REG594",
      country: "USA",
      receiptNo: "REC706",
      address: "128 Elm St, City",
      oppCharges: 213,
      gstCharges: 28,
      OTTCharges: 91,
      totalAmount: 332,
      status: "Cancelled",
      JourneyDate: "2023-03-08",
    },
    {
      lastName: "Davis",
      firstName: "William",
      age: 57,
      gender: "Male",
      RegNo: "REG376",
      country: "India",
      receiptNo: "REC671",
      address: "356 Elm St, City",
      oppCharges: 217,
      gstCharges: 24,
      OTTCharges: 55,
      totalAmount: 296,
      status: "Pending",
      JourneyDate: "2023-09-15",
    },
    {
      lastName: "Williams",
      firstName: "William",
      age: 55,
      gender: "Female",
      RegNo: "REG859",
      country: "India",
      receiptNo: "REC652",
      address: "301 Elm St, City",
      oppCharges: 235,
      gstCharges: 16,
      OTTCharges: 57,
      totalAmount: 308,
      status: "Pending",
      JourneyDate: "2023-10-29",
    },
    {
      lastName: "Smith",
      firstName: "David",
      age: 49,
      gender: "Male",
      RegNo: "REG610",
      country: "Canada",
      receiptNo: "REC705",
      address: "526 Elm St, City",
      oppCharges: 92,
      gstCharges: 23,
      OTTCharges: 58,
      totalAmount: 173,
      status: "Cancelled",
      JourneyDate: "2024-04-04",
    },
    {
      lastName: "Miller",
      firstName: "James",
      age: 44,
      gender: "Male",
      RegNo: "REG919",
      country: "India",
      receiptNo: "REC695",
      address: "394 Elm St, City",
      oppCharges: 114,
      gstCharges: 18,
      OTTCharges: 19,
      totalAmount: 151,
      status: "Pending",
      JourneyDate: "2024-07-08",
    },
    {
      lastName: "Davis",
      firstName: "Robert",
      age: 62,
      gender: "Female",
      RegNo: "REG578",
      country: "UK",
      receiptNo: "REC242",
      address: "343 Elm St, City",
      oppCharges: 229,
      gstCharges: 30,
      OTTCharges: 21,
      totalAmount: 280,
      status: "Pending",
      JourneyDate: "2024-03-21",
    },
    {
      lastName: "Davis",
      firstName: "William",
      age: 45,
      gender: "Female",
      RegNo: "REG853",
      country: "USA",
      receiptNo: "REC538",
      address: "409 Elm St, City",
      oppCharges: 214,
      gstCharges: 34,
      OTTCharges: 61,
      totalAmount: 309,
      status: "Cancelled",
      JourneyDate: "2023-10-07",
    },
    {
      lastName: "Smith",
      firstName: "John",
      age: 35,
      gender: "Female",
      RegNo: "REG995",
      country: "USA",
      receiptNo: "REC561",
      address: "859 Elm St, City",
      oppCharges: 118,
      gstCharges: 31,
      OTTCharges: 33,
      totalAmount: 182,
      status: "Paid",
      JourneyDate: "2024-05-26",
    },
    {
      lastName: "Smith",
      firstName: "Richard",
      age: 48,
      gender: "Female",
      RegNo: "REG201",
      country: "USA",
      receiptNo: "REC47",
      address: "426 Elm St, City",
      oppCharges: 78,
      gstCharges: 12,
      OTTCharges: 77,
      totalAmount: 167,
      status: "Paid",
      JourneyDate: "2023-11-20",
    },
    {
      lastName: "Johnson",
      firstName: "Michael",
      age: 41,
      gender: "Male",
      RegNo: "REG13",
      country: "Australia",
      receiptNo: "REC115",
      address: "433 Elm St, City",
      oppCharges: 243,
      gstCharges: 29,
      OTTCharges: 75,
      totalAmount: 347,
      status: "Paid",
      JourneyDate: "2024-07-08",
    },
    {
      lastName: "Jones",
      firstName: "Michael",
      age: 38,
      gender: "Male",
      RegNo: "REG671",
      country: "UK",
      receiptNo: "REC713",
      address: "601 Elm St, City",
      oppCharges: 228,
      gstCharges: 25,
      OTTCharges: 46,
      totalAmount: 299,
      status: "Pending",
      JourneyDate: "2023-09-10",
    },
    {
      lastName: "Johnson",
      firstName: "James",
      age: 54,
      gender: "Female",
      RegNo: "REG758",
      country: "USA",
      receiptNo: "REC153",
      address: "19 Elm St, City",
      oppCharges: 141,
      gstCharges: 15,
      OTTCharges: 92,
      totalAmount: 248,
      status: "Paid",
      JourneyDate: "2023-04-15",
    },
    {
      lastName: "Davis",
      firstName: "Michael",
      age: 40,
      gender: "Female",
      RegNo: "REG850",
      country: "Canada",
      receiptNo: "REC305",
      address: "496 Elm St, City",
      oppCharges: 215,
      gstCharges: 29,
      OTTCharges: 43,
      totalAmount: 287,
      status: "Pending",
      JourneyDate: "2024-02-21",
    },
    {
      lastName: "Davis",
      firstName: "Michael",
      age: 50,
      gender: "Female",
      RegNo: "REG7",
      country: "Australia",
      receiptNo: "REC460",
      address: "749 Elm St, City",
      oppCharges: 210,
      gstCharges: 16,
      OTTCharges: 92,
      totalAmount: 318,
      status: "Cancelled",
      JourneyDate: "2024-03-28",
    },
    {
      lastName: "Miller",
      firstName: "Michael",
      age: 64,
      gender: "Male",
      RegNo: "REG400",
      country: "India",
      receiptNo: "REC497",
      address: "438 Elm St, City",
      oppCharges: 193,
      gstCharges: 29,
      OTTCharges: 73,
      totalAmount: 295,
      status: "Pending",
      JourneyDate: "2023-12-26",
    },
    {
      lastName: "Miller",
      firstName: "James",
      age: 40,
      gender: "Female",
      RegNo: "REG611",
      country: "UK",
      receiptNo: "REC972",
      address: "878 Elm St, City",
      oppCharges: 95,
      gstCharges: 20,
      OTTCharges: 98,
      totalAmount: 213,
      status: "Cancelled",
      JourneyDate: "2023-12-05",
    },
    {
      lastName: "Miller",
      firstName: "Richard",
      age: 64,
      gender: "Male",
      RegNo: "REG536",
      country: "UK",
      receiptNo: "REC95",
      address: "985 Elm St, City",
      oppCharges: 157,
      gstCharges: 33,
      OTTCharges: 26,
      totalAmount: 216,
      status: "Pending",
      JourneyDate: "2024-04-30",
    },
    {
      lastName: "Johnson",
      firstName: "William",
      age: 23,
      gender: "Female",
      RegNo: "REG428",
      country: "USA",
      receiptNo: "REC445",
      address: "171 Elm St, City",
      oppCharges: 233,
      gstCharges: 23,
      OTTCharges: 36,
      totalAmount: 292,
      status: "Pending",
      JourneyDate: "2023-03-09",
    },
    {
      lastName: "Johnson",
      firstName: "John",
      age: 41,
      gender: "Female",
      RegNo: "REG102",
      country: "Australia",
      receiptNo: "REC627",
      address: "937 Elm St, City",
      oppCharges: 150,
      gstCharges: 20,
      OTTCharges: 6,
      totalAmount: 176,
      status: "Pending",
      JourneyDate: "2023-02-19",
    },
    {
      lastName: "Williams",
      firstName: "James",
      age: 59,
      gender: "Male",
      RegNo: "REG163",
      country: "UK",
      receiptNo: "REC977",
      address: "825 Elm St, City",
      oppCharges: 70,
      gstCharges: 27,
      OTTCharges: 64,
      totalAmount: 161,
      status: "Pending",
      JourneyDate: "2023-02-08",
    },
    {
      lastName: "Brown",
      firstName: "Richard",
      age: 23,
      gender: "Female",
      RegNo: "REG441",
      country: "Australia",
      receiptNo: "REC595",
      address: "578 Elm St, City",
      oppCharges: 54,
      gstCharges: 21,
      OTTCharges: 74,
      totalAmount: 149,
      status: "Paid",
      JourneyDate: "2023-10-19",
    },
    {
      lastName: "Brown",
      firstName: "Robert",
      age: 68,
      gender: "Female",
      RegNo: "REG481",
      country: "UK",
      receiptNo: "REC348",
      address: "991 Elm St, City",
      oppCharges: 242,
      gstCharges: 38,
      OTTCharges: 49,
      totalAmount: 329,
      status: "Cancelled",
      JourneyDate: "2023-01-30",
    },
    {
      lastName: "Williams",
      firstName: "Richard",
      age: 56,
      gender: "Female",
      RegNo: "REG739",
      country: "USA",
      receiptNo: "REC580",
      address: "167 Elm St, City",
      oppCharges: 191,
      gstCharges: 26,
      OTTCharges: 68,
      totalAmount: 285,
      status: "Cancelled",
      JourneyDate: "2023-05-12",
    },
    {
      lastName: "Davis",
      firstName: "David",
      age: 40,
      gender: "Male",
      RegNo: "REG315",
      country: "Australia",
      receiptNo: "REC75",
      address: "14 Elm St, City",
      oppCharges: 248,
      gstCharges: 10,
      OTTCharges: 21,
      totalAmount: 279,
      status: "Pending",
      JourneyDate: "2024-02-27",
    },
    {
      lastName: "Miller",
      firstName: "William",
      age: 28,
      gender: "Female",
      RegNo: "REG326",
      country: "UK",
      receiptNo: "REC53",
      address: "630 Elm St, City",
      oppCharges: 78,
      gstCharges: 39,
      OTTCharges: 1,
      totalAmount: 118,
      status: "Paid",
      JourneyDate: "2023-06-10",
    },
    {
      lastName: "Jones",
      firstName: "John",
      age: 67,
      gender: "Female",
      RegNo: "REG395",
      country: "Canada",
      receiptNo: "REC768",
      address: "755 Elm St, City",
      oppCharges: 109,
      gstCharges: 39,
      OTTCharges: 38,
      totalAmount: 186,
      status: "Paid",
      JourneyDate: "2023-10-22",
    },
    {
      lastName: "Miller",
      firstName: "William",
      age: 61,
      gender: "Male",
      RegNo: "REG304",
      country: "Canada",
      receiptNo: "REC559",
      address: "327 Elm St, City",
      oppCharges: 82,
      gstCharges: 19,
      OTTCharges: 79,
      totalAmount: 180,
      status: "Pending",
      JourneyDate: "2023-08-30",
    },
    {
      lastName: "Davis",
      firstName: "David",
      age: 30,
      gender: "Male",
      RegNo: "REG613",
      country: "UK",
      receiptNo: "REC500",
      address: "415 Elm St, City",
      oppCharges: 114,
      gstCharges: 27,
      OTTCharges: 99,
      totalAmount: 240,
      status: "Paid",
      JourneyDate: "2023-03-10",
    },
    {
      lastName: "Smith",
      firstName: "William",
      age: 58,
      gender: "Female",
      RegNo: "REG522",
      country: "Canada",
      receiptNo: "REC976",
      address: "510 Elm St, City",
      oppCharges: 249,
      gstCharges: 13,
      OTTCharges: 93,
      totalAmount: 355,
      status: "Pending",
      JourneyDate: "2023-12-13",
    },
    {
      lastName: "Williams",
      firstName: "Richard",
      age: 43,
      gender: "Female",
      RegNo: "REG685",
      country: "Canada",
      receiptNo: "REC732",
      address: "810 Elm St, City",
      oppCharges: 99,
      gstCharges: 24,
      OTTCharges: 85,
      totalAmount: 208,
      status: "Cancelled",
      JourneyDate: "2024-03-15",
    },
    {
      lastName: "Williams",
      firstName: "David",
      age: 44,
      gender: "Female",
      RegNo: "REG984",
      country: "USA",
      receiptNo: "REC688",
      address: "703 Elm St, City",
      oppCharges: 157,
      gstCharges: 27,
      OTTCharges: 69,
      totalAmount: 253,
      status: "Cancelled",
      JourneyDate: "2023-02-20",
    },
    {
      lastName: "Smith",
      firstName: "William",
      age: 24,
      gender: "Male",
      RegNo: "REG69",
      country: "USA",
      receiptNo: "REC37",
      address: "770 Elm St, City",
      oppCharges: 219,
      gstCharges: 29,
      OTTCharges: 54,
      totalAmount: 302,
      status: "Paid",
      JourneyDate: "2023-07-24",
    },
    {
      lastName: "Smith",
      firstName: "William",
      age: 62,
      gender: "Female",
      RegNo: "REG584",
      country: "Canada",
      receiptNo: "REC605",
      address: "156 Elm St, City",
      oppCharges: 226,
      gstCharges: 20,
      OTTCharges: 14,
      totalAmount: 260,
      status: "Pending",
      JourneyDate: "2023-06-13",
    },
    {
      lastName: "Johnson",
      firstName: "James",
      age: 65,
      gender: "Male",
      RegNo: "REG277",
      country: "USA",
      receiptNo: "REC938",
      address: "0 Elm St, City",
      oppCharges: 234,
      gstCharges: 16,
      OTTCharges: 66,
      totalAmount: 316,
      status: "Paid",
      JourneyDate: "2024-02-20",
    },
    {
      lastName: "Smith",
      firstName: "David",
      age: 26,
      gender: "Female",
      RegNo: "REG0",
      country: "UK",
      receiptNo: "REC415",
      address: "884 Elm St, City",
      oppCharges: 87,
      gstCharges: 30,
      OTTCharges: 97,
      totalAmount: 214,
      status: "Paid",
      JourneyDate: "2023-07-25",
    },
    {
      lastName: "Davis",
      firstName: "Robert",
      age: 60,
      gender: "Male",
      RegNo: "REG351",
      country: "USA",
      receiptNo: "REC916",
      address: "778 Elm St, City",
      oppCharges: 58,
      gstCharges: 24,
      OTTCharges: 50,
      totalAmount: 132,
      status: "Paid",
      JourneyDate: "2023-12-11",
    },
    {
      lastName: "Johnson",
      firstName: "Michael",
      age: 57,
      gender: "Female",
      RegNo: "REG506",
      country: "USA",
      receiptNo: "REC502",
      address: "371 Elm St, City",
      oppCharges: 127,
      gstCharges: 17,
      OTTCharges: 46,
      totalAmount: 190,
      status: "Paid",
      JourneyDate: "2024-04-24",
    },
    {
      lastName: "Williams",
      firstName: "Robert",
      age: 47,
      gender: "Male",
      RegNo: "REG973",
      country: "UK",
      receiptNo: "REC81",
      address: "706 Elm St, City",
      oppCharges: 237,
      gstCharges: 39,
      OTTCharges: 34,
      totalAmount: 310,
      status: "Paid",
      JourneyDate: "2023-05-12",
    },
    {
      lastName: "Brown",
      firstName: "John",
      age: 55,
      gender: "Female",
      RegNo: "REG660",
      country: "India",
      receiptNo: "REC363",
      address: "164 Elm St, City",
      oppCharges: 58,
      gstCharges: 37,
      OTTCharges: 24,
      totalAmount: 119,
      status: "Pending",
      JourneyDate: "2023-05-02",
    },
    {
      lastName: "Johnson",
      firstName: "Richard",
      age: 49,
      gender: "Female",
      RegNo: "REG883",
      country: "USA",
      receiptNo: "REC759",
      address: "925 Elm St, City",
      oppCharges: 77,
      gstCharges: 14,
      OTTCharges: 93,
      totalAmount: 184,
      status: "Pending",
      JourneyDate: "2023-01-25",
    },
    {
      lastName: "Johnson",
      firstName: "John",
      age: 58,
      gender: "Female",
      RegNo: "REG649",
      country: "USA",
      receiptNo: "REC463",
      address: "235 Elm St, City",
      oppCharges: 212,
      gstCharges: 34,
      OTTCharges: 63,
      totalAmount: 309,
      status: "Cancelled",
      JourneyDate: "2023-07-13",
    },
    {
      lastName: "Miller",
      firstName: "James",
      age: 52,
      gender: "Male",
      RegNo: "REG256",
      country: "Canada",
      receiptNo: "REC757",
      address: "938 Elm St, City",
      oppCharges: 237,
      gstCharges: 15,
      OTTCharges: 56,
      totalAmount: 308,
      status: "Paid",
      JourneyDate: "2023-06-02",
    },
    {
      lastName: "Williams",
      firstName: "Michael",
      age: 36,
      gender: "Male",
      RegNo: "REG900",
      country: "India",
      receiptNo: "REC787",
      address: "845 Elm St, City",
      oppCharges: 187,
      gstCharges: 10,
      OTTCharges: 99,
      totalAmount: 296,
      status: "Paid",
      JourneyDate: "2023-01-10",
    },
    {
      lastName: "Williams",
      firstName: "Robert",
      age: 34,
      gender: "Female",
      RegNo: "REG380",
      country: "UK",
      receiptNo: "REC622",
      address: "476 Elm St, City",
      oppCharges: 63,
      gstCharges: 15,
      OTTCharges: 65,
      totalAmount: 143,
      status: "Paid",
      JourneyDate: "2023-11-01",
    },
    {
      lastName: "Miller",
      firstName: "David",
      age: 30,
      gender: "Female",
      RegNo: "REG831",
      country: "USA",
      receiptNo: "REC261",
      address: "588 Elm St, City",
      oppCharges: 119,
      gstCharges: 34,
      OTTCharges: 44,
      totalAmount: 197,
      status: "Paid",
      JourneyDate: "2024-05-05",
    },
    {
      lastName: "Jones",
      firstName: "Richard",
      age: 49,
      gender: "Female",
      RegNo: "REG190",
      country: "India",
      receiptNo: "REC119",
      address: "593 Elm St, City",
      oppCharges: 213,
      gstCharges: 23,
      OTTCharges: 70,
      totalAmount: 306,
      status: "Paid",
      JourneyDate: "2023-12-20",
    },
    {
      lastName: "Williams",
      firstName: "James",
      age: 49,
      gender: "Male",
      RegNo: "REG954",
      country: "India",
      receiptNo: "REC857",
      address: "208 Elm St, City",
      oppCharges: 162,
      gstCharges: 21,
      OTTCharges: 20,
      totalAmount: 203,
      status: "Paid",
      JourneyDate: "2023-06-07",
    },
    {
      lastName: "Brown",
      firstName: "Richard",
      age: 58,
      gender: "Female",
      RegNo: "REG699",
      country: "India",
      receiptNo: "REC98",
      address: "369 Elm St, City",
      oppCharges: 173,
      gstCharges: 23,
      OTTCharges: 80,
      totalAmount: 276,
      status: "Cancelled",
      JourneyDate: "2023-08-06",
    },
    {
      lastName: "Davis",
      firstName: "William",
      age: 26,
      gender: "Female",
      RegNo: "REG619",
      country: "Canada",
      receiptNo: "REC166",
      address: "989 Elm St, City",
      oppCharges: 57,
      gstCharges: 18,
      OTTCharges: 96,
      totalAmount: 171,
      status: "Paid",
      JourneyDate: "2023-04-28",
    },
    {
      lastName: "Williams",
      firstName: "David",
      age: 37,
      gender: "Female",
      RegNo: "REG286",
      country: "India",
      receiptNo: "REC855",
      address: "84 Elm St, City",
      oppCharges: 206,
      gstCharges: 36,
      OTTCharges: 40,
      totalAmount: 282,
      status: "Paid",
      JourneyDate: "2024-04-17",
    },
    {
      lastName: "Smith",
      firstName: "David",
      age: 54,
      gender: "Female",
      RegNo: "REG505",
      country: "India",
      receiptNo: "REC257",
      address: "225 Elm St, City",
      oppCharges: 85,
      gstCharges: 29,
      OTTCharges: 74,
      totalAmount: 188,
      status: "Pending",
      JourneyDate: "2023-08-15",
    },
    {
      lastName: "Smith",
      firstName: "John",
      age: 39,
      gender: "Female",
      RegNo: "REG645",
      country: "India",
      receiptNo: "REC180",
      address: "981 Elm St, City",
      oppCharges: 244,
      gstCharges: 36,
      OTTCharges: 75,
      totalAmount: 355,
      status: "Cancelled",
      JourneyDate: "2023-07-14",
    },
    {
      lastName: "Johnson",
      firstName: "Richard",
      age: 22,
      gender: "Male",
      RegNo: "REG3",
      country: "India",
      receiptNo: "REC24",
      address: "894 Elm St, City",
      oppCharges: 116,
      gstCharges: 26,
      OTTCharges: 54,
      totalAmount: 196,
      status: "Cancelled",
      JourneyDate: "2023-10-02",
    },
    {
      lastName: "Miller",
      firstName: "James",
      age: 48,
      gender: "Female",
      RegNo: "REG90",
      country: "Canada",
      receiptNo: "REC250",
      address: "125 Elm St, City",
      oppCharges: 193,
      gstCharges: 13,
      OTTCharges: 21,
      totalAmount: 227,
      status: "Pending",
      JourneyDate: "2023-02-15",
    },
    {
      lastName: "Williams",
      firstName: "William",
      age: 60,
      gender: "Female",
      RegNo: "REG875",
      country: "India",
      receiptNo: "REC947",
      address: "925 Elm St, City",
      oppCharges: 117,
      gstCharges: 20,
      OTTCharges: 26,
      totalAmount: 163,
      status: "Cancelled",
      JourneyDate: "2023-03-26",
    },
    {
      lastName: "Smith",
      firstName: "Robert",
      age: 41,
      gender: "Female",
      RegNo: "REG125",
      country: "UK",
      receiptNo: "REC137",
      address: "673 Elm St, City",
      oppCharges: 181,
      gstCharges: 32,
      OTTCharges: 11,
      totalAmount: 224,
      status: "Cancelled",
      JourneyDate: "2023-07-19",
    },
    {
      lastName: "Miller",
      firstName: "Michael",
      age: 21,
      gender: "Female",
      RegNo: "REG232",
      country: "Canada",
      receiptNo: "REC31",
      address: "297 Elm St, City",
      oppCharges: 168,
      gstCharges: 37,
      OTTCharges: 78,
      totalAmount: 283,
      status: "Pending",
      JourneyDate: "2023-03-01",
    },
    {
      lastName: "Jones",
      firstName: "Robert",
      age: 56,
      gender: "Female",
      RegNo: "REG861",
      country: "Australia",
      receiptNo: "REC569",
      address: "293 Elm St, City",
      oppCharges: 142,
      gstCharges: 38,
      OTTCharges: 93,
      totalAmount: 273,
      status: "Pending",
      JourneyDate: "2023-01-25",
    },
    {
      lastName: "Johnson",
      firstName: "James",
      age: 63,
      gender: "Male",
      RegNo: "REG419",
      country: "Australia",
      receiptNo: "REC724",
      address: "200 Elm St, City",
      oppCharges: 124,
      gstCharges: 10,
      OTTCharges: 39,
      totalAmount: 173,
      status: "Pending",
      JourneyDate: "2024-03-05",
    },
    {
      lastName: "Brown",
      firstName: "John",
      age: 21,
      gender: "Female",
      RegNo: "REG976",
      country: "Australia",
      receiptNo: "REC936",
      address: "409 Elm St, City",
      oppCharges: 76,
      gstCharges: 11,
      OTTCharges: 30,
      totalAmount: 117,
      status: "Paid",
      JourneyDate: "2024-04-06",
    },
    {
      lastName: "Johnson",
      firstName: "John",
      age: 25,
      gender: "Female",
      RegNo: "REG491",
      country: "India",
      receiptNo: "REC256",
      address: "172 Elm St, City",
      oppCharges: 166,
      gstCharges: 12,
      OTTCharges: 0,
      totalAmount: 178,
      status: "Cancelled",
      JourneyDate: "2024-02-26",
    },
    {
      lastName: "Miller",
      firstName: "William",
      age: 57,
      gender: "Female",
      RegNo: "REG599",
      country: "UK",
      receiptNo: "REC868",
      address: "662 Elm St, City",
      oppCharges: 159,
      gstCharges: 13,
      OTTCharges: 7,
      totalAmount: 179,
      status: "Paid",
      JourneyDate: "2023-06-17",
    },
    {
      lastName: "Williams",
      firstName: "John",
      age: 64,
      gender: "Female",
      RegNo: "REG490",
      country: "USA",
      receiptNo: "REC229",
      address: "155 Elm St, City",
      oppCharges: 111,
      gstCharges: 23,
      OTTCharges: 88,
      totalAmount: 222,
      status: "Paid",
      JourneyDate: "2023-05-31",
    },
    {
      lastName: "Jones",
      firstName: "Michael",
      age: 40,
      gender: "Male",
      RegNo: "REG838",
      country: "Canada",
      receiptNo: "REC6",
      address: "995 Elm St, City",
      oppCharges: 69,
      gstCharges: 33,
      OTTCharges: 20,
      totalAmount: 122,
      status: "Paid",
      JourneyDate: "2023-08-23",
    },
    {
      lastName: "Johnson",
      firstName: "John",
      age: 59,
      gender: "Female",
      RegNo: "REG424",
      country: "India",
      receiptNo: "REC909",
      address: "201 Elm St, City",
      oppCharges: 113,
      gstCharges: 11,
      OTTCharges: 24,
      totalAmount: 148,
      status: "Paid",
      JourneyDate: "2024-04-10",
    },
    {
      lastName: "Brown",
      firstName: "David",
      age: 69,
      gender: "Male",
      RegNo: "REG763",
      country: "USA",
      receiptNo: "REC307",
      address: "699 Elm St, City",
      oppCharges: 86,
      gstCharges: 23,
      OTTCharges: 93,
      totalAmount: 202,
      status: "Cancelled",
      JourneyDate: "2023-12-27",
    },
    {
      lastName: "Smith",
      firstName: "John",
      age: 38,
      gender: "Female",
      RegNo: "REG268",
      country: "India",
      receiptNo: "REC715",
      address: "461 Elm St, City",
      oppCharges: 145,
      gstCharges: 32,
      OTTCharges: 90,
      totalAmount: 267,
      status: "Cancelled",
      JourneyDate: "2023-06-14",
    },
    {
      lastName: "Davis",
      firstName: "John",
      age: 49,
      gender: "Female",
      RegNo: "REG603",
      country: "UK",
      receiptNo: "REC446",
      address: "889 Elm St, City",
      oppCharges: 217,
      gstCharges: 33,
      OTTCharges: 71,
      totalAmount: 321,
      status: "Cancelled",
      JourneyDate: "2023-07-04",
    },
    {
      lastName: "Brown",
      firstName: "Robert",
      age: 41,
      gender: "Female",
      RegNo: "REG488",
      country: "Australia",
      receiptNo: "REC961",
      address: "313 Elm St, City",
      oppCharges: 192,
      gstCharges: 39,
      OTTCharges: 53,
      totalAmount: 284,
      status: "Pending",
      JourneyDate: "2023-03-06",
    },
    {
      lastName: "Miller",
      firstName: "James",
      age: 44,
      gender: "Male",
      RegNo: "REG966",
      country: "Australia",
      receiptNo: "REC578",
      address: "838 Elm St, City",
      oppCharges: 72,
      gstCharges: 39,
      OTTCharges: 68,
      totalAmount: 179,
      status: "Cancelled",
      JourneyDate: "2024-02-18",
    },
    {
      lastName: "Smith",
      firstName: "David",
      age: 21,
      gender: "Male",
      RegNo: "REG702",
      country: "Canada",
      receiptNo: "REC444",
      address: "877 Elm St, City",
      oppCharges: 169,
      gstCharges: 14,
      OTTCharges: 51,
      totalAmount: 234,
      status: "Cancelled",
      JourneyDate: "2024-02-16",
    },
    {
      lastName: "Davis",
      firstName: "Robert",
      age: 52,
      gender: "Male",
      RegNo: "REG894",
      country: "USA",
      receiptNo: "REC74",
      address: "848 Elm St, City",
      oppCharges: 85,
      gstCharges: 26,
      OTTCharges: 89,
      totalAmount: 200,
      status: "Paid",
      JourneyDate: "2024-02-15",
    },
    {
      lastName: "Johnson",
      firstName: "Robert",
      age: 58,
      gender: "Female",
      RegNo: "REG223",
      country: "USA",
      receiptNo: "REC392",
      address: "294 Elm St, City",
      oppCharges: 243,
      gstCharges: 38,
      OTTCharges: 1,
      totalAmount: 282,
      status: "Paid",
      JourneyDate: "2023-07-01",
    },
    {
      lastName: "Smith",
      firstName: "Richard",
      age: 55,
      gender: "Female",
      RegNo: "REG999",
      country: "Australia",
      receiptNo: "REC685",
      address: "239 Elm St, City",
      oppCharges: 58,
      gstCharges: 19,
      OTTCharges: 63,
      totalAmount: 140,
      status: "Cancelled",
      JourneyDate: "2023-07-09",
    },
    {
      lastName: "Smith",
      firstName: "David",
      age: 61,
      gender: "Female",
      RegNo: "REG543",
      country: "USA",
      receiptNo: "REC645",
      address: "675 Elm St, City",
      oppCharges: 185,
      gstCharges: 27,
      OTTCharges: 60,
      totalAmount: 272,
      status: "Cancelled",
      JourneyDate: "2023-12-08",
    },
    {
      lastName: "Williams",
      firstName: "David",
      age: 32,
      gender: "Female",
      RegNo: "REG467",
      country: "Canada",
      receiptNo: "REC466",
      address: "500 Elm St, City",
      oppCharges: 220,
      gstCharges: 35,
      OTTCharges: 44,
      totalAmount: 299,
      status: "Pending",
      JourneyDate: "2023-03-13",
    },
    {
      lastName: "Johnson",
      firstName: "James",
      age: 42,
      gender: "Male",
      RegNo: "REG924",
      country: "India",
      receiptNo: "REC385",
      address: "513 Elm St, City",
      oppCharges: 144,
      gstCharges: 32,
      OTTCharges: 8,
      totalAmount: 184,
      status: "Paid",
      JourneyDate: "2023-09-10",
    },
    {
      lastName: "Jones",
      firstName: "David",
      age: 55,
      gender: "Male",
      RegNo: "REG671",
      country: "Canada",
      receiptNo: "REC334",
      address: "291 Elm St, City",
      oppCharges: 140,
      gstCharges: 38,
      OTTCharges: 88,
      totalAmount: 266,
      status: "Paid",
      JourneyDate: "2023-11-01",
    },
    {
      lastName: "Brown",
      firstName: "Michael",
      age: 43,
      gender: "Male",
      RegNo: "REG966",
      country: "Canada",
      receiptNo: "REC482",
      address: "919 Elm St, City",
      oppCharges: 105,
      gstCharges: 36,
      OTTCharges: 42,
      totalAmount: 183,
      status: "Pending",
      JourneyDate: "2024-02-12",
    },
    {
      lastName: "Jones",
      firstName: "John",
      age: 68,
      gender: "Male",
      RegNo: "REG664",
      country: "UK",
      receiptNo: "REC966",
      address: "185 Elm St, City",
      oppCharges: 130,
      gstCharges: 32,
      OTTCharges: 13,
      totalAmount: 175,
      status: "Paid",
      JourneyDate: "2023-12-21",
    },
    {
      lastName: "Williams",
      firstName: "William",
      age: 30,
      gender: "Female",
      RegNo: "REG914",
      country: "India",
      receiptNo: "REC963",
      address: "168 Elm St, City",
      oppCharges: 58,
      gstCharges: 34,
      OTTCharges: 73,
      totalAmount: 165,
      status: "Cancelled",
      JourneyDate: "2024-05-27",
    },
    {
      lastName: "Williams",
      firstName: "David",
      age: 67,
      gender: "Male",
      RegNo: "REG958",
      country: "Canada",
      receiptNo: "REC11",
      address: "624 Elm St, City",
      oppCharges: 87,
      gstCharges: 15,
      OTTCharges: 18,
      totalAmount: 120,
      status: "Pending",
      JourneyDate: "2023-03-12",
    },
    {
      lastName: "Johnson",
      firstName: "Richard",
      age: 48,
      gender: "Female",
      RegNo: "REG34",
      country: "Australia",
      receiptNo: "REC697",
      address: "887 Elm St, City",
      oppCharges: 142,
      gstCharges: 16,
      OTTCharges: 76,
      totalAmount: 234,
      status: "Pending",
      JourneyDate: "2023-02-23",
    },
    {
      lastName: "Johnson",
      firstName: "Robert",
      age: 66,
      gender: "Male",
      RegNo: "REG558",
      country: "Australia",
      receiptNo: "REC195",
      address: "948 Elm St, City",
      oppCharges: 85,
      gstCharges: 23,
      OTTCharges: 60,
      totalAmount: 168,
      status: "Cancelled",
      JourneyDate: "2024-06-16",
    },
    {
      lastName: "Miller",
      firstName: "James",
      age: 54,
      gender: "Female",
      RegNo: "REG511",
      country: "Canada",
      receiptNo: "REC784",
      address: "55 Elm St, City",
      oppCharges: 64,
      gstCharges: 20,
      OTTCharges: 35,
      totalAmount: 119,
      status: "Pending",
      JourneyDate: "2023-11-10",
    },
    {
      lastName: "Brown",
      firstName: "John",
      age: 45,
      gender: "Male",
      RegNo: "REG583",
      country: "Canada",
      receiptNo: "REC606",
      address: "443 Elm St, City",
      oppCharges: 90,
      gstCharges: 27,
      OTTCharges: 3,
      totalAmount: 120,
      status: "Pending",
      JourneyDate: "2023-07-15",
    },
    {
      lastName: "Smith",
      firstName: "John",
      age: 68,
      gender: "Female",
      RegNo: "REG218",
      country: "Australia",
      receiptNo: "REC353",
      address: "66 Elm St, City",
      oppCharges: 215,
      gstCharges: 11,
      OTTCharges: 95,
      totalAmount: 321,
      status: "Paid",
      JourneyDate: "2023-09-26",
    },
    {
      lastName: "Jones",
      firstName: "John",
      age: 57,
      gender: "Female",
      RegNo: "REG381",
      country: "Canada",
      receiptNo: "REC182",
      address: "416 Elm St, City",
      oppCharges: 75,
      gstCharges: 34,
      OTTCharges: 70,
      totalAmount: 179,
      status: "Paid",
      JourneyDate: "2023-09-19",
    },
  ];
}
