import React from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import Pagehader from "../../Layout/Pagehader";
import { decryptData } from "../../util/CryptoJS";

import Holding from "./Holding";
import HoldingUnderlying from "./HoldingUnderlying";
import InstrumentSectorMapping from "./InstrumentSectorMapping";
import Issuers from "./Issuers";
import SectorClassification from "./SectorClassification";
import Transaction from "./Transaction";

export default function UploadsComponent() {
  const modes = decryptData(useParams().mode);
  // console.log(modes);
  const { t } = useTranslation(["Common", "Messages", "Form"]);

  const breadcrumbItems = [
    {
      label: t("Common:App_lms_Common_00229"),

      // icon: <FontAwesomeIcon icon={faList} />,
    },
    {
      label: pagemode(),
      // icon: <FontAwesomeIcon icon={faList} />,
    },
  ];

  return (
    <>
      <Pagehader
        pagename={pagemode()}
        Breadcrumbshow={true}
        breadcrumbItems={breadcrumbItems}
      ></Pagehader>

      <div className="pagebody ">{pageFunction()}</div>
    </>
  );

  function pagemode() {
    let modeLabel;

    if (modes === "Holding") {
      modeLabel = t("Common:App_lms_Common_00249");
    } else if (modes === "HoldingUnderlying") {
      modeLabel = t("Common:App_lms_Common_00250");
    } else if (modes === "SectorClassification") {
      modeLabel = t("Common:App_lms_Common_00251");
    } else if (modes === "Transaction") {
      modeLabel = t("Common:App_lms_Common_00252");
    } else if (modes === "Issuers") {
      modeLabel = t("Common:App_lms_Common_00253");
    } else if (modes === "InstrumentSectorMapping") {
      modeLabel = t("Common:App_lms_Common_00254");
    } else {
      modeLabel = ""; // Default case
    }
    return t("Common:App_lms_Common_00005", {
      mode: t("Common:App_lms_Common_00229"),
      label: modeLabel,
    });
  }

  function pageFunction() {
    let component;
    if (modes === "Holding") {
      component = <Holding />;
    } else if (modes === "HoldingUnderlying") {
      component = <HoldingUnderlying />;
    } else if (modes === "SectorClassification") {
      component = <SectorClassification />;
    } else if (modes === "Transaction") {
      component = <Transaction />;
    } else if (modes === "InstrumentSectorMapping") {
      component = <InstrumentSectorMapping />;
    } else if (modes === "Issuers") {
      component = <Issuers />;
    } else {
      component = null; // or use an empty fragment <></> if you prefer
    }

    return component;
  }
}
