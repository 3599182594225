import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import AppModal from "../Component/Modal/AppModal";
import {
  getIdleTimer,
  getLoginType,
  logout,
  removeAccess,
  removeLoginType,
  removeToken,
  setLocalStorage,
} from "../util";
import { decryptData } from "../util/CryptoJS";
import { useIdleTimeout } from "../util/IdleTimeoutContext";

export default function IdleModalTimer() {
  const [isIdleModalOpen, setIsIdleModalOpen] = useState(false);
  const [seconds, setSeconds] = useState(0);
  const { isIdle, resetIdleTimer } = useIdleTimeout();
  const navigate = useNavigate();

  /**
   * Logs the user out and resets application state.
   */
  
  const handleLogout = () => {
    removeToken();
    removeLoginType();
    removeAccess();
    setLocalStorage("counter", 0);
    navigate("/" );
    
    logout("false");
  };

  /**
   * Handles the countdown when the idle modal is open.
   */
  useEffect(() => {
    if (isIdleModalOpen && seconds > 0) {
      const timer = setInterval(() => {
        setSeconds((prevSeconds) => prevSeconds - 1);
      }, decryptData(getIdleTimer()));

      // Clear the interval when the component unmounts or countdown ends
      return () => clearInterval(timer);
    }

    if (isIdleModalOpen && seconds === 0) {
      handleLogout();
    }
  }, [isIdleModalOpen, seconds]);

  /**
   * Triggers the idle modal when the user becomes idle.
   */
  useEffect(() => {
    if (isIdle) {
      setSeconds(30); // Set countdown duration
      setIsIdleModalOpen(true);
    }
  }, [isIdle]);

  /**
   * Handles modal close and resets the idle timer.
   */
  const handleModalClose = () => {
    setIsIdleModalOpen(false);
    resetIdleTimer();
  };

  /**
   * Handles the user's action to continue the session.
   */
  const handleAction = () => {
    setIsIdleModalOpen(false);
    resetIdleTimer();
  };

  return (
    <div>
      <AppModal
        isOpen={isIdleModalOpen}
        onClose={handleModalClose}
        handleActon={handleAction} // Ensure this is defined and passed correctly
        ModalTitle="Idle Timeout Warning"
        Modalsize="md"
        buttonConfigs={[{ text: "Continue", icon: null, action: "continue" }]}
        ModalBody={<p>You have been idle for some time. Do you want to continue?</p>}
        ModalType="IdleTimeout"
        ReactOdometervalue={seconds}
      />
      {/* <AppModal
        isOpen={isIdleModalOpen}
        onClose={handleModalClose}
        handleAction={handleAction}
        ModalTitle="Idle Timeout Warning"
       
        Modalsize="md"
        buttonConfigs={[
          { text: "Continue", icon: "yes", action: handleAction },
        ]}
        ModalBody={
          <p>You have been idle for some time. Do you want to continue?</p>
        }
        ModalType="IdleTimeout"
      /> */}
    </div>
  );
}
