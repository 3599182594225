export const TenantMasterData = [
  {
    lastModifiedDate: "2024-11-14 13:59:39",
    id: 44,
    realm: "ABL5000111",
    fileUploadType: "SUBSCRIBER_REG_BULK_UPLOAD",
    fileId: "00731461-daae-4f15-954c-64535424a320",
    fileName:
      "1731572979749_20241112180455_New_Template_Subscriber_Upload.xlsx",
    filepath: "/home/supratim_c/popbo/temp/",
    recordCount: 1,
    successCount: 1,
    errorCount: 0,
    uploadStatus: "ACCEPTED",
    deleted: false,
    rejectionFileId: null,
    rejectionFileName: null,
    responseFileId: "0747ecfc-331f-4019-8ad0-dc5203266e6a",
    responseFileName:
      "14_11_2024_13_59_39_Response_Records_Subscriber_Registration_44.xlsx",
    popSpId: 0,
    popSeId: 0,
    choId: 51,
    cboId: 644,
    uploadedBy: "POP_USER",
  },
  {
    lastModifiedDate: "2024-11-14 12:27:33",
    id: 43,
    realm: "ABL5000111",
    fileUploadType: "SUBSCRIBER_REG_BULK_UPLOAD",
    fileId: "c09c7bfb-89a3-4467-9039-bc8c4860e014",
    fileName:
      "1731567453504_20241112180455_New_Template_Subscriber_Upload.xlsx",
    filepath: "/home/supratim_c/popbo/temp/",
    recordCount: 1,
    successCount: 1,
    errorCount: 0,
    uploadStatus: "ACCEPTED",
    deleted: false,
    rejectionFileId: null,
    rejectionFileName: null,
    responseFileId: "afd63f90-61a5-498e-bc49-5d5b56ef1bd8",
    responseFileName:
      "14_11_2024_12_27_33_Response_Records_Subscriber_Registration_43.xlsx",
    popSpId: 0,
    popSeId: 0,
    choId: 51,
    cboId: 644,
    uploadedBy: "POP_USER",
  },
  {
    lastModifiedDate: "2024-11-14 12:25:31",
    id: 42,
    realm: "ABL5000111",
    fileUploadType: "SUBSCRIBER_REG_BULK_UPLOAD",
    fileId: "0f59c10c-4062-445b-b83f-c1f0878106ca",
    fileName:
      "1731567331554_20241112180455_New_Template_Subscriber_Upload.xlsx",
    filepath: "/home/supratim_c/popbo/temp/",
    recordCount: 1,
    successCount: 1,
    errorCount: 0,
    uploadStatus: "ACCEPTED",
    deleted: false,
    rejectionFileId: null,
    rejectionFileName: null,
    responseFileId: "eb6264b5-9c1a-4367-825b-cfa1e13374b4",
    responseFileName:
      "14_11_2024_12_25_31_Response_Records_Subscriber_Registration_42.xlsx",
    popSpId: 0,
    popSeId: 0,
    choId: 51,
    cboId: 644,
    uploadedBy: "POP_USER",
  },
  {
    lastModifiedDate: "2024-11-14 12:24:06",
    id: 41,
    realm: "ABL5000111",
    fileUploadType: "SUBSCRIBER_REG_BULK_UPLOAD",
    fileId: "fec15c10-4ac2-4a85-b780-bd8772799f9e",
    fileName:
      "1731567246660_20241112180455_New_Template_Subscriber_Upload.xlsx",
    filepath: "/home/supratim_c/popbo/temp/",
    recordCount: 1,
    successCount: 1,
    errorCount: 0,
    uploadStatus: "ACCEPTED",
    deleted: false,
    rejectionFileId: null,
    rejectionFileName: null,
    responseFileId: "4c6d2d96-cda0-444d-95e1-bd8dcf5d785a",
    responseFileName:
      "14_11_2024_12_24_06_Response_Records_Subscriber_Registration_41.xlsx",
    popSpId: 0,
    popSeId: 0,
    choId: 51,
    cboId: 644,
    uploadedBy: "POP_USER",
  },
  {
    lastModifiedDate: "2024-11-14 12:21:26",
    id: 40,
    realm: "ABL5000111",
    fileUploadType: "SUBSCRIBER_REG_BULK_UPLOAD",
    fileId: "846b56d9-709a-4904-a218-cb88a690971c",
    fileName:
      "1731567086090_20241112180455_New_Template_Subscriber_Upload.xlsx",
    filepath: "/home/supratim_c/popbo/temp/",
    recordCount: 1,
    successCount: 1,
    errorCount: 0,
    uploadStatus: "ACCEPTED",
    deleted: false,
    rejectionFileId: null,
    rejectionFileName: null,
    responseFileId: "2647b17a-de0f-4fb2-844f-604f1dca76b2",
    responseFileName:
      "14_11_2024_12_21_26_Response_Records_Subscriber_Registration_40.xlsx",
    popSpId: 0,
    popSeId: 0,
    choId: 51,
    cboId: 644,
    uploadedBy: "POP_USER",
  },
  {
    lastModifiedDate: "2024-11-14 11:17:55",
    id: 38,
    realm: "ABL5000111",
    fileUploadType: "SUBSCRIBER_REG_BULK_UPLOAD",
    fileId: "5559b23a-a7af-4ae9-aaad-7f7ec6e35b76",
    fileName:
      "1731563275285_20241114111617_New_Template_Subscriber_Upload_14112024.xlsx",
    filepath: "/home/supratim_c/popbo/temp/",
    recordCount: 1,
    successCount: 1,
    errorCount: 0,
    uploadStatus: "ACCEPTED",
    deleted: false,
    rejectionFileId: null,
    rejectionFileName: null,
    responseFileId: "fb8a3dec-7742-4b14-a2c7-181e7eeab651",
    responseFileName:
      "14_11_2024_11_17_55_Response_Records_Subscriber_Registration_38.xlsx",
    popSpId: 0,
    popSeId: 0,
    choId: 51,
    cboId: 644,
    uploadedBy: "CORP_USER",
  },
  {
    lastModifiedDate: "2024-11-13 19:13:55",
    id: 36,
    realm: "ABL5000111",
    fileUploadType: "SUBSCRIBER_REG_BULK_UPLOAD",
    fileId: "14fcb65f-261e-4b8a-8731-d0ec0144cd7a",
    fileName:
      "1731505435734_20241112180455_New_Template_Subscriber_Upload.xlsx",
    filepath: "/home/supratim_c/popbo/temp/",
    recordCount: 1,
    successCount: 1,
    errorCount: 0,
    uploadStatus: "ACCEPTED",
    deleted: false,
    rejectionFileId: null,
    rejectionFileName: null,
    responseFileId: "cd40b4d9-3eec-42fc-9333-41c9f6205380",
    responseFileName:
      "13_11_2024_19_13_55_Response_Records_Subscriber_Registration_36.xlsx",
    popSpId: 0,
    popSeId: 0,
    choId: 51,
    cboId: 644,
    uploadedBy: "POP_USER",
  },
  {
    lastModifiedDate: "2024-11-13 17:24:19",
    id: 33,
    realm: "ABL5000111",
    fileUploadType: "SUBSCRIBER_REG_BULK_UPLOAD",
    fileId: "3dddd289-73fa-4657-a083-08589db62e85",
    fileName:
      "1731498858872_20241112180455_New_Template_Subscriber_Upload.xlsx",
    filepath: "/home/supratim_c/popbo/temp/",
    recordCount: 1,
    successCount: 0,
    errorCount: 1,
    uploadStatus: "REJECTED",
    deleted: false,
    rejectionFileId: "3cfc341d-276e-478e-a8d7-a9bb5a87b27d",
    rejectionFileName:
      "13_11_2024_17_24_19_Rejected_Records_Subscriber_Registration_33.xlsx",
    responseFileId: "2629565b-dd6d-45f7-9151-8406e712fb0e",
    responseFileName:
      "13_11_2024_17_24_19_Response_Records_Subscriber_Registration_33.xlsx",
    popSpId: 0,
    popSeId: 0,
    choId: 51,
    cboId: 644,
    uploadedBy: "POP_USER",
  },
  {
    lastModifiedDate: "2024-11-13 17:02:59",
    id: 32,
    realm: "ABL5000111",
    fileUploadType: "SUBSCRIBER_REG_BULK_UPLOAD",
    fileId: "27d87a18-64a7-4d62-8d57-4c13832d3c50",
    fileName:
      "1731497578550_20241112180455_New_Template_Subscriber_Upload.xlsx",
    filepath: "/home/supratim_c/popbo/temp/",
    recordCount: 3,
    successCount: 0,
    errorCount: 3,
    uploadStatus: "REJECTED",
    deleted: false,
    rejectionFileId: "a4847f0f-fc85-44f0-8041-a15a561d86b2",
    rejectionFileName:
      "13_11_2024_17_02_59_Rejected_Records_Subscriber_Registration_32.xlsx",
    responseFileId: "c7afe370-0235-4543-93ca-5c68f17790bd",
    responseFileName:
      "13_11_2024_17_02_59_Response_Records_Subscriber_Registration_32.xlsx",
    popSpId: 0,
    popSeId: 0,
    choId: 51,
    cboId: 644,
    uploadedBy: "POP_USER",
  },
  {
    lastModifiedDate: "2024-11-13 09:32:54",
    id: 20,
    realm: "ABL5000111",
    fileUploadType: "SUBSCRIBER_REG_BULK_UPLOAD",
    fileId: "900ee105-c300-468c-8c65-4d7b59283858",
    fileName:
      "1731470574580_1731053044380_1731051809818_20230525123634_Template_Subscriber_Upload_13112024.xlsx",
    filepath: "/home/supratim_c/popbo/temp/",
    recordCount: 0,
    successCount: 0,
    errorCount: 0,
    uploadStatus: "FAILURE",
    deleted: false,
    rejectionFileId: "85fc4038-dfc5-4254-89e0-54957f4d7c27",
    rejectionFileName:
      "13_11_2024_09_32_54_Rejected_Records_Subscriber_Registration_20.xlsx",
    responseFileId: "16433c6b-302b-4f2f-aa1c-e742cb7c1bda",
    responseFileName:
      "13_11_2024_09_32_54_Response_Records_Subscriber_Registration_20.xlsx",
    popSpId: 0,
    popSeId: 0,
    choId: 51,
    cboId: 644,
    uploadedBy: "CORP_USER",
  },
  {
    lastModifiedDate: "2024-11-13 04:25:38",
    id: 18,
    realm: "ABL5000111",
    fileUploadType: "SUBSCRIBER_REG_BULK_UPLOAD",
    fileId: "c509549c-2131-4008-984a-78f0d1e1f0ff",
    fileName:
      "1731452138827_20241112180455_New_Template_Subscriber_Upload (1).xlsx",
    filepath: "/home/supratim_c/popbo/temp/",
    recordCount: 0,
    successCount: 0,
    errorCount: 0,
    uploadStatus: "FAILURE",
    deleted: false,
    rejectionFileId: "906b94af-95ec-49d4-8430-88b00dd27ebc",
    rejectionFileName:
      "13_11_2024_04_25_38_Rejected_Records_Subscriber_Registration_18.xlsx",
    responseFileId: "e5ab831e-97c4-4dc0-9b3c-726252a4f42b",
    responseFileName:
      "13_11_2024_04_25_38_Response_Records_Subscriber_Registration_18.xlsx",
    popSpId: 0,
    popSeId: 0,
    choId: 51,
    cboId: 644,
    uploadedBy: "POP_USER",
  },
  {
    createdBy: {
      user: "ddda2346-c2c3-4d27-a79d-74cece0e36d2",
      clientIp: "103.171.247.28",
      hostIp: "127.0.0.1",
      sessionId: "7149DDA2BB30C0BAAD92414992CE718C",
    },
    createdDate: "2024-11-12 18:12:26",
    modifiedBy: {
      user: "ddda2346-c2c3-4d27-a79d-74cece0e36d2",
      clientIp: "103.171.247.28",
      hostIp: "127.0.0.1",
      sessionId: "7149DDA2BB30C0BAAD92414992CE718C",
    },
    lastModifiedDate: "2024-11-12 18:12:26",
    id: 17,
    realm: "ABL5000111",
    fileUploadType: "SUBSCRIBER_REG_BULK_UPLOAD",
    fileId: "7be9cd80-e042-42eb-9c82-783ac00956d2",
    fileName:
      "1731415346223_20241112180455_New_Template_Subscriber_Upload.xlsx",
    filepath: "/home/supratim_c/popbo/temp/",
    recordCount: 0,
    successCount: 0,
    errorCount: 0,
    uploadStatus: "FAILURE",
    deleted: false,
    rejectionFileId: null,
    rejectionFileName: null,
    responseFileId: "71236ba9-3b99-4f6b-a1e2-67c71fde5d00",
    responseFileName:
      "12_11_2024_18_12_26_Response_Records_Subscriber_Registration_17.xlsx",
    popSpId: 0,
    popSeId: 0,
    choId: 51,
    cboId: 644,
    uploadedBy: "POP_USER",
  },
  {
    createdBy: {
      user: "ddda2346-c2c3-4d27-a79d-74cece0e36d2",
      clientIp: "103.171.247.28",
      hostIp: "127.0.0.1",
      sessionId: "DB91C18655743761A3F23C1265FD611A",
    },
    createdDate: "2024-11-12 18:07:52",
    modifiedBy: {
      user: "ddda2346-c2c3-4d27-a79d-74cece0e36d2",
      clientIp: "103.171.247.28",
      hostIp: "127.0.0.1",
      sessionId: "DB91C18655743761A3F23C1265FD611A",
    },
    lastModifiedDate: "2024-11-12 18:07:52",
    id: 16,
    realm: "ABL5000111",
    fileUploadType: "SUBSCRIBER_REG_BULK_UPLOAD",
    fileId: "7cf98a19-1e12-4dd0-b178-8eb7c4f6b213",
    fileName:
      "1731415072330_20241112180455_New_Template_Subscriber_Upload.xlsx",
    filepath: "/home/supratim_c/popbo/temp/",
    recordCount: 0,
    successCount: 0,
    errorCount: 0,
    uploadStatus: "FAILURE",
    deleted: false,
    rejectionFileId: "cafaf5a1-e4e6-4fc9-88b0-5cc9734511bd",
    rejectionFileName:
      "12_11_2024_18_07_52_Rejected_Records_Subscriber_Registration_16.xlsx",
    responseFileId: "1f44310e-32dc-4a76-9bfe-d6cf79e381ed",
    responseFileName:
      "12_11_2024_18_07_52_Response_Records_Subscriber_Registration_16.xlsx",
    popSpId: 0,
    popSeId: 0,
    choId: 51,
    cboId: 644,
    uploadedBy: "POP_USER",
  },
  {
    createdBy: {
      user: "ddda2346-c2c3-4d27-a79d-74cece0e36d2",
      clientIp: "103.171.247.28",
      hostIp: "127.0.0.1",
      sessionId: "F3793F64C9D3060F7D74980E24E78BAB",
    },
    createdDate: "2024-11-12 18:01:41",
    modifiedBy: {
      user: "ddda2346-c2c3-4d27-a79d-74cece0e36d2",
      clientIp: "103.171.247.28",
      hostIp: "127.0.0.1",
      sessionId: "F3793F64C9D3060F7D74980E24E78BAB",
    },
    lastModifiedDate: "2024-11-12 18:01:41",
    id: 15,
    realm: "ABL5000111",
    fileUploadType: "SUBSCRIBER_REG_BULK_UPLOAD",
    fileId: "9f2e296b-3bb6-4cbe-ae57-c206b9276cc8",
    fileName:
      "1731414700909_20241112180108_New_Template_Subscriber_Upload.xlsx",
    filepath: "/home/supratim_c/popbo/temp/",
    recordCount: 0,
    successCount: 0,
    errorCount: 0,
    uploadStatus: "FAILURE",
    deleted: false,
    rejectionFileId: null,
    rejectionFileName: null,
    responseFileId: "f40a041c-4cb4-44ae-a7c5-f77fe8586708",
    responseFileName:
      "12_11_2024_18_01_41_Response_Records_Subscriber_Registration_15.xlsx",
    popSpId: 0,
    popSeId: 0,
    choId: 51,
    cboId: 644,
    uploadedBy: "POP_USER",
  },
  {
    createdBy: {
      user: "ddda2346-c2c3-4d27-a79d-74cece0e36d2",
      clientIp: "0:0:0:0:0:0:0:1",
      hostIp: "0:0:0:0:0:0:0:1",
      sessionId: "DBA52887C43087A0C5D7C2E0D5B2914E",
    },
    createdDate: "2024-11-08 13:34:08",
    modifiedBy: {
      user: "ddda2346-c2c3-4d27-a79d-74cece0e36d2",
      clientIp: "0:0:0:0:0:0:0:1",
      hostIp: "0:0:0:0:0:0:0:1",
      sessionId: "DBA52887C43087A0C5D7C2E0D5B2914E",
    },
    lastModifiedDate: "2024-11-08 13:34:08",
    id: 13,
    realm: "ABL5000111",
    fileUploadType: "SUBSCRIBER_REG_BULK_UPLOAD",
    fileId: "2c42b9a0-a655-4106-9849-d4da0bbf39cf",
    fileName:
      "1731053044380_1731051809818_20230525123634_Template_Subscriber_Upload (1).xlsx",
    filepath: "/home/supratim_c/popbo/temp/",
    recordCount: 0,
    successCount: 0,
    errorCount: 0,
    uploadStatus: "RUNNING",
    deleted: false,
    rejectionFileId: null,
    rejectionFileName: null,
    responseFileId: null,
    responseFileName: null,
    popSpId: 0,
    popSeId: 0,
    choId: 51,
    cboId: 644,
    uploadedBy: "POP_USER",
  },
];
