import React from "react";
import { useNavigate } from 'react-router-dom';
import ButtonComp from "../Component/ButtonComp/ButtonComp";
import Loader from "../util/Loader";

const PageNotFound = () => {
  const navigate = useNavigate();
  const handlenavigate = () => {
    navigate("/");
  };
  return (
    // <section className="error-page">
    //   <div className="center">
    //     <div className="block text-center">
    //       <h1>404</h1>
    //       <h3>Page Not Found</h3>
    //       <p>
    //         The link you clicked may be broken or the
    //         <br />
    //         page may have been removed.
    //       </p>

    //       <ButtonComp
    //         wrapperName="submit_btn_wrapper"
    //         type="submit"
    //         btnStyle="box"
    //         btnText={"visit homepage"}
    //         disabled={false}
    //         onClick={() => handlenavigate()}
    //       />
    //     </div>
    //   </div>
    // </section>
    <Loader pagename="Updating ..." />
  );
};

export default PageNotFound;
