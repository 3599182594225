import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import {
  getDelayTime,
  getToken,
  logout,
  removeAccess,
  removeLoginType,
  removeToken,
  setLocalStorage,
  validateJwt,
} from "./index";

const AuthComponent = () => {
  const navigate = useNavigate();

  /**
   * Checks the validity of the current token and triggers auto logout if invalid.
   */
  const checkTokenValidity = () => {
    const token = getToken();

    if (token) {
      if (!validateJwt(token)) {
        handleAutoLogout();
      }
    } else {
      handleAutoLogout();
    }
  };

  /**
   * Handles user auto-logout by clearing data and redirecting to the login page.
   */
  const handleAutoLogout = () => {
    // Clear all relevant data
    removeToken();
    removeLoginType();
    removeAccess();
    setLocalStorage("counter", 0);
    // Navigate to the login page
    navigate("/");
    logout("false");

    // Optionally reload the page to reset the application state
    // window.location.reload();
  };

  /**
   * Sets up an interval to periodically check token validity.
   */
  useEffect(() => {
    const intervalId = setInterval(() => {
      checkTokenValidity();
    }, getDelayTime()); // Default: 3000 ms (3 seconds)

    // Cleanup interval on component unmount
    return () => clearInterval(intervalId);
  }, []);

  // Component renders nothing
  return null;
};

export default AuthComponent;
