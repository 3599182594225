import {
  faDownload,
  faFileExcel,
  faFilePdf,
  faRetweet,
  faSearch,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { themeQuartz } from "@ag-grid-community/theming";
import { AgGridReact } from "ag-grid-react";
import PropTypes from "prop-types";
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { Col, Row } from "react-bootstrap";

const myTheme = themeQuartz.withParams({
  browserColorScheme: "light",
  headerFontSize: 14,
});

export default function AgTable({
  columnKeys,
  columnDefs,
  rowData,
  filenames,
  onSelectionChanged,
  StyleClass,
  type,
}) {
  const gridRef = useRef();
  const [searchText, setSearchText] = useState("");

  // Default column settings
  const defaultColDef = useMemo(
    () => ({
      editable: false,
      wrapText: true,
      autoHeight: true,
      sortable: true,
      resizable: true,
      filter: true,
      minWidth: 100,
      suppressSizeToFit: true,
      flex: 1,
      tooltipComponent: "customTooltip",
    }),
    []
  );

  const onGridReady = useCallback((params) => {
    gridRef.current = params.api; // Set the gridRef for accessing API directly
  }, []);

  const onQuickFilterChange = useCallback(
    (event) => {
      const value = event.target.value;
      setSearchText(value);
      if (gridRef.current && gridRef.current.api) {
        gridRef.current.api.setFilterModel({
          securityName: {
            filterType: "text",
            type: "contains",
            filter: event.target.value,
          },
          weight: {
            filterType: "text",
            type: "contains",
            filter: event.target.value,
          },
        });
        gridRef.current.api.onFilterChanged();
      }

      // Generate filterColumns dynamically from rowData
      const filterColumns = columnDefs.map((col) => col.field).filter(Boolean);

      // Create a filter model to apply the search text to each column
      const filterModel = {};
      filterColumns.forEach((col) => {
        filterModel[col] = {
          filterType: "text",
          type: "contains",
          filter: value,
        };
      });

      // Apply the filter model to the grid
      if (gridRef.current) {
        gridRef.current.setFilterModel(filterModel);
      }
    },
    [columnDefs]
  );

  const clearFilters = useCallback(() => {
    if (gridRef.current) {
      gridRef.current.setFilterModel(null);
    }
  }, []);

  const date = new Date();
  const formattedDate = date.toISOString().slice(0, 10).replace(/-/g, "_");
  const filenamestem = filenames
    ? `${filenames}_${formattedDate}.csv`
    : `Download_${formattedDate}.csv`;

  const onBtnExport = useCallback(() => {
    if (gridRef.current) {
      gridRef.current.exportDataAsCsv({
        skipHeader: false,
        skipFooters: true,
        skipRowGroups: true,
        fileName: filenamestem,
        columnKeys,
      });
    }
  }, [filenamestem, columnKeys]);

  const onFirstDataRendered = useCallback(() => {
    if (gridRef.current) {
      gridRef.current.sizeColumnsToFit();
    }
  }, []);

  return (
    <>
      <Row className="my-3">
        {type !== "eqity" ? (
          <>
            <div className="col-md-4 col-12"></div>
            <div className="col-md-8 col-12 d-flex justify-content-end align-items-end">
              <div className="d-flex table-actions-container">
                <div className="table-sub-actions">
                  <div className="export">
                    <button className={StyleClass} onClick={onBtnExport}>
                      {type === "dashboard" ? (
                        <>
                          <FontAwesomeIcon icon={faFileExcel} /> Summary Excel
                        </>
                      ) : (
                        <FontAwesomeIcon icon={faDownload} />
                      )}
                    </button>
                  </div>
                  <div className="reset mx-3 me-2">
                    <button className={StyleClass} onClick={type === "dashboard" ? onBtnExport : clearFilters }>
                      {type === "dashboard" ? (
                        <>
                          <FontAwesomeIcon icon={faFilePdf} /> Summary
                        </>
                      ) : (
                        <FontAwesomeIcon icon={faRetweet} />
                      )}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </>
        ) : (
          <div
            style={{ position: "relative", width: "100%", maxWidth: "300px" }}
          >
            <FontAwesomeIcon
              icon={faSearch}
              style={{
                position: "absolute",
                top: "50%",
                left: "25px",
                transform: "translateY(-50%)",
                color: "#38479B",
                pointerEvents: "none",
              }}
            />
            {/* Inject the styles for the placeholder */}
            <style>
              {`
          input::placeholder {
            color: #38479B; /* Apply the desired color to the placeholder */
          }
        `}
            </style>
            <input
              type="text"
              placeholder="Enter text to search"
              value={searchText}
              onChange={onQuickFilterChange}
              style={{
                width: "195%",
                padding: "8px 12px 8px 36px", // Adjust padding to make space for the icon
                borderRadius: "20px",
                border: "1px solid #d4d4d4",
                outline: "none",
              }}
            />
          </div>
        )}
      </Row>

      <Row>
        <Col>
          <AgGridReact
            theme={myTheme}
            className="me-2 AgGridTable"
            columnDefs={columnDefs}
            rowData={rowData}
            paginationPageSize={10}
            domLayout="autoHeight"
            ref={gridRef}
            tooltipShowDelay={5}
            defaultColDef={defaultColDef}
            rowGroupPanelShow={"always"}
            pivotPanelShow={"always"}
            onGridReady={onGridReady}
            pagination={true}
            onFirstDataRendered={onFirstDataRendered}
            onSelectionChanged={onSelectionChanged}
          />
        </Col>
      </Row>
    </>
  );
}

AgTable.propTypes = {
  columnKeys: PropTypes.any,
  columnDefs: PropTypes.arrayOf(PropTypes.object).isRequired,
  rowData: PropTypes.arrayOf(PropTypes.object),
  filenames: PropTypes.string,
  onSelectionChanged: PropTypes.func,
  StyleClass: PropTypes.string,
  type: PropTypes.string,
};
