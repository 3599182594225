import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { getAccess, getLoginType } from "../../util";
import { decryptData, encrypt } from "../../util/CryptoJS";
import Loader from "../../util/Loader";

export default function DashboardLandingPage() {
  const navigate = useNavigate();
  useEffect(() => {
    if (decryptData(getAccess())) {
      if (decryptData(getLoginType()) === "client") {
        navigate(`/${encrypt("Dashboard")}`);
      } else {
        navigate(`/${encrypt("Rmdashboard")}`);
      }
    } else {
      navigate("/");
    }
  }, [getAccess()]);

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    }, 2000);
  }, []);
  return <>{loading ? <Loader pagename="Dashboard ..." /> : ""}</>;
}
