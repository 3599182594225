export const TenantMaster = [
  {
    tenantId: 1,
    tenantName: "axis",
    tenantCode: "axis",
    tenantHost: "localhost",
    tenantThemeCode: "LOCAL",
  },
  //   {
  //     tenantId: 1,
  //     tenantName: "default",
  //     tenantCode: "default",
  //     tenantHost: "localhost",
  //     tenantThemeCode: "LOCAL",
  //   },
  //   },
  {
    tenantId: 2,
    tenantName: "axis",
    tenantCode: "axis",
    tenantHost: "wealthdemo.finlabsindia.org",
    tenantThemeCode: "WEALTHDEMO",
  },
  {
    tenantId: 3,
    tenantName: "hdfc",
    tenantCode: "hdfc",
    tenantHost: "bank1.fiscus.in",
    tenantThemeCode: "BANK1",
  },
  // {
  //     tenantId: 3,
  //     tenantName: 'icici',
  //     tenantCode: 'icici',
  //     tenantHost: 'localhost'
  // },
  // {
  //     tenantId: 4,
  //     tenantName: 'icici',
  //     tenantCode: 'icici',
  //     tenantHost: 'wealthdemo'
  // },
  // {
  //     tenantId: 5,
  //     tenantName: 'sbi',
  //     tenantCode: 'sbi',
  //     tenantHost: 'localhost'
  // },
  // {
  //     tenantId: 6,
  //     tenantName: 'sbi',
  //     tenantCode: 'sbi',
  //     tenantHost: 'wealthdemo'
  // },
  // {
  //     tenantId: 7,
  //     tenantName: 'hdfc',
  //     tenantCode: 'hdfc',
  //     tenantHost: 'localhost'
  // },
  // {
  //     tenantId: 8,
  //     tenantName: 'hdfc',
  //     tenantCode: 'hdfc',
  //     tenantHost: 'wealthdemo'
  // }
];
