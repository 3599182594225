
import React from "react";
import { useParams } from 'react-router-dom';
import { DownloadTemplateFunction } from "../../util/downloadTemplate";
import Historycard from "./Historycard";
import FormComponent from "./UploadFormComponent";
import { TenantMasterData } from "./data";

export default function Transaction() {
 const handleAddSubmit = (data) => {
   // console.log("data", data);
 };
 const handledownloadTemplate = (data) => {
  DownloadTemplateFunction(useParams().mode)
};
 const handleFileUpload = (file) => {
   // console.log("file", file);
 };
 return (
   <>
     <FormComponent
       onSubmit={handleAddSubmit}
       onFileChangeNew={handleFileUpload}
       downloadTemplate={handledownloadTemplate}
     />
        <div className="row">
        <Historycard data={TenantMasterData}/>
       
      </div>
   </>
 );
}
