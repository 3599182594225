import React, { useEffect, useState } from "react";
import Header from "./Header";

// Event

import i18next from "i18next";
import AuthComponent from "../util/AuthComponent";
import AppRoutes from "./AppRoutes";
import IdleModalTimer from './IdleModalTimer';
import SidebarComponent from "./Sidebar";

export default function MainLayout() {
  useEffect(() => {
    i18next.changeLanguage("en");
  }, []);

  const [togglCollapsedValue, setTogglCollapsedValue] = useState(false);

  const handleToggle = () => {

    setTogglCollapsedValue((prevValue) => !prevValue);
  };
  const intervalId = setInterval(AuthComponent, 3000);

  // To stop the interval later
  clearInterval(intervalId);

  return (
    <div className={"app"}>
      <main className="content">
        <Header />
        <div className="contentpage">
          <SidebarComponent collapsedvalue={togglCollapsedValue} />
          <section className="contentpagediv">
            {" "}
            <AppRoutes
              onToggle={handleToggle}
              collapsedvalue={togglCollapsedValue}
            ></AppRoutes>
            <AuthComponent />
            <IdleModalTimer />
          </section>
        </div>
      </main>
    </div>
  );
}
