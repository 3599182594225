// Sidebar.js
import { faBuilding, faFileLines } from "@fortawesome/free-regular-svg-icons";
import {
  faFileArrowUp,
  faHouseChimney,
  faUsersGear,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { PropTypes } from "prop-types";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Menu, MenuItem, Sidebar, SubMenu } from "react-pro-sidebar";
import { Link } from "react-router-dom";
import { encrypt, encryptData } from "../util/CryptoJS";
import { decryptData } from "./../util/CryptoJS";
import { getLoginType } from "./../util/index";
import "./sidebar.scss";

const SidebarComponent = ({ collapsedvalue }) => {
  const { t } = useTranslation(["Common", "Messages", "Form"]);
  const [currentUrl, setCurrentUrl] = useState("");
  const [suburrentUrl, setSubCurrentUrl] = useState("");
  const [urlDomain, setUrlDomain] = useState("");
  useEffect(() => {
    fetchUrl();
    // fetchScheduleList()
  }, []);

  const [activeItemId, setActiveItemId] = useState(null); // Default active item ID
  const isExpanded = true;

  const handleMenuItemClick = (id) => {
    setActiveItemId();
    setCurrentUrl();
    setActiveItemId(id);

    setTimeout(() => {
      fetchUrl();
    }, 500);
  };

  //Fetch url
  const fetchUrl = () => {
    let urlElements = window.location.href.split("/");
    let urlElelement = urlElements[3];
    let urlElelement2 = urlElements[4];
    let urlElelement1 = urlElements[2];
    setUrlDomain(`${urlElelement1}`);
    setCurrentUrl(`${urlElelement}`);
    setSubCurrentUrl(decryptData(urlElelement2));
  };

  return (
    <div className={collapsedvalue ? "no_blur" : "blur_bg"}>
      <Sidebar
        // backgroundColor="#eff8ff"
        height="100%"
        width="230px"
        collapsedWidth="6rem"
        color="$app_w_color"
        style={{
          height: "100%",
          top: "auto",
          border: 0,
          position: "sticky",
          padding: "0rem",
          margin: "0rem",
          zIndex: 9
        }}
        collapsed={true}
        transitionDuration={800}
      >
        <Menu
          menuItemStyles={{
            button: ({ level, active }) => {
              if (level === 0 || level === 1) {
                return {
                  backgroundColor: active ? "#889BFF" : "",
                  color: "white",
                  borderRadius: active ? "10px" : null,

                  "&:hover": {
                    backgroundColor: "#889BFF",
                    color: "white",
                  },
                };
              }
            },
          }}
          collapsed={collapsedvalue}
          transitionDuration={800}
        >
          {decryptData(getLoginType()) === "cl" ? (
            <MenuItem
              active={
                activeItemId === "Dashboard" ||
                currentUrl === encrypt("Dashboard")
              }
              icon={<FontAwesomeIcon icon={faHouseChimney} size="2xl" />}
              onClick={() => handleMenuItemClick("Dashboard")}
              component={<Link to={"/" + encrypt("Dashboard")} />}
              className="dflexMenu"
              title="Dashboard"
            ></MenuItem>
          ) : (
            <MenuItem
              active={
                activeItemId === "Rmdashboard" ||
                currentUrl === encrypt("Rmdashboard")
              }
              icon={<FontAwesomeIcon icon={faHouseChimney} size="2xl" />}
              onClick={() => handleMenuItemClick("Rmdashboard")}
              component={<Link to={"/" + encrypt("Rmdashboard")} />}
              className="dflexMenu"
              title="RM Dashboard"
            ></MenuItem>
          )}

          <SubMenu
            icon={
              <FontAwesomeIcon
                icon={faFileLines}
                size="2xl"
                defaultCollapsed={true}
                collapsed={!isExpanded}
              />
            }
            title="Reports"
            active={
              activeItemId === "CorporateReports" ||
              activeItemId === "EnitityReport" ||
              currentUrl === encrypt("CorporateReports") ||
              currentUrl === encrypt("EnitityReport")
            }
          >
            <MenuItem
              active={
                activeItemId === "CorporateReports" ||
                currentUrl === encrypt("CorporateReports")
              }
              icon={<FontAwesomeIcon icon={faUsersGear} />}
              onClick={() => handleMenuItemClick("CorporateReports")}
              component={<Link to={"/" + encrypt("CorporateReports")} />}
              className="SubMenudflexMenu"
            >
              {t("Common:App_lms_Common_00247")}
            </MenuItem>

            <MenuItem
              active={
                activeItemId === "EnitityReport" ||
                currentUrl === encrypt("EnitityReport")
              }
              icon={<FontAwesomeIcon icon={faBuilding} />}
              onClick={() => handleMenuItemClick("EnitityReport")}
              component={<Link to={"/" + encrypt("EnitityReport")} />}
              className="SubMenudflexMenu"
            >
              {t("Common:App_lms_Common_00248")}
            </MenuItem>
          </SubMenu>

          <SubMenu
            className="MenuItem"
            icon={
              <FontAwesomeIcon
                icon={faFileArrowUp}
                size="2xl"
                defaultCollapsed={true}
                collapsed={!isExpanded}
              />
            }
            active={
              activeItemId === "UploadsComponent" ||
              currentUrl === encrypt("UploadsComponent")
            }
            title="Uploads"
          >
            <MenuItem
              active={
                activeItemId === "UploadsComponentHolding" ||
                suburrentUrl === "Holding"
              }
              icon={<FontAwesomeIcon icon={faFileArrowUp} />}
              onClick={() => handleMenuItemClick("UploadsComponentHolding")}
              component={
                <Link
                  to={
                    "/" +
                    encrypt("UploadsComponent") +
                    `/${encryptData("Holding")}`
                  }
                />
              }
              className="SubMenudflexMenu"
              title="Holding"
            >
              Holding
            </MenuItem>
            <MenuItem
              active={
                activeItemId === "UploadsComponentHoldingHoldingUnderlying" ||
                suburrentUrl === "HoldingUnderlying"
              }
              icon={<FontAwesomeIcon icon={faFileArrowUp} />}
              onClick={() =>
                handleMenuItemClick("UploadsComponentHoldingUnderlying")
              }
              component={
                <Link
                  to={
                    "/" +
                    encrypt("UploadsComponent") +
                    `/${encryptData("HoldingUnderlying")}`
                  }
                />
              }
              className="SubMenudflexMenu"
              title="Holding Underlying"
            >
              Holding Underlying
            </MenuItem>
            <MenuItem
              active={
                activeItemId === "UploadsComponentSectorClassification" ||
                suburrentUrl === "SectorClassification"
              }
              icon={<FontAwesomeIcon icon={faFileArrowUp} />}
              onClick={() =>
                handleMenuItemClick("UploadsComponentSectorClassification")
              }
              component={
                <Link
                  to={
                    "/" +
                    encrypt("UploadsComponent") +
                    `/${encryptData("SectorClassification")}`
                  }
                />
              }
              className="SubMenudflexMenu"
              title="Sector Class ification"
            >
              Sector Classi fication
            </MenuItem>
            <MenuItem
              active={
                activeItemId === "UploadsComponentTransaction" ||
                suburrentUrl === "Transaction"
              }
              icon={<FontAwesomeIcon icon={faFileArrowUp} />}
              onClick={() => handleMenuItemClick("UploadsComponentTransaction")}
              component={
                <Link
                  to={
                    "/" +
                    encrypt("UploadsComponent") +
                    `/${encryptData("Transaction")}`
                  }
                />
              }
              className="SubMenudflexMenu"
              title="Transaction"
            >
              Transaction
            </MenuItem>
            <MenuItem
              active={
                activeItemId === "UploadsComponentInstrumentSectorMapping" ||
                suburrentUrl === "InstrumentSectorMapping"
              }
              icon={<FontAwesomeIcon icon={faFileArrowUp} />}
              onClick={() =>
                handleMenuItemClick("UploadsComponentInstrumentSectorMapping")
              }
              component={
                <Link
                  to={
                    "/" +
                    encrypt("UploadsComponent") +
                    `/${encryptData("InstrumentSectorMapping")}`
                  }
                />
              }
              className="SubMenudflexMenu"
              title="Instrument Sector Mapping"
            >
              Instrument Sector Mapping
            </MenuItem>
            <MenuItem
              active={
                activeItemId === "UploadsComponentIssuers" ||
                suburrentUrl === "Issuers"
              }
              icon={<FontAwesomeIcon icon={faFileArrowUp} />}
              onClick={() => handleMenuItemClick("UploadsComponentIssuers")}
              component={
                <Link
                  to={
                    "/" +
                    encrypt("UploadsComponent") +
                    `/${encryptData("Issuers")}`
                  }
                />
              }
              className="SubMenudflexMenu"
              title="Issuers"
            >
              Issuers
            </MenuItem>
          </SubMenu>
        </Menu>
      </Sidebar>
    </div>
  );
};

export default SidebarComponent;
SidebarComponent.propTypes = {
  collapsedvalue: PropTypes.any,
};
