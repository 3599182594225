import React, { Suspense } from "react";
import { Route, Routes } from "react-router-dom";
import WatchListModal from "../Component/Modal/WatchListModel";
import DashboardLandingPage from "../page/Dashboard/DashboardLandingPage";
import Rmdashboard from "../page/RmDashboard/Rmdashboard";
import UploadsComponent from "../page/Uploads/UploadsComponent";
import { encrypt } from "../util/CryptoJS";
import CorporateReports from "./../page/CorporateReports/CorporateReports";
import EnitityReport from "./../page/EnitityReport/EnitityReport";

const Dashboard = React.lazy(() => import("../page/Dashboard/dashboard"));

// Import the NotFound component
const NotFound = React.lazy(() => import("../page/PageNotFound"));

export default function AppRoutes() {
  return (
    <Suspense fallback={null}>
      
      <Routes>
        <Route path="/" element={<DashboardLandingPage />} />
        <Route
          path={"/" + encrypt("DashboardLandingPage")}
          element={<DashboardLandingPage />}
        />
        <Route path={"/" + encrypt("Dashboard")} element={<Dashboard />} />
        <Route path={"/" + encrypt("Rmdashboard")} element={<Rmdashboard />} />
        {/* <Route path={"/" + encrypt("ReportsList")} element={<ReportsList />} /> */}

        {/* Report */}
        <Route
          path={"/" + encrypt("EnitityReport")}
          element={<EnitityReport />}
        />
        <Route
          path={"/" + encrypt("CorporateReports")}
          element={<CorporateReports />}
        />

        <Route path={"/" + encrypt("UploadsComponent")+ "/:mode"} element={<UploadsComponent />} />
        <Route
          path={"/" + encrypt("WatchListModel")}
          element={<WatchListModal />}
        />

        {/* <Route
          path={"/" + encrypt("Interventionlist")}
          element={<Interventionlist />}
        />
        <Route
          path={"/" + encrypt("InterventionFormComponent") + "/:mode/:id"}
          element={<InterventionFormComponent />}
        />
       
        <Route path={"/" + encrypt("Schoollist")+ "/:mode"} element={<Schoollist />} />
        <Route
          path={"/" + encrypt("SchoolFormComponent") + "/:mode/:id"}
          element={<SchoolFormComponent />}
        />
  */}

        <Route
          path="*"
          element={
            <NotFound
              status={404}
              message="Oops! Looks like you're lost in space."
              btnText="Return to Home"
            />
          }
        />
      </Routes>
    </Suspense>
  );
}
