import { ArcElement, Chart as ChartJS, Legend, Title, Tooltip } from "chart.js";
import React from "react";
import { Doughnut, Line, Pie } from "react-chartjs-2";

ChartJS.register(ArcElement, Tooltip, Legend, Title);

const ApppieChart = ({
  type,
  legendposition,
  legenddisplay,
  Chartdata,
  Chartlabels,
}) => {
  const data = {
    labels: Chartlabels,
    datasets: Chartdata,
  };

  const options = {
    responsive: true,
    // Boolean - whether or not the chart should be responsive and resize when the browser does.

    responsive: true,

    // Boolean - whether to maintain the starting aspect ratio or not when responsive, if set to false, will take up entire container

    maintainAspectRatio: false,
    plugins: {
      legend: {
        position: legendposition,
        display: legenddisplay,
        labels: {
          color: "rgb(255, 99, 132)",
        },
      },

      tooltip: {
        callbacks: {
          label: (tooltipItem) => {
            const label = tooltipItem.label || "";
            const value = tooltipItem.raw || 0;
            return `${label}: ${value}`;
          },
        },
      },
    },
  };

  const PieChart = () => <Pie data={data} options={options} />;

  const DoughnutChart = () => <Doughnut data={data} options={options} />;

  const LineChart = () => <Line data={data} options={options} />;
  return (
    <>
      {type === "PieChart" && <PieChart />}
      {type === "DoughnutChart" && <DoughnutChart />}
      {type === "LineChart" && <LineChart />}
    </>
  );
};

export default ApppieChart;
