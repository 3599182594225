import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import { encrypt } from "../../util/CryptoJS";
import { Apiurl } from "../../util/apiurl";
import axiosInstance from "../../util/axiosInstance";
import { login, setLocalStorage } from "./../../util/index";
import FormComponent from "./FormComponent";

const LoginFormComponents = () => {

  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation(["Common", "Messages", "Form"]);

  // Simulate loading state (remove this if loading is based on an actual request)
  useEffect(() => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    }, 2000);
  }, []);

  // Handle form submission
  const handleAddSubmit = (data) => {
    const payload = {
      username: data.username, 
      password: data.password,
    };
    const logtype = data.LoginAs === undefined || "undefined" ? "client" : data.LoginAs.value;

    submitData(payload, logtype);
  };
  
  // Submit login data to the API
  const submitData = async (payload, logtype) => {
    try {
      const response = await axiosInstance.post(Apiurl.api_login, payload);
      const tokenData = response.data;

      if (tokenData) {
  
        // Perform login with the received token
        login(tokenData, "true", logtype);
        // Navigate to the dashboard page
        navigate(`/${encrypt("DashboardLandingPage")}`);
        setLocalStorage("counter", 1)
        toast.success(t("loginSuccess"));
        window.location.reload(true);
      } else {
        toast.error(t("loginFailed 1"));
      }
    } catch (error) {
      console.error("Login error: ", error);
      toast.error(t("login Failed"));
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
  
        <div className="apph100 d-flex align-content-center container-fluid ">
          <FormComponent onSubmit={handleAddSubmit} />
        </div>
 
    </>
  );
};

export default LoginFormComponents;
