import {
  faChevronLeft,
  faCircleChevronDown,
  faCircleInfo,
  faHeart,
  faMagnifyingGlass,
  faUser,
  faXmark,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import AppbarChart from "../ComponentsChart/AppbarChart";
import ApppieChart from "../ComponentsChart/ApppieChart";
import LineChart from "../ComponentsChart/LineChart";
import SemiDoughnutChart from "../ComponentsChart/SemiDoughnutChart";
import DataTable from "../ComponentsChart/dataTable";
import "./WatchListModal.scss";

const StockAnalysisPage = ({ stock, onClose }) => {
  // Transactions data
  const transactionsData = [
    { label: "Net", data: [0, 59, 100, 50], backgroundColor: "#5FB6FA" },
    { label: "Purchase", data: [0, 59, 100, 50], backgroundColor: "#5FF6FA" },
    { label: "Redemption", data: [0, 59, 0, 50], backgroundColor: "#6076FA" },
  ];
  const transactionLabels = ["Commodation", "Debt", "Equity", "Hybrid"];

  // Market data
  const marketData = [
    {
      label: "AUM",
      data: [12, 19, 3, 50],
      backgroundColor: ["#5FB6FA", "#FA60A9", "#CA60FA", "#8A60FA"],
    },
  ];
  const marketLabels = ["Commodities", "Hybrid", "Equity", "Debt"];

  const aumData = ["20.99", "10.99", "10.0"];
  const aumLabels = ["Net AUM", "Hold AUM", "Held Away AUM"];
  const aumBackgroundColor = ["#8B5CF6", "#FCD34D", "#F87171"];

  // Data Table
  const columns = ["Holdings", "% of total", "Sector"];
  const data = [
    ["HDFC Bank Ltd.", "6.75", "Bank"],
    ["SBI", "6.75", "Bank"],
    ["ICICI Bank Ltd.", "6.75", "Bank"],
    ["Axis Bank Ltd.", "6.75", "Bank"],
    ["HDFC Bank Ltd.", "6.75", "Bank"],
    ["Axis Bank Ltd.", "6.75", "Bank"],
  ];

  const handleTimeframeChange = (timeframe) => {};

  return (
    <div className="stock-analysis-page">
      <div class="button-container">
        <button class="start-button btn" onClick={onClose}>
          <FontAwesomeIcon icon={faChevronLeft} />
        </button>
        <button class="end-button btn" onClick={onClose}>
          <FontAwesomeIcon icon={faXmark} />
        </button>
      </div>
      <div className="search-bar-stock">
        <FontAwesomeIcon icon={faMagnifyingGlass} />
        <input
          type="text"
          placeholder={`Search for more ${stock.type}`}
          //   value={searchTerm}
          //   onChange={handleSearchChange}
          className="search-input"
        />
        <button className="filter-button">
          Your Watchlist
          <FontAwesomeIcon icon={faHeart} />
        </button>
      </div>
      <div className="content">
        <div className="stock-header p-3">
          <h2>{stock.name}</h2>
          <p>{stock.details}</p>
          <p>Direct · Growth · Equity · Sectoral/Thematic</p>
        </div>
        <div className="row">
          <div className="stock-info col-4 col-md-4 col-lg-4">
            <div className="d-flex gap-3 mt-3">
              <div className="p-3 rounded text-wrap tile flex-fill">
                <h3>Current NAV</h3>
                <p style={{ color: "green" }}>Rs. 11.813</p>
                <p className="badge-success"> +1.12%</p>
              </div>
              <div className="p-3 rounded tile">
                <div className="d-flex gap-5">
                  <h3 className="">CAGR</h3>
                  <p>
                    1 year <FontAwesomeIcon icon={faCircleChevronDown} />
                  </p>
                </div>
                <p style={{ color: "red", padding: "0.5rem" }}>0%</p>
              </div>
            </div>
            <div className="p-3 d-flex gap-5 rounded tile mt-3 flex-fill">
              <div className="">
                <h3>Min. Investment</h3>
                <p>Rs. 100</p>
              </div>
              <div className="">
                <h3>AUM</h3>
                <p>Rs 10.403 Cr</p>
              </div>
            </div>
            <div className="p-3 d-flex gap-5 rounded tile mt-3">
              <div className="">
                <h3>
                  Exit Load <FontAwesomeIcon icon={faCircleInfo} />
                </h3>
                <p>1%</p>
              </div>
              <div className="me-2">
                <h3>
                  Expense Ratio <FontAwesomeIcon icon={faCircleInfo} />
                </h3>
                <p>0.83%</p>
              </div>
            </div>
            <div className="mt-3 add_watchlist">
              <button className="add_watchlist_button w-100">
                <FontAwesomeIcon icon={faHeart} /> Add to Watchlist
              </button>
            </div>
            <div className="d-flex gap-5 w-100 mt-3 buy_list ">
              <button className="btn buy_button flex-fill">Buy</button>
              <button className="btn SIP_button flex-fill">SIP</button>
            </div>
          </div>
          <div className="chart col-8 col-md-8 col-lg-8">
            <LineChart
              labels={[
                "Jan",
                "Feb",
                "Mar",
                "Apr",
                "May",
                "Jun",
                "Jul",
                "Aug",
                "Sep",
                "Oct",
                "Nov",
                "Dec",
              ]}
              dataPoints={[
                50, 750, 475, 575, 250, 300, 40, 300, 200, 700, 150, 350, 400,
                250, 500, 350,
              ]}
              maxY={1000}
              stepSize={50}
              lineColor="#38479B"
              fillColor="rgba(60, 71, 160, 0.2)"
              buttonLabels={["1Y", "2Y", "3Y"]}
              onTimeframeChange={handleTimeframeChange}
            />
          </div>
        </div>
        <hr></hr>

        <div className=" row mb-3">
          <h3>Fund Manager</h3>
          <p>
            <FontAwesomeIcon icon={faUser} /> Mr. Shreyash Devalkar
          </p>
        </div>

        <div className="row">
          <div className="col-md-6 col-12">
            <div className="card dashbordchartcard dashbordchartcard_modal">
              <div className="card-body dashbordchartTitle">
                <p className="dashbordchartname">Risk Measure</p>
                <div className="dashbordchart">
                  <SemiDoughnutChart
                    ChartData={aumData}
                    ChartDatalabels={aumLabels}
                    ChartDatabackgroundColor={aumBackgroundColor}
                    cutoutPercentage={"30%"}
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="col-md-6 col-12">
            <div className="card dashbordchartcard dashbordchartcard_modal">
              <div className="card-body dashbordchartTitle">
                <p className="dashbordchartname">Market Capitalisation</p>
                <div className="dashbordchart">
                  <ApppieChart
                    type="DoughnutChart"
                    legendposition="bottom"
                    legenddisplay={true}
                    Chartdata={marketData}
                    Chartlabels={marketLabels}
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="col-md-6 col-12">
            <div className="card dashbordchartcard dashbordchartcard_modal">
              <div className="card-body dashbordchartTitle">
                <p className="dashbordchartname">Asset Allocation</p>
                <div className="dashbordchart">
                  <ApppieChart
                    type="PieChart"
                    legendposition="bottom"
                    legenddisplay={true}
                    Chartdata={marketData}
                    Chartlabels={marketLabels}
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="col-md-6 col-12">
            <div className="card dashbordchartcard dashbordchartcard_modal">
              <div className="card-body dashbordchartTitle">
                <p className="dashbordchartname">Top 10 Sector Holdings</p>
                <div className="dashbordchart">
                  <AppbarChart
                    legendposition="bottom"
                    legenddisplay={true}
                    Chartdata={transactionsData}
                    Chartlabels={transactionLabels}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row mt-3 mb-3">
          <DataTable
            title="Share Holder Details"
            columns={columns}
            data={data}
          />
        </div>
      </div>
    </div>
  );
};

export default StockAnalysisPage;
