import {
  faMagnifyingGlass,
  faSliders
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState } from "react";
import StockAnalysisPage from '../Modal/StockAnalysisPage';
import "./WatchListModal.scss";

const watchlist = [
  { name: "Zerodha Nifty LargeMidcap 250 Index Fund", type: "mutual_fund" },
  { name: "Reliance Industries", type: "stocks" },
  { name: "HDFC Mutual Fund", type: "mutual_fund" },
  { name: "Tata Motors", type: "stocks" },
  { name: "Infosys Ltd", type: "stocks" },
  { name: "Axis Bluechip Fund", type: "mutual_fund" },
  { name: "Asian Paints Ltd", type: "stocks" },
  { name: "ICICI Prudential Bluechip Fund", type: "mutual_fund" },
  { name: "HDFC Bank Ltd", type: "stocks" },
  { name: "Mirae Asset Large Cap Fund", type: "mutual_fund" },
];

const WatchListPage = () => {
  const [searchTerm, setSearchTerm] = useState("");
  const [watchdata, setWatchData] = useState(["Watchlist 1"]);
  const [selectedStock, setSelectedStock] = useState(null);

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
  };

  const handleClick = () => {
    setWatchData((prevData) => [...prevData, `Watchlist ${prevData.length + 1}`]);
  };

  const handletabs = (tab) => {

  };

  const handleClickStock = (stock) => {
    setSelectedStock(stock);
  };

  const filteredStocks = watchlist.filter(
    (stock) =>
      stock.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
      stock.type.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return selectedStock ? (
    <StockAnalysisPage
      stock={selectedStock}
      onClose={() => setSelectedStock(null)}
    />
  ) : (
    <div className="watchlist-page">
      <div className="header">
        <div className="tabs">
          {watchdata.map((item) => (
            <button
              key={item}
              className="tab-button"
              onClick={() => handletabs(item)}
            >
              {item}
            </button>
          ))}
          <button 
          className={ watchdata.length === 5 ? "disabled-button" : "add-button" }
          onClick={handleClick}
          disabled={watchdata.length === 5}
          >
           
            Add More
          </button>
        </div>
      </div>

      <div className="search-bar">
        <FontAwesomeIcon icon={faMagnifyingGlass} />
        <input
          type="text"
          placeholder="Enter text to search"
          value={searchTerm}
          onChange={handleSearchChange}
          className="search-input"
        />
        <button className="filter-button">
          <FontAwesomeIcon icon={faSliders} />
        </button>
      </div>

      <div className="stock-list">
        {filteredStocks.map((stock, index) => (
          <div
            key={index}
            className="stock-item"
            onClick={() => handleClickStock({ ...stock, index })}
          >
            <span className="stock-name">{stock.name}</span>
            <span
              className={`stock-badge ${
                stock.type === "mutual_fund" ? "badge-info" : "badge-success"
              }`}
            >
              {stock.type === "mutual_fund" ? "Mutual Fund" : "Stocks"}
            </span>
          </div>
        ))}
      </div>
    </div>
  );
};


export default WatchListPage;
