import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import "../../App.scss";
import Pagehader from "../../Layout/Pagehader";
import Loader from "../../util/Loader";
import { TenantMasterData } from "../Uploads/data";
import CorporateReportscard from "./CorporateReportscard";
export default function CorporateReports() {
  const [loading, setLoading] = useState(false);

  const { t } = useTranslation(["Common", "Messages", "Form"]);
  useEffect(() => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    }, 2000);
  }, []);

  const breadcrumbItems = [
    {
      label: t("Common:App_lms_Common_00119"),
      //   href: "/",
      //   icon: <FontAwesomeIcon icon={faFileLines} />,
    },
    {
      label: t("Common:App_lms_Common_00247"),
      //   icon: <FontAwesomeIcon icon={faList} />,
    },
  ];

  return (
    <>
      {loading ? (
        <Loader pagename="Dashboard " />
      ) : (
        <>
          <Pagehader
            pagename="Dashboard"
            Breadcrumbshow={true}
            breadcrumbItems={breadcrumbItems}
          ></Pagehader>

          <div className="pagebody ">
            <div className="row justify-content-center Reportspagebody">
              <div className=" col-12">hi</div>
            </div>
            <div className="row">
              <CorporateReportscard data={TenantMasterData} />
            </div>
          </div>
        </>
      )}
    </>
  );
}
