import React from "react";
import PropTypes from "prop-types";

export default function ButtonComp({wrapperName,type,onClick,disabled,btnText,btnStyle,btnIcon}) {

    const buttonContent = () => {
        return (
            btnStyle === 'round' ?
                <div className="icon t_btn_icon">
                    <span className={`${btnStyle}_button_text tooltip`} >{btnText}</span>
                    {btnIcon}
                </div>
                :
                <div className={`${btnStyle}_style`}>
                    {btnIcon && <span className={`${btnStyle}_icon`}>{btnIcon}</span>}
                    {btnText && <span className={`${btnStyle}_btn_text`}>{btnText}</span>}
                </div>
        )
    }

    return (
        <div className={wrapperName}>
            <button
                type={type}
                className={`${btnStyle}_btn ${disabled ? "btn_disabled" : ""}`}
                onClick={disabled ? null : onClick}
            >
                {buttonContent()}
            </button>
        </div>
    );
};

ButtonComp.propTypes = {
    wrapperName: PropTypes.string,
    type: PropTypes.string,
    onClick: PropTypes.func,
    disabled: PropTypes.bool,
    btnText: PropTypes.string,
    btnStyle: PropTypes.string,
    btnIcon: PropTypes.element,
};

ButtonComp.defaultProps = {
    wrapperName: "btn_wrapper",
    type: "button",
    onClick: () => { },
    disabled: false,
    btnText: "Click Me",
    btnStyle: "box",
    btnIcon: null,
};


