import PropTypes from "prop-types";
import React, { useState } from "react";
import Clock from "react-live-clock";
import { useNavigate } from "react-router-dom";
import Breadcrumb from "./../Component/BreadcrumbComponent/BreadcrumbComponent";
import closebar from "./../assets/img/barclose.svg";
import opnebar from "./../assets/img/baropne.svg";
import { getUserId } from "./../util/index";
export default function Pagehader({ Breadcrumbshow, breadcrumbItems, btnnav }) {
  const navigate = useNavigate();
  const handlenavigate = () => {
    navigate(btnnav);
  };
  const [hasNewClass, setHasNewClass] = useState(false);
  const updateClassList = (x) => {
    // Find the element with the class 'blur_bg'
    const element = document.querySelector(".blur_bg");
    element.classList.add("blur_bg");
    setHasNewClass(!hasNewClass);
    // Add the 'blur_bg' class (if it's not already present)
    element.classList.remove("new_class");
    element.classList.remove("d-none");

    element.classList.add(hasNewClass ? "new_class" : "d-none");
  };

  return (
    <div className="pageheader">
      <div className="d-flex justify-content-between align-items-center page-main-heading-section">
        <div className="d-flex align-items-center">
          <button
            type="button"
            className="btn"
            onClick={() => updateClassList()}
          >
            {hasNewClass ? <img src={closebar} /> : <img src={opnebar} />}{" "}
          </button>{" "}
          <div>
            <p className="getuser">hi , {getUserId()}</p> 
            {Breadcrumbshow ? <Breadcrumb items={breadcrumbItems} /> : ""}
          </div>
        </div>
        <div>
          <span>
            {" "}
            <Clock
              date={new Date()}
              format={"dddd, Mo MMM YYYY, h:mm:ss A"}
              ticking={true}
              className="Clock"
              timezone={"Asia/Kolkata"}
            />
            , GMT+0530
          </span>
          <div className="d-flex">
            <div className="BSEBox">
              <span className="name">BSE SENSEX</span>
              <span className="value">85498.60</span>
              <span className="per">30%</span>
            </div>
            <div className="BSEBox">
              <span className="name">BSE NIFTY</span>
              <span className="value">85498.60</span>
              <span className="per">30%</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

Pagehader.propTypes = {
  Breadcrumbshow: PropTypes.bool.isRequired,
  pagename: PropTypes.string.isRequired,
  btnshow: PropTypes.bool.isRequired,
  btnUploadnav: PropTypes.any,
  btnUploadName: PropTypes.any,
  btnUpload: PropTypes.bool.isRequired,
  BtnName: PropTypes.string,
  btnnav: PropTypes.any,
  breadcrumbItems: PropTypes.arrayOf,
};
