export const Thememaste = {
  axis: {
    "--primary-color": "124, 2, 0",
    "--default_text_color": "0,0,0",
    "--buton_primary_background_color": "124, 2, 0",
    "--buton_primary_text_color": "255,255,255",
    "--button_primary_icon_color": "0,0,0",
    "--success-color": "20,108,67",
    "--danger-color": "255,0,0",
    "--warning-color": "255,193,7",
    "--info-color": "13,202,240",
    "--muted-color": "108,117,125",
    "--form-label-color": "124, 2, 0",
    "--form-error-msg-color": "255,0,0",
    "--form-input-border-color": "124, 2, 0",
    "--form-read-only-text-color": "128,128,128",
    "--breadcrumb_color": "128,128,128",
    "--W_color": "255, 255, 255",
    "--chart_equity_color": "202, 96, 250",
    "--chart_debt_color": "250, 96, 169",
    "--chart_hybrid_color": "138, 96, 250",
    "--chart_commodities_color": "95, 182, 250",
    "--app_body_bg_color": "56, 71, 155",
  
  "--buton_secondary_background_color": "124, 2, 0",
  "--buton_secondary_text_color":" 56, 71, 155",
  "--button_secondary_icon_color":" 0, 0, 0",
  // Header
  "--header_background_color": "255, 255, 255",
  "--header_text_color":" 56, 71, 155",
  "--header_icon_color":" 56, 71, 155",
  // Sidebar
  "--sidebar_background_clolor":" 124, 2, 0",
  "--sidebar_text_clolor": "255, 255, 255",
  "--sidebar_icon_clolor":" 56, 71, 155",
  },
  hdfc: {
    "--primary-color": "35,64,104",
    "--default_text_color": "0,0,0",
    "--buton_primary_background_color": "30,132,255",
    "--buton_primary_text_color": "255,255,255",
    "--button_primary_icon_color": "0,0,0",
    "--success-color": "20,108,67",
    "--danger-color": "255,0,0",
    "--warning-color": "255,193,7",
    "--info-color": "13,202,240",
    "--muted-color": "108,117,125",
    "--form-label-color": "0,0,0",
    "--form-error-msg-color": "255,0,0",
    "--form-input-border-color": "0,0,0",
    "--form-read-only-text-color": "128,128,128",
    "--breadcrumb_color": "128,128,128",
    "--W_color": "255, 255, 255",
   
    "--app_body_bg_color": "35,64,104",
    "--buton_secondary_background_color": "124, 2, 0",
    "--buton_secondary_text_color":" 56, 71, 155",
    "--button_secondary_icon_color":" 0, 0, 0",
    // Header
    "--header_background_color": "255, 255, 255",
    "--header_text_color":" 56, 71, 155",
    "--header_icon_color":" 56, 71, 155",
    // Sidebar
    "--sidebar_background_clolor":"35,64,104",
    "--sidebar_text_clolor": "255, 255, 255",
    "--sidebar_icon_clolor":" 255,255,255",
  },
  default: {
    "--primary-color": "506, 71, 155",
    "--default_text_color": "0,0,0",
    "--buton_primary_background_color": "56, 71, 155",
    "--buton_primary_text_color": "255,255,255",
    "--button_primary_icon_color": "0,0,0",
    "--success-color": "20,108,67",
    "--danger-color": "255,0,0",
    "--warning-color": "255,193,7",
    "--info-color": "13,202,240",
    "--muted-color": "108,117,125",
    "--form-label-color": "0,0,0",
    "--form-error-msg-color": "255,0,0",
    "--form-input-border-color": "0,0,0",
    "--form-read-only-text-color": "128,128,128",
    "--breadcrumb_color": "128,128,128",
    "--W_color": "255, 255, 255",
    "--chart_equity_color": "202, 96, 250",
    "--chart_debt_color": "250, 96, 169",
    "--chart_hybrid_color": "138, 96, 250",
    "--chart_commodities_color": "95, 182, 250",
    "--app_body_bg_color": "56, 71, 155",
     // Buttons
 
 "--buton_secondary_background_color": "255, 255, 255",
  "--buton_secondary_text_color": "56, 71, 155",
  "--button_secondary_icon_color": "0, 0, 0",
  // Header
  "--header_background_color": "255, 255, 255",
  "--header_text_color": "56, 71, 155",
  "--header_icon_color": "56, 71, 155",
  // Sidebar
  "--sidebar_background_clolor": "96, 118, 250",
  "--sidebar_text_clolor": "255, 255, 255",
  "--sidebar_icon_clolor": "56, 71, 155",
  },
};



