import { faAngleDown, faAngleUp } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import "../../App.scss";
import ButtonComp from "../../Component/ButtonComp/ButtonComp";
import Pagehader from "../../Layout/Pagehader";
import Loader from "../../util/Loader";
import { EntityReportsMaster } from "./EntityReportMaster";

export default function EnitityReport() {
  // EntityMaster JSON
  const EntityMaster = EntityReportsMaster;

  const [reportBy, setReportBy] = useState("Family");
  const [activeAccordion, setActiveAccordion] = useState(null); // Track open accordion
  const [radioSelections, setRadioSelections] = useState({}); // Store radio button selections for each accordion
  const { t } = useTranslation(["Common", "Messages", "Form"]);

  const handleRadioChange = (value) => {
    setReportBy(value);
    setActiveAccordion(null);
    setRadioSelections({});
  };

  const toggleAccordion = (key) => {
    setActiveAccordion(activeAccordion === key ? null : key); // Toggle accordion
  };

  const handleRadioSelection = (key, value) => {
    setRadioSelections({
      ...radioSelections,
      [key]: value,
    });
  };

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    }, 2000);
  }, []);

  const breadcrumbItems = [
    {
      label: t("Common:App_lms_Common_00119"),
    },
    {
      label: t("Common:App_lms_Common_00248"),
    },
  ];
  const handleExport = () => {
    alert("Export");
  };
  const handleView = () => {
    alert("View");
  };
  return (
    <>
      {loading ? (
        <Loader pagename="Dashboard " />
      ) : (
        <>
          <Pagehader
            pagename="Dashboard"
            Breadcrumbshow={true}
            breadcrumbItems={breadcrumbItems}
          />
          <div>
            <div className="pagebody Reportspagebody">
              <div style={styles.container}>
                <h3>Reports by</h3>
                <div style={styles.radioGroup}>
                  {Object.keys(EntityMaster).map((type) => (
                    <label key={type} style={styles.radioLabel}>
                      <span> </span>
                      <input
                        className="me-1"
                        type="radio"
                        value={type}
                        checked={reportBy === type}
                        onChange={() => handleRadioChange(type)}
                      />
                      {type}
                    </label>
                  ))}
                </div>

                {EntityMaster[reportBy].option.map((item, index) => (
                  <div key={index} style={styles.accordionContainer}>
                    <div
                      onClick={() => toggleAccordion(item.value)}
                      style={styles.accordionHeader}
                    >
                      {item.labelName}
                      <span>
                        {activeAccordion === item.value ? (
                          <FontAwesomeIcon icon={faAngleUp} />
                        ) : (
                          <FontAwesomeIcon icon={faAngleDown} />
                        )}
                      </span>
                    </div>
                    {activeAccordion === item.value && (
                      <div style={styles.accordionContent}>
                        {EntityMaster[reportBy].radioOptions.map((option) => (
                          <label key={option} style={styles.radioLabel}>
                            <input
                              className="me-1"
                              type="radio"
                              value={option}
                              checked={radioSelections[item.value] === option}
                              onChange={() =>
                                handleRadioSelection(item.value, option)
                              }
                            />
                            {option}
                          </label>
                        ))}
                      </div>
                    )}
                  </div>
                ))}

                <div style={styles.buttonGroup}>
                  <ButtonComp
                    wrapperName={"download_temp_wrapper"}
                    type="button"
                    btnStyle="box"
                    btnText={"Export"}
                    // disabled={file === null ? true : false}
                    btnIcon=""
                    onClick={() => handleExport()}
                  />
                  <ButtonComp
                    wrapperName={"download_temp_wrapper"}
                    type="button"
                    btnStyle="box"
                    btnText={"View"}
                    // disabled={file === null ? true : false}
                    btnIcon=""
                    onClick={() => handleView()}
                  />
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
}

const styles = {
  container: {
    padding: "20px",
    borderRadius: "8px",
    width: "100%",
    margin: "auto",
  },
  radioGroup: {
    display: "flex",
    marginBottom: "20px",
  },
  radioLabel: {
    fontSize: "16px",
    fontWeight: "500",
    marginRight: "20px",
  },
  accordionContainer: {
    marginBottom: "10px",
    border: "1px solid #ccc",
    borderRadius: "20px",
    overflow: "hidden",
  },
  accordionHeader: {
    padding: "10px",
    backgroundColor: "#f0f4ff",
    cursor: "pointer",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    fontWeight: "500",
  },
  accordionContent: {
    padding: "10px",
    backgroundColor: "#FFFFFF",
  },
  buttonGroup: {
    display: "flex",
    gap: "5px",
    marginTop: "20px",
  },
  button: {
    padding: "10px 20px",
    fontSize: "16px",
    backgroundColor: "#4f74f7",
    color: "white",
    border: "none",
    borderRadius: "20px",
    cursor: "pointer",
  },
};
