// IdleTimeoutContext.js
import React, { createContext, useContext, useEffect, useState } from "react";
import { decryptData } from "./CryptoJS";
import { getIdleTimer } from "./index";

// Create a context
const IdleTimeoutContext = createContext();

// Provide IdleTimeout Context
export const IdleTimeoutProvider = ({ children }) => {
  const [isIdle, setIsIdle] = useState(false);
  const [timer, setTimer] = useState(null);

  const resetIdleTimer = () => {
    if (timer) {
      clearTimeout(timer);
    }

    // Set new idle timer (e.g., 5 minutes = 300000ms)
    const newTimer = setTimeout(() => {
      setIsIdle(true); // User is idle
    }, decryptData(getIdleTimer())); // 5 minutes

    setTimer(newTimer);
    setIsIdle(false); // User is active
  };

  useEffect(() => {
    // Add event listeners to reset timer on activity
    const events = [
      "mousemove",
      "mousedown",
      "mouseup",
      "mouseenter",
      "mouseleave",
      "mouseover",
      "mouseout",
      "keydown",
      "keyup",
      "keypress",
      "touchstart",
      "touchmove",
      "touchend",
      "touchcancel",
      "resize",
      "focus",
      "blur",
      "visibilitychange",
      "pointerdown",
      "pointerup",
      "pointermove",
    ];
    events.forEach((event) => {
      window.addEventListener(event, resetIdleTimer);
    });

    // Clean up event listeners
    return () => {
      events.forEach((event) => {
        window.removeEventListener(event, resetIdleTimer);
      });
      if (timer) {
        clearTimeout(timer);
      }
    };
  }, [timer]);

  return (
    <IdleTimeoutContext.Provider value={{ isIdle, resetIdleTimer }}>
      {children}
    </IdleTimeoutContext.Provider>
  );
};

// Custom Hook to use the IdleTimeoutContext
export const useIdleTimeout = () => useContext(IdleTimeoutContext);
