import {
  faCircle,
  faCircleDot,
  faClipboard,
} from "@fortawesome/free-regular-svg-icons";
import {
  faAdd,
  faBriefcase,
  faNetworkWired,
  faUser,
  faUserGroup,
  faWallet,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import "../../App.scss";
import AppbarChart from "../../Component/ComponentsChart/AppbarChart";
import ApppieChart from "../../Component/ComponentsChart/ApppieChart";
import SemiDoughnutChart from "../../Component/ComponentsChart/SemiDoughnutChart";
import { getData } from "../../Component/ComponentsTable/agData";
import AgTable from "../../Component/ComponentsTable/AgTable";
import { AmountFormatter } from "../../Component/ComponentsTable/AmountFormatter";
import Pagehader from "../../Layout/Pagehader";
import { encrypt } from "../../util/CryptoJS";
import Loader from "../../util/Loader";
import "./RmDashbord.scss";
import AppModal from "../../Component/Modal/AppModal";
import WatchListPage from "../../Component/Modal/WatchListModel";

export default function Rmdashboard() {
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    }, 2000);
  }, []);

  const [selectedOption, setSelectedOption] = useState("My_Families");
  const [selectedOptioncard, setSelectedOptioncard] = useState("Families");

  const [showModal, setShowModal] = useState(false);

  const handleOpenModal = () => {
    setShowModal(true);
    // navigate("/" + encrypt("WatchListModel"));
  };
  const handleCloseModal = () => setShowModal(false);

  const handleAction = () => {};

  const handleOptionChange = (event, cardname) => {
    setSelectedOption(event);
    setSelectedOptioncard(cardname);
  };
  const [optionbtn, setOptionbtn] = useState("My");
  const handleOptionbtnGro = (event) => {
    setOptionbtn(event);
  };

  // Array of card data
  const cardData = [
    {
      title: "Families",
      iconSrc: faUserGroup, // Replace with actual icon path
      value: "My_Families",
      color: "#6076FA",
    },
    {
      title: "Clients",
      iconSrc: faUser,
      value: "My_Clients",
      color: "#FA60A9",
    },
    {
      title: "RM's",
      iconSrc: faNetworkWired,
      value: "My_RM",
      color: "#FFC444",
    },
    {
      title: "Prospect",
      iconSrc: faClipboard,
      value: "My_Prospect",
      color: "#F36F56",
    },
  ];
  const [rowData, setRowData] = useState(getData());
  const columnDefs = [
    {
      headerName: "Name",
      field: "firstName",
      sortable: true,
      filter: true,
      minWidth: 180,
    },
    {
      headerName: "Current Value",
      field: "lastName",
      cellRenderer: (params) => AmountFormatterFunction(params.data.age),

      sortable: true,
      filter: true,
      cellStyle: { "text-align": "right" },
      maxWidth: 180,
    },
    {
      headerName: "AUM Held",
      field: "lastName",
      cellRenderer: (params) => AmountFormatterFunction(params.data.age),
      sortable: true,
      filter: true,
      cellStyle: { "text-align": "right" },
      maxWidth: 180,
    },
    {
      headerName: "AUM Held (Away)",
      field: "lastName",
      cellRenderer: (params) => AmountFormatterFunction(params.data.age),
      cellStyle: { "text-align": "right" },
      sortable: true,
      filter: true,
      maxWidth: 180,
    },
    {
      headerName: "Gain/Loss",
      field: "lastName",
      cellStyle: { "text-align": "right" },
      sortable: true,
      filter: true,
      maxWidth: 180,
    },
  ];

  const breadcrumbItems = [{ label: "dashboard" }];
  const aumData = ["20.99", "10.99", "10.0"];
  const aumDatalabels = ["Net AUM", "Hold AUM", "Held Away AUM"];
  const aumDatabackgroundColor = ["#8B5CF6", "#FCD34D", "#F87171"];
  //
  const Marketdata = [
    {
      label: "AUM",
      data: [12, 19, 3, 50],
      backgroundColor: ["#5FB6FA", "#FA60A9", "#CA60FA", "#8A60FA"],
      borderColor: ["#5FB6FA", "#FA60A9", "#CA60FA", "#8A60FA"],
      borderWidth: 1,
    },
  ];
  const MarketDatalabels = ["Commodities", "Hybrid", "Equity", "Debt"];
  // AUMClientWisedata data
  const AUMClientWisedata = [
    {
      label: "AUM",
      data: [65, -59, 80],
      backgroundColor: "#6076FA",
      borderColor: "#6076FA",
      borderWidth: 1,
      type: "bar", // Type set to 'bar'
    },
    {
      label: "Clients",
      data: [-5, 59, 80],
      backgroundColor: "#000",
      borderColor: "#000",
      borderWidth: 1,
      type: "line", // Type set to 'bar'
    },
  ];
  const AUMClientWiseDatalabels = ["<10", "10L- 1Cr", ">1Cr"];
  const AUMChartAxis = "x";

  // SIP

  const SIPdata = [
    {
      label: "AUM",
      data: [65, -59, 80, 65],
      backgroundColor: "#88E482",
      borderColor: "#88E482",
      borderWidth: 1,
      type: "bar", // Type set to 'bar'
    },
    {
      label: "Clients",
      data: [-5, 59, 100, 50],
      backgroundColor: "#000",
      borderColor: "#000",
      borderWidth: 1,
      type: "line", // Type set to 'bar'
    },
  ];
  const SIPDatalabels = ["Monthly", "Weekly"];
  const SIPChartAxis = "x";
  // Transactions

  const Transactionsdata = [
    {
      label: "Net",
      data: [0, 59, 100, 50],
      backgroundColor: "#5FB6FA",
      borderColor: "#5FB6FA",
      borderWidth: 1,
      type: "bar", // Type set to 'bar'
    },
    {
      label: "Purchase",
      data: [0, 59, 100, 50],
      backgroundColor: "#5FF6FA",
      borderColor: "#5FF6FA",
      borderWidth: 1,
      type: "bar", // Type set to 'bar'
    },
    {
      label: "Redemption",
      data: [0, 59, 0, 50],
      backgroundColor: "#6076FA",
      borderColor: "#6076FA",
      borderWidth: 1,
      type: "bar", // Type set to 'bar'
    },
  ];
  const TransactionsDatalabels = ["Commodation", "Debt", "Equity", "Hybrid"];
  const TransactionsChartAxis = "y";
  return (
    <>
      {loading ? (
        <Loader pagename="Dashboard " />
      ) : (
        <>
          {" "}
          <Pagehader
            pagename="Dashboard"
            Breadcrumbshow={true}
            breadcrumbItems={breadcrumbItems}
          ></Pagehader>
          <div className="pagebody">
            <Row className="topcards">
              <Col md={4}>
                <div className="card dashbordcard">
                  <div className="card-body dashbordcardTitle">
                    <p className="chartname">AUM</p>
                    <div className="aumchart">
                      <SemiDoughnutChart
                        ChartData={aumData}
                        ChartDatalabels={aumDatalabels}
                        ChartDatabackgroundColor={aumDatabackgroundColor}
                        cutoutPercentage={"70%"}
                      />
                    </div>
                  </div>
                </div>
                <div className="d-flex ">
                  <div className="card totalcard dashbordcard">
                    <div className="card-body dashbordcardTitle p-2">
                      <p className="totalcardtext">Total Families</p>
                      <div className="divvalue">
                        <FontAwesomeIcon icon={faUserGroup} />
                        <span>100</span>
                      </div>
                    </div>
                  </div>
                  <div className="card totalcard dashbordcard">
                    <div className="card-body dashbordcardTitle  p-2">
                      <p className="totalcardtext">Total Clients</p>
                      <div className="divvalue">
                        <FontAwesomeIcon icon={faUser} />
                        <span>100</span>
                      </div>
                    </div>
                  </div>
                </div>
              </Col>
              <Col md={3} className="">
                <div className="card  dashbordcard AssetsValuecard">
                  <div className="card-body dashbordcardTitle AssetsValue">
                    <div className="AssetsValuediv">
                      <div className="icon">
                        <FontAwesomeIcon icon={faNetworkWired} />
                      </div>
                      <div className="Assetstext">
                        <p className="Assetstext_name">RM Under me</p>
                        <p className="Assetstext_value">12</p>
                      </div>
                    </div>

                    <div className="AssetsValuediv">
                      <div className="icon">
                        <FontAwesomeIcon icon={faBriefcase} />
                      </div>
                      <div className="Assetstext">
                        <p className="Assetstext_name">New Revenue (MTD)</p>
                        <p className="Assetstext_value">24 L</p>
                      </div>
                    </div>

                    <div className="AssetsValuediv">
                      <div className="icon">
                        <FontAwesomeIcon icon={faWallet} />
                      </div>
                      <div className="Assetstext">
                        <p className="Assetstext_name">
                          Net New Assets (MTD) (Held)
                        </p>
                        <p className="Assetstext_value">34 L</p>
                      </div>
                    </div>
                  </div>
                </div>
              </Col>
              <Col md={5}>
                <div className="card  dashbordcard newscard">
                  <div className="card-body dashbordcardTitle newscardValue">
                    <div className="newscardValuediv">
                      <p className="newscardtext_title p-5">
                        <strong>Top News</strong>
                      </p>
                      <div className="newscardtext"></div>
                    </div>
                    {/* AddWatchlist */}
                  </div>
                </div>
                <div className="card  dashbordcard Watchlistcard">
                  <div className="card-body dashbordcardTitle WatchlistcardValue">
                    {/* AddWatchlist */}
                    <div className="WatchlistcardValuediv">
                      <p className="Watchlistcardtext_name">Add Watchlist</p>
                      <button
                        type="button"
                        className="btn Watchlistcardtext_btn"
                        onClick={handleOpenModal}
                      >
                        <FontAwesomeIcon icon={faAdd} />
                      </button>
                      {/* <WatchListModal show={showModal} handleClose={handleCloseModal} stocks={watchlist} /> */}
                      {/* <WatchListPage show={showModal} stocks={watchlist} /> */}
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
            <div className="row mincards">
              <div className="col-12">
                {" "}
                <div className="btnGro">
                  <button
                    className={
                      optionbtn === "My"
                        ? "My_all_btn btn actbtn"
                        : "My_all_btn btn"
                    }
                    onClick={() => handleOptionbtnGro("My")}
                  >
                    {" "}
                    My
                  </button>
                  <button
                    className={
                      optionbtn === "All"
                        ? "My_all_btn btn actbtn"
                        : "My_all_btn btn"
                    }
                    onClick={() => handleOptionbtnGro("All")}
                  >
                    {" "}
                    All
                  </button>
                </div>
              </div>
            </div>
            <div className="row">
              {optionbtn === "My" ? (
                <>
                  {cardData.map((card) => (
                    <div className="col-12 col-md-3 mb-4" key={card.value}>
                      <button
                        className="buttonGrocard"
                        onClick={() =>
                          handleOptionChange(card.value, card.title)
                        }
                      >
                        <div className="card dashbordcard btnGrocard ">
                          <div className="card-body dashbordcardTitle btnGrocardTitle p-2">
                            <div className="btnGrocardtext">
                              <div
                                className="icon"
                                style={{ color: `${card.color}` }}
                              >
                                {card.value === selectedOption ? (
                                  <FontAwesomeIcon icon={faCircleDot} />
                                ) : (
                                  <FontAwesomeIcon icon={faCircle} />
                                )}
                              </div>
                              <div
                                className="text"
                                style={{ color: `${card.color}` }}
                              >
                                {optionbtn} {card.title}
                              </div>
                            </div>
                            <div className="btnGrovalue">
                              <FontAwesomeIcon
                                icon={card.iconSrc}
                                style={{ color: `${card.color}` }}
                                size="2xl"
                              />
                            </div>
                          </div>
                        </div>
                      </button>
                    </div>
                  ))}
                </>
              ) : null}
            </div>
            <div className="row mb-3">
              <div className="col">
                <div className="card  dashbordcard Informationcard">
                  <div className="card-body dashbordcardTitleInformationcardValue">
                    {/* AddWatchlist */}
                    <div className="InformationValuediv">
                      <p className="Informationcardtext_name">
                        {optionbtn}{" "}
                        {optionbtn === "All" ? "" : selectedOptioncard}{" "}
                        Information
                      </p>
                    </div>
                    <div className="InformationTable">
                      <AgTable
                        columnKeys={columnDefs.field}
                        columnDefs={columnDefs}
                        rowData={rowData}
                        filenames={
                          `${optionbtn}` +
                          "_" +
                          `${optionbtn === "All" ? "" : selectedOptioncard}` +
                          "_" +
                          "Information"
                        }
                        StyleClass={"ag_export_btn ripple_btn"}
                        downloadbtnstext={true}
                        // onSelectionChanged={onSelectionChangedContribution}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* Client Table */}

            {/* Charts Section */}
            <div className="row">
              {/* Product Market Value and Asset Market Value Bifurcation */}
              <div className="col-md-6 col-12">
                <div className="card dashbordchartcard">
                  <div className="card-body dashbordchartTitle">
                    <p className="dashbordchartname">
                      Product Market Value and Asset Market Value Bifurcation
                    </p>
                    <div className="dashbordchart">
                      <ApppieChart
                        type={"DoughnutChart"}
                        legendposition={"bottom"}
                        legenddisplay={true}
                        Chartdata={Marketdata}
                        Chartlabels={MarketDatalabels}
                      />
                    </div>
                  </div>
                </div>
              </div>
              {/* AUM Client Wise (In Cr.)*/}
              <div className="col-md-6 col-12">
                <div className="card dashbordchartcard">
                  <div className="card-body dashbordchartTitle">
                    <p className="dashbordchartname">
                      AUM Client Wise (In Cr.)
                    </p>
                    <div className="dashbordchart mixbar">
                      <AppbarChart
                        legendposition={"bottom"}
                        legenddisplay={true}
                        Chartdata={AUMClientWisedata}
                        Chartlabels={AUMClientWiseDatalabels}
                        ChartAxis={AUMChartAxis}
                      />
                    </div>
                  </div>
                </div>
              </div>
              {/* Transactions grouped by Assets (In Cr.) */}
              <div className="col-md-6 col-12">
                <div className="card dashbordchartcard">
                  <div className="card-body dashbordchartTitle">
                    <p className="dashbordchartname">
                      Transactions grouped by Assets (In Cr.)
                    </p>
                    <div className="dashbordchart mixbar">
                      <AppbarChart
                        legendposition={"bottom"}
                        legenddisplay={true}
                        Chartdata={Transactionsdata}
                        Chartlabels={TransactionsDatalabels}
                        ChartAxis={TransactionsChartAxis}
                      />
                    </div>
                  </div>
                </div>
              </div>

              {/* SIP Details*/}
              <div className="col-md-6 col-12">
                <div className="card dashbordchartcard">
                  <div className="card-body dashbordchartTitle">
                    <p className="dashbordchartname">SIP Details</p>
                    <div className="dashbordchart mixbar">
                      <AppbarChart
                        legendposition={"bottom"}
                        legenddisplay={true}
                        Chartdata={SIPdata}
                        Chartlabels={SIPDatalabels}
                        ChartAxis={SIPChartAxis}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
      <AppModal
        isOpen={showModal}
        onClose={handleCloseModal}
        handleActon={handleAction} // Ensure this is defined and passed correctly
        ModalTitle="Watch List"
        Modalsize="xl"
        buttonConfigs={[{ text: "Continue", icon: null, action: "continue" }]}
        ModalBody={
          // <p>You have been idle for some time. Do you want to continue?</p>
          <div className="row ">
            <WatchListPage />
          </div>
        }
        ModalType="Watchlist"
        ModalScrollable={true}
        // ReactOdometervalue={seconds}
      />
    </>
  );
}
function AmountFormatterFunction(params) {
  return <AmountFormatter amount={params} />;
}
