import PropTypes from "prop-types"; // Import PropTypes
import React, { useEffect } from "react";
import { Controller } from "react-hook-form";
import { useTranslation } from "react-i18next";
import Select, { components } from "react-select";
import ContainerFunction from "./ContainerFunction";
import CustomDropDown from "./CustomDropdown";

const SelectElement = (props) => {
  const { t } = useTranslation(["Common", "Messages", "Form"]);
  const {
    register,
    errors,
    setValue,
    control,
    divClassName,
    readOnly = false,
    previewFlag = "",
    onSelect = () => {},
    handleInputChange = () => {},
    registerName,
    mandatory = false,
    labelName = "",

    options = [],
    isMulti = false,
  } = props;

  const { ValueContainer, Placeholder } = components;
  const CustomValueContainer = ContainerFunction(ValueContainer, Placeholder);

  useEffect(() => {
    // setValue("ifscCode",{label:"label",value:"value"})
  }, []);

  return (
    <div className={"formgroup select-box " + divClassName}>
      <label htmlFor={registerName} className="label-heading-select">
        {labelName}
        {mandatory ? <span className="errormark"> *</span> : null}
      </label>

      <Controller
        control={control}
        name={registerName}
        rules={{
          required: {
            value: mandatory,
            message: t("Messages:App_lms_Messages_00001", { label: labelName }),
          },
        }}
        render={({ field: { onChange, value, name } }) => (
          <Select
            components={{
              ValueContainer: CustomValueContainer,
            }}
            isMulti={isMulti}
            menuPlacement="auto"
            styles={CustomDropDown}
            isDisabled={readOnly}
            className={cssFunction(previewFlag, errors, registerName)}
            menuColor="red"
            value={value}
            placeholder="Select5"
            options={options}
            {...register(registerName, {})}
            onChange={(value) => {
              onSelect(value);
              onChange(value);
              setValue(name, value);
            }}
            onInputChange={(value) => handleInputChange(value)}
          />
        )}
      />

      {!errors?.[registerName]?.ref?.value && (
        <small className="textdanger">{errors?.[registerName]?.message}</small>
      )}
    </div>
  );
};

function cssFunction(previewFlag, errors, registerName) {
  if (previewFlag) {
    return "Selectontrol previewStyle";
  } else if (errors?.[registerName]) {
    return "Selectontrol errorsborder";
  } else {
    return "Selectontrol";
  }
}

// Prop types validation
SelectElement.propTypes = {
  register: PropTypes.func.isRequired,
  errors: PropTypes.object.isRequired,
  setValue: PropTypes.func.isRequired,
  control: PropTypes.object.isRequired,
  divClassName: PropTypes.string,
  readOnly: PropTypes.bool,
  previewFlag: PropTypes.string,
  onSelect: PropTypes.func,
  handleInputChange: PropTypes.func,
  registerName: PropTypes.string.isRequired,
  mandatory: PropTypes.bool,
  labelName: PropTypes.string,
  label: PropTypes.string,
  errorLabel: PropTypes.string,
  id: PropTypes.string,
  accessibilityLabel: PropTypes.string,
  useForm: PropTypes.object,
  options: PropTypes.array,
  isMulti: PropTypes.bool,
};

export default SelectElement;
