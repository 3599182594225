export const EntityReportsMaster = {
    Family: {
      option: [
        { value: "family", labelName: "Select Family" },
        { value: "client", labelName: "Select Client" },
        { value: "accountCategory", labelName: "Select Account Category" },
        { value: "account", labelName: "Select Account" },
        { value: "product", labelName: "Select Product" },
        { value: "report", labelName: "Select Report" },
        { value: "reportFormat", labelName: "Select Report Format" },
      ],
      radioOptions: ["Held", "Held Away", "Both"],
    },
    Client: {
      option: [
        { value: "family", labelName: "Select Family" },
        { value: "client", labelName: "Select Client" },
        { value: "accountCategory", labelName: "Select Account Category" },
        { value: "account", labelName: "Select Account" },
        { value: "product", labelName: "Select Product" },
        { value: "report", labelName: "Select Report" },
        { value: "reportFormat", labelName: "Select Report Format" },
      ],
      radioOptions: ["Held", "Held Away", "Both"],
    },
    Account: {
      option: [
        { value: "family", labelName: "Select Family" },
        { value: "client", labelName: "Select Client" },
        { value: "accountCategory", labelName: "Select Account Category" },
        { value: "account", labelName: "Select Account" },
        { value: "product", labelName: "Select Product" },
        { value: "report", labelName: "Select Report" },
        { value: "reportFormat", labelName: "Select Report Format" },
      ],
      radioOptions: ["Held", "Held Away", "Both", "New Option"], // Example of adding a new radio button
    },
  };
  